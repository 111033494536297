/**
 * Created by ndyumin on 11.04.2017.
 */
import {Generator} from './generators.types';
import {LookupType} from './types.types';
import {LookupValue} from './lookups.types';
import {Source} from './sources.types';
export type Pagination = {
    total: number;
    scrollId: string;
    hasMore: boolean;
};

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc'
}

export type Sorting = {
    field: string | null;
    direction: SortDirection | null;
};
export type Filters = {
    enabled?: boolean;
};
export type AppError = {
    message: string;
    type: string;
    id: number;
};
export type MenuItem = {
    action: () => void;
    title: string;
    weight: number;
};
export type Session = {
    email: string;
    username: string;
};
export type SubMenu = {
    label: string;
    link: string;
};
export type DependencyItem = {
    canonical: string | null;
    checked: boolean;
    code: string;
    type: string;
    typeLabel: string;
};
export type StoreDependency = {
    url: string;
    canonical: string;
};
export type StoreDependencies = {
    code: string;
    type: string;
    dependent: {
        data: StoreDependency[];
    };
    parents: {
        data: StoreDependency[];
    };
};
export type TableValue = Source | LookupType | LookupValue | Generator;
export type User = {
    username: string;
    email: string;
    customer: string;
    roles: string[];
};
export type ScrollData = {
    scrollHeight: number;
    clientHeight: number;
    scrollTop: number;
};
export type DraftMode = {
    dirtyValues: boolean;
    dirtySources: boolean;
    dirtyTypes: boolean;
    dirtyGenerators: boolean;
};
export type Options = Array<{
    value: string;
    label: string;
}>;
export type GroupedOptions = Array<{
    groupValue: string;
    groupLabel: string;
    options: Options;
}>;
