import * as React from 'react';
import CustomIcon from '../CustomIcon/CustomIcon';
import DiscussionDialog from '../DiscussionDialog/DiscussionDialog';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import ReactDOM from 'react-dom';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './discussion-button.less';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Command} from '../../redux/middlewares/command-middleware';
import {Component, Fragment} from 'react';
import {GeneralCollaboration} from '../../rdm-sdk/collaboration.types';
import {connect} from 'react-redux';
import {logActivityCommand} from '../../redux/actions/activityLogging';
const logger = logActivityCommand('collaboration-popup');

type StateProps = {
    collaboration: GeneralCollaboration;
    policies: CheckedPolicies;
};

type DispatchProps = {
    logPopupOpen: (...args: any) => Command;
};

type ComponentProps = {
    objectType: string;
};

type Props = StateProps & DispatchProps & ComponentProps;

type State = {
    isOpen: boolean;
};
export class DiscussionButton extends Component<Props, State> {
    buttonRef: (ref: React.ElementRef<typeof IconButton>) => void;
    button?: React.ElementRef<typeof IconButton> | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.buttonRef = (ref) => (this.button = ref);
    }

    open = () => {
        this.setState({
            isOpen: true
        });
        this.props.logPopupOpen();
    };
    close = () =>
        this.setState({
            isOpen: false
        });

    render() {
        const {objectType, collaboration, policies} = this.props;
        const {isOpen} = this.state;
        const hasComments = collaboration.discussion.commentIds.length > 0;
        const headerButtonsContainer = document.getElementById('header__buttons-container-2');
        const button = headerButtonsContainer
            ? ReactDOM.createPortal(
                  <Tooltip title={i18n.text('Comments')}>
                      <IconButton color="inherit" onClick={this.open} ref={this.buttonRef} size="large">
                          <CustomIcon name="chat" />
                      </IconButton>
                  </Tooltip>,
                  headerButtonsContainer
              )
            : false;
        return policies.collaborationEdit ? (
            <Fragment>
                {button}
                <Popover
                    open={isOpen}
                    anchorEl={this.button}
                    onClose={this.close}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: -12,
                        horizontal: 0
                    }}
                    classes={{
                        paper: styles['discussion-button__popper']
                    }}
                    PaperProps={{
                        style: {
                            height: hasComments ? 600 : 400
                        }
                    }}
                >
                    <DiscussionDialog objectType={objectType} />
                </Popover>
            </Fragment>
        ) : (
            false
        );
    }
}

const mapStateToProps: (state, props: ComponentProps) => StateProps = (state, {objectType}) => ({
    collaboration: state.collaboration[objectType],
    policies: state.policies
});

const mapDispatchToProps: DispatchProps = {
    logPopupOpen: logger('open')
};
export default connect(mapStateToProps, mapDispatchToProps)(DiscussionButton);
