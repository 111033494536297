import {Dispatch} from 'redux';

/**
 * Created by ndyumin on 11.04.2017.
 */
const type = Symbol.for('command');
type Executor = (
    arg0: {
        state: any;
        dispatch: Dispatch;
    },
    ...args: any
) => void | Promise<any>;
export type Command = {
    type: symbol | string;
    args?: any;
    executor?: Executor;
};
type CommandCreator = (executor: Executor) => (...args: any) => Command;

export const commandCreator: CommandCreator =
    (executor) =>
    (...args) => ({
        type,
        args,
        executor
    });
export const commandMiddleware = (store) => (next) => (action) =>
    action.type === type
        ? action.executor(
              {
                  state: store.getState(),
                  dispatch: store.dispatch
              },
              ...action.args
          )
        : next(action);
