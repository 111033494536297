import * as R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '../Checkbox/Checkbox';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import DatePicker from '../DatePicker/DatePicker';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import GeneratorSelector from './GeneratorSelector';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '../TextField/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import i18n from 'ui-i18n';
import styles from './edit-type-dialog.less';
import {Fragment} from 'react';
import {Generator} from '../../rdm-sdk/generators.types';
import {LookupTypeAttribute} from '../../rdm-sdk/types.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {TYPE_ATTR_TYPES, typeCodeToUri} from '../../rdm-sdk/types';
import {TypeData} from './EditTypeDialog';
import {connect} from 'react-redux';
import {dateToUnix, fieldUpdateHandler, getChecked, getValue} from '../../core/util';
import {isNameUniq, validateLookupType} from './validation';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {pipe} from '../../core/func';
import {validateGenerator} from '../AddGeneratorDialog/validation';

type AttrProps = {
    onChange: (attribute: LookupTypeAttribute) => void;
    attribute?: LookupTypeAttribute;
    onRemove?: () => void;
    errorMessage?: string;
    isEditor?: boolean;
};
export const Attribute = ({
    attribute = {
        name: '',
        type: '',
        required: false,
        visible: true
    },
    onChange,
    onRemove,
    errorMessage,
    isEditor = false
}: AttrProps) => {
    const {name, type, required, visible} = attribute;
    const next = fieldUpdateHandler(onChange, attribute);
    return (
        <div className={styles['type-attribute']}>
            <TextField
                className={styles['type-attribute__name']}
                onChange={pipe(getValue, next('name'))}
                value={name}
                label={i18n.text('Attribute name')}
                required
                error={!!errorMessage}
                helperText={errorMessage || ''}
            />
            <FormControl className={styles['type-attribute__select']}>
                <InputLabel shrink={Boolean(type)} htmlFor="attribute-type">
                    {i18n.text('Data type') + (isEditor ? '' : ' *')}
                </InputLabel>
                <Select value={type} onChange={pipe(getValue, next('type'))} input={<Input id="attribute-type" />}>
                    {TYPE_ATTR_TYPES.map((attrType, idx) => (
                        <MenuItem dense key={idx} value={attrType}>
                            {i18n.text(attrType)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Tooltip title={i18n.text('Hide this attribute in consuming applications')}>
                <Checkbox
                    className={styles['type-attribute__hide-checkbox']}
                    label={i18n.text('Hide')}
                    checked={!visible}
                    onChange={R.pipe(getChecked, R.not, next('visible'))}
                />
            </Tooltip>
            <Tooltip title={i18n.text('Require canonical value to input attribute value')}>
                <Checkbox
                    className={styles['type-attribute__required-checkbox']}
                    label={i18n.text('Required')}
                    checked={required}
                    onChange={pipe(getChecked, next('required'))}
                />
            </Tooltip>
            <IconButton
                className={styles['type-attribute__remove-button']}
                style={{
                    visibility: isEditor ? 'hidden' : 'visible'
                }}
                onClick={onRemove}
                size="large"
            >
                <Icon>close</Icon>
            </IconButton>
        </div>
    );
};

const logger = logActivityCommand('type-modal');
const saveLogger = logger('save-click');
const deleteLogger = logger('delete-click');
const cancelLogger = logger('cancel-click');

type StateProps = {
    generators: Generator[];
};

type Props = StateProps & {
    typeData: TypeData;
    onClose: () => void;
    onEdit: () => void;
    onRemove: () => void;
    dispatch: (e: StateEvent) => void;
    setTypeProp: (key: string) => (value: any) => void;
    setNewGeneratorProp: (key: string) => (value: any) => void;
    isEdited: boolean;
};

function EditView({
    typeData,
    generators,
    onClose,
    onRemove,
    onEdit,
    dispatch,
    setTypeProp,
    setNewGeneratorProp,
    isEdited
}: Props) {
    const {label, code, startDate, endDate, attributes, generator, enabled, description, newGenerator} = typeData;
    const loggableInfo = {
        label,
        code,
        startDate,
        endDate,
        attributes,
        generator
    };
    const isValid =
        validateLookupType(typeData).isSuccess() &&
        (!newGenerator || validateGenerator(newGenerator, generators).isSuccess());

    const changeAttribute = (index) => (attr) => attributes?.map((oldAttr, i) => (i !== index ? oldAttr : attr));

    const removeAttribute = (index) => () => attributes?.filter((_, i) => i !== index);

    const addAttribute = (attr) => attributes?.concat(attr);

    const attrValues = attributes?.map((attribute, idx) => (
        <Attribute
            key={idx}
            attribute={attribute}
            onChange={R.pipe(changeAttribute(idx), setTypeProp('attributes'))}
            onRemove={R.pipe(removeAttribute(idx), setTypeProp('attributes'))}
            errorMessage={isNameUniq(idx, attributes) ? '' : i18n.text('Attribute name should be unique')}
        />
    ));
    const attrEditor = (
        <Attribute isEditor key={attrValues?.length} onChange={R.pipe(addAttribute, setTypeProp('attributes'))} />
    );
    return (
        <Fragment>
            <DialogTitle>{i18n.text('Edit Lookup Type')}</DialogTitle>
            <DialogContent className={styles['edit-type-dialog__content']}>
                <TextField
                    className={styles['edit-type-dialog__textfield']}
                    name="name"
                    onChange={R.pipe(getValue, setTypeProp('label'))}
                    value={label}
                    label={i18n.text('Name')}
                    required
                />
                <TextField
                    className={styles['edit-type-dialog__textfield']}
                    name="code"
                    onChange={R.pipe(getValue, typeCodeToUri, setTypeProp('uri'))}
                    value={code}
                    label={i18n.text('Code')}
                    readOnly
                    required
                />
                <TextField
                    className={styles['edit-type-dialog__textfield']}
                    name="description"
                    onChange={R.pipe(getValue, setTypeProp('description'))}
                    value={description}
                    label={i18n.text('Description')}
                />
                <div className={styles['edit-type-dialog__date-pickers']}>
                    <DatePicker
                        label={i18n.text('Start date')}
                        id="typeStartDatePicker"
                        value={startDate}
                        onSelect={R.pipe(dateToUnix, setTypeProp('startDate'))}
                    />
                    <DatePicker
                        label={i18n.text('End date')}
                        id="typeEndDatePicker"
                        value={endDate}
                        onSelect={R.pipe(dateToUnix, setTypeProp('endDate'))}
                    />
                </div>
                <CustomSwitch
                    className={styles['edit-type-dialog__switch']}
                    label={i18n.text('Status')}
                    hintForOnState={i18n.text('Turn off to disable this lookup type in consuming applications')}
                    hintForOffState={i18n.text('Turn on to enable this lookup type in consuming applications')}
                    checked={enabled}
                    onChange={R.pipe(getChecked, setTypeProp('enabled'))}
                />

                <GeneratorSelector
                    generator={generator}
                    newGenerator={newGenerator}
                    generators={generators}
                    onChange={setTypeProp}
                    onNewGeneratorChange={setNewGeneratorProp}
                />

                <div className={styles['type-details__attributes']}>
                    <Typography variant="subtitle1" className={styles['type-details__attributes-title']}>
                        {i18n.text('Custom attributes')}
                    </Typography>
                    {attrValues?.concat(attrEditor)}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="grey"
                    className={styles['edit-type-dialog__delete-button']}
                    onClick={R.pipe(onRemove, R.always(loggableInfo), deleteLogger, dispatch)}
                >
                    {i18n.text('Delete')}
                </Button>
                <div className="spacer" />
                <Button
                    variant="grey"
                    className={styles['edit-type-dialog__cancel-button']}
                    onClick={R.pipe(onClose, R.always(loggableInfo), cancelLogger, dispatch)}
                >
                    {i18n.text('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!isValid || !isEdited}
                    onClick={R.pipe(onEdit, R.always(loggableInfo), saveLogger, dispatch)}
                >
                    {i18n.text('Done')}
                </Button>
            </DialogActions>
        </Fragment>
    );
}

const mapStateToProps: (state) => StateProps = R.pick(['generators']);
export default connect(mapStateToProps)(EditView);
