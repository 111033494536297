import * as React from 'react';
import Icon from '@mui/material/Icon';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import styles from './styles.less';

type Props = Omit<IconButtonProps, 'onClick'> & {
    visible?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const CloseDialogButton = ({visible = true, onClick, ...props}: Props) =>
    visible && (
        <IconButton {...props} className={styles['close-dialog-button']} onClick={onClick} size="large">
            <Icon>close</Icon>
        </IconButton>
    );

export default CloseDialogButton;
