import ActivityTitle from '../../../ActivityLog/parts/ActivityTitle/ActivityTitle';
import ChangeItemDecorator from '../ChangeItemDecorator/ChangeItemDecorator';
import {ACTIVITY_TYPE} from '../../../ActivityLog/constants';
import {ActivityTitleProps} from '../../../ActivityLog/parts/ActivityTitle/ActivityTitle';
import {WorkflowChangeTypes} from '../../constants';
import {connect} from 'react-redux';
import {getWFChangeTypeByChangeId} from '../../../../redux/selectors/workflowSelectors';

type StateProps = {
    workflowChangeType: string;
};

type Props = StateProps & ActivityTitleProps;

function ChangesTitle(props: Props) {
    const {type, id, workflowChangeType} = props;
    const disabledLink = workflowChangeType === WorkflowChangeTypes.ADDED_LOOKUP_TYPE;
    return type === ACTIVITY_TYPE.DELETE ? (
        <ChangeItemDecorator id={id} grouped={false}>
            <ActivityTitle {...props} disabledLink={disabledLink} />
        </ChangeItemDecorator>
    ) : (
        <ActivityTitle {...props} disabledLink={disabledLink} />
    );
}

const mapStateToProps = (state, {id}: ActivityTitleProps) =>
    ({
        workflowChangeType: getWFChangeTypeByChangeId(state, id)
    }) as StateProps;

export default connect(mapStateToProps)(ChangesTitle);
