import clsx from 'clsx';
import styles from './comment-content.less';
import {ReactElement} from 'react';
import {getPrefixedEmailRegEx, replaceEmailPrefix} from '../../rdm-sdk/collaboration';
type Props = {
    children: string;
    className?: string;
};

const CommentContent = ({children, className}: Props) => {
    const content = replaceEmailPrefix('@', '+', children);
    const regEx = getPrefixedEmailRegEx('+');
    const chunks: (ReactElement | string)[] = [];
    let result;
    let prevIndex = 0;

    while ((result = regEx.exec(content)) !== null) {
        const mention: string = result[0];
        chunks.push(content.slice(prevIndex, result.index));
        chunks.push(
            <a href={`mailto:${mention.slice(1)}`} className={styles['comment-content__mention']} key={result.index}>
                {mention}
            </a>
        );
        prevIndex = result.index + mention.length;
    }

    chunks.push(content.slice(prevIndex));
    return <pre className={clsx(styles['comment-content'], className)}>{chunks}</pre>;
};

export default CommentContent;
