import {F, curry, either, pathEq, propEq} from 'ramda';
const isAbortError = either(pathEq(['_response', 'name'], 'AbortError'), propEq('name', 'AbortError'));
export const catchIfNotAbortError = curry((f, e) => {
    if (!isAbortError(e) && typeof f === 'function') return f(e);
    else return Promise.reject(e);
});
export const catchIfAbortError = curry((f, e) => {
    if (isAbortError(e) && typeof f === 'function') return f(e);
    else return Promise.reject(e);
});
export const addSignalToRequest = (promise, signal) => () => promise(signal).catch(catchIfAbortError(F));
