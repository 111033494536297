import ActivityLogBookmark from '../../ActivityLogBookmark/ActivityLogBookmark';
import Filters from '../../filter/Filters/Filters';
import SidePanel from '../../SidePanel/SidePanel';
import SourcesRowContent from '../SourcesRowContent/SourcesRowContent';
import {OBJECT_TYPE} from '../../constants';
import {RowItem} from '../../activityLog.types';
import {StateEvent} from '../../../../rdm-sdk/state.types';
import {closeClickLogger, openClickLogger} from '../../logging';
import {connect} from 'react-redux';
import {useState} from 'react';
const selects = {
    sources: true
};
type Props = {
    dispatch: (arg0: StateEvent) => void;
};

const rowContentRenderer = ({item}) => <SourcesRowContent item={item as RowItem} />;
const filtersRenderer = ({close}) => <Filters close={close} selects={selects} objectType={OBJECT_TYPE.SOURCE} />;

function SourcesSidePanel(props: Props) {
    const {dispatch} = props;
    const [open, setOpen] = useState(false);

    function handleOpen() {
        setOpen(true);
        dispatch(openClickLogger(null) as StateEvent);
    }

    function handleClose() {
        setOpen(false);
        dispatch(closeClickLogger(null) as StateEvent);
    }

    return open ? (
        <SidePanel
            objectType={OBJECT_TYPE.SOURCE}
            onClose={handleClose}
            rowContentRenderer={rowContentRenderer}
            filtersRenderer={filtersRenderer}
        />
    ) : (
        <ActivityLogBookmark onClick={handleOpen} />
    );
}

export default connect()(SourcesSidePanel);
