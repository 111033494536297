import * as React from 'react';
import clsx from 'clsx';
import styles from './styles.less';
import {noop} from '../../../../core/util';
type Props = {
    children?: React.ReactNode | null;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
};

function Link(props: Props) {
    const {children, className, onClick = noop, disabled = false} = props;
    return (
        <span
            className={clsx(styles['link'], !disabled && styles['link--enabled'], className)}
            onClick={!disabled ? onClick : noop}
        >
            {children}
        </span>
    );
}

export default Link;
