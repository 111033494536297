import ClearIndicator from './ClearIndicator';
import Control from './Control';
import DropdownIndicator from './DropdownIndicator';
import GroupHeading from './GroupHeading';
import Menu from './Menu';
import MultiValue from './MultiValue';
import NoOptionsMessage from './NoOptionsMessage';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    GroupHeading
};
export default components;
