import {
    complement,
    converge,
    defaultTo,
    either,
    filter,
    flatten,
    isNil,
    map,
    path,
    pathOr,
    pipe,
    pluck,
    prop,
    propEq,
    propOr
} from 'ramda';
import {createSelector} from 'reselect';
import {workflowInitialState} from '../initialState';
export const getDCRs = pathOr(null, ['workflow', 'DCRs']);
export const getTasks = pathOr(null, ['workflow', 'tasks', 'data']);
export const getTasksSize = pathOr(0, ['workflow', 'tasks', 'size']);
export const getTasksOffset = pathOr(0, ['workflow', 'tasks', 'offset']);
export const getIsTenantRegistered = pathOr(null, ['workflow', 'tenantRegistered']);
export const getDisplayedTask = pathOr(null, ['workflow', 'displayedTask']);
export const getIsDCRDisplayed = pipe(getDisplayedTask, complement(isNil));
export const getDCRsByDCRId = (state, DCRId) => (getDCRs(state) || []).filter(propEq('DCRId', DCRId));
const pluckTasksURIs = pipe(pluck('objectURIs'), flatten);
export const getTaskURIsById = (state, taskID) =>
    pipe(getTasks, defaultTo([]), filter(propEq('taskID', taskID)), pluckTasksURIs)(state);
export const getTasksURIs = pipe(getTasks, defaultTo([]), pluckTasksURIs);
export const getDCRsByTaskId = (state, taskID) =>
    pipe(
        getTaskURIsById,
        map((DCRId) => getDCRsByDCRId(state, DCRId)),
        flatten
    )(state, taskID);
export const getTasksPagination = pathOr(workflowInitialState.pagination, ['workflow', 'pagination']);
export const getRejectedChanges = pathOr([], ['workflow', 'rejectedChanges']);
export const getRejectedChangesByTaskId = (state, taskID) => {
    const rejectedChanges = getRejectedChanges(state);
    const uris = getTaskURIsById(state, taskID);
    return rejectedChanges
        .map((changeId) => ({
            changeId,
            DCRId: pipe(getDCRByChangeId, prop('DCRId'))(state, changeId)
        }))
        .filter((rejectedChange) => uris.includes(rejectedChange.DCRId));
};
const getConflictChangesIds = pipe(prop('changesConflicts'), pluck('id'));
export const getConflictChangesByTaskId = (state, taskID) => {
    const DCRs = getDCRs(state);
    const uris = getTaskURIsById(state, taskID);
    return pipe(
        filter((DCR) => uris.includes(DCR.DCRId)),
        map(
            converge(
                (changeIds, DCRId) =>
                    changeIds.map((changeId) => ({
                        changeId,
                        DCRId
                    })),
                [getConflictChangesIds, prop('DCRId')]
            )
        ),
        flatten
    )(DCRs || []);
};
export const getIsWorkflowEnabled = createSelector(
    either(path(['policies', 'workflowRead']), path(['policies', 'workflowAllRead'])),
    (_state, props) => pathOr(false, ['features', 'workflow'], props),
    getIsTenantRegistered,
    (userHasRights, workflowFeatureEnabled, tenantRegistered) =>
        userHasRights && workflowFeatureEnabled && tenantRegistered
);
export const getWorkflowsSize = pathOr(0, ['workflow', 'tasks', 'size']);
export const getWorkflowsSizeLabel = (state) => {
    const size = getWorkflowsSize(state);
    const {rowsPerPage, page} = getTasksPagination(state);
    const probableTasksCount = page * rowsPerPage + size;
    return size < rowsPerPage ? String(probableTasksCount) : `>${probableTasksCount}`;
};
export const getTaskAssignees = (state, taskId) => pathOr(null, ['workflow', 'assignees', String(taskId)], state);
export const getDCRByChangeId = (state, id) => {
    const DCRs = getDCRs(state);
    return (DCRs || []).find((DCR) => DCR.changesIds.includes(id));
};
export const getWFChangeTypeByChangeId = pipe(getDCRByChangeId, propOr(null, 'workflowChangeType'));
export const getTaskByChangeId = (state, id) => {
    const change = getDCRByChangeId(state, id);
    const tasks = getTasks(state);
    return change && tasks.find((task) => task.objectURIs.includes(change.DCRId));
};
export const getIsGroupedChange = (state, id) => {
    const DCR = getDCRByChangeId(state, id);
    return DCR.groupedChangesIds.includes(id);
};
// eslint-disable-next-line ramda/prefer-ramda-boolean
export const getCanUserRejectChange: () => boolean = () =>
    /*state, id*/
    {
        return false; // TODO Functionality for rejecting changes will be enabled later
        // const {session, policies} = state;
        // const task = getTaskByChangeId(state, id);
        // return policies.workflowDCRDelete && task && session.username === task.assignee;
    };
export const getCanUserRejectGroupedChangeById = createSelector(
    getIsGroupedChange,
    getCanUserRejectChange,
    (isGrouped, canReject) => isGrouped && canReject
);
export const getChangeConflictDetailsById = (state, id) => {
    const DCR = getDCRByChangeId(state, id);
    const changeConflict = DCR.changesConflicts.find(propEq('id', id));
    return changeConflict && changeConflict.conflictDetails;
};
