type Action = {
    action?: string | symbol;
    type: string;
};

type PendingAction = {
    action: Action;
    resolve: (data: any) => void;
};

let isPendingResolve = false;
const pendingActions: PendingAction[] = [];
const stoppableType = Symbol.for('stoppable');

export const getDispatchOnResolve = (dispatch) => (action: Action) =>
    dispatch({
        type: stoppableType,
        action
    });

const confirmMiddleware = () => (next) => (action) => {
    switch (action.type) {
        case 'showConfirmationDialog':
            isPendingResolve = true;
            return next(action);

        case 'resolve': {
            isPendingResolve = false;
            const queue = pendingActions.splice(0);

            if (action.result === 'save' || action.result === 'discard') {
                queue.forEach(({action, resolve}) => resolve(next(action)));
            }

            return next(action);
        }

        case stoppableType: {
            const stoppableAction = action.action;
            return isPendingResolve
                ? new Promise((resolve) =>
                      pendingActions.push({
                          action: stoppableAction,
                          resolve
                      })
                  )
                : next(stoppableAction);
        }

        default:
            return next(action);
    }
};

export default confirmMiddleware;
