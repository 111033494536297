import * as R from 'ramda';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import RightSidePanelComponent from 'react-components/dist/DataMapping/RightSidePanel';
import i18n from 'ui-i18n';
import languagesMock from '../../Localization/languagesMock';
import styles from './styles.less';
import {DataMappingContext} from 'react-components/dist/DataMapping/DataMapping';
import {Fragment, PureComponent} from 'react';
import {LookupType} from '../../../rdm-sdk/types.types';
import {Mapping} from '../../../rdm-sdk/import.types';
import {connect} from 'react-redux';
import {fromNull} from '../../../core/maybe';
import {getTypeByUri} from '../../../rdm-sdk/configuration';
import {withContext} from '../../../core/context';
import {withImportWizardContext} from '../ImportWizardContext';
const withDataMappingContext = withContext(DataMappingContext);
const TARGET_DELIMITER = '__';
const LABEL_DELIMITER = ': ';

const joinTargetKeys = (...keys) => keys.join(TARGET_DELIMITER);

const joinLabels = (...labels) => labels.join(LABEL_DELIMITER);

const getLookupTypesItems = R.map(({uri, label}) => ({
    key: uri,
    label
}));
const getLanguageItems = R.map(({code, label}) => ({
    key: code,
    label
}));
const getAttributesItems = R.map(({name}) => ({
    key: name,
    label: name
}));
const LANGUAGE_ITEMS = getLanguageItems(languagesMock);
type TargetItem = {
    key: string;
    label: string;
};
const MAIN_ITEMS: TargetItem[] = [
    {
        label: i18n.text('Source values'),
        key: 'sourceValues'
    },
    {
        label: i18n.text('Source codes'),
        key: 'sourceCodes'
    },
    {
        label: i18n.text('Source systems'),
        key: 'sourceSystems'
    },
    {
        label: i18n.text('Start Date'),
        key: 'startDate'
    },
    {
        label: i18n.text('End Date'),
        key: 'endDate'
    }
];
type Column = {
    index: number;
    name: string;
};
type Props = {
    lookupTypeItems: TargetItem[];
    attributeItems: TargetItem[];
    selectedLookupType: LookupType;
    selectedColumn?: number | null;
    addMapping: (mapping: Mapping) => void;
    removeMapping: (arg0: {target: string}) => void;
    mappings: Mapping[];
    columns: Column[];
};
type State = {
    openedGroupItems: string[];
};

class RightSidePanel extends PureComponent<Props, State> {
    state = {
        openedGroupItems: []
    } as State;
    toggleOpenedGroupItem = (groupKey) =>
        this.setState(
            R.evolve({
                openedGroupItems: (groupKeys) =>
                    groupKeys.includes(groupKey) ? R.reject(R.equals(groupKey), groupKeys) : [...groupKeys, groupKey]
            })
        );
    isItemGroupOpened = (itemKey) => this.state.openedGroupItems.includes(itemKey);
    getColumnName = (index) => R.pipe(R.find(R.propEq('index', index)), R.propOr(index, 'name'))(this.props.columns);
    getMapping = (target) =>
        R.find(
            R.whereEq({
                target
            }),
            this.props.mappings
        );
    getMappedColumnName = R.pipe(this.getMapping, R.prop('column'), this.getColumnName);

    render() {
        const {lookupTypeItems, attributeItems, selectedColumn, addMapping, removeMapping} = this.props;
        const isMappingMode = selectedColumn != null;
        const collapseItemsGroup = [
            {
                label: i18n.text('Language'),
                key: 'localization',
                items: LANGUAGE_ITEMS
            },
            {
                label: i18n.text('Parent'),
                key: 'parent',
                items: lookupTypeItems
            },
            {
                label: i18n.text('Child'),
                key: 'child',
                items: lookupTypeItems
            },
            {
                label: i18n.text('Custom attribute'),
                key: 'attribute',
                items: attributeItems
            }
        ].filter(({items}) => items.length > 0);
        return (
            <RightSidePanelComponent>
                <div className={styles['list-container']}>
                    <List component="div">
                        {MAIN_ITEMS.map(({label, key}) => (
                            <ListItem
                                // @ts-ignore
                                button={isMappingMode}
                                key={key}
                                onClick={R.pipe(
                                    R.always({
                                        label,
                                        target: key,
                                        column: selectedColumn
                                    }),
                                    addMapping
                                )}
                            >
                                <ListItemText primary={label} />
                                {this.getMapping(key) && (
                                    <Chip
                                        label={this.getMappedColumnName(key)}
                                        onDelete={R.pipe(
                                            R.always({
                                                target: key
                                            }),
                                            removeMapping
                                        )}
                                    />
                                )}
                            </ListItem>
                        ))}

                        {collapseItemsGroup.map(({label: groupLabel, key: groupKey, items}) => (
                            <Fragment key={groupKey}>
                                <ListItem button onClick={R.pipe(R.always(groupKey), this.toggleOpenedGroupItem)}>
                                    <ListItemText primary={groupLabel} />
                                    {this.isItemGroupOpened(groupKey) ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse
                                    in={this.isItemGroupOpened(groupKey)}
                                    timeout="auto"
                                    unmountOnExit
                                    key={groupKey}
                                >
                                    <List component="div" disablePadding className={styles['list-nested']}>
                                        {items.map(({label: itemLabel, key: itemKey}) => (
                                            <ListItem
                                                // @ts-ignore
                                                button={isMappingMode}
                                                key={itemKey}
                                                onClick={R.pipe(
                                                    R.always({
                                                        label: joinLabels(groupLabel, itemLabel),
                                                        target: joinTargetKeys(groupKey, itemKey),
                                                        column: selectedColumn
                                                    }),
                                                    addMapping
                                                )}
                                            >
                                                <ListItemText primary={itemLabel} />
                                                {this.getMapping(joinTargetKeys(groupKey, itemKey)) && (
                                                    <Chip
                                                        label={this.getMappedColumnName(
                                                            joinTargetKeys(groupKey, itemKey)
                                                        )}
                                                        onDelete={R.pipe(
                                                            R.always({
                                                                target: joinTargetKeys(groupKey, itemKey)
                                                            }),
                                                            removeMapping
                                                        )}
                                                    />
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </Fragment>
                        ))}
                    </List>
                </div>
            </RightSidePanelComponent>
        );
    }
}

const mapDataMappingContextToProps = (dataMapping) => ({
    selectedColumn: dataMapping.state.selectedColumn,
    columns: dataMapping.columns,
    removeMapping: dataMapping.props.onMappingDelete,
    mappings: dataMapping.props.mapping
});

const mapImportWizardContextToProps = R.pick(['addMapping', 'selectedLookupType']);

const mapStateToProps = ({configuration}, {selectedLookupType}) => ({
    lookupTypeItems: getLookupTypesItems(configuration.lookupTypes),
    attributeItems: fromNull(selectedLookupType)
        .map(R.prop('uri'))
        .map(getTypeByUri(configuration))
        .map(R.propOr([], 'attributes'))
        .map(getAttributesItems)
        .orSome([])
});

export default R.pipe(
    withDataMappingContext(mapDataMappingContextToProps),
    connect(mapStateToProps),
    withImportWizardContext(mapImportWizardContextToProps)
)(RightSidePanel);
