import {CheckedPolicies} from '../permissions/permissions.types';
import {EDIT_MODE} from '../constants/common';
import {propOr} from 'ramda';
export const getEditModes = ({lookupValuesEdit, workflowEdit, workflowAllEdit}: CheckedPolicies) => {
    const modes: string[] = [];
    (workflowEdit || workflowAllEdit) && modes.push(EDIT_MODE.SUGGEST);
    lookupValuesEdit && modes.push(EDIT_MODE.EDIT);
    return modes;
};
export const getDefaultEditMode = (policies) => {
    const modes = getEditModes(policies);
    return propOr('', 0, modes);
};
export const isWorkflowHasEditMode = ({workflowEdit, workflowAllEdit}: CheckedPolicies) =>
    workflowEdit || workflowAllEdit;
