import i18n from 'ui-i18n';
import {FEATURE} from '../../rdm-sdk/features';
import {NEED_MDM_SYNC, UI} from '../constants/actionTypes';
import {OpenedComment, OpenedSnackbar, OpenedTask} from '../../rdm-sdk/ui.types';
import {commandCreator} from '../middlewares/command-middleware';
import {featureStream$} from '../../configs';
export const setOpenedCommentEvent = (openedComment: OpenedComment | null) => ({
    type: UI.OPENED_COMMENT,
    openedComment
});
export const setIsSearchBarVisibleEvent = (isVisible: boolean) => ({
    type: UI.IS_SEARCH_BAR_VISIBLE,
    isVisible
});
export const setOpenedSnackbarEvent = (openedSnackbar: OpenedSnackbar | null) => ({
    type: UI.OPENED_SNACKBAR,
    openedSnackbar
});
export const setOpenedTaskEvent = (openedTask: OpenedTask | null) => ({
    type: UI.OPENED_TASK,
    openedTask
});
export const showDisclaimerSnackbarCommand = commandCreator(({state: {needMdmSync}, dispatch}) => {
    const isMdmSyncSnackbarEnabled = featureStream$.getValue()[FEATURE.MDM_SYNC_SNACKBAR];

    if (needMdmSync && isMdmSyncSnackbarEnabled) {
        dispatch(
            setOpenedSnackbarEvent({
                title: i18n.text('Saved.'),
                message: i18n.text(
                    'Be aware that these changes will not be reflected on your Reltio Cloud MDM tenant or other consuming applications until sync operation is run.'
                ),
                infoLink: 'https://help.reltio.com/index.html#reltioconsole/createrdmrefreshjob.html'
            })
        );
        dispatch(needMdmSyncResetEvent());
    }
});
export const needMdmSyncResetEvent = () => ({
    type: NEED_MDM_SYNC.RESET
});
