import * as R from 'ramda';
import * as React from 'react';
import ActivityChanges from '../../parts/ActivityChanges/ActivityChanges';
import ChangesTitle from '../../parts/ChangesTitle/ChangesTitle';
import CollapseButton from '../../../CollapseButton/CollapseButton';
import DefaultChange from '../../Change/Change';
import styles from './styles.less';
import {ChangeType} from '../../activityLog.types';
import {MAX_VISIBLE_ITEMS_IN_HIERARCHY_CHANGE} from '../../constants';
import {
    MapStateToProps,
    connectValuesSidePanelStore,
    setOpenedHierarchyChangesEvent
} from '../ValuesSidePanel/valuesSidePanelStore';
import {StateEvent} from '../../../../rdm-sdk/state.types';

type StateProps = {
    open: boolean;
};

type ComponentProps = {
    id: string;
    items: Array<Record<string, any>>;
    title?: string;
    measureRowHeight: () => void;
    dispatch: (arg0: StateEvent) => void;
    ChangeComponent: React.ComponentType<ChangeType>;
};

type Props = StateProps & ComponentProps;

function HierarchyChange(props: Props) {
    const {id, items, title, open, dispatch, measureRowHeight, ChangeComponent} = props;
    const needShowCollapseButton = items.length > MAX_VISIBLE_ITEMS_IN_HIERARCHY_CHANGE;

    const setOpen = (open) => dispatch(setOpenedHierarchyChangesEvent(id, open));

    function handleOpen() {
        setOpen(true);
        setTimeout(measureRowHeight, 0);
    }

    function handleClose() {
        setOpen(false);
        setTimeout(measureRowHeight, 0);
    }

    const Change = ChangeComponent || DefaultChange;
    return (
        <ActivityChanges>
            {title && (
                <ChangesTitle className={styles['hierarchy-change__title']}>
                    {title}
                    {needShowCollapseButton && (
                        <CollapseButton
                            open={open}
                            className={styles['hierarchy-change__collapse-button']}
                            onOpen={handleOpen}
                            onClose={handleClose}
                        />
                    )}
                </ChangesTitle>
            )}
            {(open || !needShowCollapseButton) &&
                items.map((item, index) => (
                    <Change
                        id={item.id}
                        key={item.name + index}
                        displayName={item.name}
                        type={item.type}
                        newValue={item.newValue}
                        oldValue={item.oldValue}
                    />
                ))}
        </ActivityChanges>
    );
}

const mapStateToProps: MapStateToProps = (state, {id}: ComponentProps) =>
    ({
        open: R.pathOr(true, ['openedHierarchyChangesEvent', id], state)
    }) as StateProps;

export default connectValuesSidePanelStore(mapStateToProps)(HierarchyChange);
