import clsx from 'clsx';
import styles from './invisible-cell.less';
import {MouseEventHandler, PureComponent} from 'react';
type Props = {
    onClick: MouseEventHandler<HTMLDivElement>;
    isFocusCell: boolean;
};
export default class InvisibleCell extends PureComponent<Props> {
    render() {
        const {onClick, isFocusCell} = this.props;
        return (
            <div
                className={clsx(styles['invisible-cell'], isFocusCell && styles['invisible-cell--focused'])}
                onClick={onClick}
            />
        );
    }
}
