import * as R from 'ramda';
import ErrorPopup from 'react-components/dist/ErrorPopup/ErrorPopup';
const getErrors = R.filter(R.prop('error'));

const getMessage = (error) =>
    R.path(['error', 'errorMessage'], error) ||
    R.prop('message', error) ||
    (R.path(['_response', 'message'], error) === 'Failed to fetch' ? 'Internet disconnected' : null) ||
    'Unexpected error';

const formatError = (type) => (error) => {
    return {
        message: getMessage(error),
        title: type
    };
};

const withErrorNotification = R.curry((errorType, request) => {
    const dispatchErrors = R.compose(ErrorPopup.addError, R.map(formatError(errorType)));
    return request
        .then((response) => {
            try {
                response
                    .clone()
                    .json()
                    .then((data) => {
                        const errors = getErrors(data);

                        if (errors.length > 0) {
                            dispatchErrors(errors);
                        }
                    });
            } catch (err) {
                // do nothing
            }

            // eslint-disable-line no-empty
            return response;
        })
        .catch((error) => {
            dispatchErrors([error]);
            throw error;
        });
});
export default withErrorNotification;
