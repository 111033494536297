import ListRowHeader from './ListRowHeader';
import ListRowIcon from './ListRowIcon';
import ShowMoreRow from './ShowMoreRow';
import clsx from 'clsx';
import styles from './styles.less';
import {ActivityLogLabel} from '../../constants';
import {
    LookupActivityItem,
    RowContentRenderer,
    RowHeaderItem,
    ShowMoreItem,
    TypeActivityItem
} from '../../activityLog.types';

type Props = {
    item: TypeActivityItem | LookupActivityItem | RowHeaderItem | ShowMoreItem;
    measureRowHeight: () => void;
    rowContentRenderer: RowContentRenderer<TypeActivityItem | LookupActivityItem>;
    isLast: boolean;
    onCollapseItems: ({groupId, scrollIndex}: ShowMoreItem) => void;
};

const isRowHeaderItem = (
    item: TypeActivityItem | LookupActivityItem | RowHeaderItem | ShowMoreItem
): item is RowHeaderItem => {
    return item.type === ActivityLogLabel.DATE_LABEL;
};

const isShowMoreItem = (
    item: TypeActivityItem | LookupActivityItem | RowHeaderItem | ShowMoreItem
): item is ShowMoreItem => {
    return item.type === ActivityLogLabel.COLLAPSE_LABEL;
};

function ListRow(props: Props) {
    const {item, rowContentRenderer, measureRowHeight, isLast, onCollapseItems} = props;

    if (isRowHeaderItem(item)) {
        return <ListRowHeader item={item} />;
    }

    if (isShowMoreItem(item)) {
        return <ShowMoreRow item={item} onCollapseItems={onCollapseItems} />;
    }

    const {isHead} = item;
    return (
        <div className={clsx(styles['list-row'], isHead && styles['list-row__head'])}>
            {isHead && <ListRowIcon type={item.iconType} />}
            <div
                className={clsx(
                    styles['list-row__vert-line'],
                    isLast && isHead && styles['list-row__vert-line--is-last']
                )}
            />
            <div
                className={clsx(
                    styles['list-row__content'],
                    isLast && styles['list-row__content--is-last'],
                    !isHead && styles['list-row__content--is-after-head']
                )}
            >
                {rowContentRenderer({
                    item,
                    measureRowHeight
                })}
            </div>
        </div>
    );
}

export default ListRow;
