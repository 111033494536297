import * as R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '../Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import VirtualizedSelect from 'react-virtualized-select';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Column, Table} from 'react-virtualized';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DependencyItem} from '../../rdm-sdk/app.types';
import {LookupValue} from '../../rdm-sdk/lookups.types';
import {Maybe} from 'monet';
import {Observable, Subject, Subscription} from 'rxjs';
import {SCROLL_OFFSET} from '../../constants/common';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {getCanonical} from '../../rdm-sdk/lookups';
import {getChecked, getValue} from '../../core/util';
import {orSome} from '../../core/monet';
import {prop, safeProp} from '../../core/lenses';
import {requestAllValuesCommand} from '../../redux/actions/values';
import {typeCodeToUri} from '../../rdm-sdk/types';
const LIMIT = 50;
const THROTTLE_INTERVAL = 100;
export const DEBOUNCE_INTERVAL = 350;

const emptyStringToNull = (s) => (s === '' ? null : s);

const nextEnabled = (tag) => (checked) => ({
    uri: getTagUri(tag),
    checked
});

const selectCell = ({rowData: {onChange, checked, type, code}}) => (
    <Checkbox
        color="primary"
        onChange={R.pipe(
            getChecked,
            nextEnabled({
                type,
                code
            }),
            onChange
        )}
        checked={checked}
    />
);

const checkedL = prop('checked');
const onChangeL = prop('onChange');
type StateRx = {
    canonical?: string | null;
    type?: string | null;
    offset: number;
};
const initialStateRx = {
    canonical: null,
    type: null,
    offset: 0
};

const reducer = (state, {type, value}) => {
    switch (type) {
        case 'canonical':
            return {...state, canonical: value, offset: 0};

        case 'type':
            return {...state, type: value, offset: 0};

        case 'offset':
            return {...state, offset: state.offset + LIMIT};
    }

    return state;
};

const createStore = (initial) => {
    const updater = new Subject<StateEvent>();
    // @ts-ignore
    const store = updater
        .throttle((event: StateRx) =>
            event.type === 'offset' ? Observable.interval(THROTTLE_INTERVAL).first() : Promise.resolve()
        )
        .scan(reducer, initial)
        .startWith(initial);
    return {
        updater,
        store
    };
};

const lookupTypeLabel = (types) => (uri) =>
    Maybe.fromNull(types.find((type) => type.uri === uri)).map(prop('label').get);

const getTagUri = (tag) => `${tag.type}/${tag.code}`;

const getExistingUris = R.map(getTagUri);
const getFilteredExistingUris = R.compose(getExistingUris, R.filter(checkedL.get));
const updateInternalState = R.curry((receivedLookupValues, nextProps, prevState) => {
    const {currentType, currentCode, configuration, open, tags = []} = nextProps;
    const {lookupValues: prevLookupValues, references: prevReferences, selectedTags: prevSelectedTags = []} = prevState;
    const lookup = lookupTypeLabel(configuration.lookupTypes);
    const selectedTags = open ? R.uniqBy(getTagUri, R.concat(tags, prevSelectedTags)) : R.uniqBy(getTagUri, tags);
    const existingUris = open
        ? R.pipe(R.concat, R.uniq)(getExistingUris(selectedTags), getFilteredExistingUris(prevReferences))
        : getExistingUris(selectedTags);
    const exceptions = Maybe.fromNull(nextProps.exceptions).orSome([]);
    const lookupValues = R.unionWith(
        (first, second) => first.code === second.code && first.type === second.type,
        prevLookupValues,
        receivedLookupValues
    );
    const references = lookupValues
        .filter(
            R.both(
                (value) => `${value.type}/${value.code}` !== `${typeCodeToUri(currentType)}/${currentCode}`,
                (value) => !exceptions.some(({type, code}) => type === value.type && code === value.code)
            )
        )
        .map((value) => ({
            canonical: getCanonical(value.sourceMappings).orSome(''),
            code: value.code,
            type: value.type,
            typeLabel: lookup(value.type).orSome(''),
            checked: existingUris.includes(getTagUri(value))
        }));
    return {
        selectedTags,
        lookupValues,
        references,
        propsToTrack: {
            tags: nextProps.tags
        }
    };
});
type Reference = {
    canonical: string;
    code: string;
    type: string;
    typeLabel: string;
    checked: boolean;
};

type StateProps = {
    currentType: string;
    configuration: Configuration;
};

type DispatchProps = {
    requestAllValues: (
        type: string | null | undefined,
        value: string | null | undefined,
        options: Record<string, any>
    ) => Promise<LookupValue[]>;
};

type Props = StateProps &
    DispatchProps & {
        title: string;
        tags: DependencyItem[];
        exceptions: DependencyItem[];
        currentCode: string;
        open: boolean;
        onValues: (tags: DependencyItem[]) => void;
        onClose: () => void;
    };
type State = {
    selectedTags: DependencyItem[];
    references: Reference[];
    hasMoreValues: boolean;
    lookupValues: LookupValue[];
    propsToTrack: {
        tags: DependencyItem[];
    };
} & StateRx;
export class ReferencePicker extends Component<Props, State> {
    updater$: Subject<StateEvent>;
    store$: Observable<StateRx>;
    sub?: Subscription;
    _tableRef?: Record<string, any> | null;
    currentState: State;

    constructor(props: Props) {
        super(props);
        const {store, updater} = createStore(initialStateRx);
        this.updater$ = updater;
        this.store$ = store;
        this.state = {
            ...initialStateRx,
            references: [],
            selectedTags: props.tags,
            lookupValues: [],
            hasMoreValues: true,
            propsToTrack: {
                tags: props.tags
            }
        };
        this.currentState = {...this.state};
    }

    _allSelected = () => {
        const {references} = this.state;
        return references.every(checkedL.get) && references.length > 0;
    };
    _toggleSelectedTags = (selected: boolean, uri?: string) => {
        const references = this.state.references.map((ref) =>
            !uri || uri === getTagUri(ref) ? checkedL.set(ref, selected) : ref
        );
        const selectedTags = R.pipe(
            R.reject(R.pipe(R.eqBy(getTagUri), R.find(R.__, references))),
            R.concat(references.filter(checkedL.get))
        )(this.state.selectedTags);
        this.setState({
            references,
            selectedTags
        });
    };
    _resetOffset = () => {
        this._tableRef && this._tableRef.scrollToPosition(0);
        this.setState({
            references: [],
            hasMoreValues: true
        });
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const nextTags = nextProps.tags;
        const prevTags = prevState.propsToTrack.tags;

        if (nextTags !== prevTags) {
            return updateInternalState([], nextProps, prevState);
        } else {
            return null;
        }
    }

    componentDidMount() {
        const {requestAllValues} = this.props;
        this.sub = this.store$
            .do(({canonical, type, offset}) => {
                this.setState((state) => ({
                    canonical,
                    type,
                    offset,
                    lookupValues: state.canonical !== canonical || state.type !== type ? [] : state.lookupValues
                }));
            })
            .debounceTime(DEBOUNCE_INTERVAL)
            .switchMap(({canonical, type, offset}) =>
                Observable.fromPromise(
                    requestAllValues(type, canonical, {
                        offset,
                        limit: LIMIT
                    })
                )
            )
            .subscribe((values) => {
                this.setState(updateInternalState(values, this.props));

                if (values.length < LIMIT) {
                    this.setState({
                        hasMoreValues: false
                    });
                }
            });
    }

    componentWillUnmount() {
        this.sub?.unsubscribe();
    }

    next = (type: string) => (value?: any) =>
        this.updater$.next({
            type,
            value
        });

    CheckboxCell = () => (
        <Checkbox
            color="primary"
            onChange={R.pipe(this._allSelected, R.not, this._toggleSelectedTags)}
            checked={this._allSelected()}
        />
    );

    render() {
        const {
            configuration: {lookupTypes},
            open,
            onValues,
            onClose,
            title
        } = this.props;
        const {canonical, type, references, selectedTags, hasMoreValues} = this.state;

        const onChange = ({checked, uri}) => this._toggleSelectedTags(checked, uri);

        const data = references.map((ref) => onChangeL.set(ref, onChange));
        const types = lookupTypes.map((type) => ({
            value: type.uri,
            label: type.label
        }));
        return (
            <Dialog className={styles['reference-picker']} open={open}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div className={styles['reference-picker__filters']}>
                        <TextField
                            variant="standard"
                            name="canonical"
                            className={styles['reference-picker__filter-canonical']}
                            onChange={R.pipe(getValue, emptyStringToNull, this.next('canonical'), this._resetOffset)}
                            value={Maybe.fromNull(canonical).orSome('')}
                            placeholder={i18n.text('Canonical')}
                        />
                        <VirtualizedSelect
                            placeholder={i18n.text('Select...')}
                            clearable={true}
                            options={types}
                            onChange={R.pipe(
                                Maybe.fromNull,
                                safeProp('value').get,
                                orSome(null),
                                this.next('type'),
                                this._resetOffset
                            )}
                            value={type}
                        />
                    </div>
                    <Table
                        ref={(ref) => (this._tableRef = ref)}
                        className={styles['reference-picker__table']}
                        disableHeader={false}
                        rowCount={data.length}
                        rowHeight={48}
                        rowGetter={({index}) => data[index]}
                        headerHeight={50}
                        height={400}
                        width={800}
                        onScroll={({scrollHeight, clientHeight, scrollTop}) => {
                            if (scrollHeight - clientHeight - scrollTop <= SCROLL_OFFSET && hasMoreValues) {
                                this.next('offset')();
                            }
                        }}
                    >
                        <Column
                            width={50}
                            disableSort
                            headerRenderer={this.CheckboxCell}
                            cellRenderer={selectCell}
                            dataKey="selected"
                            label=""
                        />
                        <Column
                            disableSort
                            flexGrow={1}
                            width={200}
                            dataKey="canonical"
                            label={i18n.text('Canonical value')}
                        />
                        <Column
                            disableSort
                            flexGrow={1}
                            width={200}
                            dataKey="typeLabel"
                            label={i18n.text('Lookup type')}
                        />
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="grey"
                        onClick={() => {
                            this.setState(this.currentState);
                            onClose();
                        }}
                    >
                        {i18n.text('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.currentState = {...this.state};
                            onValues(selectedTags);
                        }}
                    >
                        {i18n.text('Add')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps: (state) => StateProps = R.pick(['currentType', 'configuration']);
const mapDispatchToProps = (dispatch) =>
    ({
        requestAllValues: (type, value, options) => dispatch(requestAllValuesCommand(type, value, options))
    }) as DispatchProps;
export default connect(mapStateToProps, mapDispatchToProps)(ReferencePicker);
