import * as R from 'ramda';
import Dialog from '../Dialog/Dialog';
import EditView from './EditView';
import ReadView from './ReadView';
import i18n from 'ui-i18n';
import styles from './edit-type-dialog.less';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {Generator} from '../../rdm-sdk/generators.types';
import {LookupType, LookupTypeAttribute} from '../../rdm-sdk/types.types';
import {METADATA_KEY, markEdited} from '../../core/marks';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {fromArray, fromDefined, fromNotEmpty, fromString} from '../../core/maybe';
import {generatorForTypeUpsertEvent} from '../../redux/actions/generators';
import {getTypeByCode, typeUriToCode} from '../../rdm-sdk/types';
import {lookupTypesRemoveEvent, updateTypeEvent} from '../../redux/actions/types';
import {unixToDate} from '../../core/util';
const getTypeInformation = R.pick([
    'uri',
    'label',
    'description',
    'enabled',
    'startDate',
    'endDate',
    'attributes',
    'generator',
    METADATA_KEY
]);

// Date.now will be executed on the function call
const getDefaultState = (): State => ({
    uri: '',
    label: '',
    description: '',
    enabled: true,
    startDate: Date.now(),
    endDate: null,
    generator: null,
    attributes: [],
    newGenerator: null,
    propsToTrack: {
        currentType: null
    },
    isEdited: false
});

const initState = ({configuration, currentType, generatorsForTypes}: Props): State =>
    fromString(currentType)
        .flatMap(R.compose(fromDefined, getTypeByCode(configuration)))
        .map(
            (type) =>
                ({
                    newGenerator: R.propOr(null, currentType, generatorsForTypes),
                    isEdited: false,
                    propsToTrack: {
                        currentType
                    },
                    ...(type as LookupType)
                }) as State
        )
        .orSome(getDefaultState());

type StateProps = {
    currentType: string;
    configuration: Configuration;
    dispatch: (e: StateEvent) => void;
    generatorsForTypes: Record<string, Generator>;
    policies: CheckedPolicies;
};

type Props = StateProps & {
    onEdited: () => void;
    onRemoved: () => void;
    onCancelled: () => void;
    open: boolean;
};
type State = LookupType & {
    description: string;
    enabled: boolean;
    startDate: number;
    endDate?: number | null;
    generator?: string | null;
    label: string;
    uri: string;
    attributes?: LookupTypeAttribute[];
    newGenerator?: Generator | null;
    propsToTrack: {
        currentType?: string | null;
    };
    isEdited: boolean;
};
export type TypeData = {
    code: string;
    label: string;
    description: string;
    enabled: boolean;
    generator: string;
    startDate?: Date | null;
    endDate?: Date | null;
    attributes?: LookupTypeAttribute[];
    newGenerator?: Generator | null;
};
export class EditTypeDialog extends Component<Props, State> {
    state = initState(this.props);

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const {currentType: nextCurrentType} = nextProps;
        const {
            propsToTrack: {currentType: prevCurrentType}
        } = prevState;

        if (prevCurrentType !== nextCurrentType) {
            return initState(nextProps);
        } else {
            return null;
        }
    }

    onEditClick = () => {
        const {onEdited, dispatch, currentType, generatorsForTypes} = this.props;
        const prevNewGenerator = R.propOr(null, currentType, generatorsForTypes);
        R.pipe(
            R.tap(
                R.pipe(
                    R.prop('newGenerator'),
                    R.unless(R.equals(prevNewGenerator), R.compose(dispatch, generatorForTypeUpsertEvent(currentType)))
                )
            ),
            getTypeInformation,
            markEdited,
            updateTypeEvent,
            dispatch,
            onEdited
        )(this.state);
    };
    onRemoveClick = () => {
        const {onRemoved, dispatch} = this.props;
        R.pipe(R.map(getTypeInformation), lookupTypesRemoveEvent, dispatch, onRemoved)([this.state]);
    };
    next = (field) => (data: any) =>
        this.setState({
            [field]: data,
            isEdited: true
        } as State);
    newGeneratorNext = (field: string) => (value: string | number) =>
        this.setState(({newGenerator, generator}) => ({
            newGenerator: R.assoc(field, value, newGenerator),
            generator: field === 'name' ? String(value) : generator,
            isEdited: true
        }));

    render() {
        const {onCancelled, open, policies} = this.props;
        const {newGenerator, isEdited} = this.state;
        const enabled = fromDefined(this.state.enabled).orSome(false);
        const description = fromNotEmpty(this.state.description).orSome(
            policies.configEdit ? '' : i18n.text('No description')
        );
        const label = fromString(this.state.label).orSome('');
        const code = fromString(this.state.uri).map(typeUriToCode).orSome('');
        const startDate = unixToDate(this.state.startDate);
        const endDate = unixToDate(this.state.endDate as number);
        const attributes = fromArray(this.state.attributes).orSome([]);
        const generator = fromNotEmpty(this.state.generator).orSome(
            policies.configEdit ? '' : i18n.text('No generator')
        );
        const typeData = {
            enabled,
            description,
            label,
            code,
            startDate,
            endDate,
            attributes,
            generator,
            newGenerator
        };
        return (
            <Dialog
                open={open}
                onCancel={onCancelled}
                className={policies.configEdit ? styles['edit-type-dialog'] : styles['edit-type-dialog--read-view']}
            >
                {policies.configEdit ? (
                    <EditView
                        typeData={typeData}
                        onClose={onCancelled}
                        onRemove={this.onRemoveClick}
                        onEdit={this.onEditClick}
                        setTypeProp={this.next}
                        setNewGeneratorProp={this.newGeneratorNext}
                        isEdited={isEdited}
                    />
                ) : (
                    <ReadView typeData={typeData} onClose={onCancelled} />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps: (state) => StateProps = R.pick([
    'currentType',
    'configuration',
    'policies',
    'generatorsForTypes'
]);
export default connect(mapStateToProps)(EditTypeDialog);
