import Icon from '@mui/material/Icon';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {COLLAPSE_SHOW_MORE} from '../../constants';
import {ShowMoreItem} from '../../activityLog.types';
type Props = {
    item: ShowMoreItem;
    onCollapseItems: (item: ShowMoreItem) => void;
};

function ShowMoreRow(props: Props) {
    const {item, onCollapseItems} = props;
    return (
        <div className={styles['list-row-show-more-or-less']}>
            <div className={styles['list-row__vert-line']} />
            <div className={styles['list-row-show-more-or-less__button-wrap']} onClick={() => onCollapseItems(item)}>
                {item.value === COLLAPSE_SHOW_MORE ? (
                    <>
                        {i18n.text('Show more')}
                        <Icon>arrow_drop_down</Icon>
                    </>
                ) : (
                    <>
                        {i18n.text('Show less')}
                        <Icon>arrow_drop_up</Icon>
                    </>
                )}
            </div>
        </div>
    );
}

export default ShowMoreRow;
