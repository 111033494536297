import * as React from 'react';
import ConflictWrapper from '../ConflictWrapper/ConflictWrapper';
import RejectWrapper from '../RejectWrapper/RejectWrapper';
import {connect} from 'react-redux';
import {
    getCanUserRejectChange,
    getChangeConflictDetailsById,
    getIsGroupedChange
} from '../../../../redux/selectors/workflowSelectors';
type Props = {
    id: string;
    grouped: boolean;
    isGroupedChange: boolean;
    canReject: boolean;
    conflictDetails: string;
    children: React.ReactElement;
};

const ChangeItemDecorator = (props: Props): React.ReactElement => {
    const {id, grouped, isGroupedChange, conflictDetails, canReject, children} = props;
    const canApplyWrapper = isGroupedChange === grouped;
    const hasConflicts = !!conflictDetails && canApplyWrapper;
    const canRejectChangeItem = canReject && canApplyWrapper;
    return (
        (hasConflicts && <ConflictWrapper conflictDetails={conflictDetails}>{children}</ConflictWrapper>) ||
        (canRejectChangeItem && <RejectWrapper id={id}>{children}</RejectWrapper>) ||
        children
    );
};

const mapStateToProps = (state, {id}) => ({
    isGroupedChange: id && getIsGroupedChange(state, id),
    conflictDetails: id && getChangeConflictDetailsById(state, id),
    canReject: id && getCanUserRejectChange(/*state, id*/)
});

export default connect(mapStateToProps)(ChangeItemDecorator);
