import * as R from 'ramda';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import i18n from 'ui-i18n';
import styles from './styles.less';
type Props = {
    label: string;
};

const LookupTypeCard = ({label}: Props) => (
    <Card>
        <h3 className={styles['title']}>{i18n.text('Lookup type')}</h3>
        <CardContent>
            <Avatar className={styles['icon']}>{R.head(label)}</Avatar>
            <p className={styles['text']}>{label}</p>
        </CardContent>
    </Card>
);

export default LookupTypeCard;
