import * as R from 'ramda';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import TypeHierarchyRow from './TypeHierarchyRow/TypeHierarchyRow';
import clsx from 'clsx';
import styles from './styles.less';
import tableStyles from '../CustomTable/styles.less';
import withCache from '../Cache/withCache';
import {AutoSizer, List, WindowScroller} from 'react-virtualized';
import {CACHE_KEYS} from '../../constants/common';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {ElementRef} from 'react';
import {HierarchyRawData, TreeNode, TreePath} from '../../rdm-sdk/hierarchy.types';
import {LookupType} from '../../rdm-sdk/types.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {TypeStats} from '../../rdm-sdk/stats.types';
import {TypesSearchQuery} from '../../rdm-sdk/filters.types';
import {
    buildHierarchyList,
    buildHierarchyTrees,
    filterHierarchy,
    findLinkOrigin,
    getTreeNodeByPath,
    showPath,
    showPathWithChildren,
    toggleExpandedByPath,
    toggleShowParentsByPath
} from '../../rdm-sdk/hierarchy';
import {connect} from 'react-redux';
import {getTypeByUri} from '../../rdm-sdk/configuration';
import {getTypeStats} from '../../rdm-sdk/stats';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {requestHierarchySaga} from '../../redux/actions/hierarchy';
import {searchFilter} from '../../pages/TypesPage/typesFilter';
import {typesHeaderRenderer} from '../../pages/TypesPage/typesHeaderRenderer';
type Props = {
    dispatch: (e: StateEvent) => Promise<any>;
    setCacheData: (arg0: TreeNode[]) => void;
    cacheData: TreeNode[];
    typesHierarchy?: HierarchyRawData | null;
    openTypeDialog: (type: LookupType) => void;
    typesSearchQuery: TypesSearchQuery;
    configuration: Configuration;
    openValues: (type: LookupType) => void;
    toggleHierarchyMode: () => void;
    unmappedByTypes: TypeStats;
};
export type TypesHierarchyViewState = {
    hierarchy: TreeNode[];
    propsToTrack: {
        typesSearchQuery?: TypesSearchQuery | null;
    };
    searchIndex: number;
    searchMatches: TreePath[];
    selectedPath?: TreePath | null;
};
const logger = logActivityCommand('types-hierarchy');
const ROW_HEIGHT = 48;
const PARENT_ROW_HEIGHT = 46;

type SearchMatchesNavigatorProps = {
    searchIndex: number;
    searchMatches: TreePath[];
    showPrevMatch: () => void;
    showNextMatch: () => void;
};

const SearchMatchesNavigator = ({
    searchIndex,
    searchMatches,
    showPrevMatch,
    showNextMatch
}: SearchMatchesNavigatorProps) => (
    <div className={styles['hierarchy-view__search-matches']}>
        Matches: {searchIndex + 1} / {searchMatches.length}
        <IconButton onClick={showPrevMatch} disabled={searchMatches.length === 0} size="large">
            <Icon>arrow_back</Icon>
        </IconButton>
        <IconButton onClick={showNextMatch} disabled={searchMatches.length === 0} size="large">
            <Icon>arrow_forward</Icon>
        </IconButton>
    </div>
);

export class TypesHierarchyView extends Component<Props, TypesHierarchyViewState> {
    _scrollElement: any;
    _listRef?: ElementRef<typeof List> | null;
    list?: TreePath[];

    static getDerivedStateFromProps(nextProps: Props, prevState: TypesHierarchyViewState) {
        const {typesHierarchy, typesSearchQuery: nextSearchQuery, configuration} = nextProps;
        const {
            hierarchy,
            propsToTrack: {typesSearchQuery: prevSearchQuery},
            searchMatches,
            searchIndex
        } = prevState;
        const nextState = {
            propsToTrack: {
                typesSearchQuery: nextSearchQuery
            },
            hierarchy,
            searchMatches,
            searchIndex
        };

        if (!typesHierarchy) return nextState;

        const hierarchyData = typesHierarchy.hierarchy || {};
        const shouldInitHierarchy = hierarchy.length === 0 && typesHierarchy.status !== 'INIT';

        if (shouldInitHierarchy) {
            const allTypes = R.pluck(['uri'], configuration.lookupTypes);
            nextState.hierarchy = buildHierarchyTrees(hierarchyData, allTypes);
        }

        if (prevSearchQuery === nextSearchQuery && !shouldInitHierarchy) return nextState;

        if (nextSearchQuery) {
            const filterFunction = R.pipe(R.prop('value'), getTypeByUri(configuration), searchFilter(nextSearchQuery));
            nextState.searchMatches = filterHierarchy(filterFunction, nextState.hierarchy);
            const matchesCount = nextState.searchMatches.length;

            if (matchesCount > 0) {
                nextState.searchIndex = searchIndex === -1 ? 0 : Math.min(searchIndex, matchesCount - 1);
                nextState.hierarchy = showPath(nextState.searchMatches[nextState.searchIndex], nextState.hierarchy);
            } else {
                nextState.searchIndex = -1;
            }
        } else {
            nextState.searchMatches = [];
            nextState.searchIndex = -1;
        }

        return nextState;
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            hierarchy: props.cacheData || [],
            propsToTrack: {
                typesSearchQuery: null
            },
            searchIndex: -1,
            searchMatches: [],
            selectedPath: null
        };
    }

    componentDidMount() {
        if (this._scrollElement) this._scrollElement.scrollTop = 0;
        const {dispatch, cacheData} = this.props;

        if (!cacheData) {
            requestHierarchySaga(dispatch)();
        }
    }

    componentWillUnmount() {
        if (this.state.hierarchy.length > 0) {
            this.props.setCacheData(this.state.hierarchy);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: TypesHierarchyViewState) {
        const {searchIndex, searchMatches} = this.state;

        if (searchIndex !== -1 && prevState.searchMatches !== searchMatches) {
            this.scrollToAndUpdate(searchMatches[searchIndex]);
        }
    }

    scrollToPath = (path: TreePath) => {
        const indexToScroll = this.findPathIndex(path);

        this._listRef?.scrollToRow(indexToScroll);
    };
    recomputeRowHeights = (path: TreePath) => {
        const index = this.findPathIndex(path);

        this._listRef?.recomputeRowHeights(index);
    };
    scrollToAndUpdate = R.pipe(R.tap(this.scrollToPath), R.slice(0, 1), this.recomputeRowHeights);
    toggleExpanded = (path: TreePath) =>
        this.setState(
            R.evolve({
                hierarchy: toggleExpandedByPath(path)
            }),
            () => this.recomputeRowHeights(path)
        );
    shiftSearchIndex = (offset: number) => {
        this.setState(
            ({searchMatches, searchIndex, hierarchy}) => {
                const nextIndex = R.mathMod(searchIndex + offset, searchMatches.length);
                return {
                    searchIndex: nextIndex,
                    hierarchy: showPath(searchMatches[nextIndex], hierarchy)
                };
            },
            () => this.scrollToAndUpdate(this.state.searchMatches[this.state.searchIndex])
        );
    };
    showNextSearchResult = () => {
        this.shiftSearchIndex(1);
        R.pipe(logger('next-match-click'), this.props.dispatch)();
    };
    showPrevSearchResult = () => {
        this.shiftSearchIndex(-1);
        R.pipe(logger('prev-match-click'), this.props.dispatch)();
    };
    resetSelectedPath = () =>
        this.setState({
            selectedPath: null
        });
    showLinkOrigin = (linkPath: TreePath) => {
        const originPath = findLinkOrigin(linkPath, this.state.hierarchy);

        if (originPath) {
            this.setState(
                R.evolve({
                    selectedPath: R.always(originPath),
                    hierarchy: showPath(originPath)
                }),
                () => this.scrollToAndUpdate(originPath)
            );
        }
    };
    showParent = (parentUri: string) => {
        const filterFunction = R.both(R.propEq('value', parentUri), R.complement(R.prop('isLink')));
        const [path] = filterHierarchy(filterFunction, this.state.hierarchy);

        if (path) {
            this.setState(
                R.evolve({
                    selectedPath: R.always(path),
                    hierarchy: showPathWithChildren(path)
                }),
                () => this.scrollToAndUpdate(path)
            );
        }
    };
    findPathIndex = (path: TreePath) => this.list?.findIndex(R.eqBy(R.toString, path));
    toggleShowParents = (path: TreePath) => {
        this.setState(
            R.evolve({
                hierarchy: toggleShowParentsByPath(path)
            }),
            () => this.recomputeRowHeights(path)
        );
    };
    getRowHeight = ({index}: {index: number}) => {
        const path = this.list ? this.list[index] : [];
        const nodeData = getTreeNodeByPath(path, this.state.hierarchy);
        const parentsCount = R.pathOr(0, ['typesHierarchy', 'hierarchy', nodeData.value, 'length'], this.props);
        return nodeData.showParents ? ROW_HEIGHT + parentsCount * PARENT_ROW_HEIGHT : ROW_HEIGHT;
    };

    render() {
        const {
            configuration,
            typesSearchQuery,
            openValues,
            openTypeDialog,
            unmappedByTypes,
            toggleHierarchyMode,
            dispatch
        } = this.props;
        const {hierarchy, searchIndex, searchMatches, selectedPath} = this.state;
        this.list = buildHierarchyList(hierarchy);

        const rowRenderer = ({index, key, style}) => {
            const path = this.list ? this.list[index] : [];
            const equalsToPath = R.eqBy(R.toString, path);
            const pathMatchIndex = searchMatches.findIndex(equalsToPath);
            const nodeData = getTreeNodeByPath(path, hierarchy);
            const typeUri = nodeData.value;
            const type = getTypeByUri(configuration, typeUri);
            const isMatched = pathMatchIndex !== -1;
            const parents = R.path(['typesHierarchy', 'hierarchy', typeUri], this.props);
            return (
                <TypeHierarchyRow
                    style={style}
                    key={key}
                    path={path}
                    type={type}
                    unmappedCount={getTypeStats(type, unmappedByTypes)}
                    data={nodeData}
                    toggleExpanded={this.toggleExpanded}
                    openTypeDialog={openTypeDialog}
                    openValues={openValues}
                    isMatched={isMatched}
                    isSelectedMatch={isMatched && pathMatchIndex === searchIndex}
                    onLinkClick={this.showLinkOrigin}
                    resetSelected={this.resetSelectedPath}
                    isSelected={equalsToPath(selectedPath)}
                    parents={parents}
                    onParentLinkClick={this.showParent}
                    toggleShowParents={this.toggleShowParents}
                    typesSearchQuery={typesSearchQuery}
                    logger={logger}
                />
            );
        };

        const unmappedTypesTotal = R.pipe(R.filter(R.lt(0)), R.keys, R.length)(unmappedByTypes);
        const typesHeader = typesHeaderRenderer({
            typesTotal: configuration.lookupTypes.length,
            unmappedTypesTotal,
            logger,
            filter: null
        })();
        return (
            <div
                className={tableStyles['custom-table__container']}
                ref={(ref) => (this._scrollElement = ref && ref.parentNode)}
            >
                {typesSearchQuery && (
                    <SearchMatchesNavigator
                        searchIndex={searchIndex}
                        searchMatches={searchMatches}
                        showNextMatch={this.showNextSearchResult}
                        showPrevMatch={this.showPrevSearchResult}
                    />
                )}
                <div className={clsx(tableStyles['custom-table'], styles['types-hierarhy-view__table'])}>
                    <div className={styles['hierarchy-view__header']}>
                        {typesHeader}
                        <IconButton
                            className={styles['hierarchy-view__list-button']}
                            onClick={R.pipe(toggleHierarchyMode, logger('view-toggle-click'), dispatch)}
                            size="large"
                        >
                            <Icon>list</Icon>
                        </IconButton>
                    </div>
                    <AutoSizer disableHeight={true}>
                        {({width}) => (
                            <WindowScroller scrollElement={this._scrollElement}>
                                {({height, isScrolling, onChildScroll, scrollTop}) => (
                                    <List
                                        ref={(ref) => (this._listRef = ref)}
                                        autoHeight
                                        height={height}
                                        width={width}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        overscanRowCount={10}
                                        scrollTop={scrollTop}
                                        rowCount={this.list?.length || 0}
                                        rowHeight={this.getRowHeight}
                                        rowRenderer={rowRenderer}
                                    />
                                )}
                            </WindowScroller>
                        )}
                    </AutoSizer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    typesHierarchy: state.typesHierarchy,
    typesSearchQuery: state.typesSearchQuery,
    configuration: state.configuration,
    unmappedByTypes: state.stats.unmappedByTypes
});

export default R.pipe(connect(mapStateToProps), withCache(CACHE_KEYS.HIERARCHY))(TypesHierarchyView);
