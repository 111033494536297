import {getIsConfigurationDirty, getIsValuesDirty} from '../reducers/draftModeReducer';
import {requestGeneratorsSaga, updateGeneratorsSaga} from './generators';
import {resetDependenciesEvent} from './dependencies';
import {resetTenantConfigurationSaga, updateTenantConfigurationSaga} from './configuration';
import {resetValuesSaga, suggestValuesDataSaga, updateValuesDataSaga} from './values';
export const saveDraftSaga = (draftMode, dispatch) => {
    const {dirtyValues, dirtyGenerators} = draftMode;
    const dirtyConfiguration = getIsConfigurationDirty(draftMode);
    const generatorsUpdateP = dirtyGenerators ? updateGeneratorsSaga(dispatch)() : Promise.resolve();
    return generatorsUpdateP
        .then(() => dirtyConfiguration && updateTenantConfigurationSaga(dispatch)())
        .then(() => dirtyValues && updateValuesDataSaga(dispatch)());
};
export const suggestDraftSaga = (draftMode, dispatch) => {
    const dirtyValues = getIsValuesDirty(draftMode);
    const dirtyConfiguration = getIsConfigurationDirty(draftMode);
    return Promise.resolve()
        .then(() => dirtyConfiguration && updateTenantConfigurationSaga(dispatch)())
        .then(() => dirtyValues && suggestValuesDataSaga(dispatch)());
};
export const discardDraftSaga = (draftMode, dispatch) => {
    const {dirtyValues, dirtyGenerators} = draftMode;
    const dirtyConfiguration = getIsConfigurationDirty(draftMode);
    const generatorsResetP = dirtyGenerators ? requestGeneratorsSaga(dispatch)() : Promise.resolve();
    const configurationResetP = dirtyConfiguration ? resetTenantConfigurationSaga(dispatch)() : Promise.resolve();
    const lookupValuesResetP = dirtyValues ? resetValuesSaga(dispatch)() : Promise.resolve();
    dispatch(resetDependenciesEvent());
    return Promise.all([generatorsResetP, configurationResetP, lookupValuesResetP]);
};
