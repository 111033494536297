import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ReactDOM from 'react-dom';
type Props = {
    onClick: () => void;
    children: string;
};

const MenuItemsButton = ({onClick, children}: Props) => {
    const container = document.getElementById('status-line-menu-items');
    return container
        ? ReactDOM.createPortal(
              <Button color="primary" onClick={onClick}>
                  <Icon>add</Icon> {children}
              </Button>,
              container
          )
        : false;
};

export default MenuItemsButton;
