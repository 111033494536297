import App from './AppComponent';
import FeatureProvider from './components/Feature/FeatureProvider';
import rdmTheme from './themes/rdm';
import store from './redux/store';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {Provider} from 'react-redux';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {StylesProvider, createGenerateClassName} from '@mui/styles';
import {featureStream$, initConfigs} from './configs';
import {getIsDirty} from './redux/reducers/draftModeReducer';
import {hashHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';
import {useEffect, useState} from 'react';

window.onbeforeunload = function onWindowClose(e) {
    e = e || window.event;
    const {draftMode} = store.getState();
    const isDirty = getIsDirty(draftMode);

    if (isDirty) {
        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = 'Any string';
        }

        // For Chrome and Safari
        return 'Any string';
    }
};

const generateClassName = createGenerateClassName({
    productionPrefix: 'rdm',
    disableGlobal: true
});
const history = syncHistoryWithStore(hashHistory, store);

const Main = () => {
    const [configsLoaded, setConfigsLoaded] = useState(false);
    useEffect(() => {
        const fetchConfigs = async () => {
            try {
                await initConfigs();
            } catch (e) {
                // do nothing;
            }

            setConfigsLoaded(true);
        };

        fetchConfigs();
    }, []);
    return (
        <FeatureProvider features={featureStream$}>
            <Provider store={store}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={rdmTheme}>
                        <StylesProvider generateClassName={generateClassName}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                {configsLoaded && <App history={history} />}
                            </LocalizationProvider>
                        </StylesProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Provider>
        </FeatureProvider>
    );
};

export {Main};
