import * as R from 'ramda';
import * as React from 'react';
import Button from '@mui/material/Button';
import ClickOutsideHOC from '../ClickOutsideHOC/ClickOutsideHOC';
import GridColumnsMenu from '../AddGridColumnMenu/AddGridColumnMenu';
import GridHeader from '../GridHeader/GridHeader';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InvisibleCell from './InvisibleCell/InvisibleCell';
import MappingCellGroup, {DEFAULT_MAPPING} from './MappingCellGroup/MappingCellGroup';
import MappingDetailsEditModal from '../MappingDetails/MappingDetailsEditModal';
import MappingEmptyIcon from '../../assets/inline/mappingEmpty.svg';
import MappingSidePanel from './MappingSidePanel/MappingSidePanel';
import MarkLabel from '../MarkLabel/MarkLabel';
import NetworkSubscription from '../../network/NetworkSubscription';
import ObservableSubscription from '../Subscription/ObservableSubscription';
import ReactDOM from 'react-dom';
import SourceValue from './SourceValue/SourceValue';
import Tooltip from '@mui/material/Tooltip';
import clone from 'clone';
import clsx from 'clsx';
import debounce from 'debounce';
import i18n from 'ui-i18n';
import styles from './grid.less';
import withCache from '../Cache/withCache';
import withFeatures from '../Feature/withFeatures';
import {AUTO_GENERATED_CODE_PREFIX, REPLACEABLE_CODE_PREFIX, SCROLL_OFFSET} from '../../constants/common';
import {AutoSizer, GridProps, MultiGrid, MultiGridProps} from 'react-virtualized';
import {BehaviorSubject} from 'rxjs';
import {CACHE_KEYS} from '../../constants/common';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Collaboration, CollaborationObjectType} from '../../rdm-sdk/collaboration.types';
import {
    ColumnItem,
    HiddenColumnsRecord,
    MappingColumnItem,
    MappingIdentifier,
    SelectedMapping,
    SelectedMappingMode
} from '../../rdm-sdk/grid.types';
import {Command} from '../../redux/middlewares/command-middleware';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DetailsOpen, OpenedPanel, SidePanel} from './mappingGrid.types';
import {DraftMode, ScrollData, Sorting} from '../../rdm-sdk/app.types';
import {ElementRef} from 'react';
import {Features} from '../../rdm-sdk/features.types';
import {LAST_ROW_CODE, insertMapping, updateMapping} from '../../rdm-sdk/lookups';
import {LookupValue, SourceMapping} from '../../rdm-sdk/lookups.types';
import {MappingDetailsModal} from '../MappingDetails/MappingDetailsModal';
import {Maybe} from 'monet';
import {SearchHighlightMapping} from './searchHelpers';
import {StateEvent} from '../../rdm-sdk/state.types';
import {UnmappedValue} from '../../rdm-sdk/unmapped.types';
import {ValuesSearchQuery} from '../../rdm-sdk/filters.types';
import {appendValuesEvent, checkCodeUniqueCommand, upsertValueEvent} from '../../redux/actions/values';
import {
    checkClientOnly,
    checkEmpty,
    checkErroneous,
    checkRemoved,
    hasChanges,
    markClientOnly,
    markEdited,
    markErroneous,
    markRemoved
} from '../../core/marks';
import {
    checkIfLookupSourceSelected,
    moveFocusCellDown,
    moveFocusCellLeft,
    moveFocusCellRight,
    moveFocusCellUp
} from './selectHelpers';
import {connect} from 'react-redux';
import {drawerVisibilityEvent} from '../../redux/actions/app';
import {fromNull, fromObject} from '../../core/maybe';
import {getCurrentGenerator} from '../../rdm-sdk/types';
import {getIsActivityLogEnabled} from '../../redux/reducers/activityLogReducer';
import {getIsDCRDisplayed} from '../../redux/selectors/workflowSelectors';
import {getIsLoading} from '../../network/store';
import {getIsWorkflowEnabled, getTasks} from '../../redux/selectors/workflowSelectors';
import {getMappingIdentifier, moveScrollOnColumnAdd} from '../../rdm-sdk/grid';
import {getMappingRelatedObjectUri} from '../../rdm-sdk/collaboration';
import {getOpenedComment} from '../../redux/reducers/uiReducer';
import {getOpenedTask} from '../../redux/reducers/uiReducer';
import {getSearchMappings, getValuesBySourcesStats} from '../../redux/selectors/mainReducerSelectors';
import {getUnmappedTotal} from '../../rdm-sdk/unmapped';
import {inArrayByIndex, prop} from '../../core/lenses';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {
    mappingColumnHideUpdater,
    mappingColumnOrderUpdater,
    mappingColumnResizeUpdater,
    mappingColumnShowUpdater,
    mappingSourceAddUpdater,
    mappingSourcesResetter,
    mappingSourcesUpdater
} from './columnsHelpers';
import {noop} from '../../core/util';
import {searchQueryIsNotEmpty} from './searchHelpers';
import {tap} from '../../core/monet';
import {useCanonicalCellDrop, useReferenceButtonDrop} from './dropHelpers';
const FIRST_COLUMN_NAME = i18n.text('Canonical value');
export const REGULAR_COLUMN_WIDTH = 182;
const FIRST_COLUMN_WIDTH = 220;
const ADD_BUTTON_WIDTH = 48;
const ROW_HEIGHT = 32;
const HEADER_HEIGHT = 48;
export const HEADER_ROWS_COUNT = 1;
export const FIXED_COLUMN_COUNT = 1;
export const BOTTOM_ROWS_COUNT = 1;

const truthy = (value) => !!value;

const STYLE = {
    backgroundColor: 'rgba(250, 251, 251, 1)',
    border: '1px solid #ddd',
    overflow: 'visible'
};
const STYLE_BOTTOM_LEFT_GRID = {
    zIndex: 1,
    backgroundColor: 'rgba(250, 251, 251, 1)'
};
const HEADER = {
    zIndex: 1,
    lineHeight: '64px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(0,0,0,0.2)'
} as MultiGridProps['styleTopRightGrid'];
const LEFT_HEADER = {
    zIndex: 2,
    borderBottom: '1px solid rgba(0,0,0,0.2)'
};
const logger = logActivityCommand('mapping-grid');
const sortingLogger = logger('sorting-change');
const mappingSourceAddLogger = logger('add-source-click');
const addCanonicalValueLogger = logger('add-canonical-value-click');
const changeMappingLogger = logger('mapping-change');
const addRowLogger = logger('add-row-click');
const removeSourceMappingLogger = logger('remove-source-mapping');
const loadOnScrollLogger = logger('load-on-scroll');
const keydownLogger = logger('keydown');
const resizeColumnLogger = logger('resize-column');
const reorderColumnLogger = logger('reorder-column');

type CanonicalCellProps = {
    value: LookupValue;
    searchQuery: ValuesSearchQuery;
    onCanonicalClick: (value: LookupValue) => void;
    isDCRDisplayed: boolean;
    logger: (action: string) => (...args: any) => Command;
    dispatch: (e: StateEvent) => void;
    type: string | null;
    unmappedValues: UnmappedValue[];
    loadMoreUnmapped: (unmappedValue: UnmappedValue, onLoaded?: () => void) => void;
    validateLookupValue: (lookupValue: LookupValue) => LookupValue;
};

export const CanonicalCell = ({
    value,
    searchQuery,
    onCanonicalClick,
    isDCRDisplayed,
    logger,
    dispatch,
    type,
    unmappedValues,
    loadMoreUnmapped,
    validateLookupValue
}: CanonicalCellProps) => {
    const [{isOver, canDrop, monitorId}, drop] = useCanonicalCellDrop({
        value,
        logger,
        dispatch,
        type,
        unmappedValues,
        loadMoreUnmapped,
        validateLookupValue
    });

    const highlightCell = !checkClientOnly(value) && searchQueryIsNotEmpty(searchQuery);
    const isRemoved = checkRemoved(value);
    const needHightlightChange = isDCRDisplayed && hasChanges(value);
    return (
        <div
            ref={drop}
            data-monitorid={monitorId}
            className={clsx(
                styles['canonical-box'],
                checkErroneous(value) && styles['mapping-grid__error'],
                isOver && canDrop && styles['canonical-box--drag-area'],
                needHightlightChange && styles['canonical-box--dcr-marked'],
                'grid-cell__item_canonical'
            )}
            onClick={() => (!isRemoved ? onCanonicalClick(value) : noop)}
            style={
                isRemoved
                    ? {
                          cursor: 'default'
                      }
                    : undefined
            }
        >
            <SourceValue
                isRemoved={isRemoved}
                isCanonical={true}
                value={value.canonical}
                searchQuery={searchQuery}
                highlightCell={highlightCell}
            />
            <MarkLabel entity={value} position="right" className={styles['mark-label']} />
            <div
                className={clsx(
                    styles['mapping-grid__ellipsis'],
                    checkErroneous(value) && styles['mapping-grid__ellipsis--error'],
                    isOver && canDrop && styles['mapping-grid__ellipsis--drag-area'],
                    needHightlightChange && styles['mapping-grid__ellipsis--dcr']
                )}
            />
        </div>
    );
};
export const AddReferenceButton = ({
    onAddCanonicalClick,
    onAddCanonicalDrop,
    dispatch,
    currentType,
    configuration,
    unmappedValues,
    loadMoreUnmapped,
    checkCodeUnique
}) => {
    const [{isOver, monitorId}, drop] = useReferenceButtonDrop({
        logger,
        onAddCanonicalDrop,
        dispatch,
        currentType,
        configuration,
        unmappedValues,
        loadMoreUnmapped,
        checkCodeUnique
    });

    const container = document.getElementById('status-line-menu-items');
    const button = (
        <div
            ref={drop}
            data-monitorid={monitorId}
            style={{
                backgroundColor: isOver ? 'rgba(43,152,240,0.1)' : 'white'
            }}
        >
            <Button color="primary" onClick={onAddCanonicalClick}>
                <Icon>add</Icon> {i18n.text('CANONICAL VALUE ROW')}
            </Button>
        </div>
    );
    return container ? ReactDOM.createPortal(button, container) : false;
};
export const AddRowButton = ({style, onClick}: Record<string, any>) => (
    <Tooltip title={i18n.text('Add canonical value')}>
        <div
            className={clsx(styles['canonicalCell'], styles['canonicalCell__add-button'])}
            style={style}
            onClick={onClick}
        >
            <IconButton color="primary" size="large">
                <Icon>add</Icon>
            </IconButton>
        </div>
    </Tooltip>
);
const EMPTY_STATE = (
    <div
        style={{
            position: 'relative',
            width: `calc(100% - ${REGULAR_COLUMN_WIDTH}px)`,
            height: 'calc(100vh - 128px)',
            left: `${REGULAR_COLUMN_WIDTH}px`,
            pointerEvents: 'none'
        }}
    >
        <div className={'empty-state'}>
            <div
                className={'empty-state__icon'}
                dangerouslySetInnerHTML={{
                    __html: MappingEmptyIcon
                }}
            />
            <p className={'empty-state__header'}>
                {i18n.text("You don't have any source value mappings for this lookup type.")}
                <br />
                {i18n.text('Add a canonical value row to add source value mapping.')}
            </p>
        </div>
    </div>
);
export const DropOverlay = () => (
    <div
        style={{
            position: 'absolute',
            left: FIRST_COLUMN_WIDTH,
            top: -2 * HEADER_HEIGHT,
            width: 3000,
            height: `calc(100% + ${2 * HEADER_HEIGHT}px)`,
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 1
        }}
    />
);

const getColumnWidth =
    (len: number, sources: MappingColumnItem[]) =>
    ({index}) => {
        if (index === 0) return FIRST_COLUMN_WIDTH;
        if (index === len - 1) return ADD_BUTTON_WIDTH;
        const source = sources[index - FIXED_COLUMN_COUNT];
        return source ? source.columnWidth : REGULAR_COLUMN_WIDTH;
    };

const getDetailsOpen = (detailsOpen, lookupValues) => {
    if (detailsOpen) {
        const code = R.path(['entry', 'code'], detailsOpen);
        const lookupValue = R.find(R.propEq('code', code), lookupValues);
        return lookupValue ? R.assoc('entry', lookupValue, detailsOpen) : detailsOpen;
    } else {
        return null;
    }
};

type GridRef = {
    _bottomLeftGrid: GridProps;
    _bottomRightGrid: GridProps;
    _topRightGrid: GridProps;
    recomputeGridSize: () => void;
};

type GridCellRendererProps = {
    columnIndex: number;
    key: string;
    rowIndex: number;
    style: React.CSSProperties;
};

export type MappingProps = {
    tenant: string;
    dispatch: (e: StateEvent) => Promise<any>;
    drawerVisible: boolean;
    sorting: Sorting;
    lookupValues: LookupValue[];
    configuration: Configuration;
    draftMode: DraftMode;
    policies: CheckedPolicies;
    query: ValuesSearchQuery;
    isTaskOpen: boolean;
    isTaskLoaded: boolean;
    loadMoreMapped: (callback?: (...args: any[]) => any) => void;
    loadUnmapped: () => void;
    loadMoreUnmapped: (unmappedValue: UnmappedValue, onLoaded?: () => void) => void;
    openReferenceEditWithCallback: (ref: LookupValue, onDone: (ref: LookupValue) => void) => void;
    currentType: string | null;
    availableSources: string[];
    unmappedValues: UnmappedValue[];
    unmappedTotal: number;
    onCanonicalValueSort: () => void;
    showValues: boolean;
    updateLookupValue: (code: string, lookupValue: LookupValue) => void;
    validateLookupValue: (lookupValue: LookupValue) => LookupValue;
    valuesBySourcesStats: Record<string, number>;
    collaboration: Collaboration;
    onScroll?: (arg0: ScrollData) => void;
    gridRef?: (arg0: ElementRef<typeof MultiGrid>) => void;
    scrollToRow: number;
    checkCodeUnique: (code: string) => Promise<boolean>;
    searchMappings?: SearchHighlightMapping[] | null;
    cacheData: {
        sources: MappingColumnItem[];
        extraSources: ColumnItem[];
        hiddenColumns: HiddenColumnsRecord[];
    };
    setCacheData: (arg0: {
        sources: MappingColumnItem[];
        extraSources: ColumnItem[];
        hiddenColumns: HiddenColumnsRecord[];
    }) => void;
    features: Features;
    isActivityLogEnabled: boolean;
    isWorkflowEnabled: boolean;
    collaborationOptions: {
        objectType: string;
        relatedObjectUri: string;
    };
    isEditMode: boolean;
    isDCRDisplayed: boolean;
    isCommentOpened: boolean;
};
type getUnmappedOpenDefaultType = {
    lookupValues: LookupValue[];
    unmappedTotal: number;
    query: ValuesSearchQuery;
};
type PropsToTrack = {
    lookupValues: LookupValue[];
    draftMode: DraftMode;
    availableSources: string[];
};
export type State = {
    isDragging: boolean;
    detailsOpen?: DetailsOpen;
    editorOpen: boolean;
    editorCode: string;
    editorSource: string;
    openedPanel: OpenedPanel;
    shouldApplyUnmappedOpenDefault: boolean;
    selectedColumn?: number | null;
    propsToTrack: PropsToTrack;
    sources: MappingColumnItem[];
    extraSources: ColumnItem[];
    hiddenColumns: HiddenColumnsRecord[];
};

const getUnmappedOpenDefault = ({lookupValues, unmappedTotal, query}: getUnmappedOpenDefaultType): boolean =>
    lookupValues.length === 0 && unmappedTotal > 0 && !searchQueryIsNotEmpty(query);

export class MappingGrid extends Component<MappingProps, State> {
    static defaultProps = {
        loadMoreMapped: R.identity
    };
    _grid?: typeof MultiGrid & GridRef;
    drawerVisible: boolean;
    selectedMapping$: BehaviorSubject<SelectedMapping | null>;

    constructor(props: MappingProps) {
        super(props);
        this.state = {
            detailsOpen: undefined,
            editorOpen: false,
            editorCode: '',
            editorSource: '',
            openedPanel: null,
            shouldApplyUnmappedOpenDefault: true,
            isDragging: false,
            selectedColumn: null,
            propsToTrack: {
                availableSources: [],
                lookupValues: [],
                draftMode: this.props.draftMode
            },
            sources: R.pathOr([], ['cacheData', 'sources'], props),
            extraSources: R.pathOr([], ['cacheData', 'extraSources'], props),
            hiddenColumns: R.pathOr([], ['cacheData', 'hiddenColumns'], props)
        };
        this.drawerVisible = props.drawerVisible;
        // @ts-ignore
        this.selectedMapping$ = new BehaviorSubject(null);
    }

    componentDidMount() {
        this.props.dispatch(drawerVisibilityEvent(false));

        if (this.state.shouldApplyUnmappedOpenDefault && getUnmappedOpenDefault(this.props)) {
            this.setOpenedPanel(SidePanel.UNMAPPED);
        }

        document.body && document.body.addEventListener('keydown', this.onBodyKeyDown);
    }

    static getDerivedStateFromProps(nextProps: MappingProps, prevState: State) {
        const {lookupValues, draftMode, isDCRDisplayed, availableSources} = nextProps;
        const {
            detailsOpen,
            openedPanel,
            shouldApplyUnmappedOpenDefault,
            propsToTrack: {
                lookupValues: prevLookupValues,
                availableSources: prevAvailableSources,
                draftMode: prevDraftMode
            }
        } = prevState;
        const propsToTrack = {
            lookupValues,
            draftMode,
            availableSources
        };
        const stateChanges = {} as State;
        stateChanges.propsToTrack = propsToTrack;
        let shouldUpdateState = false;
        const isUnmappedPanelOpen = openedPanel === SidePanel.UNMAPPED;

        if (shouldApplyUnmappedOpenDefault && isUnmappedPanelOpen !== getUnmappedOpenDefault(nextProps)) {
            stateChanges.openedPanel = isUnmappedPanelOpen ? null : SidePanel.UNMAPPED;
            shouldUpdateState = true;
        }

        if (isDCRDisplayed && !stateChanges.openedPanel) {
            stateChanges.openedPanel = SidePanel.WORKFLOW;
            shouldUpdateState = true;
        }

        if (lookupValues !== prevLookupValues) {
            stateChanges.detailsOpen = getDetailsOpen(detailsOpen, lookupValues);
            shouldUpdateState = true;
        }

        if (prevDraftMode.dirtyValues && !draftMode.dirtyValues) {
            return {
                ...stateChanges,
                ...mappingSourcesResetter(prevState, nextProps)
            };
        } else if (prevLookupValues !== lookupValues || availableSources !== prevAvailableSources) {
            return {
                ...stateChanges,
                ...mappingSourcesUpdater(prevState, nextProps)
            };
        } else {
            return shouldUpdateState ? stateChanges : null;
        }
    }

    componentDidUpdate(prevProps: MappingProps, prevState: State) {
        const conditions = [
            this.state.openedPanel !== prevState.openedPanel,
            this.props.lookupValues !== prevProps.lookupValues,
            this.state.sources !== prevState.sources,
            this.state.hiddenColumns !== prevState.hiddenColumns
        ];

        if (conditions.some(R.identity) && this._grid) {
            this._grid.recomputeGridSize();
        }

        if (!this.state.openedPanel && this.props.isTaskOpen && this.props.isTaskLoaded)
            this.setOpenedPanel(SidePanel.WORKFLOW as OpenedPanel);
    }

    setEditorCell = (identifier: MappingIdentifier) => {
        if (this.props.isEditMode) {
            this.setSelectedMapping({
                identifier,
                mode: SelectedMappingMode.EDIT
            });
        }
    };
    setFocusCell = (identifier: MappingIdentifier) => {
        if (this.props.isEditMode) {
            this.setSelectedMapping({
                identifier,
                mode: SelectedMappingMode.FOCUS
            });
        }
    };
    resetSelectedMapping = () => this.setSelectedMapping(null);
    changeSelectedMappingMode = (mode: SelectedMappingMode) => {
        const selectedMapping = this.getSelectedMapping();

        if (selectedMapping) {
            this.setSelectedMapping(R.assoc('mode', mode, selectedMapping));
        }
    };
    getFocusCell = () => {
        const selectedMapping = this.getSelectedMapping();
        return selectedMapping && selectedMapping.mode === SelectedMappingMode.FOCUS
            ? selectedMapping.identifier
            : null;
    };
    getSelectedMapping = () => this.selectedMapping$.getValue();
    setSelectedMapping = (value: SelectedMapping | null) => this.selectedMapping$.next(value);
    getFocusNextCellClb = () => {
        const focusCell = this.getFocusCell();

        if (focusCell && focusCell.code === LAST_ROW_CODE) {
            const {source} = focusCell;
            const nextRowIndex = this.props.lookupValues.length;
            return () => {
                const {code} = this.props.lookupValues[nextRowIndex];
                this.setFocusCell({
                    source,
                    code,
                    mappingIndex: 0
                });
            };
        } else {
            return noop;
        }
    };

    componentWillUnmount() {
        this.props.dispatch(drawerVisibilityEvent(this.drawerVisible));
        this.resetSelectedMapping();
        document.body && document.body.removeEventListener('keydown', this.onBodyKeyDown);
        this.props.setCacheData(R.pick(['sources', 'hiddenColumns', 'extraSources'], this.state));
    }

    scrollTop = (to = 0) => {
        const scrollingContainer = this?._grid?._bottomRightGrid._scrollingContainer;
        scrollingContainer!.scrollTop = Math.min(to, scrollingContainer?.scrollHeight || 0);
    };
    onSortClick = () => {
        const {
            dispatch,
            onCanonicalValueSort,
            sorting: {direction}
        } = this.props;
        onCanonicalValueSort();
        dispatch(
            sortingLogger({
                field: 'value',
                direction
            }) as StateEvent
        );
        this.scrollTop();
        this.resetSelectedMapping();
    };
    setIsDragging = (isDragging: boolean): void => {
        if (isDragging) {
            const _scrollingContainer = this?._grid?._bottomRightGrid._scrollingContainer;
            _scrollingContainer!.scrollLeft = 0;
        }

        this.setState({
            isDragging
        });
    };
    setOpenedPanel = (openedPanel: OpenedPanel): void => {
        this.setState({
            openedPanel,
            shouldApplyUnmappedOpenDefault: false
        });
    };
    setSelectedColumn = (selectedColumn?: number | null) => {
        const {selectedColumn: prevSelectedColumn} = this.state;

        if (selectedColumn !== prevSelectedColumn) {
            this.setState({
                selectedColumn
            });
        }
    };
    changeColumnOrder = (sourceIndex: number, targetIndex: number) => {
        this.setState(mappingColumnOrderUpdater(sourceIndex, targetIndex), () => this.setSelectedColumn(targetIndex));
    };
    onColumnAdd = (item: ColumnItem, index = -1) => {
        this.props.dispatch(
            mappingSourceAddLogger({
                item,
                index
            }) as StateEvent
        );
        this.setState(mappingSourceAddUpdater(item, index), () => moveScrollOnColumnAdd(this._grid, index));
    };
    onColumnHide = (label: string, columnIndex: number) => this.setState(mappingColumnHideUpdater(label, columnIndex));
    onColumnShow = (columnIndex: number) => this.setState(mappingColumnShowUpdater(columnIndex));
    onColumnResize = (columnIndex: number, deltaX: number) =>
        this.setState(mappingColumnResizeUpdater(columnIndex, deltaX));
    getDefaultLookupValue = () => {
        const {currentType, configuration} = this.props;
        const isGeneratorEnabled = Boolean(getCurrentGenerator(configuration, currentType));
        return markClientOnly({
            code: isGeneratorEnabled ? AUTO_GENERATED_CODE_PREFIX + Date.now() : REPLACEABLE_CODE_PREFIX + Date.now(),
            canonical: '',
            startDate: 0,
            endDate: 0,
            sources: {},
            enabled: true
        });
    };
    onAddCanonicalClick = () => {
        const {dispatch, currentType} = this.props;
        const sourceAbbreviation = R.pathOr(null, [0, 'abbreviation'], this.state.sources);
        const lookupValue = sourceAbbreviation
            ? insertMapping(sourceAbbreviation, this.getDefaultLookupValue(), markClientOnly(DEFAULT_MAPPING))
            : this.getDefaultLookupValue();
        dispatch(upsertValueEvent(currentType, null, lookupValue));
        dispatch(
            addCanonicalValueLogger({
                currentType
            }) as StateEvent
        );
        this.scrollTop(0);
        setTimeout(() => {
            this?._grid?._bottomRightGrid._scrollingContainer.focus(); // removing focus from the button

            if (sourceAbbreviation) {
                this.setEditorCell(getMappingIdentifier(lookupValue.code, sourceAbbreviation, 0));
            }
        });
    };
    onAddRowButtonClick = () => {
        const {dispatch, currentType} = this.props;
        const [source] = this.state.sources;

        if (source && source.abbreviation) {
            this.onAddRowCellClick(source.abbreviation);
            return;
        }

        dispatch(appendValuesEvent([this.getDefaultLookupValue()]));
        setTimeout(() => this.scrollTop(Infinity));
        dispatch(
            addRowLogger({
                currentType
            }) as StateEvent
        );
    };
    onAddRowCellClick = (sourceAbbreviation: string) => {
        const {dispatch, currentType} = this.props;
        const lookupValue = insertMapping(
            sourceAbbreviation,
            this.getDefaultLookupValue(),
            markClientOnly(DEFAULT_MAPPING)
        );
        dispatch(appendValuesEvent([lookupValue]));
        dispatch(
            addRowLogger({
                currentType
            }) as StateEvent
        );
        const currentCell = getMappingIdentifier(lookupValue.code, sourceAbbreviation, 0);
        setTimeout(() => {
            this.scrollTop(Infinity);
            this.setEditorCell(currentCell);
        });
    };
    getRowHeight = ({index}: {index: number}) => {
        const {lookupValues} = this.props;
        return index < HEADER_ROWS_COUNT
            ? HEADER_HEIGHT
            : fromObject(lookupValues[index - HEADER_ROWS_COUNT])
                  .flatMap((value) => fromObject(value.sources))
                  .map(
                      (sources) =>
                          Math.max(
                              1,
                              ...Object.values(sources)
                                  .filter(truthy)
                                  .map((sources: any) => sources.length)
                          ) * ROW_HEIGHT
                  )
                  .orSome(ROW_HEIGHT);
    };
    getColumnHeight = R.pipe(
        R.range(HEADER_ROWS_COUNT),
        R.map((index) =>
            this.getRowHeight({
                index
            })
        ),
        R.sum
    );
    onDetailsOpen = R.curry(
        (source, index, value, searchMapping) => (_event) =>
            this.setState({
                detailsOpen: {
                    entry: clone(value),
                    source,
                    index,
                    searchMapping
                }
            })
    );
    onDetailsClose = () =>
        this.setState({
            detailsOpen: undefined
        });
    logColumnResize = R.compose(this.props.dispatch, resizeColumnLogger);
    logColumnReorder = R.compose(this.props.dispatch, reorderColumnLogger);
    openReferenceModal = (lookupValue: LookupValue, callback: (...args: any[]) => any = noop) => {
        const {openReferenceEditWithCallback} = this.props;
        this.resetSelectedMapping();
        openReferenceEditWithCallback(lookupValue, callback);
    };
    updateSourceMapping = (
        sourceAbbreviation: string,
        mappingIndex: number,
        lookupValue: LookupValue,
        mapping: SourceMapping
    ) => {
        const {dispatch, currentType, updateLookupValue, validateLookupValue, checkCodeUnique} = this.props;
        const updatedLookupValue = updateMapping(sourceAbbreviation, mappingIndex, lookupValue, mapping);

        if (updatedLookupValue.code !== lookupValue.code) {
            this.setFocusCell(getMappingIdentifier(updatedLookupValue.code, sourceAbbreviation, mappingIndex));
            checkCodeUnique(updatedLookupValue.code).then((isCodeUnique) => {
                if (isCodeUnique) {
                    updateLookupValue(lookupValue.code, updatedLookupValue);
                } else {
                    const validatedValue = R.pipe(validateLookupValue, markErroneous(true), (value) =>
                        Object.assign(value, {
                            code: lookupValue.code
                        })
                    )(updatedLookupValue);
                    dispatch(upsertValueEvent(currentType, lookupValue.code, markEdited(validatedValue)));
                }
            });
        } else {
            updateLookupValue(lookupValue.code, updatedLookupValue);
        }

        dispatch(
            changeMappingLogger({
                value: {
                    code: lookupValue.code,
                    canonical: lookupValue.canonical
                },
                mappingIndex,
                mapping
            }) as StateEvent
        );
    };
    insertSourceMapping = (sourceAbbreviation: string, lookupValue: LookupValue, mapping: SourceMapping) => {
        const {dispatch, currentType} = this.props;
        dispatch(
            upsertValueEvent(
                currentType,
                lookupValue.code,
                insertMapping(sourceAbbreviation, lookupValue, markClientOnly(mapping))
            )
        );
        const insertedMappingIndex = R.pathOr(0, ['sources', sourceAbbreviation, 'length'], lookupValue);
        const currentCell = getMappingIdentifier(lookupValue.code, sourceAbbreviation, insertedMappingIndex);
        setTimeout(() => {
            this.setEditorCell(currentCell);
        });
    };
    removeSourceMapping = (sourceAbbreviation: string, mappingIndex: number, lookupValue: LookupValue) => {
        const {updateLookupValue, dispatch, currentType} = this.props;
        const sourceL = prop('sources').compose(prop(sourceAbbreviation));
        const mappingL = sourceL.compose(inArrayByIndex(mappingIndex));

        const updateMapping = (mapping) => updateLookupValue(lookupValue.code, mappingL.set(lookupValue, mapping));

        const safeMapping = fromNull(mappingL.get(lookupValue));
        tap(
            R.ifElse(
                checkEmpty,
                R.pipe(
                    R.always(sourceL.set(lookupValue, R.remove(mappingIndex, 1, sourceL.get(lookupValue)))),
                    upsertValueEvent(currentType, lookupValue.code),
                    dispatch
                ),
                R.pipe(
                    markRemoved,
                    updateMapping,
                    R.always({
                        sourceAbbreviation,
                        mappingIndex,
                        value: {
                            code: lookupValue.code,
                            canonical: lookupValue.canonical
                        }
                    }),
                    removeSourceMappingLogger,
                    dispatch
                )
            ),
            safeMapping
        );
    };
    onCellContainerClick = () => this.setSelectedColumn(null);
    cellRenderer =
        ({
            columnNames,
            gridHeight,
            visibleSources,
            fixedColumnCount,
            rowCount,
            searchMappings,
            collaborationOptions
        }: Record<string, any>) =>
        // eslint-disable-next-line react/display-name, complexity, prettier/prettier
        (props: GridCellRendererProps) => { // NOSONAR
            const {columnIndex, key, rowIndex, style} = props as GridCellRendererProps;
            const {
                dispatch,
                lookupValues,
                sorting,
                draftMode,
                query: searchQuery,
                loadMoreUnmapped,
                currentType,
                unmappedValues,
                valuesBySourcesStats,
                collaboration,
                validateLookupValue,
                isEditMode,
                isDCRDisplayed
            } = this.props;
            const {selectedColumn, extraSources, hiddenColumns} = this.state;
            const getLeftOrRight = () => (columnIndex < Number(selectedColumn) ? 'left' : 'right');
            const relativeToSelectedColumn = columnIndex === selectedColumn ? 'center' : getLeftOrRight();
            const isSelected = columnIndex === selectedColumn;
            const isLastColumn = columnIndex === columnNames.length - FIXED_COLUMN_COUNT;
            const columnName = columnNames[columnIndex];
            const source = visibleSources[columnIndex - FIXED_COLUMN_COUNT];
            const lookupValue = lookupValues[rowIndex - HEADER_ROWS_COUNT];
            const searchMapping = R.propOr(null, rowIndex - HEADER_ROWS_COUNT, searchMappings);
            const columnWidth = getColumnWidth(
                columnNames.length,
                visibleSources
            )({
                index: columnIndex
            });
            const shouldRenderLastRowCell =
                rowIndex === rowCount - 1 &&
                isEditMode &&
                columnIndex !== columnNames.length - FIXED_COLUMN_COUNT &&
                columnIndex !== 0;
            const cell = fromObject(lookupValue)
                .map((lookupValue: LookupValue) => {
                    if (columnIndex === 0) {
                        return (
                            <CanonicalCell
                                key={lookupValue.code}
                                logger={logger}
                                value={lookupValue}
                                searchQuery={searchQuery}
                                dispatch={dispatch}
                                type={currentType}
                                unmappedValues={unmappedValues}
                                loadMoreUnmapped={loadMoreUnmapped}
                                onCanonicalClick={this.openReferenceModal}
                                validateLookupValue={validateLookupValue}
                                isDCRDisplayed={isDCRDisplayed}
                            />
                        );
                    } else if (columnIndex === columnNames.length - FIXED_COLUMN_COUNT) {
                        return false;
                    } else {
                        const mappingHasSelection = checkIfLookupSourceSelected(lookupValue.code, source.abbreviation);
                        const selectedMappingObservable = this.selectedMapping$
                            .pairwise()
                            .filter(
                                ([prev, curr]) =>
                                    (Boolean(curr) && mappingHasSelection(curr)) ||
                                    (Boolean(prev) && mappingHasSelection(prev))
                            )
                            .map(([_, curr]) => curr);
                        return (
                            <ObservableSubscription
                                key={lookupValue.code}
                                source={selectedMappingObservable}
                                initialValue={this.getSelectedMapping()}
                            >
                                {(selectedMapping: SelectedMapping) => {
                                    const selectedIndex =
                                        selectedMapping && mappingHasSelection(selectedMapping)
                                            ? selectedMapping.identifier.mappingIndex
                                            : null;
                                    const focusCellIndex =
                                        selectedIndex !== null && selectedMapping.mode === SelectedMappingMode.FOCUS
                                            ? selectedIndex
                                            : null;
                                    const editorCellIndex =
                                        selectedIndex !== null && selectedMapping.mode === SelectedMappingMode.EDIT
                                            ? selectedIndex
                                            : null;
                                    return (
                                        <MappingCellGroup
                                            dispatch={dispatch}
                                            currentType={currentType}
                                            lookupValue={lookupValue}
                                            source={source}
                                            onDetailsOpen={this.onDetailsOpen}
                                            updateSourceMapping={this.updateSourceMapping}
                                            insertSourceMapping={this.insertSourceMapping}
                                            removeSourceMapping={this.removeSourceMapping}
                                            searchQuery={searchQuery}
                                            searchMapping={searchMapping}
                                            columnWidth={columnWidth}
                                            relativeToSelectedColumn={relativeToSelectedColumn}
                                            columnIndex={columnIndex}
                                            changeColumnOrder={this.changeColumnOrder}
                                            isColumnSelected={isSelected}
                                            collaborationOptions={collaborationOptions}
                                            collaboration={collaboration[CollaborationObjectType.MAPPING_GROUP]}
                                            logColumnReorder={this.logColumnReorder}
                                            setFocusCell={this.setFocusCell}
                                            changeSelectedMappingMode={this.changeSelectedMappingMode}
                                            setEditorCell={this.setEditorCell}
                                            resetSelectedMapping={this.resetSelectedMapping}
                                            focusCellIndex={focusCellIndex}
                                            editorCellIndex={editorCellIndex}
                                            isEditMode={isEditMode}
                                        />
                                    );
                                }}
                            </ObservableSubscription>
                        );
                    }
                })
                .orElse(
                    shouldRenderLastRowCell
                        ? Maybe.Some(
                              <ObservableSubscription
                                  source={this.selectedMapping$
                                      .pairwise()
                                      .filter(([prev, curr]) => {
                                          const mappingHasSelection = checkIfLookupSourceSelected(
                                              LAST_ROW_CODE,
                                              source.abbreviation
                                          );
                                          return (
                                              (Boolean(curr) && mappingHasSelection(curr)) ||
                                              (Boolean(prev) && mappingHasSelection(prev))
                                          );
                                      })
                                      .map(([_, curr]) => curr)}
                                  initialValue={this.getSelectedMapping()}
                              >
                                  {(selectedMapping: SelectedMapping) => (
                                      <InvisibleCell
                                          onClick={() => {
                                              this.onAddRowCellClick(source.abbreviation);
                                              this.setFocusCell(
                                                  getMappingIdentifier(LAST_ROW_CODE, source.abbreviation, 0)
                                              );
                                          }}
                                          isFocusCell={
                                              selectedMapping &&
                                              checkIfLookupSourceSelected(
                                                  LAST_ROW_CODE,
                                                  source.abbreviation,
                                                  selectedMapping
                                              ) &&
                                              selectedMapping.mode === SelectedMappingMode.FOCUS
                                          }
                                      />
                                  )}
                              </ObservableSubscription>
                          )
                        : Maybe.fromNull(false)
                );

            if (rowIndex === 0 && isLastColumn) {
                return extraSources.length && isEditMode ? (
                    <GridColumnsMenu style={style} key={key} items={extraSources} onClick={this.onColumnAdd} />
                ) : (
                    false
                );
            } else if (rowIndex === 0) {
                return (
                    <GridHeader
                        onSortClick={draftMode.dirtyValues ? noop : this.onSortClick}
                        onResize={this.onColumnResize}
                        sorting={sorting}
                        isEditMode={isEditMode}
                        label={columnName}
                        style={style}
                        key={key}
                        changeColumnOrder={this.changeColumnOrder}
                        setSelectedColumn={this.setSelectedColumn}
                        columnIndex={columnIndex}
                        columnHeight={Math.min(gridHeight - HEADER_HEIGHT, this.getColumnHeight(rowCount))}
                        columnWidth={columnWidth}
                        isSelected={relativeToSelectedColumn === 'center'}
                        extraItems={extraSources}
                        onColumnAdd={this.onColumnAdd}
                        onColumnHide={this.onColumnHide}
                        onColumnShow={this.onColumnShow}
                        hiddenColumns={hiddenColumns}
                        fixedColumnCount={fixedColumnCount}
                        lookupValuesCount={valuesBySourcesStats[R.prop('abbreviation', source)]}
                        logColumnResize={this.logColumnResize}
                        logColumnReorder={this.logColumnReorder}
                    />
                );
            } else if (rowIndex === rowCount - 1 && columnIndex === 0 && isEditMode) {
                return <AddRowButton key={key} style={style} onClick={this.onAddRowButtonClick} />;
            } else {
                return (
                    <div
                        className={clsx(
                            columnIndex === 0 ? styles.canonicalCell : !isLastColumn && styles['mapping-cell-group'],
                            isSelected && styles['mapping-cell-group--selected']
                        )}
                        key={key}
                        style={style}
                        onClick={this.onCellContainerClick}
                    >
                        {(!isEditMode || rowIndex >= HEADER_ROWS_COUNT) && cell.orSome(false)}
                    </div>
                );
            }
        };
    syncScrolls = debounce(() => {
        if (this._grid) {
            const bRGrid = this._grid?._bottomRightGrid;
            const tRGrid = this._grid?._topRightGrid;
            const bLGrid = this._grid?._bottomLeftGrid;

            if (
                bRGrid &&
                !bRGrid.state.isScrolling &&
                bRGrid._scrollingContainer.scrollLeft > tRGrid._scrollingContainer.scrollLeft
            ) {
                bRGrid._scrollingContainer.scrollLeft = tRGrid._scrollingContainer.scrollLeft;
            }

            if (
                bLGrid &&
                !bLGrid.state.isScrolling &&
                bLGrid._scrollingContainer.scrollTop > bRGrid._scrollingContainer.scrollTop
            ) {
                bLGrid._scrollingContainer.scrollTop = bRGrid._scrollingContainer.scrollTop;
            }
        }
    }, 200);
    // eslint-disable-next-line complexity, prettier/prettier
    scrollToMappingIdentifier = ({source, code, mappingIndex}: MappingIdentifier) => { // NOSONAR
        const columnIndex = R.findIndex(R.propEq('abbreviation', source), this.state.sources);
        const rowIndex = R.findIndex(R.propEq('code', code), this.props.lookupValues);

        const {scrollLeft} =
            this?._grid?._bottomRightGrid.getOffsetForCell({
                columnIndex,
                alignment: 'auto'
            }) || {};

        const {scrollTop: cellTopAlignmentStart} =
            this?._grid?._bottomRightGrid.getOffsetForCell({
                rowIndex,
                alignment: 'start'
            }) || {};

        const {scrollTop: cellTopAlignmentEnd} =
            this?._grid?._bottomRightGrid.getOffsetForCell({
                rowIndex: rowIndex - 1,
                alignment: 'end'
            }) || {};

        const {scrollTop: cellBottomAlignmentEnd} =
            this?._grid?._bottomRightGrid.getOffsetForCell({
                rowIndex,
                alignment: 'end'
            }) || {};

        const {
            clientHeight,
            scrollTop: gridScrollTop,
            scrollHeight
        } = this?._grid?._bottomRightGrid._scrollingContainer || {};
        const canAlignToStart =
            cellTopAlignmentStart &&
            scrollHeight &&
            clientHeight &&
            cellTopAlignmentStart < scrollHeight - clientHeight;
        const canAlignToEnd = cellBottomAlignmentEnd && cellBottomAlignmentEnd > 0;
        let scrollTop = gridScrollTop;

        if (canAlignToStart) {
            const mappingTop = cellTopAlignmentStart + mappingIndex * ROW_HEIGHT;

            if (gridScrollTop && mappingTop < gridScrollTop) {
                scrollTop = mappingTop;
            } else if (gridScrollTop && mappingTop + ROW_HEIGHT > gridScrollTop + clientHeight) {
                scrollTop = mappingTop + ROW_HEIGHT - clientHeight;
            }
        } else if (canAlignToEnd) {
            const mappingTop = Number(cellTopAlignmentEnd) + (mappingIndex + 1) * ROW_HEIGHT;

            if (gridScrollTop && mappingTop > gridScrollTop) {
                scrollTop = mappingTop;
            }
        }

        this?._grid?._bottomRightGrid.scrollToPosition({
            scrollTop: Number(scrollTop),
            scrollLeft: Number(scrollLeft)
        });
    };
    scrollToFocusCell = R.pipe(this.getFocusCell, this.scrollToMappingIdentifier);
    // eslint-disable-next-line complexity, prettier/prettier
    onBodyKeyDown = (event: Record<string, any>) => { // NOSONAR
        const {dispatch, lookupValues} = this.props;
        const {sources} = this.state;
        const focusCell = this.getFocusCell();

        if (!focusCell || this.hotKeysLocked()) {
            return;
        }

        const lookupValue = R.find(R.propEq('code', focusCell.code), lookupValues);
        const mappings = R.pathOr([], ['sources', focusCell.source], lookupValue);
        const mapping = mappings[focusCell.mappingIndex];
        const selectedMapping = this.getSelectedMapping();

        switch (event.key) {
            case 'ArrowLeft':
            case 'Left':
                this.setSelectedMapping(moveFocusCellLeft(lookupValues, sources, selectedMapping));
                this.scrollToFocusCell();
                break;

            case 'ArrowRight':
            case 'Right':
                this.setSelectedMapping(moveFocusCellRight(lookupValues, sources, selectedMapping));
                this.scrollToFocusCell();
                break;

            case 'ArrowUp':
            case 'Up':
                this.setSelectedMapping(moveFocusCellUp(lookupValues, selectedMapping));
                this.scrollToFocusCell();
                break;

            case 'ArrowDown':
            case 'Down':
                this.setSelectedMapping(moveFocusCellDown(lookupValues, selectedMapping));
                this.scrollToFocusCell();
                break;

            case 'Enter': {
                this.scrollToMappingIdentifier(focusCell);

                if (focusCell.code === LAST_ROW_CODE) {
                    this.onAddRowCellClick(focusCell.source);
                    return;
                }

                if (mapping) {
                    this.setEditorCell(focusCell);
                } else if (lookupValue) {
                    this.insertSourceMapping(focusCell.source, lookupValue, DEFAULT_MAPPING);
                }

                break;
            }

            case 'Insert':
            case 'i':
                if (focusCell.code === LAST_ROW_CODE) {
                    this.scrollToMappingIdentifier(focusCell);
                    this.onAddRowCellClick(focusCell.source);
                } else {
                    this.scrollToMappingIdentifier(R.assoc('mappingIndex', mappings.length, focusCell));
                    this.insertSourceMapping(focusCell.source, lookupValue, DEFAULT_MAPPING);
                }

                break;

            case 'Delete':
            case 'Del':
            case 'Backspace':
                event.preventDefault();

                if (focusCell.code !== LAST_ROW_CODE && mapping && !mapping.canonicalValue) {
                    this.removeSourceMapping(focusCell.source, focusCell.mappingIndex, lookupValue);
                    this.setSelectedMapping(moveFocusCellDown(lookupValues, selectedMapping));
                    this.scrollToFocusCell();
                } else {
                    this.scrollToMappingIdentifier(focusCell);
                }

                break;
        }

        if (['Insert', 'i', 'Enter', 'Backspace'].includes(event.key)) {
            dispatch(
                keydownLogger({
                    key: event.key
                }) as StateEvent
            );
        }

        if (['Del', 'Delete'].includes(event.key)) {
            dispatch(
                keydownLogger({
                    key: 'Delete'
                }) as StateEvent
            );
        }
    };
    hotKeysLocked = () => {
        return !this.getFocusCell() || !this.props.isEditMode || this.state.detailsOpen || this.props.isCommentOpened;
    };
    onContainerKeyDown = (event: Record<string, any>) => {
        if (this.hotKeysLocked()) {
            return;
        }

        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
            event.preventDefault();
        }
    };

    setGridRef = (grid) => {
        const {gridRef} = this.props;
        // @ts-ignore
        this._grid = grid;

        if (grid && gridRef) {
            // @ts-ignore
            gridRef(grid._bottomLeftGrid);
        }
    };

    render() {
        const {detailsOpen, isDragging, openedPanel, hiddenColumns} = this.state;
        const {
            configuration,
            lookupValues,
            dispatch,
            policies,
            query: searchQuery,
            loadMoreMapped,
            loadUnmapped,
            loadMoreUnmapped,
            currentType,
            unmappedValues,
            showValues,
            updateLookupValue,
            onScroll,
            scrollToRow,
            checkCodeUnique,
            searchMappings,
            isActivityLogEnabled,
            isWorkflowEnabled,
            collaborationOptions,
            isEditMode
        } = this.props;
        const fixedColumnCount = openedPanel && lookupValues.length ? 0 : FIXED_COLUMN_COUNT;
        const hiddenSourceAbbreviations = R.pipe(R.pluck('items'), R.flatten, R.pluck('abbreviation'))(hiddenColumns);
        const visibleSources = R.filter(
            ({abbreviation}) => !R.includes(abbreviation, hiddenSourceAbbreviations),
            this.state.sources
        );
        const columnNames = R.pipe(
            R.pluck('label'),
            R.concat([FIRST_COLUMN_NAME]),
            R.concat(R.__, ['__add__'])
        )(visibleSources);
        const bottomRowCount = visibleSources.length && isEditMode ? BOTTOM_ROWS_COUNT : 0;
        const rowCount = lookupValues.length + HEADER_ROWS_COUNT + bottomRowCount;
        const details = !isEditMode ? (
            <MappingDetailsModal
                configuration={configuration}
                open={Boolean(detailsOpen)}
                details={detailsOpen}
                onClose={this.onDetailsClose}
                searchQuery={searchQuery}
            />
        ) : (
            <MappingDetailsEditModal
                open={Boolean(detailsOpen)}
                details={detailsOpen}
                onClose={this.onDetailsClose}
                updateLookupValue={updateLookupValue}
                removeSourceMapping={this.removeSourceMapping}
            />
        );
        const showSidePanel = isActivityLogEnabled || isWorkflowEnabled || policies.lookupValuesRead;
        return (
            <ClickOutsideHOC onClickOutside={this.resetSelectedMapping}>
                <div
                    className={clsx(styles['mapping-grid'], {
                        [styles['mapping-grid--with-unmapped']]: isEditMode
                    })}
                    onKeyDown={this.onContainerKeyDown}
                >
                    <AutoSizer>
                        {({width, height}) => (
                            <MultiGrid
                                ref={(grid) => this.setGridRef(grid)}
                                cellRenderer={this.cellRenderer({
                                    columnNames,
                                    gridHeight: height,
                                    visibleSources,
                                    fixedColumnCount,
                                    rowCount,
                                    searchMappings,
                                    collaborationOptions
                                })}
                                width={width}
                                height={height}
                                fixedColumnCount={fixedColumnCount}
                                enableFixedColumnScroll={true}
                                hideBottomLeftGridScrollbar={true}
                                fixedRowCount={HEADER_ROWS_COUNT}
                                scrollToColumn={0}
                                columnWidth={getColumnWidth(columnNames.length, visibleSources)}
                                columnCount={columnNames.length}
                                rowHeight={this.getRowHeight}
                                rowCount={rowCount}
                                classNameBottomRightGrid={'with-overflow-visible'}
                                style={STYLE}
                                styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                                styleTopLeftGrid={LEFT_HEADER}
                                styleTopRightGrid={HEADER}
                                onScroll={(data) => {
                                    if (onScroll) {
                                        onScroll(data);
                                    }

                                    const {scrollHeight, clientHeight, scrollTop} = data;

                                    if (scrollHeight - clientHeight - scrollTop <= SCROLL_OFFSET) {
                                        loadMoreMapped(
                                            R.pipe(loadOnScrollLogger, dispatch, this.getFocusNextCellClb())
                                        );
                                    }

                                    this.syncScrolls();
                                }}
                                scrollToRow={scrollToRow >= 0 ? scrollToRow + HEADER_ROWS_COUNT : undefined}
                                scrollToAlignment={'start'}
                            />
                        )}
                    </AutoSizer>

                    <NetworkSubscription selector={getIsLoading}>
                        {(isLoading) => !showValues && !isLoading && EMPTY_STATE}
                    </NetworkSubscription>

                    {details}

                    {showSidePanel && (
                        <MappingSidePanel
                            isUnmappedDragging={isDragging}
                            loadUnmapped={loadUnmapped}
                            loadMoreUnmapped={loadMoreUnmapped}
                            resetSelectedMapping={this.resetSelectedMapping}
                            setIsDragging={this.setIsDragging}
                            openedPanel={this.state.openedPanel}
                            setOpenedPanel={this.setOpenedPanel}
                        />
                    )}

                    {isDragging && <DropOverlay />}

                    {isEditMode && (
                        <AddReferenceButton
                            onAddCanonicalClick={this.onAddCanonicalClick}
                            onAddCanonicalDrop={this.openReferenceModal}
                            dispatch={dispatch}
                            configuration={configuration}
                            currentType={currentType}
                            unmappedValues={unmappedValues}
                            loadMoreUnmapped={loadMoreUnmapped}
                            checkCodeUnique={checkCodeUnique}
                        />
                    )}
                </div>
            </ClickOutsideHOC>
        );
    }
}
const getCollaborationOptions = R.memoizeWith(R.identity, (currentType) => {
    return {
        objectType: CollaborationObjectType.MAPPING_GROUP,
        relatedObjectUri: getMappingRelatedObjectUri(currentType)
    };
});

const mapStateToProps = (state, props) => ({
    unmappedValues: R.path(['unmapped', 'unmappedValues'], state),
    unmappedTotal: getUnmappedTotal(R.prop('unmapped', state)),
    valuesBySourcesStats: getValuesBySourcesStats(state),
    searchMappings: getSearchMappings(state, props),
    isActivityLogEnabled: getIsActivityLogEnabled(state, props),
    isWorkflowEnabled: getIsWorkflowEnabled(state, props),
    collaborationOptions: getCollaborationOptions(state.currentType),
    isTaskOpen: !!getOpenedTask(state),
    isTaskLoaded: !!getTasks(state),
    isDCRDisplayed: getIsDCRDisplayed(state),
    isCommentOpened: !!getOpenedComment(state),
    ...R.pick(
        [
            'availableSources',
            'tenant',
            'configuration',
            'sorting',
            'draftMode',
            'currentType',
            'lookupValues',
            'collaboration',
            'policies'
        ],
        state
    )
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    checkCodeUnique: R.compose(dispatch, checkCodeUniqueCommand)
});

export default R.pipe(
    connect(mapStateToProps, mapDispatchToProps),
    withCache(CACHE_KEYS.MAPPING),
    withFeatures
)(MappingGrid);
