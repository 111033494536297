import * as R from 'ramda';
import DataMapping from 'react-components/dist/DataMapping/DataMapping';
import RightSidePanel from '../RightSidePanel/RightSidePanel';
import SelectLookupTypeButton from '../SelectLookupTypeButton/SelectLookupTypeButton';
import {Mapping} from '../../../rdm-sdk/import.types';
import {PureComponent} from 'react';
import {withImportWizardContext} from '../ImportWizardContext';
type Props = {
    fileData: Array<string[]>;
    mappings: Mapping[];
    removeMapping: (arg0: {target: string}) => void;
    isFirstLineHeader: boolean;
    toggleIsFirstLineHeader: () => void;
};

class ImportStep2 extends PureComponent<Props> {
    render() {
        const {fileData, mappings, removeMapping, isFirstLineHeader, toggleIsFirstLineHeader} = this.props;
        return (
            <DataMapping
                TargetSelector={SelectLookupTypeButton}
                RightSidePanel={RightSidePanel}
                data={fileData}
                mapping={mappings}
                onMappingDelete={removeMapping}
                onSetFirstLineHeader={toggleIsFirstLineHeader}
                onColumnDefaultValueChange={() => {}}
                isFirstLineHeader={isFirstLineHeader}
            />
        );
    }
}

const mapContextToProps = R.pick([
    'removeMapping',
    'mappings',
    'fileData',
    'isFirstLineHeader',
    'toggleIsFirstLineHeader'
]);
export default withImportWizardContext(mapContextToProps, ImportStep2);
