import Typography, {TypographyProps} from '@mui/material/Typography';
import {ReactElement} from 'react';

type Props = {
    selectProps: {
        classes: {
            placeholder: string;
        };
    };
    innerProps: TypographyProps;
    children: ReactElement;
};

export default function Placeholder(props: Props) {
    const {
        selectProps: {classes},
        innerProps = {},
        children
    } = props;
    return (
        <Typography color="textSecondary" className={classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}
