import * as R from 'ramda';
import Button from '@mui/material/Button';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import Dialog from '../Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ShowMore from '../ShowMore/ShowMore';
import TextField from '@mui/material/TextField';
import VirtualizedSelect from 'react-virtualized-select';
import clone from 'clone';
import i18n from 'ui-i18n';
import styles from './mapping-dialog.less';
import withCache from '../Cache/withCache';
import {CACHE_KEYS} from '../../constants/common';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {LookupSelectOption, LookupValue, SourceMapping} from '../../rdm-sdk/lookups.types';
import {Maybe} from 'monet';
import {Observable, Subject, Subscription} from 'rxjs';
import {PureComponent} from 'react';
import {SortDirection} from '../../rdm-sdk/app.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {
    countCanonicals,
    countDownstream,
    getCanonicalValue,
    insertMapping,
    parseLookupValues,
    removeMapping,
    updateMapping
} from '../../rdm-sdk/lookups';
import {deepEquals, getChecked, getValue} from '../../core/util';
import {getSourceLabel} from '../../rdm-sdk/sources';
import {inArray, inArrayByIndex, prop, safeArrayByIndex, safePath, safeProp} from '../../core/lenses';
import {isSuccess, tap} from '../../core/monet';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {markNew} from '../../core/marks';
import {requestAllValuesCommand} from '../../redux/actions/values';
import {validateMapping} from './validation';
export const INITIAL = {
    source: null,
    index: null,
    entry: {
        canonical: null,
        sources: {},
        code: null
    }
};
export const INITIAL_MAPPING = {
    enabled: true,
    downStreamDefaultValue: false,
    canonicalValue: false,
    description: null,
    code: '',
    value: ''
};
const mappingUpdater = R.curry(({type, value}, mapping) => {
    mapping = mapping || INITIAL;

    switch (type) {
        case 'entryCanonicalCode':
        case 'canonicalValue':
        case 'downStreamDefaultValue':
        case 'description':
        case 'value':
        case 'code':
        case 'enabled':
            return R.assoc(type, value, mapping);

        default:
            return mapping;
    }
});

const mappingLens = (source, index) =>
    safePath('entry', 'sources').compose(safeProp(source)).compose(safeArrayByIndex(index));

const isExistingMapping = ({index}) => index !== null;

const setDefaults = (safeDetails) => (mapping) => {
    const {source, entry} = safeDetails.orSome(INITIAL);

    if (!countCanonicals(entry)) {
        mapping = R.assoc('canonicalValue', true, mapping);
    }

    if (!countDownstream(source, entry)) {
        mapping = R.assoc('downStreamDefaultValue', true, mapping);
    }

    return mapping;
};

const getMapping = (details) => {
    const safeDetails = Maybe.fromNull(details);
    return safeDetails
        .filter(isExistingMapping)
        .flatMap((details) => mappingLens(details.source, details.index).get(safeDetails))
        .orElse(Maybe.Some(INITIAL_MAPPING))
        .map(setDefaults(safeDetails))
        .some();
};

const eventCreator = R.curry((type, value) => ({
    type,
    value
}));
const notEqual = R.curry((a, b) => a !== b);

const toSelectOption = (entry) => ({
    label: getCanonicalValue(entry).orSome(''),
    value: entry.code,
    entry
});

const toSelectOptions = R.pipe(R.map(toSelectOption), R.reject(R.pipe(R.prop('label'), R.isEmpty)));

const concatSelectOptionsCache = (selectOptionsCache) => (state) => ({
    selectOptionsCache: R.unionWith(R.eqProps('value'), state.selectOptionsCache, selectOptionsCache)
});

const logger = logActivityCommand('mapping-details-modal');
const saveLogger = logger('save-click');
const deleteLogger = logger('delete-click');
const cancelLogger = logger('cancel-click');
const addLogger = logger('add-save-click');
type Details = {
    source: string;
    index: number | null;
    entry: LookupValue;
};
type NextStateEvent = {
    type: string;
    value: Record<string, any>;
};
type Props = {
    dispatch: (e: StateEvent) => Promise<any>;
    lookupValues: LookupValue[];
    details: Details;
    open: boolean;
    onClose: () => void;
    currentType: string;
    configuration: Configuration;
    updateLookupValue: (code: string, lookupValue: LookupValue) => void;
    removeSourceMapping: (sourceAbbreviation: string, mappingIndex: number, lookupValue: LookupValue) => void;
    cacheData: LookupSelectOption[];
    setCacheData: (arg0: LookupSelectOption[]) => void;
};
type State = {
    more: boolean;
    mapping?: SourceMapping | null;
    entry?: LookupValue | null;
    index?: number | null;
    source?: string | null;
    addMode: boolean;
    isOriginallyCanonical: boolean;
    isOriginallyDownstream: boolean;
    validationFailed: boolean;
    selectedLookupValue?: LookupSelectOption | null;
    propsToTrack: {
        details?: Details | null;
        open: boolean;
        lookupValues: LookupValue[];
    };
    isEdited: boolean;
    selectOptionsCache: LookupSelectOption[];
};
export class MappingDetailsEditModal extends PureComponent<Props, State> {
    state = {
        addMode: true,
        more: true,
        mapping: null,
        source: null,
        index: null,
        entry: null,
        isOriginallyCanonical: false,
        isOriginallyDownstream: false,
        validationFailed: false,
        selectedLookupValue: null,
        propsToTrack: {
            details: this.props.details,
            open: this.props.open,
            lookupValues: this.props.lookupValues
        },
        isEdited: false,
        selectOptionsCache: this.props.cacheData || []
    } as State;
    selectInput?: Maybe<string>;
    selectOptionsCacheSubs?: Subscription;
    selectInput$: Subject<string> = new Subject();

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const {details: nextDetails, open: nextOpen} = nextProps;
        const {
            propsToTrack: {details: prevDetails, open: prevOpen}
        } = prevState;
        const propsToTrack = {
            details: nextDetails,
            open: nextOpen
        } as State['propsToTrack'];
        const stateChanges = {} as State;
        stateChanges.propsToTrack = propsToTrack;

        if (prevDetails !== nextDetails) {
            if (nextDetails) {
                Object.assign(stateChanges, nextDetails, {
                    addMode: nextDetails.index === null
                });

                if (!prevOpen && nextOpen) {
                    stateChanges.more = nextDetails.index !== null;
                }
            }

            const mapping = getMapping(nextDetails);
            stateChanges.mapping = mapping;
            stateChanges.isOriginallyCanonical = mapping.canonicalValue;
            stateChanges.isOriginallyDownstream = mapping.downStreamDefaultValue;
            stateChanges.isEdited = false;
        }

        return stateChanges;
    }

    componentDidUpdate() {
        const abbr = Maybe.fromNull(this.state.source).orSome('');
        const sourceLabel = getSourceLabel(this.props.configuration, abbr);
        const selectedElement = document.querySelector('#mapping-details-select-container .Select-value-label');

        if (selectedElement?.textContent && !selectedElement?.textContent?.includes(sourceLabel)) {
            selectedElement.textContent = `${sourceLabel}: ${selectedElement.textContent}`;
        }
    }

    componentDidMount(): void {
        const selectInputsCache: (string | null)[] = [];
        const isNotCached = R.complement(R.includes(R.__, selectInputsCache));
        this.selectOptionsCacheSubs = this.selectInput$
            .distinctUntilChanged()
            .debounceTime(400)
            .map((input) => input.trim() || null)
            .filter(isNotCached)
            .switchMap((input) => {
                selectInputsCache.push(input);
                return Observable.fromPromise(this.getSelectOptions(input));
            })
            .subscribe(
                (selectInputsCache) => this.setState(concatSelectOptionsCache(selectInputsCache)),
                (error) => console.error('Error fetching select options', error)
            );

        if (!this.props.cacheData) {
            this.selectInput$.next('');
        }
    }

    componentWillUnmount(): void {
        this.props.setCacheData(this.state.selectOptionsCache);
        this.selectOptionsCacheSubs?.unsubscribe();
    }

    getSelectOptions = (value: string | null) => {
        const {dispatch, currentType} = this.props;
        const options = {
            field: 'value',
            direction: SortDirection.ASC,
            isNonBlocking: true
        };
        return dispatch(requestAllValuesCommand(currentType, value, options) as StateEvent)
            .then(parseLookupValues)
            .then(toSelectOptions)
            .catch((error) => {
                console.error('getSelectOptions error', error);
                return [];
            });
    };
    onSelectInput = (input: string) => this.selectInput$.next(input);
    next = (event: NextStateEvent) =>
        this.setState(
            R.evolve({
                mapping: mappingUpdater(event),
                isEdited: R.T
            })
        );

    resetValidationFailed = () => {
        this.setState({
            validationFailed: false
        });
    };

    handleClose = () => {
        const {onClose} = this.props;
        return R.pipe(this.resetValidationFailed, onClose)();
    };

    handleCancel = () => {
        const {onClose} = this.props;
        return R.pipe(this.resetValidationFailed, onClose)();
    };

    applyWithValidation = () => {
        const {lookupValues, updateLookupValue} = this.props;
        const {source, index, mapping, entry, addMode, selectedLookupValue} = this.state;
        const safeEntry = Maybe.fromNull(entry);
        const safeMapping = Maybe.fromNull(mapping);
        const entryCanonicalCodeSafe = safeProp('code').get(safeEntry);
        const mappingCanonicalCodeSafe = safeProp('entryCanonicalCode').get(safeMapping).orElse(entryCanonicalCodeSafe);
        const hasCanonicalChanged = entryCanonicalCodeSafe.ap(mappingCanonicalCodeSafe.map(notEqual)).orSome(false);
        const isValid = Maybe.fromNull(mapping).map(R.pipe(validateMapping, isSuccess)).orSome(false);
        const markValueDirtyAndUpdateGlobally = R.curry((code, entry) => {
            updateLookupValue(code, entry);
            this.handleClose();
        });
        const getNewEntry = () => {
            const newCanonicalCode = mappingCanonicalCodeSafe.some();
            const entryByCodeL = inArray('code', newCanonicalCode);
            const newEntry = clone(entryByCodeL.get(lookupValues));

            if (newEntry) {
                return Promise.resolve(newEntry);
            }

            return this.getSelectOptions(R.propOr('', 'label', selectedLookupValue))
                .then(R.pipe(R.pluck('entry'), entryByCodeL.get))
                .catch((error) => {
                    console.error('Failed to fetch fresh lookup value, applying cached one', error);
                    return clone(R.propOr({}, 'entry', selectedLookupValue));
                });
        };

        const apply = (newEntry) => {
            const originalCanonicalCode = entryCanonicalCodeSafe.some();
            const newCanonicalCode = mappingCanonicalCodeSafe.some();

            if (addMode) {
                markValueDirtyAndUpdateGlobally(newCanonicalCode, insertMapping(source, newEntry, markNew(mapping)));
            } else if (hasCanonicalChanged) {
                markValueDirtyAndUpdateGlobally(
                    newCanonicalCode,
                    insertMapping(source, newEntry, markNew({...mapping, downStreamDefaultValue: false}))
                );
                markValueDirtyAndUpdateGlobally(originalCanonicalCode, removeMapping(source, index, entry));
            } else {
                let originalMapping = prop('sources').compose(prop(source)).compose(inArrayByIndex(index)).get(entry);
                originalMapping = Object.assign(
                    {
                        description: ''
                    },
                    originalMapping,
                    {
                        entryCanonicalCode: undefined
                    }
                );
                const newMapping = Object.assign(
                    {},
                    {
                        description: ''
                    },
                    mapping,
                    {
                        entryCanonicalCode: undefined
                    }
                );
                deepEquals(originalMapping, newMapping)
                    ? this.handleCancel()
                    : markValueDirtyAndUpdateGlobally(
                          originalCanonicalCode,
                          updateMapping(source, index, entry, mapping)
                      );
            }
        };

        return isValid
            ? getNewEntry().then(apply)
            : this.setState({
                  validationFailed: true
              });
    };

    render() {
        const {lookupValues, dispatch, open, removeSourceMapping} = this.props;
        const {
            more,
            source,
            index,
            mapping,
            entry,
            addMode,
            isOriginallyCanonical,
            isOriginallyDownstream,
            validationFailed,
            isEdited,
            selectOptionsCache
        } = this.state;
        const selectOptions = R.unionWith(R.eqProps('value'), toSelectOptions(lookupValues), selectOptionsCache);
        const safeEntry = Maybe.fromNull(entry);
        const safeMapping = Maybe.fromNull(mapping);
        const entryCanonicalCodeSafe = safeProp('code').get(safeEntry);
        const mappingCanonicalCodeSafe = safeProp('entryCanonicalCode').get(safeMapping).orElse(entryCanonicalCodeSafe);

        const mappingCanonicalCode = mappingCanonicalCodeSafe.orSome(null);
        const mappingCode = safeProp('code').get(safeMapping).orSome('');
        const mappingValue = safeProp('value').get(safeMapping).orSome('');
        const mappingDescription = safeProp('description').get(safeMapping).orSome('');
        const mappingIsCanonicalValue = safeProp('canonicalValue').get(safeMapping);
        const mappingIsDownStreamDefaultValue = safeProp('downStreamDefaultValue').get(safeMapping);

        const shadowCanonicalValue = mappingIsCanonicalValue.orSome(false);

        const remove = () => {
            if (source && entry && index != null) {
                removeSourceMapping(source, index, entry);
            }
        };

        const resetOpenAfterFocus = () =>
            tap((input) => {
                input._openAfterFocus = false;
            }, this.selectInput);

        const setSelectedLookupValue = (data) => {
            this.setState({
                selectedLookupValue: data
            });
        };

        const onSelectChange = R.pipe(
            R.tap(setSelectedLookupValue),
            R.prop('value'),
            eventCreator('entryCanonicalCode'),
            this.next,
            resetOpenAfterFocus
        );
        const loggableInfo = {
            mappingCode,
            mappingValue,
            mappingDescription,
            mappingIsCanonicalValue,
            mappingIsDownStreamDefaultValue
        };
        return (
            <Dialog className={styles['mapping-details']} open={open} onCancel={this.handleCancel}>
                <DialogTitle className={styles['mapping-details__title']}>
                    {addMode ? i18n.text('Add mapping') : i18n.text('Edit source value')}
                    <div id="mapping-details-select-container">
                        <VirtualizedSelect
                            filterOption={(option, input) =>
                                option.label.split(' ').map(R.pipe(R.trim, R.toLower)).some(R.startsWith(input))
                            }
                            ref={(select) => {
                                this.selectInput = Maybe.fromNull(select).map(prop('_selectRef').get);
                            }}
                            disabled={shadowCanonicalValue}
                            clearable={false}
                            options={
                                shadowCanonicalValue
                                    ? [
                                          {
                                              label: mappingValue,
                                              value: mappingCanonicalCode
                                          }
                                      ]
                                    : selectOptions
                            }
                            onChange={R.unless(R.isNil, onSelectChange)}
                            value={mappingCanonicalCode}
                            onInputChange={this.onSelectInput}
                        />
                    </div>
                </DialogTitle>

                <DialogContent>
                    <div className={styles['mapping-details__code-value']}>
                        <TextField
                            variant="standard"
                            className={styles['mapping-details__source-value']}
                            required={!addMode || validationFailed}
                            onChange={R.pipe(getValue, eventCreator('value'), this.next)}
                            value={mappingValue}
                            label={i18n.text('Source value')}
                        />

                        <TextField
                            variant="standard"
                            className={styles['mapping-details__source-code']}
                            required={!addMode || validationFailed}
                            onChange={R.pipe(getValue, eventCreator('code'), this.next)}
                            value={mappingCode}
                            label={i18n.text('Source code')}
                        />
                    </div>
                    <ShowMore
                        onToggle={(more) => {
                            this.setState({
                                more
                            });
                        }}
                        open={more}
                    >
                        <TextField
                            variant="standard"
                            onChange={R.pipe(getValue, eventCreator('description'), this.next)}
                            value={mappingDescription}
                            label={i18n.text('Description')}
                            fullWidth
                        />
                        <CustomSwitch
                            className={styles['mapping-details__switch-row']}
                            label={i18n.text('Canonical value')}
                            hintForOnState={i18n.text(
                                'Turn on to assign this source value as the target canonical value'
                            )}
                            checked={mappingIsCanonicalValue.orSome(false)}
                            disabled={isOriginallyCanonical}
                            onChange={R.pipe(getChecked, eventCreator('canonicalValue'), this.next)}
                        />
                        <CustomSwitch
                            className={styles['mapping-details__switch-row']}
                            label={i18n.text('Source system value')}
                            hintForOnState={i18n.text(
                                'Turn on to assign this source value as the default source system value'
                            )}
                            checked={mappingIsDownStreamDefaultValue.orSome(false)}
                            disabled={isOriginallyDownstream}
                            onChange={R.pipe(getChecked, eventCreator('downStreamDefaultValue'), this.next)}
                        />
                    </ShowMore>
                </DialogContent>

                <DialogActions>
                    {!addMode && (
                        <Button
                            variant="grey"
                            disabled={isOriginallyCanonical}
                            onClick={R.pipe(remove, this.handleClose, R.always(loggableInfo), deleteLogger, dispatch)}
                            className="mapping-editor__delete-button"
                        >
                            {i18n.text('Delete')}
                        </Button>
                    )}
                    <div className="spacer" />
                    <Button
                        variant="grey"
                        onClick={R.pipe(this.handleCancel, R.always(loggableInfo), cancelLogger, dispatch)}
                        className="mapping-editor__cancel-button"
                    >
                        {i18n.text('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={R.pipe(
                            this.applyWithValidation,
                            R.always(loggableInfo),
                            addMode ? addLogger : saveLogger,
                            dispatch
                        )}
                        className="mapping-editor__done-button"
                        disabled={!isEdited}
                        onKeyPress={(e) => (e.key === 'Enter' ? this.applyWithValidation() : null)}
                    >
                        {i18n.text('Done')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps = R.pick(['lookupValues', 'currentType', 'configuration']);
export default R.pipe(connect(mapStateToProps), withCache(CACHE_KEYS.MAPPING_DETAILS))(MappingDetailsEditModal);
