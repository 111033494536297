import {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
const thumbStyle = {
    backgroundColor: '#CBD3D6'
};

type Props = typeof Scrollbars;
export default class Scrollbar extends Component<Props> {
    scrollbars?: HTMLElement;
    getScrollbarsRef() {
        return this.scrollbars;
    }

    render() {
        return (
            <Scrollbars
                ref={(scrollbars) => (this.scrollbars = scrollbars)}
                renderThumbHorizontal={({style, ...props}) => <div {...props} style={{...style, ...thumbStyle}} />}
                renderThumbVertical={({style, ...props}) => <div {...props} style={{...style, ...thumbStyle}} />}
                {...this.props}
            >
                {this.props.children}
            </Scrollbars>
        );
    }
}
