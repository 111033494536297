import TablePagination from '@mui/material/TablePagination';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {WorkflowPagination} from '../workflow.types';
import {connect} from 'react-redux';
import {getTasksPagination, getTasksSize} from '../../../redux/selectors/workflowSelectors';
import {getValue} from '../../../core/util';
import {paginationChangeWFLogger} from '../logging';
import {pipe} from 'ramda';
import {setTasksPaginationEvent} from '../../../redux/actions/workflow';
const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];
type Props = {
    pagination: WorkflowPagination;
    onChange: (arg0: WorkflowPagination) => void;
    setPagination: (arg0: WorkflowPagination) => void;
    size: number;
};

function WorkflowsPagination(props: Props) {
    const {
        pagination: {rowsPerPage, page},
        setPagination,
        size
    } = props;

    const setRowsPerPage = (rowsPerPage) =>
        setPagination({
            page: 0,
            rowsPerPage
        });

    const handleChangePage = (event, page) =>
        setPagination({
            page,
            rowsPerPage
        });

    const handleChangeRowsPerPage = pipe(getValue, Number, setRowsPerPage);
    const probableCount = page * rowsPerPage + size;
    const count = size < rowsPerPage ? probableCount : -1;
    return (
        <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={i18n.text('Workflows per page:')}
            backIconButtonProps={{
                'aria-label': i18n.text('Previous Page')
            }}
            nextIconButtonProps={{
                'aria-label': i18n.text('Next Page')
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
                root: styles['workflow-panel__pagination'],
                selectLabel: styles['workflow-panel__pagination-label'],
                displayedRows: styles['workflow-panel__pagination-display-rows'],
                select: styles['workflow-panel__pagination-select']
            }}
        />
    );
}

const mapStateToProps = (state) => ({
    pagination: getTasksPagination(state),
    size: getTasksSize(state)
});

const mapDispatchToProps = (dispatch, {onChange}) => ({
    setPagination: (pagination) => {
        dispatch(paginationChangeWFLogger(pagination));
        dispatch(setTasksPaginationEvent(pagination));
        onChange(pagination);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowsPagination);
