/**
 * Created by ndyumin on 03.10.2016.
 */
import * as R from 'ramda';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Dispatch} from 'redux';
import {StateEvent} from '../../rdm-sdk/state.types';
import {User} from '../../rdm-sdk/app.types';
import {checkPoliciesAll, parsePrivilegesFromServer} from '../../permissions';
import {commandCreator} from '../middlewares/command-middleware';
import {json, requestWithBlockingSpinner, requestWithNonBlockingSpinner} from '../../network';
import {then} from '../../core/func';
const SERVICE_IDS = ['collaboration', 'rdm', 'Auth', 'MDM', 'workflow'];
export function loggedInEvent(username: string, email: string): StateEvent {
    return {
        type: 'loggedIn',
        username,
        email
    } as StateEvent;
}
export function loggedOutEvent(): StateEvent {
    return {
        type: 'loggedOut'
    };
}
export function usersEvent(users: User[]): StateEvent {
    return {
        type: 'users',
        users
    } as StateEvent;
}
export function policiesEvent(policies: CheckedPolicies): StateEvent {
    return {
        type: 'policies',
        policies
    } as StateEvent;
}
export const requestUsersCommand = commandCreator(({state}) => {
    const {
        tenant,
        policies: {usersRead}
    } = state;
    return usersRead
        ? requestWithNonBlockingSpinner(`/users/${tenant}`)
              .then(json)
              .catch((error) => {
                  console.error('Request users error', error);
                  return [];
              })
        : Promise.resolve([]);
});
export const requestUsersSaga = (dispatch: (arg0: StateEvent) => Promise<any>) =>
    R.pipe(
        requestUsersCommand,
        dispatch,
        then(R.pipe(R.map(R.pick(['username', 'email'])), usersEvent, dispatch)),
        then(R.propOr([], 'users'))
    );
export const requestPermissionsCommand = commandCreator(({state}) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/checkToken?serviceId=${SERVICE_IDS.join(',')}`, {
        method: 'post'
    })
        .then(json)
        .then(R.pathOr({}, ['user', 'userPermissions', 'permissions']))
        .then(parsePrivilegesFromServer(tenant))
        .catch((error) => {
            console.error('Check token request failed for services: ', SERVICE_IDS, error);
            return {};
        })
        .then(checkPoliciesAll)
        .catch((error) => {
            console.error('Request permissions error', error);
            return {};
        });
});
export const requestPermissionsSaga = (dispatch: Dispatch) =>
    R.pipe(requestPermissionsCommand, dispatch, then(R.pipe(policiesEvent, dispatch)));
