import * as R from 'ramda';
import LookupTypesEmptyIcon from '../../../assets/inline/lookupTypesEmpty.svg';
import TypesTable from './TypesTable';
import Typography from '@mui/material/Typography';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component} from 'react';
import {connect} from 'react-redux';
const EMPTY_STATE = (
    <div className={'empty-state'}>
        <div
            className={'empty-state__icon'}
            dangerouslySetInnerHTML={{
                __html: LookupTypesEmptyIcon
            }}
        />
        <Typography variant={'h6'} className={'empty-state__header'}>
            {i18n.text('There is no data to export')}
        </Typography>
    </div>
);
type Props = {
    isLookupTypesEmpty: boolean;
    selectedTypeUris: string[];
    setSelectedTypes: (uris: string[]) => void;
};
export class ExportStep1 extends Component<Props> {
    render() {
        const {isLookupTypesEmpty, selectedTypeUris, setSelectedTypes} = this.props;
        return (
            <div className={styles['step-container']}>
                {isLookupTypesEmpty ? (
                    EMPTY_STATE
                ) : (
                    <TypesTable selectedTypeUris={selectedTypeUris} setSelectedTypes={setSelectedTypes} />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({configuration: {lookupTypes}}) => ({
    isLookupTypesEmpty: R.isEmpty(lookupTypes)
});

export default connect(mapStateToProps)(ExportStep1);
