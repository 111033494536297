import EmptyState from '../EmptyState/EmptyState';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import WorkflowsList from '../WorkflowsList/WorkflowsList';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Fragment} from 'react';
import {WorkflowType} from '../workflow.types';
type Props = {
    workflow: WorkflowType | null;
    onClose: () => void;
};

const OpenedWorkflowTask = (props: Props) => {
    const {workflow, onClose} = props;
    return (
        <Fragment>
            <div className={styles['workflow-tasks__header']}>
                <IconButton className={styles['workflow-tasks__close']} onClick={onClose} size="large">
                    <Icon>arrow_back</Icon>
                </IconButton>
                <Typography variant={'h6'} className={styles['workflow-tasks__title']}>
                    {i18n.text('Back to Workflows')}
                </Typography>
                <div className="spacer" />
            </div>
            <div className={styles['workflow-tasks__container']}>
                {workflow ? <WorkflowsList workflows={[workflow]} /> : <EmptyState />}
            </div>
        </Fragment>
    );
};

export default OpenedWorkflowTask;
