import activity_log from '../../assets/inline/activity_log.svg';
import chat from '../../assets/inline/chat.svg';
import checkbox_indeterminate from '../../assets/inline/checkbox_indeterminate.svg';
import comment from '../../assets/inline/comment.svg';
import cross from '../../assets/inline/cross.svg';
import face from '../../assets/inline/face.svg';
import hierarchy from '../../assets/inline/hierarchy.svg';
import link from '../../assets/inline/link.svg';
import multi_parent from '../../assets/inline/multi_ parent.svg';
import required from '../../assets/inline/required.svg';
import required_inactive from '../../assets/inline/required_inactive.svg';
import workflow from '../../assets/inline/workflow.svg';
const ICONS = {
    required,
    required_inactive,
    cross,
    chat,
    comment,
    face,
    hierarchy,
    multi_parent,
    link,
    checkbox_indeterminate,
    activity_log,
    workflow
};

type Props = {
    name: string;
    className?: string;
};

const CustomIcon = ({name, ...props}: Props) => (
    <i
        {...props}
        dangerouslySetInnerHTML={{
            __html: ICONS[name]
        }}
    />
);

export default CustomIcon;
