/**
 * Created by ndyumin on 12.10.2016.
 */
import * as R from 'ramda';
import AddSourceDialog from '../../components/AddSourceDialog/AddSourceDialog';
import Button from '@mui/material/Button';
import CustomTable from '../../components/CustomTable/CustomTable';
import MenuItemsButton from '../../components/MenuItemsButton/MenuItemsButton';
import SourcesEmptyIcon from '../../assets/inline/sourcesEmpty.svg';
import SourcesSidePanel from '../../components/ActivityLog/Sources/SourcesSidePanel/SourcesSidePanel';
import draftIconCellRenderer from '../../components/CustomTable/draftIconCellRenderer';
import i18n from 'ui-i18n';
import styles from './sources.less';
import tableHeaderRenderer from '../../components/CustomTable/tableHeaderRenderer';
import unmappedCountCellRenderer from '../../components/CustomTable/unmappedCountCellRenderer';
import withFeatures from '../../components/Feature/withFeatures';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DraftMode, SortDirection, Sorting} from '../../rdm-sdk/app.types';
import {Features} from '../../rdm-sdk/features.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {Stats} from '../../rdm-sdk/stats.types';
import {StatusRightMenu} from '../../components/AppBar/StatusLine';
import {connect} from 'react-redux';
import {deleteSourcesEvent} from '../../redux/actions/configuration';
import {getDispatchOnResolve} from '../../redux/middlewares/confirm-middleware/confirmMiddleware';
import {getIsActivityLogEnabled} from '../../redux/reducers/activityLogReducer';
import {getSourceByUri} from '../../rdm-sdk/configuration';
import {isExceptionRoute, sortBy} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {pipe} from '../../core/func';
import {push, replace} from 'react-router-redux';
import {requestUnmappedTotalsBySourcesSaga} from '../../redux/actions/stats';
import {
    resetActivitiesEvent,
    resetActivitiesTotalEvent,
    resetActivityLogFiltersEvent
} from '../../redux/actions/activityLog';
import {showConfirmationDialogEvent, titleEvent} from '../../redux/actions/app';
const APP_TITLE = i18n.text('Sources');
const COLUMNS = [
    {
        label: i18n.text('Name'),
        flexGrow: 1,
        dataKey: 'label',
        width: 200,
        cellRenderer: draftIconCellRenderer
    }
];
const UNMAPPED_COLUMN = {
    label: '',
    flexGrow: 0.1,
    dataKey: 'unmappedCount',
    width: 100,
    cellRenderer: unmappedCountCellRenderer
};
const getCode = R.pipe(R.prop('uri'), R.split('/'), R.last);
type Props = {
    dispatch: (e: StateEvent) => Promise<any>;
    dispatchOnResolve: (e: StateEvent) => Promise<any>;
    router: Record<string, any>;
    route: Record<string, any>;
    draftMode: DraftMode;
    tenant: string;
    policies: CheckedPolicies;
    configuration: Configuration;
    features: Features;
    stats: Stats;
    isActivityLogEnabled: boolean;
};
type State = {
    dialogOpen: boolean;
    sorting: Sorting;
    currentSource: string | null;
};
const logger = logActivityCommand('sources-table');
const addSourceLogger = logger('add-source-click');
export class SourcesListPage extends Component<Props, State> {
    state = {
        currentSource: null,
        dialogOpen: false,
        sorting: {
            field: 'label',
            direction: SortDirection.ASC
        }
    } as State;

    componentDidMount() {
        const {dispatch, router, route, stats, dispatchOnResolve} = this.props;
        dispatch(titleEvent(APP_TITLE));

        if (R.isEmpty(stats.unmappedBySources)) {
            requestUnmappedTotalsBySourcesSaga(dispatch)();
        }

        router.setRouteLeaveHook(route, (nextRoute) => {
            const {draftMode} = this.props;

            if (draftMode.dirtySources && !isExceptionRoute(nextRoute)) {
                if (nextRoute.action === 'POP') dispatch(replace(router.location));
                dispatch(showConfirmationDialogEvent());
                dispatchOnResolve(push(nextRoute));
                return false;
            }
        });
    }

    componentDidUpdate(prevProps: Props) {
        const {
            configuration: {sources},
            policies,
            dispatch
        } = this.props;

        if (
            (prevProps.configuration.sources.length === 0 && !R.isEmpty(sources)) ||
            (R.isEmpty(prevProps.policies) && !R.isEmpty(policies))
        ) {
            requestUnmappedTotalsBySourcesSaga(dispatch)();
        }
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(resetActivitiesEvent());
        dispatch(resetActivitiesTotalEvent());
        dispatch(resetActivityLogFiltersEvent());
    }

    render() {
        const {
            dispatch,
            policies,
            configuration: {sources},
            stats,
            draftMode,
            isActivityLogEnabled
        } = this.props;
        const {currentSource, dialogOpen, sorting} = this.state;

        const closeDialog = () =>
            this.setState({
                dialogOpen: false
            });

        const resetCurrentSourceAndCloseDialog = pipe(
            () =>
                this.setState({
                    currentSource: null
                }),
            closeDialog
        );
        const source = getSourceByUri(this.props.configuration, currentSource);

        const nextSource = (currentSource) => {
            this.setState({
                currentSource,
                dialogOpen: currentSource !== null
            });
        };

        const sourcesData = pipe(
            R.map((source) =>
                Object.assign({}, source, {
                    unmappedCount: R.prop(getCode(source), stats.unmappedBySources)
                })
            ),
            sortBy(sorting.field, sorting.direction)
        )(sources);
        const EMPTY_STATE = (
            <div className={'empty-state'}>
                <div
                    className={'empty-state__icon'}
                    dangerouslySetInnerHTML={{
                        __html: SourcesEmptyIcon
                    }}
                />
                <h3 className={'empty-state__header'}>{i18n.text('There are no source systems for this tenant')}</h3>
                <p className={'empty-state__text'}>{i18n.text('Create a new source system to add source values')}</p>
                {policies.configEdit && (
                    <Button
                        color="primary"
                        variant="contained"
                        className={'empty-state__button'}
                        onClick={() =>
                            this.setState({
                                dialogOpen: true
                            })
                        }
                    >
                        {i18n.text('Create source system')}
                    </Button>
                )}
            </div>
        );
        const unmappedSourcesTotal = R.pipe(
            R.filter((n) => n > 0),
            R.keys,
            R.length
        )(stats.unmappedBySources);
        const sourcesTotal = sources.length;
        const headerRenderer = tableHeaderRenderer({
            caption: i18n.text('Sources List'),
            summary:
                sourcesTotal > 1
                    ? i18n.text('${count} sources', {
                          count: sourcesTotal
                      })
                    : i18n.text('${count} source', {
                          count: sourcesTotal
                      }),
            summaryWarning:
                unmappedSourcesTotal > 0 &&
                (unmappedSourcesTotal > 1
                    ? i18n.text('${count} sources with unmapped values', {
                          count: unmappedSourcesTotal
                      })
                    : i18n.text('${count} source with unmapped values', {
                          count: unmappedSourcesTotal
                      })),
            summaryWarningTooltip: i18n.text('Total sources with unmapped values'),
            logger
        });
        return (
            <div className={styles['sources-page__content']}>
                {sourcesTotal > 0 ? (
                    <CustomTable
                        isEditMode={policies.configEdit}
                        logger={logger}
                        primaryHeaderRenderer={headerRenderer}
                        data={sourcesData}
                        selectKey={'uri'}
                        columns={unmappedSourcesTotal ? [...COLUMNS, UNMAPPED_COLUMN] : COLUMNS}
                        onDelete={(selected) => {
                            dispatch(deleteSourcesEvent(selected));
                        }}
                        sorting={{
                            sort: sorting,
                            onChange: (sorting) =>
                                this.setState({
                                    sorting
                                })
                        }}
                        onRowClick={({rowData}) => {
                            nextSource(rowData.uri);
                        }}
                    />
                ) : (
                    EMPTY_STATE
                )}
                <AddSourceDialog
                    open={dialogOpen}
                    isEditMode={policies.configEdit}
                    source={source}
                    onCancelled={resetCurrentSourceAndCloseDialog}
                    onCreated={resetCurrentSourceAndCloseDialog}
                />
                {policies.configEdit && (
                    <MenuItemsButton
                        onClick={() => {
                            this.setState({
                                dialogOpen: true
                            });
                            dispatch(addSourceLogger() as StateEvent);
                        }}
                    >
                        {i18n.text('SOURCE SYSTEM')}
                    </MenuItemsButton>
                )}
                <StatusRightMenu dispatch={dispatch} isEditMode={policies.configEdit} draftMode={draftMode} />
                {isActivityLogEnabled && <SourcesSidePanel />}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    isActivityLogEnabled: getIsActivityLogEnabled(state, props),
    ...R.pick(['draftMode', 'tenant', 'policies', 'configuration', 'stats'], state)
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    dispatchOnResolve: getDispatchOnResolve(dispatch)
});

export default R.pipe(connect(mapStateToProps, mapDispatchToProps), withFeatures)(SourcesListPage);
