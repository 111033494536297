import * as R from 'ramda';
export type DiffInfo = {
    added: any[];
    removed: any[];
};
export const getDiff = (oldValues: any[], newValues: any[], oldDiff: DiffInfo = {} as DiffInfo): DiffInfo => {
    const removed = R.difference(oldValues, newValues);
    const added = R.difference(newValues, oldValues);
    return R.evolve(
        {
            added: R.pipe(R.without(removed), R.concat(R.without(oldDiff.removed, added))),
            removed: R.pipe(R.without(added), R.concat(R.without(oldDiff.added, removed)))
        },
        oldDiff
    );
};
