/* eslint-disable complexity */
/**
 * Created by ndyumin on 19.10.2016.
 */
import * as R from 'ramda';
import initialState from '../initialState';
import {LookupValue} from '../../rdm-sdk/lookups.types';
import {SortDirection} from '../../rdm-sdk/app.types';
import {createLens, inArray, path, prop} from '../../core/lenses';
import {markLookupValueRemoved} from '../../rdm-sdk/lookups';
import {markRemoved} from '../../core/marks';
const configurationL = createLens('configuration');
const sourcesL = createLens('sources');
const sessionL = createLens('session');
const tenantL = createLens('tenant');
const currentTypeL = createLens('currentType');
const lookupValuesL = createLens('lookupValues');
const lookupTypesL = createLens('lookupTypes');
const availableSourcesL = createLens('availableSources');
const sortingL = createLens('sorting');
const filtersL = createLens('filters');
const directionL = path('sorting', 'direction');
const drawerVisibleL = createLens('drawerVisible');
const submenuL = createLens('submenu');
const titleL = createLens('appTitle');
const searchQueryL = createLens('searchQuery');
const paginationL = prop('pagination');
const totalL = paginationL.compose(prop('total'));
const generatorsL = prop('generators');
const scrollIdL = prop('scrollId');
const paginationScrollIdL = paginationL.compose(scrollIdL);
const hasMoreL = prop('hasMore');
const paginationHasMoreL = paginationL.compose(hasMoreL);
const dependenciesL = prop('dependencies');
const statsL = createLens('stats');
const typesSearchQueryL = createLens('typesSearchQuery');
const showConfirmationDialogL = prop('showConfirmationDialog');
const typesHierarchyL = createLens('typesHierarchy');
const editModeL = createLens('editMode');
const loadingL = createLens('loading');
const configurationSources = configurationL.compose(sourcesL);
const configurationLookupTypesL = configurationL.compose(lookupTypesL);
// eslint-disable-next-line prettier/prettier
export default function mainReducer(state = initialState, event) { // NOSONAR
    // eslint-disable-next-line prettier/prettier
    switch (event.type) { // NOSONAR
        case 'users':
            return R.assoc('users', event.users, state);

        case 'policies':
            return R.assoc('policies', event.policies, state);

        case 'loggedOut':
            return sessionL.set(state, null);

        case 'loggedIn':
            return sessionL.set(state, {
                username: event.username,
                email: event.email
            });

        case 'tenantConfiguration':
            return configurationL.set(state, {
                sources: [],
                lookupTypes: [],
                ...event.configuration
            });

        case 'tenantChanged':
            return tenantL.set(state, event.tenant);

        case 'addSource':
            return configurationSources.set(state, configurationSources.get(state).concat(event.source));

        case 'updateSource':
            return configurationSources.set(
                state,
                configurationSources
                    .get(state)
                    .map((source) => (source.uri === event.source.uri ? event.source : source))
            );

        case 'deleteSources':
            return configurationSources.set(
                state,
                configurationSources.get(state).map((source) => {
                    const removed = event.sources.find((s) => s.uri === source.uri);
                    return removed ? removed : source;
                })
            );

        case 'lookupTypesRemove':
            return configurationLookupTypesL.set(
                state,
                configurationLookupTypesL
                    .get(state)
                    .map((type) => (event.types.find((t) => t.uri === type.uri) ? markRemoved(type) : type))
            );

        case 'addType':
            return configurationLookupTypesL.set(state, configurationLookupTypesL.get(state).concat(event.typeInfo));

        case 'updateType':
            return configurationLookupTypesL.set(
                state,
                configurationLookupTypesL
                    .get(state)
                    .map((type) => (type.uri === event.typeInfo.uri ? event.typeInfo : type))
            );

        case 'changeAvailableSources':
            return availableSourcesL.set(state, event.availableSources);

        case 'currentType':
            return currentTypeL.set(lookupValuesL.set(availableSourcesL.set(state, []), []), event.currentType);

        case 'upsertValue': {
            const typeMatches = event.luType === state.currentType;
            const value = (state.lookupValues as LookupValue[]).find((value) => event.code === value.code);
            const duplicate =
                event.value &&
                (state.lookupValues as LookupValue[]).find((existing) => existing.code === event.value.code);
            const valueExists = Boolean(value);
            const getUpdatedLookupValues = () =>
                valueExists
                    ? (state.lookupValues as LookupValue[]).map((value) =>
                          event.code === value.code ? event.value : value
                      )
                    : [event.value, ...(state.lookupValues || [])];
            return typeMatches && (valueExists || !duplicate)
                ? lookupValuesL.set(state, getUpdatedLookupValues())
                : state;
        }

        case 'deleteValues': {
            const {codes = []} = event;
            return event.currentType === state.currentType
                ? lookupValuesL.set(
                      state,
                      lookupValuesL.get(state).filter((value) => !codes.includes(value.code))
                  )
                : state;
        }

        case 'appendValues':
            return lookupValuesL.set(state, [...lookupValuesL.get(state), ...event.lookupValues]);

        case 'valuesReceived':
            return lookupValuesL.set(state, event.lookupValues);

        case 'lookupValuesRemove':
            return lookupValuesL.set(
                state,
                lookupValuesL
                    .get(state)
                    .map((value) => (event.values.includes(value) ? markLookupValueRemoved(value) : value))
            );

        case 'toggleSorting':
            return directionL.set(
                state,
                directionL.get(state) === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
            );

        case 'setSorting':
            return sortingL.set(state, event.sorting);

        case 'setFilters':
            return filtersL.set(state, event.filters);

        case 'drawerVisibility':
            return drawerVisibleL.set(state, event.isVisible);

        case 'drawerToggle':
            return drawerVisibleL.set(state, !drawerVisibleL.get(state));

        case 'submenu':
            return submenuL.set(state, event.submenu);

        case 'title':
            return titleL.set(state, event.title);

        case 'clearSearchQuery':
            return searchQueryL.set(state, null);

        case 'searchQuery':
            return searchQueryL.set(state, event.searchQuery);

        case 'paginationScrollId':
            return paginationScrollIdL.set(state, event.value);

        case 'paginationHasMore':
            return paginationHasMoreL.set(state, event.value);

        case 'total':
            return totalL.set(state, event.value);

        case 'generatorsFetch':
            return generatorsL.set(state, event.value);

        case 'generatorsUpsert': {
            const nameL = inArray('name', event.value.name);
            const generators = generatorsL.get(state);
            const generator = nameL.get(generators);
            return generatorsL.set(
                state,
                generator ? nameL.set(generators, event.value) : [event.value, ...generators]
            );
        }

        case 'generatorsRemove':
            return generatorsL.set(
                state,
                generatorsL.get(state).map(R.when(R.pipe(R.prop('name'), R.includes(R.__, event.removed)), markRemoved))
            );

        case 'saveDependency': {
            const dependencies = dependenciesL.get(state);
            const existingIndex = R.findIndex(
                R.whereEq({
                    type: R.path(['value', 'type'], event),
                    code: R.path(['value', 'code'], event)
                }),
                dependencies
            );
            return dependenciesL.set(
                state,
                existingIndex === -1
                    ? dependencies.concat(event.value)
                    : R.update(existingIndex, event.value, dependencies)
            );
        }

        case 'resetDependencies':
            return dependenciesL.set(state, []);

        case 'statsUpdate': {
            const stats = Object.assign({}, statsL.get(state));
            return statsL.set(state, Object.assign(stats, event.stats));
        }

        case 'typesSearchQuery':
            return typesSearchQueryL.set(state, event.searchQuery);

        case 'showConfirmationDialog':
            return showConfirmationDialogL.set(state, event.dialogType);

        case 'hideConfirmationDialog':
            return showConfirmationDialogL.set(state, null);

        case 'typesHierarchy':
            return typesHierarchyL.set(state, event.hierarchy);

        case 'setEditMode':
            return editModeL.set(state, event.mode);

        case 'setLoading':
            return loadingL.set(state, event.loading);
    }

    return state;
}
