import {GRID_COLUMN_REORDER_TYPE} from '../../constants/dragTypes';
import {useDrop} from 'react-dnd';

type DragItem = {
    columnIndex: number;
};

export const useDropTarget = ({columnIndex, changeColumnOrder, logColumnReorder}) => {
    return useDrop(
        () => ({
            accept: GRID_COLUMN_REORDER_TYPE,
            canDrop: (item: DragItem) => {
                const {columnIndex: currentColumnIndex} = item;
                return columnIndex > 0 && columnIndex !== currentColumnIndex;
            },
            drop: (item: DragItem) => {
                const {columnIndex: currentColumnIndex} = item;
                changeColumnOrder(currentColumnIndex, columnIndex);
                logColumnReorder({
                    sourceIndex: currentColumnIndex,
                    targetIndex: columnIndex
                });
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                monitorId: monitor.getHandlerId()
            })
        }),
        []
    );
};
