import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.less';
import {connect} from 'react-redux';
import {pick} from 'ramda';
type Props = {
    loading?: boolean;
};

const LoaderIndicator = ({loading}: Props) =>
    loading && (
        <div className={styles['loader-wrapper']}>
            <CircularProgress />
        </div>
    );

const mapStateToProps = pick(['loading']);
export default connect(mapStateToProps)(LoaderIndicator);
