import DefaultChange from '../Change/Change';
import clsx from 'clsx';
import styles from './styles.less';
import {NestedChangeType} from '../activityLog.types';

function BulletPoint() {
    return <div className={styles['bullet-point']} />;
}

function NestedChange(props: NestedChangeType) {
    const {changes, header, className, ChangeComponent} = props;
    const Change = ChangeComponent || DefaultChange;
    return (
        <div className={clsx(styles['nested-change'], className)}>
            {header}
            <div>
                {changes.map((change) => (
                    <div key={change.displayName} className={styles['nested-change__container']}>
                        <BulletPoint />
                        <Change {...change} className={styles['nested-change__item']} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NestedChange;
