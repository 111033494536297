import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {checkRemoved} from '../../core/marks';
import {getNumberLabel} from '../../rdm-sdk/utils';
export default function unmappedCountCellRenderer({rowData, cellData}: Record<string, any>) {
    const isRemoved = checkRemoved(rowData);
    return (
        cellData > 0 && (
            <Tooltip title={i18n.text('Total unmapped values')}>
                <div
                    style={{
                        color: isRemoved ? 'inherit' : 'rgba(251, 0, 53, 1)'
                    }}
                    className={styles['unmapped-count-cell']}
                >
                    <Icon>warning</Icon>
                    {getNumberLabel(cellData)}
                </div>
            </Tooltip>
        )
    );
}
