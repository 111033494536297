import * as R from 'ramda';
import Cell from './Cell/Cell';
import GridColumnsMenu from '../AddGridColumnMenu/AddGridColumnMenu';
import Header from '../GridHeader/GridHeader';
import MarkLabel from '../MarkLabel/MarkLabel';
import SourceValue from '../Mapping/SourceValue/SourceValue';
import i18n from 'ui-i18n';
import languagesMock from './languagesMock';
import localizationEmptyIcon from '../../assets/inline/localizationEmpty.svg';
import styles from './localization.less';
import withCache from '../Cache/withCache';
import {AutoSizer, GridProps, MultiGrid} from 'react-virtualized';
import {CACHE_KEYS} from '../../constants/common';
import {CSSProperties, Component} from 'react';
import {ColumnItem, Coordinates, HiddenColumnsRecord} from '../../rdm-sdk/grid.types';
import {DraftMode, ScrollData, Sorting} from '../../rdm-sdk/app.types';
import {ElementRef} from 'react';
import {Link} from 'react-router';
import {LocalizationItem, LookupValue} from '../../rdm-sdk/lookups.types';
import {SCROLL_OFFSET} from '../../constants/common';
import {StateEvent} from '../../rdm-sdk/state.types';
import {ValuesSearchQuery} from '../../rdm-sdk/filters.types';
import {checkClientOnly, checkRemoved, markEdited, markNew, markRemoved} from '../../core/marks';
import {connect} from 'react-redux';
import {fromNull, fromObject} from '../../core/maybe';
import {
    localizationColumnHideUpdater,
    localizationColumnOrderUpdater,
    localizationColumnResizeUpdater,
    localizationColumnShowUpdater,
    localizationLanguageAddUpdater,
    localizationLanguagesUpdater
} from './columnsHelpers';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {moveScrollOnColumnAdd} from '../../rdm-sdk/grid';
import {noop} from '../../core/util';
import {searchQueryIsNotEmpty} from '../Mapping/searchHelpers';
import {upsertValueEvent} from '../../redux/actions/values';
export const REGULAR_COLUMN_WIDTH = 182;
export const FIXED_COLUMN_COUNT = 1;
export const FIRST_COLUMN_NAME = i18n.text('Canonical value');
const FIRST_COLUMN_WIDTH = 220;
const ADD_BUTTON_WIDTH = 48;
const FIXED_ROWS_COUNT = 1;
const HEADER_HEIGHT = 47;
const ROW_HEIGHT = 32;
const OVERSCAN_ROW_COUNT = 10;
const OVERSCAN_COLUMN_COUNT = 0;
const languagesLabelsMap = languagesMock.reduce((acc, curr) => {
    return Object.assign(acc, {
        [curr.label]: curr
    });
}, {});
export const LANGUAGES = languagesMock.map(R.assoc('columnWidth', REGULAR_COLUMN_WIDTH));

const getLang = (value) => languagesLabelsMap[value] || {};

const getColumnWidth =
    (len: number, columnItems: ColumnItem[]) =>
    ({index}) => {
        if (index === 0) return FIRST_COLUMN_WIDTH;
        if (index === len - 1) return ADD_BUTTON_WIDTH;
        const item = columnItems[index - FIXED_COLUMN_COUNT];
        return item ? item.columnWidth : REGULAR_COLUMN_WIDTH;
    };

const rowHeight = ({index}) => (index === 0 ? HEADER_HEIGHT : ROW_HEIGHT);

const upsertLocalizationItem = (item) => (localizations) => {
    const index = R.findIndex((curr) => curr.languageCode === item.languageCode, localizations);
    return index === -1
        ? R.concat(localizations, [markNew(item)])
        : R.adjust(index, (localization) => Object.assign({}, localization, markEdited(item)), localizations);
};

export const upsertLocalization = (lookupValue: LookupValue, languageCode: string, value: string): LookupValue => ({
    ...lookupValue,
    localizations: upsertLocalizationItem({
        languageCode,
        value
    })(lookupValue.localizations || [])
});
export const deleteLocalization = (lookupValue: LookupValue, languageCode: string): LookupValue => ({
    ...lookupValue,
    localizations: fromNull(lookupValue.localizations)
        .map(R.map(R.when(R.propEq('languageCode', languageCode), markRemoved)))
        // @ts-ignore
        .orSome(undefined) as LocalizationItem[] | undefined
});
export const getLookupLocalization = (lookupValue: LookupValue, languageCode: string): LocalizationItem => {
    return R.pipe(
        R.prop('localizations'),
        R.defaultTo([]),
        R.find(R.propEq('languageCode', languageCode)),
        R.defaultTo({
            languageCode,
            value: ''
        })
    )(lookupValue);
};

type CanonicalCellProps = {
    value: LookupValue;
    searchQuery: ValuesSearchQuery;
};

const CanonicalCell = ({value, searchQuery}: CanonicalCellProps) => {
    const highlightCell = !checkClientOnly(value) && searchQueryIsNotEmpty(searchQuery);
    const isRemoved = checkRemoved(value);
    return (
        <div className={styles['localization__canonical-cell']}>
            <SourceValue
                isRemoved={isRemoved}
                isCanonical={true}
                value={value.canonical}
                searchQuery={searchQuery}
                highlightCell={highlightCell}
            />
            <div className={styles['localization__canonical-cell-ellipsis']} />
            <MarkLabel entity={value} position="right" />
        </div>
    );
};

type EmptyValuesProps = {
    tenant: string;
    currentType: string;
};
export const EmptyValues = ({tenant, currentType}: EmptyValuesProps) => (
    <section className={'empty-state'}>
        <div
            className={'empty-state__icon'}
            dangerouslySetInnerHTML={{
                __html: localizationEmptyIcon
            }}
        />
        <p className={'empty-state__header'}>
            {i18n.text('This is where you can enter language translations for canonical values.')}
            <br />
            <br />
            <Link className={styles['localization__link']} to={`/${tenant}/types/${currentType}/mapping`}>
                {i18n.text('Go to Mapping page')}
            </Link>
            {' ' + i18n.text('and add canonical values before adding language translations.')}
        </p>
    </section>
);
export const EmptyLanguages = () => (
    <section
        style={{
            position: 'relative',
            top: HEADER_HEIGHT + 100,
            left: FIRST_COLUMN_WIDTH,
            width: `calc(100% - ${FIRST_COLUMN_WIDTH}px)`
        }}
    >
        <div className={'empty-state'}>
            <div
                className={'empty-icon'}
                dangerouslySetInnerHTML={{
                    __html: localizationEmptyIcon
                }}
            />
            <p>{i18n.text('Add a new language column to enter language translations for canonical values.')}</p>
        </div>
    </section>
);
export type Props = {
    dispatch: (e: StateEvent) => void;
    lookupValues: LookupValue[];
    draftMode: DraftMode;
    sorting: Sorting;
    tenant: string;
    loadMoreMapped: (callback?: () => void) => void;
    currentType: string;
    onCanonicalValueSort: () => void;
    showValues: boolean;
    onScroll?: (arg0: ScrollData) => void;
    gridRef?: (arg0: ElementRef<typeof MultiGrid>) => void;
    scrollToRow: number;
    query: ValuesSearchQuery;
    cacheData: {
        languages: ColumnItem[];
        extraLanguages: ColumnItem[];
        hiddenColumns?: HiddenColumnsRecord[];
    };
    setCacheData: (arg0: {
        languages: ColumnItem[];
        extraLanguages: ColumnItem[];
        hiddenColumns: HiddenColumnsRecord[];
    }) => void;
    isEditMode: boolean;
};
export type State = {
    editorCell: Coordinates | null;
    selectedColumn?: number | null;
    languages: ColumnItem[];
    extraLanguages: ColumnItem[];
    hiddenColumns: HiddenColumnsRecord[];
    propsToTrack: {
        lookupValues: LookupValue[];
    };
};

type GridRef = {
    _bottomLeftGrid: GridProps;
    _bottomRightGrid: GridProps;
    recomputeGridSize: () => void;
};

const logger = logActivityCommand('localization-grid');
const sortingLogger = logger('sorting-change');
const localeAddLogger = logger('add-locale-click');
const changeLogger = logger('change-translation');
const deleteLogger = logger('delete-translation');
const loadOnScrollLogger = logger('load-on-scroll');
const resizeColumnLogger = logger('resize-column');
const reorderColumnLogger = logger('reorder-column');

type GridCellRendererProps = {
    columnIndex: number;
    key: string;
    rowIndex: number;
    style: CSSProperties;
};
export class Localization extends Component<Props, State> {
    _grid?: Element & GridRef;
    inputRefs: Record<string, HTMLInputElement>;
    blockUpload?: boolean;
    state = {
        editorCell: null,
        selectedColumn: null,
        languages: R.pathOr([], ['cacheData', 'languages'], this.props),
        extraLanguages: R.pathOr(LANGUAGES, ['cacheData', 'extraLanguages'], this.props),
        hiddenColumns: R.pathOr([], ['cacheData', 'hiddenColumns'], this.props),
        propsToTrack: {
            lookupValues: []
        }
    } as State;

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const {lookupValues: nextLookupValues} = nextProps;
        const {
            propsToTrack: {lookupValues: prevLookupValues}
        } = prevState;
        const nextState = {
            propsToTrack: {
                lookupValues: nextLookupValues
            }
        };

        if (prevLookupValues.length !== nextLookupValues.length || prevLookupValues[0] !== nextLookupValues[0]) {
            return {
                ...nextState,
                ...localizationLanguagesUpdater(prevState, nextProps)
            };
        } else {
            return nextState;
        }
    }

    constructor(props: Props) {
        super(props);
        this.inputRefs = {};
    }

    resetScroll = () => {
        const _scrollingContainer = this?._grid?._bottomRightGrid._scrollingContainer;
        _scrollingContainer!.scrollTop = 0;
    };
    onSortClick = () => {
        const {
            dispatch,
            onCanonicalValueSort,
            sorting: {direction}
        } = this.props;
        onCanonicalValueSort();
        dispatch(
            sortingLogger({
                field: 'value',
                direction
            }) as StateEvent
        );
        this.resetScroll();
    };
    logColumnResize = R.compose(this.props.dispatch, resizeColumnLogger);
    logColumnReorder = R.compose(this.props.dispatch, reorderColumnLogger);

    componentDidUpdate(prevProps: Props, prevState: State) {
        const conditions = [
            this.props.lookupValues !== prevProps.lookupValues,
            this.state.languages !== prevState.languages,
            this.state.hiddenColumns !== prevState.hiddenColumns
        ];

        if (conditions.some(R.identity) && this._grid) {
            this?._grid?.recomputeGridSize();
        }
    }

    componentWillUnmount(): void {
        this.props.setCacheData(R.pick(['languages', 'extraLanguages', 'hiddenColumns'], this.state));
    }

    changeColumnOrder = (sourceIndex: number, targetIndex: number) => {
        this.setState(localizationColumnOrderUpdater(sourceIndex, targetIndex), () =>
            this.setSelectedColumn(targetIndex)
        );
    };
    onLanguageAdd = (language: ColumnItem, index = -1) => {
        this.props.dispatch(
            localeAddLogger({
                language,
                index
            }) as StateEvent
        );
        this.setState(localizationLanguageAddUpdater(language, index), () => moveScrollOnColumnAdd(this?._grid, index));
    };
    onColumnHide = (label: string, columnIndex: number) =>
        this.setState(localizationColumnHideUpdater(label, columnIndex));
    onColumnShow = (columnIndex: number) => this.setState(localizationColumnShowUpdater(columnIndex));
    onColumnResize = (columnIndex: number, deltaX: number) =>
        this.setState(localizationColumnResizeUpdater(columnIndex, deltaX));
    registerDraftInputRef = (row: number, col: number) => (ref: HTMLInputElement) => {
        this.inputRefs[`${row}_${col}`] = ref;
    };
    getNextEnabledRow = ({row, col}: Coordinates, dRow: number): number => {
        let nextRow = row + dRow,
            nextInput = this.inputRefs[`${nextRow}_${col}`];

        while (nextInput?.disabled) {
            nextRow += dRow;
            nextInput = this.inputRefs[`${nextRow}_${col}`];
        }

        return nextRow;
    };
    navigateUp = ({row, col}: Coordinates, key?: string) => {
        const nextRow = this.getNextEnabledRow(
            {
                row,
                col
            },
            -1
        );
        const input = this.inputRefs[`${nextRow}_${col}`];
        input?.focus();

        if (!input && row > 0) {
            const _scrollingContainer = this?._grid?._bottomRightGrid._scrollingContainer;
            _scrollingContainer.scrollTop = ROW_HEIGHT * nextRow;
            setTimeout(
                () =>
                    this.navigateFromCell(
                        {
                            row: nextRow + 1,
                            col
                        },
                        key
                    ),
                0
            );
        }
        return input;
    };

    navigateDown = ({row, col}: Coordinates, key?: string) => {
        const nextRow = this.getNextEnabledRow(
            {
                row,
                col
            },
            1
        );
        const input = this.inputRefs[`${nextRow}_${col}`];
        input?.focus();

        if (nextRow >= this.props.lookupValues.length - 1) {
            this.props.loadMoreMapped(() => {
                this.navigateFromCell(
                    {
                        row: nextRow - 1,
                        col
                    },
                    key
                );
                delete this.blockUpload;
            });
        } else if (!input) {
            this.blockUpload = true;
            const _scrollingContainer = this?._grid?._bottomRightGrid._scrollingContainer;
            _scrollingContainer!.scrollTop = ROW_HEIGHT * nextRow;
            setTimeout(
                () =>
                    this.navigateFromCell(
                        {
                            row: nextRow - 1,
                            col
                        },
                        key
                    ),
                0
            );
        }
        return input;
    };

    navigateLeft = ({row, col}: Coordinates) => {
        const input = this.inputRefs[`${row}_${col - 1}`];
        input?.focus();
        const nextInput = this.inputRefs[`${row}_${col - 2}`];

        if (input && !nextInput) {
            const _scrollingContainer = this?._grid?._bottomRightGrid._scrollingContainer;
            _scrollingContainer!.scrollLeft -= REGULAR_COLUMN_WIDTH;
        }

        return input;
    };

    navigateRight = ({row, col}: Coordinates) => {
        const input = this.inputRefs[`${row}_${col + 1}`];
        input?.focus();
        const nextInput = this.inputRefs[`${row}_${col + 2}`];

        if (input && !nextInput) {
            const _scrollingContainer = this?._grid?._bottomRightGrid?._scrollingContainer;
            _scrollingContainer!.scrollLeft += REGULAR_COLUMN_WIDTH;
        }
        return input;
    };

    navigateFromCell = ({row, col}: Coordinates, key?: string): HTMLInputElement | null => {
        let input = null as HTMLInputElement | null;

        switch (key) {
            case 'ArrowUp':
                return this.navigateUp({row, col}, key);

            case 'ArrowDown':
                return this.navigateDown({row, col}, key);

            case 'ArrowLeft':
                return this.navigateLeft({row, col});

            case 'ArrowRight':
                return this.navigateRight({row, col});

            default:
                input = this.inputRefs[`${row}_${col}`];
                input && input.focus();
        }

        return input;
    };
    setEditorCell = (editorCell: Coordinates | null): Promise<void> =>
        new Promise((resolve) =>
            this.setState(
                {
                    editorCell
                },
                resolve
            )
        );
    setSelectedColumn = (selectedColumn?: number | null) => {
        this.setState({
            selectedColumn
        });
    };
    onCellChange = (lookupValue: LookupValue, languageCode: string, value: string) => {
        const {dispatch, currentType} = this.props;
        lookupValue = upsertLocalization(lookupValue, languageCode, value);
        dispatch(upsertValueEvent(currentType, R.prop('code', lookupValue), markEdited(lookupValue)));
        dispatch(
            changeLogger({
                value: {
                    code: lookupValue.code,
                    canonical: lookupValue.canonical
                },
                languageCode,
                currentType
            }) as StateEvent
        );
    };
    onCellDelete = (lookupValue: LookupValue, languageCode: string) => {
        const {dispatch, currentType} = this.props;
        lookupValue = deleteLocalization(lookupValue, languageCode);
        dispatch(upsertValueEvent(currentType, R.prop('code', lookupValue), markEdited(lookupValue)));
        dispatch(
            deleteLogger({
                value: {
                    code: lookupValue.code,
                    canonical: lookupValue.canonical
                },
                languageCode,
                currentType
            }) as StateEvent
        );
    };

    headerRenderer = ({
        columnIndex,
        key,
        style,
        cell,
        rowIndex,
        columnName,
        columnHeight,
        columnNames,
        relativeToSelectedColumn
    }) => {
        const {draftMode, sorting, isEditMode} = this.props;
        const {languages, extraLanguages, hiddenColumns} = this.state;
        const columnWidth = getColumnWidth(
            columnNames.length,
            languages
        )({
            index: columnIndex
        });
        if (rowIndex === 0 && columnIndex === columnNames.length - FIXED_COLUMN_COUNT) {
            return extraLanguages.length && isEditMode ? (
                <GridColumnsMenu style={style} key={key} items={extraLanguages} onClick={this.onLanguageAdd} />
            ) : (
                false
            );
        } else if (rowIndex === 0) {
            return (
                <Header
                    onSortClick={draftMode.dirtyValues ? noop : this.onSortClick}
                    onResize={this.onColumnResize}
                    sorting={sorting}
                    isEditMode={isEditMode}
                    label={columnName}
                    style={style}
                    key={key}
                    changeColumnOrder={this.changeColumnOrder}
                    setSelectedColumn={this.setSelectedColumn}
                    columnIndex={columnIndex}
                    columnHeight={columnHeight}
                    columnWidth={columnWidth}
                    isSelected={relativeToSelectedColumn === 'center'}
                    extraItems={extraLanguages}
                    onColumnAdd={this.onLanguageAdd}
                    onColumnHide={this.onColumnHide}
                    onColumnShow={this.onColumnShow}
                    hiddenColumns={hiddenColumns}
                    fixedColumnCount={FIXED_COLUMN_COUNT}
                    logColumnResize={this.logColumnResize}
                    logColumnReorder={this.logColumnReorder}
                />
            );
        } else {
            return (
                <div key={key} style={style}>
                    {
                        // @ts-ignore
                        cell.orSome('')
                    }
                </div>
            );
        }
    };

    cellRenderer =
        ({columnNames, columnHeight}: {columnNames: string[]; columnHeight: number}) =>
        // eslint-disable-next-line react/display-name
        ({columnIndex, key, rowIndex, style}: GridCellRendererProps) => {
            const {lookupValues, query, isEditMode} = this.props;
            const {editorCell, selectedColumn} = this.state;
            const columnName = columnNames[columnIndex];
            const mappingValue = lookupValues[rowIndex - FIXED_ROWS_COUNT];
            const row = rowIndex - FIXED_ROWS_COUNT;
            const col = columnIndex - FIXED_COLUMN_COUNT;
            const leftRightRelative = columnIndex < Number(selectedColumn) ? 'left' : 'right';
            const relativeToSelectedColumn = columnIndex === selectedColumn ? 'center' : leftRightRelative;
            const cell = fromObject(mappingValue).map((lookupValue) => {
                const localization = getLookupLocalization(lookupValue, getLang(columnName).code);

                if (columnIndex === 0) {
                    return <CanonicalCell key={lookupValue.code} value={lookupValue} searchQuery={query} />;
                } else if (columnIndex === columnNames.length - FIXED_COLUMN_COUNT) {
                    return false;
                } else {
                    return (
                        <Cell
                            key={lookupValue.code}
                            isDisabled={!isEditMode || checkRemoved(lookupValue)}
                            localization={localization}
                            onChange={(loc) => {
                                if (localization.value !== loc.value && loc.value === '') {
                                    this.onCellDelete(lookupValue, loc.languageCode);
                                } else if (localization.value !== loc.value) {
                                    this.onCellChange(lookupValue, loc.languageCode, loc.value);
                                }
                            }}
                            onDelete={() => {
                                localization.value && this.onCellDelete(lookupValue, localization.languageCode);
                            }}
                            setEditorCell={this.setEditorCell}
                            registerDraftInputRef={this.registerDraftInputRef}
                            editorCell={editorCell}
                            currentCell={{
                                row,
                                col
                            }}
                            navigateFromCell={this.navigateFromCell}
                            changeColumnOrder={this.changeColumnOrder}
                            columnIndex={columnIndex}
                            relativeToSelectedColumn={relativeToSelectedColumn}
                            totalValues={lookupValues.length}
                            setSelectedColumn={this.setSelectedColumn}
                            logColumnReorder={this.logColumnReorder}
                        />
                    );
                }
            });

            return this.headerRenderer({
                columnIndex,
                key,
                style,
                cell,
                rowIndex,
                columnName,
                columnHeight,
                columnNames,
                relativeToSelectedColumn
            });
        };

    handleScroll = (data) => {
        const {loadMoreMapped, dispatch, onScroll} = this.props;

        if (onScroll) {
            onScroll(data);
        }

        const {scrollHeight, clientHeight, scrollTop} = data;

        if (scrollHeight - clientHeight - scrollTop <= SCROLL_OFFSET && !this.blockUpload) {
            loadMoreMapped(R.pipe(loadOnScrollLogger, dispatch));
        }
    };

    render() {
        const {lookupValues, tenant, currentType, showValues, gridRef, scrollToRow} = this.props;
        const {languages, hiddenColumns} = this.state;
        const hiddenLanguageLabels = R.pipe(R.pluck('items'), R.flatten, R.pluck('label'))(hiddenColumns);
        const columnNames = R.pipe(
            R.pluck('label'),
            R.reject(R.includes(R.__, hiddenLanguageLabels)),
            R.concat([FIRST_COLUMN_NAME]),
            R.concat(R.__, ['__add__'])
        )(languages);
        return showValues ? (
            <div className={styles['localization']}>
                <AutoSizer>
                    {({width, height}) => (
                        <MultiGrid
                            ref={(grid) => {
                                // @ts-ignore
                                this._grid = grid;

                                if (grid && gridRef) {
                                    // @ts-ignore
                                    gridRef(grid?._bottomLeftGrid);
                                }
                            }}
                            cellRenderer={this.cellRenderer({
                                columnNames,
                                columnHeight: Math.min(height - HEADER_HEIGHT, lookupValues.length * ROW_HEIGHT)
                            })}
                            width={width}
                            height={height}
                            fixedColumnCount={FIXED_COLUMN_COUNT}
                            enableFixedColumnScroll={true}
                            hideBottomLeftGridScrollbar={true}
                            fixedRowCount={FIXED_ROWS_COUNT}
                            scrollToColumn={0}
                            columnWidth={getColumnWidth(columnNames.length, languages)}
                            estimatedColumnSize={REGULAR_COLUMN_WIDTH}
                            columnCount={columnNames.length}
                            rowHeight={rowHeight}
                            rowCount={lookupValues.length + FIXED_ROWS_COUNT}
                            onScroll={this.handleScroll}
                            overscanRowCount={OVERSCAN_ROW_COUNT}
                            overscanColumnCount={OVERSCAN_COLUMN_COUNT}
                            scrollToRow={scrollToRow >= 0 ? scrollToRow + FIXED_ROWS_COUNT : undefined}
                            scrollToAlignment={'start'}
                        />
                    )}
                </AutoSizer>
                {languages.length === 0 && hiddenColumns.length === 0 && <EmptyLanguages />}
            </div>
        ) : (
            <EmptyValues tenant={tenant} currentType={currentType} />
        );
    }
}
const mapStateToProps = R.pick(['currentType', 'lookupValues', 'draftMode', 'sorting', 'tenant']);
export default R.pipe(connect(mapStateToProps), withCache(CACHE_KEYS.LOCALIZATION))(Localization);
