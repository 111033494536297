import * as R from 'ramda';
import initialState, {collaborationInitialState} from '../initialState';
import {CollaborationObjectType} from '../../rdm-sdk/collaboration.types';
import {combineReducers} from 'redux';
import {leftJoin} from '../../core/util';
const generalCollaborationReducer = combineReducers({
    commentsCount: commentsCountReducer,
    comments: commentsReducer,
    discussion: discussionReducer
});
// eslint-disable-next-line prettier/prettier
export default function collaborationReducer(state = initialState.collaboration, event) { // NOSONAR
    if (Object.values(CollaborationObjectType).includes(event.key)) {
        return {
            ...state,
            [event.key]: generalCollaborationReducer(state?.[event.key], event)
        };
    } else {
        return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function commentsCountReducer(state = collaborationInitialState.commentsCount, event) { // NOSONAR
    switch (event.type) {
        case 'setCommentsCount':
            return event.commentsCount;

        case 'updateCommentsCount':
            return {...state, ...event.commentsCount};

        case 'appendCommentCount': {
            const newCommentCount = [
                {
                    commentId: event.comment.commentId,
                    replies: event.comment.replies.length,
                    status: 'open'
                }
            ];
            const prevCommentCount = R.prop(event.comment.objectId, state);
            return Object.assign({}, state, {
                [event.comment.objectId]: prevCommentCount
                    ? R.concat(prevCommentCount, newCommentCount)
                    : newCommentCount
            });
        }

        case 'deleteCommentCount': {
            return R.evolve(
                {
                    [event.comment.objectId]: R.reject(R.propEq('commentId', event.comment.commentId))
                },
                state
            );
        }

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function commentsReducer(state = collaborationInitialState.comments, event) { // NOSONAR
    switch (event.type) {
        case 'setComment':
            return R.assoc(event.commentId, event.comment, state);

        case 'setComments':
            return event.comments.reduce((comments, comment) => {
                return {
                    ...comments,
                    ...{
                        [comment.commentId]: comment
                    }
                };
            }, state);

        case 'appendCommentReplies':
        case 'deleteCommentReply':
        case 'updateComment':
        case 'updateCommentReply': {
            const comment = state[event.commentId];
            return R.assoc(event.commentId, commentReducer(comment, event), state);
        }

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function commentReducer(comment = {}, event) { // NOSONAR
    switch (event.type) {
        case 'appendCommentReplies':
            return R.evolve(
                {
                    replies: R.concat(R.__, event.replies)
                },
                comment
            );

        case 'deleteCommentReply':
            return R.evolve(
                {
                    replies: R.reject(R.propEq('replyId', event.replyId))
                },
                comment
            );

        case 'updateComment':
            return {...comment, ...event.update};

        case 'updateCommentReply':
            return R.evolve(
                {
                    replies: R.map((reply) => (reply.replyId === event.reply.replyId ? event.reply : reply))
                },
                comment
            );

        default:
            return comment;
    }
}
// eslint-disable-next-line prettier/prettier
export function discussionReducer(state = collaborationInitialState.discussion, event) { // NOSONAR
    switch (event.type) {
        case 'updateDiscussion':
            return R.evolve(
                {
                    commentIds: leftJoin(R.__, event.discussion.commentIds),
                    nextPageToken: () => event.discussion.nextPageToken
                },
                state
            );

        case 'resetDiscussion':
            return collaborationInitialState.discussion;

        case 'deleteDiscussionComment':
            return R.evolve(
                {
                    commentIds: R.reject(R.equals(event.commentId))
                },
                state
            );

        case 'prependDiscussionComment':
            return R.evolve(
                {
                    commentIds: R.concat([event.commentId])
                },
                state
            );

        case 'upliftDiscussionComment':
            return R.evolve(
                {
                    commentIds: R.pipe(R.reject(R.equals(event.commentId)), R.concat([event.commentId]))
                },
                state
            );

        default:
            return state;
    }
}
