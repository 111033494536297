import * as R from 'ramda';
import Button from '@mui/material/Button';
import Dialog from '../Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '../TextField/TextField';
import i18n from 'ui-i18n';
import styles from './add-type-dialog.less';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {addTypeEvent} from '../../redux/actions/types';
import {connect} from 'react-redux';
import {fromString} from '../../core/maybe';
import {getValue, isUriUnique} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {markClientOnly, markNew} from '../../core/marks';
import {pipe} from '../../core/func';
import {push, replace} from 'react-router-redux';
import {typeCodeToUri, typeUriToCode} from '../../rdm-sdk/types';
import {validateLookupType} from './validation';

const getTypeInformation = ({uri, label}) => ({
    uri,
    label,
    description: '',
    enabled: true,
    startDate: Date.now(),
    endDate: null
});

type StateProps = {
    configuration: Configuration;
    tenant: string;
};

type Props = StateProps & {
    dispatch: (e: StateEvent) => void;
    onCreateClick?: () => void;
    onCancelClick?: () => void;
    onClose?: () => void;
    open?: boolean;
};

type State = {
    label: string;
    uri: string;
    open: boolean;
};
const logger = logActivityCommand('type-modal');
const addLogger = logger('add-save-click');
const cancelLogger = logger('cancel-click');
export class AddTypeDialog extends Component<Props, State> {
    shouldPrepopulateCode: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            label: '',
            uri: '',
            open: true
        };
        this.shouldPrepopulateCode = true;
    }

    turnOffCodePrepopulation = () => (this.shouldPrepopulateCode = false);
    next = (field: keyof State) => (data: any) =>
        this.setState({
            [field]: data
        } as State);
    close = () => {
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
            this.setState({
                label: '',
                uri: ''
            });
        } else {
            this.setState({
                open: false
            });
        }
    };
    prepopulateCode = R.pipe(R.toUpper, R.replace(/\s/g, '_'), typeCodeToUri, this.next('uri'));
    onCreateClick = () => {
        const {dispatch, tenant} = this.props;
        const type = encodeURIComponent(typeUriToCode(this.state.uri));
        pipe(
            getTypeInformation,
            markNew,
            markClientOnly,
            addTypeEvent,
            dispatch,
            R.always(replace(`/${tenant}/types/${type}`)),
            dispatch
        )(this.state);
    };
    handleOnCreateClick = () =>
        typeof this.props.onCreateClick === 'function' ? this.props.onCreateClick() : this.onCreateClick();
    onCancelClick = () => {
        const {dispatch, tenant} = this.props;
        dispatch(push(`/${tenant}/types`));
    };
    handleOnCancelClick = () =>
        typeof this.props.onCancelClick === 'function' ? this.props.onCancelClick() : this.onCancelClick();
    getOpen = () => (typeof this.props.open === 'boolean' ? this.props.open : this.state.open);

    render() {
        const {
            dispatch,
            configuration: {lookupTypes}
        } = this.props;
        const label = fromString(this.state.label).orSome('');
        const code = fromString(this.state.uri).map(typeUriToCode).orSome('');
        const isDuplicateUri = !isUriUnique(this.state.uri, lookupTypes);
        const isValid = validateLookupType(this.state).isSuccess() && !isDuplicateUri;
        const loggableInfo = {
            label,
            code
        };
        return (
            <Dialog open={this.getOpen()} className={styles['add-type-dialog']}>
                <DialogTitle>{i18n.text('New lookup type')}</DialogTitle>
                <DialogContent>
                    <TextField
                        name="name"
                        onChange={pipe(
                            getValue,
                            R.tap(R.when(R.always(this.shouldPrepopulateCode), this.prepopulateCode)),
                            this.next('label')
                        )}
                        value={label}
                        label={i18n.text('Lookup type name')}
                        required
                    />
                    <TextField
                        name="code"
                        onChange={pipe(getValue, typeCodeToUri, this.next('uri'), this.turnOffCodePrepopulation)}
                        value={code}
                        label={i18n.text('Lookup type code')}
                        required
                        helperText={isDuplicateUri ? i18n.text('Code already exists') : ''}
                        error={isDuplicateUri}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="grey"
                        onClick={R.pipe(
                            this.handleOnCancelClick,
                            this.close,
                            R.always(loggableInfo),
                            cancelLogger,
                            dispatch
                        )}
                    >
                        {i18n.text('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        disabled={!isValid}
                        onClick={R.pipe(
                            this.handleOnCreateClick,
                            this.close,
                            R.always(loggableInfo),
                            addLogger,
                            dispatch
                        )}
                    >
                        {i18n.text('Done')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps: (state) => StateProps = R.pick(['configuration', 'tenant']);
export default connect(mapStateToProps)(AddTypeDialog);
