import {LookupType} from '../../rdm-sdk/types.types';
import {Maybe, Validation} from 'monet';
import {typeUriToCode} from '../../rdm-sdk/types';
export const validateLabel = (lookup) =>
    typeof lookup.label === 'string' && lookup.label.length > 0
        ? Validation.success(lookup.label)
        : Validation.fail(['label is empty']);
export const validateUri = (lookup: LookupType) => {
    const code: string = Maybe.fromNull(lookup.uri)
        .map((uri) => uri.toString())
        .map(typeUriToCode)
        .orSome('');
    return code.length > 0 ? Validation.success(lookup.uri) : Validation.fail(['uri invalid']);
};
export const validateLookupType = (lookup) => validateLabel(lookup).ap(validateUri(lookup).acc());
