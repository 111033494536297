type Props = {
    relativeToSelectedColumn: 'left' | 'right' | 'center';
};
export default function DragHeaderHintLine({relativeToSelectedColumn}: Props) {
    return (
        <div
            style={{
                width: 3,
                height: 2000,
                zIndex: 100,
                position: 'absolute',
                backgroundColor: 'black',
                top: -1000,
                [relativeToSelectedColumn === 'left' ? 'left' : 'right']: 0
            }}
        />
    );
}
