import * as R from 'ramda';
import {createContext} from 'react';

type CacheContextType = {
    getCacheData: ((key: string) => string) | (() => void);
    setCacheData: (key: string) => (value: string) => void;
};
export default createContext<CacheContextType>({
    getCacheData: () => {},
    setCacheData: R.curry((_, __) => {})
});
