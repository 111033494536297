import * as R from 'ramda';
import * as React from 'react';
import DiscussionComment from '../DiscussionComment/DiscussionComment';
import Scrollbar from '../Scrollbar/Scrollbar';
import debounce from 'debounce';
import discussionEmptyIcon from '../../assets/inline/discussionEmpty.svg';
import i18n from 'ui-i18n';
import styles from './discussion-dialog.less';
import {
    CollaborationUpdate,
    Comment as CommentType,
    GeneralCollaboration,
    NewReply,
    ReplyAction
} from '../../rdm-sdk/collaboration.types';
import {Component} from 'react';
import {Session, User} from '../../rdm-sdk/app.types';
import {connect} from 'react-redux';
import {
    createReplySaga,
    deleteCommentSaga,
    loadNextDiscussionSaga,
    reopenCommentSaga,
    resolveCommentSaga,
    updateCommentSaga
} from '../../redux/actions/collaboration';
import {getCodeFromObjectId, getCommentUpdate, getMentions} from '../../rdm-sdk/collaboration';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {then} from '../../core/func';

const Triangle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className={styles['discussion-dialog__triangle']}
        preserveAspectRatio="xMinYMid meet"
        width={12}
        height={24}
    >
        <polygon points="12,0 0,12 12,24" />
    </svg>
);

const EmptyState = () => (
    <div
        style={{
            position: 'relative',
            width: '100%',
            height: 'calc(100% - 40px)'
        }}
    >
        <div className={'empty-state'}>
            <div
                className={'empty-state__icon'}
                dangerouslySetInnerHTML={{
                    __html: discussionEmptyIcon
                }}
            />
            <p className={'empty-state__header'}>{i18n.text('No comments')}</p>
        </div>
    </div>
);

const logger = logActivityCommand('collaboration-popup');
const loadOnScrollLogger = logger('load-on-scroll');
const resolveLogger = logger('resolve-click');
const reopenLogger = logger('reopen-click');
const deleteCommentLogger = logger('delete-comment-click');
const saveCommentLogger = logger('save-comment-click');
const addReplyLogger = logger('add-reply-click');

type StateProps = {
    canonicals: Record<string, string>;
    collaboration: GeneralCollaboration;
    users: User[];
    session: Session;
};

type DispatchProps = {
    loadNextDiscussion: () => Promise<any>;
    createReply: (commentId: string, newReply: NewReply) => Promise<any>;
    reopenComment: (comment: CommentType, content?: string) => Promise<any>;
    resolveComment: (comment: CommentType) => Promise<any>;
    deleteComment: (comment: CommentType) => Promise<any>;
    updateComment: (commentId: string, update: CollaborationUpdate) => Promise<any>;
    logLoadOnScroll: () => void;
    logCommentResolve: (comment: CommentType) => void;
    logCommentReopen: (comment: CommentType) => void;
    logCommentDelete: (comment: CommentType) => void;
    logCommentSave: (arg0: {comment: CommentType; content: string}) => void;
    logReplyAdd: (arg0: {comment: CommentType; content: string}) => void;
};

type Props = StateProps &
    DispatchProps & {
        objectType: string;
    };
export class DiscussionDialog extends Component<Props> {
    scrollbarRef: (ref: React.ElementRef<typeof Scrollbar>) => void;
    scrollbar?: React.ElementRef<typeof Scrollbar> | null;

    constructor(props: Props) {
        super(props);

        this.scrollbarRef = (ref) => (this.scrollbar = ref);
    }

    componentDidMount() {
        const {
            collaboration: {discussion}
        } = this.props;

        if (typeof discussion.nextPageToken === 'undefined') {
            this.props.loadNextDiscussion();
        }
    }

    loadMore = debounce(
        R.pipe(
            this.props.loadNextDiscussion,
            then(R.when(R.pipe(R.prop('items'), R.length), R.pipe(R.always(undefined), this.props.logLoadOnScroll)))
        )
    );
    onScrollFrame = (values: Record<string, any>) => {
        if (values.top > 0.99) {
            this.loadMore();
        }
    };
    scrollTop = (to = 0) => {
        const scrollbars = this.scrollbar && this.scrollbar.getScrollbarsRef();
        // @ts-ignore
        scrollbars?.scrollTop(to);
    };
    onCommentDeleteClick = (comment: CommentType) => {
        const {deleteComment} = this.props;
        return deleteComment(comment);
    };
    onCommentEdited = (comment: CommentType) => (content: string) => {
        const {updateComment, logCommentSave} = this.props;
        logCommentSave({
            comment,
            content
        });
        const update = getCommentUpdate(comment, content);
        return updateComment(comment.commentId, update).then(() => this.scrollTop());
    };
    onResolveClick = (comment: CommentType) => {
        const {resolveComment} = this.props;
        return resolveComment(comment).then(() => this.scrollTop());
    };
    onReopenClick = (comment: CommentType) => {
        const {reopenComment} = this.props;
        return reopenComment(comment).then(() => this.scrollTop());
    };
    createReply = (comment: CommentType, content: string) => {
        const {reopenComment, createReply, logReplyAdd} = this.props;
        logReplyAdd({
            comment,
            content
        });
        const isResolved = comment.status === 'resolved';
        const newReply: NewReply = {
            content: content,
            namedUsers: getMentions(content),
            action: ReplyAction.NONE
        };
        const resultP = isResolved ? reopenComment(comment, content) : createReply(comment.commentId, newReply);
        return resultP.then(() => this.scrollTop());
    };

    render() {
        const {collaboration, session, users, canonicals, logCommentResolve, logCommentDelete, logCommentReopen} =
            this.props;
        const comments = collaboration.discussion.commentIds
            .map(R.prop(R.__, collaboration.comments))
            .filter(Boolean) as CommentType[];
        return (
            <div className={styles['discussion-dialog']}>
                <Triangle />
                <Scrollbar onScrollFrame={this.onScrollFrame} ref={this.scrollbarRef}>
                    {comments.map((comment) => (
                        <DiscussionComment
                            key={comment.commentId}
                            comment={comment}
                            username={R.prop('username', session)}
                            users={users}
                            onCommentDeleteClick={R.pipe(R.tap(logCommentDelete), this.onCommentDeleteClick)}
                            onCommentEdited={this.onCommentEdited}
                            onResolveClick={R.pipe(R.tap(logCommentResolve), this.onResolveClick)}
                            onReopenClick={R.pipe(R.tap(logCommentReopen), this.onReopenClick)}
                            createReply={this.createReply}
                            canonical={
                                canonicals[getCodeFromObjectId(comment.objectId)] ||
                                getCodeFromObjectId(comment.objectId)
                            }
                        />
                    ))}
                    {comments.length === 0 && <EmptyState />}
                </Scrollbar>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    ({
        canonicals: R.reduce(
            (canonicals, lookupValue) => {
                return {
                    ...canonicals,
                    ...{
                        [lookupValue.code]: lookupValue.canonical
                    }
                };
            },
            {},
            state.lookupValues
        ),
        collaboration: state.collaboration[ownProps.objectType],
        ...R.pick(['users', 'session'], state)
    }) as StateProps;

const mapDispatchToProps = (dispatch, {objectType}) =>
    ({
        loadNextDiscussion: loadNextDiscussionSaga(dispatch, objectType),
        createReply: createReplySaga(dispatch, objectType),
        reopenComment: reopenCommentSaga(dispatch, objectType),
        resolveComment: resolveCommentSaga(dispatch, objectType),
        deleteComment: deleteCommentSaga(dispatch, objectType),
        updateComment: updateCommentSaga(dispatch, objectType),
        logLoadOnScroll: R.pipe(loadOnScrollLogger, dispatch),
        logCommentResolve: R.pipe(resolveLogger, dispatch),
        logCommentReopen: R.pipe(reopenLogger, dispatch),
        logCommentDelete: R.pipe(deleteCommentLogger, dispatch),
        logCommentSave: R.pipe(saveCommentLogger, dispatch),
        logReplyAdd: R.pipe(addReplyLogger, dispatch)
    }) as DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionDialog);
