/* eslint-disable complexity */
/**
 * Created by ndyumin on 11.04.2017.
 */
import * as R from 'ramda';
import {SearchQuery} from '../../rdm-sdk/filters.types';
import {checkRange} from '../../core/util';
import {fromNotEmpty} from '../../core/maybe';
import {initialQuery, initialTypeQuery} from './initialSearchQuery';
import {prop} from '../../core/lenses';
import {sourceAbbrToUri} from '../../rdm-sdk/sources';
export const parseType = (value) => {
    const startsWithExclamation = value[0] === '!';
    return {
        type: startsWithExclamation ? value.substring(1) : value,
        negate: startsWithExclamation
    };
};
const valueTypeL = prop('value').compose(prop('type'));
const valueNegateL = prop('value').compose(prop('negate'));
const codeTypeL = prop('code').compose(prop('type'));
const codeNegateL = prop('code').compose(prop('negate'));
const nameTypeL = prop('name').compose(prop('type'));
const nameNegateL = prop('name').compose(prop('negate'));
const canonicalValueTypeL = prop('canonicalValue').compose(prop('type'));
const canonicalValueNegateL = prop('canonicalValue').compose(prop('negate'));
const getValueOrNull = (value) => (value.length ? value : null);

// eslint-disable-next-line prettier/prettier
export const queryReducer = (state: SearchQuery = initialQuery, update) => { // NOSONAR
    switch (update.type) {
        case 'reset':
            return initialQuery;

        case 'fullUpdate':
            return update.value;

        case 'hasWords':
            return prop('hasWords').set(state, fromNotEmpty(update.value).orSome(null));

        case 'sources':
            return prop('sources').set(state, update.value);

        case 'valueType': {
            const parsedValueType = parseType(update.value);
            return valueTypeL.set(valueNegateL.set(state, parsedValueType.negate), parsedValueType.type);
        }

        case 'valueValue':
            return update.value !== null
                ? prop('value').compose(prop('value')).set(state, getValueOrNull(update.value))
                : prop('value').set(state, null);

        case 'valueNegate':
            return valueNegateL.set(state, update.value);

        case 'codeType': {
            const parsedCodeType = parseType(update.value);
            return codeTypeL.set(codeNegateL.set(state, parsedCodeType.negate), parsedCodeType.type);
        }

        case 'codeValue':
            return update.value !== null
                ? prop('code').compose(prop('value')).set(state, getValueOrNull(update.value))
                : prop('code').set(state, null);

        case 'codeNegate':
            return codeNegateL.set(state, update.value);

        case 'hasMissingValues':
            return prop('hasMissingValues').set(state, update.value);

        case 'startDate':
            return checkRange(update.value, state.endDate) ? prop('startDate').set(state, update.value) : state;

        case 'endDate':
            return checkRange(state.startDate, update.value) ? prop('endDate').set(state, update.value) : state;

        case 'nameType': {
            const parsedNameType = parseType(update.value);
            return nameTypeL.set(nameNegateL.set(state, parsedNameType.negate), parsedNameType.type);
        }

        case 'nameValue':
            return update.value !== null
                ? prop('name').compose(prop('value')).set(state, getValueOrNull(update.value))
                : prop('name').set(state, null);

        case 'nameNegate':
            return nameNegateL.set(state, update.value);

        case 'generator':
            return prop('generator').set(state, R.isEmpty(update.value) ? null : update.value);

        case 'canonicalCodeValue':
            return R.evolve(
                {
                    canonicalCode: {
                        value: () => (R.isEmpty(update.value) ? null : update.value)
                    }
                },
                state
            );

        case 'canonicalCodeNegate':
            return R.evolve(
                {
                    canonicalCode: {
                        negate: () => update.value
                    }
                },
                state
            );

        case 'canonicalValueType': {
            const parsedValueType = parseType(update.value);
            return canonicalValueTypeL.set(
                canonicalValueNegateL.set(state, parsedValueType.negate),
                parsedValueType.type
            );
        }

        case 'canonicalValueValue':
            return R.evolve(
                {
                    canonicalValue: {
                        value: () => (R.isEmpty(update.value) ? null : update.value)
                    }
                },
                state
            );

        case 'canonicalValueNegate':
            return R.evolve(
                {
                    canonicalValue: {
                        negate: () => update.value
                    }
                },
                state
            );

        default:
            return state;
    }
};
export const queryUpdater = (update) => (state) => {
    return {
        query: queryReducer(state.query, update)
    };
};
export const getTypesByNameQuery = (name) => {
    return {
        ...initialTypeQuery,
        name: {
            type: 'contains',
            negate: false,
            value: name
        }
    };
};
const sourcesToUri = R.map(R.pipe(R.prop('source'), sourceAbbrToUri));
export const getLookupsByCanonicalValueQuery = (value) => {
    return {
        ...initialQuery,
        canonicalValue: {
            type: 'contains',
            negate: false,
            value
        }
    } as SearchQuery;
};
export const getLookupsByMappingInSourcesQuery = (mapping, sources: {source: string}[] = []) => {
    return {
        ...initialQuery,
        value: {
            type: 'contains',
            negate: false,
            value: mapping.value
        },
        code: {
            type: 'equals',
            negate: false,
            value: mapping.code
        },
        sources: sourcesToUri(sources)
    } as SearchQuery;
};
