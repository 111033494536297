import * as R from 'ramda';
import ErrorPopup from 'react-components/dist/ErrorPopup/ErrorPopup';
import i18n from 'ui-i18n';
import {checkEdited, checkNew, checkRemoved, metadataReplacer} from '../../core/marks';
import {commandCreator} from '../middlewares/command-middleware';
import {json, requestWithBlockingSpinner} from '../../network';
import {then} from '../../core/func';

const hasNot = (prop) => R.complement(R.has(prop));

export const ensureDefaultGeneratorFields = R.map(
    R.when(R.both(R.propEq('type', 'SEQUENTIAL'), hasNot('rangeStart')), R.assoc('rangeStart', 0))
);
export function generatorsFetchEvent(value) {
    return {
        type: 'generatorsFetch',
        value
    };
}
export function generatorsUpsertEvent(value) {
    return {
        type: 'generatorsUpsert',
        value
    };
}
export const generatorForTypeUpsertEvent = R.curry((typeCode, generator) => ({
    type: 'generatorForTypeUpsert',
    typeCode,
    generator
}));
export function generatorsRemoveEvent(removed) {
    return {
        type: 'generatorsRemove',
        removed
    };
}
export const requestGeneratorsCommand = commandCreator(({state}) => {
    const {
        tenant,
        policies: {generatorsEdit}
    } = state;
    return generatorsEdit
        ? requestWithBlockingSpinner(`/rdm/generators/${tenant}/`, {}, i18n.text('Request generators error')).then(json)
        : Promise.resolve([]);
});
export const requestGeneratorsSaga = (dispatch) =>
    R.pipe(
        requestGeneratorsCommand,
        dispatch,
        then(R.pipe(ensureDefaultGeneratorFields, generatorsFetchEvent, dispatch))
    );
export const addGenerators = (tenant, dispatch, added) => {
    return added.length
        ? requestWithBlockingSpinner(
              `/rdm/generators/${tenant}`,
              {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(added, metadataReplacer)
              },
              i18n.text('Create generator error')
          )
        : Promise.resolve();
};
export const deleteGenerators = (tenant, dispatch, removed) => {
    const deletedItemsPromises = removed.map(({name}) =>
        requestWithBlockingSpinner(
            `/rdm/generators/${tenant}/${encodeURIComponent(name)}`,
            {
                method: 'DELETE'
            },
            i18n.text('Delete generator error')
        )
    );
    return deletedItemsPromises.length ? Promise.all(deletedItemsPromises) : Promise.resolve();
};
export const updateGeneratorsCommand = commandCreator(({state, dispatch}) => {
    const {configuration, generators, generatorsForTypes, tenant} = state;
    const addedGenerators = generators.filter(R.either(checkNew, checkEdited)).concat(R.values(generatorsForTypes));
    const removedGenerators = generators.filter(checkRemoved);
    const usedGenerators = R.uniq(R.pluck('generator', configuration.lookupTypes));
    const removableGenerators = removedGenerators.filter((gen) => {
        const isUsed = usedGenerators.includes(gen.name);
        isUsed &&
            ErrorPopup.addError({
                message: `Can't delete generator [${gen.name}] from tenant [${tenant}] till tenant has lookup types which use this generator`,
                title: i18n.text('Delete generator error')
            });
        return !isUsed;
    });
    return Promise.all([
        deleteGenerators(tenant, dispatch, removableGenerators),
        addGenerators(tenant, dispatch, addedGenerators)
    ]).catch((error) => console.error('updateGeneratorsCommand error:', error));
});
export const updateGeneratorsSaga = (dispatch) =>
    R.pipe(updateGeneratorsCommand, dispatch, then(requestGeneratorsSaga(dispatch)));
