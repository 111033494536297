import ActivityChanges from '../../parts/ActivityChanges/ActivityChanges';
import Change from '../../Change/Change';
import i18n from 'ui-i18n';
import {ActivityFieldItem} from '../../activityLog.types';
import {formatBool} from '../../../../rdm-sdk/activities';
import {formatDate} from '../../../../core/date';
type Props = {
    items: ActivityFieldItem[];
};

function TypeFields(props: Props) {
    const {items} = props;
    const {label, description, startDate, endDate, enabled, generator} = items.reduce(
        (fields, item) => ({...fields, [item.name]: item}),
        {} as Record<string, ActivityFieldItem>
    );
    return (
        <ActivityChanges>
            {label && (
                <Change
                    displayName={i18n.text('Name')}
                    newValue={label.newValue}
                    oldValue={label.oldValue}
                    type={label.type}
                />
            )}
            {description && (
                <Change
                    displayName={i18n.text('Description')}
                    newValue={description.newValue}
                    oldValue={description.oldValue}
                    type={description.type}
                />
            )}
            {startDate && (
                <Change
                    displayName={i18n.text('Start date')}
                    newValue={formatDate(startDate.newValue)}
                    oldValue={formatDate(startDate.oldValue)}
                    type={startDate.type}
                />
            )}
            {endDate && (
                <Change
                    displayName={i18n.text('End date')}
                    newValue={formatDate(endDate.newValue)}
                    oldValue={formatDate(endDate.oldValue)}
                    type={endDate.type}
                />
            )}
            {enabled && (
                <Change
                    displayName={i18n.text('Status')}
                    newValue={formatBool(enabled.newValue)}
                    oldValue={formatBool(enabled.oldValue)}
                    type={enabled.type}
                />
            )}
            {generator && (
                <Change
                    displayName={i18n.text('Code generator')}
                    newValue={generator.newValue}
                    oldValue={generator.oldValue}
                    type={generator.type}
                />
            )}
        </ActivityChanges>
    );
}

export default TypeFields;
