import GridMenu from '../GridMenu/GridMenu';
import MenuItem from '../GridMenu/GridMenuItem';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './collaboration-menu.less';
type Props = {
    onEditClick: () => void;
    onDeleteClick: (id: string) => Promise<any> | void;
    iconStyle?: Record<string, any>;
    iconClassName?: string;
    canEdit?: boolean;
    canDelete?: boolean;
};
export default function CollaborationMenu({
    onEditClick,
    onDeleteClick,
    iconStyle,
    iconClassName,
    canEdit = true,
    canDelete = true
}: Props) {
    return (
        <GridMenu
            iconStyle={iconStyle}
            iconClassName={clsx(styles['collaboration-menu__icon'], iconClassName)}
            className={styles['collaboration-menu']}
            tetherProps={{
                attachment: 'top right',
                targetAttachment: 'middle left',
                offset: '18px -27px',
                classes: {
                    element: styles['collaboration-menu__tether-element']
                }
            }}
        >
            <MenuItem isVisible={canEdit} className={styles['collaboration-menu-item']} onClick={onEditClick}>
                {i18n.text('Edit')}
            </MenuItem>
            <MenuItem isVisible={canDelete} className={styles['collaboration-menu-item']} onClick={onDeleteClick}>
                {i18n.text('Delete')}
            </MenuItem>
        </GridMenu>
    );
}
