import * as R from 'ramda';
import ActivityLogIconButton from '../../ActivityLog/ActivityLogIconButton/ActivityLogIconButton';
import Badge from '@mui/material/Badge';
import Bookmark from '../../Bookmark/Bookmark';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import UnmappedPanel from '../../UnmappedPanel';
import UnmappedPanelIcon from './UnmappedPanelIcon';
import ValuesSidePanel from '../../ActivityLog/LookupValues/ValuesSidePanel/ValuesSidePanel';
import Workflow from '../../Workflow/Workflow';
import WorkflowIconButton from '../../Workflow/WorkflowIconButton/WorkflowIconButton';
import i18n from 'ui-i18n';
import styles from './styles.less';
import withFeatures from '../../Feature/withFeatures';
import {Features} from '../../../rdm-sdk/features.types';
import {OpenedPanel, SidePanel} from '../mappingGrid.types';
import {Policies} from '../../../permissions/permissions.types';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {UnmappedValue} from '../../../rdm-sdk/unmapped.types';
import {closeClickLogger, openClickLogger} from '../../ActivityLog/logging';
import {closeClickWFLogger, openClickWFLogger} from '../../Workflow/logging';
import {connect} from 'react-redux';
import {getIsActivityLogEnabled} from '../../../redux/reducers/activityLogReducer';
import {getIsWorkflowEnabled, getWorkflowsSizeLabel} from '../../../redux/selectors/workflowSelectors';
import {getUnmappedTotal} from '../../../rdm-sdk/unmapped';
import {logActivityCommand} from '../../../redux/actions/activityLogging';
type Props = {
    dispatch: (e: StateEvent) => void;
    setIsDragging: (isDragging: boolean) => void;
    loadUnmapped: () => void;
    loadMoreUnmapped: (unmappedValue: UnmappedValue, onLoaded?: () => void) => void;
    isUnmappedDragging: boolean;
    resetSelectedMapping: () => void;
    policies: Policies;
    features: Features;
    openedPanel: OpenedPanel;
    setOpenedPanel: (arg0: OpenedPanel) => void;
    unmappedTotal: number;
    isActivityLogEnabled: boolean;
    isWorkflowEnabled: boolean;
    workflowsSizeLabel: string;
};
const unmappedPanelLogger = logActivityCommand('unmapped-panel');
const openUnmappedPanelLogger = unmappedPanelLogger('open-click');
const closeUnmappedPanelLogger = unmappedPanelLogger('close-click');

function MappingSidePanel(props: Props) {
    const {
        dispatch,
        setIsDragging,
        isUnmappedDragging,
        loadUnmapped,
        loadMoreUnmapped,
        resetSelectedMapping,
        policies,
        openedPanel,
        setOpenedPanel,
        unmappedTotal,
        isActivityLogEnabled,
        isWorkflowEnabled,
        workflowsSizeLabel
    } = props;

    function handleUnmappedPanelClose() {
        setOpenedPanel(null);
        dispatch(closeUnmappedPanelLogger(null) as StateEvent);
    }

    function handleUnmappedPanelOpen() {
        setOpenedPanel(SidePanel.UNMAPPED);
        dispatch(openUnmappedPanelLogger(null) as StateEvent);
    }

    function handleActivityLogClose() {
        setOpenedPanel(null);
        dispatch(closeClickLogger(null) as StateEvent);
    }

    function handleActivityLogOpen() {
        setOpenedPanel(SidePanel.ACTIVITY_LOG);
        dispatch(openClickLogger(null) as StateEvent);
    }

    function handleWorkflowOpen() {
        setOpenedPanel(SidePanel.WORKFLOW);
        dispatch(openClickWFLogger(null) as StateEvent);
    }

    function handleWorkflowClose() {
        setOpenedPanel(null);
        dispatch(closeClickWFLogger(null) as StateEvent);
    }

    switch (openedPanel) {
        case SidePanel.UNMAPPED:
            return (
                <UnmappedPanel
                    onClose={handleUnmappedPanelClose}
                    onClick={resetSelectedMapping}
                    setIsDragging={setIsDragging}
                    loadUnmapped={loadUnmapped}
                    loadMoreUnmapped={loadMoreUnmapped}
                    isUnmappedDragging={isUnmappedDragging}
                />
            );

        case SidePanel.ACTIVITY_LOG:
            return <ValuesSidePanel onClick={resetSelectedMapping} onClose={handleActivityLogClose} />;

        case SidePanel.WORKFLOW:
            return <Workflow onClick={resetSelectedMapping} onClose={handleWorkflowClose} />;

        default:
            return (
                <Bookmark>
                    {policies.lookupValuesRead && (
                        <Tooltip title={i18n.text('Unmapped values')}>
                            <Badge
                                badgeContent={unmappedTotal}
                                color="error"
                                classes={{
                                    badge: styles['unmapped-panel-icon__badge']
                                }}
                                overlap="rectangular"
                            >
                                <IconButton
                                    onClick={handleUnmappedPanelOpen}
                                    className={styles['unmapped-panel-icon']}
                                    size="large"
                                >
                                    <UnmappedPanelIcon />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    )}
                    {isActivityLogEnabled && <ActivityLogIconButton onClick={handleActivityLogOpen} />}
                    {isWorkflowEnabled && (
                        <WorkflowIconButton onClick={handleWorkflowOpen} sizeLabel={workflowsSizeLabel} />
                    )}
                </Bookmark>
            );
    }
}

const getUnmappedTotalAll = (state) => (state.unmapped.loadingTotals ? 0 : getUnmappedTotal(state.unmapped));

const mapStateToProps = (state, props) => ({
    unmappedTotal: getUnmappedTotalAll(state),
    workflowsSizeLabel: getWorkflowsSizeLabel(state),
    isActivityLogEnabled: getIsActivityLogEnabled(state, props),
    isWorkflowEnabled: getIsWorkflowEnabled(state, props),
    ...R.pick(['policies'], state)
});

export default R.pipe(connect(mapStateToProps), withFeatures)(MappingSidePanel);
