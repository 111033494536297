import * as React from 'react';
import CustomIcon from '../../../CustomIcon/CustomIcon';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';

type Props = {
    isParent: boolean;
    isLink?: boolean;
    isMultiParent: boolean;
    showParents?: boolean;
    onLinkClick: React.MouseEventHandler<HTMLElement>;
    toggleShowParents: React.MouseEventHandler<HTMLElement>;
};

const NodeIcon = ({isParent, isLink, isMultiParent, showParents, onLinkClick, toggleShowParents}: Props) => {
    const getMultiParentTooltip = () => {
        return isMultiParent ? (
            <Tooltip title={showParents ? i18n.text('Hide multiple parents') : i18n.text('Show multiple parents')}>
                <div
                    onClick={toggleShowParents}
                    className={clsx(
                        styles['marker'],
                        styles['marker--multi_parent'],
                        isParent ? styles['marker--parent'] : styles['marker--child']
                    )}
                >
                    <CustomIcon name="multi_parent" />
                </div>
            </Tooltip>
        ) : (
            <div className={clsx(styles['marker'], isParent ? styles['marker--parent'] : styles['marker--child'])} />
        );
    };
    return isLink ? (
        <Tooltip title={i18n.text('Show origin')}>
            <div
                onClick={onLinkClick}
                className={clsx(
                    styles['marker'],
                    styles['marker--link'],
                    isParent ? styles['marker--parent'] : styles['marker--child']
                )}
            >
                <CustomIcon name="link" />
            </div>
        </Tooltip>
    ) : (
        getMultiParentTooltip()
    );
};

export default NodeIcon;
