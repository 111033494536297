import ActivityLogIconButton from '../ActivityLogIconButton/ActivityLogIconButton';
import Bookmark from '../../Bookmark/Bookmark';
import styles from './styles.less';
type Props = {
    onClick: () => void;
};

function ActivityLogBookmark({onClick}: Props) {
    return (
        <Bookmark className={styles['bookmark']} onClick={onClick}>
            <ActivityLogIconButton />
        </Bookmark>
    );
}

export default ActivityLogBookmark;
