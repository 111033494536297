import * as R from 'ramda';
import MultiSelect from '../../../ReactSelect/MultiSelect';
import i18n from 'ui-i18n';
import {SelectSourceOption} from '../../activityLog.types';
import {connect} from 'react-redux';
import {getActivityLogFilterSources} from '../../../../redux/reducers/activityLogReducer';
import {setActivityLogFilterSourcesEvent} from '../../../../redux/actions/activityLog';
const toSourceOptions = R.map((source) => ({
    label: source.label,
    value: source.abbreviation,
    uri: source.uri
}));
type Props = {
    options: SelectSourceOption[];
    values: SelectSourceOption[];
    onChange: (values: SelectSourceOption[]) => void;
};
export function SourceSelect(props: Props) {
    const {options, values, onChange} = props;
    return <MultiSelect creatable label={i18n.text('Sources')} options={options} value={values} onChange={onChange} />;
}

const mapStateToProps = (state) => ({
    options: toSourceOptions(state.configuration.sources),
    values: getActivityLogFilterSources(state)
});

const mapDispatchToProps = {
    onChange: setActivityLogFilterSourcesEvent
};
export default connect(mapStateToProps, mapDispatchToProps)(SourceSelect);
