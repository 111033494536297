import FormControlLabel, {FormControlLabelProps} from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import clsx from 'clsx';
import styles from './styles.less';
import {Fragment} from 'react';
type Props = {
    label: string;
    hintForOnState: string;
    hintForOffState?: string;
    checked: boolean;
} & Omit<FormControlLabelProps, 'control'>;

const CustomSwitch = ({
    label,
    hintForOnState,
    hintForOffState = hintForOnState,
    checked,
    className,
    ...otherProps
}: Props) => (
    <div className={clsx(styles['custom-switch'], className)}>
        <FormControlLabel
            control={<Switch color="primary" className={styles['custom-switch__control']} />}
            checked={checked}
            label={
                <Fragment>
                    {label}
                    <FormHelperText className={styles['custom-switch__hint']}>
                        {(!checked && hintForOffState) || hintForOnState}
                    </FormHelperText>
                </Fragment>
            }
            className={styles['custom-switch__row']}
            classes={{
                label: styles['custom-switch__label'],
                disabled: styles['custom-switch__label--disabled']
            }}
            {...otherProps}
        />
    </div>
);

export default CustomSwitch;
