/**
 * Created by ndyumin on 19.10.2016.
 */
export function currentTypeEvent(currentType) {
    return {
        type: 'currentType',
        currentType
    };
}
export function lookupTypesRemoveEvent(types) {
    return {
        type: 'lookupTypesRemove',
        types
    };
}
export function addTypeEvent(typeInfo) {
    return {
        type: 'addType',
        typeInfo
    };
}
export function updateTypeEvent(typeInfo) {
    return {
        type: 'updateType',
        typeInfo
    };
}
export function typesSearchQueryEvent(searchQuery) {
    return {
        type: 'typesSearchQuery',
        searchQuery
    };
}
