import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Cell, Pie, PieChart} from 'recharts';
const COLORS = ['#B9E98A', '#F88080'];
type Props = {
    mappedSources: number;
    totalSources: number;
};

const PieChartCard = ({mappedSources, totalSources}: Props) => {
    const unmappedSources = totalSources - mappedSources;
    const data = [
        {
            name: 'Group A',
            value: mappedSources
        },
        {
            name: 'Group B',
            value: unmappedSources
        }
    ];
    return (
        <Card>
            <h3 className={styles['title']}>Mapping columns</h3>
            <CardContent className={styles['card-content']}>
                <div className={styles['chart-container']}>
                    <PieChart width={234} height={234}>
                        <Pie
                            data={data}
                            cx={110}
                            cy={110}
                            innerRadius={95}
                            outerRadius={110}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={index} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                    <div className={styles['pie-details']}>
                        <div className={styles['total-count']}>{totalSources}</div>
                        <div className={styles['label']}>{i18n.text('Columns')}</div>
                    </div>
                </div>
                <div className={styles['details-container']}>
                    <div className={styles['detail']}>
                        <span
                            className={styles['indicator']}
                            style={{
                                backgroundColor: COLORS[0]
                            }}
                        />
                        <div className={styles['group-count']}>{mappedSources}</div>
                        <div className={styles['label']}>{i18n.text('Mapped')}</div>
                    </div>
                    <div className={styles['detail']}>
                        <span
                            className={styles['indicator']}
                            style={{
                                backgroundColor: COLORS[1]
                            }}
                        />
                        <div className={styles['group-count']}>{unmappedSources}</div>
                        <div className={styles['label']}>{i18n.text('Unmapped')}</div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default PieChartCard;
