/**
 * Created by ndyumin on 11.04.2017.
 */
export class RequestBuilder {
    url = '/';
    params: string[] = [];

    constructor(url: string) {
        this.url = url;
    }

    addParam(param: string, value: string | number) {
        if (typeof value !== 'undefined') {
            this.params = this.params.concat(`${param}=${encodeURIComponent(value)}`);
        }

        return this;
    }

    build() {
        return `${this.url}?${this.params.join('&')}`;
    }
}
