/**
 * Created by ndyumin on 11.03.2017.
 */
import * as R from 'ramda';
import {CheckedPolicies, PermissionsFromServer, Policies, Privileges} from './permissions.types';
// Resources Definition: https://docs.google.com/spreadsheets/d/13jSavw70mnaGGd9kf3tvaW6cEj8B6Y1q4IxsPHBPItM/edit#gid=1843239409
const POLICIES: Policies = {
    configEdit: [
        {
            resourceId: 'rdm:config.*',
            privileges: toPrivileges('CRUD')
        }
    ],
    lookupValuesEdit: [
        {
            resourceId: 'rdm:config.*',
            privileges: toPrivileges('R')
        },
        {
            resourceId: 'rdm:data.lookups.*',
            privileges: toPrivileges('CRUD')
        }
    ],
    lookupValuesRead: [
        {
            resourceId: 'rdm:config.*',
            privileges: toPrivileges('R')
        },
        {
            resourceId: 'rdm:data.lookups.*',
            privileges: toPrivileges('R')
        }
    ],
    dependenciesEdit: [
        {
            resourceId: 'rdm:config.lookups.dependency.*',
            privileges: toPrivileges('CRUD')
        }
    ],
    generatorsEdit: [
        {
            resourceId: 'rdm:config.generators.*',
            privileges: toPrivileges('CRUD')
        },
        {
            resourceId: 'rdm:config.*',
            privileges: toPrivileges('R')
        }
    ],
    collaborationEdit: [
        {
            resourceId: 'collaboration:comments.*',
            privileges: toPrivileges('CRUD')
        },
        {
            resourceId: 'collaboration:status.*',
            privileges: toPrivileges('R')
        }
    ],
    usersRead: [
        {
            resourceId: 'Auth:customer.user.tenants.*',
            privileges: toPrivileges('R')
        }
    ],
    activityLogRead: [
        {
            resourceId: 'MDM:data.activityLog.personal.*',
            privileges: toPrivileges('R')
        }
    ],
    activityLogAllRead: [
        {
            resourceId: 'MDM:data.activityLog.*',
            privileges: toPrivileges('R')
        }
    ],
    workflowRead: [
        {
            resourceId: 'workflow:data.tasks.*',
            privileges: toPrivileges('R')
        },
        {
            resourceId: 'rdm:data.changeRequests.personal.*',
            privileges: toPrivileges('R')
        }
    ],
    workflowAllRead: [
        {
            resourceId: 'workflow:data.tasks.*',
            privileges: toPrivileges('R')
        },
        {
            resourceId: 'rdm:data.changeRequests.*',
            privileges: toPrivileges('R')
        }
    ],
    workflowEdit: [
        {
            resourceId: 'workflow:data.*',
            privileges: toPrivileges('CR')
        },
        {
            resourceId: 'rdm:data.changeRequests.personal.*',
            privileges: toPrivileges('CRU')
        }
    ],
    workflowAllEdit: [
        {
            resourceId: 'workflow:data.*',
            privileges: toPrivileges('CR')
        },
        {
            resourceId: 'rdm:data.changeRequests.*',
            privileges: toPrivileges('CRU')
        }
    ],
    workflowReview: [
        {
            resourceId: 'rdm:data.changeRequests.*',
            privileges: toPrivileges('E')
        }
    ],
    workflowTaskUpdate: [
        {
            resourceId: 'workflow:data.tasks.*',
            privileges: toPrivileges('U')
        }
    ],
    workflowDCRDelete: [
        {
            resourceId: 'rdm:data.changeRequests.*',
            privileges: toPrivileges('D')
        }
    ]
};
export const normalizePath = (path: string): string[] => path.replace(/:/g, '.').split('.').slice(0, -1);
export const parsePrivilegesFromServer = R.curry((tenantName: string, privilegesFromServer: PermissionsFromServer) => {
    const resourceIds = Object.keys(privilegesFromServer);
    return resourceIds.reduce((tree, resourceId) => {
        const resourcePrivileges = privilegesFromServer[resourceId];
        const privileges = Object.keys(resourcePrivileges).reduce((acc, key) => {
            const tenants = resourcePrivileges[key];
            if (
                R.any(
                    (tenant) =>
                        tenant === tenantName || tenant === 'SuperUser:*' || tenant.startsWith('SuperUser:allTenants')
                )(tenants)
            )
                acc[key] = true;
            return acc;
        }, {});
        const path = normalizePath(resourceId);
        const prevData = R.pathOr({}, path, tree);
        return R.assocPath(path, {...prevData, privileges}, tree);
    }, {});
});
export function traversePrivilegesTree([head, ...tail]: string[], tree: Record<string, any>, acc: Privileges = {}) {
    if (!head || !tree[head]) {
        return acc;
    } else {
        const privileges = R.mergeRight(tree[head].privileges, acc);
        return traversePrivilegesTree(tail, tree[head], privileges);
    }
}
export const checkRule = R.curry((privilegesTree, rule) => {
    const {resourceId, privileges} = rule;
    const servicePrivileges = traversePrivilegesTree(normalizePath(resourceId), privilegesTree);
    const superUserPrivileges = traversePrivilegesTree(['SuperUser', 'allTenants'], privilegesTree);
    return R.whereEq(privileges, R.mergeRight(servicePrivileges, superUserPrivileges));
});
const checkPolicy = R.curry((privilegesTree, policy) => policy.every(checkRule(privilegesTree)));
export function checkPoliciesAllAdvanced(policies: Policies, privilegesTree: Record<string, any>): CheckedPolicies {
    return R.map(checkPolicy(privilegesTree), policies);
}
export const checkPoliciesAll = R.curry(checkPoliciesAllAdvanced)(POLICIES);
export function toPrivileges(privilegesString: string): Privileges {
    const charMap = {
        C: 'CREATE',
        R: 'READ',
        U: 'UPDATE',
        D: 'DELETE',
        E: 'EXECUTE'
    };
    return privilegesString
        .toUpperCase()
        .split('')
        .filter((char) => Object.keys(charMap).includes(char))
        .reduce((privileges, char) => {
            return {...privileges, [charMap[char]]: true};
        }, {});
}
