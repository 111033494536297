import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {ACTIVITY_TYPE} from '../../constants';
type Props = {
    type: string;
};

function ListRowIcon({type}: Props) {
    switch (type) {
        case ACTIVITY_TYPE.ADD:
            return (
                <Tooltip title={i18n.text('Added')}>
                    <AddCircleOutlineIcon className={clsx(styles['list-row__icon'], styles['list-row__icon--add'])} />
                </Tooltip>
            );

        case ACTIVITY_TYPE.CHANGE:
            return (
                <Tooltip title={i18n.text('Edited')}>
                    <EditIcon className={clsx(styles['list-row__icon'], styles['list-row__icon--change'])} />
                </Tooltip>
            );

        case ACTIVITY_TYPE.DELETE:
            return (
                <Tooltip title={i18n.text('Deleted')}>
                    <DeleteIcon className={clsx(styles['list-row__icon'], styles['list-row__icon--delete'])} />
                </Tooltip>
            );

        default:
            return null;
    }
}

export default ListRowIcon;
