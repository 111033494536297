import ChangeItemDecorator from '../ChangeItemDecorator/ChangeItemDecorator';
import NestedChange from '../../../ActivityLog/NestedChange/NestedChange';
import {NestedChangeType} from '../../../ActivityLog/activityLog.types';

function DCRNestedChange(props: NestedChangeType) {
    const {id = null} = props;
    return (
        <ChangeItemDecorator id={id} grouped={true}>
            <NestedChange {...props} />
        </ChangeItemDecorator>
    );
}

export default DCRNestedChange;
