import * as R from 'ramda';
import Comment from './Comment/Comment';
import CommentDialogHeader from './CommentDialogHeader/CommentDialogHeader';
import CommentDialogInput from '../CommentDialogInput/CommentDialogInput';
import styles from './comment-dialog.less';
import {
    CollaborationOptions,
    CollaborationUpdate,
    CommentData,
    Comment as CommentType,
    GeneralCollaboration,
    NewComment,
    NewReply,
    Reply,
    ReplyAction
} from '../../rdm-sdk/collaboration.types';
import {PureComponent} from 'react';
import {Session, User} from '../../rdm-sdk/app.types';
import {connect} from 'react-redux';
import {
    createCommentSaga,
    createReplySaga,
    deleteCommentCountEvent,
    deleteCommentSaga,
    deleteDiscussionCommentEvent,
    deleteReplySaga,
    getCommentByObjectIdSaga,
    getCommentSaga,
    reopenCommentSaga,
    resolveCommentSaga,
    updateCommentReplySaga,
    updateCommentSaga
} from '../../redux/actions/collaboration';
import {getCommentPermlink, getCommentUpdate, getMentions, getObjectId} from '../../rdm-sdk/collaboration';
import {later, noop} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {typeCodeToUri} from '../../rdm-sdk/types';

const Triangle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className={styles['comment-dialog__triangle']}
        preserveAspectRatio="xMinYMid meet"
        width={20}
        height={20}
    >
        <polygon points="0,0 20,20 20,0" />
    </svg>
);

const logger = logActivityCommand('comment-popup');
const resolveLogger = logger('resolve-click');
const addCommentLogger = logger('add-comment-click');
const deleteCommentLogger = logger('delete-comment-click');
const saveCommentLogger = logger('save-comment-click');
const addReplyLogger = logger('add-reply-click');
const deleteReplyLogger = logger('delete-reply-click');
const saveReplyLogger = logger('save-reply-click');

type StateProps = {
    collaboration: GeneralCollaboration;
    currentType: string;
    session: Session;
    users: User[];
};

type DispatchProps = {
    createComment: (newComment: NewComment) => Promise<any>;
    getComment: (commentId: string) => Promise<any>;
    getCommentByObjectId: (commentId: string) => Promise<any>;
    createReply: (commentId: string, newReply: NewReply) => Promise<any>;
    reopenComment: (comment: CommentType, content?: string) => Promise<any>;
    resolveComment: (comment: CommentType) => Promise<any>;
    deleteComment: (comment: CommentType) => Promise<any>;
    deleteReply: (commentId: string, replyId: string) => Promise<any>;
    updateComment: (commentId: string, update: CollaborationUpdate) => Promise<any>;
    updateCommentReply: (commentId: string, replyId: string, update: CollaborationUpdate) => Promise<any>;
    cleanUpComment: (comment: CommentType) => void;
    logCommentResolve: (comment: CommentType) => void;
    logCommentAdd: (arg0: {content: string}) => void;
    logCommentDelete: (comment: CommentType) => void;
    logCommentSave: (arg0: {comment: CommentType; content: string}) => void;
    logReplyAdd: (arg0: {comment: CommentType; content: string}) => void;
    logReplyDelete: (reply: Reply) => void;
    logReplySave: (arg0: {reply: Reply; content: string}) => void;
};

type Props = StateProps &
    DispatchProps & {
        data: CommentData;
        collaborationOptions: CollaborationOptions;
        closeCommentDialog: () => void;
        commentId: string;
        isOpen: boolean;
        setParentHovered: (arg0: boolean) => () => void;
        openCommentDialog: () => void;
    };
export class CommentDialog extends PureComponent<Props> {
    comment?: CommentType | null = null;
    input?: HTMLTextAreaElement | null;

    componentDidMount() {
        const {commentId} = this.props;
        this.loadComment(commentId);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.commentId !== this.props.commentId) {
            this.loadComment(this.props.commentId);
        }
    }

    loadComment = (commentId: string) => {
        const {
            getComment,
            collaboration: {comments}
        } = this.props;

        if (commentId && !comments[commentId]) {
            getComment(commentId);
        }
    };
    onSendClick = (content: string) => {
        const {currentType, data, collaborationOptions, getCommentByObjectId, cleanUpComment} = this.props;
        const objectId = getObjectId(collaborationOptions.objectType, currentType, data);
        return getCommentByObjectId(objectId).then((recentComment) => {
            if (this.comment && !recentComment) {
                cleanUpComment(this.comment);
            }

            this.comment = recentComment;
            return recentComment ? this.createReply(content) : this.createComment(content);
        });
    };
    logSendClick = (content: string) =>
        this.comment
            ? this.props.logReplyAdd({
                  content,
                  comment: this.comment
              })
            : this.props.logCommentAdd({
                  content
              });
    createComment = (content: string) => {
        const {createComment, currentType, data, collaborationOptions} = this.props;
        const objectId = getObjectId(collaborationOptions.objectType, currentType, data);
        const newComment: NewComment = {
            objectId: objectId,
            objectType: collaborationOptions.objectType,
            visibility: 'public',
            content: content,
            permanentLink: getCommentPermlink(collaborationOptions.objectType, data),
            namedUsers: getMentions(content),
            relatedObjectUris: [typeCodeToUri(currentType), collaborationOptions.relatedObjectUri]
        };
        return createComment(newComment);
    };
    createReply = (content: string): Promise<any> => {
        const {createReply, closeCommentDialog, reopenComment} = this.props;
        const newReply: NewReply = {
            content: content,
            namedUsers: getMentions(content),
            action: ReplyAction.NONE
        };
        const commentId = R.prop('commentId', this.comment);

        if (!commentId || !this.comment) {
            closeCommentDialog();
            return Promise.resolve();
        }

        const isResolved = this.comment.status === 'resolved';
        return isResolved ? reopenComment(this.comment, content) : createReply(commentId, newReply);
    };
    onResolveClick = (comment: CommentType) => {
        const {resolveComment, closeCommentDialog} = this.props;
        return resolveComment(comment).then(closeCommentDialog);
    };
    onCommentDeleteClick = (comment: CommentType) => {
        const {deleteComment, closeCommentDialog} = this.props;
        return deleteComment(comment).then(closeCommentDialog);
    };
    onReplyDeleteClick = (reply: Reply): Promise<any> => {
        const {deleteReply, closeCommentDialog} = this.props;
        const commentId = R.prop('commentId', this.comment);

        if (!commentId) {
            closeCommentDialog();
            return Promise.resolve();
        }

        return deleteReply(commentId, reply.replyId);
    };
    onCommentEdited = (comment: CommentType, content: string) => {
        const {updateComment, logCommentSave} = this.props;
        logCommentSave({
            comment,
            content
        });
        const update = getCommentUpdate(comment, content);
        return updateComment(comment.commentId, update);
    };
    onReplyEdited = (reply: Reply, content: string): Promise<any> => {
        const {updateCommentReply, logReplySave} = this.props;
        const commentId = R.prop('commentId', this.comment);

        if (!commentId) {
            return Promise.resolve();
        }

        logReplySave({
            reply,
            content
        });
        const update: CollaborationUpdate = {
            content: content,
            namedUsers: getMentions(content)
        };
        return updateCommentReply(commentId, reply.replyId, update);
    };
    inputRef = (ref?: HTMLTextAreaElement | null) => {
        this.input = ref;
    };
    focusInput = () => this.input && this.input.focus();

    render() {
        if (!this.props.session) return false;
        const {
            session: {username},
            collaboration: {comments},
            commentId,
            users,
            setParentHovered,
            openCommentDialog,
            isOpen,
            logCommentResolve,
            logCommentDelete,
            logReplyDelete
        } = this.props;
        this.comment = comments[commentId];
        const openCommentDialogHandler = !isOpen ? R.pipe(openCommentDialog, later(this.focusInput)) : noop;
        return (
            <div
                className={styles['comment-dialog']}
                onMouseEnter={setParentHovered(true)}
                onMouseLeave={setParentHovered(false)}
                onClick={R.pipe((e) => e.stopPropagation(), openCommentDialogHandler)}
            >
                <Triangle />
                <div className={styles['comment-dialog__hover-area-extension']} />

                {!this.comment && <CommentDialogHeader username={username} />}
                <div className={styles['comment-dialog__comments']}>
                    {this.comment &&
                        [this.comment, ...this.comment.replies].map((comment, index) => (
                            <Comment
                                key={index}
                                username={username}
                                comment={comment}
                                onResolveClick={R.pipe(R.tap(logCommentResolve), this.onResolveClick)}
                                onCommentDeleteClick={R.pipe(R.tap(logCommentDelete), this.onCommentDeleteClick)}
                                onReplyDeleteClick={R.pipe(R.tap(logReplyDelete), this.onReplyDeleteClick)}
                                onCommentEdited={this.onCommentEdited}
                                onReplyEdited={this.onReplyEdited}
                                users={users}
                            />
                        ))}
                </div>

                <div className={styles['comment-input__container']}>
                    <CommentDialogInput
                        value=""
                        onSendClick={R.pipe(R.tap(this.logSendClick), this.onSendClick)}
                        onChange={openCommentDialogHandler}
                        users={users}
                        inputRef={this.inputRef}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps): StateProps => ({
    collaboration: state.collaboration[ownProps.collaborationOptions.objectType],
    ...R.pick(['session', 'currentType', 'users'], state)
});

const mapDispatchToProps = (dispatch, {collaborationOptions: {objectType}}) =>
    ({
        createComment: createCommentSaga(dispatch, objectType),
        getComment: getCommentSaga(dispatch, objectType),
        getCommentByObjectId: getCommentByObjectIdSaga(dispatch, objectType),
        createReply: createReplySaga(dispatch, objectType),
        reopenComment: reopenCommentSaga(dispatch, objectType),
        resolveComment: resolveCommentSaga(dispatch, objectType),
        deleteComment: deleteCommentSaga(dispatch, objectType),
        deleteReply: deleteReplySaga(dispatch, objectType),
        updateComment: updateCommentSaga(dispatch, objectType),
        updateCommentReply: updateCommentReplySaga(dispatch, objectType),
        cleanUpComment: (comment) => {
            dispatch(deleteCommentCountEvent(objectType, comment));
            dispatch(deleteDiscussionCommentEvent(objectType, comment.commentId));
        },
        logCommentResolve: R.pipe(resolveLogger, dispatch),
        logCommentAdd: R.pipe(addCommentLogger, dispatch),
        logCommentDelete: R.pipe(deleteCommentLogger, dispatch),
        logCommentSave: R.pipe(saveCommentLogger, dispatch),
        logReplyAdd: R.pipe(addReplyLogger, dispatch),
        logReplyDelete: R.pipe(deleteReplyLogger, dispatch),
        logReplySave: R.pipe(saveReplyLogger, dispatch)
    }) as DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CommentDialog);
