import store from '../redux/store';
import {checkToken, redirectTenantError} from '../core/auth';
import {getDeviceInfo} from '../core/device';
import {getIsTenantRegisteredSaga} from '../redux/actions/workflow';
import {initAnalytics} from '../core/analytics';
import {json} from '../network';
import {logActivityCommand} from '../redux/actions/activityLogging';
import {loggedInEvent, requestPermissionsSaga} from '../redux/actions/auth';
import {requestActivityLogTenantSaga} from '../redux/actions/activityLog';
import {requestGeneratorsSaga} from '../redux/actions/generators';
import {requestTenantConfigurationCommand, tenantConfigurationEvent} from '../redux/actions/configuration';
import {tenantChangedEvent} from '../redux/actions/tenants';
const TENANT_ID_PATTERN = /^\w{2,32}$/;
export default function checkAuthorization(router, replace, next) {
    const {session} = store.getState();
    const {dispatch} = store;
    const {tenantId} = router.params;

    if (!TENANT_ID_PATTERN.test(tenantId)) {
        redirectTenantError('nonexistentTenant');
    } else if (session === null) {
        checkToken()
            .then(json)
            .then((data) => {
                initAnalytics(data.user);
                const {username, email} = data.user;
                const launchLogger = logActivityCommand('navigation')('launch');
                dispatch(loggedInEvent(username, email));
                dispatch(launchLogger(getDeviceInfo()));
                next();
                dispatch(tenantChangedEvent(tenantId));
                requestPermissionsSaga(dispatch)().then(requestGeneratorsSaga(dispatch));
                requestActivityLogTenantSaga(dispatch)();
                getIsTenantRegisteredSaga(dispatch)();
                return requestTenantConfigurationCommand(tenantId);
            })
            .then((config) => dispatch(tenantConfigurationEvent(config)))
            .catch((err) => {
                if (err.message.startsWith('Tenant configuration not found for tenant id')) {
                    redirectTenantError('nonexistentTenant');
                } else if (err.message === 'Internal error' || err.message === 'Access is denied') {
                    redirectTenantError('tenantAccessDenied');
                } else {
                    console.error('Auth check unhandled error', err);
                }
            });
    } else {
        next();
    }
}
