import * as R from 'ramda';
import CacheProvider from '../../components/Cache/CacheProvider';
import EditTypeDialog from '../../components/EditTypeDialog/EditTypeDialog';
import EmptyState from './EmptyState';
import MenuItemsButton from '../../components/MenuItemsButton/MenuItemsButton';
import TypesHierarchyView from '../../components/TypesHierarchyView/TypesHierarchyView';
import TypesSearchBar from '../../components/Search/TypesSearch/TypesSearchBar';
import TypesSidePanel from '../../components/ActivityLog/LookupTypes/TypesSidePanel/TypesSidePanel';
import TypesTable from '../../components/TypesTable/TypesTable';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import withFeatures from '../../components/Feature/withFeatures';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DraftMode} from '../../rdm-sdk/app.types';
import {Features} from '../../rdm-sdk/features.types';
import {LookupType} from '../../rdm-sdk/types.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {Stats} from '../../rdm-sdk/stats.types';
import {StatusRightMenu} from '../../components/AppBar/StatusLine';
import {Subject} from 'rxjs';
import {connect} from 'react-redux';
import {currentTypeEvent, typesSearchQueryEvent} from '../../redux/actions/types';
import {getDispatchOnResolve} from '../../redux/middlewares/confirm-middleware/confirmMiddleware';
import {getIsActivityLogEnabled} from '../../redux/reducers/activityLogReducer';
import {getIsWorkflowEnabled} from '../../redux/selectors/workflowSelectors';
import {isExceptionRoute} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {push, replace} from 'react-router-redux';
import {
    requestHasLookupsDCRByTypesSaga,
    requestLookupTotalsByTypesSaga,
    requestUnmappedTotalsByTypesSaga
} from '../../redux/actions/stats';
import {
    resetActivitiesEvent,
    resetActivitiesTotalEvent,
    resetActivityLogFiltersEvent
} from '../../redux/actions/activityLog';
import {setDisplayedTaskEvent} from '../../redux/actions/workflow';
import {setIsSearchBarVisibleEvent} from '../../redux/actions/ui';
import {showConfirmationDialogEvent, titleEvent} from '../../redux/actions/app';
import {typeUriToCode} from '../../rdm-sdk/types';
import {typesHierarchyEvent} from '../../redux/actions/hierarchy';
const APP_TITLE = i18n.text('Lookup Types');
type Props = {
    route: Record<string, any>;
    router: Record<string, any>;
    dispatch: (e: StateEvent) => Promise<any>;
    dispatchOnResolve: (e: StateEvent) => Promise<any>;
    tenant: string;
    policies: CheckedPolicies;
    configuration: Configuration;
    stats: Stats;
    draftMode: DraftMode;
    features: Features;
    isActivityLogEnabled: boolean;
    isWorkflowEnabled: boolean;
};
type State = {
    editDialogOpen: boolean;
    hierarchyMode: boolean;
};
const addTypeLogger = logActivityCommand('types-page')('add-type-click');
const getCode = R.pipe(R.prop('uri'), typeUriToCode);
export class TypesPage extends Component<Props, State> {
    state = {
        editDialogOpen: false,
        hierarchyMode: false
    };
    isWorkflowEnabledStream$ = new Subject();
    isReceivedPolicies$ = new Subject();
    isLookupsReceived$ = new Subject();
    setCurrentType = R.pipe(getCode, currentTypeEvent, this.props.dispatch);
    openEditDialog = (type: LookupType) => {
        this.setCurrentType(type);
        this.setState({
            editDialogOpen: true
        });
    };
    closeEditDialog = () => {
        this.setState({
            editDialogOpen: false
        });
        this.props.dispatch(currentTypeEvent(null));
    };
    openAddDialog = () => {
        const {tenant, dispatch} = this.props;
        dispatch(push(`/${tenant}/types/NEW_LOOKUP_TYPE/mapping?mode=new_type`));
    };
    openValues = (type: LookupType) => {
        const {tenant, dispatch} = this.props;
        const typeCode = encodeURIComponent(getCode(type));
        dispatch(push(`/${tenant}/types/${typeCode}`));
        this.setCurrentType(type);
    };

    componentDidMount() {
        const {dispatch, route, router, dispatchOnResolve, isWorkflowEnabled, policies} = this.props;
        dispatch(titleEvent(APP_TITLE));
        dispatch(setDisplayedTaskEvent(null));
        const isPoliciesReceived$ = this.isReceivedPolicies$.startWith(!R.isEmpty(policies)).filter(R.identity);
        const isLookupsReceived$ = this.isReceivedPolicies$.startWith(!R.isEmpty(policies)).filter(R.identity);
        const isWorkflowEnabledStream$ = this.isWorkflowEnabledStream$.startWith(isWorkflowEnabled).filter(R.identity);
        isPoliciesReceived$
            .sample(isWorkflowEnabledStream$)
            .first()
            .subscribe(() => {
                if (R.isEmpty(this.props.stats.typesHasDCR)) {
                    requestHasLookupsDCRByTypesSaga(dispatch)();
                }
            });
        isPoliciesReceived$
            .sample(isLookupsReceived$)
            .first()
            .subscribe(() => {
                if (R.isEmpty(this.props.stats.valuesByTypes)) {
                    requestLookupTotalsByTypesSaga(dispatch)();
                }

                if (R.isEmpty(this.props.stats.unmappedByTypes)) {
                    requestUnmappedTotalsByTypesSaga(dispatch)();
                }
            });
        router.setRouteLeaveHook(route, (nextRoute) => {
            const {draftMode} = this.props;

            if (draftMode.dirtyTypes && !isExceptionRoute(nextRoute)) {
                if (nextRoute.action === 'POP') dispatch(replace(router.location));
                dispatch(showConfirmationDialogEvent());
                dispatchOnResolve(push(nextRoute));
                return false;
            }
        });
    }

    componentDidUpdate() {
        const {
            configuration: {lookupTypes},
            policies,
            isWorkflowEnabled
        } = this.props;
        this.isWorkflowEnabledStream$.next(isWorkflowEnabled);
        this.isReceivedPolicies$.next(!R.isEmpty(policies));
        this.isLookupsReceived$.next(!R.isEmpty(lookupTypes));
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(typesSearchQueryEvent(null));
        dispatch(setIsSearchBarVisibleEvent(false));
        dispatch(typesHierarchyEvent(null));
        dispatch(resetActivitiesEvent());
        dispatch(resetActivitiesTotalEvent());
        dispatch(resetActivityLogFiltersEvent());
    }

    toggleHierarchyMode = () =>
        this.setState(
            R.evolve({
                hierarchyMode: R.not
            })
        );

    render() {
        const {
            dispatch,
            policies,
            draftMode,
            configuration: {lookupTypes},
            isActivityLogEnabled
        } = this.props;
        const {editDialogOpen, hierarchyMode} = this.state;
        return (
            <div
                className={clsx(
                    styles['types-page__content'],
                    isActivityLogEnabled && styles['types-page__content--with-bookmark']
                )}
            >
                {lookupTypes.length > 0 ? (
                    <CacheProvider>
                        {hierarchyMode ? (
                            <TypesHierarchyView
                                openTypeDialog={this.openEditDialog}
                                openValues={this.openValues}
                                toggleHierarchyMode={this.toggleHierarchyMode}
                            />
                        ) : (
                            <TypesTable
                                openTypeDialog={this.openEditDialog}
                                openValues={this.openValues}
                                toggleHierarchyMode={this.toggleHierarchyMode}
                            />
                        )}
                    </CacheProvider>
                ) : (
                    <EmptyState isEditMode={policies.configEdit} addTypeClb={this.openAddDialog} />
                )}
                <EditTypeDialog
                    open={editDialogOpen}
                    onCancelled={this.closeEditDialog}
                    onEdited={this.closeEditDialog}
                    onRemoved={this.closeEditDialog}
                />
                {policies.configEdit && (
                    <MenuItemsButton onClick={R.pipe(this.openAddDialog, R.always(undefined), addTypeLogger, dispatch)}>
                        {i18n.text('LOOKUP TYPE')}
                    </MenuItemsButton>
                )}
                <StatusRightMenu
                    dispatch={dispatch}
                    isEditMode={policies.configEdit}
                    draftMode={draftMode}
                    renderSearchBar={() => <TypesSearchBar />}
                />
                {isActivityLogEnabled && <TypesSidePanel />}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    isActivityLogEnabled: getIsActivityLogEnabled(state, props),
    isWorkflowEnabled: getIsWorkflowEnabled(state, props),
    ...R.pick(['tenant', 'configuration', 'stats', 'draftMode', 'policies'], state)
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    dispatchOnResolve: getDispatchOnResolve(dispatch)
});

export default R.pipe(connect(mapStateToProps, mapDispatchToProps), withFeatures)(TypesPage);
