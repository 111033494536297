import * as R from 'ramda';
import ActivityContent from '../../parts/ActivityContent/ActivityContent';
import ActivityTitle from '../../parts/ActivityTitle/ActivityTitle';
import ActivityUserTime from '../../parts/ActivityUserTime/ActivityUserTime';
import TypeAttributes from '../TypeAttributes/TypeAttributes';
import TypeFields from '../TypeFields/TypeFields';
import i18n from 'ui-i18n';
import {ACTIVITY_TYPE} from '../../constants';
import {Fragment} from 'react';
import {TypeActivityItem} from '../../activityLog.types';
import {connect} from 'react-redux';
import {getTypesByNameQuery} from '../../../Search/store';
import {linkClickLogger} from '../../logging';
import {typesSearchQueryEvent} from '../../../../redux/actions/types';
type Props = {
    item: TypeActivityItem;
    filterTypesByName: (name: string) => void;
};

function TypesRowContent(props: Props) {
    const {
        item: {label, user, timestamp, type, fields = [], attributes = [], isHead = false},
        filterTypesByName
    } = props;
    const hasFields = fields.length > 0;
    const hasAttributes = attributes.length > 0;
    const hasChanges = hasAttributes || hasFields;

    function handleLinkClick() {
        filterTypesByName(label);
    }

    return (
        <Fragment>
            {isHead && <ActivityUserTime user={user} timestamp={timestamp} />}
            <ActivityTitle
                disabledLink={type === ACTIVITY_TYPE.DELETE}
                onLinkClick={handleLinkClick}
                type={type}
                title={i18n.text('Lookup type:')}
                label={label}
            />
            {hasChanges && (
                <ActivityContent>
                    {hasFields && <TypeFields items={fields} />}
                    {hasAttributes && <TypeAttributes items={attributes} />}
                </ActivityContent>
            )}
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => ({
    filterTypesByName: R.pipe(
        getTypesByNameQuery,
        R.tap(R.compose(dispatch, linkClickLogger)),
        typesSearchQueryEvent,
        dispatch
    )
});

export default connect(null, mapDispatchToProps)(TypesRowContent);
