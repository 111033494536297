import * as R from 'ramda';
import ColumnMenu from '../GridMenu/GridMenu';
import Draggable from 'react-draggable';
import ExtraItemsList from '../AddGridColumnMenu/ExtraItemsList';
import Icon from '@mui/material/Icon';
import MenuItem from '../GridMenu/GridMenuItem';
import TetherComponent from 'react-tether';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './header.less';
import {ColumnItem, HiddenColumnsRecord} from '../../rdm-sdk/grid.types';
import {Fragment, useEffect, useState} from 'react';
import {GRID_COLUMN_REORDER_TYPE} from '../../constants/dragTypes';
import {SortDirection, Sorting} from '../../rdm-sdk/app.types';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {useDrag} from 'react-dnd';
import {useDropTarget} from './useDropTarget';

type Props = {
    isEditMode: boolean;
    onResize?: ((index: number, offsetWidth: number) => void) | null;
    label: string;
    style: Record<string, any>;
    sorting: Sorting;
    onSortClick: () => void;
    columnHeight: number;
    columnWidth: number;
    setSelectedColumn: (columnIndex?: number | null) => void;
    columnIndex: number;
    isSelected: boolean;
    extraItems: ColumnItem[];
    onColumnAdd: (item: ColumnItem, insertColumnIndex?: number) => void;
    onColumnHide: (label: string, columnIndex: number) => void;
    onColumnShow: (columnIndex: number) => void;
    hiddenColumns: HiddenColumnsRecord[];
    fixedColumnCount: number;
    lookupValuesCount?: number | null;
    logColumnResize: (payload: {columnIndex: number; offsetX: number}) => Promise<any>;
    logColumnReorder: (payload: Record<string, any>) => Promise<any>;
    changeColumnOrder: (sourceIndex: number, targetIndex: number) => void;
};

const GridHeader = ({
    isEditMode,
    onResize,
    label,
    style,
    sorting,
    onSortClick,
    columnIndex,
    columnHeight,
    columnWidth,
    setSelectedColumn,
    isSelected,
    extraItems,
    onColumnAdd,
    onColumnHide,
    onColumnShow,
    hiddenColumns,
    fixedColumnCount,
    lookupValuesCount,
    logColumnResize,
    changeColumnOrder,
    logColumnReorder
}: Props) => {
    const [insertColumnIndex, setInsertIndex] = useState(-1);

    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true});
    }, []);

    const [{isDragging, monitorId}, drag, preview] = useDrag(
        () => ({
            type: GRID_COLUMN_REORDER_TYPE,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
                monitorId: monitor.getHandlerId()
            }),
            item: {
                columnIndex,
                columnHeight,
                columnWidth
            }
        }),
        []
    );

    const [, drop] = useDropTarget({columnIndex, changeColumnOrder, logColumnReorder});

    useEffect(() => {
        if (isDragging) {
            setSelectedColumn(columnIndex);
        }
    }, [isDragging]);

    const isFirstColumn = columnIndex === 0;
    const isResizable = typeof onResize === 'function';
    const hiddenIndices = R.pluck('index', hiddenColumns);
    const showExpandLeft = R.includes(columnIndex, hiddenIndices);
    const showExpandRight = R.includes(columnIndex + 1, hiddenIndices);
    const hasInserts = extraItems.length !== 0 && isEditMode;

    const dragHandle = (ref) => (
        <Draggable
            ref={ref}
            axis="x"
            defaultClassName="DragHandle"
            defaultClassNameDragging="DragHandleActive"
            onStop={(event, data) => {
                onResize && onResize(columnIndex, data.x);
                logColumnResize({
                    columnIndex,
                    offsetX: data.x
                });
            }}
            position={{
                x: 0,
                y: 0
            }}
        >
            <div className={styles['grid-header__drag-handle']} />
        </Draggable>
    );

    const header = (ref) => (
        <Fragment>
            <div
                ref={(_ref) => {
                    ref.current = _ref;
                    if (!isFirstColumn) {
                        drag(_ref);
                        drop(_ref);
                    }
                }}
                data-monitorid={monitorId}
                style={style}
                className={clsx(styles['grid-header'], isSelected && styles['grid-header--selected'])}
                onClick={() =>
                    !isSelected && columnIndex > 0 ? setSelectedColumn(columnIndex) : setSelectedColumn(null)
                }
            >
                {showExpandLeft && (
                    <Icon className={styles['grid-header__expand-left-icon']} onClick={() => onColumnShow(columnIndex)}>
                        arrow_drop_up
                    </Icon>
                )}
                <div className={styles['grid-header__label']} onClick={() => isFirstColumn && onSortClick()}>
                    {isFirstColumn && (
                        <Icon className={styles['grid-header__sort-icon']}>
                            {sorting.direction === SortDirection.ASC ? 'arrow_upward' : 'arrow_downward'}
                        </Icon>
                    )}
                    <span>{label}</span>
                    {!R.isNil(lookupValuesCount) && (
                        <span className={styles['grid-header__counter']}>({lookupValuesCount})</span>
                    )}
                </div>
                {showExpandRight && (
                    <Icon
                        className={styles['grid-header__expand-right-icon']}
                        onClick={() => onColumnShow(columnIndex + 1)}
                    >
                        arrow_drop_down
                    </Icon>
                )}
                {!isFirstColumn && (
                    <ColumnMenu
                        iconClassName={styles['grid-header__menu-icon']}
                        iconStyle={{
                            visibility: isSelected ? 'visible' : undefined
                        }}
                    >
                        <MenuItem isVisible={hasInserts} onClick={R.pipe(R.always(columnIndex - 1), setInsertIndex)}>
                            {i18n.text('Insert 1 left')}
                        </MenuItem>
                        <MenuItem isVisible={hasInserts} onClick={R.pipe(R.always(columnIndex), setInsertIndex)}>
                            {i18n.text('Insert 1 right')}
                        </MenuItem>
                        <MenuItem onClick={() => onColumnHide(label, columnIndex)}>{i18n.text('Hide column')}</MenuItem>
                    </ColumnMenu>
                )}
            </div>
        </Fragment>
    );

    return (
        <TetherComponent
            attachment="top left"
            targetAttachment="top left"
            constraints={[
                {
                    to: 'scrollParent',
                    pin: true
                }
            ]}
            renderTarget={(_ref) => (
                <TetherComponent
                    attachment="top left"
                    targetAttachment="top right"
                    constraints={[
                        {
                            to: 'scrollParent',
                            pin: true
                        }
                    ]}
                    targetOffset="0 -4px"
                    renderTarget={(ref) => {
                        // @ts-ignore
                        _ref.current = ref.current;
                        return header(ref);
                    }}
                    renderElement={(ref) => isResizable && !isFirstColumn && dragHandle(ref)}
                />
            )}
            renderElement={(ref) =>
                insertColumnIndex !== -1 && (
                    <ExtraItemsList
                        ref={ref}
                        items={extraItems}
                        onClickOutside={() => setInsertIndex(-1)}
                        onClick={(item: ColumnItem) => {
                            onColumnAdd(item, insertColumnIndex);
                            setSelectedColumn(insertColumnIndex + fixedColumnCount);
                            setInsertIndex(-1);
                        }}
                    />
                )
            }
        />
    );
};

export default GridHeader;
