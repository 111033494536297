import Scrollbar from '../Scrollbar/Scrollbar';
import UnmappedList from './UnmappedList';
import clsx from 'clsx';
import styles from './styles.less';
import {Command} from '../../redux/middlewares/command-middleware';
import {PureComponent} from 'react';
import {StateEvent} from '../../rdm-sdk/state.types';
import {Unmapped, UnmappedItem, UnmappedValue} from '../../rdm-sdk/unmapped.types';
import {connect} from 'react-redux';
import {
    requestUnmappedSaga,
    toggleUnmappedOrderEvent,
    unmappedScrollIdResetBySourceEvent,
    unmappedSelectItemToggleEvent
} from '../../redux/actions/unmapped';

const initCollapsedCols = (unmappedValues: UnmappedValue[]): {collapsedCols: number[]} => {
    const collapsedCols = unmappedValues.reduce((result: number[], unmappedValue, idx) => {
        return unmappedValue.total === 0 ? result.concat(idx) : result;
    }, []);
    return {
        collapsedCols
    };
};

type StateProps = {
    dispatch: (e: StateEvent) => void;
    unmapped: Unmapped;
};

export type UnmappedTableProps = StateProps & {
    searchQuery?: string | null;
    setIsDragging: (isDragging: boolean) => void;
    isUnmappedDragging: boolean;
    loadUnmapped: () => void;
    loadMoreUnmapped: (unmappedValue: UnmappedValue, onLoaded?: () => void) => void;
    className?: string;
    logger: (action: string) => (payload: any) => Command;
};
type State = {
    collapsedCols: number[];
    propsToTrack: {
        unmapped: Unmapped;
    };
};
export class UnmappedTable extends PureComponent<UnmappedTableProps, State> {
    constructor(props: UnmappedTableProps) {
        super(props);
        this.state = {
            collapsedCols: [],
            propsToTrack: {
                unmapped: this.props.unmapped
            }
        } as State;
    }

    static getDerivedStateFromProps(nextProps: UnmappedTableProps, prevState: State) {
        const {
            unmapped: {unmappedValues}
        } = nextProps;
        const {
            propsToTrack: {
                unmapped: {unmappedValues: prevUnmappedValues}
            }
        } = prevState;
        const nextState = {
            propsToTrack: {
                unmapped: {
                    unmappedValues
                }
            }
        };

        if (unmappedValues !== prevUnmappedValues) {
            return {...nextState, ...initCollapsedCols(unmappedValues)};
        } else {
            return nextState;
        }
    }

    componentDidMount() {
        const {
            unmapped: {unmappedValues},
            loadUnmapped
        } = this.props;

        if (!unmappedValues.length) {
            loadUnmapped();
        }

        this.setState(initCollapsedCols(unmappedValues));
    }

    onSort = ({source}: UnmappedValue) => {
        const {dispatch, logger} = this.props;
        dispatch(toggleUnmappedOrderEvent(source));
        dispatch(unmappedScrollIdResetBySourceEvent(source));
        dispatch(logger('sorting-change')(source) as StateEvent);
        return requestUnmappedSaga(dispatch, source);
    };
    onItemClick = (item: UnmappedItem): void => this.props.dispatch(unmappedSelectItemToggleEvent(item));
    onExpandClick = (colId: number) => {
        let {collapsedCols} = this.state;
        collapsedCols = collapsedCols.filter((i) => i !== colId);
        this.setState({
            collapsedCols
        });
    };

    render() {
        const {
            unmapped: {unmappedValues, selectedItems, removedItems},
            searchQuery,
            setIsDragging,
            isUnmappedDragging,
            loadMoreUnmapped,
            className
        } = this.props;
        const {collapsedCols} = this.state;
        return (
            <Scrollbar className={clsx(className, styles['unmapped-table__scroll-container'])}>
                <div className={styles['unmapped-table']}>
                    {unmappedValues.map((unmappedValue, idx) => {
                        return (
                            !~collapsedCols.indexOf(idx) && (
                                <UnmappedList
                                    key={unmappedValue.source}
                                    unmappedValue={unmappedValue}
                                    selectedItems={selectedItems}
                                    onItemClick={this.onItemClick}
                                    onSort={this.onSort}
                                    prevCollapsedId={!!~collapsedCols.indexOf(idx - 1) && idx - 1}
                                    nextCollapsedId={!!~collapsedCols.indexOf(idx + 1) && idx + 1}
                                    onExpandClick={this.onExpandClick}
                                    loadMore={loadMoreUnmapped}
                                    searchQuery={searchQuery}
                                    setIsDragging={setIsDragging}
                                    isUnmappedDragging={isUnmappedDragging}
                                    removedItems={removedItems}
                                />
                            )
                        );
                    })}
                </div>
            </Scrollbar>
        );
    }
}

const mapStateToProps = (state) =>
    ({
        unmapped: state.unmapped
    }) as StateProps;

export default connect(mapStateToProps)(UnmappedTable);
