import * as React from 'react';
import DragHeaderHintLine from '../../GridHeader/DragHeaderHintLine';
import MarkLabel from '../../MarkLabel/MarkLabel';
import clsx from 'clsx';
import styles from './cell.less';
import {Coordinates} from '../../../rdm-sdk/grid.types';
import {LocalizationItem} from '../../../rdm-sdk/lookups.types';
import {checkRemoved} from '../../../core/marks';
import {fieldUpdateHandler, noop} from '../../../core/util';
import {useDropTarget} from '../../GridHeader/useDropTarget';
import {useEffect, useState} from 'react';
type Props = {
    isDisabled: boolean;
    localization: LocalizationItem;
    onChange: (value: LocalizationItem) => void;
    currentCell: Coordinates;
    setEditorCell: (editorCell: Coordinates | null) => Promise<void>;
    registerDraftInputRef: (row: number, col: number) => (input: HTMLInputElement) => void;
    editorCell: Coordinates | null;
    navigateFromCell: (coordinates: Coordinates, key?: string) => HTMLInputElement | null;
    columnIndex: number;
    relativeToSelectedColumn: 'left' | 'right' | 'center';
    totalValues: number;
    onDelete: () => void;
    setSelectedColumn: (columnIndex?: number | null) => void;
    logColumnReorder: (payload: {sourceIndex: number; targetIndex: number}) => Promise<any>;
    changeColumnOrder: (sourceIndex: number, targetIndex: number) => void;
};

const Cell = (props: Props) => {
    const {
        isDisabled,
        localization,
        onChange,
        setEditorCell,
        registerDraftInputRef,
        editorCell,
        currentCell: {row, col},
        navigateFromCell,
        relativeToSelectedColumn,
        totalValues,
        onDelete,
        setSelectedColumn,
        columnIndex,
        changeColumnOrder,
        logColumnReorder
    } = props;
    const [draft, setDraft] = useState(localization.value);
    const [shouldClear, setShouldClear] = useState(false);
    const [currentLocalization, setCurrentLocalization] = useState(localization);

    useEffect(() => {
        if (currentLocalization.value !== localization.value || checkRemoved(localization)) {
            setDraft(localization.value);
        }
        setCurrentLocalization(localization);
    }, [localization]);

    const [{isOver, canDrop, monitorId}, drop] = useDropTarget({columnIndex, changeColumnOrder, logColumnReorder});

    const onDraftInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        setDraft(shouldClear ? value.slice(draft.length) : value);
        setShouldClear(false);
    };

    const resetDraft = () => {
        setDraft(localization.value);
    };

    const next = fieldUpdateHandler(onChange, localization)('value');
    const isEditorCell = editorCell && editorCell.row === row && editorCell.col === col;
    const isSelected = relativeToSelectedColumn === 'center';
    const isModified = localization.value !== draft;

    const getInputClassname = () => {
        return clsx(
            !isModified && checkRemoved(localization) && styles['line-through'],
            styles['localization-cell__active'],
            isSelected && styles['localization-cell--highlight'],
            !isModified && styles['localization-cell--no-cursor']
        );
    };
    return (
        <div
            ref={drop}
            data-monitorid={monitorId}
            className={clsx(styles['localization-cell'], isSelected && styles['localization-cell--selected'])}
            onClick={() => setSelectedColumn(null)}
            onDoubleClick={() =>
                isDisabled
                    ? noop
                    : setEditorCell({
                          row,
                          col
                      })
            }
            style={{
                borderBottom:
                    relativeToSelectedColumn === 'center' && row === totalValues - 1 ? '1px solid #81B9FA' : undefined
            }}
        >
            {isOver && canDrop && <DragHeaderHintLine relativeToSelectedColumn={relativeToSelectedColumn} />}
            {isEditorCell ? (
                <input
                    key={`${row}_${col}`}
                    value={draft}
                    className={styles['localization-cell__editor']}
                    onBlur={() => {
                        next(draft);
                        setEditorCell(null);
                    }}
                    onFocus={(event) => {
                        setShouldClear(false);
                        event.currentTarget.selectionStart = draft.length;
                    }}
                    onChange={onDraftInputChange}
                    onKeyDown={(event) => {
                        event.stopPropagation();

                        switch (event.key) {
                            case 'Escape':
                                resetDraft();
                                setEditorCell(null).then(() =>
                                    navigateFromCell({
                                        row,
                                        col
                                    })
                                );
                                break;

                            case 'Enter': {
                                const focusedInput = navigateFromCell(
                                    {
                                        row,
                                        col
                                    },
                                    'ArrowDown'
                                );
                                setEditorCell(null).then(() => {
                                    if (!focusedInput) {
                                        navigateFromCell({
                                            row,
                                            col
                                        });
                                        next(draft);
                                    }
                                });
                                break;
                            }
                        }
                    }}
                    type="text"
                    autoFocus={true}
                />
            ) : (
                <div
                    style={{
                        position: 'relative'
                    }}
                >
                    <input
                        className={getInputClassname()}
                        key={`${row}_${col}_draft`}
                        value={draft}
                        ref={registerDraftInputRef(row, col)}
                        onBlur={() => next(draft)}
                        onKeyDown={(event) => {
                            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                                event.preventDefault();
                                navigateFromCell(
                                    {
                                        row,
                                        col
                                    },
                                    event.key
                                );
                            }

                            switch (event.key) {
                                case 'Escape':
                                    resetDraft();
                                    break;

                                case 'F2':
                                    setEditorCell({
                                        row,
                                        col
                                    });
                                    break;

                                case 'Delete':
                                    onDelete();
                                    break;

                                case 'Backspace':
                                    if (!isModified) {
                                        setDraft('');
                                    }

                                    break;

                                case 'Enter': {
                                    const focusedInput = navigateFromCell(
                                        {
                                            row,
                                            col
                                        },
                                        'ArrowDown'
                                    );
                                    !focusedInput && next(draft);
                                    break;
                                }
                            }
                        }}
                        onFocus={(event) => {
                            event.currentTarget.selectionStart = draft.length;
                            setShouldClear(true);
                        }}
                        onClick={(event) => {
                            event.currentTarget.selectionStart = draft.length;
                        }}
                        type="text"
                        disabled={isDisabled}
                        onChange={onDraftInputChange}
                    />
                    {!isModified && (
                        <div
                            className={clsx(
                                styles['localization-cell__ellipsis'],
                                isSelected && styles['localization-cell__ellipsis--selected']
                            )}
                        />
                    )}
                    {!isModified && <MarkLabel entity={localization} position="right" />}
                </div>
            )}
        </div>
    );
};

export default Cell;
