import i18n from 'ui-i18n';
export const DEFAULT_GENERATOR = {
    name: '',
    type: 'UUID'
};
export const GENERATOR_TYPES = {
    UUID: i18n.text('UUID'),
    SEQUENTIAL: i18n.text('Sequential')
};
export const ALL_GENERATORS_SEARCH_OPTION = i18n.text('All generators');
