import confirmMiddleware from '../middlewares/confirm-middleware/confirmMiddleware';
import reducer from '../reducers';
import {applyMiddleware, compose, createStore} from 'redux';
import {commandMiddleware} from '../middlewares/command-middleware';
import {hashHistory} from 'react-router';
import {identity} from 'ramda';
import {routerMiddleware} from 'react-router-redux';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__: () => void;
    }
}

export default createStore(
    reducer,
    compose(
        applyMiddleware(confirmMiddleware, commandMiddleware, routerMiddleware(hashHistory)),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : identity
    )
);
