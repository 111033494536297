import * as R from 'ramda';
import Attribute from './Attribute';
import Button from '@mui/material/Button';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import DatePicker from '../DatePicker/DatePicker';
import Dialog from '../Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditableReferenceTagList from './EditableReferenceTagList';
import Icon from '@mui/material/Icon';
import TextField from '../TextField/TextField';
import Typography from '@mui/material/Typography';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {AUTO_GENERATED_CODE_PREFIX, ERRORS, REPLACEABLE_CODE_PREFIX} from '../../constants/common';
import {Component, Fragment} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DependencyItem, StoreDependencies, StoreDependency} from '../../rdm-sdk/app.types';
import {DiffInfo} from './diffHelpers';
import {LookupType} from '../../rdm-sdk/types.types';
import {LookupValue} from '../../rdm-sdk/lookups.types';
import {Maybe} from 'monet';
import {Observable, Subject} from 'rxjs';
import {StateEvent} from '../../rdm-sdk/state.types';
import {applyDCRsToDependenciesSaga} from '../../redux/actions/applyingDCR';
import {checkClientOnly, checkErroneous, getError, markEdited} from '../../core/marks';
import {checkCodeUniqueCommand, upsertValueEvent} from '../../redux/actions/values';
import {connect} from 'react-redux';
import {dateToUnix, getChecked, getValue, unixToDate} from '../../core/util';
import {fromNotEmpty, fromNull} from '../../core/maybe';
import {getDiff} from './diffHelpers';
import {getExtendedAttributes, markLookupValueRemoved} from '../../rdm-sdk/lookups';
import {getTypeByCode} from '../../rdm-sdk/types';
import {inArray, safeObjectLens, safeProp} from '../../core/lenses';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {orSome} from '../../core/monet';
import {pipe} from '../../core/func';
import {requestDependenciesSaga, saveDependencyEvent} from '../../redux/actions/dependencies';
import {validateDetails} from './validation';
const codeL = safeProp('code');
const startDateL = safeProp('startDate');
const endDateL = safeProp('endDate');
const enabledL = safeProp('enabled');
const attributesL = safeProp('attributes');
const canonicalL = safeProp('canonical');

type StateProps = {
    configuration: Configuration;
    currentType?: string | null;
    lookupValues: LookupValue[];
    tenant: string;
    storeDeps?: StoreDependencies | null;
};

type DispatchProps = {
    dispatch: (e: StateEvent) => Promise<any>;
};

type Props = StateProps &
    DispatchProps & {
        parents: StoreDependencies[];
        reference: LookupValue;
        onClose: () => void;
        open: boolean;
        onDone: (ref: LookupValue) => void;
        updateLookupValue: (code: string, lookupValue: LookupValue) => void;
        checkCodeUnique: (code: string) => Promise<boolean>;
        isEditMode: boolean;
        dependenciesReadOnly: boolean;
    };
type State = {
    parents: DependencyItem[];
    dependents: DependencyItem[];
    isCodeExists: boolean;
    isParentsDirty: boolean;
    isDependentsDirty: boolean;
    isEdited: boolean;
    reference: LookupValue | null;
    propsToTrack: {
        reference: LookupValue | null;
        storeDeps: StoreDependencies | null;
    };
};
const DEFAULT_STATE = {
    parents: [],
    dependents: [],
    reference: null,
    isCodeExists: false,
    isParentsDirty: false,
    isDependentsDirty: false,
    isEdited: false,
    propsToTrack: {
        reference: null,
        storeDeps: null
    }
};

const initState = (props) =>
    fromNull(props.reference)
        .map((reference) => ({
            reference: {
                ...reference,
                startDate: unixToDate(reference.startDate),
                endDate: unixToDate(reference.endDate),
                attributes: getExtendedAttributes(reference, getTypeByCode(props.configuration, props.currentType))
            },
            isEdited: false,
            isParentsDirty: false,
            isDependentsDirty: false,
            isCodeExists: getError(reference) === ERRORS.CODE_EXISTS,
            parents: [],
            dependents: [],
            propsToTrack: {
                reference,
                storeDeps: props.storeDeps
            }
        }))
        .orSome(DEFAULT_STATE);

const toUri = ({type, code}: DependencyItem) => `${type}/${code}`;

const getDepsData = ({type, code, canonical}) => ({
    canonical,
    uri: toUri({
        type,
        code
    } as DependencyItem)
});

const formatAttributes = R.pipe(
    R.map(R.pick(['name', 'value'])),
    R.filter(R.prop('value')),
    fromNotEmpty,
    orSome(undefined)
);

const formatLookupValue = (reference: LookupValue, parents?: string[]): LookupValue =>
    Object.assign({}, reference, {
        startDate: dateToUnix(reference.startDate),
        endDate: dateToUnix(reference.endDate),
        // @ts-ignore
        localizations: Maybe.fromNull(reference.localizations).orSome(undefined),
        parents,
        attributes: formatAttributes(reference.attributes)
    });

const formatCodes = (types) => (codes) =>
    codes.map(({uri, canonical}) => {
        const uriTokens = uri.split('/');
        const code = uriTokens.pop();
        const type = uriTokens.join('/');
        const labelL = inArray('uri', type).compose(safeObjectLens('label'));
        const typeLabel = labelL.get(types).orSome('');
        return {
            canonical,
            code,
            type,
            typeLabel,
            checked: true
        };
    });

const parseDependency = (dependency: StoreDependency[], lookupTypes: LookupType[]): DependencyItem[] =>
    fromNotEmpty(dependency).map(formatCodes(lookupTypes)).orSome([]);

const getDepsFromStore = (
    type: string | null | undefined,
    code: string | null | undefined,
    dependencies: StoreDependencies[]
) =>
    type && code
        ? R.find(
              R.whereEq({
                  type,
                  code
              }),
              dependencies
          )
        : null;

const checkCodeIsImmutable = R.complement(checkClientOnly);
const logger = logActivityCommand('reference-modal');
const saveLogger = logger('save-click');
const deleteLogger = logger('delete-click');
const cancelLogger = logger('cancel-click');
export class ReferenceDialog extends Component<Props, State> {
    code$: Subject<any>;
    dependentDiff?: DiffInfo;
    parentsDiff?: DiffInfo;

    constructor(props: Props) {
        super(props);
        this.state = initState(props);
        this.code$ = new Subject();
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const {reference, configuration, storeDeps} = nextProps;
        const {
            propsToTrack: {reference: prevReference, storeDeps: prevStoreDeps}
        } = prevState;
        const stateChanges = {};

        if (reference !== prevReference) {
            Object.assign(stateChanges, initState(nextProps));
        }

        if (storeDeps && storeDeps !== prevStoreDeps) {
            Object.assign(stateChanges, {
                propsToTrack: {
                    reference,
                    storeDeps
                },
                parents: parseDependency(storeDeps.parents.data, configuration.lookupTypes),
                dependents: parseDependency(storeDeps.dependent.data, configuration.lookupTypes)
            });
        }

        return stateChanges;
    }

    componentDidUpdate() {
        this.dependentDiff = {
            added: [],
            removed: []
        };
        this.parentsDiff = {
            added: [],
            removed: []
        };
        const {reference, storeDeps, dispatch} = this.props;

        if (reference && reference.code && !checkClientOnly(reference) && !storeDeps) {
            requestDependenciesSaga(dispatch)(reference.code).then(({value: dependencies}) => {
                applyDCRsToDependenciesSaga(dispatch)(dependencies);
            });
        }
    }

    componentDidMount() {
        const {checkCodeUnique} = this.props;
        this.code$
            .debounceTime(300)
            .filter((code) => code !== '')
            .switchMap(R.compose(Observable.fromPromise, checkCodeUnique))
            .subscribe(
                (isCodeUnique) =>
                    this.setState({
                        isCodeExists: !isCodeUnique
                    }),
                (error) => console.error('Check for uniqueness of the code is failed', error)
            );
    }

    componentWillUnmount() {
        this.code$.unsubscribe();
    }

    next = (field: string) => (data: any) => {
        if (field === 'code') {
            this.code$.next(data);
        }

        this.setState(({reference}) => ({
            reference: Object.assign({}, reference, {
                [field]: data
            }),
            isEdited: true
        }));
    };
    onDoneClick = () => {
        const {currentType, onDone, dispatch, updateLookupValue, reference: originalReference, onClose} = this.props;
        const {reference, parents, dependents, isParentsDirty, isDependentsDirty} = this.state;

        if (reference) {
            const lookupValue = formatLookupValue(reference, parents.map(toUri));
            onDone(lookupValue);

            if (isParentsDirty || isDependentsDirty) {
                const depsParents = {
                    data: parents.map(getDepsData)
                };
                const depsDependents = {
                    data: dependents.map(getDepsData)
                };
                const deps = markEdited({
                    type: currentType,
                    code: R.prop('code', reference),
                    parents: isParentsDirty ? markEdited(depsParents) : depsParents,
                    dependent: isDependentsDirty ? markEdited(depsDependents) : depsDependents
                });
                dispatch(saveDependencyEvent(deps));
            }

            if (getError(originalReference) === ERRORS.CODE_EXISTS) {
                updateLookupValue(lookupValue.code, lookupValue);
            } else {
                updateLookupValue(originalReference.code, lookupValue);
            }
        }

        onClose();
    };
    onDeleteClick = () => {
        const {reference} = this.state;
        const {dispatch, currentType, reference: originalReference, onClose} = this.props;

        if (reference) {
            const lookupValue = formatLookupValue(reference);
            dispatch(
                upsertValueEvent(currentType, R.prop('code', originalReference), markLookupValueRemoved(lookupValue))
            );
        }

        onClose();
    };
    getErrorMessage = () => {
        const {reference, isCodeExists} = this.state;
        if (isCodeExists) return i18n.text('Code already exists');
        const safeReference = Maybe.fromNull(reference);
        const safeCode = codeL.get(safeReference);
        const isCodeEmpty = safeCode.isNone();
        const isCodeReplaceable = safeCode.map(R.startsWith(REPLACEABLE_CODE_PREFIX)).orSome(false);
        const isErroneous = safeReference.map(checkErroneous).orSome(false);
        if (isCodeEmpty || (isCodeReplaceable && isErroneous)) return i18n.text('Code is required');
        return '';
    };
    onDependentChanged = (dependents: DependencyItem[]) => {
        this.dependentDiff = getDiff(this.state.dependents.map(toUri), dependents.map(toUri), this.dependentDiff);
        this.setState({
            dependents,
            isDependentsDirty: true,
            isEdited: true
        });
    };
    onParentsChanged = (parents: DependencyItem[]) => {
        this.parentsDiff = getDiff(this.state.parents.map(toUri), parents.map(toUri), this.parentsDiff);
        this.setState({
            parents,
            isParentsDirty: true,
            isEdited: true
        });
    };

    render() {
        const {dispatch, open, onClose, isEditMode, dependenciesReadOnly} = this.props;
        const {reference, isCodeExists, parents, dependents, isEdited} = this.state;
        const safeReference = Maybe.fromNull(reference);
        const code = codeL.get(safeReference).orSome('');
        const startDate = startDateL.get(safeReference).orSome(null);
        const endDate = endDateL.get(safeReference).orSome(null);
        const enabled = enabledL.get(safeReference).orSome(true);
        const attributes = attributesL.get(safeReference).orSome([]);

        const onAttrChange = (name) => (attribute) => inArray('name', name).set(attributes, attribute);

        const errorMessage = this.getErrorMessage();
        const isValid = !isCodeExists && reference && validateDetails(reference).isSuccess();
        const showDependencies = Boolean(safeReference.map(checkCodeIsImmutable).orSome(false));
        const attributeItems = attributes
            .map(
                (attr, i) =>
                    (isEditMode || attr.value) && (
                        <div key={i} className={styles['reference-dialog__attribute']}>
                            <Attribute
                                attribute={attr}
                                isEditMode={isEditMode}
                                onChange={R.pipe(onAttrChange(attr.name), this.next('attributes'))}
                            />
                        </div>
                    )
            )
            .filter(R.identity);
        const loggableInfo = {
            code,
            startDate,
            endDate,
            enabled,
            attributes,
            parentsDiff: this.parentsDiff,
            dependentDiff: this.dependentDiff
        };
        return (
            <Dialog open={open} onCancel={onClose} className={styles['reference-dialog']}>
                <DialogTitle>
                    {isEditMode ? i18n.text('Edit canonical value') : canonicalL.get(safeReference).orSome('')}
                </DialogTitle>
                <DialogContent className={styles['reference-dialog__content']}>
                    <div className={styles['reference-dialog__section']}>
                        <TextField
                            readOnly={
                                !isEditMode ||
                                !checkClientOnly(reference) ||
                                code.startsWith(AUTO_GENERATED_CODE_PREFIX)
                            }
                            error={!!errorMessage}
                            helperText={errorMessage}
                            onChange={pipe(getValue, this.next('code'))}
                            value={code.startsWith(REPLACEABLE_CODE_PREFIX) ? '' : code}
                            label={i18n.text('Code')}
                            required={isEditMode}
                        />

                        <div className={styles['reference-dialog__row']}>
                            <DatePicker
                                readOnly={!isEditMode}
                                label={i18n.text('Start date')}
                                id="startDatePicker"
                                value={startDate}
                                onSelect={this.next('startDate')}
                            />

                            <DatePicker
                                readOnly={!isEditMode}
                                label={i18n.text('End date')}
                                id="endDatePicker"
                                value={endDate}
                                onSelect={this.next('endDate')}
                            />
                        </div>

                        {isEditMode ? (
                            <CustomSwitch
                                className={styles['reference-dialog__switch']}
                                label={i18n.text('Status')}
                                hintForOnState={i18n.text('Turn off to disable this canonical value from your tenant')}
                                checked={enabled}
                                onChange={pipe(getChecked, this.next('enabled'))}
                            />
                        ) : (
                            <p className={styles['reference-dialog__status']}>
                                <Icon>{enabled ? 'checked' : 'do_not_disturb'}</Icon>
                                {enabled ? i18n.text('Status enabled') : i18n.text('Status disabled')}
                            </p>
                        )}
                    </div>

                    {attributeItems.length > 0 && (
                        <div className={styles['reference-dialog__section']}>
                            <Typography variant="subtitle1" className={styles['reference-dialog__attributes-title']}>
                                {i18n.text('Custom attributes')}
                            </Typography>
                            {attributeItems}
                        </div>
                    )}

                    {showDependencies && (
                        <Fragment>
                            <div className={styles['reference-dialog__section']}>
                                <EditableReferenceTagList
                                    currentCode={code}
                                    title={i18n.text('Parents')}
                                    elementName={i18n.text('Parent')}
                                    pickerTitle={i18n.text('Add parents canonical value')}
                                    onTagsUpdated={this.onParentsChanged}
                                    tags={parents}
                                    exceptions={dependents}
                                    readOnly={dependenciesReadOnly}
                                />
                            </div>

                            <div className={styles['reference-dialog__section']}>
                                <EditableReferenceTagList
                                    currentCode={code}
                                    title={i18n.text('Children')}
                                    pickerTitle={i18n.text('Add children canonical value')}
                                    elementName={i18n.text('Children')}
                                    onTagsUpdated={this.onDependentChanged}
                                    tags={dependents}
                                    exceptions={parents}
                                    readOnly={dependenciesReadOnly}
                                />
                            </div>
                        </Fragment>
                    )}
                </DialogContent>

                {isEditMode && (
                    <DialogActions>
                        <Button
                            variant="grey"
                            onClick={R.pipe(this.onDeleteClick, R.always(loggableInfo), deleteLogger, dispatch)}
                        >
                            {i18n.text('Delete')}
                        </Button>
                        <div className="spacer" />
                        <Button
                            variant="grey"
                            onClick={R.pipe(onClose, R.always(loggableInfo), cancelLogger, dispatch)}
                        >
                            {i18n.text('Cancel')}
                        </Button>
                        <Button
                            disabled={!isValid || !isEdited}
                            color="primary"
                            onClick={R.pipe(this.onDoneClick, R.always(loggableInfo), saveLogger, dispatch)}
                        >
                            {i18n.text('Done')}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}

const mapStateToProps: (state, props) => StateProps = (state, {reference}) => {
    const {currentType, dependencies} = state;
    const storeDeps = getDepsFromStore(currentType, R.prop('code', reference), dependencies);
    return {
        storeDeps,
        ...R.pick(['configuration', 'currentType', 'tenant', 'lookupValues'], state)
    };
};

const mapDispatchToProps = (dispatch) =>
    ({
        dispatch,
        checkCodeUnique: R.compose(dispatch, checkCodeUniqueCommand)
    }) as DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceDialog);
