/**
 * Created by ndyumin on 27.04.2017.
 */
export const toggleSortingEvent = () => ({
    type: 'toggleSorting'
});
export function setSortingEvent(sorting) {
    return {
        type: 'setSorting',
        sorting
    };
}
