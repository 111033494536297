import * as R from 'ramda';
import SearchBar from '../SearchBar';
import ValuesSearchDetails from './ValuesSearchDetails';
import {Component} from 'react';
import {DraftMode} from '../../../rdm-sdk/app.types';
import {SEARCH_KEY_WORDS} from '../../../constants/common';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {ValuesSearchQuery} from '../../../rdm-sdk/filters.types';
import {clearSearchQueryEvent, searchQueryEvent} from '../../../redux/actions/search';
import {connect} from 'react-redux';
import {getDispatchOnResolve} from '../../../redux/middlewares/confirm-middleware/confirmMiddleware';
import {getIsSearchBarVisible} from '../../../redux/reducers/uiReducer';
import {initialQuery} from '../initialSearchQuery';
import {logActivityCommand} from '../../../redux/actions/activityLogging';
import {requestValuesSaga} from '../../../redux/actions/values';
import {showConfirmationDialogEvent} from '../../../redux/actions/app';

type StateProps = {
    searchQuery: ValuesSearchQuery;
    draftMode: DraftMode;
    isSearchBarVisible: boolean;
};

type DispatchProps = {
    dispatch: (e: StateEvent) => Promise<any>;
    dispatchOnResolve: (e: StateEvent) => Promise<any>;
};

type Props = StateProps & DispatchProps;

const KEY_WORDS = [
    SEARCH_KEY_WORDS.CODE,
    SEARCH_KEY_WORDS.VALUE,
    SEARCH_KEY_WORDS.SOURCE_SYSTEM,
    SEARCH_KEY_WORDS.START_DATE,
    SEARCH_KEY_WORDS.END_DATE,
    SEARCH_KEY_WORDS.CANONICAL_CODE,
    SEARCH_KEY_WORDS.CANONICAL_VALUE
];
export class ValuesSearchBar extends Component<Props> {
    isLeaveUnsavedChanges = (onLeave: () => void) => {
        const {draftMode, dispatch} = this.props;

        if (draftMode.dirtyValues) {
            dispatch(showConfirmationDialogEvent());
        }

        onLeave();
    };
    onSearch = (query: ValuesSearchQuery) => {
        const {dispatchOnResolve} = this.props;
        this.isLeaveUnsavedChanges(() =>
            R.pipe(searchQueryEvent, dispatchOnResolve, requestValuesSaga(dispatchOnResolve))(query)
        );
    };
    onReset = () => {
        const {dispatchOnResolve} = this.props;
        this.isLeaveUnsavedChanges(
            R.pipe(clearSearchQueryEvent, dispatchOnResolve, requestValuesSaga(dispatchOnResolve))
        );
    };

    render() {
        const {dispatch, searchQuery, isSearchBarVisible} = this.props;
        const logger = logActivityCommand('search-values-bar');
        return (
            <SearchBar
                dispatch={dispatch}
                searchQuery={searchQuery}
                initialQuery={initialQuery}
                detailsComponent={ValuesSearchDetails}
                onSearch={this.onSearch}
                onReset={this.onReset}
                logger={logger}
                keywords={KEY_WORDS}
                isVisible={isSearchBarVisible}
            />
        );
    }
}

const mapStateToProps: (state) => StateProps = (state) => ({
    isSearchBarVisible: getIsSearchBarVisible(state),
    ...R.pick(['searchQuery', 'draftMode'], state)
});

const mapDispatchToProps = (dispatch) =>
    ({
        dispatch,
        dispatchOnResolve: getDispatchOnResolve(dispatch)
    }) as DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ValuesSearchBar);
