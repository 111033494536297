import * as R from 'ramda';
import {RelationsData, RelationsNode, TreeData, TreeNode, TreePath} from './hierarchy.types';
export const getChildrenData = R.pipe(
    R.toPairs,
    R.map(([child, parents]) => R.xprod([child], parents)),
    R.unnest,
    R.reduceBy((acc, [child]) => acc.concat(child), [], R.last)
);
export const buildTree = (root: RelationsNode, childrenData: RelationsData): TreeData => {
    const nodes: RelationsNode[] = [];

    const addChildren = (value: RelationsNode, lowerSiblingsCount: number[]): TreeNode => {
        const isLink = nodes.includes(value);
        const node = {
            value,
            lowerSiblingsCount,
            children: [] as TreeNode[],
            isLink
        };

        if (!isLink) {
            nodes.push(value);
        }

        const nodeChildrenValues = childrenData[value];

        if (nodeChildrenValues && !isLink) {
            node.children = nodeChildrenValues.map((child, index) =>
                addChildren(child, lowerSiblingsCount.concat(nodeChildrenValues.length - index - 1))
            );
        }

        return node;
    };

    return {
        tree: addChildren(root, []),
        nodes
    };
};

const buildAndAccTree =
    (childrenData: RelationsData) =>
    ([treesAcc, nodesAcc], root) => {
        const {tree, nodes} = buildTree(root, childrenData);
        return [treesAcc.concat(tree), nodesAcc.concat(nodes)];
    };

export const buildHierarchyTrees = (parentsData: RelationsData, allNodes: string[]): TreeNode[] => {
    const childrenData: RelationsData = getChildrenData(parentsData);
    const parents = Object.keys(childrenData);
    const children = Object.keys(parentsData);
    let trees = [],
        coveredNodes = [];
    const getTree = buildAndAccTree(childrenData);
    const roots = R.difference(parents, children);
    [trees, coveredNodes] = roots.reduce(getTree, [trees, coveredNodes]);
    let loopedTreeRoot;

    // eslint-disable-next-line no-cond-assign
    while ((loopedTreeRoot = R.head(R.difference(parents, coveredNodes)))) {
        [trees, coveredNodes] = getTree([trees, coveredNodes], loopedTreeRoot);
    }

    const leftNodes = R.difference(allNodes, coveredNodes);
    [trees] = leftNodes.reduce(getTree, [trees, coveredNodes]);
    return trees;
};
const transformPath = R.intersperse('children');
export const getTreeNodeByPath = R.useWith(R.path, [transformPath]);
const updateNodeByPath = R.curry(
    (transform: (arg0: TreeNode) => TreeNode, path: TreePath, hierarchy: TreeNode[]): TreeNode[] => {
        const pathLens = R.lensPath(transformPath(path));
        const oldNode = R.view(pathLens, hierarchy);
        return oldNode ? R.set(pathLens, transform(oldNode), hierarchy) : hierarchy;
    }
);
export const toggleExpandedByPath = updateNodeByPath((node) => ({
    ...node,
    expanded: !node.expanded
}));
export const toggleShowParentsByPath = updateNodeByPath((node) => ({
    ...node,
    showParents: !node.showParents
}));
const updateAllNodesByPath = R.curry(
    (transform: (arg0: TreeNode) => TreeNode, path: TreePath, hierarchy: TreeNode[]): TreeNode[] =>
        path.reduce(
            (hierarchy, step, index) => updateNodeByPath(transform, path.slice(0, index + 1), hierarchy),
            hierarchy
        )
);
export const showPathWithChildren = updateAllNodesByPath((node) => ({
    ...node,
    expanded: true
}));
export const showPath = R.useWith(showPathWithChildren, [R.init]);

const reduceTree = (
    treeIndex: number,
    tree: TreeNode,
    filter: (arg0: TreeNode) => boolean,
    ignoreCollapsed = false
): TreePath[] => {
    const addPath = (path: TreePath, node: TreeNode) => {
        const list = filter(node) ? [path] : [];
        let children = [] as TreePath[][];

        if (node.children && (!ignoreCollapsed || node.expanded)) {
            children = node.children.map((child, index) => addPath([...path, index], child));
        }

        return list.concat(...children);
    };

    return addPath([treeIndex], tree);
};

export const findLinkOrigin = (path: TreePath, hierarchy: TreeNode[]): TreePath | null => {
    const linkNode = getTreeNodeByPath(path, hierarchy);

    if (!linkNode || !linkNode.isLink) {
        return null;
    } else {
        const searchFunction = (node) => !node.isLink && node.value === linkNode.value;

        const [treeIndex] = path;
        const [originNode] = reduceTree(treeIndex, hierarchy[treeIndex], searchFunction);
        return originNode;
    }
};
export const filterHierarchy = R.curry((filter: (arg0: TreeNode) => boolean, hierarchy: TreeNode[]): TreePath[] =>
    hierarchy.reduce((acc, tree, index) => acc.concat(reduceTree(index, tree, filter)), [] as TreePath[])
);
export const buildHierarchyList = (hierarchy: TreeNode[]): TreePath[] =>
    hierarchy.reduce((acc, tree, index) => acc.concat(reduceTree(index, tree, R.T, true)), [] as TreePath[]);
