import LookupTypeCard from '../LookupTypeCard/LookupTypeCard';
import NumberOfRecordsCard from '../NumberOfRecordsCard/NumberOfRecordsCard';
import UploadInfoCard from '../UploadInfoCard/UploadInfoCard';
import i18n from 'ui-i18n';
import styles from './styles.less';
type Props = {
    typeLabel: string;
    fileName: string;
    fileLinesCount?: number | null;
};

const FileDetails = ({typeLabel, fileName, fileLinesCount}: Props) => (
    <div className={styles['card-group']}>
        <h3 className={styles['title']}>{i18n.text('File Details')}</h3>
        <div className={styles['card-container']}>
            <LookupTypeCard label={typeLabel} />
            <NumberOfRecordsCard count={fileLinesCount} />
            <UploadInfoCard path={fileName} />
        </div>
    </div>
);

export default FileDetails;
