/**
 * Created by ndyumin on 11.08.2017.
 */
import * as R from 'ramda';
export const METADATA_KEY = '_metadata';

const getMarkL = (fieldName) => {
    const path = [METADATA_KEY, fieldName];
    return R.lens(R.pathOr(null, path), R.assocPath(path));
};

const statusMarkL = getMarkL('status');
const clientOnlyMarkL = getMarkL('clientOnly');
const viewOnlyMarkL = getMarkL('viewOnly');
const erroneousMarkL = getMarkL('error');
const unmappedCodeMarkL = getMarkL('unmappedCode');
const MARK = {
    NEW: 'new',
    REMOVED: 'removed',
    EDITED: 'edited',
    TEMP: 'temp'
};
const setStatusMark = R.set(statusMarkL);
const getStatusMark = R.view(statusMarkL);
export const markNew = setStatusMark(MARK.NEW);
export const markRemoved = setStatusMark(MARK.REMOVED);
export const markEdited = setStatusMark(MARK.EDITED);
export const markClientOnly = R.set(clientOnlyMarkL, true);
export const markErroneous = R.set(erroneousMarkL);
export const markWithUnmappedKey = R.set(unmappedCodeMarkL);
export const markViewOnly = R.set(viewOnlyMarkL, true);
export const getError = R.view(erroneousMarkL);
export const getUnmappedKey = R.view(unmappedCodeMarkL);
export const checkRemoved = R.pipe(getStatusMark, R.equals(MARK.REMOVED));
export const checkNew = R.pipe(getStatusMark, R.equals(MARK.NEW));
export const checkEdited = R.pipe(getStatusMark, R.equals(MARK.EDITED));
export const hasChanges = R.anyPass([checkRemoved, checkNew, checkEdited]);
export const checkClientOnly = R.pipe(R.view(clientOnlyMarkL), Boolean);
export const checkErroneous = R.pipe(getError, Boolean);
export const checkNewTruly = R.either(checkNew, R.both(checkClientOnly, checkEdited));
export const checkEmpty = R.both(checkClientOnly, R.complement(R.either(checkEdited, checkNew)));
export const checkViewOnly = R.pipe(R.view(viewOnlyMarkL), Boolean);
export const rejectRemoved = R.reject(checkRemoved);
export const rejectEmpty = R.reject(checkEmpty);
export const metadataReplacer = (key, value) => (key === METADATA_KEY ? undefined : value);
