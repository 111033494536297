import * as R from 'ramda';
import store from '../redux/store';
import {push} from 'react-router-redux';
import {toObservable} from '../core/util';
export function checkPolicies(requiredPolicyNames) {
    return function (router, replace, next) {
        toObservable(store)
            .first(({tenant, policies}) => !R.isNil(tenant) && !R.isEmpty(policies))
            .subscribe(({policies, tenant}) => {
                const hasAccess = requiredPolicyNames.every((name) => policies[name]);

                if (!hasAccess) {
                    store.dispatch(push(`/${tenant}/types`));
                } else {
                    next();
                }
            });
    };
}
