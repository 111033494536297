import MultiSelect from '../../../ReactSelect/MultiSelect';
import i18n from 'ui-i18n';
import {ACTIVITY_TYPE} from '../../constants';
import {SelectOption} from '../../activityLog.types';
import {connect} from 'react-redux';
import {getActivityLogFilterActivityTypes} from '../../../../redux/reducers/activityLogReducer';
import {setActivityLogFilterActivityTypesEvent} from '../../../../redux/actions/activityLog';
const options = [
    {
        value: ACTIVITY_TYPE.ADD,
        label: i18n.text('Added')
    },
    {
        value: ACTIVITY_TYPE.CHANGE,
        label: i18n.text('Edited')
    },
    {
        value: ACTIVITY_TYPE.DELETE,
        label: i18n.text('Deleted')
    }
];
type Props = {
    values: SelectOption[];
    onChange: (arg0: SelectOption[]) => void;
};

function ActivityTypeSelect(props: Props) {
    const {values, onChange} = props;
    return <MultiSelect label={i18n.text('Activity')} options={options} value={values} onChange={onChange} />;
}

const mapStateToProps = (state) => ({
    values: getActivityLogFilterActivityTypes(state)
});

const mapDispatchToProps = {
    onChange: setActivityLogFilterActivityTypesEvent
};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityTypeSelect);
