import i18n from 'ui-i18n';
export const TASK_PRIORITIES = [
    {
        label: i18n.text('Low'),
        priority: 1,
        color: '#F7F7F7'
    },
    {
        label: i18n.text('Medium'),
        priority: 50,
        color: '#F7DE03'
    },
    {
        label: i18n.text('High'),
        priority: 100,
        color: '#FF9800'
    },
    {
        label: i18n.text('Urgent'),
        priority: 1000,
        color: '#F44336'
    }
];
export const MAX_VISIBLE_CHANGES_IN_DCR = 3;
export const MAX_VISIBLE_COMMENTS_IN_DCR = 3;
export const WorkflowChangeTypes = {
    ADDED_LOOKUP_TYPE: 'ADDED_LOOKUP',
    DELETED_LOOKUP_TYPE: 'DELETED_LOOKUP',
    DELETED_UNMAPPED_VALUE: 'DELETED_UNMAPPED_VALUE'
};
