import * as R from 'ramda';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
type Props = {
    title: string;
    actionName: string;
    steps: string[];
    onClose: () => void;
    canContinue: (activeStep: number) => boolean;
    onFinish: () => void;
    stepContentRenderer: (activeStep: number) => ReactNode;
    logCloseBtnClick: () => void;
    logContinueBtnClick: () => void;
    logStepBtnClick: (stepIndex: number) => void;
};
type State = {
    activeStep: number;
};
export class CustomWizard extends Component<Props, State> {
    state = {
        activeStep: 0
    };
    setActiveStep = (activeStep: number) =>
        this.setState({
            activeStep
        });
    goToNextStep = () =>
        this.setState(
            R.evolve({
                activeStep: R.add(1)
            })
        );

    render() {
        const {
            title,
            actionName,
            steps,
            onClose,
            canContinue,
            onFinish,
            stepContentRenderer,
            logCloseBtnClick,
            logContinueBtnClick,
            logStepBtnClick
        } = this.props;
        const {activeStep} = this.state;
        const stepConnector = (
            <StepConnector
                classes={{
                    line: styles['step-connector__line'],
                    root: styles['step-connector']
                }}
            />
        );
        const isLastStep = activeStep === steps.length - 1;
        const nextStep = activeStep + 1;
        const isNextStepDisabled = !canContinue(activeStep);
        return (
            <div className={styles.wizard}>
                <Toolbar className={styles.toolbar}>
                    <IconButton color="inherit" onClick={R.pipe(onClose, logCloseBtnClick)} size="large">
                        <Icon>close</Icon>
                    </IconButton>
                    <Typography className={styles.title} variant={'h6'}>
                        {title}
                    </Typography>
                </Toolbar>
                <div className={styles['stepper-container']}>
                    <Stepper activeStep={activeStep} className={styles.stepper} connector={stepConnector}>
                        {steps.map((label, index) => (
                            <Step key={label} disabled={(index === nextStep && isNextStepDisabled) || index > nextStep}>
                                <StepButton
                                    classes={{
                                        touchRipple: styles['step-button__ripple']
                                    }}
                                    onClick={R.pipe(R.always(index), R.tap(logStepBtnClick), this.setActiveStep)}
                                >
                                    <StepLabel
                                        classes={{
                                            label: styles['step-button__label']
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div className={styles['controls-container']}>
                        <Button
                            className={styles['action-button']}
                            variant={'contained'}
                            color={'primary'}
                            disabled={isNextStepDisabled}
                            onClick={isLastStep ? onFinish : R.pipe(this.goToNextStep, logContinueBtnClick)}
                        >
                            {isLastStep ? actionName : i18n.text('Continue')}
                        </Button>
                    </div>
                </div>
                <div className={styles['content-container']}>{stepContentRenderer(activeStep)}</div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, {logger}) => ({
    logCloseBtnClick: R.compose(dispatch, logger('close-button-click')),
    logContinueBtnClick: R.compose(dispatch, logger('continue-button-click')),
    logStepBtnClick: R.compose(dispatch, logger('step-button-click'), R.objOf('stepIndex'))
});

export default connect(null, mapDispatchToProps)(CustomWizard);
