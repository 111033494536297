import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Option from 'react-components/dist/Select/Option';
import Select from 'react-components/dist/Select/Select';
import i18n from 'ui-i18n';
import useStyles from './styles';
import {connect} from 'react-redux';
import {getTaskAssignees} from '../../../../redux/selectors/workflowSelectors';
import {requestTaskAssigneesSaga} from '../../../../redux/actions/workflow';
export type AssigneeSelectorProps = {
    assignee: string;
    taskAssignees: string[] | null;
    onChange: (arg0: string) => void;
    requestTaskAssignees: (arg0: string) => null;
    taskID: string;
    readOnly: boolean;
};
export function AssigneeSelector(props: AssigneeSelectorProps) {
    const {assignee, taskAssignees, onChange, requestTaskAssignees, taskID, readOnly} = props;
    const classes = useStyles();

    const requestAssignees = () => taskAssignees || requestTaskAssignees(taskID);

    return (
        <Select
            label={i18n.text('Assigned to')}
            searchable
            fullWidth
            loading={!taskAssignees}
            value={assignee}
            onOpen={requestAssignees}
            classes={classes}
            onChange={onChange}
            disabled={readOnly}
        >
            {(taskAssignees || []).map((assignee) => (
                <Option key={assignee} value={assignee} searchToken={assignee}>
                    <ListItem dense>
                        <ListItemText primary={assignee} />
                    </ListItem>
                </Option>
            ))}
        </Select>
    );
}

const mapStateToProps = (state, {taskID}) => ({
    taskAssignees: getTaskAssignees(state, taskID)
});

const mapDispatchToProps = (dispatch) => ({
    requestTaskAssignees: requestTaskAssigneesSaga(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssigneeSelector);
