import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import {useState} from 'react';
export type ActionsMenuProps = {
    actions: Array<Record<string, any>>;
    onChooseAction: (arg0: string) => void;
    className?: string;
};

function ActionsMenu({actions, onChooseAction, className}: ActionsMenuProps) {
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (action) => () => {
        onChooseAction(action);
        onClose();
    };

    return (
        <div className={className}>
            <Tooltip title={i18n.text('Actions')}>
                <IconButton onClick={openMenu} size="large">
                    <Icon>more_vert</Icon>
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
                {actions.map((action) => (
                    <MenuItem key={action.action} onClick={onItemClick(action.action)}>
                        {action.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default ActionsMenu;
