import * as R from 'ramda';
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import SortedColumnTitle from '../SortedColumnTitle/SortedColumnTitle';
import TableRow from './CustomTableRow/CustomTableRow';
import clsx from 'clsx';
import styles from './styles.less';
import withFeatures from '../Feature/withFeatures';
import {AutoSizer, Column, Grid, Table, WindowScroller} from 'react-virtualized';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {CollaborationOptions, GeneralCollaboration} from '../../rdm-sdk/collaboration.types';
import {Component} from 'react';
import {DraftMode, ScrollData, Sorting, TableValue} from '../../rdm-sdk/app.types';
import {ElementRef} from 'react';
import {FEATURE} from '../../rdm-sdk/features';
import {Features} from '../../rdm-sdk/features.types';
import {OpenedComment} from '../../rdm-sdk/ui.types';
import {SCROLL_OFFSET} from '../../constants/common';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {fromNull} from '../../core/maybe';
import {getIsDirty} from '../../redux/reducers/draftModeReducer';
import {getOpenedComment} from '../../redux/reducers/uiReducer';
import {noop} from '../../core/util';
import {pipe} from '../../core/func';
import {rejectRemoved} from '../../core/marks';
type CheckedEvent = {
    value: TableValue;
    checked: boolean;
    shiftKeyPressed: boolean;
};

const getChecked = (e) => ({
    checked: e.target.checked,
    shiftKeyPressed: e.nativeEvent.shiftKey
});

const nextChecked =
    (value: TableValue) =>
    ({checked, shiftKeyPressed}): CheckedEvent => ({
        value,
        checked,
        shiftKeyPressed
    });

export type CustomTableHeaderProps = {
    selected: TableValue[];
    unselectClb: () => void;
    removeClb: () => void;
};
type Props = {
    dispatch: (event: StateEvent) => Promise<any>;
    currentType: string;
    data: TableValue[];
    selectKey: string;
    onDelete: (selected: TableValue[]) => void;
    draftMode: DraftMode;
    sorting: {
        sort: Sorting;
        onChange: (sorting: Sorting) => void;
    };
    isEditMode: boolean;
    columns: any[];
    columnsData: Record<string, any>;
    primaryHeaderRenderer: (arg0: CustomTableHeaderProps) => React.ReactNode;
    onRowClick?: (e: Record<string, any>) => void;
    loadMore: (callback?: (...args: any[]) => any) => void;
    collaborationOptions: CollaborationOptions;
    features: Features;
    logger: (action: string) => (payload: any) => StateEvent;
    collaboration?: GeneralCollaboration | null;
    openedComment?: OpenedComment | null;
    onScroll?: (arg0: ScrollData) => void;
    gridRef?: (arg0: ElementRef<typeof Grid>) => void;
    scrollToIndex?: number;
    policies: CheckedPolicies;
};
type State = {
    selected: TableValue[];
    lastClickedItemIndex: number;
    propsToTrack: {
        data: TableValue[];
        draftMode: DraftMode;
    };
};
export class CustomTable extends Component<Props, State> {
    static defaultProps = {
        columnsData: {},
        summaryWarningTooltip: ''
    };
    _table?: ElementRef<typeof Table> | null;
    _scrollElement?: Element | ParentNode | null;
    state = {
        selected: [],
        lastClickedItemIndex: -1,
        propsToTrack: {
            data: this.props.data,
            draftMode: this.props.draftMode
        }
    };
    _getDataList = () => rejectRemoved(this.props.data);
    _toggleAllSelected = () => {
        const {logger, dispatch} = this.props;
        const selected = this._allSelected() ? [] : this._getDataList();
        this.setState({
            selected
        });
        dispatch(
            logger('select-all-click')({
                selectedCount: selected.length
            })
        );
    };
    _allSelected = (selectedLength: number = this.state.selected.length) => {
        return selectedLength > 0 && selectedLength === this._getDataList().length;
    };
    _onItemSelected = ({checked, value, shiftKeyPressed}: CheckedEvent) => {
        const {lastClickedItemIndex} = this.state;
        const {logger, dispatch, data} = this.props;
        const clickedIndex = data.findIndex((s) => this.equalItems(s, value));
        const clickedItem: TableValue | null = clickedIndex > -1 ? data[clickedIndex] : null;

        if (clickedItem) {
            const startClickIndex = shiftKeyPressed && lastClickedItemIndex > -1 ? lastClickedItemIndex : clickedIndex;
            const endClickIndex = clickedIndex;
            const minIndex = Math.min(startClickIndex, endClickIndex);
            const maxIndex = Math.max(startClickIndex, endClickIndex);
            const selected = checked
                ? this.addSelectedItemsInRange(minIndex, maxIndex)
                : this.removeSelectedItemsInRange(minIndex, maxIndex);
            this.setState({
                selected,
                lastClickedItemIndex: endClickIndex
            });
            dispatch(logger('select-item-click')(clickedItem));
        }
    };
    _onRemoveClick = () => {
        const {onDelete, dispatch, logger} = this.props;
        const {selected} = this.state;
        onDelete(selected);
        this.setState({
            selected: []
        });
        dispatch(
            logger('delete-selected-click')({
                selectedCount: selected.length
            })
        );
    };
    _unselectAll = () => {
        const {dispatch, logger} = this.props;
        this.setState({
            selected: []
        });
        dispatch(logger('unselect-all-click')(null));
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const {data: nextData, draftMode: nextDraftMode, selectKey} = nextProps;
        const {
            propsToTrack: {data: prevData, draftMode: prevDraftMode},
            selected
        } = prevState;
        const propsToTrack = {
            data: nextData,
            draftMode: nextDraftMode
        };

        if (!R.eqBy(getIsDirty, nextDraftMode, prevDraftMode)) {
            return {
                selected: [],
                propsToTrack
            };
        } else if (selected.length > 0 && nextData !== prevData) {
            return {
                selected: R.innerJoin(R.eqProps(selectKey), selected, nextData),
                propsToTrack
            };
        } else {
            return {
                propsToTrack
            };
        }
    }

    _getCheckboxClass = () =>
        styles[this.state.selected.length ? 'custom-table__checkbox-multiselect' : 'custom-table__checkbox-hidden'];
    getItemsInRange = (startIndex, endIndex) => {
        const {data} = this.props;
        return data.slice(startIndex, endIndex + 1);
    };
    equalItems = (a, b) => R.eqProps(this.props.selectKey, a, b);
    addSelectedItemsInRange = (startIndex, endIndex) => {
        const itemsToSelect = this.getItemsInRange(startIndex, endIndex);
        return itemsToSelect.reduce((selected: TableValue[], item) => {
            const alreadySelected = !!selected.find((selectedItem) => this.equalItems(selectedItem, item));
            return !alreadySelected ? selected.concat(item) : selected;
        }, this.state.selected);
    };
    removeSelectedItemsInRange = (startIndex, endIndex) => {
        const itemsToDeselect = this.getItemsInRange(startIndex, endIndex);
        return R.differenceWith(this.equalItems, this.state.selected, itemsToDeselect);
    };
    checkboxHeaderRenderer = () => (
        <Checkbox
            color="primary"
            onChange={this._toggleAllSelected}
            checked={this._allSelected()}
            className={clsx(styles['custom-table__checkbox'], this._getCheckboxClass())}
        />
    );
    checkboxCellRenderer = ({rowData}: {rowData: TableValue}) => (
        <Checkbox
            color="primary"
            onChange={pipe(getChecked, nextChecked(rowData), this._onItemSelected)}
            checked={!!R.find(R.propEq(this.props.selectKey, rowData[this.props.selectKey]), this.state.selected)}
            className={clsx(styles['custom-table__checkbox'], this._getCheckboxClass())}
        />
    );
    headerRowRenderer = ({className, columns}: Record<string, any>) => {
        const {primaryHeaderRenderer} = this.props;
        const {selected} = this.state;
        return (
            <div className={className}>
                {primaryHeaderRenderer({
                    selected,
                    unselectClb: this._unselectAll,
                    removeClb: this._onRemoveClick
                })}
                <div className={styles['header-columns']} role="row">
                    {columns}
                </div>
            </div>
        );
    };
    headerRenderer = ({label, dataKey, columnData}: Record<string, any>) => (
        <SortedColumnTitle
            dispatch={this.props.dispatch}
            logger={this.props.logger}
            style={{
                textAlign: R.propOr('left', 'textAlign', columnData)
            }}
            label={label}
            field={R.propOr(dataKey, 'sortKey', columnData)}
            {...this.props.sorting}
        />
    );
    defaultCellRenderer = ({cellData}: Record<string, any>) => cellData;
    rowRenderer = ({columns, className, onRowClick, rowData, style}: Record<string, any>) => {
        const {
            features,
            collaborationOptions,
            collaboration,
            dispatch,
            logger,
            openedComment,
            currentType,
            policies,
            selectKey
        } = this.props;
        return (
            <TableRow
                collaborationEnabled={Boolean(features[FEATURE.COLLABORATION])}
                key={rowData[selectKey]}
                className={className}
                style={style}
                dispatch={dispatch}
                currentType={currentType}
                onRowClick={R.pipe(
                    R.tap(
                        R.pipe(
                            R.prop('rowData'),
                            logger('row-click'),
                            dispatch,
                            // @ts-ignore
                            () => document?.activeElement!.blur()
                        )
                    ),
                    onRowClick
                )}
                rowData={rowData}
                collaborationOptions={collaborationOptions}
                collaboration={collaboration}
                openedComment={openedComment}
                logger={logger}
                policies={policies}
            >
                {columns}
            </TableRow>
        );
    };
    getColumns = () => {
        const {isEditMode, columns, columnsData} = this.props;
        return (
            isEditMode
                ? [
                      <Column
                          key={-1}
                          width={50}
                          disableSort
                          headerRenderer={this.checkboxHeaderRenderer}
                          cellRenderer={this.checkboxCellRenderer}
                          dataKey="selected"
                          label=""
                      />
                  ]
                : []
        ).concat(
            columns.map((column, i) => (
                <Column
                    key={i}
                    disableSort
                    headerRenderer={this.headerRenderer}
                    {...column}
                    cellRenderer={column.cellRenderer || this.defaultCellRenderer}
                    columnData={{
                        ...column.columnData,
                        ...this.state,
                        ...columnsData
                    }}
                />
            ))
        );
    };

    render() {
        const {data, onRowClick, loadMore, logger, dispatch, onScroll, gridRef, scrollToIndex} = this.props;
        return (
            <div
                className={styles['custom-table__container']}
                ref={(ref) => (this._scrollElement = ref && ref.parentNode)}
            >
                <div className={styles['custom-table']}>
                    <Table
                        width={960}
                        height={120}
                        headerHeight={120}
                        rowHeight={0}
                        rowCount={0}
                        headerRowRenderer={this.headerRowRenderer}
                        rowGetter={() => {
                            throw new Error('Logic Error');
                        }}
                    >
                        {this.getColumns()}
                    </Table>
                    <WindowScroller scrollElement={this._scrollElement as Element}>
                        {({height, isScrolling, onChildScroll, scrollTop}) => (
                            <AutoSizer disableHeight={true}>
                                {({width}) => (
                                    <Table
                                        ref={(table) => {
                                            this._table = table;

                                            if (table && gridRef) {
                                                gridRef(table.Grid);
                                            }
                                        }}
                                        autoHeight
                                        height={height}
                                        width={width}
                                        headerHeight={0}
                                        disableHeader
                                        isScrolling={isScrolling}
                                        className={'with-overflow-visible'}
                                        onScroll={(data) => {
                                            onChildScroll(data);

                                            if (onScroll) {
                                                onScroll(data);
                                            }

                                            const {scrollHeight, clientHeight, scrollTop} = data;

                                            if (loadMore && scrollHeight - clientHeight - scrollTop <= SCROLL_OFFSET) {
                                                loadMore(R.pipe(logger('load-on-scroll'), dispatch));
                                            }
                                        }}
                                        scrollTop={scrollTop}
                                        rowCount={data.length}
                                        rowHeight={48}
                                        rowGetter={({index}) => data[index]}
                                        onRowClick={fromNull(onRowClick).orSome(noop)}
                                        rowRenderer={this.rowRenderer}
                                        scrollToIndex={scrollToIndex || -1}
                                        scrollToAlignment={'start'}
                                    >
                                        {this.getColumns()}
                                    </Table>
                                )}
                            </AutoSizer>
                        )}
                    </WindowScroller>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    openedComment: getOpenedComment(state),
    collaboration: ownProps.collaborationOptions ? state.collaboration[ownProps.collaborationOptions.objectType] : null,
    ...R.pick(['draftMode', 'currentType', 'policies'], state)
});

export default R.pipe(withFeatures, connect(mapStateToProps))(CustomTable);
