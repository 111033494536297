import FeaturesContext from './FeaturesContext';
import {Component} from 'react';
export default (WrappedComponent) => {
    class Connected extends Component {
        render() {
            return (
                <FeaturesContext.Consumer>
                    {(features) => <WrappedComponent features={features} {...this.props} />}
                </FeaturesContext.Consumer>
            );
        }
        static displayName = `WithFeatures(${WrappedComponent.displayName || WrappedComponent.name})`;
    }

    return Connected;
};
