/**
 * Created by ndyumin on 05.04.2017.
 */
export const searchQueryEvent = (searchQuery) => ({
    type: 'searchQuery',
    searchQuery
});
export const clearSearchQueryEvent = () => ({
    type: 'clearSearchQuery'
});
