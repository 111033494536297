import * as R from 'ramda';
import {Component} from 'react';
export const withContext = R.curry((Context, mapContextToProps, WrappedComponent) => {
    class Connected extends Component {
        render() {
            return (
                <Context.Consumer>
                    {(context) => <WrappedComponent {...this.props} {...mapContextToProps(context, this.props)} />}
                </Context.Consumer>
            );
        }

        static displayName = `WithContext(${WrappedComponent.displayName || WrappedComponent.name})`;
    }

    return Connected;
});
