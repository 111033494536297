import ReactDOM from 'react-dom';
import {Component, MouseEventHandler} from 'react';

type Props = {
    children: React.ReactElement;
    onClickOutside?: MouseEventHandler<HTMLDivElement>;
};
export default class ClickOutsideHOC extends Component<Props> {
    componentDidMount() {
        this.onClickHandler = this.onClickHandler.bind(this);
        document.body.addEventListener('click', this.onClickHandler);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.onClickHandler);
    }

    onClickHandler(event) {
        // eslint-disable-next-line react/no-find-dom-node
        const dialog = ReactDOM.findDOMNode(this) as Element;
        const rect = dialog?.getBoundingClientRect();
        const isInDialog =
            (rect.top <= event.clientY &&
                event.clientY <= rect.top + rect.height &&
                rect.left <= event.clientX &&
                event.clientX <= rect.left + rect.width) ||
            (dialog?.contains(event.target) && event.target !== dialog);
        const dialogVisible = rect.height !== 0 || rect.width !== 0;

        if (!isInDialog && dialogVisible && typeof this.props.onClickOutside === 'function') {
            this.props.onClickOutside(event);
        }
    }

    render() {
        return this.props.children;
    }
}
