/**
 * Created by ndyumin on 01.11.2016.
 */
import {Maybe} from 'monet';
import {compose} from './func';
export function lens(getter, setter) {
    return {
        get: getter,
        set: setter,
        compose: (l) =>
            lens(
                (obj) => l.get(getter(obj)),
                (obj, value) => setter(obj, l.set(getter(obj), value))
            )
    };
}
export function createLens(fieldName) {
    return lens(
        (obj) => obj[fieldName],
        (obj, value) =>
            Object.assign({}, obj, {
                [fieldName]: value
            })
    );
}
export const inObject = createLens;
export const prop = createLens;
export function inArray(key, value) {
    return lens(
        (arr) => arr.filter((v) => v[key] === value)[0],
        (arr, val) => arr.map((el) => (el[key] === value ? val : el))
    );
}
export function inArrayByIndex(index) {
    return lens(
        (arr) => arr[index],
        (arr, val) => ((arr[index] = val), arr)
    );
}
export const makeSafe = (l) =>
    lens(
        (obj) => Maybe.fromNull(obj).flatMap(compose(Maybe.fromNull, l.get)),
        (obj, value) => Maybe.fromNull(obj).map((obj) => l.set(obj, value))
    );
export const safeObjectLens = compose(makeSafe, prop);

const toMaybeLens = (l) =>
    lens(
        (safeObj) => safeObj.flatMap((obj) => Maybe.fromNull(l.get(obj))),
        (safeObj, safeValue) => safeValue.flatMap((value) => safeObj.map((obj) => l.set(obj, value)))
    );

export const safeProp = compose(toMaybeLens, prop);
// @ts-ignore
export const safeArray = compose(toMaybeLens, inArray);
export const safeArrayByIndex = compose(toMaybeLens, inArrayByIndex);
export const safeProps = (...args) => args.reduce((props, key) => prop(key).set(props, safeProp(key)), {});
export const props = (...args) => args.reduce((props, key) => prop(key).set(props, prop(key)), {});
export const path = (key: string, ...keys: string[]) =>
    // @ts-ignore
    keys.length ? prop(key).compose(path(...keys)) : prop(key);
export const safePath = (key, ...keys) =>
    // @ts-ignore
    keys.length ? safeProp(key).compose(safePath(...keys)) : safeProp(key);
