import * as R from 'ramda';
import i18n from 'ui-i18n';
import {commandCreator} from '../middlewares/command-middleware';
import {getTypeByUri} from '../../rdm-sdk/configuration';
import {json, requestWithBlockingSpinner} from '../../network';
import {then} from '../../core/func';
export function typesHierarchyEvent(hierarchy) {
    return {
        type: 'typesHierarchy',
        hierarchy
    };
}
const requestHierarchyCommand = commandCreator(({state}) => {
    const {tenant, configuration} = state;
    const isUriInConfig = getTypeByUri(configuration);
    const rejectInvalidUris = R.pipe(
        R.map(R.filter(isUriInConfig)),
        R.pickBy((value, key) => isUriInConfig(key) && value.length)
    );
    return requestWithBlockingSpinner(`/rdm/hierarchy/${tenant}/`, {}, i18n.text('Request hierarchy error'))
        .then(json)
        .then(
            R.evolve({
                hierarchy: rejectInvalidUris
            })
        );
});
export const requestHierarchySaga = (dispatch) =>
    R.pipe(requestHierarchyCommand, dispatch, then(R.pipe(typesHierarchyEvent, dispatch)));
