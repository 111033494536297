import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import {ReactElement} from 'react';

type Props = {
    selectProps: {
        classes: {
            multiValue: string;
        };
    };
    removeProps: Record<string, any>;
    children: ReactElement;
};

export default function MultiValue({children, selectProps, removeProps}: Props) {
    return (
        <Chip
            tabIndex={-1}
            label={children}
            className={selectProps.classes.multiValue}
            classes={{
                label: selectProps.classes['multiValue__label']
            }}
            onDelete={removeProps.onClick}
            deleteIcon={<CancelIcon {...removeProps} />}
        />
    );
}
