import * as R from 'ramda';
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import NetworkSubscription from '../../network/NetworkSubscription';
import Tooltip from '@mui/material/Tooltip';
import UserProfile from 'react-components/dist/AdminToolHeader/UserProfile/UserProfile';
import clsx from 'clsx';
import styles from './app-bar.less';
import {Component} from 'react';
import {Link} from 'react-router';
import {Maybe} from 'monet';
import {Session, SubMenu} from '../../rdm-sdk/app.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {compose, pipe} from '../../core/func';
import {connect} from 'react-redux';
import {drawerToggleEvent} from '../../redux/actions/app';
import {getShowNonBlockingSpinner} from '../../network/store';
import {later} from '../../core/util';
import {push} from 'react-router-redux';
const APP_TITLE_LENGTH = 20;

type NavIconProps = {
    dispatch: (e: StateEvent) => void;
    state: 'back' | 'menu';
    tenant?: string | null;
};

const NavIcon = ({dispatch, tenant, state}: NavIconProps) =>
    state === 'menu' ? (
        <IconButton
            className={styles['header__button']}
            onClick={compose(dispatch, drawerToggleEvent) as React.MouseEventHandler<HTMLButtonElement>}
            size="large"
        >
            <Icon>menu</Icon>
        </IconButton>
    ) : (
        <IconButton
            className={styles['header__button']}
            onClick={pipe(() => `/${tenant}/types`, push, dispatch)}
            size="large"
        >
            <Icon>arrow_back</Icon>
        </IconButton>
    );

const MenuTabs = ({location, submenu}: {location: Location; submenu: SubMenu[]}) => {
    const tabs = submenu.map((item) => {
        const active = location.pathname.indexOf(item.link) >= 0 ? styles['menu-tabs__tab--active'] : '';
        return (
            <Link className={clsx(active, styles['menu-tabs__tab'])} to={item.link} key={item.link}>
                {item.label}
            </Link>
        );
    });
    return <span className={clsx(styles['header__menu-tabs'], styles['menu-tabs'])}>{tabs}</span>;
};

type StateProps = {
    session?: Session | null;
    appTitle: string;
    submenu: SubMenu[];
    tenant?: string | null;
    dispatch: (e: StateEvent) => void;
};

type Props = StateProps & {
    location: Location;
};
type State = {
    showProgress: boolean;
};
export class Header extends Component<Props, State> {
    onLogout = later(R.pipe(R.always('/logout'), push, this.props.dispatch));

    render() {
        const {session, location, appTitle, submenu, tenant, dispatch} = this.props;
        const username = Maybe.fromNull(session)
            .flatMap((session) => Maybe.fromNull(session?.username))
            .orSome('');
        const email = Maybe.fromNull(session)
            .flatMap((session) => Maybe.fromNull(session?.email))
            .orSome('');

        const getHeaderTitle = () =>
            appTitle.length > APP_TITLE_LENGTH ? (
                <Tooltip title={appTitle}>
                    <span className={clsx(styles['header__title'], 'header-title')}>
                        {appTitle.slice(0, APP_TITLE_LENGTH)}...
                    </span>
                </Tooltip>
            ) : (
                <span className={clsx(styles['header__title'], 'header-title')}>{appTitle}</span>
            );
        return (
            <header className={styles['header']}>
                <NavIcon dispatch={dispatch} tenant={tenant} state={submenu.length ? 'back' : 'menu'} />
                {submenu.length ? (
                    getHeaderTitle()
                ) : (
                    <React.Fragment>
                        <span className={styles['header__logo-reltio']} />
                        <span className={clsx(styles['header__logo'], 'rdm-logo')} />
                        <span className={styles['header__logo-label']} />
                    </React.Fragment>
                )}

                <MenuTabs location={location} submenu={submenu} />
                <div className="spacer" />
                <NetworkSubscription selector={getShowNonBlockingSpinner}>
                    {(showSpinner) => showSpinner && <CircularProgress size={25} className={styles['progress']} />}
                </NetworkSubscription>
                <div className="header__buttons-container" id="header__buttons-container" />
                <div className="header__buttons-container" id="header__buttons-container-2" />
                <div className={styles['header__user-icon']}>
                    <UserProfile
                        user={{
                            username,
                            email
                        }}
                        onLogout={this.onLogout}
                    />
                </div>
            </header>
        );
    }
}
const mapStateToProps = R.pick(['session', 'appTitle', 'submenu', 'tenant']) as (state) => StateProps;
export default connect(mapStateToProps)(Header);
