import FeaturesContext from './FeaturesContext';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Component, ReactElement} from 'react';
import {Features} from '../../rdm-sdk/features.types';
type Props = {
    children: ReactElement;
    features: BehaviorSubject<Features>;
};
type State = {
    features: Features;
};
export default class FeatureProvider extends Component<Props, State> {
    subscription?: Subscription;

    constructor(props: Props) {
        super(props);
        this.state = {
            features: props.features.getValue()
        };
    }

    componentDidMount() {
        this.subscription = this.props.features.subscribe((features) =>
            this.setState({
                features
            })
        );
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    render() {
        return <FeaturesContext.Provider value={this.state.features}>{this.props.children}</FeaturesContext.Provider>;
    }
}
