import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
type Props = {
    className?: string;
    type?: 'ADD' | 'CHANGE' | 'DELETE' | null;
    text?: string;
};

const Tag = (props: Props) => {
    const {className, type, text, ...otherProps} = props;
    let iconClass: string;
    let iconText: string;

    switch (type) {
        case 'ADD':
            iconClass = styles['new-icon'];
            iconText = i18n.text('NEW');
            break;

        case 'CHANGE':
            iconClass = styles['edit-icon'];
            iconText = i18n.text('EDITED');
            break;

        case 'DELETE':
            iconClass = styles['delete-icon'];
            iconText = i18n.text('DELETED');
            break;

        default:
            return null;
    }

    return (
        <span className={clsx(iconClass, className)} {...otherProps}>
            {text || iconText}
        </span>
    );
};

export default Tag;
