import Icon from '@mui/material/Icon';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
type Props = {
    collapsed: boolean;
    onClick: () => void;
    showMoreLabel?: string;
    showLessLabel?: string;
    className?: string;
};

const CollapseButton = (props: Props) => {
    const {
        collapsed,
        onClick,
        showMoreLabel = i18n.text('Show more'),
        showLessLabel = i18n.text('Show less'),
        className
    } = props;
    return (
        <div className={clsx(styles['collapse-button'], className)} onClick={onClick}>
            {collapsed ? (
                <>
                    {showMoreLabel}
                    <Icon>arrow_drop_down</Icon>
                </>
            ) : (
                <>
                    {showLessLabel}
                    <Icon>arrow_drop_up</Icon>
                </>
            )}
        </div>
    );
};

export default CollapseButton;
