import * as R from 'ramda';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component} from 'react';
import {StateEvent} from '../../rdm-sdk/state.types';
import {fromNotEmpty, fromNull, notEmpty} from '../../core/maybe';
import {getValue} from '../../core/util';
import {orSome} from '../../core/monet';
import {requestUnmappedAllSaga, unmappedSearchQueryEvent} from '../../redux/actions/unmapped';
import {then} from '../../core/func';
type Props = {
    unmappedSearchQuery?: string | null;
    dispatch: (e: StateEvent) => void;
    onSearch: () => void;
    disabled: boolean;
};
type State = {
    searchString?: string | null;
};

class UnmappedSearchBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchString: props.unmappedSearchQuery
        };
    }

    _onChange = (searchString: string | null) =>
        this.setState({
            searchString
        });
    _clearSearch = () => {
        this._onChange(null);

        if (!this.props.unmappedSearchQuery) return;
        const {dispatch, onSearch} = this.props;
        dispatch(unmappedSearchQueryEvent(null));
        requestUnmappedAllSaga(dispatch)().then(onSearch);
    };

    render() {
        const {dispatch, onSearch, disabled = false} = this.props;
        const {searchString} = this.state;
        const isSearchStringNotEmpty = notEmpty(searchString);
        return (
            <div className={`${styles['unmapped-search-bar']} ${isSearchStringNotEmpty ? 'active' : ''}`}>
                <Icon className={styles['search-icon']}>search</Icon>
                <TextField
                    variant="standard"
                    placeholder={i18n.text('Search unmapped values & codes')}
                    onKeyPress={(e) =>
                        e.key === 'Enter'
                            ? R.pipe(
                                  R.trim,
                                  fromNotEmpty,
                                  orSome(null),
                                  unmappedSearchQueryEvent,
                                  dispatch,
                                  R.always(null),
                                  requestUnmappedAllSaga(dispatch),
                                  then(onSearch)
                              )(searchString)
                            : null
                    }
                    onChange={R.pipe(getValue, this._onChange)}
                    value={fromNull(searchString).orSome('')}
                    autoFocus
                    fullWidth
                    disabled={disabled}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
                {isSearchStringNotEmpty && (
                    <IconButton
                        className={styles['close-button']}
                        onClick={this._clearSearch}
                        disabled={disabled}
                        size="large"
                    >
                        <Icon>close</Icon>
                    </IconButton>
                )}
            </div>
        );
    }
}

export default UnmappedSearchBar;
