import {LookupValue, SourceMapping} from '../../rdm-sdk/lookups.types';
import {SearchHighlightMapping} from './searchHelpers';
export type OnDetailsOpen = (
    source: string,
    mappingIndex: number | null,
    lookupValue: LookupValue,
    searchMapping: SearchHighlightMapping | null
) => (event: any) => void;

export type DetailsOpen = {
    entry: LookupValue;
    index: number;
    searchMapping: SearchHighlightMapping;
    source: string;
};

export type UpdateSourceMapping = (
    source: string,
    mappingIndex: number,
    lookupValue: LookupValue,
    mapping: SourceMapping
) => void;
export type InsertSourceMapping = (source: string, lookupValue: LookupValue, mapping: SourceMapping) => void;
export type RemoveSourceMapping = (source: string, mappingIndex: number, lookupValue: LookupValue) => void;

export enum SidePanel {
    UNMAPPED = 'unmapped',
    ACTIVITY_LOG = 'activityLog',
    WORKFLOW = 'workflow'
}
export type OpenedPanel = null | SidePanel;
