import * as R from 'ramda';
import {StateEvent} from '../../../../rdm-sdk/state.types';
import {createContext, memo, useContext, useMemo, useReducer} from 'react';
import {noop} from '../../../../core/util';

type ValuesSidePanelStoreContext = {
    state: any;
    dispatch: (event: StateEvent) => void;
};
const ValuesSidePanelStore = createContext<ValuesSidePanelStoreContext>({} as ValuesSidePanelStoreContext);
const initialState = {
    openedSourceChanges: {},
    openedHierarchyChangesEvent: {}
};
export function setOpenedSourceChangesEvent(key, open) {
    return {
        type: 'setOpenedSourceChanges',
        key,
        open
    };
}
export function setOpenedHierarchyChangesEvent(key, open) {
    return {
        type: 'setOpenedHierarchyChangesEvent',
        key,
        open
    };
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'setOpenedSourceChanges':
            return R.assocPath(['openedSourceChanges', action.key], action.open, state);

        case 'setOpenedHierarchyChangesEvent':
            return R.assocPath(['openedHierarchyChangesEvent', action.key], action.open, state);

        default:
            return state;
    }
};

export function ValuesSidePanelProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(
        () => ({
            state,
            dispatch
        }),
        [state, dispatch]
    );
    return <ValuesSidePanelStore.Provider value={value} {...props} />;
}

export type MapStateToProps = (state: any, props: any) => Record<string, any> | void;
export const connectValuesSidePanelStore =
    (mapStateToProps: MapStateToProps = noop) =>
    (WrappedComponent) => {
        function Connected(props) {
            const {dispatch, state} = useContext(ValuesSidePanelStore);
            return <WrappedComponent dispatch={dispatch} {...mapStateToProps(state, props)} {...props} />;
        }

        Connected.displayName = `connectValuesSidePanelStore(${WrappedComponent.displayName || WrappedComponent.name})`;
        return memo(Connected);
    };
