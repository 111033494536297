/**
 * Created by ndyumin on 07.02.2017.
 */
import ExtendableError from 'es6-error';
import {path, prop} from 'ramda';
export class ReltioError extends ExtendableError {
    constructor(details) {
        super(details.message);
    }
}

const getErrorMessageByDetails = (details) =>
    prop('error_description', details) || prop('errorMessage', details) || path(['error', 'errorMessage'], details);

export class ReltioNetworkError extends ReltioError {
    _response: any;

    constructor(details, response) {
        super(
            Object.assign({}, details, {
                message: getErrorMessageByDetails(details)
            })
        );
        this._response = response;
    }

    getResponse() {
        return this._response;
    }
}
