import CustomIcon from '../../CustomIcon/CustomIcon';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './suggestor.less';
import {Component} from 'react';
import {User} from '../../../rdm-sdk/app.types';
import {getClosestScrollContainer, getTranslate, noop, toNegativeOrZero, toPositiveOrZero} from '../../../core/util';
type SuggestorProps = {
    users: User[];
    selected: number;
    onItemClick: (arg0: User) => void;
    onItemMouseEnter: (arg0: number) => void;
    suggestorRef: (arg0: HTMLUListElement | null) => void;
    style?: Record<string, any>;
};
type State = {
    isTopPosition: boolean;
};

class Suggestor extends Component<SuggestorProps, State> {
    suggestor: HTMLUListElement | null = null;
    state = {
        isTopPosition: false
    };

    componentDidMount() {
        this.moveSuggestor();
        const scrollContainer = getClosestScrollContainer(this.suggestor as Element);
        scrollContainer && scrollContainer.addEventListener('scroll', this.moveSuggestor);
        window.addEventListener('resize', this.moveSuggestor);
    }

    componentDidUpdate() {
        this.moveSuggestor();
    }

    componentWillUnmount() {
        const scrollContainer = getClosestScrollContainer(this.suggestor as Element);
        scrollContainer && scrollContainer.removeEventListener('scroll', this.moveSuggestor);
        window.removeEventListener('resize', this.moveSuggestor);
    }

    moveSuggestor = () => {
        const suggestor = this.suggestor;
        const scrollContainer = getClosestScrollContainer(suggestor as Element);

        if (scrollContainer) {
            const translateSuggestor = getTranslate(scrollContainer, suggestor);
            if (toNegativeOrZero(translateSuggestor.bottom))
                !this.state.isTopPosition &&
                    this.setState({
                        isTopPosition: true
                    });
            else
                this.state.isTopPosition &&
                    toPositiveOrZero(translateSuggestor.top) &&
                    this.setState({
                        isTopPosition: false
                    });
        }
    };
    setRefs = (ref: null | HTMLUListElement) => {
        const {suggestorRef = noop} = this.props;
        this.suggestor = ref;
        suggestorRef(ref);
    };

    render() {
        const {users, selected, style, onItemClick, onItemMouseEnter} = this.props;
        const {isTopPosition} = this.state;
        return (
            <ul
                className={clsx(styles['suggestor'], isTopPosition && styles['suggestor--top-position'])}
                style={style}
                ref={this.setRefs}
            >
                {users.map((user, index) => (
                    <li
                        key={index}
                        className={clsx(
                            styles['suggestor__item'],
                            index === selected && styles['suggestor__item--selected']
                        )}
                        onClick={() => onItemClick(user)}
                        onMouseEnter={() => onItemMouseEnter(index)}
                    >
                        <div className={styles['suggestor__avatar']}>
                            <CustomIcon name="face" />
                        </div>
                        <div className={styles['suggestor__info']}>
                            <p className={styles['suggestor__username']}>{user.username}</p>
                            <p className={styles['suggestor__email']}>{user.email}</p>
                        </div>
                    </li>
                ))}
                {!users.length && (
                    <div className={clsx(styles['suggestor__item'], styles['suggestor__item--no-found'])}>
                        {i18n.text('Found no matching users')}
                    </div>
                )}
            </ul>
        );
    }
}

export default Suggestor;
