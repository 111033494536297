import * as R from 'ramda';
import {checkClientOnly} from '../core/marks';
import {fromNull} from '../core/maybe';
import {getTypeByUri} from './configuration';
import {map, orSome} from '../core/monet';
export const typeCodeToUri = (type: string) => `rdm/lookupTypes/${type}`;
export const typeUriToCode: (uri: string) => string = R.pipe(R.split('/'), R.last);
export const getTypeByCode = R.useWith(getTypeByUri, [R.identity, typeCodeToUri]);
export const checkIfTypeIsClientOnly = R.pipe(getTypeByCode, fromNull, map(checkClientOnly), orSome(false));
export const getCurrentGenerator = R.pipe(getTypeByCode, R.prop('generator'));
export const getTypeCode = R.pipe(R.prop('uri'), typeUriToCode);
export const TYPE_ATTR_TYPES = [
    'Number', // i18n.text('Number')
    'Boolean', // i18n.text('Boolean'),
    'Integer', // i18n.text('Integer'),
    'Float', // i18n.text('Float'),
    'Time', // i18n.text('Time'),
    'Date', // i18n.text('Date'),
    'String' // i18n.text('String')
];
