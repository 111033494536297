import * as R from 'ramda';
import EditTypeDialog from '../../components/EditTypeDialog/EditTypeDialog';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ReactDOM from 'react-dom';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import {Component, Fragment} from 'react';
import {StateEvent} from '../../rdm-sdk/state.types';
import {push} from 'react-router-redux';
type Props = {
    onEdit: () => void;
    dispatch: (e: StateEvent) => Promise<any>;
    tenantId: string;
    onDelete: () => void;
    disabled?: boolean;
};
type State = {
    dialogOpen: boolean;
};

class EditTypeButton extends Component<Props, State> {
    state = {
        dialogOpen: false
    };
    openDialog = () =>
        this.setState({
            dialogOpen: true
        });
    closeDialog = () =>
        this.setState({
            dialogOpen: false
        });
    onEdited = () => {
        this.closeDialog();
        setTimeout(() => this.props.onEdit());
    };

    render() {
        const {dispatch, tenantId, onDelete, disabled = false} = this.props;
        const headerButtonsContainer = document.getElementById('header__buttons-container');
        const editTypeButton = headerButtonsContainer
            ? ReactDOM.createPortal(
                  <Tooltip title={i18n.text('Edit lookup type')}>
                      <IconButton color="inherit" onClick={this.openDialog} disabled={disabled} size="large">
                          <Icon>settings</Icon>
                      </IconButton>
                  </Tooltip>,
                  headerButtonsContainer
              )
            : false;
        return (
            <Fragment>
                {editTypeButton}
                <EditTypeDialog
                    open={this.state.dialogOpen}
                    onCancelled={this.closeDialog}
                    onEdited={this.onEdited}
                    onRemoved={R.pipe(this.closeDialog, onDelete, R.always(push(`/${tenantId}/types`)), dispatch)}
                />
            </Fragment>
        );
    }
}

export default EditTypeButton;
