import * as R from 'ramda';
import {createRxStore} from '../core/util';
export const NETWORK_INITIAL_STATE = {
    blockingRequestCount: 0,
    nonBlockingRequestCount: 0
};
export default createRxStore(R.flip(R.evolve), NETWORK_INITIAL_STATE);
export const getShowNonBlockingSpinner = (state) =>
    state.blockingRequestCount <= 0 && state.nonBlockingRequestCount > 0;
export const getIsLoading = (state) => state.blockingRequestCount > 0 || state.nonBlockingRequestCount > 0;
