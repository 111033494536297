import type {} from '@mui/x-date-pickers/themeAugmentation';

import '@mui/styles';
import {Theme, createTheme} from '@mui/material/styles';

declare module '@mui/styles' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        grey: true;
    }
}

const rdmTheme = createTheme({
    components: {
        MuiInput: {
            styleOverrides: {
                formControl: {
                    'label + &': {
                        marginTop: '20px',
                        marginBottom: '20px'
                    }
                },
                root: {
                    color: 'rgba(0,0,0,0.87)',
                    lineHeight: 'none'
                },
                input: {
                    borderBottomColor: 'rgba(0,0,0,0.42)',
                    padding: '4px 0 5px'
                },
                underline: {
                    '&:hover:not($disabled)::before': {
                        height: 1
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottom: '1px solid rgba(0,0,0,0.42)'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    '&:hover': {
                        backgroundColor: '#2B98F0'
                    }
                }
            },
            variants: [
                {
                    props: {variant: 'grey'},
                    style: {
                        color: 'rgba(0,0,0,0.65)',
                        borderRadius: '2px',
                        padding: '6px 8px',
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.15)'
                        }
                    }
                }
            ]
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                    padding: 0,
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.15)'
                    }
                }
            }
        },
        MuiTouchRipple: {
            styleOverrides: {
                root: {
                    color: 'rgba(0,0,0,0.54)'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    whiteSpace: 'normal'
                },
                tooltip: {
                    fontWeight: 300,
                    position: 'relative',
                    top: '-15px'
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    zIndex: 200
                },
                paper: {
                    boxShadow: '0 0 24px 0 rgba(0,0,0,0.22), 0 24px 24px 0 rgba(0,0,0,0.3)',
                    flexDirection: 'column',
                    borderRadius: '2px',
                    backgroundColor: '#FFF',
                    padding: 0,
                    overflowY: 'visible',
                    margin: 0
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.87)',
                    padding: '20px 24px',
                    borderBottom: '1px solid rgba(0,0,0,0.12)'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: '0 24px',
                    margin: '-1px 0',
                    position: 'relative',
                    zIndex: 100,
                    '& > *': {
                        flex: '0 0 auto'
                    },
                    '&::after': {
                        flex: '1 0 auto',
                        content: '""',
                        display: 'block',
                        minHeight: '1px',
                        margin: '0 -24px',
                        borderBottom: '2px solid #fff'
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        margin: '0 -24px',
                        borderTop: '2px solid #fff'
                    },
                    '&:first-child': {
                        marginTop: '24px',
                        paddingTop: 0
                    },
                    '&:last-child': {
                        marginBottom: '24px',
                        paddingBottom: 0
                    },
                    '&::-webkit-scrollbar-track': {
                        borderRadius: '10px',
                        backgroundColor: 'white'
                    },
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '10px',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                    }
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '8px 8px 8px 24px',
                    minHeight: '36px',
                    borderTop: 'solid 1px rgba(0,0,0,0.12)',
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 0
                },
                spacing: {
                    margin: 0,
                    '&:first-child': {
                        marginLeft: '0'
                    },
                    '&:not(:first-child)': {
                        marginLeft: '8px'
                    }
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 24
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&:not($checked)': {
                        color: '#fafafa'
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        '&.Mui-focusVisible': {backgroundColor: 'rgba(0, 0, 0, 0.08)'},
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.08)'
                        }
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    margin: '2px'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'inherit'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: '2px'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.8125rem'
                }
            }
        }
    },
    typography: {
        button: {
            fontSize: 14
        },
        body2: {
            fontSize: 16
        },
        h6: {
            fontSize: 20,
            lineHeight: '28px'
        },
        subtitle1: {
            fontSize: 14
        }
    },
    palette: {
        error: {
            main: '#FF1744'
        },
        primary: {
            main: '#2B98F0'
        },
        secondary: {
            main: '#2B98F0'
        }
    }
});
export default rdmTheme;
