import Typography, {TypographyProps} from '@mui/material/Typography';
import {ReactElement} from 'react';

type Props = {
    selectProps: {
        classes: {
            singleValue: string;
        };
    };
    innerProps: TypographyProps;
    children: ReactElement;
};

export default function SingleValue(props: Props) {
    const {
        selectProps: {classes},
        innerProps,
        children
    } = props;
    return (
        <Typography className={classes.singleValue} {...innerProps}>
            {children}
        </Typography>
    );
}
