// fork of https://github.com/abinavseelan/react-input-trigger
import getCaretCoordinates from 'textarea-caret';
import {Children, Component, ReactElement, RefObject, cloneElement} from 'react';
import {noop} from '../../core/util';

function getHookObject(
    type: string,
    element: HTMLTextAreaElement | null,
    startPoint: number | null = null
): InputMetaData {
    const caret = getCaretCoordinates(element, element?.selectionEnd);
    const result = {
        hookType: type,
        cursor: {
            selectionStart: element?.selectionStart,
            selectionEnd: element?.selectionEnd,
            top: caret.top,
            left: caret.left,
            height: caret.height
        }
    } as InputMetaData;

    if (!startPoint) {
        return result;
    }

    result.text = element?.value.substring(startPoint, element.selectionStart);
    return result;
}

type InputMetaData = {
    hookType: 'start' | 'cancel' | 'typing';
    cursor: {
        selectionStart: number;
        selectionEnd: number;
        top: number;
        left: number;
        height: number;
    };
    text?: string;
};

type Props = {
    element: HTMLTextAreaElement | null;
    trigger: {
        keyCode: number;
        shiftKey?: boolean;
        ctrlKey?: boolean;
        metaKey?: boolean;
    };
    onStart: (metaData: InputMetaData) => void;
    onCancel: () => void;
    onType: (metaData: InputMetaData) => void;
    endTrigger: (endTrigger: () => void) => void;
    children: ReactElement;
    inputRef?: RefObject<HTMLDivElement>;
};

type State = {
    triggered: boolean;
    triggerStartPosition: number | null;
};

class InputTrigger extends Component<Props, State> {
    element: HTMLTextAreaElement | null;
    constructor(props) {
        super(props);
        this.state = {
            triggered: false,
            triggerStartPosition: null
        };
        this.handleTrigger = this.handleTrigger.bind(this);
        this.resetState = this.resetState.bind(this);
        this.element = this.props.element;
    }

    componentDidUpdate() {
        this.element = this.props.element;
    }

    componentDidMount() {
        this.props.endTrigger(this.resetState);
    }

    handleTrigger(event) {
        const {trigger, onStart, onCancel, onType} = this.props;
        const {which, shiftKey, metaKey, ctrlKey} = event;
        const {selectionStart} = event.target;
        const {triggered, triggerStartPosition} = this.state;

        if (!triggered) {
            if (
                which === trigger.keyCode &&
                shiftKey === !!trigger.shiftKey &&
                ctrlKey === !!trigger.ctrlKey &&
                metaKey === !!trigger.metaKey
            ) {
                this.setState(
                    {
                        triggered: true,
                        triggerStartPosition: selectionStart + 1
                    },
                    () => {
                        setTimeout(() => {
                            onStart(getHookObject('start', this.element));
                        }, 0);
                    }
                );
            }
        } else {
            if (which === 8 && selectionStart <= Number(triggerStartPosition)) {
                this.setState(
                    {
                        triggered: false,
                        triggerStartPosition: null
                    },
                    () => {
                        setTimeout(() => {
                            onCancel();
                        }, 0);
                    }
                );
            } else {
                setTimeout(() => {
                    onType(getHookObject('typing', this.element, triggerStartPosition));
                }, 0);
            }
        }

        return null;
    }

    resetState() {
        this.setState({
            triggered: false,
            triggerStartPosition: null
        });
    }

    render() {
        const {element, children, inputRef} = this.props;
        return (
            <div ref={inputRef} role="button" tabIndex={-1} onKeyDown={element ? this.handleTrigger : noop}>
                {!element
                    ? Children.map(this.props.children, (child) =>
                          cloneElement(child, {
                              ref: (element) => {
                                  this.element = element;
                              }
                          })
                      )
                    : children}
            </div>
        );
    }

    static defaultProps = {
        trigger: {
            keyCode: null,
            shiftKey: false,
            ctrlKey: false,
            metaKey: false
        },
        onStart: () => {},
        onCancel: () => {},
        onType: () => {},
        endTrigger: () => {},
        element: null
    };
}

export default InputTrigger;
