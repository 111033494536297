import MarkLabel from '../MarkLabel/MarkLabel';
import {checkRemoved} from '../../core/marks';
export default function draftIconCellRenderer({rowData, cellData}: Record<string, any>) {
    const isRemoved = checkRemoved(rowData);
    return (
        <div
            style={{
                color: isRemoved ? 'inherit' : 'rgba(0, 0, 0, 0.87)'
            }}
        >
            <MarkLabel entity={rowData} /> {cellData}
        </div>
    );
}
