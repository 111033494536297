import * as R from 'ramda';
import initialState from '../initialState';
import {UI} from '../constants/actionTypes';
import {UiState} from '../../rdm-sdk/ui.types';
export const getIsSearchBarVisible = R.pathOr(false, ['ui', 'isSearchBarVisible']);
export const getOpenedComment = R.pathOr(null, ['ui', 'openedComment']);
export const getOpenedSnackbar = R.pathOr(false, ['ui', 'openedSnackbar']);
export const getOpenedTask = R.pathOr(false, ['ui', 'openedTask']);
// eslint-disable-next-line prettier/prettier
export default function uiReducer(state = initialState.ui as UiState, event) { // NOSONAR
    switch (event.type) {
        case UI.IS_SEARCH_BAR_VISIBLE:
            return R.assoc('isSearchBarVisible', event.isVisible, state);

        case UI.OPENED_COMMENT:
            return R.assoc('openedComment', event.openedComment, state);

        case UI.OPENED_SNACKBAR:
            return R.assoc('openedSnackbar', event.openedSnackbar, state);

        case UI.OPENED_TASK:
            return R.assoc('openedTask', event.openedTask, state);

        default:
            return state;
    }
}
