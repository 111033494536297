/**
 * Created by ndyumin on 13.10.2016.
 */
import * as R from 'ramda';
import i18n from 'ui-i18n';
import {AUTO_GENERATED_CODE_PREFIX} from '../../constants/common';
import {CONFIG} from '../../rdm-sdk/configs';
import {Maybe} from 'monet';
import {RequestBuilder} from '../../rdm-sdk/network';
import {checkEdited, checkNew, checkRemoved, metadataReplacer} from '../../core/marks';
import {checkIfTypeIsClientOnly, typeUriToCode} from '../../rdm-sdk/types';
import {commandCreator} from '../middlewares/command-middleware';
import {configStream$} from '../../configs';
import {createDCRSaga} from './workflow';
import {curry, pipe, then} from '../../core/func';
import {
    deleteMappedUnmappedSaga,
    suggestDeleteMappedUnmappedSaga,
    unmappedRemovedItemsClearEvent,
    unmappedSelectedItemsClearEvent
} from './unmapped';
import {
    formatReference,
    parseLookupUri,
    parseLookupValue,
    parseLookupValues,
    rejectRemovedAndEmptyMappings,
    rejectRemovedLocalizations
} from '../../rdm-sdk/lookups';
import {json, requestWithBlockingSpinner, requestWithNonBlockingSpinner} from '../../network';
import {logError, sequentialPromiseAll} from '../../core/util';
import {needMdmSyncResetEvent, showDisclaimerSnackbarCommand} from './ui';
import {paginationHasMoreEvent, paginationScrollIdEvent, totalEvent} from './app';
import {prop, safePath} from '../../core/lenses';
import {requestLookupTotalsBySourcesSaga, resetAllStatsEvent} from './stats';
import {requestWorkflowTasksAndDCRsSaga, resetTasksPaginationEvent, startProcessSaga} from './workflow';
import {suggestUpdateDependenciesSaga, updateDependenciesSaga} from './dependencies';
import {typeFilter, typeValueFilter, valuesFilterFromState} from '../../rdm-sdk/filters';
type SetErrorFlag = (data: any) => any;
export const MAPPED_VALUES_LIMIT = '40';
const MAX_BY_URI_REQUEST_SIZE = 1000;
const MAX_TOTALS_SOURCES = '10000';
export function valuesReceivedEvent(lookupValues) {
    return {
        type: 'valuesReceived',
        lookupValues
    };
}
export function appendValuesEvent(lookupValues) {
    return {
        type: 'appendValues',
        lookupValues
    };
}
export function changeAvailableSourcesEvent(availableSources: string[]) {
    return {
        type: 'changeAvailableSources',
        availableSources
    };
}
export const requestTotalCommand = commandCreator(({state}) => {
    const {tenant, currentType, configuration} = state;
    if (!currentType || checkIfTypeIsClientOnly(configuration, currentType))
        return Promise.resolve({
            total: 0
        });
    const filter = valuesFilterFromState(state);
    const req = new RequestBuilder(`/rdm/lookups/${tenant}/${currentType}/_total/`).addParam('filter', filter).build();
    return requestWithNonBlockingSpinner(req).then(json);
});
export const requestAvailableSourcesCommand = commandCreator(({state}) => {
    const {tenant, currentType} = state;

    if (!currentType) {
        return Promise.resolve([]);
    }

    const request = new RequestBuilder(`/rdm/lookups/${tenant}/_facets`)
        .addParam('facet', 'source')
        .addParam('max', MAX_TOTALS_SOURCES)
        .addParam('filter', typeFilter(Maybe.fromNull(currentType)))
        .build();
    return requestWithNonBlockingSpinner(request, {}, i18n.text('Sources request error'))
        .then(json)
        .then(prop('source').get)
        .then(R.keys);
});
export const requestAvailableSourcesSaga = (dispatch: (...args: any[]) => any): ((...args: any[]) => any) =>
    pipe(requestAvailableSourcesCommand, dispatch, then(pipe(changeAvailableSourcesEvent, dispatch)));
export const requestTotalSaga = (dispatch: (...args: any[]) => any): ((...args: any[]) => any) =>
    pipe(requestTotalCommand, dispatch, then(pipe(prop('total').get, totalEvent, dispatch)));
export const requestAllValuesCommand = commandCreator(({state}, type, value, options = {}) => {
    const {tenant, configuration} = state;
    const currentTypeIsClientOnly = type && checkIfTypeIsClientOnly(configuration, typeUriToCode(type));
    if (currentTypeIsClientOnly) return Promise.resolve([]);
    const filter = typeValueFilter(Maybe.fromNull(type), Maybe.fromNull(value));
    const req = new RequestBuilder(`/rdm/lookups/${tenant}/`)
        .addParam('filter', filter)
        .addParam('sort', options.field)
        .addParam('order', options.direction)
        .addParam('limit', options.limit)
        .addParam('offset', options.offset)
        .build();
    const request = options.isNonBlocking ? requestWithNonBlockingSpinner : requestWithBlockingSpinner;
    return request(req)
        .then(json)
        .catch((error) => {
            console.error('Request all values error:', error);
            return [];
        });
});

const deleteValuesRequest = ({tenant, uris}) =>
    requestWithBlockingSpinner(
        `/rdm/lookups/${tenant}/_delete`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uris
            })
        },
        i18n.text('Delete value error')
    ).then(json);

const suggestDeleteValuesRequest = ({tenant, uris, DCRID}) =>
    requestWithBlockingSpinner(
        `/rdm/changeRequests/${tenant}/${DCRID}/lookups/_delete`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uris
            })
        },
        i18n.text('Suggest delete value error')
    ).then(json);

export const deleteValuesCommand = commandCreator(({state}, setErrorFlag, DCRID = null) => {
    const {tenant, currentType, lookupValues} = state;
    const deleteRequests = R.pipe(
        R.filter(checkRemoved),
        R.map(({code}) => `${tenant}/${currentType}/${code}`),
        R.splitEvery(MAX_BY_URI_REQUEST_SIZE),
        R.map((uris) =>
            DCRID
                ? suggestDeleteValuesRequest({
                      tenant,
                      uris,
                      DCRID
                  })
                : deleteValuesRequest({
                      tenant,
                      uris
                  })
        )
    )(lookupValues);
    return Promise.all(deleteRequests)
        .then(R.flatten)
        .then(R.when(R.any(R.has('error')), setErrorFlag));
});
export const deleteValuesSaga = (dispatch: (...args: any[]) => any) =>
    R.pipe(
        deleteValuesCommand,
        dispatch,
        then(
            R.pipe(
                R.filter(R.has('value')),
                R.reduce((acc, {uri}) => {
                    const [, type, code] = uri.split('/');
                    return {
                        [type]: !acc[type] ? [code] : [...acc[type], code]
                    };
                }, {}),
                R.forEachObjIndexed((codes, type) => dispatch(deleteValuesEvent(type, codes)))
            )
        )
    );
export const suggestDeleteValuesSaga = (dispatch: (...args: any[]) => any) => R.pipe(deleteValuesCommand, dispatch);
export const requestValuesCommand = commandCreator(({state, dispatch}, params = {}) => {
    const {tenant, currentType, configuration} = state;

    if (!currentType || checkIfTypeIsClientOnly(configuration, currentType)) {
        return Promise.resolve([]);
    }

    const {resume} = params;
    const safeState = Maybe.fromNull(state);
    const safeScrollId = safePath('pagination', 'scrollId').get(safeState);
    const safeSortingDirection = safePath('sorting', 'direction').get(safeState);
    const safeSortingField = safePath('sorting', 'field').get(safeState);
    const filter = valuesFilterFromState(state);
    const req = new RequestBuilder(`/rdm/lookups/${tenant}/_scan`)
        .addParam('limit', MAPPED_VALUES_LIMIT)
        .addParam('filter', filter)
        .addParam('sort', safeSortingField.orSome(undefined))
        .addParam('order', safeSortingDirection.orSome(undefined))
        .build();
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: resume ? safeScrollId.orSome('') : ''
    };
    return requestWithBlockingSpinner(req, options, i18n.text('Request lookup values error'))
        .then(json)
        .then(
            R.tap((data) => {
                dispatch(paginationScrollIdEvent(R.propOr('', 'scrollId', data)));
                dispatch(paginationHasMoreEvent(R.has('scrollId', data)));
            })
        )
        .then(R.pipe(R.propOr([], 'values'), parseLookupValues))
        .catch((error) => {
            console.error('Request values error:', error);
            return [];
        });
});
export const requestValuesSaga = (dispatch: (...args: any[]) => any): ((...args: any[]) => any) =>
    pipe(requestValuesCommand, dispatch, then(pipe(valuesReceivedEvent, dispatch)));
export const resetValuesSaga = (dispatch: (...args: any[]) => any): ((...args: any[]) => any) =>
    pipe(
        requestValuesSaga(dispatch),
        then(
            pipe(
                unmappedRemovedItemsClearEvent,
                dispatch,
                unmappedSelectedItemsClearEvent,
                dispatch,
                needMdmSyncResetEvent,
                dispatch
            )
        )
    );
export const requestNextValuesSaga = (dispatch: (...args: any[]) => any): ((...args: any[]) => any) =>
    pipe(
        R.always({
            resume: true
        }),
        requestValuesCommand,
        dispatch,
        then(pipe(appendValuesEvent, dispatch))
    );
export const requestValuesByUrisCommand = (tenant: string, uris: string[]) =>
    Promise.all(
        R.splitEvery(MAX_BY_URI_REQUEST_SIZE, uris).map((uris) =>
            requestWithBlockingSpinner(`/rdm/lookups/${tenant}/_byUris`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uris
                })
            }).then(json)
        )
    )
        .then(R.flatten)
        .catch((error) => {
            console.error('_byUris request error', error);
            return [];
        });
export const checkCodeUniqueCommand = commandCreator(({state}, code) => {
    const {tenant, currentType, lookupValues, configuration} = state;
    const isCodeLocallyExists = Boolean(R.find(R.propEq('code', code), lookupValues));
    const isCheckedLocally = checkIfTypeIsClientOnly(configuration, currentType) || isCodeLocallyExists;
    return isCheckedLocally
        ? Promise.resolve(!isCodeLocallyExists)
        : requestValuesByUrisCommand(tenant, [`${tenant}/${currentType}/${code}`]).then((values) => {
              return !isCodeLocallyExists && values.length === 0;
          });
});
export const upsertValueEvent = curry((type, code, value) => ({
    type: 'upsertValue',
    luType: type,
    code,
    value
}));
export function lookupValuesRemoveEvent(values): Record<string, any> {
    return {
        type: 'lookupValuesRemove',
        values
    };
}
export const deleteValuesEvent = curry((currentType: string, codes: string[]) => ({
    type: 'deleteValues',
    currentType,
    codes
}));
const LOOKUP_VALUES_API_LIMIT = 100;

const updateLookupValuesRequest = ({tenant, values, encodedType}) =>
    requestWithBlockingSpinner(
        `/rdm/lookups/${tenant}/${encodedType}/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values, metadataReplacer)
        },
        i18n.text('Update lookup values error')
    ).then(json);

const suggestUpdateLookupValuesRequest = ({tenant, values, encodedType, DCRID}) =>
    requestWithBlockingSpinner(
        `/rdm/changeRequests/${tenant}/${DCRID}/lookups/${encodedType}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values, metadataReplacer)
        },
        i18n.text('Suggest lookup values error')
    ).then(json);

const updateLookupValuesCommand = commandCreator(({state}, setErrorFlag, DCRID = null) => {
    const {tenant, currentType, lookupValues} = state;
    const values = lookupValues
        .filter((value) => checkNew(value) || checkEdited(value))
        .map(rejectRemovedAndEmptyMappings)
        .map(rejectRemovedLocalizations)
        .map(formatReference(tenant, currentType));

    const updateValues = (values) => {
        const updateRequest = () =>
            DCRID
                ? suggestUpdateLookupValuesRequest({
                      tenant,
                      values,
                      encodedType,
                      DCRID
                  })
                : updateLookupValuesRequest({
                      tenant,
                      values,
                      encodedType
                  });
        const encodedType = encodeURIComponent(currentType);
        return values.length ? updateRequest() : Promise.resolve([]);
    };

    const updateValuesInChunks = (values) =>
        Promise.all(R.splitEvery(LOOKUP_VALUES_API_LIMIT, values).map(updateValues)).then(R.flatten);

    return updateValuesInChunks(values).then(R.when(R.any(R.has('error')), setErrorFlag));
});
const removeAutoGeneratedLookupValuesCommand = commandCreator(({state, dispatch}) => {
    const {lookupValues, currentType} = state;
    R.pipe(
        R.pluck('code'),
        R.filter(R.startsWith(AUTO_GENERATED_CODE_PREFIX)),
        R.compose(dispatch, deleteValuesEvent(currentType))
    )(lookupValues);
});
export const updateValuesSaga = (dispatch: (...args: any[]) => any) =>
    R.pipe(
        updateLookupValuesCommand,
        dispatch,
        then(
            R.pipe(R.tap(R.compose(dispatch, removeAutoGeneratedLookupValuesCommand)), (responseData) => {
                const updatedValues = responseData.reduce((acc, {value, uri}) => {
                    if (value) {
                        const {type, code} = parseLookupUri(uri);
                        return acc.concat({
                            type,
                            code,
                            value: parseLookupValue(value)
                        });
                    } else {
                        return acc;
                    }
                }, []);
                return deleteMappedUnmappedSaga(dispatch)(updatedValues).then(() =>
                    updatedValues.map(({type, code, value}) => dispatch(upsertValueEvent(type, code, value)))
                );
            })
        )
    );
export const updateValuesDataSaga = (dispatch: (...args: any[]) => any) => () => {
    let errorFlag = false;

    const isError = () => errorFlag;

    const setErrorFlag: SetErrorFlag = (data) => {
        errorFlag = true;
        return data;
    };

    const onSuccess = () => {
        dispatch(showDisclaimerSnackbarCommand());
        dispatch(resetAllStatsEvent());
        return sequentialPromiseAll([
            requestValuesSaga(dispatch),
            requestTotalSaga(dispatch),
            requestLookupTotalsBySourcesSaga(dispatch)
        ]);
    };

    return Promise.all([
        updateValuesSaga(dispatch)(setErrorFlag),
        updateDependenciesSaga(dispatch)(),
        deleteValuesSaga(dispatch)(setErrorFlag)
    ])
        .then(R.unless(isError, onSuccess))
        .catch(logError('update values data error'));
};
export const suggestUpdateValuesSaga =
    (dispatch: (...args: any[]) => any) => (setErrorFlag: SetErrorFlag, DCRID: string) =>
        R.pipe(
            updateLookupValuesCommand,
            dispatch,
            then(
                R.pipe(
                    R.tap(R.compose(dispatch, removeAutoGeneratedLookupValuesCommand)),
                    R.tap((responseData) => {
                        const updatedValues = R.pipe(
                            R.map(({changes}) => Object.keys(changes).map(parseLookupUri)),
                            R.flatten
                        )(responseData);
                        // TODO Delete the next "if" after migration to etalon
                        if (configStream$.getValue()[CONFIG.SUPORT_UNMAPPED_VALUES_IN_DCR])
                            suggestDeleteMappedUnmappedSaga(dispatch)(updatedValues, DCRID);
                    })
                )
            )
        )(setErrorFlag, DCRID);
export const suggestValuesDataSaga = (dispatch: (...args: any[]) => any) => () => {
    let errorFlag = false;

    const isError = () => errorFlag;

    const setErrorFlag: SetErrorFlag = (data) => {
        errorFlag = true;
        return data;
    };

    const onSuccess = () => {
        dispatch(showDisclaimerSnackbarCommand());
        dispatch(resetAllStatsEvent());
        dispatch(resetTasksPaginationEvent());
        return sequentialPromiseAll([
            requestValuesSaga(dispatch),
            requestTotalSaga(dispatch),
            requestLookupTotalsBySourcesSaga(dispatch),
            requestWorkflowTasksAndDCRsSaga(dispatch)
        ]).then(() => {
            return pipe(unmappedRemovedItemsClearEvent, dispatch, unmappedSelectedItemsClearEvent, dispatch)();
        });
    };

    return createDCRSaga(dispatch)()
        .then((DCR) => {
            const DCRID = R.propOr(null, 'id', DCR);
            const suggestSagas = [
                () => suggestUpdateValuesSaga(dispatch)(setErrorFlag, DCRID),
                () => suggestUpdateDependenciesSaga(dispatch)(DCRID),
                () => suggestDeleteValuesSaga(dispatch)(setErrorFlag, DCRID)
            ];
            return sequentialPromiseAll(suggestSagas);
        })
        .then(R.flatten)
        .then(R.unless(isError, R.pipe(startProcessSaga(dispatch), then(onSuccess))))
        .catch(logError('Suggest values data error'));
};
