import styles from './style.less';
import {MaybeMonad} from '../../core/monet';
import {POSTFIX_SERIALIZE, keywords} from './parser';
import {ReactElement} from 'react';
import {safeProp} from '../../core/lenses';
const safeHasWords = safeProp('hasWords');

const drawIf = (safeValue: MaybeMonad<string | ReactElement>, label: string) => {
    const safeDrawValue: MaybeMonad<ReactElement | string> = safeValue.map((value) => (
        <span className={styles.queryElem} key={label}>
            <span className={styles.queryElem__keyword}>{label}</span>
            {value}
        </span>
    ));

    return safeDrawValue.orSome('');
};

type Props<SQ> = {
    safeQuery: MaybeMonad<SQ>;
    onClick: () => void;
};

function QueryLine<SQ>({safeQuery, onClick}: Props<SQ>) {
    return (
        <div className={`${styles.queryLine} queryLine`} onClick={onClick}>
            {drawIf(safeHasWords.get(safeQuery), '')}
            {keywords.map(({name, getString}) => drawIf(getString(safeQuery), name + POSTFIX_SERIALIZE))}
        </div>
    );
}

export default QueryLine;
