/**
 * Created by ndyumin on 04.05.2017.
 */
import * as R from 'ramda';
import {fromString} from '../core/maybe';
import {getSourceByUri} from './configuration';
export const getSourceLabel = (configuration, abbreviation) =>
    R.pipe(getSourceByUri, R.propOr(abbreviation, 'label'))(configuration, sourceAbbrToUri(abbreviation));
export const sourceUriToAbbr = (source) =>
    fromString(source)
        .map((s) => s.substring(s.lastIndexOf('/') + 1))
        .orSome('');
export const sourceAbbrToUri = (abbr) => `rdm/sources/${abbr}`;
