import * as R from 'ramda';
import {ColumnItem} from '../../rdm-sdk/grid.types';
import {FIXED_COLUMN_COUNT, REGULAR_COLUMN_WIDTH} from './MappingGrid';
import {MappingProps, State} from './MappingGrid';
import {
    addHiddenColumn,
    mergeHiddenByIndex,
    removeHiddenColumn,
    updateHiddenColumns,
    updateHiddenColumnsOnDnD
} from '../../rdm-sdk/grid';
import {changeOrder, leftJoin} from '../../core/util';
export const mappingSourceAddUpdater = (source: ColumnItem, index: number) =>
    R.evolve({
        sources: R.insert(index, source),
        extraSources: R.reject(R.eqProps('abbreviation', source)),
        hiddenColumns: R.unless(() => index === -1, updateHiddenColumns(1, index))
    });
export const mappingColumnOrderUpdater = (sourceIndex: number, targetIndex: number) =>
    R.evolve({
        sources: changeOrder(sourceIndex - 1, targetIndex - 1),
        hiddenColumns: updateHiddenColumnsOnDnD(sourceIndex, targetIndex)
    });
export const mappingSourcesResetter = (
    state: Pick<State, 'hiddenColumns' | 'sources'>,
    props: Pick<MappingProps, 'configuration' | 'lookupValues' | 'availableSources'>
) => {
    const configSources = props.configuration.sources.map(R.assoc('columnWidth', REGULAR_COLUMN_WIDTH));
    const hiddenAbbreviations = R.pipe(R.pluck('items'), R.flatten, R.pluck('abbreviation'))(state.hiddenColumns);
    const abbreviationsOld = R.pluck('abbreviation', state.sources);
    const abbreviationsCurrent = leftJoin(
        props.lookupValues.reduce((sources, value) => leftJoin(sources, Object.keys(value.sources)), []),
        props.availableSources || []
    );
    let hiddenColumns = state.hiddenColumns;
    let removedCount = 0;
    const newAbbreviations = leftJoin(
        abbreviationsOld.filter((abbreviation, i) => {
            const propsHasSource = abbreviationsCurrent.includes(abbreviation);

            if (!propsHasSource) {
                hiddenColumns = updateHiddenColumns(-1, i + FIXED_COLUMN_COUNT - removedCount, hiddenColumns);
                removedCount++;
            }

            return propsHasSource;
        }),
        R.difference(abbreviationsCurrent, hiddenAbbreviations)
    );
    const newHiddenAbbreviations = R.intersection(hiddenAbbreviations, abbreviationsCurrent);
    hiddenColumns = R.pipe(
        mergeHiddenByIndex,
        R.reduce((acc, hidden) => {
            const newItems = hidden.items.filter(({abbreviation}) => newHiddenAbbreviations.includes(abbreviation));
            return newItems.length
                ? acc.concat([
                      {
                          items: newItems,
                          index: hidden.index
                      }
                  ])
                : acc;
        }, [])
    )(hiddenColumns);
    const sources = newAbbreviations.map((abbreviation) =>
        R.find(R.propEq('abbreviation', abbreviation), configSources)
    );
    const extraSources = R.reject(
        ({abbreviation}) => newAbbreviations.includes(abbreviation) || newHiddenAbbreviations.includes(abbreviation),
        configSources
    );
    return {
        sources,
        extraSources,
        hiddenColumns
    };
};
export const mappingSourcesUpdater = (
    state: Pick<State, 'hiddenColumns' | 'sources'>,
    props: Pick<MappingProps, 'configuration' | 'availableSources' | 'lookupValues'>
) => {
    const configSources = props.configuration.sources.map(R.assoc('columnWidth', REGULAR_COLUMN_WIDTH));
    const hiddenAbbreviations = R.pipe(R.pluck('items'), R.flatten, R.pluck('abbreviation'))(state.hiddenColumns);
    const abbreviationsOld = R.pluck('abbreviation', state.sources);
    const abbreviationsCurrent = leftJoin(
        props.lookupValues.reduce((sources, value) => leftJoin(sources, Object.keys(value.sources)), []),
        props.availableSources || []
    );
    const abbreviationsDiff = R.reject(
        R.includes(R.__, [...abbreviationsOld, ...hiddenAbbreviations]),
        abbreviationsCurrent
    );
    const sourcesDiff = R.filter((source) => abbreviationsDiff.includes(source.abbreviation), configSources);
    const newAbbreviations = abbreviationsOld.concat(abbreviationsDiff);
    const extraSources = R.reject(
        ({abbreviation}) => newAbbreviations.includes(abbreviation) || hiddenAbbreviations.includes(abbreviation),
        configSources
    );
    return {
        sources: [...state.sources, ...sourcesDiff],
        extraSources
    };
};
export const mappingColumnHideUpdater = (label: string, columnIndex: number) => (state: State) => {
    const source = R.find(R.propEq('label', label), state.sources);
    return {
        hiddenColumns: addHiddenColumn(source, columnIndex, state.hiddenColumns),
        sources: R.reject(R.propEq('label', label), state.sources)
    };
};
export const mappingColumnShowUpdater = (columnIndex: number) => (state: State) => {
    const hiddenSources = R.pipe(R.find(R.propEq('index', columnIndex)), R.propOr([], 'items'))(state.hiddenColumns);
    const sources = [
        ...state.sources.slice(0, columnIndex - FIXED_COLUMN_COUNT),
        ...hiddenSources,
        ...state.sources.slice(columnIndex - FIXED_COLUMN_COUNT)
    ];
    return {
        sources,
        hiddenColumns: removeHiddenColumn(columnIndex, state.hiddenColumns)
    };
};
export const mappingColumnResizeUpdater = (columnIndex: number, deltaX: number) =>
    R.evolve({
        sources: R.adjust(
            columnIndex - FIXED_COLUMN_COUNT,
            R.evolve({
                columnWidth: (width) => Math.max(width + deltaX, 64)
            })
        )
    });
