import * as R from 'ramda';
import {Generator} from '../../rdm-sdk/generators.types';
import {INTEGER_REG_EX} from '../../constants/common';
import {Validation} from 'monet';
import {isString, notEmpty} from '../../core/maybe';
export const validateRangeStart = (type, rangeStart) =>
    type !== 'SEQUENTIAL' || INTEGER_REG_EX.test(rangeStart)
        ? Validation.success(rangeStart)
        : Validation.fail(['Incorrect start seed']);
export const validateEmptyName = (name) =>
    isString(name) && notEmpty(name) ? Validation.success(name) : Validation.fail(['Name is empty']);
export const validateDuplicateName = (name, generators) =>
    !generators.some(R.propEq('name', name)) ? Validation.success(name) : Validation.fail(['Name is duplicate']);
export const validateGenerator = ({name, type, rangeStart}: Generator, generators: Generator[]) =>
    validateEmptyName(name).ap(validateDuplicateName(name, generators).ap(validateRangeStart(type, rangeStart).acc()));
