import * as React from 'react';
import RejectButton from '../RejectButton/RejectButton';
import clsx from 'clsx';
import styles from './styles.less';
import {StateEvent} from '../../../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {getRejectedChanges} from '../../../../redux/selectors/workflowSelectors';
import {pipe} from 'ramda';
import {rejectChangeWFLogger} from '../../logging';
import {useState} from 'react';
import {workflowAddRejectedEvent, workflowDeleteRejectedEvent} from '../../../../redux/actions/workflow';
export type RejectWrapperProps = {
    id: string;
    isRejected: boolean;
    dispatch: (e: StateEvent) => void;
    children: React.ReactNode;
};
export function RejectWrapper(props: RejectWrapperProps) {
    const {id, isRejected, dispatch, children} = props;
    const [mouseEntered, setMouseEntered] = useState(false);

    const onMouseEnter = () => setMouseEntered(true);

    const onMouseLeave = () => setMouseEntered(false);

    const toggleReject = () => {
        pipe(isRejected ? workflowDeleteRejectedEvent : workflowAddRejectedEvent, dispatch)(id);
        dispatch(rejectChangeWFLogger(!isRejected) as StateEvent);
    };

    return (
        <div
            className={clsx(styles['reject-container'], mouseEntered && styles['reject-container__active'])}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <RejectButton active={mouseEntered} onClick={toggleReject} rejected={isRejected} />
            <div className={clsx(isRejected && styles['reject-container-rejected'])}>{children}</div>
        </div>
    );
}

const mapStateToProps = (state, {id}) => ({
    isRejected: getRejectedChanges(state).includes(id)
});

export default connect(mapStateToProps)(RejectWrapper);
