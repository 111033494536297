import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import ReferencePicker from './ReferencePicker';
import Typography from '@mui/material/Typography';
import styles from './styles.less';
import {Component} from 'react';
import {DependencyItem} from '../../rdm-sdk/app.types';
import {Maybe} from 'monet';
import {pipe} from '../../core/func';
type Props = {
    title: string;
    pickerTitle: string;
    elementName: string;
    tags: DependencyItem[];
    onTagsUpdated: (tags: DependencyItem[]) => void;
    currentCode?: string | null;
    exceptions: DependencyItem[];
    readOnly: boolean;
};
type State = {
    dialogOpen: boolean;
};
export default class EditableReferenceTagList extends Component<Props, State> {
    state = {
        dialogOpen: false
    };
    openDialog = () =>
        this.setState({
            dialogOpen: true
        });
    closeDialog = () =>
        this.setState({
            dialogOpen: false
        });

    render() {
        const {title, pickerTitle, elementName, tags, onTagsUpdated, currentCode, exceptions, readOnly} = this.props;
        const {dialogOpen} = this.state;

        const remove = (tag) => () => onTagsUpdated(tags.filter((t) => tag !== t));

        const chips = tags.map((tag) => (
            <Chip
                classes={{
                    root: styles['reference-dialog__dependence-chip'],
                    label: styles['reference-dialog__dependence-chip-label'],
                    deleteIcon: styles['reference-dialog__dependence-chip-deleteIcon']
                }}
                key={`${tag.type}/${tag.code}`}
                onDelete={readOnly ? undefined : remove(tag)}
                label={`${Maybe.fromNull(tag.typeLabel).orSome(tag.type)}: ${Maybe.fromNull(tag.canonical).orSome(
                    tag.code
                )}`}
            />
        ));
        return (
            <div className={styles['reference-dialog__dependence']}>
                <div className={styles['reference-dialog__row']}>
                    <Typography variant="subtitle1" className={styles['reference-dialog__dependence-title']}>
                        {title}
                    </Typography>
                    {!readOnly && (
                        <Button
                            color="primary"
                            className={styles['reference-dialog__dependence-add']}
                            onClick={this.openDialog}
                        >
                            <Icon>add</Icon> {elementName}
                        </Button>
                    )}
                </div>

                <div className={styles['reference-dialog__dependence-chips']}>{chips}</div>

                {!readOnly && (
                    <ReferencePicker
                        title={pickerTitle}
                        currentCode={currentCode || ''}
                        onClose={this.closeDialog}
                        tags={tags}
                        onValues={pipe(onTagsUpdated, this.closeDialog)}
                        open={dialogOpen}
                        exceptions={exceptions}
                    />
                )}
            </div>
        );
    }
}
