/**
 * Created by ndyumin on 11.04.2017.
 */
import * as R from 'ramda';
import moment from 'moment';
import {ACTIVITY_TYPE, OBJECT_TYPE} from '../components/ActivityLog/constants';
import {ActivityLogFilters, ObjectType} from '../components/ActivityLog/activityLog.types';
import {Configuration} from './configuration.types';
import {Filters} from './app.types';
import {Maybe} from 'monet';
import {SearchQuery, ValuesSearchQuery} from './filters.types';
import {Source} from './sources.types';
import {curry, map} from '../core/func';
import {escapeDoubleQuotes, trim} from '../core/util';
import {fromNotEmpty, fromNull} from '../core/maybe';
import {getLookupValueUri} from './lookups';
import {prop, safePath, safeProp} from '../core/lenses';
import {sourceUriToAbbr} from './sources';
export const wrapWithBraces = (str: string) => `(${str})`;
export class FilterBuilder {
    clauses: string[] = [];

    addClause(clause: string) {
        if (clause) {
            this.clauses = this.clauses.concat(wrapWithBraces(clause));
        }

        return this;
    }

    build() {
        return this.clauses.join(' and ');
    }
}
const safeHasWordsL = safeProp('hasWords');
const safeTypeL = safeProp('type');
const safeValueL = safeProp('value');
const safeCodeL = safeProp('code');
const safeNegateL = safeProp('negate');
const safeStartDateL = safeProp('startDate');
const safeEndDateL = safeProp('endDate');
const safeCanonicalCodeL = safeProp('canonicalCode');
const safeCanonicalCodesL = safeProp('canonicalCodes');
const safeCanonicalValueL = safeProp('canonicalValue');
const safeValueValueL = safeValueL.compose(safeValueL);
const safeValueTypeL = safeValueL.compose(safeTypeL);
const safeValueNegateL = safeValueL.compose(safeNegateL);
const safeCodeValueL = safeCodeL.compose(safeValueL);
const safeCodeTypeL = safeCodeL.compose(safeTypeL);
const safeCodeNegateL = safeCodeL.compose(safeNegateL);
const safeCanonicalCodeValueL = safeCanonicalCodeL.compose(safeValueL);
const safeCanonicalCodeTypeL = safeCanonicalCodeL.compose(safeTypeL);
const safeCanonicalCodeNegateL = safeCanonicalCodeL.compose(safeNegateL);
const safeCanonicalValueValueL = safeCanonicalValueL.compose(safeValueL);
const safeCanonicalValueTypeL = safeCanonicalValueL.compose(safeTypeL);
const safeCanonicalValueNegateL = safeCanonicalValueL.compose(safeNegateL);

const hasItems = (xs) => xs.length > 0;

const join = (negate: boolean) => (sources: Source[]) => sources.join(negate ? ' and ' : ' or ');

const zip = curry((values, negateValue, codes, negateCode) =>
    values
        .map((value, i) => wrapWithBraces(`${value} and ${codes[i]}`))
        .join(negateValue && negateCode ? ' and ' : ' or ')
);
const isEqualLength = curry((v, c) => v.length === c.length);

const getFieldClause = (field: string) =>
    curry((negate: boolean, type: string, value: string, sources: string[]): string[] =>
        sources.map(
            (source) =>
                `inSameAttributeValue(${negate ? 'not ' : ''}${type}(sourceMappings.values.${field},"${value}")` +
                ` and equals(sourceMappings.source,"${source}")))`
        )
    );

const getValueClause = getFieldClause('value');
const getCodeClause = getFieldClause('code');

const getCanonicalCodesClause = (codes) => codes.map(getCanonicalCodeClause(false, 'equals')).join(' or ');

const getHasWordsClauseForSources = curry((value, sources) =>
    sources
        .map((source) => `(fullText(code_${source},"${value}") or fullText(value_${source},"${value}"))`)
        .join(' or ')
);

const getHasWordsClause = (hasWords: string) =>
    `fullText(code,"${hasWords}") or fullText(value,"${hasWords}") or fullText(sourceMappings.values.code,"${hasWords}") or fullText(sourceMappings.values.value,"${hasWords}")`;

const getCanonicalCodeClause = curry((negate, type, value) => `(${negate ? 'not ' : ''}${type}(code,"${value}"))`);
const getCanonicalValueClause = curry((negate, type, value) => `(${negate ? 'not ' : ''}${type}(value,"${value}"))`);

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

const rectifySearchToken = R.pipe(trim, escapeDoubleQuotes);

const getTypeClause = (safeType: Maybe<string>): Maybe<string> => safeType.map((type) => `equals(type,"${type}")`);

const getQueryClause = (safeQuery: Maybe<string>): Maybe<string> =>
    safeQuery.map(
        R.pipe(rectifySearchToken, (query) => `(startsWith(value,"${query}")) or (startsWith(code,"${query}"))`)
    );

export const filterFromSearchQuery = (
    safeType: Maybe<string>,
    safeQuery: Maybe<SearchQuery>,
    safeConfigSources: Maybe<Source[]>,
    safeFilters: Maybe<Filters>
): string => {
    const safeHasWords = safeHasWordsL.get(safeQuery).map(rectifySearchToken);
    const safeCodeValue = safeCodeValueL.get(safeQuery).map(rectifySearchToken);
    const safeValueValue = safeValueValueL.get(safeQuery).map(rectifySearchToken);
    const safeCanonicalCodeValue = safeCanonicalCodeValueL.get(safeQuery).map(rectifySearchToken);
    const safeCanonicalValueValue = safeCanonicalValueValueL.get(safeQuery).map(rectifySearchToken);
    const safeCodeType = safeCodeTypeL.get(safeQuery);
    const safeValueType = safeValueTypeL.get(safeQuery);
    const safeCanonicalCodeType = safeCanonicalCodeTypeL.get(safeQuery);
    const safeCanonicalValueType = safeCanonicalValueTypeL.get(safeQuery);
    const safeValueNegate = safeValueNegateL.get(safeQuery);
    const safeCodeNegate = safeCodeNegateL.get(safeQuery);
    const safeCanonicalCodeNegate = safeCanonicalCodeNegateL.get(safeQuery);
    const safeCanonicalValueNegate = safeCanonicalValueNegateL.get(safeQuery);
    const safeSourcesProp = safeProp('sources').get(safeQuery).filter(hasItems);
    const safeSources = safeSourcesProp.orElse(safeConfigSources.map(map(prop('uri').get))).map(map(sourceUriToAbbr));
    const safeCanonicalCodes = safeCanonicalCodesL.get(safeQuery);
    const canonicalCodesClause = safeCanonicalCodes.map(getCanonicalCodesClause);
    const valuesClause = safeSources.ap(safeValueValue.ap(safeValueType.ap(safeValueNegate.map(getValueClause))));
    const codesClause = safeSources.ap(safeCodeValue.ap(safeCodeType.ap(safeCodeNegate.map(getCodeClause))));
    const hasWordsClause = safeSourcesProp
        .map(map(sourceUriToAbbr))
        .ap(safeHasWords.map(getHasWordsClauseForSources))
        .orElse(safeHasWords.map(getHasWordsClause));
    const canonicalCodeClause = safeCanonicalCodeValue.ap(
        safeCanonicalCodeType.ap(safeCanonicalCodeNegate.map(getCanonicalCodeClause))
    );
    const canonicalValueClause = safeCanonicalValueValue.ap(
        safeCanonicalValueType.ap(safeCanonicalValueNegate.map(getCanonicalValueClause))
    );
    const missingMappingClause: Maybe<Source[]> = safeQuery
        .filter(prop('hasMissingValues').get)
        .flatMap(() => safeSources.map(map((source) => `(missing(code_${source}))`)));
    const startDateClause = safeStartDateL
        .get(safeQuery)
        .map((startDate) => `gte(startDate,"${formatDate(startDate)}")`);
    const endDateClause = safeEndDateL.get(safeQuery).map((endDate) => `lte(endDate,"${formatDate(endDate)}")`);
    const typeClause = getTypeClause(safeType);
    const builder = new FilterBuilder()
        .addClause(canonicalCodeClause.orSome(''))
        .addClause(canonicalValueClause.orSome(''))
        .addClause(hasWordsClause.orSome(''))
        .addClause(typeClause.orSome(''))
        .addClause(startDateClause.orSome(''))
        .addClause(endDateClause.orSome(''))
        .addClause(canonicalCodesClause.orSome(''));
    const filters = safeFilters.orSome({});

    for (const f in filters) {
        builder.addClause(`equals(${f},${filters[f]})`);
    }

    codesClause.ap(valuesClause.map(isEqualLength)).orSome(false)
        ? builder.addClause(safeCodeNegate.ap(codesClause.ap(safeValueNegate.ap(valuesClause.map(zip)))).orSome(''))
        : builder
              .addClause(valuesClause.ap(safeValueNegate.map(join)).map(wrapWithBraces).orSome(''))
              .addClause(codesClause.ap(safeCodeNegate.map(join)).map(wrapWithBraces).orSome(''));

    const safeClause: Maybe<string> = missingMappingClause.map(join(false)).map(wrapWithBraces);
    builder.addClause(safeClause.orSome(''));
    return builder.build();
};
export const valuesFilterFromState = (state: {
    searchQuery: ValuesSearchQuery;
    filters: Filters;
    currentType: string;
    configuration: Configuration;
}): string => {
    const safeState = Maybe.fromNull(state);
    const safeQuery = safeProp('searchQuery').get(safeState);
    const safeConfigSources = safePath('configuration', 'sources').get(safeState);
    const safeFilters = safeProp('filters').get(safeState);
    const safeCurrentType = safeProp('currentType').get(safeState);
    return filterFromSearchQuery(safeCurrentType, safeQuery, safeConfigSources, safeFilters);
};
export const typeFilter = (safeType: Maybe<string>) => {
    const typeClause = getTypeClause(safeType);
    return new FilterBuilder().addClause(typeClause.orSome('')).build();
};
export const typeValueFilter = (safeType: Maybe<string>, safeValue: Maybe<string>) => {
    const typeClause = getTypeClause(safeType);
    const valueClause: Maybe<string> = safeValue.map(
        R.pipe(rectifySearchToken, (value) => `startsWith(value,"${value}")`)
    );
    return new FilterBuilder().addClause(typeClause.orSome('')).addClause(valueClause.orSome('')).build();
};
export const typeCodeFilter = (safeType: Maybe<string>, safeCode: Maybe<string>) => {
    const typeClause = getTypeClause(safeType);
    const codeClause: Maybe<string> = safeCode.map(R.pipe(rectifySearchToken, (value) => `equals(code,"${value}")`));
    return new FilterBuilder().addClause(typeClause.orSome('')).addClause(codeClause.orSome('')).build();
};
export const filterTotalsFromUnmappedSearchQuery = (safeType: Maybe<string>, safeQuery: Maybe<string>) => {
    const typeClause = getTypeClause(safeType);
    const queryClause = getQueryClause(safeQuery);
    return new FilterBuilder().addClause(typeClause.orSome('')).addClause(queryClause.orSome('')).build();
};
export const filterFromUnmappedSearchQuery = (
    safeSource: Maybe<string>,
    safeType: Maybe<string>,
    safeQuery: Maybe<string>
) => {
    const sourceClause: Maybe<string> = safeSource.map((source) => `equals(source,"${source}")`);
    const typeClause = getTypeClause(safeType);
    const queryClause = getQueryClause(safeQuery);
    return new FilterBuilder()
        .addClause(sourceClause.orSome(''))
        .addClause(typeClause.orSome(''))
        .addClause(queryClause.orSome(''))
        .build();
};

const getObjectUriPrefix = (objectType, currentType, tenantName) =>
    ({
        [OBJECT_TYPE.LOOKUP_TYPE]: 'rdm/lookupTypes',
        [OBJECT_TYPE.SOURCE]: 'rdm/sources',
        [OBJECT_TYPE.LOOKUP_VALUE]: `${tenantName}/${currentType}/`
    })[objectType];

const getFullActivityType = R.curry((objectType, activityType) => {
    const prefix = {
        [OBJECT_TYPE.LOOKUP_TYPE]: 'LOOKUP_TYPE_',
        [OBJECT_TYPE.LOOKUP_VALUE]: 'LOOKUP_',
        [OBJECT_TYPE.SOURCE]: 'SOURCE_'
    }[objectType];
    const type = {
        [ACTIVITY_TYPE.ADD]: 'ADDED',
        [ACTIVITY_TYPE.CHANGE]: 'CHANGED',
        [ACTIVITY_TYPE.DELETE]: 'DELETED'
    }[activityType];
    return prefix + type;
});
const toEqualsClausesJoinedWithOr = R.curry((property, items) => {
    return fromNotEmpty(items)
        .map(R.map((value) => `equals(${property}, "${value}")`))
        .map(R.join(' or '))
        .orSome('');
});
export const buildActivityLogFilter = (
    objectType: ObjectType,
    currentType: string | null,
    filters: ActivityLogFilters,
    tenantName = ''
) => {
    const {activityTypes, users, lookupTypes, sources, lookupValues, startDate, endDate} = R.evolve(
        {
            activityTypes: R.map(R.pipe(R.prop('value'), getFullActivityType(objectType))),
            users: R.pluck('value'),
            lookupTypes: R.pluck('uri'),
            sources: R.pluck('uri'),
            lookupValues: R.map(R.pipe(R.prop('value'), getLookupValueUri(tenantName, currentType)))
        },
        filters
    );
    const sourceTags = filters.sources.map(({value}) => `source:${value}`);
    const objectUriPrefix = getObjectUriPrefix(objectType, currentType, tenantName);

    const safeStartDateClause: Maybe<string> = fromNull(startDate).map((date) => `gte(timestamp, ${Number(date)})`);
    const startDateClause: string = safeStartDateClause.orSome('');

    const safeEndDateClause: Maybe<string> = fromNull(endDate).map((date) => `lte(timestamp, ${Number(date)})`);
    const endDateClause = safeEndDateClause.orSome('');

    const activityTypesClause = toEqualsClausesJoinedWithOr('items.eventType', activityTypes);
    const usersClause = toEqualsClausesJoinedWithOr('user', users);
    const objectTypeClause = `startsWith(items.objectUri, "${objectUriPrefix}")`;
    const lookupTypesClause = toEqualsClausesJoinedWithOr('items.objectUri', lookupTypes);
    const lookupValuesClause = toEqualsClausesJoinedWithOr('items.objectUri', lookupValues);
    const sourcesClause = toEqualsClausesJoinedWithOr('items.objectUri', sources);
    const sourceTagsClause = toEqualsClausesJoinedWithOr('items.tags', sourceTags);
    const filter = new FilterBuilder()
        .addClause(startDateClause)
        .addClause(endDateClause)
        .addClause(activityTypesClause)
        .addClause(usersClause);

    switch (objectType) {
        case OBJECT_TYPE.LOOKUP_TYPE:
            return filter.addClause(lookupTypesClause || objectTypeClause).build();

        case OBJECT_TYPE.SOURCE:
            return filter.addClause(sourcesClause || objectTypeClause).build();

        case OBJECT_TYPE.LOOKUP_VALUE:
            return filter
                .addClause(lookupValuesClause || objectTypeClause)
                .addClause(sourceTagsClause)
                .build();

        default:
            return '';
    }
};
