import * as R from 'ramda';
import queryString from 'query-string';
import {
    CollaborationUpdate,
    Comment,
    CommentsCount,
    Discussion,
    NewReply,
    Reply
} from '../../rdm-sdk/collaboration.types';
import {
    RESOLVE_REPLY,
    getObjectIds,
    getRelatedObjectUri,
    getReopenReply,
    toCommentsCountDict
} from '../../rdm-sdk/collaboration';
import {SortDirection} from '../../rdm-sdk/app.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {commandCreator} from '../middlewares/command-middleware';
import {json, requestWithBlockingSpinner, requestWithNonBlockingSpinner} from '../../network';
import {then} from '../../core/func';
const getComment = R.curry((tenant, commentId, isBlocking) => {
    const qs = queryString.stringify({
        includeDeleted: false,
        sort: 'createdTime',
        order: SortDirection.DESC
    });
    const request = isBlocking ? requestWithBlockingSpinner : requestWithNonBlockingSpinner;
    return request(`/collaboration/${tenant}/comments/${commentId}?${qs}`).then(json);
});

/* EVENTS */
export const setCommentsCountEvent = R.curry((key: string, commentsCount: CommentsCount) => ({
    type: 'setCommentsCount',
    key,
    commentsCount
}));
export const updateCommentsCountEvent = R.curry((key: string, commentsCount: CommentsCount) => ({
    type: 'updateCommentsCount',
    key,
    commentsCount
}));
export const appendCommentCountEvent = R.curry((key: string, comment: Comment) => ({
    type: 'appendCommentCount',
    key,
    comment
}));
export const deleteCommentCountEvent = R.curry((key: string, comment: Comment) => ({
    type: 'deleteCommentCount',
    key,
    comment
}));
export const setCommentsEvent = R.curry((key: string, comments: Comment[]) => ({
    type: 'setComments',
    key,
    comments
}));
export const setCommentEvent = R.curry((key: string, commentId: string, comment: Comment) => ({
    type: 'setComment',
    key,
    commentId,
    comment
}));
export const appendCommentRepliesEvent = R.curry((key: string, commentId: string, replies: Reply[]) => ({
    type: 'appendCommentReplies',
    key,
    commentId,
    replies
}));
export const deleteCommentReplyEvent = R.curry((key: string, commentId: string, replyId: string) => ({
    type: 'deleteCommentReply',
    key,
    commentId,
    replyId
}));
export const updateCommentEvent = R.curry((key: string, commentId: string, update: CollaborationUpdate) => ({
    type: 'updateComment',
    key,
    commentId,
    update
}));
export const updateCommentReplyEvent = R.curry((key: string, commentId: string, reply: Reply) => ({
    type: 'updateCommentReply',
    key,
    commentId,
    reply
}));
export const updateDiscussionEvent = R.curry((key: string, discussion: Discussion) => ({
    type: 'updateDiscussion',
    key,
    discussion
}));
export const resetDiscussionEvent = (key: string) => ({
    type: 'resetDiscussion',
    key
});
export const deleteDiscussionCommentEvent = R.curry((key: string, commentId: string) => ({
    type: 'deleteDiscussionComment',
    key,
    commentId
}));
export const prependDiscussionCommentEvent = R.curry((key: string, commentId: string) => ({
    type: 'prependDiscussionComment',
    key,
    commentId
}));
export const upliftDiscussionCommentEvent = R.curry((key: string, commentId: string) => ({
    type: 'upliftDiscussionComment',
    key,
    commentId
}));
export const resolveCommentEvent = R.curry((key: string, commentId: string) =>
    updateCommentEvent(key, commentId, {
        status: 'resolved'
    })
);
export const reopenCommentEvent = R.curry((key: string, commentId: string) =>
    updateCommentEvent(key, commentId, {
        status: 'open'
    })
);

/* COMMANDS */
export const getCommentsCountCommand = (key: string) =>
    commandCreator(({state}, lookupValues) => {
        const {
            tenant,
            currentType,
            policies: {collaborationEdit}
        } = state;

        if (!currentType || !lookupValues.length) {
            return Promise.resolve([]);
        }

        const objectIds = getObjectIds(key, currentType, lookupValues);
        const qs = queryString.stringify({
            includeDeleted: false
        });
        return collaborationEdit
            ? requestWithNonBlockingSpinner(`/collaboration/${tenant}/comments/count?${qs}`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(objectIds)
              })
                  .then(json)
                  .catch((error) => {
                      console.error('getCommentsCountCommand', key, error);
                      return [];
                  })
            : Promise.resolve([]);
    });
export const getCommentCommand = commandCreator(({state}, commentId) => getComment(state.tenant, commentId, false));
export const getCommentByObjectIdCommand = commandCreator(({state}, objectId) => {
    const {tenant} = state;
    const qs = queryString.stringify({
        includeDeleted: false
    });
    return requestWithBlockingSpinner(`/collaboration/${tenant}/comments/count?${qs}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify([objectId])
    })
        .then(json)
        .then(R.pathOr(null, [0, 'comments', 0, 'commentId']))
        .then((commentId) => (commentId ? getComment(tenant, commentId, true) : null));
});
export const createCommentCommand = commandCreator(({state}, comment) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/collaboration/${tenant}/comments`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify([comment])
    }).then(json);
});
export const createReplyCommand = commandCreator(({state}, commentId, reply) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/collaboration/${tenant}/comments/${commentId}/replies`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify([reply])
    }).then(json);
});
export const deleteCommentCommand = commandCreator(({state}, commentId) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/collaboration/${tenant}/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
});
export const deleteReplyCommand = commandCreator(({state}, commentId, replyId) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/collaboration/${tenant}/comments/${commentId}/replies/${replyId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
});
export const updateCommentCommand = commandCreator(({state}, commentId, update) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/collaboration/${tenant}/comments/${commentId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(update)
    }).then(json);
});
export const updateReplyCommand = commandCreator(({state}, commentId, replyId, update) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/collaboration/${tenant}/comments/${commentId}/replies/${replyId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(update)
    }).then(json);
});
export const loadNextDiscussionCommand = (key: string) =>
    commandCreator(({state}) => {
        const {tenant, currentType, collaboration} = state;
        const discussion = collaboration[key].discussion;

        if (discussion.nextPageToken === null) {
            return Promise.resolve({
                items: [],
                nextPageToken: null
            });
        }

        const qs = queryString.stringify({
            maxResults: 10,
            relatedObjectUri: getRelatedObjectUri(key, currentType),
            sort: 'modifiedTime',
            order: SortDirection.DESC,
            pageToken: discussion.nextPageToken
        });
        return requestWithBlockingSpinner(`/collaboration/${tenant}/comments?${qs}`).then(json);
    });

/* SAGAS */
export const getCommentsCountSaga = (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    R.pipe(
        getCommentsCountCommand(key),
        dispatch,
        then(R.pipe(toCommentsCountDict, updateCommentsCountEvent(key), dispatch))
    );
export const getCommentSaga = (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    R.pipe(getCommentCommand, dispatch, then(R.pipe(R.of, setCommentsEvent(key), dispatch)));
export const getCommentByObjectIdSaga = (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    R.pipe(
        getCommentByObjectIdCommand,
        dispatch,
        then(
            R.when(
                Boolean,
                R.pipe(
                    R.tap((comment) => dispatch(setCommentEvent(key, comment.commentId, comment))),
                    R.tap((comment) => dispatch(appendCommentCountEvent(key, comment)))
                )
            )
        )
    );
export const createCommentSaga = (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    R.pipe(
        createCommentCommand,
        dispatch,
        then(R.pipe(setCommentsEvent(key), dispatch)),
        then(R.pipe(R.prop('comments'), R.nth(0), appendCommentCountEvent(key), dispatch)),
        then(R.pipe(R.path(['comment', 'commentId']), prependDiscussionCommentEvent(key), dispatch))
    );
export const createReplySaga =
    (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    (commentId: string, newReply: NewReply): Promise<any> =>
        dispatch(createReplyCommand(commentId, newReply) as StateEvent).then((replies) => {
            dispatch(appendCommentRepliesEvent(key, commentId, replies));
            dispatch(upliftDiscussionCommentEvent(key, commentId));
            return replies;
        });
export const resolveCommentSaga =
    (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    (comment: Comment): Promise<any> =>
        R.pipe(
            createReplySaga(dispatch, key),
            then(R.compose(dispatch, deleteCommentCountEvent(key), R.always(comment))),
            then(R.compose(dispatch, resolveCommentEvent(key), R.always(comment.commentId)))
        )(comment.commentId, RESOLVE_REPLY);
export const reopenCommentSaga =
    (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    (comment: Comment, content?: string): Promise<any> =>
        R.pipe(
            createReplySaga(dispatch, key),
            then(R.compose(dispatch, appendCommentCountEvent(key), R.always(comment))),
            then(R.compose(dispatch, reopenCommentEvent(key), R.always(comment.commentId)))
        )(comment.commentId, getReopenReply(content));
export const deleteCommentSaga =
    (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    (comment: Comment): Promise<any> =>
        dispatch(getCommentCommand(comment.commentId) as StateEvent)
            .catch((error) => {
                console.error('Delete comment error', error);
                return null;
            })
            .then((comment) => comment && dispatch(deleteCommentCommand(comment.commentId) as StateEvent))
            .then(R.compose(dispatch, deleteCommentCountEvent(key), R.always(comment)))
            .then(R.compose(dispatch, deleteDiscussionCommentEvent(key), R.always(comment.commentId)));
export const deleteReplySaga =
    (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    (commentId: string, replyId: string): Promise<any> =>
        dispatch(deleteReplyCommand(commentId, replyId) as StateEvent).then(() => {
            dispatch(deleteCommentReplyEvent(key, commentId, replyId));
            dispatch(upliftDiscussionCommentEvent(key, commentId));
        });
export const updateCommentSaga =
    (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    (commentId: string, update: CollaborationUpdate): Promise<any> =>
        dispatch(updateCommentCommand(commentId, update) as StateEvent).then((updateResponse) => {
            dispatch(updateCommentEvent(key, commentId, R.dissoc('replies', updateResponse)));
            dispatch(upliftDiscussionCommentEvent(key, commentId));
        });
export const updateCommentReplySaga =
    (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    (commentId: string, replyId: string, update: CollaborationUpdate): Promise<any> =>
        dispatch(updateReplyCommand(commentId, replyId, update) as StateEvent).then((reply) =>
            dispatch(updateCommentReplyEvent(key, commentId, reply))
        );
export const loadNextDiscussionSaga = (dispatch: (event: StateEvent) => Promise<any>, key: string) =>
    R.pipe(
        loadNextDiscussionCommand(key),
        dispatch,
        then(
            R.pipe(
                R.pick(['items', 'nextPageToken']),
                R.tap(R.compose(dispatch, setCommentsEvent(key), R.prop('items'))),
                R.tap(
                    R.compose(dispatch, updateDiscussionEvent(key), (discussion) => ({
                        commentIds: R.pluck('commentId', discussion.items),
                        nextPageToken: discussion.nextPageToken
                    }))
                )
            )
        )
    );
