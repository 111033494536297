import * as R from 'ramda';
import * as Utils from '../../core/util';
import {ALL_GENERATORS_SEARCH_OPTION} from '../../rdm-sdk/generators';
import {FILTER_OPTIONS} from '../../constants/common';
import {LookupType} from '../../rdm-sdk/types.types';
import {SearchQuery, TypesSearchQuery} from '../../rdm-sdk/filters.types';
import {contains, isEmpty, isNotEmpty, splitToWords} from '../../core/util';
import {fromNull} from '../../core/maybe';
import {map} from '../../core/func';
import {orSome} from '../../core/monet';
import {typeUriToCode} from '../../rdm-sdk/types';
const getWords = R.pipe(fromNull, map(splitToWords), orSome([]));

const negate = (negate) => (value) => (negate ? !value : value);

export const hasWords = (query: TypesSearchQuery, type: LookupType) => {
    const words = getWords(query.hasWords);
    return isEmpty(words) || words.some(contains(type.label)) || words.some(contains(typeUriToCode(type.uri)));
};
export const checkName = (query: TypesSearchQuery, type: LookupType) => {
    const tokens = getWords(query.name.value);
    // eslint-disable-next-line import/namespace
    const checkFunction = Utils[query.name.type];
    const negateFunction = negate(query.name.negate);
    return isEmpty(tokens) || negateFunction(tokens.every(checkFunction(type.label)));
};
export const checkCode = (query: TypesSearchQuery, type: LookupType) => {
    const tokens = getWords(query.code.value);
    // eslint-disable-next-line import/namespace
    const checkFunction = Utils[query.code.type];
    const negateFunction = negate(query.code.negate);
    return isEmpty(tokens) || negateFunction(tokens.every(checkFunction(typeUriToCode(type.uri))));
};
export const checkGenerator = (query: TypesSearchQuery, type: LookupType) => {
    const generator = query.generator;
    const typeGenerator = type.generator;
    return (
        generator == null ||
        (isNotEmpty(typeGenerator) && (generator === ALL_GENERATORS_SEARCH_OPTION || typeGenerator === generator))
    );
};
export const checkStartDate = (query: TypesSearchQuery, type: LookupType) => {
    const startDate = query.startDate;
    return isEmpty(startDate) || new Date(type.startDate || '') >= (startDate as Date);
};
export const checkEndDate = (query: TypesSearchQuery, type: LookupType) => {
    const endDate = query.endDate;
    return isEmpty(endDate) || new Date(type.endDate || '') <= (endDate as Date);
};
export const searchFilter = R.ifElse(
    R.isNil,
    R.T,
    R.allPass([hasWords, checkName, checkCode, checkGenerator, checkStartDate, checkEndDate])
);
export const enabledFilter = R.curry((filter, type) => filter !== FILTER_OPTIONS.ENABLED || type.enabled);
export const hasDCRFilter = R.curry((filter, type) => filter !== FILTER_OPTIONS.HAS_DCR || type.hasDCR);
export const hasUnmappedFilter = R.curry(
    (filter, type) => filter !== FILTER_OPTIONS.HAS_UNMAPPED || type.unmappedCount
);
export const filteringApplied = (searchQuery: SearchQuery | null, filter: string) =>
    filter !== FILTER_OPTIONS.ALL || searchQuery !== null;
export default (searchQuery: SearchQuery | null, filter: string) =>
    R.allPass([searchFilter(searchQuery), enabledFilter(filter), hasDCRFilter(filter), hasUnmappedFilter(filter)]);
