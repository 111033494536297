import * as R from 'ramda';
import CustomMenu from '../CustomMenu/CustomMenu';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {connect} from 'react-redux';

export type ListFilterProps = {
    options: string[];
    selected: string;
    onChange: (value: string) => void;
    logFilterChange: (message: string) => void;
};

const anchorRenderer = (onClick) => (
    <Tooltip title={i18n.text('Filter')}>
        <IconButton onClick={onClick as () => void} size="large">
            <Icon>filter_list</Icon>
        </IconButton>
    </Tooltip>
);

export const ListFilter = ({options, selected, onChange, logFilterChange}: ListFilterProps) => (
    <CustomMenu
        className={styles['list-filter']}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        anchorRenderer={anchorRenderer}
        itemsRenderer={(onClick) =>
            options.map((o, i) => {
                const isSelected = o === selected;
                return (
                    <MenuItem
                        key={i}
                        className={clsx(styles['list-filter__menu-item'], isSelected && styles['selected'])}
                        onClick={R.pipe(R.always(o), R.tap(logFilterChange), onChange, onClick)}
                    >
                        {isSelected && <Icon>done</Icon>} {o}
                    </MenuItem>
                );
            })
        }
    />
);

const mapDispatchToProps = (dispatch, {logger}) => ({
    logFilterChange: R.pipe(logger('filter-change'), dispatch)
});

export default connect(null, mapDispatchToProps)(ListFilter);
