import {ForwardedRef, MutableRefObject, RefObject, useEffect, useRef} from 'react';
export function useCombinedRefs<T>(
    ref1: ForwardedRef<T> | RefObject<T>,
    ref2: ForwardedRef<T> | RefObject<T>
): MutableRefObject<T> {
    const targetRef = useRef<T>();
    useEffect(() => {
        [ref1, ref2].filter(Boolean).forEach((ref) => {
            if (typeof ref === 'function') {
                // @ts-ignore
                ref(targetRef.current);
            } else {
                // @ts-ignore
                ref.current = targetRef.current;
            }
        });
    }, [ref1, ref2]);
    return targetRef as MutableRefObject<T>;
}
