import {TaskType} from '../../components/Workflow/workflow.types';
import {append, assoc, pathOr, pipe, uniq, without} from 'ramda';
import {combineReducers} from 'redux';
import {workflowInitialState} from '../initialState';
// eslint-disable-next-line prettier/prettier
export function rejectReducer(state = workflowInitialState.rejectedChanges, event) { // NOSONAR
    switch (event.type) {
        case 'workflowAddRejected':
            return pipe(append(event.id), uniq)(state);

        case 'workflowDeleteRejected':
            return without(event.id, state);

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function DCRsReducer(state = workflowInitialState.DCRs, event) { // NOSONAR
    switch (event.type) {
        case 'workflowDCRsReceived':
            return event.DCRs;

        case 'resetWorkflows':
        case 'resetWorkflowDCRs':
            return workflowInitialState.DCRs;

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function workflowTasksReducer(state = workflowInitialState.tasks, event) { // NOSONAR
    switch (event.type) {
        case 'workflowTasksReceived':
            return event.tasks;

        case 'workflowTaskUpdate': {
            const task = pathOr({}, ['task', 'data', 0], event);
            const newTasks = (state.data || []).map((currentTask: TaskType) =>
                currentTask.taskID === task.taskID ? task : currentTask
            );
            return assoc('data', newTasks, state);
        }

        case 'workflowTaskReset': {
            const data = (state.data || [])?.filter((task: TaskType) => task.taskID !== event.taskID);
            return {...state, data, size: data.length};
        }

        case 'resetWorkflows':
            return workflowInitialState.tasks;

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function paginationReducer(state = workflowInitialState.pagination, event) { // NOSONAR
    switch (event.type) {
        case 'setTasksPagination':
            return event.pagination;

        case 'resetTasksPagination':
            return workflowInitialState.pagination;

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function assigneesReducer(state = workflowInitialState.assignees, event) { // NOSONAR
    switch (event.type) {
        case 'taskAssigneesReceived':
            return assoc(String(event.taskId), event.assignees, state);

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function tenantRegisteredReducer(state = workflowInitialState.tenantRegistered, event) { // NOSONAR
    switch (event.type) {
        case 'tenantRegistered':
            return event.isRegistered;

        default:
            return state;
    }
}
// eslint-disable-next-line prettier/prettier
export function displayedTaskReducer(state = workflowInitialState.displayedTask, event) { // NOSONAR
    switch (event.type) {
        case 'setDisplayedTask':
            return event.displayedTask;

        default:
            return state;
    }
}
export default combineReducers({
    tasks: workflowTasksReducer,
    DCRs: DCRsReducer,
    rejectedChanges: rejectReducer,
    pagination: paginationReducer,
    assignees: assigneesReducer,
    tenantRegistered: tenantRegisteredReducer,
    displayedTask: displayedTaskReducer
});
