import ActivityChanges from '../../parts/ActivityChanges/ActivityChanges';
import Change from '../../Change/Change';
import i18n from 'ui-i18n';
import {ActivityFieldItem} from '../../activityLog.types';
type Props = {
    items: ActivityFieldItem[];
};

function SourceFields(props: Props) {
    const {items} = props;
    const {label, icon} = items.reduce(
        (fields, item) => ({...fields, [item.name]: item}),
        {} as Record<string, ActivityFieldItem>
    );
    return (
        <ActivityChanges>
            {label && (
                <Change
                    displayName={i18n.text('Name')}
                    newValue={label.newValue}
                    oldValue={label.oldValue}
                    type={label.type}
                />
            )}
            {icon && (
                <Change
                    displayName={i18n.text('Icon')}
                    newValue={icon.newValue}
                    oldValue={icon.oldValue}
                    type={icon.type}
                />
            )}
        </ActivityChanges>
    );
}

export default SourceFields;
