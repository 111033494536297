import * as R from 'ramda';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import i18n from 'ui-i18n';
import recordsSvg from '../../../assets/inline/records.svg';
import styles from './styles.less';
type Props = {
    count?: number | null;
};

const NumberOfRecordsCard = ({count}: Props) => (
    <Card>
        <h3 className={styles['title']}>{i18n.text('Total No. of records')}</h3>
        <CardContent>
            <div
                className={styles['icon']}
                dangerouslySetInnerHTML={{
                    __html: recordsSvg
                }}
            />
            <p className={styles['text']}>{R.defaultTo('-', count)}</p>
        </CardContent>
    </Card>
);

export default NumberOfRecordsCard;
