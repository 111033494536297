import CustomChange from '../../Change/Change';
import Filters from '../../filter/Filters/Filters';
import NestedChange from '../../NestedChange/NestedChange';
import SidePanel from '../../SidePanel/SidePanel';
import ValuesRowContent from '../ValuesRowContent/ValuesRowContent';
import {LookupActivityItem} from '../../activityLog.types';
import {OBJECT_TYPE} from '../../constants';
import {ValuesSidePanelProvider} from './valuesSidePanelStore';
import {linkClickLogger} from '../../logging';
const selects = {
    lookupValues: true,
    sources: true
};
type Props = {
    onClose: () => void;
    onClick: () => void;
};

const FiltersRenderer = ({close}) => {
    return <Filters close={close} selects={selects} objectType={OBJECT_TYPE.LOOKUP_VALUE} />;
};

const rowContentRenderer = ({item, measureRowHeight}) => (
    <ValuesRowContent
        item={item as LookupActivityItem}
        measureRowHeight={measureRowHeight}
        ChangeComponent={CustomChange}
        NestedChangeComponent={NestedChange}
        linkClickLogger={linkClickLogger}
    />
);

function ValuesSidePanel(props: Props) {
    const {onClose, onClick} = props;
    return (
        <ValuesSidePanelProvider>
            <SidePanel
                onClick={onClick}
                objectType={OBJECT_TYPE.LOOKUP_VALUE}
                onClose={onClose}
                rowContentRenderer={rowContentRenderer}
                filtersRenderer={FiltersRenderer}
            />
        </ValuesSidePanelProvider>
    );
}

export default ValuesSidePanel;
