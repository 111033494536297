/* eslint-disable complexity */
import * as R from 'ramda';
import initialState from '../initialState';
import {LookupValue} from '../../rdm-sdk/lookups.types';
import {NEED_MDM_SYNC} from '../constants/actionTypes';
import {checkEdited, checkNewTruly, checkRemoved} from '../../core/marks';
import {getEnabled} from '../../rdm-sdk/utils';
import {getTypeByUri} from '../../rdm-sdk/configuration';
import {mappingsSignificantlyChanged} from '../../rdm-sdk/lookups';
// eslint-disable-next-line prettier/prettier
export default function needMdmSyncReducer(state = initialState, event) { // NOSONAR
    switch (event.type) {
        case 'addType':
        case 'lookupTypesRemove':
        case 'addSource':
        case 'deleteSources':
        case 'lookupValuesRemove':
            return true;

        case 'updateType': {
            const currType = event.typeInfo;
            const prevType = getTypeByUri(state.configuration, currType.uri);
            const isSignificantlyChanged = prevType && currType && getEnabled(currType) !== getEnabled(prevType);
            return isSignificantlyChanged || state.needMdmSync;
        }

        case 'upsertValue': {
            const currValue = event.value;
            const prevValue = (state.lookupValues as LookupValue[]).find(
                (value) => event.code === value.code
            ) as LookupValue;
            const isSignificantlyChanged =
                currValue &&
                prevValue &&
                (getEnabled(currValue) !== getEnabled(prevValue as LookupValue) ||
                    !R.equals(currValue.attributes, prevValue.attributes) ||
                    checkRemoved(currValue) ||
                    mappingsSignificantlyChanged(currValue, prevValue));
            return checkNewTruly(currValue) || isSignificantlyChanged || state.needMdmSync;
        }

        case 'saveDependency':
            return checkEdited(event.value) || state.needMdmSync;

        case NEED_MDM_SYNC.RESET:
            return false;

        default:
            return state.needMdmSync;
    }
}
