import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import styles from './styles.less';
type Props = {
    searchQuery: string;
    placeholder: string;
    onChange: (event: Record<string, any>) => void;
    onClear: () => void;
};

const SearchInput = ({searchQuery, placeholder, onChange, onClear}: Props) => (
    <div className={styles['input-container']}>
        <SearchIcon className={styles['search-input-icon']} />
        <label className={styles['search-input-label']} htmlFor={'search-input'} />
        <Input
            value={searchQuery}
            id={'search-input'}
            disableUnderline
            placeholder={placeholder}
            fullWidth
            onChange={onChange}
        />
        <IconButton
            onClick={onClear}
            className={clsx(styles['close-icon'], Boolean(searchQuery) && styles['close-icon--visible'])}
            size="large"
        >
            <CloseIcon />
        </IconButton>
    </div>
);

export default SearchInput;
