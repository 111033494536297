import * as R from 'ramda';
import DoneIcon from '@mui/icons-material/CheckCircle';
import Highlighter from 'react-highlight-words';
import clsx from 'clsx';
import styles from './styles.less';
import {CONTENT_MARGIN_TOP} from './constants';
import {Component} from 'react';
import {UnmappedItem} from '../../rdm-sdk/unmapped.types';
import {noop, startsWith} from '../../core/util';
type Props = {
    items: UnmappedItem[];
    searchWords: string[];
    onItemClick: (item: UnmappedItem) => void;
    index: number;
    className?: string;
};
export class UnmappedTile extends Component<Props> {
    render() {
        const {items, searchWords, onItemClick = noop, index, className} = this.props;
        const item = items[index];
        const foundSubstring = searchWords.every(R.either(startsWith(item.value), startsWith(item.code)));
        const wordsToHighlight = foundSubstring ? searchWords : [];
        const isRemoved = Boolean(item.removed);
        return (
            <div
                className={clsx(
                    !className && styles['unmapped-table__tile'],
                    className,
                    isRemoved && styles['unmapped-table__tile-done']
                )}
                style={{
                    marginTop: index === 0 ? CONTENT_MARGIN_TOP : 0
                }}
                onClick={() => onItemClick(item)}
            >
                <div className={styles['unmapped-table__code-value-container']}>
                    <div className={styles['unmapped-table__tile-value']}>
                        <Highlighter
                            searchWords={wordsToHighlight}
                            textToHighlight={item.value}
                            highlightClassName={'highlightText'}
                        />
                    </div>
                    <div className={styles['unmapped-table__tile-code']}>
                        <Highlighter
                            searchWords={wordsToHighlight}
                            textToHighlight={item.code}
                            highlightClassName={'highlightText'}
                        />
                    </div>
                </div>
                {isRemoved && <DoneIcon className={styles['unmapped-table__tile-done-icon']} fontSize="small" />}
            </div>
        );
    }
}
export default UnmappedTile;
