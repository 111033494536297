import * as R from 'ramda';
import moment from 'moment';
import {I, curry, map, pipe} from '../../core/func';
import {Maybe} from 'monet';
import {MaybeMonad} from '../../core/monet';
import {SEARCH_KEY_WORDS} from '../../constants/common';
import {SearchQuery, ValuesSearchQuery} from '../../rdm-sdk/filters.types';
import {fromDate, fromNotEmpty} from '../../core/maybe';
import {queryReducer} from './store';
import {safeProp} from '../../core/lenses';
import {sourceAbbrToUri, sourceUriToAbbr} from '../../rdm-sdk/sources';
import {trim} from '../../core/util';
export const POSTFIX_PARSE = ':';
export const POSTFIX_SERIALIZE = POSTFIX_PARSE + ' ';
const SOURCE_DELIMITER = ', ';
const DATE_FORMAT = 'MM/DD/YYYY';

/* Parsing helpers */
const getNextIndex = (str, currentIdx = -1) => {
    const indexes = keywords
        .map((keyword) => str.indexOf(keyword.name + POSTFIX_PARSE))
        .filter((idx) => idx !== -1 && idx > currentIdx)
        .sort((a, b) => a - b);
    return Maybe.fromNull(indexes[0]).orSome(str.length);
};

const getValue = (str, keyword) => {
    const index = str.indexOf(keyword);
    return index !== -1 ? str.slice(index + keyword.length, getNextIndex(str, index)).trim() : null;
};

const updateHasWords = (query, searchString) => {
    const rawData = searchString.slice(0, getNextIndex(searchString)).trim();
    return queryReducer(query, {
        type: 'hasWords',
        value: fromNotEmpty(rawData).orSome(null)
    });
};

const parseDate = (dateStr) => {
    const momentDate = moment(dateStr, DATE_FORMAT);
    return momentDate.isValid() ? momentDate.toDate() : null;
};

/* Serialise helpers */
const safeHasWordsL = safeProp('hasWords');
const safeValueL = safeProp('value');
const safeCodeL = safeProp('code');
const safeNegateL = safeProp('negate');
const safeSourcesL = safeProp('sources');
const safeStartDateL = safeProp('startDate');
const safeEndDateL = safeProp('endDate');
const safeNameL = safeProp('name');
const safeGeneratorL = safeProp('generator');
const safeCanonicalCodeL = safeProp('canonicalCode');
const safeCanonicalValueL = safeProp('canonicalValue');
const safeValueValueL = safeValueL.compose(safeValueL);
const safeValueNegateL = safeValueL.compose(safeNegateL);
const safeCodeValueL = safeCodeL.compose(safeValueL);
const safeCodeNegateL = safeCodeL.compose(safeNegateL);
const safeNameValueL = safeNameL.compose(safeValueL);
const safeNameNegateL = safeNameL.compose(safeNegateL);
const safeCanonicalCodeValueL = safeCanonicalCodeL.compose(safeValueL);
const safeCanonicalCodeNegateL = safeCanonicalCodeL.compose(safeNegateL);
const safeCanonicalValueValueL = safeCanonicalValueL.compose(safeValueL);
const safeCanonicalValueNegateL = safeCanonicalValueL.compose(safeNegateL);

const join = (delimiter) => (arr) => arr.join(delimiter);

const wrapWithCurlyIfNeeded = (value) => (value.split(' ').length > 1 ? `{${value}}` : value);

const trimAndWrap = pipe(trim, wrapWithCurlyIfNeeded);
const addNotSign = curry((negate, value) => (negate ? `-${trimAndWrap(value)}` : trimAndWrap(value)));

const toLocaleDateString = (date) => moment(date).format(DATE_FORMAT);

const toLocaleDateStringSafe = pipe(fromDate, map(toLocaleDateString));

const addLabelIf = (safeValue: MaybeMonad<string>, label: string): string =>
    safeValue.map((value) => label + value).orSome('');

const removeCurly = (str) => {
    const arr = str.split('');
    const openIdx = arr.indexOf('{');
    const closeIdx = arr.lastIndexOf('}');

    if (openIdx < closeIdx) {
        arr.splice(openIdx, 1);
        arr.splice(closeIdx - 1, 1);
    }

    return arr.join('');
};

const isNegate = (value) => (value || '').startsWith('-');

export const keywords = [
    {
        name: SEARCH_KEY_WORDS.SOURCE_SYSTEM,
        updateQuery: <SQ>(query: SQ, rawData?: string | null): SQ => {
            const sources = (Maybe.fromNull(rawData) as Maybe<string>)
                .orSome('')
                .split(SOURCE_DELIMITER)
                .filter(Boolean)
                .map(sourceAbbrToUri);
            return queryReducer(query as ValuesSearchQuery, {
                type: 'sources',
                value: sources
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeSourcesL
                .get(safeQuery)
                .filter((sources) => sources.length)
                .map(map(sourceUriToAbbr))
                .map(join(SOURCE_DELIMITER))
    },
    {
        name: SEARCH_KEY_WORDS.VALUE,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null): SQ => {
            const negate = isNegate(rawData);
            const value = Maybe.fromNull(rawData).map(removeCurly).orSome('');
            query = queryReducer(query, {
                type: 'valueValue',
                value: negate ? value.slice(1) : value
            });
            return queryReducer(query, {
                type: 'valueNegate',
                value: negate
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeValueValueL.get(safeQuery).ap(safeValueNegateL.get(safeQuery).map(addNotSign))
    },
    {
        name: SEARCH_KEY_WORDS.CODE,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null): SQ => {
            const negate = isNegate(rawData);
            const code = Maybe.fromNull(rawData).map(removeCurly).orSome('');
            query = queryReducer(query, {
                type: 'codeValue',
                value: negate ? code.slice(1) : code
            });
            return queryReducer(query, {
                type: 'codeNegate',
                value: negate
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeCodeValueL.get(safeQuery).ap(safeCodeNegateL.get(safeQuery).map(addNotSign))
    },
    {
        name: SEARCH_KEY_WORDS.START_DATE,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null) => {
            return queryReducer(query, {
                type: 'startDate',
                value: parseDate(rawData)
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeStartDateL.get(safeQuery).flatMap(toLocaleDateStringSafe)
    },
    {
        name: SEARCH_KEY_WORDS.END_DATE,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null) => {
            return queryReducer(query, {
                type: 'endDate',
                value: parseDate(rawData)
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeEndDateL.get(safeQuery).flatMap(toLocaleDateStringSafe)
    },
    {
        name: SEARCH_KEY_WORDS.NAME,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null): SQ => {
            const negate = isNegate(rawData);
            const name = Maybe.fromNull(rawData).map(removeCurly).orSome('');
            query = queryReducer(query, {
                type: 'nameValue',
                value: negate ? name.slice(1) : name
            });
            return queryReducer(query, {
                type: 'nameNegate',
                value: negate
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeNameValueL.get(safeQuery).ap(safeNameNegateL.get(safeQuery).map(addNotSign))
    },
    {
        name: SEARCH_KEY_WORDS.GENERATOR,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null) => {
            return queryReducer(query, {
                type: 'generator',
                value: rawData
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> => safeGeneratorL.get(safeQuery)
    },
    {
        name: SEARCH_KEY_WORDS.CANONICAL_CODE,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null) => {
            const negate = isNegate(rawData);
            const code = Maybe.fromNull(rawData).map(removeCurly).orSome('');
            query = queryReducer(query, {
                type: 'canonicalCodeValue',
                value: negate ? code.slice(1) : code
            });
            return queryReducer(query, {
                type: 'canonicalCodeNegate',
                value: negate
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeCanonicalCodeValueL.get(safeQuery).ap(safeCanonicalCodeNegateL.get(safeQuery).map(addNotSign))
    },
    {
        name: SEARCH_KEY_WORDS.CANONICAL_VALUE,
        updateQuery: <SQ extends SearchQuery>(query: SQ, rawData?: string | null) => {
            const negate = isNegate(rawData);
            const value = Maybe.fromNull(rawData).map(removeCurly).orSome('');
            query = queryReducer(query, {
                type: 'canonicalValueValue',
                value: negate ? value.slice(1) : value
            });
            return queryReducer(query, {
                type: 'canonicalValueNegate',
                value: negate
            });
        },
        getString: <SQ>(safeQuery: MaybeMonad<SQ>): MaybeMonad<string> =>
            safeCanonicalValueValueL.get(safeQuery).ap(safeCanonicalValueNegateL.get(safeQuery).map(addNotSign))
    }
];
const indexedKeywords = R.indexBy(R.prop('name'), keywords);
export const parse = curry(function (keyWords, query, searchString) {
    query = updateHasWords(query, searchString);
    return keyWords.reduce((query, name) => {
        const rawData = getValue(searchString, name + POSTFIX_PARSE);
        return indexedKeywords[name].updateQuery(query, rawData);
    }, query);
});
export function serialize<SQ>(query: SQ) {
    const safeQuery = Maybe.fromNull(query) as MaybeMonad<SQ>;
    return [
        addLabelIf(safeHasWordsL.get(safeQuery), ''),
        ...keywords.map(({name, getString}) => addLabelIf(getString(safeQuery), name + POSTFIX_SERIALIZE))
    ]
        .filter(I)
        .join(' ');
}
