/**
 * Created by ndyumin on 10.10.2016.
 */
import * as R from 'ramda';
import fetchWithTokenRefresh from './fetchWithTokenRefresh';
import reduxStore from '../redux/store';
import store from './store';
import withErrorNotification from './withErrorNotification';
import {PRIORITY, enqueue} from './priorityQueue';
import {ReltioNetworkError} from '../errors/ReltioError';
import {setLoadingEvent} from '../redux/actions/app';
const {updater$, store$} = store;
store$
    .map(R.prop('blockingRequestCount'))
    .distinctUntilChanged()
    .pairwise()
    .subscribe(([prev, curr]) => {
        if (prev === 0 && curr === 1) {
            reduxStore.dispatch(setLoadingEvent(true));
        }

        if (curr === 0) {
            reduxStore.dispatch(setLoadingEvent(false));
        }
    });
export function json(response) {
    return response.json();
}
export function handleFetchErrors(err) {
    throw new ReltioNetworkError(err, err);
}
export function parseErrors(response) {
    if (!response.ok) {
        const body = response.text();
        return body.then((message) => {
            const details = {};

            try {
                Object.assign(details, JSON.parse(message));
            } catch (e) {
                Object.assign(details, {
                    message,
                    original: e
                });
            }

            throw new ReltioNetworkError(details, response);
        });
    }

    return response;
}
const DEFAULT_OPTIONS = {
    credentials: 'same-origin',
    pragma: 'no-cache',
    'cache-control': 'no-cache'
};

const incrementBlockingRequestCount = () =>
    updater$.next({
        blockingRequestCount: R.inc
    });

const incrementNonBlockingRequestCount = () =>
    updater$.next({
        nonBlockingRequestCount: R.inc
    });

const decrementBlockingRequestCount = () =>
    updater$.next({
        blockingRequestCount: R.unless(R.equals(0), R.dec)
    });

const decrementNonBlockingRequestCount = () =>
    updater$.next({
        nonBlockingRequestCount: R.unless(R.equals(0), R.dec)
    });

type RequestFn = (url: string, options?: Record<string, unknown>, errorNotificationMessage?: string) => Promise<any>;
export const rawRequest: RequestFn = (url, options, errorNotificationMessage = '') => {
    const requestOptions = Object.assign({}, DEFAULT_OPTIONS, options);
    const request = fetchWithTokenRefresh(url, requestOptions).catch(handleFetchErrors).then(parseErrors);
    return errorNotificationMessage ? withErrorNotification(errorNotificationMessage, request) : request;
};
export const requestWithBlockingSpinner: RequestFn = (...args) => {
    return enqueue(() => blockingRequest(...args), PRIORITY.HIGH);
};

const blockingRequest: RequestFn = (...args) => {
    incrementBlockingRequestCount();
    return rawRequest(...args).finally(decrementBlockingRequestCount);
};

type RequestWithNonBlockingSpinner = (
    url: string,
    options?: Record<string, unknown>,
    errorNotificationMessage?: string,
    priority?: PRIORITY
) => Promise<any>;
export const requestWithNonBlockingSpinner: RequestWithNonBlockingSpinner = (
    url,
    options = {},
    errorNotificationMessage = '',
    priority = PRIORITY.LOW
) => {
    return enqueue(() => nonBlockingRequest(url, options, errorNotificationMessage), priority);
};

const nonBlockingRequest: RequestFn = (...args) => {
    incrementNonBlockingRequestCount();
    return rawRequest(...args).finally(decrementNonBlockingRequestCount);
};
