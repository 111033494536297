import Paper, {PaperProps} from '@mui/material/Paper';
import {ReactElement} from 'react';

type Props = {
    selectProps: {
        classes: {
            menu: string;
        };
    };
    getStyles: (type: string, props: Props) => PaperProps['style'];
    innerProps: PaperProps;
    innerRef: PaperProps['ref'];
    children: ReactElement;
};

export default function Menu(props: Props) {
    const {
        selectProps: {classes},
        innerProps,
        innerRef,
        getStyles,
        children
    } = props;
    return (
        <Paper ref={innerRef} style={getStyles('menu', props)} className={classes.menu} {...innerProps}>
            {children}
        </Paper>
    );
}
