import {WorkflowChangeTypes} from '../../components/Workflow/constants';
import {applyDCRToDependencies, applyDCRToLookup, getMarkingFunctionByDCR} from '../../rdm-sdk/workflow';
import {clearSearchQueryEvent} from './search';
import {commandCreator} from '../middlewares/command-middleware';
import {filter, forEach, pipe, pluck, prop, propEq} from 'ramda';
import {getDCRsByDCRId} from '../selectors/workflowSelectors';
import {getDCRsByTaskId, getDisplayedTask, getIsDCRDisplayed} from '../selectors/workflowSelectors';
import {initialQuery} from '../../components/Search/initialSearchQuery';
import {markViewOnly} from '../../core/marks';
import {requestValuesSaga} from './values';
import {resetDependenciesEvent, saveDependencyEvent} from './dependencies';
import {searchQueryEvent} from './search';
import {setDisplayedTaskEvent} from './workflow';
import {setIsSearchBarVisibleEvent} from './ui';
import {upsertValueEvent} from './values';

const getLookupByCode = (code, lookupValues) => {
    return lookupValues.find(propEq('code', code));
};

export const requestDCRLookupsCommand = commandCreator(({state, dispatch}, DCRId) => {
    const DCRs = getDCRsByDCRId(state, DCRId);
    const codes = pluck('lookupCode', DCRs);
    const query = {...initialQuery, canonicalCodes: codes};
    dispatch(searchQueryEvent(query));
    return requestValuesSaga(dispatch)();
});
export const applyDCRsToDependenciesCommand = commandCreator(({state, dispatch}, dependencies) => {
    const {currentType} = state;
    const displayedTask = getDisplayedTask(state);
    const displayedDCRs = displayedTask ? getDCRsByTaskId(state, displayedTask) : [];

    if (displayedDCRs.length) {
        const lookupCode = dependencies.code;
        const parents = dependencies.parents.data;
        const dependent = dependencies.dependent.data;
        pipe(
            filter(propEq('lookupCode', lookupCode)),
            forEach((DCR) => {
                const dependencies = applyDCRToDependencies(DCR, {
                    parents,
                    dependent
                });
                dispatch(
                    saveDependencyEvent({
                        ...dependencies,
                        type: currentType,
                        code: lookupCode
                    })
                );
            })
        )(displayedDCRs);
    }
});
export const applyDCRsToLookupsCommand = commandCreator(({state, dispatch}, DCRs) => {
    const {lookupValues, currentType} = state;
    dispatch(setIsSearchBarVisibleEvent(false));
    dispatch(resetDependenciesEvent());
    DCRs.forEach((DCR) => {
        const lookupCode = prop('lookupCode', DCR);

        if (DCR.workflowChangeType === WorkflowChangeTypes.ADDED_LOOKUP_TYPE) {
            const dependencies = applyDCRToDependencies(DCR, {
                parents: [],
                dependent: []
            });
            dispatch(
                saveDependencyEvent({
                    ...dependencies,
                    type: currentType,
                    code: lookupCode
                })
            );
        }

        const lookupValue = {...getLookupByCode(lookupCode, lookupValues)};
        const newLookup = applyDCRToLookup(DCR, lookupValue);

        if (newLookup) {
            const markingFunction = getMarkingFunctionByDCR(DCR);
            const markedLookupValue = pipe(markingFunction, markViewOnly)(newLookup);
            dispatch(upsertValueEvent(currentType, lookupCode, markedLookupValue));
        }
    });
});
export const resetApplyingDCRsCommand = commandCreator(({state, dispatch}) => {
    const isTaskDisplayed = getIsDCRDisplayed(state);

    if (isTaskDisplayed) {
        dispatch(resetDependenciesEvent());
        dispatch(clearSearchQueryEvent());
        dispatch(setDisplayedTaskEvent(null));
    }
});
export const cancelApplyingDCRsCommand = commandCreator(({state, dispatch}) => {
    const isTaskDisplayed = getIsDCRDisplayed(state);

    if (isTaskDisplayed) {
        dispatch(resetDependenciesEvent());
        dispatch(clearSearchQueryEvent());
        requestValuesSaga(dispatch)();
        dispatch(setDisplayedTaskEvent(null));
    }
});
// SAGAs
export const requestDCRLookupsSaga = (dispatch) => pipe(requestDCRLookupsCommand, dispatch);
export const applyDCRsToDependenciesSaga = (dispatch) => pipe(applyDCRsToDependenciesCommand, dispatch);
export const applyDCRsToLookupsSaga = (dispatch) => pipe(applyDCRsToLookupsCommand, dispatch);
export const resetApplyingDCRsSaga = (dispatch) => pipe(resetApplyingDCRsCommand, dispatch);
export const cancelApplyingDCRsSaga = (dispatch) => pipe(cancelApplyingDCRsCommand, dispatch);
