import * as React from 'react';
import Button from '@mui/material/Button';
import EditModeSelect from './EditModeSelect';
import Icon from '@mui/material/Icon';
import ReactDOM from 'react-dom';
import i18n from 'ui-i18n';
import styles from './drawer.less';
import {DraftMode} from '../../rdm-sdk/app.types';
import {EDIT_MODE} from '../../constants/common';
import {StateEvent} from '../../rdm-sdk/state.types';
import {discardDraftSaga, saveDraftSaga, suggestDraftSaga} from '../../redux/actions/draftMode';
import {getIsDirty, getIsValuesDirty} from '../../redux/reducers/draftModeReducer';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {noop} from '../../core/util';

const SaveButton = ({onClick, isDirty, label}: Record<string, any>) => (
    <Button color="primary" disabled={!isDirty} className="status-bar__save-button" onClick={onClick}>
        {label}
    </Button>
);

const CancelButton = ({onClick, isDirty}: Record<string, any>) => (
    <Button variant="grey" disabled={!isDirty} className="status-bar__cancel-button" onClick={onClick}>
        {i18n.text('Discard changes')}
    </Button>
);

const logger = logActivityCommand('draft-mode-buttons');
const saveLogger = logger('save-click');
const cancelLogger = logger('cancel-click');
type RightMenuProps = {
    dispatch: (event: StateEvent) => Promise<any>;
    isEditMode: boolean;
    draftMode: DraftMode;
    modes?: Record<string, any>;
    editMode?: string;
    canApplyResolver?: boolean;
    renderSearchBar?: (...args: any[]) => any;
};
export const StatusRightMenu = ({
    dispatch,
    isEditMode,
    draftMode,
    editMode,
    canApplyResolver = true,
    renderSearchBar = noop
}: RightMenuProps) => {
    const container = document.getElementById('status-line-right-menu');
    const isDirty = getIsDirty(draftMode);
    const isDirtyValues = getIsValuesDirty(draftMode);
    const rightMenu = (
        <React.Fragment>
            {renderSearchBar()}
            {isEditMode ? (
                [
                    <CancelButton
                        key="cancel"
                        onClick={() => {
                            isDirty && discardDraftSaga(draftMode, dispatch);
                            dispatch(cancelLogger() as StateEvent);
                        }}
                        isDirty={isDirty}
                    />,
                    editMode === EDIT_MODE.SUGGEST ? (
                        <SaveButton
                            key="suggest"
                            label={i18n.text('Suggest')}
                            onClick={() => {
                                isDirtyValues && suggestDraftSaga(draftMode, dispatch);
                                dispatch(saveLogger() as StateEvent);
                            }}
                            isDirty={isDirtyValues && canApplyResolver}
                        />
                    ) : (
                        <SaveButton
                            key="save"
                            label={i18n.text('Save')}
                            onClick={() => {
                                isDirty && saveDraftSaga(draftMode, dispatch);
                                dispatch(saveLogger() as StateEvent);
                            }}
                            isDirty={isDirty && canApplyResolver}
                        />
                    ),
                    Boolean(editMode) && <EditModeSelect key="mode" />
                ]
            ) : (
                <Button variant="grey" disabled>
                    <Icon>remove_red_eye</Icon> {i18n.text('VIEW ONLY')}
                </Button>
            )}
        </React.Fragment>
    );
    return container ? ReactDOM.createPortal(rightMenu, container) : false;
};
export function StatusLine() {
    return (
        <div className={styles.statusLine}>
            <div id="status-line-menu-items" />
            <div className="spacer" />
            <span id="status-line-right-menu" className={styles.rightMenu} />
        </div>
    );
}
