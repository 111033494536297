import {noop} from './util';
import {requestWithBlockingSpinner} from '../network';
const config = fetch('/clientConfig').then((response) => response.json());

const getUIUrl = (path = '') => `${location.origin}/${path}`;

export function logout() {
    location.replace(getUIUrl('logout'));
}
export function redirectToTenantChoosing() {
    config.then(({loginPath}) => {
        window.onbeforeunload = noop;
        const rdmUIUrl = `${location.protocol}//${location.hostname}:${location.port}`;
        location.assign(`${loginPath}?clientId=reltio_ui&redirectUrl=${rdmUIUrl}/callback?redirectUrl=${rdmUIUrl}`);
    });
}
export function redirectToLogin() {
    config.then(({loginPath}) => {
        if (!location.href.startsWith(loginPath)) {
            window.onbeforeunload = noop;
            const rdmUIUrl = `${location.protocol}//${location.hostname}:${location.port}`;
            const currentPath = location.hash.slice(1);
            const [, tenantId] = currentPath.split('/');
            const redirectUrl = `${rdmUIUrl}${currentPath}`;
            location.assign(
                `${loginPath}?clientId=reltio_ui&redirectUrl=${rdmUIUrl}/callback?redirectUrl=${redirectUrl}&tenant=${tenantId}`
            );
        }
    });
}
export function redirectTenantError(errorId: string) {
    config.then(({loginPath}) => {
        const currentPath = location.hash.slice(1);
        const [, tenant] = currentPath.split('/');
        const rdmUIUrl = `${location.protocol}//${location.hostname}:${location.port}`;
        location.assign(
            `${loginPath}/error?id=${errorId}&clientId=reltio_ui&redirectUrl=${rdmUIUrl}/callback?redirectUrl=${rdmUIUrl}${
                tenant ? `&tenant=${tenant}` : ''
            }`
        );
    });
}
export function checkToken(): Promise<any> {
    return requestWithBlockingSpinner('checkToken', {
        method: 'post'
    }).catch((err) => {
        redirectToLogin();
        throw err;
    });
}
export function refreshToken(): Promise<any> {
    return fetch('refreshToken', {
        method: 'post'
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Unauthorized');
            }
        })
        .catch((err) => {
            redirectToLogin();
            throw err;
        });
}
