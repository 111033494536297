import * as R from 'ramda';
import ClickOutsideHOC from '../ClickOutsideHOC/ClickOutsideHOC';
import styles from './add-grid-column-menu.less';
import {ColumnItem} from '../../rdm-sdk/grid.types';
import {Component} from 'react';
type Props = {
    items: ColumnItem[];
    onClickOutside: () => void;
    onClick: (item: ColumnItem) => void;
};

class ExtraItemsList extends Component<Props> {
    render() {
        const {items, onClickOutside, onClick} = this.props;
        return (
            <ClickOutsideHOC onClickOutside={onClickOutside}>
                <ul className={styles['extra-items-list']}>
                    {items.map((item, idx) => (
                        <li
                            key={idx}
                            className={styles['extra-items-list__item']}
                            onClick={R.pipe(R.always(item), onClick)}
                            title={item.label}
                        >
                            {item.label}
                        </li>
                    ))}
                </ul>
            </ClickOutsideHOC>
        );
    }
}

export default ExtraItemsList;
