import * as R from 'ramda';
import CustomIcon from '../../../CustomIcon/CustomIcon';
import styles from './styles.less';
import {Configuration} from '../../../../rdm-sdk/configuration.types';
import {connect} from 'react-redux';
import {getTypeByUri} from '../../../../rdm-sdk/configuration';

const Triangle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className={styles['parents-list__triangle']}
        preserveAspectRatio="xMinYMid meet"
        width={10}
        height={10}
    >
        <polygon points="0,0 10,10 10,0" />
    </svg>
);

type Props = {
    parents: string[];
    onParentClick: (parent: string) => void;
    configuration?: Configuration;
};

const ParentsList = ({parents, onParentClick, configuration}: Props) => (
    <div className={styles['parents-list']}>
        <Triangle />
        {parents.map((parent, index) => (
            <div key={index} onClick={() => onParentClick(parent)} className={styles['parents-list__row']}>
                <CustomIcon name="link" />
                {getTypeByUri(configuration, parent).label}
            </div>
        ))}
    </div>
);

const mapStateToProps = R.pick(['configuration']);
export default connect(mapStateToProps)(ParentsList);
