import i18n from 'ui-i18n';
import workflowEmptyIcon from '../../../assets/inline/workflowEmpty.svg';

const EmptyState = () => (
    <div className={'empty-state'}>
        <div
            className={'empty-state__icon'}
            dangerouslySetInnerHTML={{
                __html: workflowEmptyIcon
            }}
        />
        <h3 className={'empty-state__header'}>{i18n.text("We couldn't find any data change requests")}</h3>
    </div>
);

export default EmptyState;
