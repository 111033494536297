import * as R from 'ramda';
import initialState from '../initialState';
import {getTypeCode} from '../../rdm-sdk/types';
// eslint-disable-next-line prettier/prettier
export default function generatorsForTypesReducer(state = initialState.generatorsForTypes, event) { // NOSONAR
    switch (event.type) {
        case 'generatorsFetch':
            return initialState.generatorsForTypes;

        case 'generatorForTypeUpsert':
            return event.generator ? R.assoc(event.typeCode, event.generator, state) : R.dissoc(event.typeCode, state);

        case 'lookupTypesRemove':
            return event.types.reduce((state, type) => R.dissoc(getTypeCode(type), state), state);

        default:
            return state;
    }
}
