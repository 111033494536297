import {BehaviorSubject} from 'rxjs';
import {DEFAULT_FEATURES} from '../rdm-sdk/features';
import {Features} from '../rdm-sdk/features.types';
import {always, propOr} from 'ramda';
import {getUrlTenant} from '../core/util';
import {json} from '../network';
export const featureStream$ = new BehaviorSubject(DEFAULT_FEATURES as Features);
export const configStream$ = new BehaviorSubject({});
export const initConfigs = () =>
    fetch(`/configs?tenant=${getUrlTenant()}`)
        .then(json)
        .catch(
            always({
                features: DEFAULT_FEATURES,
                config: {}
            })
        )
        .then((configs) => {
            featureStream$.next(propOr(DEFAULT_FEATURES, 'features', configs));
            configStream$.next(propOr({}, 'config', configs));
        });
