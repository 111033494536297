export enum CollaborationStatus {
    OPEN = 'open',
    RESOLVED = 'resolved'
}
export enum ReplyAction {
    REOPEN = 'reopen',
    RESOLVE = 'resolve',
    NONE = 'none'
}
export type CollaborationCommentCount = {
    commentId: string;
    replies: number;
    status: CollaborationStatus;
};
export type CommentsCount = Record<string, CollaborationCommentCount[]>;
export type Reply = {
    selfLink: string;
    replyId: string;
    createdBy: string;
    createdTime: number;
    modifiedTime: number;
    deleted: boolean;
    action: ReplyAction;
    content: string;
    namedUsers: [];
};
export type NewReply = {
    content: string;
    namedUsers: string[];
    action: ReplyAction;
};
export type Comment = {
    selfLink: string;
    commentId: string;
    createdBy: string;
    createdTime: number;
    modifiedTime: number;
    deleted: boolean;
    visibility: 'public' | 'private';
    status: 'open' | 'resolved';
    objectId: string;
    objectType: string;
    permanentLink: string;
    content: string;
    namedUsers: string[];
    relatedObjectUris: string[];
    replies: Reply[];
    assignee?: string; // should be implemented on API
};
export type NewComment = {
    objectId: string;
    objectType: string;
    visibility: 'private' | 'public';
    content: string;
    permanentLink: string;
    namedUsers: [];
    relatedObjectUris: string[];
};
export type Comments = Record<string, Comment>;
export type Discussion = {
    commentIds: string[];
    nextPageToken?: string | null;
};
export type GeneralCollaboration = {
    commentsCount: CommentsCount;
    comments: Comments;
    discussion: Discussion;
};
export type Collaboration = Record<string, GeneralCollaboration>;
export type CollaborationOptions = {
    objectType: string;
    relatedObjectUri: string;
};
export type CollaborationUpdate = {
    content: string;
    namedUsers: string[];
    visibility?: 'private' | 'public';
};
export type CommentData = {
    code?: string;
    source?: string;
};

export enum CollaborationObjectType {
    LOOKUP_VALUE = 'rdm/lookupValues',
    MAPPING_GROUP = 'rdm/mappingGroup'
}
