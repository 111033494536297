import * as R from 'ramda';
import * as React from 'react';
import ClickOutsideHOC from '../ClickOutsideHOC/ClickOutsideHOC';
import Icon from '@mui/material/Icon';
import TetherComponent from 'react-tether';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import listStyles from '../AddGridColumnMenu/add-grid-column-menu.less';
import styles from './grid-menu.less';
import {MenuItemProps} from './GridMenuItem';
import {PureComponent} from 'react';
import {noop} from '../../core/util';
type Props = {
    stopPropagation: boolean;
    iconClassName: string;
    className: string;
    iconStyle: Record<string, any>;
    children: React.ReactElement<MenuItemProps>[];
    tetherProps: Record<string, any>;
    tooltipTitle?: string;
};
type State = {
    isOpen: boolean;
};
export default class ColumnMenu extends PureComponent<Props, State> {
    static defaultProps = {
        stopPropagation: false,
        iconClassName: '',
        className: '',
        iconStyle: {},
        tetherProps: {},
        tooltipTitle: ''
    };
    state = {
        isOpen: false
    };
    toggleOpen = () =>
        this.setState(({isOpen}) => ({
            isOpen: !isOpen
        }));

    render() {
        const {iconClassName, iconStyle, children, className, stopPropagation, tetherProps, tooltipTitle} = this.props;
        const {isOpen} = this.state;
        const stopPropagationHandler = stopPropagation ? (e) => e.stopPropagation() : noop;
        return (
            <TetherComponent
                attachment="top left"
                targetAttachment="top left"
                constraints={[
                    {
                        to: 'window',
                        pin: true
                    }
                ]}
                renderTarget={(ref) => (
                    <Tooltip title={tooltipTitle || ''}>
                        <span
                            ref={ref as React.RefObject<HTMLSpanElement>}
                            onClick={R.pipe(stopPropagationHandler, this.toggleOpen)}
                            style={iconStyle}
                            className={iconClassName}
                        >
                            <Icon
                                classes={{
                                    root: styles.icon
                                }}
                            >
                                more_vert
                            </Icon>
                        </span>
                    </Tooltip>
                )}
                renderElement={(ref) =>
                    isOpen && (
                        <ClickOutsideHOC ref={ref as React.Ref<ClickOutsideHOC>} onClickOutside={this.toggleOpen}>
                            <ul className={clsx(listStyles['extra-items-list'], styles['grid-menu'], className)}>
                                {React.Children.map(children, (menuItem, index) =>
                                    React.cloneElement(menuItem, {
                                        key: index,
                                        onClick: R.pipe(
                                            stopPropagationHandler,
                                            menuItem.props.onClick || noop,
                                            this.toggleOpen
                                        )
                                    })
                                )}
                            </ul>
                        </ClickOutsideHOC>
                    )
                }
                {...tetherProps}
            />
        );
    }
}
