import OpenedWorkflowTask from './WorkflowTasks/OpenedWorkflowTask';
import WorkflowTasks from './WorkflowTasks/WorkflowTasks';
import styles from './styles.less';
import {StateEvent} from '../../rdm-sdk/state.types';
import {TaskType, WorkflowDCRType, WorkflowType} from './workflow.types';
import {append, assoc, propEq} from 'ramda';
import {cancelApplyingDCRsSaga} from '../../redux/actions/applyingDCR';
import {connect} from 'react-redux';
import {getDCRs, getTasks} from '../../redux/selectors/workflowSelectors';
import {getIsDCRDisplayed} from '../../redux/selectors/workflowSelectors';
import {getOpenedTask} from '../../redux/reducers/uiReducer';
import {noop} from '../../core/util';
import {
    requestWorkflowDCRsSaga,
    requestWorkflowTasksAndDCRsSaga,
    resetOpenedTaskSaga
} from '../../redux/actions/workflow';
import {useEffect} from 'react';
type WorkflowsType = WorkflowType[] | null;
type Props = {
    onClose: () => void;
    onClick: () => void;
    requestWorkflowTasksAndDCRs: () => void;
    requestWorkflowDCRs: () => void;
    resetOpenedTask: () => void;
    tasks: TaskType[];
    DCRs: WorkflowDCRType[];
    openedTaskId: string;
    isDCRDisplayed: boolean;
    dispatch: (e: StateEvent) => void;
};
export const mergeTaskWithDCRs = (tasks: TaskType[], DCRs: WorkflowDCRType[]): WorkflowsType =>
    !tasks || !DCRs
        ? null
        : tasks.reduce((result, task) => {
              const collectedDCRs = DCRs.filter((DCR) => task.objectURIs.includes(DCR.DCRId));
              return append(assoc('DCRs', collectedDCRs, task), result);
          }, []);
export function Workflow(props: Props) {
    const {
        requestWorkflowTasksAndDCRs,
        requestWorkflowDCRs,
        resetOpenedTask,
        onClose,
        onClick = noop,
        tasks,
        DCRs,
        openedTaskId,
        dispatch
    } = props;
    const workflows = mergeTaskWithDCRs(tasks, DCRs);
    const isTasksLoaded = !!tasks;
    useEffect(() => {
        isTasksLoaded && requestWorkflowDCRs();
    }, [isTasksLoaded]);
    const handlePaginationChange = requestWorkflowTasksAndDCRs;
    const handleRefresh = requestWorkflowTasksAndDCRs;
    const handleCloseOpenedTask = resetOpenedTask;
    const openedWorkflowTask = openedTaskId && (workflows || []).find(propEq('taskID', openedTaskId));

    const onCloseHandle = () => {
        cancelApplyingDCRsSaga(dispatch)();
        onClose();
    };

    return (
        <div className={styles['workflow-panel']} onClick={onClick}>
            {openedWorkflowTask ? (
                <OpenedWorkflowTask workflow={openedWorkflowTask} onClose={handleCloseOpenedTask} />
            ) : (
                <WorkflowTasks
                    workflows={workflows}
                    onClose={onCloseHandle}
                    onPaginationChange={handlePaginationChange}
                    onRefresh={handleRefresh}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    tasks: getTasks(state),
    DCRs: getDCRs(state),
    openedTaskId: getOpenedTask(state),
    isDCRDisplayed: getIsDCRDisplayed(state)
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    requestWorkflowDCRs: requestWorkflowDCRsSaga(dispatch),
    requestWorkflowTasksAndDCRs: requestWorkflowTasksAndDCRsSaga(dispatch),
    resetOpenedTask: resetOpenedTaskSaga(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
