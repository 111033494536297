import FeaturesContext from './FeaturesContext';
import {FEATURE} from '../../rdm-sdk/features';
import {ReactElement} from 'react';

type Props<T> = T & {
    required: FEATURE[];
    children: ((props: T) => ReactElement) | ReactElement;
};

export default function FeatureFilter<T>({required: requiredFeatures, children, ...otherProps}: Props<T>) {
    const otherChildrenProps = {...otherProps} as T;
    const getChildren = () => (typeof children === 'function' ? children(otherChildrenProps) : children);
    return (
        <FeaturesContext.Consumer>
            {(features) => {
                const availableFeatures = Object.keys(features).filter((featureName) => features[featureName]);
                const visible = requiredFeatures.every((feature) => availableFeatures.includes(feature));
                return visible ? getChildren() : null;
            }}
        </FeaturesContext.Consumer>
    );
}
