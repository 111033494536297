import * as Sentry from '@sentry/react';
import {CaptureConsole} from '@sentry/integrations';
import {getUrlTenant} from './util';

declare global {
    interface Window {
        environmentName?: string;
        dsn?: string;
        release?: string;
    }
}

export const initMonitoring = () => {
    if (!window.dsn) {
        return;
    }

    try {
        Sentry.init({
            dsn: window.dsn,
            release: window.release || '',
            integrations: [new Sentry.BrowserTracing(), new CaptureConsole({levels: ['error']})],
            tracesSampleRate: 0.2,
            environment: window.environmentName || 'UNKNOWN'
        });
        Sentry.setTag('tenant', getUrlTenant());
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
