import Dialog, {DialogProps} from '@mui/material/Dialog';

type Props = DialogProps & {
    open: boolean;
    onCancel?: () => void;
};

const CustomDialog = ({className, open, onCancel, children, ...otherProps}: Props) => (
    <Dialog
        classes={{
            paper: className
        }}
        onClose={onCancel}
        open={open}
        transitionDuration={0}
        maxWidth={false}
        {...otherProps}
    >
        {children}
    </Dialog>
);

export default CustomDialog;
