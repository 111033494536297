import {logActivityCommand} from '../../redux/actions/activityLogging';
export const logger = logActivityCommand('workflow');
export const openClickWFLogger = logger('open-click');
export const closeClickWFLogger = logger('close-click');
export const paginationChangeWFLogger = logger('pagination-change');
export const dateChangeWFLogger = logger('date-change');
export const priorityChangeWFLogger = logger('priority-change');
export const assigneeChangeWFLogger = logger('assignee-change');
export const addCommentWFLogger = logger('add-comment');
export const linkClickWFLogger = logger('link-click');
export const scrollToCommentsWFLogger = logger('scroll-to-comments');
export const actionsIconClickWFLogger = logger('actions-icon-click');
export const rejectChangeWFLogger = logger('reject-change');
export const executeActionWFLogger = logger('execute-action');
export const DCROpenClickWFLogger = logger('dcr-open-click');
export const DCRCloseClickWFLogger = logger('dcr-close-click');
export const showMoreWFLogger = logger('show-more');
export const showLessWFLogger = logger('show-less');
export const showAllCommentsWFLogger = logger('show-all-comments');
export const showLessCommentsWFLogger = logger('show-less-comments');
