import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';

type Props = {
    selectProps: {
        classes: {
            dropdownIndicator: string;
        };
    };
    innerProps: IconButtonProps;
};

export default function DropdownIndicator({selectProps: {classes}, innerProps}: Props) {
    return (
        <IconButton {...innerProps} className={classes.dropdownIndicator} size="large">
            <ArrowDropDownIcon />
        </IconButton>
    );
}
