/**
 * Created by ndyumin on 11.04.2017.
 */
import TextField from '@mui/material/TextField';
import styles from './style.less';
import {Component} from 'react';
import {getValue} from '../../core/util';
import {pipe} from '../../core/func';
type Props<SQ> = {
    onSearch: (query: SQ) => void;
    query: SQ;
    onChange: (value: string) => void;
    searchString: string;
    parse: (query: SQ) => (searchString: string) => SQ;
};
export default class BasicSearchBar<SQ> extends Component<Props<SQ>> {
    render() {
        const {query, onSearch, onChange, searchString, parse} = this.props;
        return (
            <TextField
                variant="standard"
                className={`${styles['searchBar__textfield']} test-searchBar__textfield`}
                name="search"
                onKeyPress={(e) => (e.key === 'Enter' ? pipe(parse(query), onSearch)(searchString) : null)}
                onChange={pipe(getValue, onChange)}
                value={searchString}
                autoFocus
                InputProps={{
                    disableUnderline: true
                }}
            />
        );
    }
}
