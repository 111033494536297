import activityLogReducer from './activityLogReducer';
import collaborationReducer from './collaborationReducer';
import draftModeReducer from './draftModeReducer';
import generatorsForTypesReducer from './generatorsForTypesReducer';
import initialState from '../initialState';
import mainReducer from './mainReducer';
import needMdmSyncReducer from './needMdmSyncReducer';
import uiReducer from './uiReducer';
import unmappedReducer from './unmappedReducer';
import workflowReducer from './workflowReducer';
import {UiState} from '../../rdm-sdk/ui.types';
import {routerReducer} from 'react-router-redux';
// eslint-disable-next-line prettier/prettier
export default function reducer(state = initialState, event) { // NOSONAR
    return {
        ...mainReducer(state, event),
        unmapped: unmappedReducer(state.unmapped, event),
        collaboration: collaborationReducer(state.collaboration, event),
        ui: uiReducer(state.ui as UiState, event),
        routing: routerReducer(state.routing, event),
        draftMode: draftModeReducer(state.draftMode, event),
        needMdmSync: needMdmSyncReducer(state, event),
        generatorsForTypes: generatorsForTypesReducer(state.generatorsForTypes, event),
        activityLog: activityLogReducer(state.activityLog, event),
        workflow: workflowReducer(state.workflow, event)
    };
}
