import './main.less';
import * as R from 'ramda';
import AppBar from './components/AppBar/AppBar';
import GeneratorsPage from './pages/GeneratorsPage/GeneratorsPage';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import SourcesPage from './pages/SourcesPage/SourcesListPage';
import TypesListPage from './pages/TypesPage/TypesPage';
import ValuesListPage from './pages/ValuesPage/ValuesPageContainer';
import checkAuthorization from './router-hooks/checkAuthorization';
import openMappingComment from './router-hooks/openMappingComment';
import openValuesComment from './router-hooks/openValuesComment';
import openWorkflowTask from './router-hooks/openWorkflowTask';
import {Component} from 'react';
import {Dispatch} from 'redux';
import {History} from 'history';
import {IndexRedirect, Redirect, Route, Router, browserHistory} from 'react-router';
import {checkPolicies} from './router-hooks/checkPolicies';
import {connect} from 'react-redux';
import {hot} from 'react-hot-loader';
import {later} from './core/util';
import {logActivityCommand} from './redux/actions/activityLogging';
import {logout, redirectToTenantChoosing} from './core/auth';
const logger = logActivityCommand('navigation');
const logoutLogger = logger('logout');
const locationLogger = logger('location');
type Props = {
    history: Record<string, any>;
    dispatch: Dispatch;
};

class App extends Component<Props> {
    unlistenBrowserHistory: (...args: any[]) => any;

    constructor(props: Props) {
        super(props);
        this.unlistenBrowserHistory = browserHistory.listen(R.pipe(locationLogger, this.props.dispatch));
    }

    onLogout = () => {
        this.unlistenBrowserHistory();
        this.props.dispatch(logoutLogger());
        later(logout)();
    };

    render() {
        const {history} = this.props;
        return (
            <Router history={history as History}>
                <Route path="/login" onEnter={redirectToTenantChoosing} />
                <Route path="/">
                    <IndexRedirect to="/login" />
                    <Route path="/logout" onEnter={this.onLogout} />
                    <Route path="/:tenantId/page_not_found" component={PageNotFound} />
                    <Route path="/:tenantId" component={AppBar} onEnter={checkAuthorization}>
                        <IndexRedirect to="types" />
                        <Route path="sources" component={SourcesPage} />
                        <Route path="types" component={TypesListPage} />
                        <Route path="types/:type">
                            <IndexRedirect to="mapping" />
                            <Route path=":tab/task/:taskId" onEnter={openWorkflowTask} />
                            <Route path=":tab/:code/:source" onEnter={openMappingComment} />
                            <Route path=":tab/:code" onEnter={openValuesComment} />
                            <Route
                                path=":tab"
                                component={ValuesListPage}
                                onEnter={checkPolicies(['lookupValuesRead'])}
                            />
                        </Route>
                        <Route
                            path="generators"
                            component={GeneratorsPage}
                            onEnter={checkPolicies(['generatorsEdit'])}
                        />
                        <Redirect from="*" to="page_not_found" />
                    </Route>
                </Route>
            </Router>
        );
    }
}

export default R.pipe(connect(), hot(module))(App);
