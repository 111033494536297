import * as R from 'ramda';
import {Maybe, Validation} from 'monet';
import {validateLabel} from '../AddTypeDialog/validation';
export const isNameUniq = (attrIndex, attributes) =>
    attributes
        .map((attr, index) => attr.name !== R.path([attrIndex, 'name'], attributes) || index === attrIndex)
        .every(R.identity);
export const isAllNamesUniq = (attributes) =>
    attributes.map((_, index) => isNameUniq(index, attributes)).every(R.identity);
export const validateAttributes = (lookup) => {
    const attributes = Maybe.fromNull(lookup.attributes).orSome([]);
    return (attributes.length && attributes.some(({name, type}) => !name || !type)) || !isAllNamesUniq(attributes)
        ? Validation.fail(['attributes invalid'])
        : Validation.success(lookup.attributes);
};
export const validateLookupType = (lookup) => validateLabel(lookup).ap(validateAttributes(lookup).acc());
