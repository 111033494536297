import styles from './styles.less';
import {MAX_MINUTES_LABEL, getTimeLabel, minutesDiff} from '../../../../rdm-sdk/dates';
import {useEffect, useState} from 'react';
type Props = {
    user: string;
    timestamp: number;
};

function ActivityUserTime({user, timestamp}: Props) {
    const [timeLabel, setTimeLabel] = useState(getTimeLabel(timestamp));

    const updateTimeLabel = () => {
        let timeoutId = null as NodeJS.Timeout | null;

        const dispose = () => {
            if (timeoutId) window.clearTimeout(timeoutId);
        };

        const update = () => {
            if (minutesDiff(Date.now(), timestamp) < MAX_MINUTES_LABEL) {
                setTimeLabel(getTimeLabel(timestamp));
                timeoutId = setTimeout(update, 60000);
            }
        };

        update();
        return dispose;
    };

    useEffect(() => {
        const dispose = updateTimeLabel();
        return () => dispose();
    }, []);
    return (
        <p className={styles['user-time']}>
            <span title={user} className={styles['user']}>
                {user}
            </span>
            <span className={styles['time']}>{timeLabel}</span>
        </p>
    );
}

export default ActivityUserTime;
