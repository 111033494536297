import * as R from 'ramda';
import ObservableSubscription from '../components/Subscription/ObservableSubscription';
import store from './store';
import {ReactNode} from 'react';
type Props = {
    selector: (state: Record<string, any>) => boolean;
    children: (...args: any[]) => ReactNode;
};
export default function NetworkSubscription({selector, children}: Props) {
    return (
        <ObservableSubscription source={store.store$.map(selector).distinctUntilChanged(R.equals)}>
            {children}
        </ObservableSubscription>
    );
}
