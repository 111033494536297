import * as React from 'react';
import clsx from 'clsx';
import styles from './styles.less';
type Props = {
    children?: React.ReactNode | null;
    className?: string;
};

function ChangesTitle({children, className}: Props) {
    return <h4 className={clsx(styles['changes-title'], className)}>{children}</h4>;
}

export default ChangesTitle;
