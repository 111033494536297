import * as React from 'react';
import Scrollbar from '../Scrollbar/Scrollbar';
import {Component} from 'react';
import {GridProps, List} from 'react-virtualized';
import {ListProps} from 'react-virtualized';
const listStyle = {
    overflowX: false,
    overflowY: false
};

type Props = ListProps & {
    width: number;
    height: number;
};
export default class ListWithScroll extends Component<Props> {
    List: List | null = null;
    scrollbar?: Scrollbar;
    state = {
        isLoading: false
    };

    handleScroll = ({target}) => {
        const {scrollTop, scrollLeft} = target;
        this.List?.Grid?.handleScrollEvent({
            scrollTop,
            scrollLeft
        });
    };

    componentDidUpdate() {
        const grid = this.List?.Grid;
        if (
            grid &&
            !this.state.isLoading &&
            grid?.props.height > (grid as unknown as GridProps)?.getTotalRowsHeight()
        ) {
            this.setState({
                isLoading: true
            });
            this.props.loadMore(() => {
                this.setState({
                    isLoading: false
                });
            });
        }
    }

    getScrollbarsRef() {
        return this.scrollbar?.getScrollbarsRef();
    }

    render() {
        const {width, height} = this.props;
        return (
            <Scrollbar
                ref={(scrollbar) => (this.scrollbar = scrollbar)}
                autoHide
                style={{
                    width,
                    height
                }}
                onScroll={this.handleScroll}
            >
                <List
                    {...this.props}
                    ref={(instance) => (this.List = instance)}
                    // @ts-ignore
                    style={listStyle}
                />
            </Scrollbar>
        );
    }
}
