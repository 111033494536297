/**
 * Created by ndyumin on 09.01.2017.
 */
import {CONFIRMATION_DIALOG_TYPES} from '../../constants/common';
export function submenuEvent(submenu) {
    return {
        type: 'submenu',
        submenu
    };
}
export function titleEvent(title) {
    return {
        type: 'title',
        title
    };
}
export function drawerToggleEvent() {
    return {
        type: 'drawerToggle'
    };
}
export function drawerVisibilityEvent(isVisible) {
    return {
        type: 'drawerVisibility',
        isVisible
    };
}
export function paginationScrollIdEvent(value) {
    return {
        type: 'paginationScrollId',
        value
    };
}
export function paginationHasMoreEvent(value) {
    return {
        type: 'paginationHasMore',
        value
    };
}
export const totalEvent = (value) => ({
    type: 'total',
    value
});
export const showConfirmationDialogEvent = (dialogType = CONFIRMATION_DIALOG_TYPES.LEAVE_PAGE) => ({
    type: 'showConfirmationDialog',
    dialogType
});
export const hideConfirmationDialogEvent = () => ({
    type: 'hideConfirmationDialog'
});
export function resolveEvent(result) {
    return {
        type: 'resolve',
        result
    };
}
export function setEditModeEvent(mode) {
    return {
        type: 'setEditMode',
        mode
    };
}
export function setLoadingEvent(loading) {
    return {
        type: 'setLoading',
        loading
    };
}
