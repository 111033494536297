import * as React from 'react';
import ActivityChanges from '../../parts/ActivityChanges/ActivityChanges';
import DefaultChange from '../../Change/Change';
import i18n from 'ui-i18n';
import {ActivityFieldItem} from '../../activityLog.types';
import {ChangeType} from '../../activityLog.types';
import {formatBool} from '../../../../rdm-sdk/activities';
import {formatDate} from '../../../../core/date';
import {isNil} from 'ramda';
type Props = {
    items: ActivityFieldItem[];
    ChangeComponent: React.ComponentType<ChangeType>;
};

function ValueFields(props: Props) {
    const {items, ChangeComponent} = props;
    const {canonical, startDate, endDate, enabled} = items.reduce(
        (fields, item) => ({...fields, [item.name]: item}),
        {} as Record<string, ActivityFieldItem>
    );
    const Change = ChangeComponent || DefaultChange;
    return (
        <ActivityChanges>
            {canonical && (
                <Change
                    id={canonical.id}
                    displayName={i18n.text('Canonical value')}
                    newValue={canonical.newValue}
                    oldValue={canonical.oldValue}
                    type={canonical.type}
                />
            )}
            {startDate && (
                <Change
                    id={startDate.id}
                    displayName={i18n.text('Start date')}
                    newValue={formatDate(startDate.newValue)}
                    oldValue={startDate.oldValue && formatDate(startDate.oldValue)}
                    type={startDate.type}
                />
            )}
            {endDate && (
                <Change
                    id={endDate.id}
                    displayName={i18n.text('End date')}
                    newValue={formatDate(endDate.newValue)}
                    oldValue={endDate.oldValue && formatDate(endDate.oldValue)}
                    type={endDate.type}
                />
            )}
            {enabled && (
                <Change
                    id={enabled.id}
                    displayName={i18n.text('Status')}
                    newValue={formatBool(enabled.newValue)}
                    oldValue={isNil(enabled.oldValue) ? '' : formatBool(enabled.oldValue)}
                    type={enabled.type}
                />
            )}
        </ActivityChanges>
    );
}

export default ValueFields;
