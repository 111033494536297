import Typography from '@mui/material/Typography';
import {ReactElement} from 'react';

type Props = {
    selectProps: {
        classes: {
            groupHeading: string;
        };
    };
    children: ReactElement;
};

export default function GroupHeading({children, selectProps: {classes}}: Props) {
    return (
        <Typography variant="subtitle2" className={classes.groupHeading}>
            {children}
        </Typography>
    );
}
