import * as React from 'react';
import ActivitiesPagination from '../ActivitiesPagination/ActivitiesPagination';
import ActivityList from '../ActivityList/ActivityList';
import Button from '@mui/material/Button';
import EmptyState from '../EmptyState/EmptyState';
import FilterModal from '../filter/FilterModal/FilterModal';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {
    FiltersRenderer,
    LookupActivityItem,
    ObjectType,
    RowContentRenderer,
    TypeActivityItem
} from '../activityLog.types';
import {List} from 'react-virtualized';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {filterClearLogger} from '../logging';
import {
    getActivities,
    getActivitiesTotal,
    getIsActivityLogFilterApplied
} from '../../../redux/reducers/activityLogReducer';
import {noop} from '../../../core/util';
import {
    requestActivitiesSaga,
    requestActivitiesTotalSaga,
    resetActivityLogFiltersEvent
} from '../../../redux/actions/activityLog';
import {setActivitiesFirstPageEvent} from '../../../redux/actions/activityLog';
import {useEffect, useRef} from 'react';

type StateProps = {
    activities?: Array<Record<string, any>> | null;
    isFilterApplied: boolean;
    total: number;
};

type DispatchProps = {
    dispatch: (event: StateEvent) => void;
    requestActivitiesTotal: () => Promise<any>;
    requestActivities: () => Promise<any>;
};

type Props = StateProps &
    DispatchProps & {
        filtersRenderer: FiltersRenderer;
        objectType: ObjectType;
        rowContentRenderer: RowContentRenderer<TypeActivityItem | LookupActivityItem>;
        onClose: () => void;
        onClick?: () => void;
    };
export function SidePanel(props: Props) {
    const {
        onClose,
        rowContentRenderer,
        filtersRenderer,
        onClick = noop,
        requestActivitiesTotal,
        requestActivities,
        activities,
        total,
        isFilterApplied,
        dispatch
    } = props;
    const listRef = useRef<List>(null);
    useEffect(() => {
        if (!activities) {
            requestActivitiesTotal();
            requestActivities();
        }
    }, []);

    function handlePaginationChange() {
        requestActivities();
        listRef.current?.scrollToPosition(0);
    }

    function handleFilterClear() {
        dispatch(filterClearLogger(null) as StateEvent);
        dispatch(setActivitiesFirstPageEvent());
        dispatch(resetActivityLogFiltersEvent());
        requestActivitiesTotal();
        requestActivities();
    }

    return (
        <div className={styles['side-panel']} onClick={onClick}>
            <div className={styles['side-panel__header']}>
                <IconButton className={styles['side-panel__close']} onClick={onClose} size="large">
                    <Icon>close</Icon>
                </IconButton>
                <Typography variant={'h6'} className={styles['side-panel__title']}>
                    {i18n.text('Activity log')}
                </Typography>
                <div className="spacer" />
                <FilterModal isActive={isFilterApplied} contentRenderer={filtersRenderer} />
            </div>
            {isFilterApplied && (
                <div className={styles['filter-info']}>
                    <div className={styles['filter-info__container']}>
                        <span className={styles['filter-info__label']}>{i18n.text('Filter results:')}</span>
                        <span className={styles['filter-info__count']}>{total}</span>
                    </div>
                    <Button variant="grey" className={styles['filter-info__reset']} onClick={handleFilterClear}>
                        {i18n.text('CLEAR FILTER')}
                    </Button>
                </div>
            )}
            {activities && activities.length > 0 ? (
                <React.Fragment>
                    <div className={styles['side-panel__list-container']}>
                        <ActivityList ref={listRef} items={activities} rowContentRenderer={rowContentRenderer} />
                    </div>
                    <ActivitiesPagination onChange={handlePaginationChange} />
                </React.Fragment>
            ) : (
                <EmptyState />
            )}
        </div>
    );
}
const mapStateToProps = (state) =>
    ({
        activities: getActivities(state),
        total: getActivitiesTotal(state),
        isFilterApplied: getIsActivityLogFilterApplied(state)
    }) as StateProps;

const mapDispatchToProps = (dispatch, {objectType}) =>
    ({
        dispatch,
        requestActivitiesTotal: () => requestActivitiesTotalSaga(dispatch)(objectType),
        requestActivities: () => requestActivitiesSaga(dispatch)(objectType)
    }) as DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
