import * as R from 'ramda';
import Button from '@mui/material/Button';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import FormControl from '@mui/material/FormControl';
import GeneratorEditor from '../AddGeneratorDialog/GeneratorEditor';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18n from 'ui-i18n';
import styles from './edit-type-dialog.less';
import {Component, ReactNode} from 'react';
import {DEFAULT_GENERATOR} from '../../rdm-sdk/generators';
import {Generator} from '../../rdm-sdk/generators.types';
import {fromArray} from '../../core/maybe';
import {getChecked, getValue} from '../../core/util';
import {pipe} from '../../core/func';
import {prop} from '../../core/lenses';
const nameL = prop('name');
type Props = {
    generators?: Generator[] | null;
    generator: string;
    newGenerator?: Generator | null;
    onChange: (field: 'generator' | 'newGenerator') => (data: Generator) => void;
    onNewGeneratorChange: (field: string) => (value: string | number) => void;
};
type State = {
    useGenerator: boolean;
};

class GeneratorSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            useGenerator: Boolean(props.generator)
        };
    }

    resetGenerator = R.unless(
        R.identity,
        R.pipe(R.always(null), this.props.onChange('newGenerator'), R.always(null), this.props.onChange('generator'))
    );
    toggleUseGenerator = () =>
        this.setState(
            R.evolve({
                useGenerator: R.not
            })
        );

    render() {
        const {generators, generator, newGenerator, onChange, onNewGeneratorChange} = this.props;
        const {useGenerator} = this.state;
        const generatorsOptions = fromArray(generators).orSome([]).map(nameL.get);
        const newGeneratorValue = `NEW_GEN_${Date.now()}`;
        const generatorValue = newGenerator ? newGeneratorValue : generator;
        return (
            <div className={styles['edit-type-dialog__generator-selector']}>
                <CustomSwitch
                    className={styles['edit-type-dialog__switch']}
                    label={i18n.text('Auto generate canonical code')}
                    hintForOnState={i18n.text('Turn off to manually input canonical codes')}
                    hintForOffState={i18n.text('Turn on to automatically generate canonical codes')}
                    checked={useGenerator}
                    onChange={pipe(getChecked, R.tap(this.resetGenerator), this.toggleUseGenerator)}
                />

                {useGenerator && (
                    <FormControl variant="standard" className={styles['edit-type-dialog__generator']}>
                        <InputLabel htmlFor="type-generator">
                            {newGenerator ? '' : i18n.text('Code generator')}
                        </InputLabel>
                        <Select
                            variant="standard"
                            value={generatorValue}
                            onChange={R.pipe(
                                getValue,
                                R.ifElse(
                                    R.equals(newGeneratorValue),
                                    R.pipe(
                                        R.always(DEFAULT_GENERATOR),
                                        onChange('newGenerator'),
                                        R.always(null),
                                        onChange('generator')
                                    ),
                                    R.pipe(onChange('generator'), R.always(null), onChange('newGenerator'))
                                )
                            )}
                            input={<Input id="type-generator" />}
                            renderValue={(value) =>
                                (value === newGeneratorValue ? i18n.text('Create new') : value) as ReactNode
                            }
                        >
                            {generatorsOptions
                                .map((generator, idx) => (
                                    <MenuItem
                                        dense
                                        key={idx}
                                        value={generator}
                                        className={styles['edit-type-dialog__generator-item']}
                                    >
                                        {generator}
                                    </MenuItem>
                                ))
                                .concat(
                                    <MenuItem
                                        dense
                                        key={generatorsOptions.length}
                                        value={newGeneratorValue}
                                        className={styles['edit-type-dialog__generator-item']}
                                    >
                                        <Button color="primary">
                                            <Icon>add</Icon> {i18n.text('NEW GENERATOR')}
                                        </Button>
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                )}

                {newGenerator && (
                    <GeneratorEditor
                        name={newGenerator.name}
                        type={newGenerator.type}
                        rangeStart={newGenerator.rangeStart}
                        isNew={true}
                        generators={generators}
                        onChange={onNewGeneratorChange}
                    />
                )}
            </div>
        );
    }
}

export default GeneratorSelector;
