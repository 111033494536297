import * as R from 'ramda';
import AddLookupTypeButton from '../AddLookupTypeButton/AddLookupTypeButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SearchInput from '../SearchInput/SearchInput';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component} from 'react';
import {LookupType} from '../../../rdm-sdk/types.types';
import {connect} from 'react-redux';
type Props = {
    open: boolean;
    lookupTypes: LookupType[];
    onTypeSelect: (arg0: LookupType) => void;
    onClose: () => void;
};
type State = {
    searchQuery: string;
};

class SelectLookupTypeDialog extends Component<Props, State> {
    state = {
        searchQuery: ''
    };
    onSearchChange = (event: Record<string, any>) =>
        this.setState({
            searchQuery: event.target.value
        });
    onSearchClear = () =>
        this.setState({
            searchQuery: ''
        });
    reset = () => {
        this.setState({
            searchQuery: ''
        });
    };

    render() {
        const {open, onClose, lookupTypes, onTypeSelect} = this.props;
        const {searchQuery} = this.state;
        const types = lookupTypes.filter(({label}) => label.toLowerCase().startsWith(searchQuery.toLowerCase()));
        return (
            <Dialog
                open={open}
                onClose={onClose}
                classes={{
                    paper: styles['dialog']
                }}
            >
                <DialogTitle>{i18n.text('Select the lookup type you want to import')}</DialogTitle>
                <DialogContent>
                    <SearchInput
                        searchQuery={searchQuery}
                        placeholder={i18n.text('Search lookup type')}
                        onChange={this.onSearchChange}
                        onClear={this.onSearchClear}
                    />
                    <List>
                        {types.map((lookupType) => (
                            <ListItem
                                key={lookupType.uri}
                                button
                                onClick={R.pipe(R.always(lookupType), onTypeSelect, onClose, this.reset)}
                            >
                                <ListItemText primary={lookupType.label} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions className={styles['actions']}>
                    <AddLookupTypeButton />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    lookupTypes: state.configuration.lookupTypes
});

export default connect(mapStateToProps)(SelectLookupTypeDialog);
