import * as R from 'ramda';
import i18n from 'ui-i18n';
import {ACTIVITY_TYPE, OBJECT_TYPE} from '../components/ActivityLog/constants';
import {SourceMapping} from './lookups.types';
import {languagesMap} from '../components/Localization/languagesMock';
export const formatBool = (bool) => (bool ? i18n.text('ON') : i18n.text('OFF'));
export const getDelta = (newValue = {}, oldValue = {}) => {
    return R.union(Object.keys(newValue), Object.keys(oldValue)).reduce((changes, key) => {
        if (R.has(key, newValue) && !R.has(key, oldValue)) {
            changes.push({
                name: key,
                type: ACTIVITY_TYPE.ADD,
                newValue: newValue[key]
            });
        } else if (!R.has(key, newValue) && R.has(key, oldValue)) {
            changes.push({
                name: key,
                type: ACTIVITY_TYPE.DELETE,
                oldValue: oldValue[key]
            });
        } else if (newValue[key] !== oldValue[key]) {
            changes.push({
                name: key,
                type: ACTIVITY_TYPE.CHANGE,
                newValue: newValue[key],
                oldValue: oldValue[key]
            });
        }

        return changes;
    }, []);
};
const getChangeID = R.propOr(null, 'id');
export function getType(rawType) {
    if (rawType.includes('ADDED')) {
        return ACTIVITY_TYPE.ADD;
    } else if (rawType.includes('DELETED')) {
        return ACTIVITY_TYPE.DELETE;
    } else if (rawType.includes('CHANGED')) {
        return ACTIVITY_TYPE.CHANGE;
    } else {
        return null;
    }
}

function getObjectType(rawType) {
    if (rawType.includes('LOOKUP_TYPE')) {
        return OBJECT_TYPE.LOOKUP_TYPE;
    } else if (rawType.includes('LOOKUP')) {
        return OBJECT_TYPE.LOOKUP_VALUE;
    } else if (rawType.includes('SOURCE')) {
        return OBJECT_TYPE.SOURCE;
    } else {
        return null;
    }
}

function getName(attributeType) {
    if (attributeType.startsWith('attributes')) {
        return attributeType.replace(/^attributes\//, '');
    } else if (attributeType.startsWith('localizations')) {
        const langCode = attributeType.replace(/^localizations\//, '');
        return languagesMap[langCode] || langCode;
    } else {
        return attributeType;
    }
}

function getGeneralItems(itemType, delta) {
    return delta
        .filter((item) => item.type && item.type.includes(itemType))
        .map((item) => ({
            id: getChangeID(item),
            type: getType(item.type),
            name: getName(item.attributeType),
            newValue: item.newValue,
            oldValue: item.oldValue
        }));
}

export function getLookupValueAttributes(delta) {
    return delta
        .filter((item) => item.type && item.type.includes('ATTRIBUTE'))
        .map((item) => ({
            id: getChangeID(item),
            type: getType(item.type),
            name: getName(item.attributeType),
            dataType: item.dataType,
            newValue: item.newValue && item.newValue.value,
            oldValue: item.oldValue && item.oldValue.value
        }));
}

function parseDepUri(uri) {
    const [lookupType, lookupValue] = uri.replace(/^rdm\/lookupTypes\//, '').split('/');
    return {
        lookupType,
        lookupValue
    };
}

function parseMappingUri(uri) {
    const [, source, code] = uri.match(/source\/(.+)\/mapping\/(.+)/);
    return {
        source,
        code
    };
}

export function getLookupValueDependencies(itemType, delta) {
    const change = delta.find((item) => item.type && item.type.includes(itemType));
    if (!change) return [];
    const {newValue = [], oldValue = [], id = null} = change;
    const added = newValue
        .filter((uri) => !oldValue.includes(uri))
        .map((uri) => {
            const {lookupType, lookupValue} = parseDepUri(uri);
            return {
                id,
                type: ACTIVITY_TYPE.ADD,
                name: lookupType,
                newValue: lookupValue
            };
        });
    const deleted = oldValue
        .filter((uri) => !newValue.includes(uri))
        .map((uri) => {
            const {lookupType, lookupValue} = parseDepUri(uri);
            return {
                id,
                type: ACTIVITY_TYPE.DELETE,
                name: lookupType,
                oldValue: lookupValue
            };
        });
    return [...added, ...deleted];
}
export function getLookupValueLocalizations(delta) {
    return delta
        .filter((item) => item.type && item.type.includes('LOCALIZATION'))
        .map((item) => ({
            id: getChangeID(item),
            type: getType(item.type),
            name: getName(item.attributeType),
            newValue: item.newValue && item.newValue.value,
            oldValue: item.oldValue && item.oldValue.value
        }));
}
export function getAddedLookupValueSourceChanges({sourceMappings = [], id}) {
    return sourceMappings.map(({source, values}: {source: string; values: SourceMapping[]}) => ({
        source,
        mappings: values.map((mapping) => ({
            id,
            type: ACTIVITY_TYPE.ADD,
            name: mapping.code,
            newValue: mapping
        }))
    }));
}
export function getAddedLookupValueAttributes({attributes = [], id}) {
    return attributes.map(({name, value}) => ({
        id,
        type: ACTIVITY_TYPE.ADD,
        name: name,
        newValue: value
    }));
}
export function getAddedLookupValueDependencies({parents = [], id}) {
    return parents.map((uri) => {
        const {lookupType, lookupValue} = parseDepUri(uri);
        return {
            id,
            type: ACTIVITY_TYPE.ADD,
            name: lookupType,
            newValue: lookupValue
        };
    });
}
export function getAddedLookupValueLocalizations({localizations = [], id}) {
    return localizations.map(({languageCode, value}) => ({
        id,
        type: ACTIVITY_TYPE.ADD,
        name: getName(languageCode),
        newValue: value
    }));
}
export function getAddedLookupValueFields(lookupValue) {
    const fieldsNames = ['startDate', 'endDate', 'enabled'];
    return fieldsNames.map((name) => ({
        id: lookupValue.id,
        type: ACTIVITY_TYPE.ADD,
        name,
        newValue: lookupValue[name]
    }));
}
export function getLookupValueSources(data) {
    const delta = R.propOr([], 'delta', data);
    const lookupValue = R.propOr({}, 'value', data);
    const groupedBySource = delta
        .filter((item) => item.type && item.type.includes('MAPPING'))
        .map((item) => ({...item, ...parseMappingUri(item.attributeType)}))
        .reduce((groupedBySource, item) => {
            const {source} = parseMappingUri(item.attributeType);
            const mappings = groupedBySource[source] || [];
            mappings.push({
                id: getChangeID(item),
                type: getType(item.type),
                name: item.code,
                newValue: item.newValue,
                oldValue: item.oldValue
            });
            groupedBySource[source] = mappings;
            return groupedBySource;
        }, {});
    const sources = Object.entries(groupedBySource).map(([source, mappings]) => ({
        source,
        mappings
    }));
    return [...sources, ...getAddedLookupValueSourceChanges(lookupValue)];
}

function getCanonicalFieldChange(delta) {
    const items = delta.filter((item) => item.type && item.type.includes('MAPPING'));
    const newCanonicalItem = items.find((item) => {
        const isCanonicalBefore = R.pathOr(false, ['oldValue', 'canonicalValue'], item);
        const isCanonicalAfter = R.pathOr(false, ['newValue', 'canonicalValue'], item);
        return !isCanonicalBefore && isCanonicalAfter;
    });
    const oldCanonicalItem = items.find((item) => {
        const isCanonicalBefore = R.pathOr(false, ['oldValue', 'canonicalValue'], item);
        const isCanonicalAfter = R.pathOr(false, ['newValue', 'canonicalValue'], item);
        return isCanonicalBefore && !isCanonicalAfter;
    });

    if (newCanonicalItem && newCanonicalItem.newValue && oldCanonicalItem && oldCanonicalItem.oldValue) {
        const {source: newSource} = parseMappingUri(newCanonicalItem.attributeType);
        const {source: oldSource} = parseMappingUri(oldCanonicalItem.attributeType);
        const {newValue} = newCanonicalItem;
        const {oldValue} = oldCanonicalItem;
        return {
            type: ACTIVITY_TYPE.CHANGE,
            name: 'canonical',
            newValue: `${newSource} ${newValue.value} (${newValue.code})`,
            oldValue: `${oldSource} ${oldValue.value} (${oldValue.code})`
        };
    } else {
        return null;
    }
}

export function getLookupValueFields(delta) {
    const fields = getGeneralItems('FIELD', delta);
    const canonicalField = getCanonicalFieldChange(delta);
    canonicalField && fields.push(canonicalField);
    return fields;
}
export const filterActivityItemsByType = R.curry((objectType, activity) =>
    !objectType
        ? activity
        : {
              ...activity,
              items: activity.items.filter((item) => {
                  const itemObjectType = getObjectType(item.eventType);
                  return itemObjectType === objectType;
              })
          }
);
export const filterActivityItemsByLookupType = R.curry((lookupType, activity) =>
    !lookupType
        ? activity
        : {
              ...activity,
              items: activity.items.filter((item) => item.objectUri.includes(`/${lookupType}/`))
          }
);
export const addIconTypeForActivityItems = (activity) => {
    const getItemType = R.pipe(R.prop('eventType'), getType);
    const hasEqualTypes = activity.items.every((item, i, items) => i === 0 || R.eqBy(getItemType, item, items[i - 1]));
    return R.evolve(
        {
            items: R.adjust(0, (firstItem) => ({
                ...firstItem,
                iconType: hasEqualTypes ? getItemType(firstItem) : ACTIVITY_TYPE.CHANGE
            }))
        },
        activity
    );
};

const parseDelta = (delta) => {
    return {
        fields: getGeneralItems('FIELD', delta),
        attributes: getGeneralItems('ATTRIBUTE', delta)
    };
};

export const parseLookupDelta = (delta, sourcesData, newLookupValue) => {
    return {
        sources: getLookupValueSources(sourcesData),
        ...(newLookupValue ? parseNewLookupValuesChanges(newLookupValue) : parseLookupValueChanges(delta))
    };
};

const parseLookupValueChanges = (delta) => ({
    fields: getLookupValueFields(delta),
    attributes: getLookupValueAttributes(delta),
    parents: getLookupValueDependencies('PARENTS', delta),
    children: getLookupValueDependencies('CHILDREN', delta),
    localizations: getLookupValueLocalizations(delta)
});

const parseNewLookupValuesChanges = (newLookupValue) => ({
    fields: getAddedLookupValueFields(newLookupValue),
    attributes: getAddedLookupValueAttributes(newLookupValue),
    parents: getAddedLookupValueDependencies(newLookupValue),
    localizations: getAddedLookupValueLocalizations(newLookupValue)
});

export const parseActivity = (activity) => {
    return activity.items.map((item, i) => {
        const delta = R.pathOr([], ['data', 'delta'], item);
        const itemObjectType = getObjectType(item.eventType);
        const isLookupValue = itemObjectType === OBJECT_TYPE.LOOKUP_VALUE;
        const newLookupValue = R.path(['data', 'value'], item);
        return {
            isHead: i === 0,
            activityId: activity.id,
            type: getType(item.eventType),
            iconType: i === 0 && item.iconType,
            timestamp: activity.timestamp,
            user: activity.user,
            label: item.objectValue,
            ...(isLookupValue ? parseLookupDelta(delta, item.data, newLookupValue) : parseDelta(delta))
        };
    });
};
export const collectActivities = (objectType, lookupType) =>
    R.chain(
        R.pipe(
            filterActivityItemsByType(objectType),
            filterActivityItemsByLookupType(lookupType),
            addIconTypeForActivityItems,
            parseActivity
        )
    );
