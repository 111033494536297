import * as React from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import styles from './styles.less';
import {FiltersRenderer} from '../../activityLog.types';
import {StateEvent} from '../../../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {filterCancelLogger, filterClickLogger} from '../../logging';
import {useState} from 'react';
type Props = {
    contentRenderer: FiltersRenderer;
    isActive: boolean;
    dispatch: (arg0: StateEvent) => void;
};

function FilterModal(props: Props) {
    const {contentRenderer, isActive, dispatch} = props;
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
        dispatch(filterClickLogger(null) as StateEvent);
    }

    function handleClose() {
        setAnchorEl(null);
        dispatch(filterCancelLogger(null) as StateEvent);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;
    return (
        <React.Fragment>
            <IconButton
                aria-describedby={id}
                color={isActive ? 'primary' : 'default'}
                onClick={handleClick}
                size="large"
            >
                <Icon>filter_list</Icon>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                PaperProps={{
                    className: styles['container']
                }}
            >
                {contentRenderer({
                    close: handleClose
                })}
            </Popover>
        </React.Fragment>
    );
}

export default connect()(FilterModal);
