import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {getValue} from '../../../../core/util';
import {pipe} from 'ramda';
import {useState} from 'react';
export type InputCommentProps = {
    onSend: (arg0: string) => void;
};
export function InputComment(props: InputCommentProps) {
    const {onSend} = props;
    const [comment, setComment] = useState('');
    const isCommentExist = !!comment;

    const clearComment = () => setComment('');

    const onSendComment = () => {
        onSend(comment);
        clearComment();
    };

    return (
        <div className={styles['input-comment']}>
            <Input
                name="dcr-comment"
                disableUnderline
                className={styles['input-comment__comment']}
                type="text"
                multiline
                maxRows={8}
                value={comment}
                onChange={pipe(getValue, setComment)}
                inputProps={{
                    className: styles['input-comment__input'],
                    onKeyDown: (event) => {
                        const {key, ctrlKey} = event;

                        if (key === 'Enter') {
                            if (ctrlKey) setComment(comment + '\n');
                            else {
                                event.preventDefault();
                                onSendComment();
                            }
                            return;
                        }
                    }
                }}
                placeholder={i18n.text('Type comment')}
            />
            <Button
                disabled={!isCommentExist}
                color="secondary"
                className={styles['input-comment__button']}
                onClick={onSendComment}
            >
                {i18n.text('Send')}
            </Button>
        </div>
    );
}
export default InputComment;
