import * as R from 'ramda';
import i18n from 'ui-i18n';
import {AUTO_GENERATED_CODE_PREFIX} from '../../constants/common';
import {Maybe} from 'monet';
import {PRIORITY} from '../../network/priorityQueue';
import {RequestBuilder} from '../../rdm-sdk/network';
import {SortDirection} from '../../rdm-sdk/app.types';
import {UnmappedSourceTotal} from '../../rdm-sdk/unmapped.types';
import {catchIfNotAbortError} from '../../errors/utils';
import {checkIfTypeIsClientOnly} from '../../rdm-sdk/types';
import {commandCreator} from '../middlewares/command-middleware';
import {filterFromUnmappedSearchQuery, filterTotalsFromUnmappedSearchQuery} from '../../rdm-sdk/filters';
import {getOriginalUnmappedCodeCreator} from '../../rdm-sdk/lookups';
import {json, requestWithBlockingSpinner, requestWithNonBlockingSpinner} from '../../network';
import {then} from '../../core/func';
export const UNMAPPED_LIMIT = 20;
const MAX_BY_URI_REQUEST_SIZE = 1000;
const REQUEST_TOTALS_TIMEOUT_MS = 10 * 60000;
const MAX_TOTALS_SOURCES = 10000;
export const unmappedResetEvent = () => ({
    type: 'unmappedReset'
});
export const unmappedValuesResetEvent = () => ({
    type: 'unmappedValuesReset'
});
export const unmappedValuesReceivedEvent = (unmappedValues) => ({
    type: 'unmappedValuesReceived',
    unmappedValues
});
export const toggleUnmappedOrderEvent = (source) => ({
    type: 'toggleUnmappedOrder',
    source
});
export const updateUnmappedItemsEvent = R.curry((source, items) => ({
    type: 'updateUnmappedItems',
    source,
    items
}));
export const appendUnmappedItemsEvent = R.curry((source, items) => ({
    type: 'appendUnmappedItems',
    source,
    items
}));
export const unmappedSearchQueryEvent = (searchQuery) => ({
    type: 'unmappedSearchQuery',
    searchQuery
});
export const unmappedSelectItemToggleEvent = (item) => ({
    type: 'unmappedSelectItemToggle',
    item
});
export const unmappedSelectedItemsClearEvent = () => ({
    type: 'unmappedSelectedItemsClear'
});
export const unmappedRemovedItemsAppendEvent = (key, removedItems) => ({
    type: 'unmappedRemovedItemsAppend',
    key,
    removedItems
});
export const unmappedRemovedItemsClearEvent = () => ({
    type: 'unmappedRemovedItemsClear'
});
export const unmappedScrollIdResetEvent = () => ({
    type: 'unmappedScrollIdReset'
});
export const unmappedScrollIdResetBySourceEvent = R.curry((source) => ({
    type: 'unmappedScrollIdResetBySource',
    source
}));
export const updateUnmappedScrollIdEvent = R.curry((source, scrollId) => ({
    type: 'updateUnmappedScrollId',
    source,
    scrollId
}));
export const setUnmappedSourceTotalEvent = (totals: UnmappedSourceTotal[] = []) => ({
    type: 'setUnmappedSourceTotal',
    totals
});
export const setLoadingTotalEvent = (loading) => ({
    type: 'setLoadingTotal',
    loading
});
export const setLoadingUnmappedEvent = (loading) => ({
    type: 'setLoadingUnmapped',
    loading
});
let lastTotalsRequestTimestamp = 0;
export const clearLastTotalsRequestTimestamp = () => (lastTotalsRequestTimestamp = 0);

const setLastTotalsRequestTimestamp = () => (lastTotalsRequestTimestamp = Date.now());

export const getUnmappedSourcesTotalsRequest = ({state, options}) => {
    const {
        tenant,
        currentType,
        configuration,
        unmapped: {unmappedSearchQuery}
    } = state;

    const {excludeSearchQuery, signal} = options || {};
    const filter = filterTotalsFromUnmappedSearchQuery(
        Maybe.fromNull(currentType),
        Maybe.fromNull(excludeSearchQuery ? null : unmappedSearchQuery)
    );
    const currentTypeIsClientOnly = checkIfTypeIsClientOnly(configuration, currentType);
    const request = new RequestBuilder(`/rdm/unmapped/${tenant}/_facets`)
        .addParam('facet', 'source')
        .addParam('filter', filter)
        .addParam('max', MAX_TOTALS_SOURCES)
        .build();

    const signalOptions = signal ? {signal} : {};
    return () =>
        currentTypeIsClientOnly
            ? Promise.resolve({})
            : requestWithNonBlockingSpinner(request, signalOptions).then(json).then(R.prop('source'));
};
let totalsPromise: Promise<any> | null = null;
export const requestUnmappedSourcesTotals = commandCreator(({state, dispatch}, options) => {
    const {
        unmapped: {unmappedSearchQuery}
    } = state;
    const {setTotals = false, signal} = options || {};
    const isTimeoutExceeded = Date.now() - lastTotalsRequestTimestamp > REQUEST_TOTALS_TIMEOUT_MS;

    if (!setTotals && totalsPromise && !isTimeoutExceeded && !unmappedSearchQuery) {
        return totalsPromise;
    }

    const needToSetTotals = setTotals || (isTimeoutExceeded && !unmappedSearchQuery);
    const totalsRequest = getUnmappedSourcesTotalsRequest({
        state,
        options: {
            excludeSearchQuery: setTotals,
            signal
        }
    });
    dispatch(setLoadingTotalEvent(true));
    const totalsPromiseRequest = totalsRequest()
        .then((totals) => {
            return Object.keys(totals)
                .map((source) => {
                    const total = totals[source];
                    return {
                        source,
                        total
                    };
                })
                .filter(({total}) => total > 0);
        })
        .then(
            R.tap(
                R.when(R.always(needToSetTotals), (totals) => {
                    setLastTotalsRequestTimestamp();
                    dispatch(setUnmappedSourceTotalEvent(totals));
                })
            )
        )
        .then(R.tap(() => dispatch(setLoadingTotalEvent(false))))
        .catch(
            catchIfNotAbortError((error) => {
                console.error('Get unmapped sources error', error);
                return Promise.reject(error);
            })
        )
        .catch((error) => {
            dispatch(setLoadingTotalEvent(false));
            return Promise.reject(error);
        });
    if (needToSetTotals) totalsPromise = totalsPromiseRequest;
    return totalsPromiseRequest;
});
export const requestUnmapped = commandCreator(({state}, source: string, signal) => {
    const {
        tenant,
        currentType,
        unmapped: {unmappedValues, unmappedSearchQuery}
    } = state;
    const encodedType = encodeURIComponent(currentType);
    const unmappedValue = R.find(R.propEq('source', source), unmappedValues);
    const scrollId = R.propOr('', 'scrollId', unmappedValue);
    const filter = filterFromUnmappedSearchQuery(
        Maybe.fromNull(source),
        Maybe.fromNull(currentType),
        Maybe.fromNull(unmappedSearchQuery)
    );
    const req = new RequestBuilder(`/rdm/unmapped/${tenant}/_scan`)
        .addParam('type', encodedType)
        .addParam('limit', UNMAPPED_LIMIT)
        .addParam('sort', 'value')
        .addParam('order', R.propOr(SortDirection.ASC, 'order', unmappedValue))
        .addParam('filter', filter)
        .build();

    type Options = {
        method: string;
        headers: any;
        body: string;
        signal?: AbortSignal;
    };
    const options: Options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: scrollId
    };
    if (signal) options.signal = signal;
    return currentType
        ? requestWithNonBlockingSpinner(req, options, '', PRIORITY.MEDIUM)
              .then(json)
              .catch(
                  catchIfNotAbortError((error) => {
                      console.error(`requestUnmapped error for source ${source}`, error);
                      return Promise.reject(error);
                  })
              )
        : Promise.resolve([]);
});

const setScrollIdBySource = (dispatch) => (source) =>
    R.compose(dispatch, updateUnmappedScrollIdEvent(source), R.propOr('', 'scrollId'));

const getUnmappedValues = R.ifElse(R.has('values'), R.prop('values'), R.always([]));
export const requestUnmappedSaga = R.curry((dispatch, source, signal = null) =>
    dispatch(requestUnmapped(source, signal))
        .then(R.tap(R.compose(dispatch, updateUnmappedItemsEvent(source), getUnmappedValues)))
        .then(R.tap(setScrollIdBySource(dispatch)(source)))
        .catch(catchIfNotAbortError((error) => console.error(`requestUnmappedSaga error for source ${source}`, error)))
);
export const requestNextUnmappedSaga = R.curry((dispatch, source, signal = null) =>
    dispatch(requestUnmapped(source, signal))
        .then(R.tap(R.compose(dispatch, appendUnmappedItemsEvent(source), getUnmappedValues)))
        .then(R.tap(setScrollIdBySource(dispatch)(source)))
        .catch(
            catchIfNotAbortError((error) => console.error(`requestNextUnmappedSaga error for source ${source}`, error))
        )
);

const requestUnmappedItemsSaga = (dispatch) =>
    R.curry((total, signal = null) =>
        dispatch(requestUnmapped(total.source, signal))
            .then(
                R.tap(
                    R.compose(dispatch, unmappedValuesReceivedEvent, (unmapped) => ({
                        items: getUnmappedValues(unmapped),
                        ...total
                    }))
                )
            )
            .then(R.tap(setScrollIdBySource(dispatch)(total.source)))
            .catch(
                catchIfNotAbortError((error) => {
                    console.error('requestUnmappedFormatted error', error);
                    return Promise.reject(error);
                })
            )
    );

const setLoadingUnmappedToFalse = R.pipe(R.F, setLoadingUnmappedEvent);
const setLoadingUnmappedToTrue = R.pipe(R.T, setLoadingUnmappedEvent);
export const requestUnmappedAllSaga =
    (dispatch) =>
    (signal = null) =>
        R.pipe(
            unmappedScrollIdResetEvent,
            dispatch,
            unmappedValuesResetEvent,
            dispatch,
            setLoadingUnmappedToTrue,
            dispatch,
            R.always({
                signal
            }),
            requestUnmappedSourcesTotals,
            dispatch,
            then((totals: {total: number; source: string}[]) => {
                return Promise.all(totals.map((total) => requestUnmappedItemsSaga(dispatch)(total, signal))).catch(
                    (error) => {
                        dispatch(setLoadingUnmappedToFalse());
                        return Promise.reject(error);
                    }
                );
            }),
            then(R.pipe(setLoadingUnmappedToFalse, dispatch))
        )();
export const requestUnmappedTotalSaga =
    (dispatch) =>
    (signal = null) =>
        R.pipe(
            R.always({
                setTotals: true,
                signal
            }),
            requestUnmappedSourcesTotals,
            dispatch
        )();

const deleteMappedUnmappedRequest = ({uris, tenant}) =>
    requestWithBlockingSpinner(
        `/rdm/unmapped/${tenant}/_delete`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uris
            })
        },
        i18n.text('Delete unmapped error')
    );

const suggestDeleteMappedUnmappedRequest = ({uris, tenant, DCRID}) =>
    requestWithBlockingSpinner(
        `/rdm/changeRequests/${tenant}/${DCRID}/unmapped/_delete`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uris
            })
        },
        i18n.text('Suggest unmapped error')
    );

const deleteMappedUnmappedCommand = commandCreator(({state}, mappedValues, DCRID = null) => {
    const {
        unmapped: {removedItems: unmappedRemovedItems},
        lookupValues,
        tenant,
        currentType
    } = state;
    const getOriginalUnmappedCode = getOriginalUnmappedCodeCreator(lookupValues);
    const uris = R.pipe(
        R.reduce(
            (acc, {code}) => {
                const removed = unmappedRemovedItems[getOriginalUnmappedCode(code)];
                return code && Array.isArray(removed) ? acc.concat(removed) : acc;
            },
            R.propOr([], AUTO_GENERATED_CODE_PREFIX, unmappedRemovedItems)
        ),
        R.map(({code, source}) => `${tenant}/${currentType}/${source}/${code}`),
        R.splitEvery(MAX_BY_URI_REQUEST_SIZE)
    )(mappedValues);
    const removeRequests = uris.map((uris) =>
        (DCRID
            ? suggestDeleteMappedUnmappedRequest({
                  tenant,
                  uris,
                  DCRID
              })
            : deleteMappedUnmappedRequest({
                  tenant,
                  uris
              })
        ).then(json)
    );
    return Promise.all(removeRequests).then(R.flatten);
});
export const deleteMappedUnmappedSaga = (dispatch) =>
    R.pipe(
        deleteMappedUnmappedCommand,
        dispatch,
        then(
            R.when(R.any(R.has('value')), () =>
                Promise.all([requestUnmappedTotalSaga(dispatch)(), requestUnmappedAllSaga(dispatch)()]).then(
                    R.compose(dispatch, unmappedRemovedItemsClearEvent)
                )
            )
        )
    );
export const suggestDeleteMappedUnmappedSaga = (dispatch) => R.pipe(deleteMappedUnmappedCommand, dispatch);
