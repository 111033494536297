import * as R from 'ramda';
import * as React from 'react';
import ListRow from './ListRow/ListRow';
import ListRowHeader from './ListRow/ListRowHeader';
import styles from './styles.less';
import {ActivityLogLabel, COLLAPSE_SHOW_LESS, COLLAPSE_SHOW_MORE, MAX_VISIBLE_ITEMS_IN_ACTIVITY} from '../constants';
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from 'react-virtualized';
import {
    LookupActivityItem,
    RowContentRenderer,
    RowHeaderItem,
    ShowMoreItem,
    TypeActivityItem
} from '../activityLog.types';
import {getDateLabel} from '../../../rdm-sdk/dates';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useCombinedRefs} from '../../../core/hooks';
const filterClosedActivities = R.curry((uncollapsedActivities, items) => {
    let itemsCounterInActivity = 0;
    return items.reduce((resultItems, item, index) => {
        const {activityId, isHead} = item;
        if (isHead) itemsCounterInActivity = 0;
        else itemsCounterInActivity++;
        const isCollapsed = !uncollapsedActivities.includes(activityId);
        const isLastActivityItem = index + 1 === items.length || activityId !== items[index + 1].activityId;

        if (itemsCounterInActivity < MAX_VISIBLE_ITEMS_IN_ACTIVITY) {
            resultItems.push(item);
        } else {
            if (isCollapsed) {
                if (itemsCounterInActivity === MAX_VISIBLE_ITEMS_IN_ACTIVITY) {
                    resultItems.push({
                        type: ActivityLogLabel.COLLAPSE_LABEL,
                        value: COLLAPSE_SHOW_MORE,
                        groupId: activityId,
                        scrollIndex: resultItems.length
                    });
                }
            } else {
                resultItems.push(item);

                if (isLastActivityItem) {
                    resultItems.push({
                        type: ActivityLogLabel.COLLAPSE_LABEL,
                        value: COLLAPSE_SHOW_LESS,
                        groupId: activityId,
                        scrollIndex: resultItems.length - itemsCounterInActivity + MAX_VISIBLE_ITEMS_IN_ACTIVITY
                    });
                }
            }
        }

        return resultItems;
    }, []);
});

const addDateItems = (items) => {
    let headerIndex: number | null = null;
    let prev = null as LookupActivityItem | TypeActivityItem | null;
    return items.reduce((acc, curr) => {
        if (curr.timestamp) {
            const currDate = getDateLabel(curr.timestamp);
            const prevDate = prev && getDateLabel(prev.timestamp);

            if (currDate !== prevDate) {
                headerIndex = acc.length;
                acc.push({
                    type: ActivityLogLabel.DATE_LABEL,
                    value: currDate,
                    headerIndex
                });
            }

            prev = curr;
        }

        acc.push({...curr, headerIndex});
        return acc;
    }, []);
};

type Props = {
    items: Array<Record<string, any>>;
    rowContentRenderer: RowContentRenderer<TypeActivityItem | LookupActivityItem>;
};

const ActivityList = React.forwardRef(function ActivityList(props: Props, ref: React.ForwardedRef<List>) {
    const {rowContentRenderer} = props;
    const listRef = useRef<List>(null);
    const combinedRef = useCombinedRefs(listRef, ref);
    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            minHeight: 50
        })
    );
    const initialLastOffset = {
        offset: 0,
        index: 0
    };
    const [stickyItem, setStickyItem] = useState<RowHeaderItem | null>(null);
    const [uncollapsedActivities, setUncollapsedActivities] = useState<string[]>([]);
    const [lastOffset, setLastOffset] = useState(initialLastOffset);
    useEffect(() => {
        cache.current?.clearAll();

        if (lastOffset.index) {
            listRef.current?.recomputeRowHeights(lastOffset.index);
            listRef.current?.scrollToPosition(lastOffset.offset);
            setLastOffset(initialLastOffset);
        } else listRef.current?.recomputeRowHeights();
    }, [props.items, uncollapsedActivities]);
    const items = R.pipe(filterClosedActivities(uncollapsedActivities), addDateItems)(props.items);

    const onCollapseItems = ({groupId, scrollIndex: index}: ShowMoreItem) => {
        setLastOffset({
            index,
            offset: combinedRef?.current?.getOffsetForRow({
                index,
                alignment: 'auto'
            })
        });
        setUncollapsedActivities((uncollapsedActivities) =>
            uncollapsedActivities.includes(groupId)
                ? R.without([groupId], uncollapsedActivities)
                : R.append(groupId, uncollapsedActivities)
        );
    };

    const rowRenderer = useCallback(
        ({index, key, parent, style}) => {
            return (
                <CellMeasurer cache={cache.current} columnIndex={0} key={key} rowIndex={index} parent={parent}>
                    {({measure}) => (
                        <div style={style}>
                            <ListRow
                                item={items[index]}
                                measureRowHeight={measure}
                                rowContentRenderer={rowContentRenderer}
                                isLast={index === items.length - 1}
                                onCollapseItems={onCollapseItems}
                            />
                        </div>
                    )}
                </CellMeasurer>
            );
        },
        [props.items, uncollapsedActivities]
    );
    const onRowsRendered = useCallback(
        ({startIndex}) => {
            if (startIndex > 0) {
                const item = items[startIndex];

                if (!stickyItem || item.headerIndex !== stickyItem.headerIndex) {
                    setStickyItem(items[item.headerIndex]);
                }
            } else {
                setStickyItem(null);
            }
        },
        [items, stickyItem]
    );
    return (
        <AutoSizer>
            {({height, width}) => (
                <div className={styles['activity-list']}>
                    {stickyItem && <ListRowHeader sticky item={stickyItem} />}
                    <List
                        ref={combinedRef}
                        deferredMeasurementCache={cache.current}
                        height={height}
                        width={width}
                        rowCount={items.length}
                        rowHeight={cache.current.rowHeight}
                        rowRenderer={rowRenderer}
                        onRowsRendered={onRowsRendered}
                    />
                </div>
            )}
        </AutoSizer>
    );
});
export default ActivityList;
