import i18n from 'ui-i18n';
import tableHeaderRenderer from '../../components/CustomTable/tableHeaderRenderer';
export const typesHeaderRenderer = ({typesTotal, unmappedTypesTotal, logger, filter}) =>
    tableHeaderRenderer({
        caption: i18n.text('Lookup Types'),
        summary:
            (typesTotal > 0 || null) &&
            (typesTotal > 1
                ? i18n.text('${count} types', {
                      count: typesTotal
                  })
                : i18n.text('${count} type', {
                      count: typesTotal
                  })),
        summaryWarning:
            (unmappedTypesTotal > 0 || null) &&
            (unmappedTypesTotal > 1
                ? i18n.text('${count} types with unmapped values', {
                      count: unmappedTypesTotal
                  })
                : i18n.text('${count} type with unmapped values', {
                      count: unmappedTypesTotal
                  })),
        summaryWarningTooltip: i18n.text('Total lookup types with unmapped values'),
        filter,
        logger
    });
