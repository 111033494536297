import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18n from 'ui-i18n';
import makeStyles from '@mui/styles/makeStyles';
import styles from './modeSelect.less';
import suggestIcon from '../../assets/inline/suggest.svg';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {EDIT_MODE} from '../../constants/common';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {getEditMode} from '../../redux/selectors/mainReducerSelectors';
import {getEditModes} from '../../rdm-sdk/editModeTypes';
import {getValue} from '../../core/util';
import {pick, pipe} from 'ramda';
import {setEditModeEvent} from '../../redux/actions/app';
const useStyles = makeStyles(() => ({
    root: {
        padding: '0 10px 0 26px',
        fontSize: '14px',
        textTransform: 'uppercase',
        lineHeight: '16px',
        color: 'rgba(0,0,0,0.87)',
        fontWeight: 500
    },
    outlined: {
        borderColor: 'rgba(0,0,0,0.12)',
        padding: '12px 22px 7px 14px !important'
    },
    formRoot: {
        margin: '0 9px 0 2px'
    }
}));

const getModeIcon = (mode) => {
    switch (mode) {
        case EDIT_MODE.SUGGEST:
            return (
                <span
                    className={styles['mode-select__suggest-icon']}
                    dangerouslySetInnerHTML={{
                        __html: suggestIcon
                    }}
                />
            );

        case EDIT_MODE.EDIT:
            return (
                <Icon fontSize="small" className={styles['mode-select__edit-icon']}>
                    {'edit'}
                </Icon>
            );
    }
};

const getEditModeLabel = (mode) => {
    switch (mode) {
        case EDIT_MODE.SUGGEST:
            return i18n.text('Suggesting');

        case EDIT_MODE.EDIT:
            return i18n.text('Editing');

        default:
            return mode;
    }
};

type StateProps = {
    dispatch: (event: StateEvent) => Promise<any>;
    policies: CheckedPolicies;
    mode?: string | null;
};

type Props = StateProps;

const EditModeSelect = ({dispatch, mode, policies}: Props) => {
    const classes = useStyles();
    const modes = getEditModes(policies);
    const onChange = pipe(getValue, setEditModeEvent, dispatch);
    return (
        <FormControl
            variant="outlined"
            classes={{
                root: classes.formRoot
            }}
        >
            {getModeIcon(mode)}
            <Select
                variant="outlined"
                name="editing_mode"
                value={mode}
                onChange={onChange}
                classes={{
                    root: classes.root,
                    outlined: classes.outlined
                }}
            >
                {modes.map((mode) => (
                    <MenuItem key={mode} value={mode}>
                        {getEditModeLabel(mode)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const mapStateToProps = (state) =>
    ({
        mode: getEditMode(state),
        ...pick(['policies'], state)
    }) as (state) => StateProps;

export default connect(mapStateToProps)(EditModeSelect);
