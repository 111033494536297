import Menu, {MenuProps} from '@mui/material/Menu';
import {Component} from 'react';
import type {MouseEventHandler, ReactNode} from 'react';
type Props = Omit<MenuProps, 'open'> & {
    className?: string;
    anchorRenderer: (onClick: MouseEventHandler<HTMLAnchorElement>) => ReactNode;
    itemsRenderer: (onClick: () => void) => ReactNode;
};
type MenuState = {
    anchorEl: MenuProps['anchorEl'];
};

class CustomMenu extends Component<Props, MenuState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            anchorEl: null
        };
    }

    handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        this.setState({
            anchorEl: event.currentTarget as Element
        });
    };
    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    render() {
        const {anchorEl} = this.state;
        const {className, anchorRenderer, itemsRenderer, ...otherProps} = this.props;
        return (
            <div>
                {anchorRenderer(this.handleClick)}

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    classes={{
                        paper: className
                    }}
                    {...otherProps}
                >
                    {itemsRenderer(this.handleClose)}
                </Menu>
            </div>
        );
    }
}

export default CustomMenu;
