/**
 * Created by ndyumin on 11.03.2017.
 */
'use strict';

import {curry} from './func';
export interface Functor<T> {
    map<U>(fn: (t: T) => U): Functor<U>;
}
interface Apply<T> extends Functor<T> {
    ap<U>(fn: Apply<(t: T) => U>): Apply<U>;
}
interface Monad<T> extends Apply<T> {
    flatMap<U>(fn: (v: T) => Monad<U>): Monad<U>;
}
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
interface Either<E, A> {
    cata<E, A, T>(leftFn: (e: E) => T, rightFn: (a: A) => T): T;
}
export interface MaybeMonad<A> {
    flatMap<T>(fn: (v: A) => MaybeMonad<T>): MaybeMonad<T>;
    toEither<E>(left: E): Either<E, A>;
    map<T>(fn: (v: A) => T): MaybeMonad<T>;
    ap<T>(fn: MaybeMonad<(t: A) => T>): MaybeMonad<T>;
    filter(pred: (v: A) => boolean): MaybeMonad<A>;
    orElse(m: MaybeMonad<A>): MaybeMonad<A>;
    orSome(v: A): A;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Validation<E, A> extends Monad<A> {
    isSuccess(): boolean;
}
export interface Some<T> extends MaybeMonad<T> {
    some(): T;
}
export type None = MaybeMonad<any>;
export const some = <T>(maybe: Some<T>): T => maybe.some();
export const ap =
    <T, U>(apply2: Apply<(t: T) => U>) =>
    (apply1: Apply<T>): Apply<U> =>
        apply1.ap(apply2);
// @ts-ignore
declare function map<T, U>(fn: (t: T) => U): (functor: T[]) => U[];
// @ts-ignore
declare function map<T, U>(fn: (t: T) => U): (functor: Functor<T>) => Functor<U>;
// eslint-disable-next-line no-redeclare
export function map(fn) {
    return (functor) => functor.map(fn);
}
export const orSome =
    <T>(value: T) =>
    (maybe: MaybeMonad<T>) =>
        maybe.orSome(value);
export const flatMap =
    <T, U>(fn: (v: T) => Monad<U>) =>
    (monad: Monad<T>): Monad<U> =>
        monad.flatMap(fn);
export const toEither =
    <E, A>(left: E) =>
    (maybe: MaybeMonad<A>): Either<E, A> =>
        maybe.toEither(left);
export const isSuccess = <E, A>(validation: Validation<E, A>): boolean => validation.isSuccess();
export const cata =
    <E, A, T>(leftFn: (e: E) => T, rightFn: (a: A) => T) =>
    (either: Either<E, A>): T =>
        either.cata(leftFn, rightFn);
export const tap = curry((fn, maybe) => (maybe.isSome() ? fn(maybe.some()) : null));
