import CloseDialogButton from '../CloseDialogButton/CloseDialogButton';
import CustomIcon from '../CustomIcon/CustomIcon';
import DatePicker from '../DatePicker/DatePicker';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import TextField from '../TextField/TextField';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './edit-type-dialog.less';
import {Fragment} from 'react';
import {LookupTypeAttribute} from '../../rdm-sdk/types.types';
import {TypeData} from './EditTypeDialog';
import {fromBoolean, fromString} from '../../core/maybe';
export const Attribute = ({attribute}: {attribute: LookupTypeAttribute}) => {
    const name = fromString(attribute.name).orSome('');
    const type = fromString(attribute.type).orSome('');
    const required = fromBoolean(attribute.required).orSome(false);
    const visible = fromBoolean(attribute.visible).orSome(true);
    return (
        <div className={styles['type-attribute']}>
            <TextField
                readOnly
                className={styles['type-attribute__name']}
                value={name}
                label={i18n.text('Attribute name')}
            />
            <TextField
                readOnly
                className={styles['type-attribute__select--read-view']}
                value={i18n.text(type)}
                label={i18n.text('Data type')}
            />
            <div
                className={clsx(
                    styles['type-attribute__hide-checkbox--read-view'],
                    !visible && styles['type-attribute__inactive']
                )}
            >
                <Icon className={styles['edit-type-dialog__icon']}>
                    {visible ? 'remove_red_eye' : 'visibility_off'}
                </Icon>
                {visible ? i18n.text('Visible') : i18n.text('Hidden')}
            </div>
            <div
                className={clsx(
                    styles['type-attribute__required-checkbox--read-view'],
                    !required && styles['type-attribute__inactive']
                )}
            >
                <CustomIcon
                    name={required ? 'required' : 'required_inactive'}
                    className={clsx('material-icons', styles['edit-type-dialog__custom-icon'])}
                />
                {required ? i18n.text('Required') : i18n.text('Not required')}
            </div>
        </div>
    );
};
type Props = {
    typeData: TypeData;
    onClose: () => void;
};
export default function ReadView({
    typeData: {attributes, description, label, enabled, endDate, startDate, code, generator},
    onClose
}: Props) {
    return (
        <Fragment>
            <DialogTitle>{label}</DialogTitle>
            <CloseDialogButton onClick={onClose} />
            <DialogContent
                className={clsx(styles['edit-type-dialog__content'], styles['edit-type-dialog__content--read-view'])}
            >
                <TextField
                    className={styles['edit-type-dialog__textfield']}
                    name="code"
                    readOnly
                    value={code}
                    label={i18n.text('Code')}
                />
                <TextField
                    className={styles['edit-type-dialog__textfield']}
                    name="description"
                    readOnly
                    value={description}
                    label={i18n.text('Description')}
                />
                <div className={styles['edit-type-dialog__date-pickers']}>
                    <DatePicker readOnly label={i18n.text('Start date')} id="typeStartDatePicker" value={startDate} />
                    <DatePicker readOnly label={i18n.text('End date')} id="typeEndDatePicker" value={endDate} />
                </div>
                <p className={styles['edit-type-dialog__status--view-mode']}>
                    <Icon className={styles['edit-type-dialog__icon']}>{enabled ? 'checked' : 'do_not_disturb'}</Icon>
                    {enabled ? i18n.text('Status enabled') : i18n.text('Status disabled')}
                </p>
                <TextField
                    className={styles['edit-type-dialog__textfield']}
                    name="generator"
                    readOnly
                    value={generator}
                    label={i18n.text('Generator')}
                />
                <div className={styles['type-details__attributes--view-mode']}>
                    <Typography variant="subtitle1" className={styles['type-details__attributes-title']}>
                        {i18n.text('Custom attributes')}
                    </Typography>
                    {attributes?.map((attribute, idx) => <Attribute key={idx} attribute={attribute} />)}
                </div>
            </DialogContent>
        </Fragment>
    );
}
