import * as R from 'ramda';
import Checkbox from '../../Checkbox/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component} from 'react';
import {LookupType} from '../../../rdm-sdk/types.types';
import {connect} from 'react-redux';
import {fromNull} from '../../../core/maybe';
import {getChecked, noop} from '../../../core/util';
import {getTypeStats} from '../../../rdm-sdk/stats';
import {requestLookupTotalsByTypesSaga} from '../../../redux/actions/stats';
const ROWS = [
    {
        id: 'label',
        numeric: false,
        disablePadding: true,
        label: i18n.text('Lookup types')
    },
    {
        id: 'valuesCount',
        numeric: true,
        disablePadding: false,
        label: i18n.text('Source values')
    }
];
const getSelectableUris = R.pipe(R.filter(R.prop('valuesCount')), R.pluck('uri'));
type Props = {
    requestValuesCount: () => void;
    selectedTypeUris: string[];
    setSelectedTypes: (uris: string[]) => void;
    lookupTypes: Array<
        LookupType & {
            valuesCount: number;
        }
    >;
    needToLoadValuesCount: boolean;
};

class TypesTable extends Component<Props> {
    onSelectClick = R.curry((uri: string, isChecked: boolean) => {
        const {selectedTypeUris} = this.props;
        const index = selectedTypeUris.findIndex(R.equals(uri));
        this.props.setSelectedTypes(
            isChecked ? R.concat(selectedTypeUris, [uri]) : R.remove(index, 1, selectedTypeUris)
        );
    });
    onSelectAllClick = (isChecked: boolean) => {
        const {lookupTypes} = this.props;
        this.props.setSelectedTypes(isChecked ? getSelectableUris(lookupTypes) : []);
    };

    componentDidMount() {
        if (this.props.needToLoadValuesCount) {
            this.props.requestValuesCount();
        }
    }

    render() {
        const {lookupTypes, selectedTypeUris} = this.props;
        const selectedCount = selectedTypeUris.length;
        const selectableCount = getSelectableUris(lookupTypes).length;
        const typesCount = lookupTypes.length;
        return (
            <Paper elevation={2} className={styles.paper}>
                <Toolbar className={styles.toolbar}>
                    <div className={styles.header}>
                        <Typography variant="h6" className={styles.title}>
                            {i18n.text('Lookup Types')}
                        </Typography>
                        <Typography className={styles.summary}>
                            {typesCount > 0 &&
                                (typesCount > 1
                                    ? i18n.text('${count} types', {
                                          count: typesCount
                                      })
                                    : i18n.text('${count} type', {
                                          count: typesCount
                                      }))}
                        </Typography>
                    </div>
                    <Typography className={styles.hint}>
                        {i18n.text('You may select one or more lookup types to export')}
                    </Typography>
                </Toolbar>
                <Table className={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" className={styles['table-cell--checkbox']}>
                                <Checkbox
                                    indeterminate={selectedCount > 0 && selectedCount < selectableCount}
                                    checked={selectedCount > 0 && selectedCount === selectableCount}
                                    onChange={R.pipe(getChecked, this.onSelectAllClick)}
                                />
                            </TableCell>
                            {ROWS.map((row) => {
                                return (
                                    <TableCell
                                        key={row.id}
                                        align={row.numeric ? 'right' : 'inherit'}
                                        padding={row.disablePadding ? 'none' : undefined}
                                    >
                                        {row.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lookupTypes.map(({uri, label, valuesCount}) => {
                            const isSelected = selectedTypeUris.includes(uri);
                            const isEmpty = valuesCount === 0;
                            return (
                                <Tooltip
                                    key={uri}
                                    title={isEmpty ? i18n.text('This type has no source values to export') : ''}
                                >
                                    <TableRow
                                        hover={!isEmpty}
                                        className={clsx(styles['table-row'], isEmpty && styles['table-row--empty'])}
                                        classes={{
                                            hover: styles['table-row--hover']
                                        }}
                                        onClick={
                                            isEmpty
                                                ? noop
                                                : R.pipe(R.always(isSelected), R.not, this.onSelectClick(uri))
                                        }
                                    >
                                        <TableCell padding="checkbox" className={styles['table-cell--checkbox']}>
                                            <Checkbox disabled={isEmpty} checked={isSelected} />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none">
                                            {label}
                                        </TableCell>
                                        <TableCell align="right" className={styles['table-cell--numeric']}>
                                            {fromNull(valuesCount).orSome('-')}
                                        </TableCell>
                                    </TableRow>
                                </Tooltip>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

const mapStateToProps = ({configuration: {lookupTypes}, stats: {valuesByTypes}}) => ({
    lookupTypes: lookupTypes.map((type) =>
        Object.assign({}, type, {
            valuesCount: getTypeStats(type, valuesByTypes)
        })
    ),
    needToLoadValuesCount: R.isEmpty(valuesByTypes)
});

const mapDispatchToProps = (dispatch) => ({
    requestValuesCount: R.pipe(R.T, requestLookupTotalsByTypesSaga(dispatch))
});

export default connect(mapStateToProps, mapDispatchToProps)(TypesTable);
