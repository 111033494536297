import CustomIcon from '../../CustomIcon/CustomIcon';
import Input from '@mui/material/Input';
import InputSuggestor from '../../InputSuggestor/InputSuggestor';
import debounce from 'debounce';
import styles from './discussion-input.less';
import {PureComponent} from 'react';
import {User} from '../../../rdm-sdk/app.types';
type Props = {
    value: string;
    onEnter: (arg0: string) => any;
    users: User[];
    placeholder: string;
    inputRef: (ref?: HTMLTextAreaElement | null) => void;
};

class DiscussionInput extends PureComponent<Props> {
    static defaultProps = {
        placeholder: ''
    };
    onEnterDebounced = debounce(this.props.onEnter, 200);

    render() {
        const {value, users, placeholder, inputRef} = this.props;
        return (
            <div className={styles['discussion-input__container']}>
                <div className={styles['discussion-input__avatar']}>
                    <CustomIcon name="face" />
                </div>
                <InputSuggestor value={value} users={users}>
                    {({value, textareaRef, onInputChange, resetValue, showSuggestor}) => (
                        <Input
                            disableUnderline
                            className={styles['discussion-input']}
                            inputRef={(ref) => {
                                textareaRef(ref);
                                inputRef(ref);
                            }}
                            type="text"
                            multiline={true}
                            maxRows={8}
                            value={value}
                            onChange={onInputChange}
                            inputProps={{
                                onKeyDown: (event) => {
                                    const {key, ctrlKey} = event;
                                    if (key === 'Enter') event.preventDefault();

                                    if (key === 'Enter' && ctrlKey) {
                                        onInputChange({
                                            target: {
                                                value: value + '\n'
                                            }
                                        });
                                        return;
                                    }

                                    if (key === 'Enter' && !showSuggestor && value) {
                                        this.onEnterDebounced(value);
                                        resetValue();
                                    }
                                }
                            }}
                            placeholder={placeholder}
                        />
                    )}
                </InputSuggestor>
            </div>
        );
    }
}

export default DiscussionInput;
