import * as R from 'ramda';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import CustomDragLayer from '../CustomDragLayer/CustomDragLayer';
import CustomSnackbar from '../CustomSnackbar/CustomSnackbar';
import Drawer from './Drawer';
import ErrorPopup from 'react-components/dist/ErrorPopup/ErrorPopup';
import Header from './Header';
import LoaderIndicator from '../LoaderIndicator/LoaderIndicator';
import clsx from 'clsx';
import styles from './app-bar.less';
import {AppError} from '../../rdm-sdk/app.types';
import {Component, ReactElement} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {StatusLine} from './StatusLine';
import {connect} from 'react-redux';

type Props = {
    drawerVisible: boolean;
    children: ReactElement;
    tenant?: string | null;
    location: Location;
    errors: AppError[];
};
export class App extends Component<Props> {
    render() {
        const {drawerVisible, children, tenant, location} = this.props;
        return (
            <DndProvider backend={HTML5Backend}>
                <div className={styles.layout}>
                    <div className={styles['layout__inner-container']}>
                        <Header location={location} />

                        <div className={styles['app-bar__container']}>
                            <Drawer tenant={tenant} open={drawerVisible} location={location} />
                            <div className={styles['app-bar__main']}>
                                <StatusLine />
                                <div
                                    className={clsx(
                                        styles.content,
                                        drawerVisible ? 'content__with-drawer' : 'content__without-drawer'
                                    )}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                        <CustomDragLayer />
                        <ConfirmationDialog />
                        <ErrorPopup
                            className={{
                                [styles.errorBlock]: true
                            }}
                        />
                        <CustomSnackbar />
                    </div>
                </div>
                <LoaderIndicator />
            </DndProvider>
        );
    }
}
const mapStateToProps = R.pick(['drawerVisible', 'tenant', 'errors']);
export default connect(mapStateToProps)(App);
