import CommentContent from '../CommentContent/CommentContent';
import CustomIcon from '../CustomIcon/CustomIcon';
import i18n from 'ui-i18n';
import styles from './discussion-reply.less';
import {PureComponent} from 'react';
import {Reply} from '../../rdm-sdk/collaboration.types';
import {formatCommentTime} from '../../rdm-sdk/utils';
type Props = {
    reply: Reply;
};

class DiscussionReply extends PureComponent<Props> {
    render() {
        const {reply} = this.props;
        const isReplyResolved = reply.action === 'resolve';
        const isReplyReopened = reply.action === 'reopen';
        return (
            <div className={styles['discussion-reply__container']}>
                <div className={styles['discussion-reply__avatar']}>
                    <CustomIcon name="face" />
                </div>

                <div className={styles['discussion-reply']}>
                    <p className={styles['discussion-reply__info']}>
                        <span title={reply.createdBy} className={styles['discussion-reply__username']}>
                            {reply.createdBy}
                        </span>
                        {isReplyResolved && (
                            <span
                                className={styles['discussion-reply__action-info']}
                                title={i18n.text('Marked as resolved')}
                            >
                                {i18n.text('Marked as resolved')}
                            </span>
                        )}
                        {isReplyReopened && (
                            <span className={styles['discussion-reply__action-info']} title={i18n.text('Re-opened')}>
                                {i18n.text('Re-opened')}
                            </span>
                        )}
                    </p>

                    <CommentContent className={styles['discussion-reply__content']}>{reply.content}</CommentContent>

                    <p className={styles['discussion-reply__time']}>{formatCommentTime(reply.createdTime)}</p>
                </div>
            </div>
        );
    }
}

export default DiscussionReply;
