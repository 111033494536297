import * as R from 'ramda';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputSuggestor from '../InputSuggestor/InputSuggestor';
import styles from './component-dialog-input.less';
import {PureComponent} from 'react';
import {User} from '../../rdm-sdk/app.types';
import {then} from '../../core/func';
type Props = {
    value: string;
    onSendClick: (arg0: string) => Promise<any>;
    onChange: (event: Record<string, any>) => void;
    users: User[];
    exitEditing?: () => void;
    inputRef?: (arg0?: HTMLTextAreaElement | null) => void;
};

class CommentDialogInput extends PureComponent<Props> {
    static defaultProps = {
        onChange: () => {},
        exitEditing: () => {}
    };

    render() {
        const {value, onSendClick, onChange, users, exitEditing, inputRef} = this.props;
        return (
            <InputSuggestor value={value} onChange={onChange} users={users}>
                {({value, textareaRef, onInputChange, resetValue}) => (
                    <Input
                        autoFocus
                        disableUnderline
                        className={styles['comment-dialog-input']}
                        inputRef={(ref) => {
                            textareaRef(ref);
                            inputRef && inputRef(ref);
                        }}
                        type="text"
                        multiline={true}
                        maxRows={8}
                        value={value}
                        onChange={onInputChange}
                        endAdornment={
                            <InputAdornment position="end" className={styles['comment-dialog-input__send-button']}>
                                <IconButton
                                    disabled={!value}
                                    onClick={R.pipe(
                                        R.always(value),
                                        onSendClick,
                                        then(R.pipe(resetValue, exitEditing))
                                    )}
                                    size="large"
                                >
                                    <Icon>send</Icon>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                )}
            </InputSuggestor>
        );
    }
}

export default CommentDialogInput;
