import AddTypeDialog from '../../components/AddTypeDialog/AddTypeDialog';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MappingGrid from '../../components/Mapping/MappingGrid';
import ReactDOM from 'react-dom';
import i18n from 'ui-i18n';
import {Component} from 'react';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {drawerVisibilityEvent, submenuEvent, titleEvent} from '../../redux/actions/app';
import {initialQuery} from '../../components/Search/initialSearchQuery';
import {noop} from '../../core/util';
import {tenantChangedEvent} from '../../redux/actions/tenants';

type DispatchProps = {
    dispatch: (e: StateEvent) => Promise<any>;
};

type Props = DispatchProps & {
    location: Location;
    params: {
        tenantId: string;
    };
};
export class ValuesPageNewTypeMock extends Component<Props> {
    componentDidMount() {
        const {params, dispatch, location} = this.props;
        const {tenantId} = params;
        dispatch(tenantChangedEvent(tenantId));
        dispatch(titleEvent(i18n.text('New lookup type')));
        dispatch(
            submenuEvent([
                {
                    link: location.pathname,
                    label: i18n.text('Mapping')
                },
                {
                    link: 'Values',
                    label: i18n.text('Values')
                },
                {
                    link: 'Localization',
                    label: i18n.text('Localization')
                }
            ])
        );
        dispatch(drawerVisibilityEvent(false));
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(titleEvent(' '));
        dispatch(submenuEvent([]));
        dispatch(drawerVisibilityEvent(true));
    }

    render() {
        const headerButtonsContainer = document.getElementById('header__buttons-container');
        const editTypeButton = headerButtonsContainer
            ? ReactDOM.createPortal(
                  <IconButton color="inherit" size="large">
                      <Icon>settings</Icon>
                  </IconButton>,
                  headerButtonsContainer
              )
            : false;
        return (
            <ErrorBoundary>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1 1 auto',
                        overflow: 'hidden'
                    }}
                >
                    <MappingGrid
                        loadMoreMapped={noop}
                        loadMoreUnmapped={noop}
                        onAddCanonicalClick={noop}
                        onAddCanonicalDrop={noop}
                        dialogSelectOptions={[]}
                        onDialogSelectInput={noop}
                        getDialogSelectOptions={noop}
                        onCanonicalValueSort={noop}
                        query={initialQuery}
                        sources={[]}
                        extraSources={[]}
                        hiddenColumns={[]}
                        changeColumnOrder={noop}
                        onColumnAdd={noop}
                        onColumnHide={noop}
                        onColumnShow={noop}
                        onColumnResize={noop}
                        showValues={false}
                    />
                    {editTypeButton}
                    <AddTypeDialog />
                </div>
            </ErrorBoundary>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

export default connect(null, mapDispatchToProps)(ValuesPageNewTypeMock);
