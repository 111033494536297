import * as R from 'ramda';
import * as React from 'react';
import ClickOutsideHOC from '../../ClickOutsideHOC/ClickOutsideHOC';
import Icon from '@mui/material/Icon';
import MarkLabel from '../../MarkLabel/MarkLabel';
import NodeIcon from './NodeIcon/NodeIcon';
import ParentsList from './ParentsList/ParentsList';
import SearchWordsHighlighter from '../../SearchWordsHighlighter/SearchWordsHighlighter';
import actionCellRenderer from '../../TypesTable/actionCellRenderer';
import clsx from 'clsx';
import shallowCompare from 'react-addons-shallow-compare';
import styles from './styles.less';
import unmappedCountCellRenderer from '../../CustomTable/unmappedCountCellRenderer';
import {Command} from '../../../redux/middlewares/command-middleware';
import {Component} from 'react';
import {Dispatch} from 'redux';
import {LookupType} from '../../../rdm-sdk/types.types';
import {RelationsNode, TreeNode, TreePath} from '../../../rdm-sdk/hierarchy.types';
import {TypesSearchQuery} from '../../../rdm-sdk/filters.types';
import {checkRemoved} from '../../../core/marks';
import {connect} from 'react-redux';
import {noop} from '../../../core/util';

type StateProps = {
    isEditMode: boolean;
    canOpenValues: boolean;
    dispatch: Dispatch;
};

type Props = StateProps & {
    key: string;
    style: Record<string, any>;
    path: TreePath;
    data: TreeNode;
    type: LookupType;
    toggleExpanded: (path: TreePath) => void;
    openTypeDialog: (type: LookupType) => void;
    isMatched: boolean;
    isSelectedMatch: boolean;
    isSelected: boolean;
    onLinkClick: (path: TreePath) => void;
    resetSelected: () => void;
    parents: RelationsNode[];
    toggleShowParents: (path: TreePath) => void;
    onParentLinkClick: (type: string) => void;
    unmappedCount: number;
    typesSearchQuery: TypesSearchQuery;
    logger: (action: string) => (payload: any) => Command;
    openValues: (type: LookupType) => void;
};

const ScaffoldBlock = ({isLast, hasLowerSiblings}: {isLast?: boolean; hasLowerSiblings?: boolean}) => (
    <div
        className={clsx(
            styles['hierarchy-row__scaffold'],
            isLast && styles['hierarchy-row__scaffold--hor'],
            isLast && styles['hierarchy-row__scaffold--half-ver'],
            hasLowerSiblings && styles['hierarchy-row__scaffold--full-ver']
        )}
    />
);

const transformProps = R.evolve({
    path: R.toString
});
const customShallowCompare = R.useWith(shallowCompare, [
    R.evolve({
        props: transformProps
    }),
    transformProps
]);
export class TypeHierarchyRow extends Component<Props> {
    toggleExpanded: React.MouseEventHandler<HTMLDivElement> = (e) => {
        const {
            dispatch,
            logger,
            type,
            path,
            data: {expanded},
            toggleExpanded
        } = this.props;
        e.stopPropagation();
        R.pipe(
            logger('expand-toggle-click'),
            dispatch
        )({
            type,
            show: !expanded
        });
        toggleExpanded(path);
    };
    onLinkClick: React.MouseEventHandler<HTMLElement> = (e) => {
        const {dispatch, logger, path, type, onLinkClick} = this.props;
        e.stopPropagation();
        R.pipe(logger('link-click'), dispatch)(type);
        onLinkClick(path);
    };
    openTypeDialog = () => {
        const {dispatch, logger, type, openTypeDialog} = this.props;
        R.pipe(logger('edit-button-click'), dispatch)(type);
        openTypeDialog(type);
    };
    toggleShowParents: React.MouseEventHandler<HTMLElement> = (e) => {
        const {
            dispatch,
            logger,
            type,
            path,
            data: {showParents},
            toggleShowParents
        } = this.props;
        e.stopPropagation();
        R.pipe(
            logger('parents-toggle-click'),
            dispatch
        )({
            type,
            show: !showParents
        });
        toggleShowParents(path);
    };
    onParentLinkClick = (parent: RelationsNode) => {
        const {dispatch, logger, type, onParentLinkClick} = this.props;
        R.pipe(
            logger('parent-link-click'),
            dispatch
        )({
            type,
            parent
        });
        onParentLinkClick(parent);
    };
    openValues = () => {
        const {dispatch, logger, type, openValues} = this.props;
        R.pipe(logger('row-click'), dispatch)(type);
        openValues(type);
    };

    shouldComponentUpdate(nextProps: Props) {
        return customShallowCompare(this, nextProps, null);
    }

    getRowClassname() {
        const {type, isMatched, isSelected, isSelectedMatch, canOpenValues} = this.props;

        const isRemoved = checkRemoved(type);
        const isRowClickable = canOpenValues && !isRemoved;
        const isDisabled = !type.enabled;
        return clsx(
            styles['hierarchy-row'],
            isMatched && !isSelectedMatch && styles['hierarchy-row--yellow-frame'],
            (isSelected || isSelectedMatch) && styles['hierarchy-row--blue-frame'],
            isRemoved && styles['hierarchy-row--line-through'],
            isRowClickable && styles['hierarchy-row--clickable'],
            (isRemoved || isDisabled) && styles['hierarchy-row--grayed-out']
        );
    }

    render() {
        const {
            key,
            style,
            data: {children, expanded, isLink, showParents, lowerSiblingsCount},
            type,
            isEditMode,
            unmappedCount,
            isMatched,
            isSelected,
            resetSelected,
            parents,
            typesSearchQuery,
            canOpenValues
        } = this.props;
        const childrenCount = children.length;
        const isParent = childrenCount > 0;
        const isMultiParent = parents && parents.length > 1;
        const isRemoved = checkRemoved(type);
        const isRowClickable = canOpenValues && !isRemoved;
        const expandButton = isParent ? (
            <div className={styles['hierarchy-row__button-expand']} onClick={this.toggleExpanded}>
                <Icon>{expanded ? 'arrow_drop_down' : 'arrow_right'}</Icon>
            </div>
        ) : null;
        const childrenCounter = isParent ? (
            <span className={styles['hierarchy-row__children-count']}>{childrenCount}</span>
        ) : null;
        const editButton = actionCellRenderer({
            rowData: type,
            columnData: {
                isEditMode,
                selected: [],
                rowAction: this.openTypeDialog
            }
        });
        const unmappedCounter =
            unmappedCount > 0 ? (
                <div className={styles['hierarchy-row__unmapped-count']}>
                    {unmappedCountCellRenderer({
                        cellData: unmappedCount,
                        rowData: type
                    })}
                </div>
            ) : null;
        const scaffold = lowerSiblingsCount?.map((count, index) => (
            <ScaffoldBlock key={index} isLast={index === lowerSiblingsCount.length - 1} hasLowerSiblings={count > 0} />
        ));
        return (
            <div
                key={key}
                style={{...style, width: 'auto', minWidth: '100%'}}
                className={styles['hierarchy-row__container']}
            >
                <ClickOutsideHOC onClickOutside={isSelected ? resetSelected : noop}>
                    <div
                        role="row"
                        className={this.getRowClassname()}
                        onClick={isRowClickable ? this.openValues : noop}
                    >
                        {scaffold}
                        {expandButton}
                        <NodeIcon
                            isParent={isParent}
                            isLink={isLink}
                            isMultiParent={isMultiParent}
                            showParents={showParents}
                            onLinkClick={this.onLinkClick}
                            toggleShowParents={this.toggleShowParents}
                        />
                        <MarkLabel entity={type} className={styles['hierarchy-row__marker-label']} />
                        <SearchWordsHighlighter
                            style={{
                                textDecoration: isRemoved ? 'line-through' : 'initial'
                            }}
                            value={type.label}
                            searchQuery={typesSearchQuery}
                            searchFields={['name', 'hasWords']}
                            enabled={isMatched}
                        />
                        {childrenCounter}
                        <div className={'spacer'} />
                        {unmappedCounter}
                        {editButton}
                    </div>
                </ClickOutsideHOC>
                {showParents && (
                    <div className={styles['hierarchy-row__parents-row']}>
                        {R.init(scaffold).concat(
                            <ScaffoldBlock
                                key={scaffold.length - 1}
                                hasLowerSiblings={lowerSiblingsCount[scaffold.length - 1] > 0}
                            />
                        )}
                        <ParentsList parents={parents} onParentClick={this.onParentLinkClick} />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) =>
    ({
        isEditMode: state.policies.configEdit,
        canOpenValues: state.policies.lookupValuesRead
    }) as StateProps;

export default connect(mapStateToProps)(TypeHierarchyRow);
