import * as R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '../../Checkbox/Checkbox';
import CustomMultiSelect from '../../CustomMultiSelect/CustomMultiSelect';
import DatePicker from '../../DatePicker/DatePicker';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from '../style.less';
import {Component} from 'react';
import {Configuration} from '../../../rdm-sdk/configuration.types';
import {Maybe} from 'monet';
import {ValuesSearchQuery} from '../../../rdm-sdk/filters.types';
import {connect} from 'react-redux';
import {fromString} from '../../../core/maybe';
import {getChecked, getValue} from '../../../core/util';
import {pipe} from '../../../core/func';

const sourceToOptions = ({label, uri}) => ({
    value: uri,
    label
});

const getTypeString = ({type, negate}) => (negate ? `!${type}` : type);

type ChangeEvent = {
    value: string | number | Date;
    type: string;
};

type StateProps = {
    configuration: Configuration;
};

type Props = StateProps & {
    onChange: (e: ChangeEvent) => void;
    query: ValuesSearchQuery;
    onSearch: (query: ValuesSearchQuery) => void;
    onReset: () => void;
};
const OPTIONS = [
    {
        label: i18n.text('Contains'),
        value: 'contains'
    },
    {
        label: i18n.text('Does not contain'),
        value: '!contains'
    }
];
const OPTIONS_EQUALS = [
    {
        label: i18n.text('Equals'),
        value: 'equals'
    },
    {
        label: i18n.text('Does not equal'),
        value: '!equals'
    }
];
export class ValuesSearchDetails extends Component<Props> {
    next = (type: string) => (value: string | number | Date) => {
        this.props.onChange({
            value,
            type
        });
    };

    render() {
        const {
            onSearch,
            onReset,
            query,
            configuration: {sources}
        } = this.props;
        const sourceOptions = sources.map(sourceToOptions);

        const getSourceLabel = (uri) => R.pipe(R.find(R.propEq('value', uri)), R.propOr(uri, 'label'))(sourceOptions);

        return (
            <div className={`${styles.detailsForm}`}>
                <div className={`${styles.detailsFormRow}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Has the words')}</div>
                    <TextField
                        variant="standard"
                        id="searchHasWordsTextField"
                        onChange={pipe(getValue, this.next('hasWords'))}
                        value={fromString(query.hasWords).orSome('')}
                        fullWidth
                    />
                </div>
                <div className={`${styles.detailsFormRow} ${styles.detailsFormRow__sourceSystem}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Source system')}</div>
                    <FormControl
                        variant="standard"
                        className={clsx(styles['form-control'], styles['form-control__sources'])}
                    >
                        <CustomMultiSelect
                            id="values-search-details-sources-select"
                            className={styles['select-sources']}
                            value={query.sources}
                            onSelect={this.next('sources')}
                            placeholder={i18n.text('All sources')}
                            input={
                                <Input
                                    classes={{
                                        input: styles['select-input']
                                    }}
                                />
                            }
                            options={sourceOptions}
                            getChipLabel={getSourceLabel}
                        />
                    </FormControl>
                </div>
                <div className={`${styles.detailsFormRow}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Canonical value')}</div>
                    <FormControl variant="standard" className={styles['form-control']}>
                        <Select
                            variant="standard"
                            id="values-search-details-canonical-value-select"
                            value={getTypeString(query.canonicalValue)}
                            onChange={R.pipe(getValue, this.next('canonicalValueType'))}
                            input={
                                <Input
                                    classes={{
                                        input: styles['select-input']
                                    }}
                                />
                            }
                        >
                            {OPTIONS.map((option) => (
                                <MenuItem dense key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        variant="standard"
                        id="searchAllValuesTextField"
                        name="all_canonical_values"
                        value={Maybe.fromNull(query.canonicalValue.value).orSome('')}
                        onChange={pipe(getValue, this.next('canonicalValueValue'))}
                        placeholder={i18n.text('all values')}
                    />
                </div>
                <div className={`${styles.detailsFormRow}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Source value')}</div>
                    <FormControl variant="standard" className={styles['form-control']}>
                        <Select
                            variant="standard"
                            id="values-search-details-value-select"
                            value={getTypeString(query.value)}
                            onChange={R.pipe(getValue, this.next('valueType'))}
                            input={
                                <Input
                                    classes={{
                                        input: styles['select-input']
                                    }}
                                />
                            }
                        >
                            {OPTIONS.map((option) => (
                                <MenuItem dense key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        variant="standard"
                        id="searchAllValuesTextField"
                        name="all_values"
                        value={Maybe.fromNull(query.value.value).orSome('')}
                        onChange={pipe(getValue, this.next('valueValue'))}
                        placeholder={i18n.text('all values')}
                    />
                </div>
                <div className={`${styles.detailsFormRow}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Source code')}</div>
                    <FormControl variant="standard" className={styles['form-control']}>
                        <Select
                            variant="standard"
                            id="values-search-details-code-select"
                            value={getTypeString(query.code)}
                            onChange={R.pipe(getValue, this.next('codeType'))}
                            input={
                                <Input
                                    classes={{
                                        input: styles['select-input']
                                    }}
                                />
                            }
                        >
                            {OPTIONS_EQUALS.map((option) => (
                                <MenuItem dense key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        variant="standard"
                        id="searchAllCodesTextField"
                        name="all_codes"
                        value={Maybe.fromNull(query.code.value).orSome('')}
                        onChange={pipe(getValue, this.next('codeValue'))}
                        placeholder={i18n.text('all codes')}
                    />
                </div>
                <div className={styles['has-missing']}>
                    <Checkbox
                        id="searchShowDisabledCheckbox"
                        className={styles.showDisabledCheckBox}
                        label={i18n.text('Has missing reference values in a cell block')}
                        onChange={pipe(getChecked, this.next('hasMissingValues'))}
                        checked={query.hasMissingValues}
                    />
                </div>
                <div>
                    <div className={styles.pickerWrapper}>
                        <DatePicker
                            label={i18n.text('Start date')}
                            id="searchStartDatePicker"
                            // @ts-ignore
                            value={Maybe.fromNull(query.startDate).orSome(null)}
                            onSelect={this.next('startDate')}
                        />
                    </div>
                    <div className={styles.pickerWrapper}>
                        <DatePicker
                            label={i18n.text('Expiration date')}
                            id="searchEndDatePicker"
                            // @ts-ignore
                            value={Maybe.fromNull(query.endDate).orSome(null)}
                            onSelect={this.next('endDate')}
                        />
                    </div>
                </div>
                <div className={styles.detailsButtonRow}>
                    <Button variant="grey" onClick={onReset}>
                        {i18n.text('Clear filter')}
                    </Button>
                    <Button
                        color="primary"
                        style={{
                            float: 'right'
                        }}
                        onClick={() => onSearch(query)}
                    >
                        {i18n.text('Search')}
                    </Button>
                </div>
            </div>
        );
    }
}
const mapStateToProps: (state) => StateProps = R.pick(['configuration']);
export default connect(mapStateToProps)(ValuesSearchDetails);
