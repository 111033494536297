import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
type Props = {
    open: boolean;
    className: string;
    onClose: () => void;
    onOpen: () => void;
};

function CollapseButton(props: Props) {
    const {open, className, onClose, onOpen} = props;
    return open ? (
        <IconButton onClick={onClose} className={className} size="large">
            <KeyboardArrowUpIcon />
        </IconButton>
    ) : (
        <IconButton onClick={onOpen} className={className} size="large">
            <KeyboardArrowDownIcon />
        </IconButton>
    );
}

export default CollapseButton;
