import {UAParser} from 'ua-parser-js';
import {fromNull} from './maybe';

const getNameAndVersionStr = ({name, version}) => `${name} ${version}`;

const getResolutionStr = ({width, height}) => `${width}x${height}`;

export const getDeviceInfo = () => {
    const uaParser = new UAParser();
    return {
        os: getNameAndVersionStr(uaParser.getOS()),
        browser: getNameAndVersionStr(uaParser.getBrowser()),
        screenResolution: fromNull(screen).map(getResolutionStr).orSome('N/A'),
        windowSize: `${innerWidth}x${innerHeight}`
    };
};
