import * as R from 'ramda';
import ClickOutsideHOC from '../../ClickOutsideHOC/ClickOutsideHOC';
import FeatureFilter from '../../Feature/FeatureFilter';
import GridMenu from '../../GridMenu/GridMenu';
import MarkLabel from '../../MarkLabel/MarkLabel';
import MenuItem from '../../GridMenu/GridMenuItem';
import Popover from '@mui/material/Popover';
import SourceValue from '../SourceValue/SourceValue';
import TextField from '../../TextField/TextField';
import clsx from 'clsx';
import gridStyles from '../grid.less';
import i18n from 'ui-i18n';
import styles from './mapping-cell.less';
import {CheckedPolicies} from '../../../permissions/permissions.types';
import {FEATURE} from '../../../rdm-sdk/features';
import {LookupValue, SourceMapping} from '../../../rdm-sdk/lookups.types';
import {MappingIdentifier, SelectedMappingMode} from '../../../rdm-sdk/grid.types';
import {OnDetailsOpen, RemoveSourceMapping, UpdateSourceMapping} from '../mappingGrid.types';
import {PureComponent, Ref} from 'react';
import {SearchHighlightMapping} from '../searchHelpers';
import {Source} from '../../../rdm-sdk/sources.types';
import {ValuesSearchQuery} from '../../../rdm-sdk/filters.types';
import {checkClientOnly, checkEmpty, checkErroneous, checkRemoved, hasChanges} from '../../../core/marks';
import {connect} from 'react-redux';
import {getMappingIdentifier} from '../../../rdm-sdk/grid';
import {getValue, noop} from '../../../core/util';
import {logActivityCommand} from '../../../redux/actions/activityLogging';
import {matchSearchQuery} from '../searchHelpers';
const mappingIsNewAndEmpty = R.allPass([
    checkEmpty,
    R.pipe(R.prop('code'), R.isEmpty),
    R.pipe(R.prop('value'), R.isEmpty)
]);

const Triangle = () => (
    <svg height="10" width="10" className={styles.corner}>
        <g>
            <title>Canonical value for this row</title>
            <polygon
                id="sdfsfd"
                points="0,0 10,0 0,10"
                style={{
                    fill: '#2B98F0',
                    stroke: 'purple',
                    strokeWidth: 0
                }}
            />
        </g>
    </svg>
);

type Props = {
    mapping: SourceMapping;
    lookupValue: LookupValue;
    mappingIndex: number;
    onDetailsOpen: OnDetailsOpen;
    updateSourceMapping: UpdateSourceMapping;
    removeSourceMapping: RemoveSourceMapping;
    searchQuery: ValuesSearchQuery;
    searchMapping: SearchHighlightMapping;
    source: Source;
    columnWidth: number;
    policies: CheckedPolicies;
    isColumnSelected: boolean;
    isEditorCell: boolean;
    isFocusCell: boolean;
    openCommentDialog: () => void;
    hasComment: boolean;
    isCommentDialogOpen: boolean;
    setEditorCell: (arg0: MappingIdentifier) => void;
    setFocusCell: (arg0: MappingIdentifier) => void;
    resetSelectedMapping: () => void;
    changeSelectedMappingMode: (mode: SelectedMappingMode) => void;
    logKeyDown: (payload: {key: string}) => void;
    logCommentAdd: (payload: {source: Source; lookupValue: LookupValue}) => void;
    isEditMode: boolean;
    isDCRDisplayed: boolean;
};
export type State = {
    mapping: SourceMapping;
    canPopulate: boolean;
    propsToTrack: {
        mapping?: SourceMapping | null;
    };
};
export class MappingCell extends PureComponent<Props, State> {
    cell?: HTMLDivElement | null;
    valueInputRef: Ref<HTMLInputElement>;
    codeInputRef: Ref<HTMLInputElement>;
    valueInput?: HTMLInputElement | null;
    codeInput?: HTMLInputElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            mapping: props.mapping,
            canPopulate: !props.mapping.code,
            propsToTrack: {
                mapping: props.mapping
            }
        };

        this.valueInputRef = (ref) => (this.valueInput = ref);

        this.codeInputRef = (ref) => (this.codeInput = ref);
    }

    open = (event: Record<string, any>) => {
        const {setEditorCell, setFocusCell, lookupValue, source, mappingIndex} = this.props;
        const identifier = getMappingIdentifier(lookupValue.code, source.abbreviation, mappingIndex);

        if (event.ctrlKey) {
            setFocusCell(identifier);
        } else {
            setEditorCell(identifier);
        }
    };
    save = () => {
        const {
            mapping: initialMapping,
            mappingIndex,
            source,
            lookupValue,
            updateSourceMapping,
            changeSelectedMappingMode
        } = this.props;
        const {mapping} = this.state;
        changeSelectedMappingMode(SelectedMappingMode.FOCUS);

        if (!R.equals(mapping, initialMapping)) {
            updateSourceMapping(source.abbreviation, mappingIndex, lookupValue, mapping);
        }
    };
    cancel = () => {
        this.props.changeSelectedMappingMode(SelectedMappingMode.FOCUS);
        this.setState({
            mapping: this.props.mapping
        });
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const {mapping: nextMapping} = nextProps;
        const {
            propsToTrack: {mapping: prevMapping}
        } = prevState;

        if (!R.equals(nextMapping, prevMapping)) {
            return {
                mapping: nextMapping,
                canPopulate: !nextMapping.code,
                propsToTrack: {
                    mapping: nextMapping
                }
            };
        } else {
            return null;
        }
    }

    disableCodePopulation = () =>
        this.setState({
            canPopulate: false
        });
    updateMapping = R.curry(
        (key: string, value: string | boolean | null) =>
            new Promise((resolve) =>
                this.setState(
                    R.evolve({
                        mapping: R.assoc(key, value)
                    }),
                    resolve as () => void
                )
            )
    );
    onSetDefaultDownstreamClick = () => {
        this.updateMapping('downStreamDefaultValue', true).then(this.save);
    };
    onSetCanonical = () => {
        this.updateMapping('canonicalValue', true).then(this.save);
    };
    populateCode = (value: string) =>
        this.setState(
            R.evolve({
                mapping: (mapping: SourceMapping) => {
                    return Object.assign({}, mapping, {
                        value,
                        code: R.pipe(R.toUpper, R.replace(/\s/g, '_'))(value)
                    });
                }
            })
        );
    remove = () => {
        const {removeSourceMapping, source, mappingIndex, lookupValue} = this.props;
        removeSourceMapping(source.abbreviation, mappingIndex, lookupValue);
    };
    removeIfNewAndEmpty = () => {
        const {mapping} = this.state;

        if (mappingIsNewAndEmpty(mapping)) {
            this.remove();
        }
    };
    onPopoverKeyDown = (event: Record<string, any>) => {
        switch (event.key) {
            case 'Enter':
                event.stopPropagation();
                this.save();
                this.removeIfNewAndEmpty();
                break;

            case 'Escape':
                this.cancel();
                break;
        }

        if (['Enter', 'Escape'].includes(event.key)) {
            this.props.logKeyDown({
                key: event.key
            });
        }
    };
    onTextFieldKeyDown = (event: Record<string, any>) => {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
            event.stopPropagation();
        }

        switch (event.key) {
            case 'Tab': {
                event.preventDefault();
                this.props.logKeyDown({
                    key: event.key
                });

                if (document.activeElement === this.valueInput) {
                    this.codeInput && this.codeInput.focus();
                } else {
                    this.valueInput && this.valueInput.focus();
                }
            }
        }
    };

    getGridMenu() {
        const {
            lookupValue,
            onDetailsOpen,
            mapping: initialMapping,
            searchMapping,
            source,
            mappingIndex,
            policies,
            isEditorCell,
            openCommentDialog,
            hasComment,
            isFocusCell,
            resetSelectedMapping,
            logCommentAdd,
            isEditMode
        } = this.props;
        const {mapping} = this.state;
        const isRemoved = checkRemoved(mapping);
        const isNew = !initialMapping;
        const isClientOnly = checkClientOnly(initialMapping);
        const isInsertCommentVisible = !hasComment && !isClientOnly && policies?.collaborationEdit;
        const onSettingsClick = R.ifElse(
            R.always(isNew),
            onDetailsOpen(source.abbreviation, null, lookupValue, null),
            onDetailsOpen(source.abbreviation, mappingIndex, lookupValue, searchMapping)
        );
        return (
            !isRemoved && (
                <GridMenu
                    stopPropagation
                    className={styles['mapping-cell__menu']}
                    iconClassName={styles['mapping-cell__editor-icon']}
                    tooltipTitle={i18n.text('Settings')}
                >
                    <MenuItem isVisible={isEditMode && !mapping.canonicalValue} onClick={this.onSetCanonical}>
                        {i18n.text('Set as default canonical value')}
                    </MenuItem>
                    <MenuItem
                        isVisible={isEditMode && !mapping.downStreamDefaultValue}
                        onClick={this.onSetDefaultDownstreamClick}
                    >
                        {i18n.text('Set as default source system value')}
                    </MenuItem>
                    <MenuItem onClick={R.pipe(onSettingsClick, this.save)}>
                        {isEditMode ? i18n.text('Edit settings') : i18n.text('View settings')}
                    </MenuItem>
                    <FeatureFilter required={[FEATURE.COLLABORATION]}>
                        {
                            // @ts-ignore
                            ({onClick}) => (
                                <MenuItem
                                    isVisible={isInsertCommentVisible}
                                    onClick={R.pipe(
                                        onClick,
                                        this.save,
                                        openCommentDialog,
                                        R.always({
                                            lookupValue,
                                            source
                                        }),
                                        logCommentAdd
                                    )}
                                >
                                    {i18n.text('Insert comment')}
                                </MenuItem>
                            )
                        }
                    </FeatureFilter>
                    <MenuItem
                        isVisible={isEditMode && !mapping.canonicalValue}
                        onClick={R.pipe(
                            this.save,
                            this.remove,
                            R.when(R.always(isFocusCell || isEditorCell), resetSelectedMapping)
                        )}
                    >
                        {i18n.text('Delete')}
                    </MenuItem>
                </GridMenu>
            )
        );
    }

    render() {
        const {
            mapping: initialMapping,
            searchQuery,
            searchMapping,
            source,
            columnWidth,
            isColumnSelected,
            isEditorCell,
            isCommentDialogOpen,
            isFocusCell,
            isEditMode,
            isDCRDisplayed
        } = this.props;
        const {mapping, canPopulate} = this.state;
        const isClientOnly = checkClientOnly(initialMapping);
        const isNew = !initialMapping;
        const isRemoved = checkRemoved(mapping);

        const getMatchedSearchQuery = () =>
            searchMapping &&
            matchSearchQuery(searchQuery, searchMapping, source.abbreviation, mapping.value, mapping.code);
        const matchedSearchQuery = getMatchedSearchQuery();
        const getIsHighLighted = () => !isNew && !isClientOnly && matchedSearchQuery;
        const isHighlighted = getIsHighLighted();
        const gridMenu = this.getGridMenu();
        const needHightlightChange = R.both(R.always(isDCRDisplayed), R.always(hasChanges(mapping)))();
        return (
            <div>
                <div
                    ref={(cell) => (this.cell = cell)}
                    className={clsx(
                        styles['mapping-cell'],
                        checkErroneous(mapping) && gridStyles['mapping-grid__error'],
                        isColumnSelected && styles['mapping-cell--selected-column'],
                        isCommentDialogOpen && styles['mapping-cell--comment-open'],
                        isFocusCell && styles['mapping-cell--focused'],
                        needHightlightChange && styles['mapping-cell--dcr-marked']
                    )}
                    onClick={isRemoved ? noop : this.open}
                    style={
                        isRemoved
                            ? {
                                  cursor: 'default'
                              }
                            : undefined
                    }
                >
                    {mapping.canonicalValue && <Triangle />}
                    {<MarkLabel entity={mapping} position="right" className={styles['mapping-cell__label']} />}
                    <SourceValue
                        isRemoved={isRemoved}
                        value={mapping.value}
                        highlightCell={isHighlighted}
                        searchQuery={searchQuery}
                    />
                    {gridMenu}
                    <div
                        className={clsx(
                            styles['mapping-cell__ellipsis'],
                            checkErroneous(mapping) && gridStyles['mapping-grid__ellipsis--error'],
                            isColumnSelected && styles['mapping-cell__ellipsis--selected'],
                            isCommentDialogOpen && styles['mapping-cell__ellipsis--comment-open'],
                            needHightlightChange && styles['mapping-cell__ellipsis--dcr']
                        )}
                    />
                </div>
                <Popover
                    className={styles['mapping-cell__popover']}
                    open={isEditorCell}
                    anchorEl={this.cell}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    marginThreshold={0}
                    PaperProps={{
                        classes: {
                            elevation8: styles['no-shadow']
                        }
                    }}
                    onKeyDown={this.onPopoverKeyDown}
                >
                    <ClickOutsideHOC onClickOutside={R.pipe(this.save, this.removeIfNewAndEmpty)}>
                        <div
                            className={styles['mapping-cell__editor-container']}
                            style={{
                                width: columnWidth
                            }}
                        >
                            <TextField
                                autoFocus
                                inputRef={this.valueInputRef}
                                readOnly={!isEditMode}
                                className={styles['mapping-cell__editor']}
                                InputProps={{
                                    className: styles['mapping-cell__editor-input']
                                }}
                                name="value"
                                placeholder={i18n.text('Value')}
                                onChange={R.pipe(
                                    getValue,
                                    canPopulate ? this.populateCode : this.updateMapping('value')
                                )}
                                value={mapping.value}
                                onKeyDown={this.onTextFieldKeyDown}
                            />
                            <TextField
                                readOnly={!isEditMode}
                                inputRef={this.codeInputRef}
                                className={styles['mapping-cell__editor']}
                                InputProps={{
                                    className: styles['mapping-cell__editor-input']
                                }}
                                name="code"
                                placeholder={i18n.text('Code')}
                                onChange={R.pipe(
                                    getValue,
                                    this.updateMapping('code'),
                                    canPopulate ? this.disableCodePopulation : noop
                                )}
                                value={mapping.code}
                                onKeyDown={this.onTextFieldKeyDown}
                            />
                        </div>
                    </ClickOutsideHOC>
                    {gridMenu}
                </Popover>
            </div>
        );
    }
}
const mapDispatchToProps = {
    logKeyDown: logActivityCommand('mapping-grid-editor')('keydown'),
    logCommentAdd: logActivityCommand('mapping-grid')('add-comment-click')
};
export default connect(null, mapDispatchToProps)(MappingCell);
