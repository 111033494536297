import Button from '@mui/material/Button';
import LookupTypesEmptyIcon from '../../assets/inline/lookupTypesEmpty.svg';
import i18n from 'ui-i18n';

const EmptyState = ({isEditMode, addTypeClb}: {isEditMode: boolean; addTypeClb: () => void}) => (
    <div className={'empty-state'}>
        <div
            className={'empty-state__icon'}
            dangerouslySetInnerHTML={{
                __html: LookupTypesEmptyIcon
            }}
        />
        <h3 className={'empty-state__header'}>{i18n.text('There are no lookup types for this tenant')}</h3>
        <p className={'empty-state__text'}>{i18n.text('Create a new lookup type to add canonical values')}</p>
        {isEditMode && (
            <Button color="primary" variant="contained" className={'empty-state__button'} onClick={addTypeClb}>
                {i18n.text('Create lookup type')}
            </Button>
        )}
    </div>
);

export default EmptyState;
