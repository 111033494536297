import i18n from 'ui-i18n';
import {LocalizationColumnItem} from '../../rdm-sdk/grid.types';
const languages = [
    {
        code: 'zh-CN',
        label: i18n.text('Chinese (Simplified)')
    },
    {
        code: 'zh-TW',
        label: i18n.text('Chinese (Traditional)')
    },
    {
        code: 'cs-CZ',
        label: i18n.text('Czech')
    },
    {
        code: 'en-GB',
        label: i18n.text('English (UK)')
    },
    {
        code: 'en-US',
        label: i18n.text('English (US)')
    },
    {
        code: 'fr-FR',
        label: i18n.text('French')
    },
    {
        code: 'de-DE',
        label: i18n.text('German')
    },
    {
        code: 'el-GR',
        label: i18n.text('Greek')
    },
    {
        code: 'hu-HU',
        label: i18n.text('Hungarian')
    },
    {
        code: 'it-IT',
        label: i18n.text('Italian')
    },
    {
        code: 'ja-JP',
        label: i18n.text('Japanese')
    },
    {
        code: 'ko-KR',
        label: i18n.text('Korean')
    },
    {
        code: 'pl-PL',
        label: i18n.text('Polish')
    },
    {
        code: 'pt-BR',
        label: i18n.text('Portuguese (Brazil)')
    },
    {
        code: 'pt-PT',
        label: i18n.text('Portuguese (Portugal)')
    },
    {
        code: 'ru-RU',
        label: i18n.text('Russian')
    },
    {
        code: 'es-ES',
        label: i18n.text('Spanish')
    },
    {
        code: 'tr-TR',
        label: i18n.text('Turkish')
    },
    {
        code: 'vi-VN',
        label: i18n.text('Vietnamese')
    }
] as LocalizationColumnItem[];
export const languagesMap = languages.reduce((acc, {code, label}) => ({...acc, [code]: label}), {});
export default languages;
/*
Language    ISO Language Code
Chinese (Simplified)    zh-CN
Chinese (Traditional)    zh-TW
Czech (Czech Republic)    cs-CZ
English (United Kingdom)    en-GB
English (United States)    en-US
French (France)    fr-FR
German (Germany)    de-DE
Greek (Greece)    el-GR
Hungarian (Hungary)    hu-HU
Italian (Italy)    it-IT
Japanese (Japan)    ja-JP
Korean (Korea)    ko-KR
Polish (Poland)    pl-PL
Portuguese (Portugal)    pt-PT
Russian (Russia)    ru-RU
Spanish (Spain)    es-ES
Turkish (Turkey)    tr-TR
Vietnamese (Viet Nam)    vi-VN
 */
