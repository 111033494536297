/**
/**
 * Created by ndyumin on 19.10.2016.
 */
import {ActivityLog} from '../../components/ActivityLog/activityLog.types';
import {CollaborationObjectType, GeneralCollaboration} from '../../rdm-sdk/collaboration.types';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {ReduxState, WorkflowState} from '../../rdm-sdk/reduxState.types';
import {SortDirection} from '../../rdm-sdk/app.types';
import {UiState} from '../../rdm-sdk/ui.types';
export const collaborationInitialState = {
    commentsCount: {},
    comments: {},
    discussion: {
        commentIds: [],
        nextPageToken: undefined
    }
} as GeneralCollaboration;
export const activityLogInitialState = {
    activities: null,
    total: 0,
    pagination: {
        rowsPerPage: 25,
        page: 0
    },
    filters: {
        activityTypes: [],
        users: [],
        lookupTypes: [],
        sources: [],
        lookupValues: [],
        startDate: null,
        endDate: null
    },
    isFilterApplied: false,
    tenant: null
} as ActivityLog;
export const workflowInitialState = {
    tasks: {
        data: null,
        size: 0
    },
    DCRs: null,
    pagination: {
        rowsPerPage: 25,
        page: 0
    },
    rejectedChanges: [],
    assignees: [],
    tenantRegistered: null,
    displayedTask: null
} as WorkflowState;
export default {
    ui: {
        isSearchBarVisible: false,
        openedComment: null,
        openedSnackbar: null,
        openedTask: null
    } as UiState,
    searchQuery: null,
    appTitle: ' ',
    submenu: [],
    configuration: {
        lookupTypes: [],
        sources: [],
        tenantId: '',
        label: ''
    } as Configuration,
    sorting: {
        field: 'value',
        direction: SortDirection.ASC
    },
    filters: {},
    pagination: {
        total: 0,
        scrollId: '',
        hasMore: true
    },
    draftMode: {
        dirtyValues: false,
        dirtySources: false,
        dirtyTypes: false,
        dirtyGenerators: false
    },
    lookupValues: [],
    availableSources: [],
    currentType: null,
    drawerVisible: true,
    session: null,
    tenant: null,
    generators: [],
    generatorsForTypes: {},
    dependencies: [],
    errors: [],
    unmapped: {
        unmappedValues: [],
        unmappedSearchQuery: null,
        selectedItems: [],
        removedItems: {},
        unmappedSourcesTotals: null,
        loadingTotals: false,
        loadingUnmapped: false
    },
    stats: {
        valuesByTypes: {},
        unmappedByTypes: {},
        valuesBySources: {},
        unmappedBySources: {},
        typesHasDCR: {}
    },
    typesSearchQuery: null,
    showConfirmationDialog: null,
    collaboration: {
        [CollaborationObjectType.LOOKUP_VALUE]: collaborationInitialState,
        [CollaborationObjectType.MAPPING_GROUP]: collaborationInitialState
    },
    users: [],
    typesHierarchy: null,
    policies: {},
    needMdmSync: false,
    activityLog: activityLogInitialState,
    workflow: workflowInitialState,
    editMode: '',
    loading: false,
    routing: {}
} as ReduxState;
