import SearchWordsHighlighter from '../../SearchWordsHighlighter/SearchWordsHighlighter';
import styles from './source-value.less';
import {SearchQuery} from '../../../rdm-sdk/filters.types';
type Props = {
    isRemoved: boolean;
    isCanonical?: boolean;
    value?: string;
    highlightCell: boolean;
    searchQuery: SearchQuery;
};

const SourceValue = ({isRemoved, isCanonical = false, value, searchQuery, highlightCell}: Props) => {
    return (
        <div
            style={
                isRemoved
                    ? {
                          color: 'grey'
                      }
                    : {}
            }
            className={styles['source-value']}
        >
            <SearchWordsHighlighter
                style={{
                    textDecoration: isRemoved ? 'line-through' : 'initial'
                }}
                value={value}
                searchQuery={searchQuery}
                searchFields={isCanonical ? ['hasWords'] : ['value', 'hasWords']}
                enabled={highlightCell}
            />
            {highlightCell && <div className={styles.highlightCell} />}
        </div>
    );
};

export default SourceValue;
