import * as R from 'ramda';
import {rejectRemoved} from '../core/marks';
export const getSourceByUri = R.curry((configuration, uri) =>
    R.find(R.propEq('uri', uri), R.propOr([], 'sources', configuration))
);
export const getTypeByUri = R.curry((configuration, uri) =>
    R.find(R.propEq('uri', uri), R.propOr([], 'lookupTypes', configuration))
);
export const rejectRemovedTypes = R.evolve({
    lookupTypes: rejectRemoved
});
export const rejectRemovedSources = R.evolve({
    sources: rejectRemoved
});
