import * as R from 'ramda';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import styles from './styles.less';
import {Component, SyntheticEvent} from 'react';
import {OpenedSnackbar} from '../../rdm-sdk/ui.types';
import {connect} from 'react-redux';
import {getOpenedSnackbar} from '../../redux/reducers/uiReducer';
import {setOpenedSnackbarEvent} from '../../redux/actions/ui';
export const SnackbarMessage = ({title, message, infoLink}: OpenedSnackbar) => (
    <div className={styles['snackbar-container']}>
        <div className={styles['snackbar-message-container']}>
            <div className={styles['snackbar-title']}>{title}</div>
            <div id="snackbar-message" className={styles['snackbar-message']}>
                {message}
            </div>
        </div>
        {infoLink && (
            <a className={styles['snackbar-link']} href={infoLink} target="blank" rel="noopener noreferrer">
                <Icon>info</Icon>
            </a>
        )}
    </div>
);
type Props = {
    openedSnackbar?: OpenedSnackbar | null;
    hideSnackbar: () => void;
};
type State = {
    messages: OpenedSnackbar[];
    open: boolean;
    propsToTrack: {
        openedSnackbar?: OpenedSnackbar | null;
    };
};
export class CustomSnackbar extends Component<Props, State> {
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const {openedSnackbar: nextOpenedSnackbar} = nextProps;
        const {
            propsToTrack: {openedSnackbar: prevOpenedSnackbar},
            open,
            messages
        } = prevState;
        const nextState = {
            propsToTrack: {
                openedSnackbar: nextOpenedSnackbar
            },
            open,
            messages
        };

        if (nextOpenedSnackbar && nextOpenedSnackbar !== prevOpenedSnackbar) {
            nextState.messages = messages.concat(nextOpenedSnackbar);
            nextState.open = !open;
        }

        return nextState;
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            messages: [],
            propsToTrack: {
                openedSnackbar: props.openedSnackbar
            }
        };
    }

    handleClose = (_event: Event | SyntheticEvent<any, Event>, reason: string) => {
        if (reason !== 'clickaway') {
            this.setState({
                open: false
            });
            this.props.hideSnackbar();
        }
    };
    handleExited = () => {
        this.setState(({messages}) => ({
            messages: R.tail(messages),
            open: messages.length > 1
        }));
    };

    render() {
        const {messages, open} = this.state;
        const [message] = messages;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={open}
                autoHideDuration={10000}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'snackbar-message',
                    classes: {
                        root: styles['snackbar-content-root'],
                        message: styles['snackbar-content-message'],
                        action: styles['snackbar-content-action']
                    }
                }}
                message={<SnackbarMessage {...message} />}
                action={
                    <IconButton color="inherit" onClick={this.handleClose as () => void} size="large">
                        <Icon>close</Icon>
                    </IconButton>
                }
                classes={{
                    root: styles['snackbar']
                }}
                TransitionProps={{
                    onExited: this.handleExited
                }}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    openedSnackbar: getOpenedSnackbar(state)
});

const mapDispatchToProps = (dispatch) => ({
    hideSnackbar: R.compose(dispatch, setOpenedSnackbarEvent, R.always(null))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackbar);
