import * as R from 'ramda';
import Button from '@mui/material/Button';
import Dialog from '../Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GeneratorEditor from './GeneratorEditor';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component} from 'react';
import {DEFAULT_GENERATOR} from '../../rdm-sdk/generators';
import {Generator} from '../../rdm-sdk/generators.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {fromNull} from '../../core/maybe';
import {generatorsRemoveEvent, generatorsUpsertEvent} from '../../redux/actions/generators';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {markEdited, markNew} from '../../core/marks';
import {validateGenerator, validateRangeStart} from './validation';
const DEFAULT_STATE = {
    ...DEFAULT_GENERATOR,
    isNew: true,
    isEdited: false,
    propsToTrack: {
        generator: null
    }
};
const getGeneratorInformation = R.pick(['name', 'type', 'rangeStart']);

const initState = (generator) =>
    fromNull(generator)
        .map(getGeneratorInformation)
        .map(
            R.pipe(
                R.assoc('isNew', false),
                R.assoc('isEdited', false),
                R.assocPath(['propsToTrack', 'generator'], generator)
            )
        )
        .orSome(DEFAULT_STATE);

type StateProps = {
    generators: Generator[];
    dispatch: (e: StateEvent) => Promise<any>;
};

type Props = StateProps & {
    generator?: Generator | null;
    open: boolean;
    onCreated: () => void;
    onCancelled: () => void;
};
type State = Generator & {
    isNew: boolean;
    isEdited: boolean;
    propsToTrack: {
        generator?: Generator | null;
    };
};
const logger = logActivityCommand('generator-modal');
const saveLogger = logger('save-click');
const addLogger = logger('add-save-click');
const deleteLogger = logger('delete-click');
const cancelLogger = logger('cancel-click');
export class AddGeneratorDialog extends Component<Props, State> {
    state = initState(this.props.generator) as State;

    static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {
        const {generator: nextGenerator} = nextProps;
        const {
            propsToTrack: {generator: prevGenerator}
        } = prevState;

        if (nextGenerator !== prevGenerator) {
            return initState(nextGenerator) as State;
        } else {
            return null;
        }
    }

    onCreateClick = () => {
        const {dispatch, onCreated} = this.props;
        R.pipe(
            getGeneratorInformation,
            this.state.isNew ? markNew : markEdited,
            generatorsUpsertEvent,
            dispatch,
            onCreated
        )(this.state);
    };
    onDeleteClick = () => {
        const {dispatch, onCreated} = this.props;
        R.pipe(R.pluck('name'), generatorsRemoveEvent, dispatch, onCreated)([this.state]);
    };
    onDialogExited = () => {
        this.setState(initState(this.props.generator) as State);
    };
    next = (field: string) => (data: any) =>
        this.setState((state) => ({
            ...state,
            [field]: data,
            isEdited: true
        }));

    render() {
        const {dispatch, open, onCancelled, generators} = this.props;
        const {isNew, name, type, isEdited, rangeStart} = this.state;
        const title = isNew ? i18n.text('Add generator') : i18n.text('Edit generator');
        const isValid = isNew
            ? validateGenerator(
                  {
                      name,
                      type,
                      rangeStart
                  },
                  generators
              ).isSuccess()
            : validateRangeStart(type, rangeStart).isSuccess();
        const loggableInfo = R.pick(['name', 'type', 'isNew', 'isEdited', 'rangeStart'])(this.state);
        return (
            <Dialog
                open={open}
                onCancel={onCancelled}
                className={styles['add-generator-dialog']}
                TransitionProps={{
                    onExited: this.onDialogExited
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <GeneratorEditor
                        name={name}
                        type={type}
                        rangeStart={rangeStart}
                        generators={generators}
                        isNew={isNew}
                        onChange={this.next}
                    />
                </DialogContent>
                <DialogActions>
                    {!isNew && (
                        <Button
                            variant="grey"
                            className="add-generator-dialog__delete-button"
                            onClick={R.pipe(this.onDeleteClick, R.always(loggableInfo), deleteLogger, dispatch)}
                        >
                            {i18n.text('Delete')}
                        </Button>
                    )}
                    <div className="spacer" />
                    <Button
                        variant="grey"
                        className="add-generator-dialog__cancel-button"
                        onClick={R.pipe(onCancelled, R.always(loggableInfo), cancelLogger, dispatch)}
                    >
                        {i18n.text('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        disabled={!isValid || !isEdited}
                        onClick={R.pipe(
                            this.onCreateClick,
                            R.always(loggableInfo),
                            isNew ? addLogger : saveLogger,
                            dispatch
                        )}
                    >
                        {i18n.text('Done')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps: (state) => StateProps = R.pick(['generators']);
export default connect(mapStateToProps)(AddGeneratorDialog);
