import * as R from 'ramda';
import MultiSelect from '../../../ReactSelect/MultiSelect';
import i18n from 'ui-i18n';
import {SelectUserOption} from '../../activityLog.types';
import {connect} from 'react-redux';
import {getActivityLogFilterUsers} from '../../../../redux/reducers/activityLogReducer';
import {requestUsersSaga} from '../../../../redux/actions/auth';
import {setActivityLogFilterUsersEvent} from '../../../../redux/actions/activityLog';
import {useEffect, useState} from 'react';
export const toUserOptions = R.map((user) => ({
    label: user.username,
    value: user.username,
    user
}));
type Props = {
    options: SelectUserOption[];
    values: SelectUserOption[];
    onChange: (values: SelectUserOption[]) => void;
    requestUsers: () => Promise<any>;
};
export function UserSelect(props: Props) {
    const {options, values, onChange, requestUsers} = props;
    const [isLoading, setIsLoading] = useState(options.length === 0);
    useEffect(() => {
        let mounted = true;

        if (!options.length) {
            requestUsers().then(() => {
                mounted && setIsLoading(false);
            });
        }

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <MultiSelect
            creatable
            label={i18n.text('User')}
            options={options}
            value={values}
            onChange={onChange}
            isLoading={isLoading}
        />
    );
}

const mapStateToProps = (state) => ({
    options: toUserOptions(state.users),
    values: getActivityLogFilterUsers(state)
});

const mapDispatchToProps = (dispatch) => ({
    requestUsers: requestUsersSaga(dispatch),
    onChange: (values) => dispatch(setActivityLogFilterUsersEvent(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
