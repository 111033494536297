import * as R from 'ramda';
import {SelectedMapping, SelectedMappingMode} from '../../rdm-sdk/grid.types';
import {checkRemoved} from '../../core/marks';
import {getMappingByIdentifier, getNextSourceAbbreviation, getPrevSourceAbbreviation} from '../../rdm-sdk/grid';
import {getNextLookupValueCode, getPreviousLookupValue} from '../../rdm-sdk/lookups';

enum Direction {
    LEFT = 'left',
    RIGHT = 'right',
    DOWN = 'down',
    UP = 'up'
}

const getMappings = (source, lookupValue) => R.pathOr([], ['sources', source], lookupValue);

const reduceIndexed = R.addIndex(R.reduce);

function getCellBelow(lookupValues, identifier) {
    const lookupValue = R.find(R.propEq('code', identifier.code), lookupValues);
    const mappings = getMappings(identifier.source, lookupValue);
    const nextMappingIndex = identifier.mappingIndex + 1;
    const nextIdentifier = {
        code:
            nextMappingIndex >= mappings.length
                ? getNextLookupValueCode(identifier.code, lookupValues)
                : identifier.code,
        source: identifier.source,
        mappingIndex: nextMappingIndex >= mappings.length ? 0 : nextMappingIndex
    };
    const nextMapping = getMappingByIdentifier(nextIdentifier, lookupValues);

    if (nextMapping === null || !checkRemoved(nextMapping)) {
        return nextIdentifier;
    } else {
        return getCellBelow(lookupValues, nextIdentifier);
    }
}

function getCellAbove(lookupValues, identifier) {
    const lookupValue = getPreviousLookupValue(identifier.code, lookupValues);
    const mappings = getMappings(identifier.source, lookupValue);
    const nextMappingIndex = identifier.mappingIndex - 1;
    const nextIdentifier = {
        code: nextMappingIndex < 0 ? R.propOr(identifier.code, 'code', lookupValue) : identifier.code,
        source: identifier.source,
        mappingIndex: nextMappingIndex < 0 ? R.clamp(0, Infinity, mappings.length - 1) : nextMappingIndex
    };
    const nextMapping = getMappingByIdentifier(nextIdentifier, lookupValues);

    if (nextMapping === null || !checkRemoved(nextMapping)) {
        return nextIdentifier;
    } else if (R.equals(nextIdentifier, identifier)) {
        return null;
    } else {
        return getCellAbove(lookupValues, nextIdentifier);
    }
}

function chooseMappingIndex(current, available) {
    for (const next of R.reverse(available)) {
        if (current === next) return current;
        if (current > next) return next;
    }

    return available.length > 0 ? available[0] : 0;
}

function getCellHoriz(direction, identifier, sources, lookupValues) {
    const source: string =
        direction === 'right'
            ? getNextSourceAbbreviation(identifier.source, sources)
            : getPrevSourceAbbreviation(identifier.source, sources);
    const availableIndices = R.pipe(
        R.find(R.propEq('code', identifier.code)),
        R.pathOr([], ['sources', source]),
        reduceIndexed((indices, mapping, index) => (!checkRemoved(mapping) ? [...indices, index] : indices), [])
    )(lookupValues);
    const nextIdentifier = {
        code: identifier.code,
        source,
        mappingIndex: chooseMappingIndex(identifier.mappingIndex, availableIndices)
    };
    const nextMapping = getMappingByIdentifier(nextIdentifier, lookupValues);

    if (nextMapping === null || !checkRemoved(nextMapping)) {
        return nextIdentifier;
    } else if (nextIdentifier.source === identifier.source) {
        return null;
    } else {
        return getCellHoriz(
            direction,
            {...nextIdentifier, mappingIndex: identifier.mappingIndex},
            sources,
            lookupValues
        );
    }
}

export const moveFocusCellVert = (direction: 'up' | 'down') =>
    R.curry((lookupValues, selectedMapping) => {
        const identifier = R.propOr(null, 'identifier', selectedMapping);
        const mode = R.propOr(null, 'mode', selectedMapping);

        if (mode === SelectedMappingMode.EDIT) {
            return selectedMapping;
        }

        const getNextCell = direction === 'up' ? getCellAbove : getCellBelow;
        const nextIdentifier = getNextCell(lookupValues, identifier) || identifier;
        return {
            mode: SelectedMappingMode.FOCUS,
            identifier: nextIdentifier
        };
    });
export const moveFocusCellDown = moveFocusCellVert(Direction.DOWN);
export const moveFocusCellUp = moveFocusCellVert(Direction.UP);
export const moveFocusCellHoriz = (direction: Direction) =>
    R.curry((lookupValues, sources, selectedMapping) => {
        const identifier = R.propOr(null, 'identifier', selectedMapping);
        const mode = R.propOr(null, 'mode', selectedMapping);

        if (mode === SelectedMappingMode.EDIT) {
            return selectedMapping;
        }

        const nextIdentifier = getCellHoriz(direction, identifier, sources, lookupValues) || identifier;
        return {
            mode: SelectedMappingMode.FOCUS,
            identifier: nextIdentifier
        };
    });
export const moveFocusCellRight = moveFocusCellHoriz(Direction.RIGHT);
export const moveFocusCellLeft = moveFocusCellHoriz(Direction.LEFT);
export const checkIfLookupSourceSelected = R.curry(
    (lookupCode: string, sourceAbbr: string, selectedMapping: SelectedMapping) =>
        selectedMapping &&
        lookupCode === selectedMapping.identifier.code &&
        sourceAbbr === selectedMapping.identifier.source
);
