import {Validation} from 'monet';
export const validateLabel = (source) =>
    typeof source.label === 'string' && source.label.length > 0
        ? Validation.success(source.label)
        : Validation.fail(['label is empty']);
export const validateAbbreviation = (source) =>
    typeof source.abbreviation === 'string' && source.abbreviation.length > 0
        ? Validation.success(source.abbreviation)
        : Validation.fail(['abbreviation is empty']);
export const validateSource = (source) => validateLabel(source).ap(validateAbbreviation(source).acc());
