import * as R from 'ramda';
import MarkLabel from '../../components/MarkLabel/MarkLabel';
import SearchWordsHighlighter from '../../components/SearchWordsHighlighter/SearchWordsHighlighter';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import {checkClientOnly, checkRemoved} from '../../core/marks';
export default function CanonicalValueCellRenderer({rowData, cellData, columnData}: Record<string, any>) {
    const {query} = columnData;
    const isEnabled = R.propOr(true, 'enabled', rowData);
    const isRemoved = checkRemoved(rowData);
    const isClientOnly = checkClientOnly(rowData);
    return (
        <Tooltip title={!isEnabled ? i18n.text('Disabled') : ''} placement="bottom-start">
            <div>
                <MarkLabel entity={rowData} />
                <SearchWordsHighlighter
                    style={{
                        textDecoration: isRemoved ? 'line-through' : 'initial',
                        color: isRemoved || !isEnabled ? 'inherit' : 'rgba(0, 0, 0, 0.87)'
                    }}
                    value={cellData}
                    searchQuery={query}
                    searchFields={['hasWords']}
                    enabled={!isClientOnly}
                />
            </div>
        </Tooltip>
    );
}
