import * as R from 'ramda';
import i18n from 'ui-i18n';
import {checkEdited, metadataReplacer} from '../../core/marks';
import {commandCreator} from '../middlewares/command-middleware';
import {getCanonical} from '../../rdm-sdk/lookups';
import {json, requestWithBlockingSpinner} from '../../network';
import {pipe, then} from '../../core/func';
import {requestValuesByUrisCommand} from './values';
import {typeCodeToUri} from '../../rdm-sdk/types';
export const saveDependencyEvent = (value) => ({
    type: 'saveDependency',
    value
});
export const resetDependenciesEvent = () => ({
    type: 'resetDependencies'
});

const getLookupUri = (tenant) => (uri) => `${tenant}/${uri.split('/').slice(-2).join('/')}`;

export const requestDependenciesCommand = commandCreator(({state}, code) => {
    const {tenant, currentType} = state;
    return requestWithBlockingSpinner(`/rdm/dependencies/${tenant}/_byUris`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uris: [`${typeCodeToUri(currentType)}/${code}`]
        })
    })
        .then(json)
        .then(([{parents, dependent}]) => {
            const uris = parents.concat(dependent).map(getLookupUri(tenant));
            const depData = {
                parents: {
                    data: parents
                },
                dependent: {
                    data: dependent
                },
                code,
                type: currentType
            };
            return uris.length > 0
                ? requestValuesByUrisCommand(tenant, uris).then((lookups) => {
                      const addCanonical = R.map((uri) => {
                          const lookup = lookups.find(({type, code}) => `${type}/${code}` === uri);
                          return lookup
                              ? {
                                    uri,
                                    canonical: getCanonical(lookup.sourceMappings).orSome('')
                                }
                              : {
                                    uri
                                };
                      });
                      return R.evolve(
                          {
                              parents: {
                                  data: addCanonical
                              },
                              dependent: {
                                  data: addCanonical
                              }
                          },
                          depData
                      );
                  })
                : depData;
        });
});
export const requestDependenciesSaga = (dispatch) =>
    pipe(requestDependenciesCommand, dispatch, then(pipe(saveDependencyEvent, dispatch)));
export const updateDependenciesRequest = ({tenant, body}) =>
    requestWithBlockingSpinner(
        `/rdm/dependencies/${tenant}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body, metadataReplacer)
        },
        i18n.text('Update dependencies error')
    ).then(json);
export const suggestDependenciesRequest = ({tenant, body, DCRID}) => {
    return requestWithBlockingSpinner(
        `/rdm/changeRequests/${tenant}/${DCRID}/dependencies`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body, metadataReplacer)
        },
        i18n.text('Suggest dependencies error')
    ).then(json);
};
export const updateDependenciesCommand = commandCreator(({state}, DCRID) => {
    const {tenant, dependencies} = state;
    const body = dependencies.filter(R.pipe(R.prop('dependent'), checkEdited)).map((dep) => ({
        uri: `${typeCodeToUri(dep.type)}/${dep.code}`,
        dependent: R.pluck('uri', dep.dependent.data)
    }));

    const dependenciesRequest = () =>
        DCRID
            ? suggestDependenciesRequest({
                  tenant,
                  body,
                  DCRID
              })
            : updateDependenciesRequest({
                  tenant,
                  body
              });
    return body.length ? dependenciesRequest() : Promise.resolve([]);
});
export const updateDependenciesSaga = (dispatch) =>
    R.pipe(updateDependenciesCommand, dispatch, then(R.pipe(resetDependenciesEvent, dispatch)));
export const suggestUpdateDependenciesSaga = (dispatch) =>
    R.pipe(updateDependenciesCommand, dispatch, then(R.tap(R.pipe(resetDependenciesEvent, dispatch))));
