import ActivityTypeSelect from '../../selects/ActivityTypeSelect/ActivityTypeSelect';
import FilterControl from '../FilterControl/FilterControl';
import LookupTypeSelect from '../../selects/LookupTypeSelect/LookupTypeSelect';
import LookupValuesSelect from '../../selects/LookupValuesSelect/LookupValuesSelect';
import RangeSelect from '../../selects/RangeSelect/RangeSelect';
import SourceSelect from '../../selects/SourceSelect/SourceSelect';
import Typography from '@mui/material/Typography';
import UserSelect from '../../selects/UserSelect/UserSelect';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {StateEvent} from '../../../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {filterClearLogger, searchApplyLogger} from '../../logging';
import {
    requestActivitiesSaga,
    requestActivitiesTotalSaga,
    resetActivityLogFiltersEvent,
    setActivityLogFilterAppliedEvent
} from '../../../../redux/actions/activityLog';
import {setActivitiesFirstPageEvent} from '../../../../redux/actions/activityLog';
type Props = {
    close: () => void;
    selects: {
        lookupTypes?: boolean;
        lookupValues?: boolean;
        sources?: boolean;
    };
    dispatch: (arg0: StateEvent) => void;
    objectType: string;
};
export function Filters(props: Props) {
    const {close, selects = {}, dispatch, objectType} = props;

    function handleSearch() {
        dispatch(setActivitiesFirstPageEvent());
        requestActivitiesTotalSaga(dispatch)(objectType);
        requestActivitiesSaga(dispatch)(objectType);
        dispatch(setActivityLogFilterAppliedEvent());
        dispatch(searchApplyLogger(null) as StateEvent);
        close();
    }

    function handleClear() {
        dispatch(setActivitiesFirstPageEvent());
        dispatch(filterClearLogger(null) as StateEvent);
        dispatch(resetActivityLogFiltersEvent());
        requestActivitiesTotalSaga(dispatch)(objectType);
        requestActivitiesSaga(dispatch)(objectType);
    }

    return (
        <div>
            <Typography variant={'h6'} className={styles['filters__title']}>
                {i18n.text('Filter')}
            </Typography>
            <ActivityTypeSelect />
            <RangeSelect />
            <UserSelect />
            {selects.lookupTypes && <LookupTypeSelect />}
            {selects.lookupValues && <LookupValuesSelect />}
            {selects.sources && <SourceSelect />}
            <FilterControl onClear={handleClear} onCancel={close} onSearch={handleSearch} />
        </div>
    );
}
export default connect()(Filters);
