import WorkflowItem from '../WorkflowItem/WorkflowItem';
import {ValuesSidePanelProvider} from '../../ActivityLog/LookupValues/ValuesSidePanel/valuesSidePanelStore';
import {WorkflowType} from '../workflow.types';
type Props = {
    workflows: WorkflowType[];
};
export function WorkflowList(props: Props) {
    const {workflows = []} = props;
    return (
        <ValuesSidePanelProvider>
            {workflows.map((workflow) => (
                <WorkflowItem key={workflow.id} workflow={workflow} />
            ))}
        </ValuesSidePanelProvider>
    );
}
export default WorkflowList;
