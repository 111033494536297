/**
 * Created by ndyumin on 12.10.2016.
 */
import * as R from 'ramda';
import CacheProvider from '../../components/Cache/CacheProvider';
import CustomTable from '../../components/CustomTable/CustomTable';
import DiscussionButton from '../../components/DiscussionButton/DiscussionButton';
import EditTypeButton from './EditTypeButton';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import FeatureFilter from '../../components/Feature/FeatureFilter';
import Localization from '../../components/Localization/Localization';
import MappingGrid from '../../components/Mapping/MappingGrid';
import ReferenceDialog from '../../components/ReferenceDialog/ReferenceDialog';
import ValuesEmptyIcon from '../../assets/inline/valuesEmpty.svg';
import ValuesSearchBar from '../../components/Search/ValuesSearch/ValuesSearchBar';
import ViewportSync from './ViewportSync';
import canonicalCodeCellRenderer from './canonicalCodeCellRenderer';
import canonicalValueCellRenderer from './canonicalValueCellRenderer';
import dateCellRenderer from '../../components/CustomTable/dateCellRenderer';
import i18n from 'ui-i18n';
import initialState from '../../redux/initialState';
import tableHeaderRenderer from '../../components/CustomTable/tableHeaderRenderer';
import withFeatures from '../../components/Feature/withFeatures';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {CollaborationObjectType} from '../../rdm-sdk/collaboration.types';
import {Component, ReactElement} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DraftMode, Filters, Pagination, Sorting, User} from '../../rdm-sdk/app.types';
import {EDIT_MODE, FILTER_OPTIONS, REPLACEABLE_CODE_PREFIX} from '../../constants/common';
import {FEATURE} from '../../rdm-sdk/features';
import {LookupValue} from '../../rdm-sdk/lookups.types';
import {
    MAPPED_VALUES_LIMIT,
    lookupValuesRemoveEvent,
    requestAvailableSourcesSaga,
    requestNextValuesSaga,
    requestTotalSaga,
    requestValuesSaga,
    upsertValueEvent
} from '../../redux/actions/values';
import {Maybe} from 'monet';
import {StateEvent} from '../../rdm-sdk/state.types';
import {StatusRightMenu} from '../../components/AppBar/StatusLine';
import {UnmappedValue} from '../../rdm-sdk/unmapped.types';
import {ValuesSearchQuery} from '../../rdm-sdk/filters.types';
import {addSignalToRequest} from '../../errors/utils';
import {canSaveLookups, getExtendedAttributes, validateSourceMappings} from '../../rdm-sdk/lookups';
import {checkEdited, checkNew, markEdited, markErroneous} from '../../core/marks';
import {checkIfTypeIsClientOnly, getTypeByCode} from '../../rdm-sdk/types';
import {clearSearchQueryEvent} from '../../redux/actions/search';
import {connect} from 'react-redux';
import {currentTypeEvent} from '../../redux/actions/types';
import {
    drawerVisibilityEvent,
    setEditModeEvent,
    showConfirmationDialogEvent,
    submenuEvent,
    titleEvent,
    totalEvent
} from '../../redux/actions/app';
import {getCommentsCountSaga, resetDiscussionEvent} from '../../redux/actions/collaboration';
import {getDefaultEditMode, isWorkflowHasEditMode} from '../../rdm-sdk/editModeTypes';
import {getDispatchOnResolve} from '../../redux/middlewares/confirm-middleware/confirmMiddleware';
import {getEditMode} from '../../redux/selectors/mainReducerSelectors';
import {getIsDCRDisplayed, getIsWorkflowEnabled} from '../../redux/selectors/workflowSelectors';
import {getLookupValueRelatedObjectUri} from '../../rdm-sdk/collaboration';
import {getOpenedTask} from '../../redux/reducers/uiReducer';
import {inArray, prop} from '../../core/lenses';
import {initialQuery} from '../../components/Search/initialSearchQuery';
import {isExceptionRoute, noop} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {push, replace} from 'react-router-redux';
import {requestLookupTotalsBySourcesSaga, resetValuesBySourcesStatsEvent} from '../../redux/actions/stats';
import {
    requestNextUnmappedSaga,
    requestUnmappedAllSaga,
    requestUnmappedTotalSaga,
    unmappedResetEvent
} from '../../redux/actions/unmapped';
import {requestOpenedWorkflowTaskSaga, requestWorkflowTasksSaga} from '../../redux/actions/workflow';
import {requestUsersSaga} from '../../redux/actions/auth';
import {
    resetActivitiesEvent,
    resetActivitiesTotalEvent,
    resetActivityLogFiltersEvent
} from '../../redux/actions/activityLog';
import {resetWorkflowsEvent} from '../../redux/actions/workflow';
import {searchQueryIsNotEmpty} from '../../components/Mapping/searchHelpers';
import {setFiltersEvent} from '../../redux/actions/filters';
import {setIsSearchBarVisibleEvent} from '../../redux/actions/ui';
import {setSortingEvent, toggleSortingEvent} from '../../redux/actions/sorting';
import {tap} from '../../core/monet';
import {tenantChangedEvent} from '../../redux/actions/tenants';
import {validateAttributes} from '../../components/ReferenceDialog/validation';
const filterOptions = [FILTER_OPTIONS.ALL, FILTER_OPTIONS.ENABLED];
const COLUMNS = [
    {
        label: i18n.text('Name'),
        flexGrow: 0.7,
        dataKey: 'canonical',
        width: 400,
        columnData: {
            sortKey: 'value'
        },
        cellRenderer: canonicalValueCellRenderer
    },
    {
        label: i18n.text('Code'),
        flexGrow: 0.1,
        dataKey: 'code',
        width: 90,
        cellRenderer: canonicalCodeCellRenderer
    },
    {
        label: i18n.text('Start date'),
        flexGrow: 0.1,
        dataKey: 'startDate',
        width: 90,
        cellRenderer: dateCellRenderer
    },
    {
        label: i18n.text('End date'),
        flexGrow: 0.1,
        dataKey: 'endDate',
        width: 90,
        cellRenderer: dateCellRenderer
    }
];
const AbortController = window.AbortController;
type Props = {
    dispatch: (e: StateEvent) => Promise<any>;
    dispatchOnResolve: (e: StateEvent) => Promise<any>;
    draftMode: DraftMode;
    params: {
        tab: string;
        type: string;
        tenantId: string;
    };
    searchQuery: ValuesSearchQuery;
    lookupValues: LookupValue[];
    configuration: Configuration;
    appTitle: string;
    router: Record<string, any>;
    routes: Array<Record<string, any>>;
    pagination: Pagination;
    sorting: Sorting;
    filters: Filters;
    currentType: string | null;
    policies: CheckedPolicies;
    users: User[];
    isWorkflowEnabled: boolean;
    editMode?: string;
    openedTask: string;
    isDCRDisplayed: boolean;
};
type State = {
    query: ValuesSearchQuery;
    referenceEditOpen: boolean;
    referenceEditRef: LookupValue | null;
};
const EMPTY_STATE = (
    <div className={'empty-state'}>
        <div
            className={'empty-state__icon'}
            dangerouslySetInnerHTML={{
                __html: ValuesEmptyIcon
            }}
        />
        <p className={'empty-state__header'}>{i18n.text('Add a new column to add canonical and source values')}</p>
    </div>
);
export class ValuesListPage extends Component<Props, State> {
    onReferenceEditDone?: ((reference: LookupValue) => void) | null;
    query$: Subject<ValuesSearchQuery>;
    lookupsStream$: Subject<LookupValue[]>;
    configStream$: Subject<any>;
    isWorkflowEnabledStream$: Subject<any>;
    loadMoreMapped$: Subject<any>;
    loadMoreUnmapped$: Subject<{unmappedValue: UnmappedValue; onLoaded: () => void}>;
    loadMoreUnmappedSubs?: Subscription;
    totalSubscription?: Subscription;
    querySubscription?: Subscription;
    loadMoreMappedSubs?: Subscription;
    removeLeaveHook?: () => void;
    currentTypeIsClientOnly?: boolean;
    startIndexStream$: BehaviorSubject<number>;
    requestAbortController: any;
    state = {
        query: initialQuery,
        referenceEditOpen: false,
        referenceEditRef: null
    };

    constructor(props: Props) {
        super(props);
        this.query$ = new Subject();
        this.lookupsStream$ = new Subject();
        this.loadMoreMapped$ = new Subject();
        this.loadMoreUnmapped$ = new Subject();
        this.configStream$ = new Subject();
        this.isWorkflowEnabledStream$ = new Subject();
        this.startIndexStream$ = new BehaviorSubject(-1);
        this.requestAbortController = null;
    }

    onCanonicalValueSort = () => {
        const {dispatch, sorting} = this.props;
        dispatch(
            setSortingEvent({
                field: 'value',
                direction: sorting.direction
            })
        );
        dispatch(toggleSortingEvent());
        requestValuesSaga(dispatch)();
    };

    componentDidUpdate(prevProps: Props) {
        const {
            configuration,
            configuration: {lookupTypes, sources},
            appTitle,
            dispatch,
            params: {tenantId, type},
            lookupValues,
            searchQuery,
            isWorkflowEnabled
        } = this.props;
        this.query$.next(searchQuery);
        this.configStream$.next(configuration);
        this.lookupsStream$.next(lookupValues);
        this.isWorkflowEnabledStream$.next(isWorkflowEnabled);
        this.currentTypeIsClientOnly = checkIfTypeIsClientOnly(
            {
                lookupTypes
            },
            type
        );
        const typeLabel = Maybe.fromNull(
            getTypeByCode(
                {
                    lookupTypes
                },
                type
            )
        ).map(prop('label').get);
        tap(R.unless(R.equals(appTitle), R.pipe(titleEvent, dispatch)), typeLabel);
        const getType = R.pipe(R.prop('uri'), R.split('/'), R.last);
        const types = R.map(getType, lookupTypes);

        if (!prevProps.draftMode.dirtyTypes && sources.length > 0 && !types.includes(type)) {
            dispatch(push(`/${tenantId}/page_not_found`));
        }
    }

    componentDidMount() {
        const {params, dispatch, router, routes, configuration, users, dispatchOnResolve, isWorkflowEnabled} =
            this.props;
        const {tenantId, type} = params;
        const path = `${tenantId}/types/${encodeURIComponent(type)}`;
        this.requestAbortController = new AbortController();
        const signal = this.requestAbortController.signal;
        dispatch(currentTypeEvent(type));
        dispatch(tenantChangedEvent(tenantId));
        dispatch(
            submenuEvent([
                {
                    link: `${path}/mapping`,
                    label: i18n.text('Mapping')
                },
                {
                    link: `${path}/value`,
                    label: i18n.text('Values')
                },
                {
                    link: `${path}/localization`,
                    label: i18n.text('Localization')
                }
            ])
        );
        dispatch(drawerVisibilityEvent(false));
        dispatch(setSortingEvent(initialState.sorting));
        dispatch(setFiltersEvent(initialState.filters));
        dispatch(unmappedResetEvent());
        requestValuesSaga(dispatch)().then(
            R.pipe(R.prop('lookupValues'), this.getCollaborationCommentsCount, requestAvailableSourcesSaga(dispatch))
        );
        this.loadMoreMappedSubs = this.loadMoreMapped$
            .skip(1)
            .filter(() => this.props.pagination.hasMore)
            .groupBy(() => this.props.pagination.scrollId)
            .flatMap((group) => group.take(1))
            .subscribe((onLoaded) =>
                requestNextValuesSaga(this.props.dispatch)()
                    .then(R.pipe(R.prop('lookupValues'), this.getCollaborationCommentsCount))
                    .then(onLoaded)
            );
        this.loadMoreUnmappedSubs = this.loadMoreUnmapped$
            .groupBy(({unmappedValue}) => unmappedValue.source)
            .flatMap((group) => group.throttleTime(100))
            .filter(R.path(['unmappedValue', 'scrollId']))
            .subscribe(({unmappedValue, onLoaded}) => {
                const {dispatch} = this.props;
                requestNextUnmappedSaga(dispatch, unmappedValue.source).then(onLoaded);
            });
        this.totalSubscription = this.query$
            .distinctUntilChanged(R.equals)
            .skip(1)
            .subscribe(
                R.pipe(
                    requestTotalSaga(dispatch),
                    addSignalToRequest(requestLookupTotalsBySourcesSaga(dispatch), signal)
                )
            );
        this.querySubscription = this.query$
            .distinctUntilChanged(R.equals)
            .sample(this.lookupsStream$.distinctUntilChanged())
            .subscribe((query) => {
                this.setState({
                    query: Maybe.fromNull(query).orSome(initialQuery)
                });
            });
        const route = routes[2];
        this.removeLeaveHook = router.setRouteLeaveHook(route, (nextRoute) => {
            const {draftMode} = this.props;

            if ((draftMode.dirtyValues || draftMode.dirtyTypes) && !isExceptionRoute(nextRoute)) {
                if (nextRoute.action === 'POP') dispatch(replace(router.location));
                dispatch(showConfirmationDialogEvent());
                dispatchOnResolve(push(nextRoute));
                return false;
            }
        });
        this.configStream$
            .startWith(configuration)
            .first(R.has('tenantId'))
            .subscribe(
                R.pipe(
                    addSignalToRequest(requestUnmappedTotalSaga(dispatch), signal),
                    requestTotalSaga(dispatch),
                    addSignalToRequest(requestLookupTotalsBySourcesSaga(dispatch), signal)
                )
            );
        this.isWorkflowEnabledStream$
            .startWith(isWorkflowEnabled)
            .first(R.identity)
            .subscribe(() => {
                const {openedTask, policies} = this.props;
                R.pipe(getDefaultEditMode, setEditModeEvent, dispatch)(policies);
                openedTask ? requestOpenedWorkflowTaskSaga(dispatch)(openedTask) : requestWorkflowTasksSaga(dispatch)();
            });

        if (!users.length) {
            requestUsersSaga(dispatch)();
        }
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        this.requestAbortController.abort();
        dispatch(submenuEvent([]));
        dispatch(titleEvent(' '));
        dispatch(currentTypeEvent(null));
        dispatch(drawerVisibilityEvent(true));
        dispatch(clearSearchQueryEvent());
        dispatch(totalEvent(0));
        this.totalSubscription?.unsubscribe();
        this.querySubscription?.unsubscribe();
        this.loadMoreMappedSubs?.unsubscribe();
        this.loadMoreUnmappedSubs?.unsubscribe();
        dispatch(unmappedResetEvent());
        dispatch(resetDiscussionEvent(CollaborationObjectType.LOOKUP_VALUE));
        dispatch(resetDiscussionEvent(CollaborationObjectType.MAPPING_GROUP));
        dispatch(setIsSearchBarVisibleEvent(false));
        dispatch(resetValuesBySourcesStatsEvent());
        dispatch(resetActivitiesEvent());
        dispatch(resetActivitiesTotalEvent());
        dispatch(resetActivityLogFiltersEvent());
        dispatch(resetWorkflowsEvent());
    }

    getCollaborationCommentsCount = (lookupValues: LookupValue[]) => {
        const {dispatch} = this.props;
        getCommentsCountSaga(dispatch, CollaborationObjectType.LOOKUP_VALUE)(lookupValues);
        getCommentsCountSaga(dispatch, CollaborationObjectType.MAPPING_GROUP)(lookupValues);
    };

    isLeaveUnsavedChanges(onLeave: () => void) {
        const {dispatch, draftMode} = this.props;

        if (draftMode.dirtyValues) {
            dispatch(showConfirmationDialogEvent());
        }

        onLeave();
    }

    handleSortingChange = (sorting: Sorting) => {
        this.isLeaveUnsavedChanges(() => {
            const {dispatchOnResolve} = this.props;
            dispatchOnResolve(setSortingEvent(sorting));
            requestValuesSaga(dispatchOnResolve)();
        });
    };
    handleFiltersChange = (filter: string) => {
        const filters: Filters =
            filter === FILTER_OPTIONS.ENABLED
                ? {
                      enabled: true
                  }
                : {};
        const {dispatchOnResolve} = this.props;
        const signal = this.requestAbortController.signal;
        this.isLeaveUnsavedChanges(() => {
            R.pipe(
                setFiltersEvent,
                dispatchOnResolve,
                requestValuesSaga(dispatchOnResolve),
                requestTotalSaga(dispatchOnResolve),
                addSignalToRequest(requestLookupTotalsBySourcesSaga(dispatchOnResolve), signal)
            )(filters);
        });
    };
    loadUnmapped = () => {
        const dispatch = this.props.dispatch;
        const signal = this.requestAbortController.signal;
        addSignalToRequest(requestUnmappedAllSaga(dispatch), signal)();
    };
    loadMoreMapped = (onLoaded: () => void = noop) => {
        this.loadMoreMapped$.next(onLoaded);
    };
    loadMoreUnmapped = (unmappedValue: UnmappedValue, onLoaded: () => void = noop) => {
        this.loadMoreUnmapped$.next({unmappedValue, onLoaded});
    };
    openReferenceEdit = (referenceEditRef: LookupValue) =>
        this.setState({
            referenceEditOpen: true,
            referenceEditRef
        });
    closeReferenceEdit = () => {
        this.setState({
            referenceEditOpen: false,
            referenceEditRef: null
        });
        delete this.onReferenceEditDone;
    };
    openReferenceEditWithCallback = (ref: LookupValue, onDoneCb: (ref: LookupValue) => void) => {
        this.onReferenceEditDone = onDoneCb;
        this.openReferenceEdit(ref);
    };
    onValueDelete = R.pipe(lookupValuesRemoveEvent, this.props.dispatch);
    updateLookupValue = (code: string, lookupValue: LookupValue) => {
        const {dispatch, currentType} = this.props;
        return R.pipe(this.validateLookupValue, markEdited, upsertValueEvent(currentType, code), dispatch)(lookupValue);
    };
    validateLookupValue = (lookupValue: LookupValue) => {
        const {configuration, currentType} = this.props;
        let value = validateSourceMappings(lookupValue);
        const attributesExt = getExtendedAttributes(value, getTypeByCode(configuration, currentType));
        const isAttributesValid = validateAttributes(attributesExt).isSuccess();

        if (!isAttributesValid) {
            value = markErroneous(true, value);
        }

        if (value.code.startsWith(REPLACEABLE_CODE_PREFIX)) {
            value = markErroneous(true, value);
        }

        return value;
    };

    shouldComponentUpdate(nextProps: Props) {
        return !(
            R.path(['location', 'action'], this.props) === 'PUSH' && R.path(['location', 'action'], nextProps) === 'POP'
        );
    }

    onLookupTypeEdited = () => {
        const {dispatch, lookupValues, currentType} = this.props;
        lookupValues.forEach((value) => {
            if (!(checkNew(value) || checkEdited(value))) return;
            const validatedValue = this.validateLookupValue(value);
            dispatch(upsertValueEvent(currentType, validatedValue.code, validatedValue));
        });
    };
    isWorkflowEditMode = () => {
        const {policies, isWorkflowEnabled} = this.props;
        return isWorkflowHasEditMode(policies) && isWorkflowEnabled;
    };
    isEditMode = () => {
        const {policies, isDCRDisplayed} = this.props;
        const isWorkflowEditMode = this.isWorkflowEditMode();
        return (policies.lookupValuesEdit || isWorkflowEditMode) && !isDCRDisplayed;
    };
    getHeaderSummaryLabel = (valuesCount: number) => {
        const getCountValuesLabel = () =>
            valuesCount > 1
                ? i18n.text('${count} values', {
                      count: valuesCount
                  })
                : i18n.text('${count} value', {
                      count: valuesCount
                  });
        return valuesCount > 0 ? getCountValuesLabel() : null;
    };
    getHeaderFilter = () => {
        const {filters} = this.props;
        return {
            selected: filters['enabled'] ? FILTER_OPTIONS.ENABLED : FILTER_OPTIONS.ALL,
            onChange: this.handleFiltersChange,
            options: filterOptions
        };
    };
    needShowValues = () => {
        const {lookupValues, filters} = this.props;
        const {query} = this.state;
        return searchQueryIsNotEmpty(query) || !R.isEmpty(filters) || lookupValues.length > 0;
    };
    getIsSuggestMode = () => {
        const {isWorkflowEnabled, editMode} = this.props;
        return isWorkflowEnabled && editMode === EDIT_MODE.SUGGEST;
    };

    render() {
        const {
            currentType,
            lookupValues,
            sorting,
            pagination: {total},
            policies,
            dispatch,
            params: {tenantId},
            draftMode,
            isWorkflowEnabled,
            editMode,
            isDCRDisplayed,
            pagination
        } = this.props;
        const {tab} = this.props.params;
        const {query, referenceEditOpen, referenceEditRef} = this.state;
        const isWorkflowEditMode = this.isWorkflowEditMode();
        const isEditMode = this.isEditMode();
        const dependenciesReadOnly = !isEditMode || (!policies.dependenciesEdit && !isWorkflowEditMode);
        const referenceEditDialog = (
            <ReferenceDialog
                open={referenceEditOpen}
                reference={referenceEditRef}
                onClose={this.closeReferenceEdit}
                onDone={this.onReferenceEditDone || noop}
                updateLookupValue={this.updateLookupValue}
                isEditMode={isEditMode}
                dependenciesReadOnly={dependenciesReadOnly}
            />
        );
        const isNotSuggestMode = !this.getIsSuggestMode();
        const editTypeButton = policies.configEdit && isNotSuggestMode && (
            <EditTypeButton
                onEdit={this.onLookupTypeEdited}
                dispatch={dispatch}
                tenantId={tenantId}
                onDelete={this.removeLeaveHook || noop}
                disabled={isDCRDisplayed}
            />
        );
        const valuesCount = this.currentTypeIsClientOnly ? lookupValues.length : total;
        const showValues = this.needShowValues();
        const maxViewportIndex = valuesCount - 1;
        const canApplyResolver = canSaveLookups(lookupValues);
        const canLoad = pagination.hasMore && lookupValues.length >= Number(MAPPED_VALUES_LIMIT);
        let page: ReactElement | null = null;

        switch (tab) {
            case 'value': {
                const logger = logActivityCommand('values-table');
                const headerRenderer = tableHeaderRenderer({
                    caption: i18n.text('Canonical Value'),
                    summary: this.getHeaderSummaryLabel(valuesCount),
                    filter: this.getHeaderFilter(),
                    logger
                });
                page = (
                    <div
                        id="values-page-table-container"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            background: '#FAFBFB',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        {showValues ? (
                            <ViewportSync
                                key="values"
                                maxIndex={maxViewportIndex}
                                startIndexStream$={this.startIndexStream$}
                                canLoad={canLoad}
                                loadMore={this.loadMoreMapped}
                            >
                                {({onScroll, gridRef, startIndex}) => (
                                    <CustomTable
                                        logger={logger}
                                        primaryHeaderRenderer={headerRenderer}
                                        isEditMode={isEditMode}
                                        data={lookupValues}
                                        selectKey={'code'}
                                        columns={COLUMNS}
                                        columnsData={{
                                            query
                                        }}
                                        onDelete={this.onValueDelete}
                                        sorting={{
                                            sort: sorting,
                                            onChange: this.handleSortingChange
                                        }}
                                        onRowClick={({rowData}) => {
                                            this.openReferenceEdit(inArray('code', rowData.code).get(lookupValues));
                                        }}
                                        loadMore={this.loadMoreMapped}
                                        collaborationOptions={{
                                            objectType: CollaborationObjectType.LOOKUP_VALUE,
                                            relatedObjectUri: getLookupValueRelatedObjectUri(currentType || '')
                                        }}
                                        onScroll={onScroll}
                                        gridRef={gridRef}
                                        scrollToIndex={startIndex}
                                    />
                                )}
                            </ViewportSync>
                        ) : (
                            EMPTY_STATE
                        )}
                        {referenceEditDialog}
                        <FeatureFilter required={[FEATURE.COLLABORATION]}>
                            <DiscussionButton objectType={CollaborationObjectType.LOOKUP_VALUE} />
                        </FeatureFilter>
                        {editTypeButton}
                    </div>
                );
                break;
            }

            case 'mapping':
                page = (
                    <div
                        id="values-page-mapping-grid-container"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1 1 auto',
                            overflow: 'hidden'
                        }}
                    >
                        <ViewportSync
                            key="mapping"
                            maxIndex={maxViewportIndex}
                            startIndexStream$={this.startIndexStream$}
                            canLoad={canLoad}
                            loadMore={this.loadMoreMapped}
                        >
                            {({onScroll, gridRef, startIndex}) => (
                                <MappingGrid
                                    loadMoreMapped={this.loadMoreMapped}
                                    loadUnmapped={this.loadUnmapped}
                                    loadMoreUnmapped={this.loadMoreUnmapped}
                                    query={query}
                                    openReferenceEditWithCallback={this.openReferenceEditWithCallback}
                                    onCanonicalValueSort={this.onCanonicalValueSort}
                                    showValues={showValues}
                                    updateLookupValue={this.updateLookupValue}
                                    validateLookupValue={this.validateLookupValue}
                                    onScroll={onScroll}
                                    gridRef={gridRef}
                                    scrollToRow={startIndex}
                                    isEditMode={isEditMode}
                                />
                            )}
                        </ViewportSync>
                        {editTypeButton}
                        <FeatureFilter required={[FEATURE.COLLABORATION]}>
                            <DiscussionButton objectType={CollaborationObjectType.MAPPING_GROUP} />
                        </FeatureFilter>
                        {referenceEditDialog}
                    </div>
                );
                break;

            case 'localization':
                page = (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1 1 auto',
                            marginBottom: 2
                        }}
                    >
                        <ViewportSync
                            key="localization"
                            maxIndex={maxViewportIndex}
                            startIndexStream$={this.startIndexStream$}
                            canLoad={canLoad}
                            loadMore={this.loadMoreMapped}
                        >
                            {({onScroll, gridRef, startIndex}) => (
                                <Localization
                                    loadMoreMapped={this.loadMoreMapped}
                                    onCanonicalValueSort={this.onCanonicalValueSort}
                                    showValues={showValues}
                                    onScroll={onScroll}
                                    gridRef={gridRef}
                                    scrollToRow={startIndex}
                                    query={query}
                                    isEditMode={isEditMode}
                                />
                            )}
                        </ViewportSync>
                        {editTypeButton}
                    </div>
                );
                break;
        }

        return (
            <ErrorBoundary>
                <CacheProvider>{page}</CacheProvider>
                <StatusRightMenu
                    dispatch={dispatch}
                    isEditMode={policies.configEdit || isEditMode}
                    draftMode={draftMode}
                    editMode={isWorkflowEnabled ? editMode : ''}
                    canApplyResolver={canApplyResolver}
                    renderSearchBar={() => <ValuesSearchBar />}
                />
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state, props) => ({
    isWorkflowEnabled: getIsWorkflowEnabled(state, props),
    editMode: getEditMode(state),
    openedTask: getOpenedTask(state),
    isDCRDisplayed: getIsDCRDisplayed(state),
    ...R.pick(
        [
            'draftMode',
            'searchQuery',
            'lookupValues',
            'configuration',
            'appTitle',
            'pagination',
            'sorting',
            'filters',
            'currentType',
            'users',
            'policies'
        ],
        state
    )
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    dispatchOnResolve: getDispatchOnResolve(dispatch)
});

export default R.pipe(connect(mapStateToProps, mapDispatchToProps), withFeatures)(ValuesListPage);
