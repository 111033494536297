import * as R from 'ramda';
import SearchBar from '../SearchBar';
import TypesSearchDetails from './TypesSearchDetails';
import {Component} from 'react';
import {SEARCH_KEY_WORDS} from '../../../constants/common';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {TypesSearchQuery} from '../../../rdm-sdk/filters.types';
import {connect} from 'react-redux';
import {getIsSearchBarVisible} from '../../../redux/reducers/uiReducer';
import {initialTypeQuery} from '../initialSearchQuery';
import {logActivityCommand} from '../../../redux/actions/activityLogging';
import {typesSearchQueryEvent} from '../../../redux/actions/types';

type StateProps = {
    dispatch: (e: StateEvent) => Promise<any>;
    typesSearchQuery: TypesSearchQuery;
};

type Props = StateProps & {
    isSearchBarVisible?: boolean;
};
const KEY_WORDS = [
    SEARCH_KEY_WORDS.CODE,
    SEARCH_KEY_WORDS.NAME,
    SEARCH_KEY_WORDS.GENERATOR,
    SEARCH_KEY_WORDS.START_DATE,
    SEARCH_KEY_WORDS.END_DATE
];
export class TypesSearchBar extends Component<Props> {
    onSearch = R.pipe(typesSearchQueryEvent, this.props.dispatch);
    onReset = R.pipe(R.always(null), typesSearchQueryEvent, this.props.dispatch);

    render() {
        const logger = logActivityCommand('search-types-bar');
        const {dispatch, isSearchBarVisible = false, typesSearchQuery} = this.props;
        return (
            <SearchBar
                isVisible={isSearchBarVisible}
                logger={logger}
                dispatch={dispatch}
                searchQuery={typesSearchQuery}
                initialQuery={initialTypeQuery}
                detailsComponent={TypesSearchDetails}
                onSearch={this.onSearch}
                onReset={this.onReset}
                keywords={KEY_WORDS}
            />
        );
    }
}

const mapStateToProps = (state) =>
    ({
        isSearchBarVisible: getIsSearchBarVisible(state),
        ...R.pick(['typesSearchQuery'], state)
    }) as StateProps;

export default connect(mapStateToProps)(TypesSearchBar);
