import * as R from 'ramda';
import * as React from 'react';
import CustomMenu from '../CustomMenu/CustomMenu';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '../TextField/TextField';
import i18n from 'ui-i18n';
import moment, {Moment} from 'moment';
import styles from './styles.less';
import {BaseSingleInputFieldProps, DateValidationError, FieldSection, UseDateFieldProps} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TextFieldProps} from '@mui/material/TextField';
import {formatDate} from '../../core/date';
import {noop} from '../../core/util';
type Props = {
    id: string;
    value?: Date | null;
    onSelect: (date: Date) => void;
    readOnly?: boolean;
    label?: string | null;
    TextFieldProps?: TextFieldProps;
    iconName?: string;
    minDate?: Date;
};
type State = {
    open: boolean;
};

type CustomDateTextFieldProps = Omit<Props, 'id'> & {
    openCalendar: () => void;
    inputRef: React.Ref<HTMLDivElement>;
    showPopup: boolean;
};

interface DateTextFieldProps
    extends UseDateFieldProps<Moment>,
        BaseSingleInputFieldProps<Moment | null, Moment, FieldSection, DateValidationError> {
    openCalendar?: React.Dispatch<React.SetStateAction<boolean>>;
    onSelect?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DateTextField = React.forwardRef((props: CustomDateTextFieldProps, innerRef: React.Ref<HTMLDivElement>) => {
    const {label, value, onSelect, readOnly, openCalendar, TextFieldProps, iconName, inputRef, showPopup} = props;

    return (
        <div ref={innerRef}>
            <TextField
                ref={inputRef as React.Ref<HTMLDivElement>}
                label={label}
                readOnly
                value={formatDate(value, true)}
                {...TextFieldProps}
            />
            {showPopup ? (
                <CustomMenu
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    anchorRenderer={(onClick: React.MouseEventHandler<HTMLAnchorElement>) =>
                        (
                            <IconButton
                                className={styles['picker__menu-button']}
                                style={{
                                    visibility: readOnly ? 'hidden' : 'visible'
                                }}
                                onClick={onClick as () => void}
                                size="large"
                            >
                                <Icon>{iconName}</Icon>
                            </IconButton>
                        ) as React.ReactNode
                    }
                    itemsRenderer={(onClick) => [
                        <MenuItem key={0} onClick={R.pipe(R.always(null), onSelect, onClick)}>
                            <IconButton size="large">
                                <Icon>event_busy</Icon>
                            </IconButton>
                            {i18n.text('Never')}
                        </MenuItem>,
                        <MenuItem key={1} onClick={R.pipe(openCalendar, onClick)}>
                            <IconButton size="large">
                                <Icon>event</Icon>
                            </IconButton>
                            {i18n.text('Pick date')}
                        </MenuItem>
                    ]}
                />
            ) : (
                <IconButton
                    className={styles['picker__menu-button']}
                    style={{
                        visibility: readOnly ? 'hidden' : 'visible'
                    }}
                    onClick={openCalendar}
                    size="large"
                >
                    <Icon>{iconName}</Icon>
                </IconButton>
            )}
        </div>
    );
});

DateTextField.displayName = 'DateTextField';

export default class CustomDatePicker extends React.Component<Props, State> {
    static defaultProps = {
        onSelect: noop,
        TextFieldProps: {},
        iconName: 'arrow_drop_down'
    };
    anchorEl?: HTMLDivElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            open: false
        };
    }

    anchorElRef = (ref?: HTMLDivElement | null) => (this.anchorEl = ref);
    closeCalendar = () =>
        this.setState({
            open: false
        });
    openCalendar = () =>
        this.setState({
            open: true
        });

    TextFieldComponent = ({InputProps = {}}: DateTextFieldProps) => {
        const {value, onSelect, readOnly, label, TextFieldProps, iconName, minDate} = this.props;

        const dateTextFieldProps = {
            label,
            value,
            onSelect,
            readOnly,
            openCalendar: this.openCalendar,
            TextFieldProps,
            iconName,
            showPopup: !minDate
        };

        const {ref: inputRef = null} = InputProps;

        return <DateTextField {...dateTextFieldProps} inputRef={inputRef} />;
    };

    isFinish = true;

    render() {
        const {id, onSelect, readOnly, value, minDate} = this.props;
        const {open} = this.state;

        return (
            <div id={id} ref={this.anchorElRef} className={`picker ${readOnly ? 'readOnly' : ''}`}>
                <DatePicker<Moment>
                    views={['year', 'month', 'day']}
                    value={moment(value).isValid() ? moment(value) : moment()}
                    minDate={moment(minDate ? minDate : new Date(1970, 0, 1))}
                    onChange={(moment) => {
                        onSelect((moment as Moment).toDate());
                    }}
                    slots={{
                        field: this.TextFieldComponent
                    }}
                    open={open}
                    onClose={this.closeCalendar}
                    onOpen={this.openCalendar}
                />
            </div>
        );
    }
}
