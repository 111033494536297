import * as R from 'ramda';
import AddGeneratorDialog from '../../components/AddGeneratorDialog/AddGeneratorDialog';
import Button from '@mui/material/Button';
import CustomTable from '../../components/CustomTable/CustomTable';
import GeneratorsEmptyIcon from '../../assets/inline/generatorsEmpty.svg';
import MenuItemsButton from '../../components/MenuItemsButton/MenuItemsButton';
import draftIconCellRenderer from '../../components/CustomTable/draftIconCellRenderer';
import i18n from 'ui-i18n';
import styles from './styles.less';
import tableHeaderRenderer from '../../components/CustomTable/tableHeaderRenderer';
import typesUsedCellRenderer from './typesUsedCellRenderer';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DraftMode, SortDirection, Sorting} from '../../rdm-sdk/app.types';
import {GENERATOR_TYPES} from '../../rdm-sdk/generators';
import {Generator} from '../../rdm-sdk/generators.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {StatusRightMenu} from '../../components/AppBar/StatusLine';
import {connect} from 'react-redux';
import {generatorsRemoveEvent} from '../../redux/actions/generators';
import {getDispatchOnResolve} from '../../redux/middlewares/confirm-middleware/confirmMiddleware';
import {isExceptionRoute, sortBy} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {push, replace} from 'react-router-redux';
import {showConfirmationDialogEvent, titleEvent} from '../../redux/actions/app';

type DispatchProps = {
    dispatch: (e: StateEvent) => Promise<any>;
    dispatchOnResolve: (e: StateEvent) => Promise<any>;
};

type Props = DispatchProps & {
    configuration: Configuration;
    generators: Generator[];
    tenant: string;
    router: Record<string, any>;
    route: Record<string, any>;
    policies: CheckedPolicies;
    draftMode: DraftMode;
};
type State = {
    sorting: Sorting;
    currentGenerator: string | null;
    dialogOpen: boolean;
};
const APP_TITLE = i18n.text('Generators');
const COLUMNS = [
    {
        label: i18n.text('Name'),
        flexGrow: 0.75,
        dataKey: 'name',
        width: 720,
        cellRenderer: draftIconCellRenderer
    },
    {
        label: i18n.text('Type'),
        flexGrow: 0.1,
        dataKey: 'type',
        width: 95
    },
    {
        label: i18n.text('Lookup types used'),
        flexGrow: 0.15,
        dataKey: 'useCount',
        width: 145,
        columnData: {
            textAlign: 'right'
        },
        cellRenderer: typesUsedCellRenderer
    }
];
const logger = logActivityCommand('generators-table');
const addGeneratorLogger = logger('add-generator-click');
export class GeneratorsPage extends Component<Props, State> {
    state = {
        sorting: {
            field: 'name',
            direction: SortDirection.ASC
        },
        currentGenerator: null,
        dialogOpen: false
    } as State;

    componentDidMount() {
        const {dispatch, router, route, dispatchOnResolve} = this.props;
        dispatch(titleEvent(APP_TITLE));
        router.setRouteLeaveHook(route, (nextRoute) => {
            const {draftMode} = this.props;

            if (draftMode.dirtyGenerators && !isExceptionRoute(nextRoute)) {
                if (nextRoute.action === 'POP') dispatch(replace(router.location));
                dispatch(showConfirmationDialogEvent());
                dispatchOnResolve(push(nextRoute));
                return false;
            }
        });
    }

    setSorting = (sorting: Sorting) =>
        this.setState({
            sorting
        });
    resetAndClose = () =>
        this.setState({
            dialogOpen: false,
            currentGenerator: null
        });

    render() {
        const {
            generators,
            configuration: {lookupTypes},
            dispatch,
            tenant,
            policies,
            draftMode
        } = this.props;
        const {sorting, currentGenerator, dialogOpen} = this.state;
        const usedGenerators = R.countBy(R.prop('generator'), lookupTypes);
        const data = R.pipe(
            R.map((gen) =>
                Object.assign({}, gen, {
                    useCount: R.propOr(0, gen.name, usedGenerators),
                    type: GENERATOR_TYPES[gen.type]
                })
            ),
            sortBy(sorting.field, sorting.direction)
        )(generators);
        const EMPTY_STATE = (
            <div className={'empty-state'}>
                <div
                    className={'empty-state__icon'}
                    dangerouslySetInnerHTML={{
                        __html: GeneratorsEmptyIcon
                    }}
                />
                <h3 className={'empty-state__header'}>{i18n.text('There are no generators for this tenant')}</h3>
                <p className={'empty-state__text'}>
                    {i18n.text('Create a new generator to automatically generate canonical codes')}
                </p>
                <Button
                    color="primary"
                    variant="contained"
                    className={'empty-state__button'}
                    onClick={() =>
                        this.setState({
                            dialogOpen: true
                        })
                    }
                >
                    {i18n.text('Create generator')}
                </Button>
            </div>
        );
        const headerRenderer = tableHeaderRenderer({
            caption: i18n.text('Generators'),
            summary:
                generators.length > 1
                    ? i18n.text('${count} generators', {
                          count: generators.length
                      })
                    : i18n.text('${count} generator', {
                          count: generators.length
                      }),
            logger
        });
        return (
            <div className={styles['generators-page__content']}>
                {generators.length > 0 ? (
                    <CustomTable
                        isEditMode={policies.generatorsEdit}
                        logger={logger}
                        primaryHeaderRenderer={headerRenderer}
                        data={data}
                        selectKey={'name'}
                        columns={COLUMNS}
                        columnsData={{
                            dispatch,
                            tenant
                        }}
                        onDelete={(selected) => {
                            dispatch(generatorsRemoveEvent(R.pluck('name', selected)));
                        }}
                        sorting={{
                            sort: sorting,
                            onChange: this.setSorting
                        }}
                        onRowClick={({rowData}) => {
                            this.setState({
                                currentGenerator: rowData.name,
                                dialogOpen: true
                            });
                        }}
                    />
                ) : (
                    EMPTY_STATE
                )}
                <AddGeneratorDialog
                    open={dialogOpen}
                    generator={currentGenerator ? generators.find(R.propEq('name', currentGenerator)) : null}
                    onCancelled={this.resetAndClose}
                    onCreated={this.resetAndClose}
                />
                <MenuItemsButton
                    onClick={() => {
                        this.setState({
                            dialogOpen: true
                        });
                        dispatch(addGeneratorLogger() as StateEvent);
                    }}
                >
                    {i18n.text('GENERATOR')}
                </MenuItemsButton>
                <StatusRightMenu dispatch={dispatch} isEditMode={policies.generatorsEdit} draftMode={draftMode} />
            </div>
        );
    }
}
const mapStateToProps = R.pick(['generators', 'configuration', 'tenant', 'policies', 'draftMode']);

const mapDispatchToProps = (dispatch) =>
    ({
        dispatch,
        dispatchOnResolve: getDispatchOnResolve(dispatch)
    }) as DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(GeneratorsPage);
