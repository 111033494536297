import store from '../redux/store';
import {initialQuery} from '../components/Search/initialSearchQuery';
import {searchQueryEvent} from '../redux/actions/search';
import {setIsSearchBarVisibleEvent, setOpenedCommentEvent} from '../redux/actions/ui';
export default function openValuesComment(router, replace, next) {
    const {tenant} = store.getState();
    const {dispatch} = store;
    const {
        params: {tab, tenantId, type, code}
    } = router;

    if (tenant) {
        window.location.reload();
        return;
    }

    replace(`/${tenantId}/types/${type}/${tab}`);
    dispatch(
        setOpenedCommentEvent({
            code
        })
    );
    const query = {
        ...initialQuery,
        canonicalCode: {
            type: 'equals',
            negate: false,
            value: code
        }
    };
    dispatch(searchQueryEvent(query));
    dispatch(setIsSearchBarVisibleEvent(true));
    return next();
}
