import {getLocale, loadLocale} from './core/translations';
import {getUrlTenant} from './core/util';
import {json} from './network/index';
const locale = getLocale();

if (locale.startsWith('en')) {
    require('./app.tsx');
} else {
    fetch(`/translations/${locale}?tenant=${getUrlTenant()}`)
        .then(json)
        .then(loadLocale(locale))
        .catch((error) => {
            console.error('Fetch locations error', error);
        })
        .finally(() => {
            require('./app.tsx');
        });
}
