/* eslint-disable complexity */
import * as R from 'ramda';
import initialState from '../initialState';
import {checkEdited, checkNewTruly, checkRemoved, checkViewOnly} from '../../core/marks';
// eslint-disable-next-line prettier/prettier
export default function draftModeReducer(state = initialState.draftMode, event) { // NOSONAR
    switch (event.type) {
        case 'generatorsUpsert':
        case 'generatorsRemove':
        case 'generatorForTypeUpsert':
            return R.assoc('dirtyGenerators', true, state);

        case 'generatorsFetch':
            return R.assoc('dirtyGenerators', false, state);

        case 'addType':
        case 'updateType':
        case 'lookupTypesRemove':
            return R.assoc('dirtyTypes', true, state);

        case 'addSource':
        case 'updateSource':
        case 'deleteSources':
            return R.assoc('dirtySources', true, state);

        case 'tenantConfiguration':
            return R.pipe(R.assoc('dirtyTypes', false), R.assoc('dirtySources', false))(state);

        case 'lookupValuesRemove':
            return R.assoc('dirtyValues', true, state);

        case 'upsertValue':
            return (checkNewTruly(event.value) || checkEdited(event.value) || checkRemoved(event.value)) &&
                !checkViewOnly(event.value)
                ? R.assoc('dirtyValues', true, state)
                : state;

        case 'valuesReceived':
            return R.assoc('dirtyValues', false, state);

        default:
            return state;
    }
}
export const getIsDirty = R.pipe(R.values, R.any(R.identity));
export const getIsValuesDirty = R.prop('dirtyValues');
export const getIsConfigurationDirty = R.either(R.prop('dirtySources'), R.prop('dirtyTypes'));
