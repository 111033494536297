import * as R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '../../Checkbox/Checkbox';
import DatePicker from '../../DatePicker/DatePicker';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import i18n from 'ui-i18n';
import styles from '../style.less';
import {ALL_GENERATORS_SEARCH_OPTION} from '../../../rdm-sdk/generators';
import {Component} from 'react';
import {Generator} from '../../../rdm-sdk/generators.types';
import {Maybe} from 'monet';
import {TypesSearchQuery} from '../../../rdm-sdk/filters.types';
import {connect} from 'react-redux';
import {fromString, isNotNull} from '../../../core/maybe';
import {getChecked, getValue} from '../../../core/util';
import {pipe} from '../../../core/func';

const getTypeString = ({type, negate}) => (negate ? `!${type}` : type);

type ChangeEvent = {
    value: string | number | Date;
    type: string;
};

type StateProps = {
    generators: Generator[];
};

type Props = StateProps & {
    onChange: (e: ChangeEvent) => void;
    query: TypesSearchQuery;
    onSearch: (query: TypesSearchQuery) => void;
    onReset: () => void;
};
const OPTIONS = [
    {
        label: i18n.text('Contains'),
        value: 'contains'
    },
    {
        label: i18n.text('Does not contain'),
        value: '!contains'
    },
    {
        label: i18n.text('Starts with'),
        value: 'startsWith'
    }
];
export class TypesSearchDetails extends Component<Props> {
    next = (type: string) => (value: string | number | Date) =>
        this.props.onChange({
            value,
            type
        });

    render() {
        const {onSearch, onReset, query, generators} = this.props;
        const generatorOptions = R.pluck(['name'], generators);
        const filterOnGenerator = isNotNull(query.generator);
        return (
            <div className={`${styles.detailsForm}`}>
                <div className={`${styles.detailsFormRow}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Has the words')}</div>
                    <TextField
                        variant="standard"
                        id="searchHasWordsTextField"
                        onChange={pipe(getValue, this.next('hasWords'))}
                        value={fromString(query.hasWords).orSome('')}
                        fullWidth
                    />
                </div>
                <div className={`${styles.detailsFormRow}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Name')}</div>
                    <FormControl variant="standard" className={styles['form-control']}>
                        <Select
                            variant="standard"
                            id="types-search-details-name-select"
                            value={getTypeString(query.name)}
                            onChange={R.pipe(getValue, this.next('nameType'))}
                            input={
                                <Input
                                    classes={{
                                        input: styles['select-input']
                                    }}
                                />
                            }
                        >
                            {OPTIONS.map((option) => (
                                <MenuItem dense key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        variant="standard"
                        id="searchAllNamesTextField"
                        name="all_names"
                        value={Maybe.fromNull(query.name.value).orSome('')}
                        onChange={pipe(getValue, this.next('nameValue'))}
                        placeholder={i18n.text('all names')}
                    />
                </div>
                <div className={`${styles.detailsFormRow}`}>
                    <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Code')}</div>
                    <FormControl variant="standard" className={styles['form-control']}>
                        <Select
                            variant="standard"
                            id="types-search-details-code-select"
                            value={getTypeString(query.code)}
                            onChange={R.pipe(getValue, this.next('codeType'))}
                            input={
                                <Input
                                    classes={{
                                        input: styles['select-input']
                                    }}
                                />
                            }
                        >
                            {OPTIONS.map((option) => (
                                <MenuItem dense key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        variant="standard"
                        id="searchAllCodesTextField"
                        name="all_codes"
                        value={Maybe.fromNull(query.code.value).orSome('')}
                        onChange={pipe(getValue, this.next('codeValue'))}
                        placeholder={i18n.text('all codes')}
                    />
                </div>

                <div>
                    <div className={styles.pickerWrapper}>
                        <DatePicker
                            id="searchStartDatePicker"
                            label={i18n.text('Start date')}
                            // @ts-ignore
                            value={Maybe.fromNull(query.startDate).orSome(null)}
                            onSelect={this.next('startDate')}
                        />
                    </div>
                    <div className={styles.pickerWrapper}>
                        <DatePicker
                            id="searchEndDatePicker"
                            label={i18n.text('End date')}
                            // @ts-ignore
                            value={Maybe.fromNull(query.endDate).orSome(null)}
                            onSelect={this.next('endDate')}
                        />
                    </div>
                </div>

                <div className={styles['has-missing']}>
                    <Checkbox
                        id="searchFilterOnGeneratorCheckbox"
                        className={styles.showDisabledCheckBox}
                        label={i18n.text('Has generator')}
                        onChange={pipe(
                            R.ifElse(getChecked, R.always(ALL_GENERATORS_SEARCH_OPTION), R.always(null)),
                            this.next('generator')
                        )}
                        checked={filterOnGenerator}
                    />
                </div>

                {filterOnGenerator && (
                    <div className={`${styles.detailsFormRow}`}>
                        <div className={`${styles.detailsFormRowLabel}`}>{i18n.text('Generator')}</div>
                        <FormControl variant="standard" className={styles['form-control']}>
                            <Select
                                variant="standard"
                                id="types-search-details-generator-select"
                                value={query.generator}
                                onChange={R.pipe(getValue, this.next('generator'))}
                                input={
                                    <Input
                                        classes={{
                                            input: styles['select-input']
                                        }}
                                        id="type-search-generator"
                                    />
                                }
                            >
                                {generatorOptions
                                    .map((option, i) => (
                                        <MenuItem dense key={i} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))
                                    .concat(
                                        <MenuItem
                                            dense
                                            key={generatorOptions.length}
                                            value={ALL_GENERATORS_SEARCH_OPTION}
                                        >
                                            {ALL_GENERATORS_SEARCH_OPTION}
                                        </MenuItem>
                                    )}
                            </Select>
                        </FormControl>
                    </div>
                )}

                <div className={styles.detailsButtonRow}>
                    <Button variant="grey" onClick={onReset}>
                        {i18n.text('Clear filter')}
                    </Button>
                    <Button
                        color="primary"
                        style={{
                            float: 'right'
                        }}
                        onClick={() => onSearch(query)}
                    >
                        {i18n.text('Search')}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps: (state) => StateProps = R.pick(['generators']);
export default connect(mapStateToProps)(TypesSearchDetails);
