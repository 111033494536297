import * as R from 'ramda';
import Button from '@mui/material/Button';
import CollaborationMenu from '../../CollaborationMenu/CollaborationMenu';
import CommentContent from '../../CommentContent/CommentContent';
import CommentDialogInput from '../../CommentDialogInput/CommentDialogInput';
import CustomIcon from './../../CustomIcon/CustomIcon';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './comment.less';
import {Comment as CommentType, Reply} from '../../../rdm-sdk/collaboration.types';
import {PureComponent} from 'react';
import {User} from '../../../rdm-sdk/app.types';
import {formatCommentTime} from '../../../rdm-sdk/utils';
type Props = {
    comment: CommentType | Reply;
    onResolveClick: (comment: CommentType) => Promise<any>;
    onCommentDeleteClick: (comment: CommentType) => Promise<any>;
    onReplyDeleteClick: (reply: Reply) => Promise<any>;
    onCommentEdited: (comment: CommentType, content: string) => Promise<any>;
    onReplyEdited: (reply: Reply, content: string) => Promise<any>;
    username: string;
    users: User[];
};
type State = {
    isEditing: boolean;
};

class Comment extends PureComponent<Props, State> {
    state = {
        isEditing: false
    };
    toggleIsEditing = () =>
        this.setState(
            R.evolve({
                isEditing: R.not
            })
        );
    onEdited = (content: string): Promise<any> => {
        const {onCommentEdited, onReplyEdited, comment} = this.props;

        if ((comment as CommentType).commentId) {
            return onCommentEdited(comment as CommentType, content);
        }

        if ((comment as Reply).replyId) {
            return onReplyEdited(comment as Reply, content);
        }

        return Promise.resolve();
    };
    exitEditing = () =>
        this.setState({
            isEditing: false
        });

    render() {
        const {username, comment, onResolveClick, onCommentDeleteClick, onReplyDeleteClick, users} = this.props;
        const {isEditing} = this.state;
        const isComment = R.has('commentId', comment);
        const hasAssignee = R.has('assignee', comment);
        const isOwner = username === comment.createdBy;
        const isReplyResolved = (comment as Reply).action && (comment as Reply).action === 'resolve';
        const isReplyReopened = (comment as Reply).action && (comment as Reply).action === 'reopen';
        const contentIsNotEmpty = !R.isEmpty(comment.content);
        return (
            <div className={clsx(styles['comment'], hasAssignee && styles['comment--assigned'])}>
                <div className={styles['comment__header']}>
                    <div className={styles['comment__avatar']}>
                        <CustomIcon name="face" />
                    </div>
                    <div className={styles['comment__info']}>
                        <p title={comment.createdBy} className={styles['comment__username']}>
                            {comment.createdBy}
                        </p>
                        <p className={styles['comment__time']}>{formatCommentTime(comment.createdTime)}</p>
                    </div>
                    {isComment && !hasAssignee && (
                        <Button
                            className={styles['comment__resolve']}
                            onClick={R.pipe(R.always(comment), onResolveClick)}
                        >
                            {i18n.text('Resolve')}
                        </Button>
                    )}
                    {isOwner && !isReplyResolved && (!isReplyReopened || contentIsNotEmpty) && (
                        <CollaborationMenu
                            onEditClick={this.toggleIsEditing}
                            onDeleteClick={() => {
                                if ((comment as CommentType).commentId) {
                                    return onCommentDeleteClick(comment as CommentType);
                                }

                                if ((comment as Reply).replyId) {
                                    return onReplyDeleteClick(comment as Reply);
                                }
                            }}
                            canDelete={!isReplyReopened}
                        />
                    )}
                </div>
                {isEditing ? (
                    <CommentDialogInput
                        value={comment.content}
                        onSendClick={this.onEdited}
                        users={users}
                        exitEditing={this.exitEditing}
                    />
                ) : (
                    contentIsNotEmpty && <CommentContent>{comment.content}</CommentContent>
                )}
                {isReplyResolved && <p className={styles['comment__action-info']}>{i18n.text('Marked as resolved')}</p>}
                {isReplyReopened && <p className={styles['comment__action-info']}>{i18n.text('Re-opened')}</p>}
            </div>
        );
    }
}

export default Comment;
