import CustomChange from './CustomChange';
import TruncateText from '../../TruncateText/TruncateText';
import clsx from 'clsx';
import styles from './styles.less';
import {ChangeType} from '../activityLog.types';
import {fromNull} from '../../../core/maybe';
type ChangeValueProps = {
    value: string;
    className?: string;
};

const ChangeValue = ({value, className}: ChangeValueProps) => {
    return value ? (
        <span className={clsx(styles['change__value'], className)} title={value}>
            <TruncateText>{value}</TruncateText>
        </span>
    ) : null;
};

const Arrow = () => <span className={styles['change__arrow']}>→</span>;

function Change(props: ChangeType) {
    const {
        displayName,
        newValue: newValueProp = null,
        oldValue: oldValueProp = null,
        type,
        className,
        children
    } = props;
    const newValue = fromNull(newValueProp).orSome('').toString();
    const oldValue = fromNull(oldValueProp).orSome('').toString();
    return (
        <CustomChange
            className={className}
            type={type}
            displayName={displayName}
            postfix={newValue || oldValue ? ':' : ''}
        >
            {children
                ? children
                : (newValue || oldValue) && (
                      <span className={styles['change__values']}>
                          <span className={styles['change__first-row']}>
                              <ChangeValue value={oldValue} />
                              {newValue && oldValue && <Arrow />}
                          </span>
                          <ChangeValue value={newValue} className={styles['change__new-value']} />
                      </span>
                  )}
        </CustomChange>
    );
}

export default Change;
