/**
 * Created by ndyumin on 28.12.2016.
 */
import * as R from 'ramda';
import clone from 'clone';
import {AUTO_GENERATED_CODE_PREFIX, REPLACEABLE_CODE_PREFIX} from '../constants/common';
import {AttributeExt, LookupValue, SourceMapping, Sources} from './lookups.types';
import {I, curry, filter, map, pipe} from '../core/func';
import {LookupType} from './types.types';
import {Maybe} from 'monet';
import {
    checkClientOnly,
    checkEdited,
    checkEmpty,
    checkErroneous,
    checkNew,
    checkRemoved,
    getUnmappedKey,
    markClientOnly,
    markEdited,
    markErroneous,
    markRemoved,
    rejectEmpty,
    rejectRemoved
} from '../core/marks';
import {fromDefined, fromNull, get} from '../core/maybe';
import {inArray, inArrayByIndex, prop, safeProp} from '../core/lenses';
import {orSome} from '../core/monet';
import {typeCodeToUri} from './types';

const notEmpty = (arr) => arr.length > 0;

const truthy = (value) => !!value;

const canonicalValueL = prop('canonicalValue');
const downStreamDefaultValueL = prop('downStreamDefaultValue');
const hasDefaultDownstreamValueL = inArray('downStreamDefaultValue', true);
const sourcesL = prop('sources');
export const parseSources = R.reduceBy(
    (acc, source) =>
        R.pipe(
            R.when(R.always(acc.length), R.map(R.assoc('downStreamDefaultValue', false))),
            R.concat(acc)
        )(source.values),
    [],
    R.prop('source')
);
export const codeToUri = (type: string, code: string) => `${typeCodeToUri(type)}/${code}`;
export const parseLookupUri = (uri: string) => {
    const [tenant, type, ...codeParts] = uri.split('/');
    const code = codeParts.join('/');
    return {
        tenant,
        type,
        code
    };
};
export const isCanonical = pipe(Maybe.fromNull, safeProp('canonicalValue').get, orSome('false'));
export const hasCanonical = pipe(
    Maybe.fromNull,
    safeProp('values').get,
    map(filter(isCanonical)),
    map((arr: SourceMapping[]) => arr.length > 0),
    orSome(false)
);
export function getCanonical(
    sources?:
        | {
              source: string;
              values: SourceMapping[] | null;
          }[]
        | null
) {
    return fromDefined(sources)
        .map(filter(hasCanonical))
        .filter(notEmpty)
        .map(get(0))
        .map(get('values'))
        .map((values) => values.filter(isCanonical))
        .map(get(0))
        .map(get('value'));
}
export function parseLookupValue(value: Record<string, any>) {
    return {
        code: value.code,
        startDate: value.startDate,
        endDate: value.endDate,
        canonical: getCanonical(value.sourceMappings).orSome(''),
        sources: parseSources(value.sourceMappings),
        parents: value.parents,
        attributes: value.attributes,
        localizations: value.localizations,
        enabled: value.enabled
    };
}
export function parseLookupValues(lookupValues: Array<Record<string, any>>) {
    return lookupValues.map(parseLookupValue);
}
export const formatMapping = I;
export const formatMappings = (sourcesObj: Sources) =>
    Object.keys(sourcesObj).map((key) => ({
        source: key,
        values: sourcesObj[key].map(formatMapping)
    }));
export const formatReference = curry((tenantId, type, reference) => {
    const updatedReference = clone(reference);
    updatedReference.sourceMappings = formatMappings(updatedReference.sources);
    updatedReference.tenantId = tenantId;
    updatedReference.type = `rdm/lookupTypes/${type}`;
    delete updatedReference.sources;
    delete updatedReference.canonical;

    if (updatedReference.code.startsWith(AUTO_GENERATED_CODE_PREFIX)) {
        delete updatedReference.code;
    }

    return updatedReference;
});
export const removeMapping = curry((source, index, value) => {
    value = clone(value);
    const l = prop('sources').compose(prop(source));
    return l.set(
        value,
        l
            .get(value)
            .map((mapping, i) => (index !== i ? mapping : markRemoved({...mapping, downStreamDefaultValue: false})))
    );
});

const resetMappingProp = (prop) => (mapping) =>
    mapping[prop]
        ? Object.assign(markEdited(mapping), {
              [prop]: false
          })
        : mapping;

const resetPropInMappings = (prop) => R.pipe(fromNull, map(map(resetMappingProp(prop))), orSome(null));

export const resetCanonicalValue = (value: LookupValue) => ({
    ...value,
    sources: R.map(resetPropInMappings('canonicalValue'), value.sources)
});
export const resetDownstreamValue = (source: string) => (value: LookupValue) => ({
    ...value,
    sources: {
        ...value.sources,
        [source]: resetPropInMappings('downStreamDefaultValue')(value.sources[source])
    }
});

const setCanonical = (canonical) => (value) => ({...value, canonical});

export const reconcileValueWithMapping = (
    source: string,
    {canonicalValue, downStreamDefaultValue, value}: SourceMapping,
    lookupValue: LookupValue
) =>
    R.pipe(
        R.when(R.always(canonicalValue), R.pipe(resetCanonicalValue, setCanonical(value))),
        R.when(R.always(downStreamDefaultValue), resetDownstreamValue(source))
    )(lookupValue);
export const updateMapping = curry((source, index, value, mapping) => {
    value = clone(value);
    const l = prop('sources').compose(prop(source)).compose(inArrayByIndex(index));
    const originalMapping = l.get(value);
    let updatedMapping = Object.assign({}, originalMapping, mapping);

    if (checkClientOnly(value) && !value.canonical) {
        value.code = (value.code.startsWith(REPLACEABLE_CODE_PREFIX) && mapping.code) || value.code;
        updatedMapping = Object.assign(updatedMapping, {
            canonicalValue: true
        });
    }

    value = reconcileValueWithMapping(source, updatedMapping, value);
    return l.set(value, markEdited(updatedMapping));
});
export const insertMapping = curry((source, value, mapping) => {
    value = reconcileValueWithMapping(source, mapping, clone(value));
    const l = prop('sources').compose(prop(source));
    return l.set(value, [].concat(l.get(value), markClientOnly(mapping)).filter(truthy));
});
export const getCanonicalValue = (entry: LookupValue) => {
    const calculated = Object.values(entry.sources)
        .filter(truthy)
        .map(filter(isCanonical))
        .reduce((acc, mappings) => acc.concat(mappings), []);
    return safeProp('value').get(Maybe.fromNull(calculated[0]));
};
export const hasCanonicalInSourceMapping = (entries: SourceMapping[]) =>
    entries.some((entry) => canonicalValueL.get(entry));
export const resetCanonicalInSourceMapping = (entries: SourceMapping[]) =>
    entries.map((entry) => canonicalValueL.set(entry, false));
export const resetCanonicalInSourceMappings = (mappings: Record<string, any>) => {
    const newMappings = {};
    Object.keys(mappings).forEach((source) => {
        newMappings[source] = resetCanonicalInSourceMapping(mappings[source]);
    });
    return newMappings;
};
export const setDefaultSourceMappings = (mappings: Record<string, any>) => {
    const newMappings = {};
    const firstSource = Object.keys(mappings)[0];
    let hasCanonical = false;
    Object.keys(mappings).forEach((source) => {
        const entries = mappings[source];
        hasCanonical = hasCanonical || hasCanonicalInSourceMapping(entries);
        newMappings[source] = hasDefaultDownstreamValueL.get(entries)
            ? entries
            : [downStreamDefaultValueL.set(entries[0], true), ...entries.slice(1)];
    });
    if (!hasCanonical && firstSource)
        newMappings[firstSource] = [
            canonicalValueL.set(newMappings[firstSource][0], true),
            ...newMappings[firstSource].slice(1)
        ];
    return newMappings;
};

const countFlags = (prop) =>
    R.reduce((sum, mapping) => {
        if (R.propOr(false, prop, mapping)) {
            return sum + 1;
        }

        return sum;
    }, 0);

export const countCanonicals = R.pipe(R.propOr({}, 'sources'), R.values, R.flatten, countFlags('canonicalValue'));
export const countDownstream = R.curry((source, lookupValue) =>
    R.pipe(R.pathOr([], ['sources', source]), countFlags('downStreamDefaultValue'))(lookupValue)
);
export const getLocalizationCodes: (arg0: LookupValue[]) => string[] = R.reduce((codes, lookupValue) => {
    if (!lookupValue.localizations) return codes;
    if (!lookupValue.localizations.length) return codes;
    const languageCodes = R.pluck('languageCode', lookupValue.localizations);
    languageCodes.forEach((code) => {
        if (!codes.includes(code)) {
            codes = codes.concat(code);
        }
    });
    return codes;
}, []);

const isMappingValid = (mapping: SourceMapping) =>
    (mapping && mapping.code && mapping.code.length && mapping.value && mapping.value.length) ||
    checkEmpty(mapping) ||
    checkRemoved(mapping);

export const isMappingsValid: (mappings: SourceMapping[]) => boolean = R.all(isMappingValid);
export const markErroneousMappings: (mappings: SourceMapping[]) => SourceMapping[] = R.map(
    R.ifElse(isMappingValid, markErroneous(false), markErroneous(true))
);
export const validateSourceMappings = (lookupValue: LookupValue): LookupValue => {
    const sources = R.propOr({}, 'sources', lookupValue);
    const sourceNames = Object.keys(sources);
    let hasError = false;
    let mappingsCount = 0;
    sourceNames.forEach((sourceName) => {
        const mappings = sources[sourceName];
        mappingsCount += mappings.length;
        sources[sourceName] = markErroneousMappings(mappings);

        if (!isMappingsValid(mappings)) {
            hasError = true;
        }
    });
    hasError = hasError || sourceNames.length === 0 || mappingsCount === 0;
    return Object.assign({}, hasError ? markErroneous(true, lookupValue) : markErroneous(false, lookupValue), {
        sources
    });
};
export const getExtendedAttributes = (lookupValue: LookupValue, currentTypeConf: LookupType): AttributeExt[] =>
    R.pipe(
        R.propOr([], 'attributes'),
        R.map((attr) =>
            Object.assign({}, attr, R.find(R.propEq('name', attr.name), R.defaultTo([], lookupValue.attributes)))
        )
    )(currentTypeConf);
const shouldIncrementLookupValuesCount: (mappings: SourceMapping[]) => boolean = R.ifElse(
    R.all(checkClientOnly),
    R.any(R.either(checkEdited, checkNew)),
    R.F
);
const shouldDecrementLookupValuesCount: (mappings: SourceMapping[]) => boolean = R.pipe(
    rejectEmpty,
    R.both(R.length, R.all(checkRemoved))
);
export const countAddedLookupValues = (lookupValues: LookupValue[]) => {
    return lookupValues.reduce((countBySource, value) => {
        const countBySourceInValue = Object.keys(value.sources).reduce((countObj, source) => {
            const mappings = value.sources[source];
            let score = 0;

            if (shouldIncrementLookupValuesCount(mappings)) {
                score = 1;
            }

            if (shouldDecrementLookupValuesCount(mappings)) {
                score = -1;
            }

            return Object.assign({}, countObj, {
                [source]: R.propOr(0, source, countObj) + score
            });
        }, {});
        return R.mergeWith(R.add, countBySource, countBySourceInValue);
    }, {});
};
export const LAST_ROW_CODE = '__lastRowCode__';
export const getNextLookupValueCode = (code: string, lookupValues: LookupValue[]) => {
    const index = R.findIndex(R.propEq('code', code), lookupValues);
    if (index === -1) return code;
    const next = lookupValues[index + 1];
    return next ? next.code : LAST_ROW_CODE;
};
export const getPreviousLookupValue = (code: string, lookupValues: LookupValue[]) => {
    if (code === LAST_ROW_CODE) return lookupValues[lookupValues.length - 1];
    const index = R.findIndex(R.propEq('code', code), lookupValues);
    return lookupValues[index - 1];
};
export const buildUnmappedCodes = R.reduce((unmappedCodes, lookupValue) => {
    const unmappedCode = getUnmappedKey(lookupValue);
    return unmappedCode
        ? Object.assign({}, unmappedCodes, {
              [lookupValue.code]: unmappedCode
          })
        : unmappedCodes;
}, {});
export const getOriginalUnmappedCodeCreator = R.curry((lookupValues, code) => {
    const unmappedCodes = buildUnmappedCodes(lookupValues);
    return R.has(code, unmappedCodes) ? unmappedCodes[code] : code;
});
const pickRelevantFields = R.pick(['code', 'value', 'canonicalValue', 'downStreamDefaultValue', 'enabled']);
export const mappingsSignificantlyChanged = (currValue: LookupValue, prevValue: LookupValue) =>
    R.pipe(
        R.propOr({}, 'sources'),
        R.mapObjIndexed((mappings, source) =>
            mappings.reduce((isSignificantlyChanged, currMapping, mappingIndex) => {
                if (checkClientOnly(currMapping)) {
                    return true;
                } else if (checkEdited(currMapping)) {
                    const prevMapping = R.pathOr(null, ['sources', source, mappingIndex], prevValue);
                    return prevMapping && !R.eqBy(pickRelevantFields, currMapping, prevMapping);
                } else if (checkRemoved(currMapping)) {
                    return true;
                } else {
                    return isSignificantlyChanged;
                }
            }, false)
        ),
        R.values,
        R.any(R.identity)
    )(currValue);
export const canSaveLookups = R.none(R.both(checkErroneous, R.complement(checkRemoved)));
export const rejectRemovedAndEmptyMappings = (reference: LookupValue): LookupValue => {
    const sources = sourcesL.get(reference);
    return sourcesL.set(
        reference,
        Object.keys(sources).reduce((updated, sourceName) => {
            return Object.assign(updated, {
                [sourceName]: pipe(R.prop(sourceName), rejectRemoved, rejectEmpty)(sources)
            });
        }, {})
    );
};
export const rejectRemovedLocalizations = R.evolve({
    localizations: R.unless(R.isNil, rejectRemoved)
});
export const markMappingsRemoved = (mappings: Sources): Sources =>
    Object.keys(mappings).reduce((updated, source) => prop(source).set(updated, mappings[source].map(markRemoved)), {});
export const markLookupValueRemoved = R.pipe(
    (value) =>
        Object.assign({}, value, {
            sources: markMappingsRemoved(value.sources),
            localizations: value.localizations ? R.map(markRemoved, value.localizations) : value.localizations
        }),
    markRemoved
);
export const getLookupValueUri = R.curry((tenantName, type, code) => {
    return `${tenantName}/${type}/${code}`;
});
