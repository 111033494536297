import * as R from 'ramda';
import Checkbox from '../../Checkbox/Checkbox';
import CustomMultiSelect from '../../CustomMultiSelect/CustomMultiSelect';
import CustomSwitch from '../../CustomSwitch/CustomSwitch';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import languagesMock from '../../Localization/languagesMock';
import styles from './styles.less';
import {Component, Fragment} from 'react';
import {ExportDetails} from '../../../rdm-sdk/export.types';
import {ExportFileFormat} from '../../../rdm-sdk/export';
import {GroupedOptions, Options} from '../../../rdm-sdk/app.types';
import {connect} from 'react-redux';
import {getChecked, getValue} from '../../../core/util';
import {getTypeByUri} from '../../../rdm-sdk/configuration';
const FILE_FORMATS_DATA = [
    {
        label: i18n.text('CSV'),
        value: ExportFileFormat.CSV,
        hint: i18n.text('Simple file format used to store tabular data as comma-separated values')
    },
    {
        label: i18n.text('JSON'),
        value: ExportFileFormat.JSON,
        hint: i18n.text('File format that uses human-readable text to transmit data objects')
    }
];
const ATTR_DELIMITER = ':';
const attrOptionToValue = R.reduceBy(
    (acc, option) => R.pipe(R.split(ATTR_DELIMITER), R.tail(), R.concat(acc))(option),
    [],
    R.pipe(R.split(ATTR_DELIMITER), R.head)
);
const attrValueToOption = R.pipe(
    R.toPairs,
    R.map(([typeUri, attrs]) => R.xprod([typeUri], attrs)),
    R.unnest,
    R.map(R.join(ATTR_DELIMITER))
);

const getLanguageLabel = (code) => R.pipe(R.find(R.propEq('code', code)), R.propOr(code, 'label'))(languagesMock);

type Props = {
    exportDetails: ExportDetails;
    setExportDetails: (properties: ExportDetails) => void;
    sourceOptions: Options;
    attributeOptions: GroupedOptions;
};
export class ExportStep2 extends Component<Props> {
    next = (key: string) => (value: any) =>
        this.props.setExportDetails({
            ...this.props.exportDetails,
            [key]: value
        });
    getAuditData = (key: string) => (value: string) => ({
        ...this.props.exportDetails.auditData,
        [key]: value
    });

    render() {
        const {
            exportDetails: {sourceUris, attributes, languageCodes, exportParents, fileFormat, auditData},
            sourceOptions,
            attributeOptions
        } = this.props;
        const {exportUpdateInfo = false, exportVersion = false} = auditData || {};
        const exportSourceValues = !!sourceUris;

        const getSourceLabel = (uri) => R.pipe(R.find(R.propEq('value', uri)), R.propOr(uri, 'label'))(sourceOptions);

        const exportLocalization = !!languageCodes;
        const languageOptions = languagesMock.map(({code, label}) => ({
            value: code,
            label
        }));
        const exportAttributes = !!attributes;

        const getAttributeLabel = (value) => {
            const [typeUri, attrName] = value.split(ATTR_DELIMITER);
            const {groupLabel} = R.find(R.propEq('groupValue', typeUri), attributeOptions);
            return `${groupLabel}: ${attrName}`;
        };

        const exportAuditData = !!auditData;
        return (
            <div className={styles['step-container']}>
                <Paper elevation={2} className={styles.paper}>
                    <Typography variant="subtitle1" className={styles.subheading}>
                        {i18n.text('Select properties to export')}
                    </Typography>

                    <CustomSwitch
                        className={styles.switch}
                        label={i18n.text('Source values and codes')}
                        hintForOffState={i18n.text(
                            'Turn on if you want to export the raw source values and codes from selected sources'
                        )}
                        hintForOnState={i18n.text(
                            "Turn off if you don't want to export the raw source values and codes"
                        )}
                        checked={exportSourceValues}
                        onChange={R.pipe(R.ifElse(getChecked, R.always([]), R.always(null)), this.next('sourceUris'))}
                        classes={{
                            label: styles['switch-label']
                        }}
                    />
                    {exportSourceValues && (
                        <div className={styles['multi-select']}>
                            <InputLabel htmlFor="select-sources" disableAnimation shrink>
                                {i18n.text('Sources')}
                            </InputLabel>
                            <CustomMultiSelect
                                id="export-sources-select"
                                input={<Input id="select-sources" />}
                                value={sourceUris}
                                onSelect={this.next('sourceUris')}
                                className={styles['multi-select__control']}
                                placeholder={i18n.text('All sources')}
                                options={sourceOptions}
                                getChipLabel={getSourceLabel}
                            />
                        </div>
                    )}

                    <CustomSwitch
                        className={styles.switch}
                        label={i18n.text('Custom attributes')}
                        hintForOffState={i18n.text(
                            'Turn on if you want to include custom attribute values in your export'
                        )}
                        hintForOnState={i18n.text(
                            "Turn off if you don't want to include custom attribute values in your export"
                        )}
                        checked={exportAttributes}
                        onChange={R.pipe(R.ifElse(getChecked, R.always({}), R.always(null)), this.next('attributes'))}
                        classes={{
                            label: styles['switch-label']
                        }}
                    />
                    {exportAttributes && (
                        <div className={styles['multi-select']}>
                            <InputLabel htmlFor="select-attributes" disableAnimation shrink>
                                {i18n.text('Attributes')}
                            </InputLabel>
                            <CustomMultiSelect
                                id="export-attributes-select"
                                input={<Input id="select-attributes" />}
                                value={attrValueToOption(attributes)}
                                onSelect={R.pipe(attrOptionToValue, this.next('attributes'))}
                                className={styles['multi-select__control']}
                                placeholder={i18n.text('All attributes')}
                                groupedOptions={attributeOptions}
                                getChipLabel={getAttributeLabel}
                            />
                        </div>
                    )}

                    <CustomSwitch
                        className={styles.switch}
                        label={i18n.text('Translations')}
                        hintForOffState={i18n.text(
                            'Turn on if you want to export localization translations from selected languages'
                        )}
                        hintForOnState={i18n.text("Turn off if you don't want to export localization translations")}
                        checked={exportLocalization}
                        onChange={R.pipe(
                            R.ifElse(getChecked, R.always([]), R.always(null)),
                            this.next('languageCodes')
                        )}
                        classes={{
                            label: styles['switch-label']
                        }}
                    />
                    {exportLocalization && (
                        <div className={styles['multi-select']}>
                            <InputLabel htmlFor="select-languages" disableAnimation shrink>
                                {i18n.text('Languages')}
                            </InputLabel>
                            <CustomMultiSelect
                                id="export-languages-select"
                                input={<Input id="select-languages" />}
                                value={languageCodes}
                                onSelect={this.next('languageCodes')}
                                className={styles['multi-select__control']}
                                placeholder={i18n.text('All languages')}
                                options={languageOptions}
                                getChipLabel={getLanguageLabel}
                            />
                        </div>
                    )}

                    <CustomSwitch
                        className={styles.switch}
                        label={i18n.text('Parents')}
                        hintForOffState={i18n.text('Turn on if you want to export parents')}
                        hintForOnState={i18n.text("Turn off if you don't want to export parents")}
                        checked={exportParents}
                        onChange={R.pipe(getChecked, this.next('exportParents'))}
                        classes={{
                            label: styles['switch-label']
                        }}
                    />

                    <CustomSwitch
                        className={clsx(styles.switch, styles['switch__narrow'])}
                        label={i18n.text('Audit data')}
                        hintForOffState={i18n.text('Turn on if you want to include audit data in your export')}
                        hintForOnState={i18n.text("Turn off if you don't want to include audit data in your export")}
                        checked={exportAuditData}
                        onChange={R.pipe(R.ifElse(getChecked, R.always({}), R.always(null)), this.next('auditData'))}
                        classes={{
                            label: styles['switch-label']
                        }}
                    />

                    {exportAuditData && (
                        <div className={styles['checkboxes-wrapper']}>
                            <Checkbox
                                checked={exportUpdateInfo}
                                onChange={R.pipe(
                                    getChecked,
                                    this.getAuditData('exportUpdateInfo'),
                                    this.next('auditData')
                                )}
                                label={i18n.text('Update information')}
                                classes={{
                                    label: styles['checkbox__label']
                                }}
                            />

                            <Checkbox
                                checked={exportVersion}
                                onChange={R.pipe(
                                    getChecked,
                                    this.getAuditData('exportVersion'),
                                    this.next('auditData')
                                )}
                                label={i18n.text('Version')}
                                classes={{
                                    label: styles['checkbox__label']
                                }}
                            />
                        </div>
                    )}

                    <Divider className={styles.divider} />
                    <Typography variant="subtitle1" className={styles.subheading}>
                        {i18n.text('Select file format')}
                    </Typography>

                    <div className={styles['radio-group']}>
                        {FILE_FORMATS_DATA.map(({value, label, hint}, index) => {
                            const checked = fileFormat === value;
                            return (
                                <div key={index} className={styles['radio-button']}>
                                    <FormControlLabel
                                        value={value}
                                        control={<Radio color="primary" />}
                                        label={
                                            <Fragment>
                                                {label}
                                                <FormHelperText className={styles['radio-button__hint']}>
                                                    {hint}
                                                </FormHelperText>
                                            </Fragment>
                                        }
                                        checked={checked}
                                        onChange={R.pipe(getValue, this.next('fileFormat'))}
                                        classes={{
                                            label: styles['radio-button__label']
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = ({configuration}, {selectedTypeUris}) => ({
    sourceOptions: configuration.sources.map(({uri, label}) => ({
        value: uri,
        label
    })),
    attributeOptions: selectedTypeUris.reduce((acc, uri) => {
        const {attributes, label} = getTypeByUri(configuration, uri);
        return attributes && attributes.length > 0
            ? acc.concat({
                  groupLabel: label,
                  groupValue: uri,
                  options: attributes.map(({name}) => ({
                      value: `${uri}${ATTR_DELIMITER}${name}`,
                      label: name
                  }))
              })
            : acc;
    }, [])
});

export default connect(mapStateToProps)(ExportStep2);
