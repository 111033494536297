import * as React from 'react';
import clsx from 'clsx';
import styles from './styles.less';
type Props = {
    children: React.ReactNode | null;
    className?: string;
    prefix?: string;
    postfix?: string;
};

function TruncateText(props: Props) {
    const {children, prefix, postfix, className} = props;
    return (
        <span className={clsx(styles['truncate-text'], className)}>
            {Boolean(prefix) && prefix}
            <span className={clsx(styles['truncate-text__truncate'])}>{children}</span>
            {Boolean(postfix) && postfix}
        </span>
    );
}

export default TruncateText;
