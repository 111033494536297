import makeStyles from '@mui/styles/makeStyles';

export type StyleProps = {
    placeholder: string;
    label: string;
};

export default makeStyles((theme) => ({
    control: {
        display: 'flex',
        padding: 0,
        height: 'auto'
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        marginLeft: theme.spacing(0.5)
    },
    multiValue: {
        margin: theme.spacing(0.5, 0.25),
        maxWidth: '100%'
    },
    multiValue__label: {
        paddingRight: 0,
        marginRight: 12,
        maxWidth: '100%',
        overflow: 'hidden'
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2)
    },
    singleValue: {
        fontSize: 16
    },
    placeholder: (props: StyleProps) => ({
        position: 'absolute',
        left: 6,
        bottom: 10,
        fontSize: 16,
        display: !props.placeholder || props.label ? 'none' : undefined
    }),
    menu: {
        zIndex: 40,
        marginTop: theme.spacing(1)
    },
    clearIndicator: {
        padding: 0,
        margin: theme.spacing(0.5, 1)
    },
    dropdownIndicator: {
        padding: 0,
        margin: theme.spacing(0.5, 1)
    },
    groupHeading: {
        paddingLeft: '28px'
    },
    option: {
        fontWeight: 400
    },
    'option--selected': {
        fontWeight: 400
    },
    container: {
        backgroundColor: '#F0F0F0',
        borderRadius: '3px',
        marginBottom: '19px'
    },
    label: {
        top: 5,
        left: 16
    },
    formControl: {
        'label + &': {
            marginBottom: 0,
            marginTop: theme.spacing(2)
        }
    }
}));
