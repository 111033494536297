import store from '../redux/store';
import {setOpenedTaskEvent} from '../redux/actions/ui';
export default function openWorkflowTask(router, replace, next) {
    const {tenant} = store.getState();
    const {dispatch} = store;
    const {
        params: {tab, tenantId, type, taskId}
    } = router;

    if (tenant) {
        window.location.reload();
        return;
    }

    replace(`/${tenantId}/types/${type}/${tab}`);
    dispatch(setOpenedTaskEvent(taskId));
    return next();
}
