import Link from '../Link/Link';
import Tag from '../../../Tag/Tag';
import TruncateText from '../../../TruncateText/TruncateText';
import clsx from 'clsx';
import styles from './styles.less';
import {ACTIVITY_TYPE} from '../../constants';
import {noop} from '../../../../core/util';
export type ActivityTitleProps = {
    id?: string | null;
    className?: string;
    disabledLink: boolean;
    onLinkClick?: () => void;
    type: ACTIVITY_TYPE;
    title: string;
    label: string;
};

function ActivityTitle(props: ActivityTitleProps) {
    const {className, disabledLink, onLinkClick = noop, type, title, label} = props;
    return (
        <h3 className={clsx(styles['activity-title'], className)}>
            <span className={styles['activity-title__label']}>{title}</span>
            <Link disabled={disabledLink} onClick={onLinkClick} className={styles['activity-title__link']}>
                <TruncateText>{label}</TruncateText>
            </Link>
            <Tag type={type} />
        </h3>
    );
}

export default ActivityTitle;
