import * as React from 'react';
import styles from './styles.less';
type Props = {
    children?: React.ReactNode | null;
};

function ActivityContent({children}: Props) {
    return <div className={styles['activity-content']}>{children}</div>;
}

export default ActivityContent;
