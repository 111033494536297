import CacheContext from './CacheContext';
import {PureComponent} from 'react';
export default (key) => (WrappedComponent) => {
    class Connected extends PureComponent {
        render() {
            return (
                <CacheContext.Consumer>
                    {({getCacheData, setCacheData}) => (
                        <WrappedComponent
                            cacheData={getCacheData(key)}
                            setCacheData={setCacheData(key)}
                            {...this.props}
                        />
                    )}
                </CacheContext.Consumer>
            );
        }

        static displayName = `WithCache(${WrappedComponent.displayName || WrappedComponent.name})`;
    }

    return Connected;
};
