import * as React from 'react';
import Comments from './Comments';
import InputComment from './InputComment';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {WorkflowComment} from '../../workflow.types';
type Props = {
    onSend: (arg0: string) => void;
    comments: WorkflowComment[];
    readOnly: boolean;
};
const CommentSection = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
    const {onSend, comments, readOnly} = props;
    return (
        <div className={styles['comment-section']} ref={ref}>
            <div className={styles['comment-section__header']}>{i18n.text('Comments')}</div>
            <Comments comments={comments} />
            {!readOnly && <InputComment onSend={onSend} />}
        </div>
    );
});
CommentSection.displayName = 'CommentSection';
export default CommentSection;
