import * as R from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Link} from 'react-router';
import {checkRemoved} from '../../core/marks';
import {initialTypeQuery} from '../../components/Search/initialSearchQuery';
import {later} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {typesSearchQueryEvent} from '../../redux/actions/types';
const applyLogger = logActivityCommand('search-types-bar')('search-apply');
const TypesUsedCellRenderer = ({rowData, cellData, columnData}: Record<string, any>) => {
    const isRemoved = checkRemoved(rowData);
    const {tenant, dispatch} = columnData;
    const query = {...initialTypeQuery, generator: rowData.name};
    return (
        <div className={styles['types-count-cell']}>
            {cellData > 0 && !isRemoved ? (
                <Tooltip title={i18n.text('View lookup types')}>
                    <div>
                        <Link
                            className={styles['types-page-link']}
                            to={`${tenant}/types`}
                            onClick={R.pipe(
                                R.always(query),
                                R.tap(later(R.pipe(applyLogger, dispatch))),
                                typesSearchQueryEvent,
                                dispatch
                            )}
                        >
                            {cellData}
                        </Link>
                    </div>
                </Tooltip>
            ) : (
                cellData
            )}
        </div>
    );
};

TypesUsedCellRenderer.displayName = 'TypesUsedCellRenderer';

export default TypesUsedCellRenderer;
