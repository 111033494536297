import Checkbox from '@mui/material/Checkbox';
import CustomIcon from '../CustomIcon/CustomIcon';
import FormControlLabel, {FormControlLabelProps} from '@mui/material/FormControlLabel';
import {forwardRef} from 'react';

import styles from './checkbox.less';

type Props = Omit<FormControlLabelProps, 'control' | 'label'> & {
    label?: string;
    onChange?: (event: React.ChangeEvent<unknown>) => void;
    indeterminate?: boolean;
};
const CustomCheckbox = forwardRef(
    ({onChange, indeterminate, label = '', ...restProps}: Props, ref: React.Ref<HTMLDivElement>) => {
        return (
            <FormControlLabel
                ref={ref}
                classes={{
                    label: styles['control-label']
                }}
                control={
                    <Checkbox
                        className={styles['checkbox']}
                        color="primary"
                        indeterminate={indeterminate}
                        indeterminateIcon={
                            <CustomIcon name="checkbox_indeterminate" className={styles['checkbox--indeterminate']} />
                        }
                    />
                }
                onChange={(event) =>
                    // @ts-ignore
                    !event.target.disabled && onChange ? onChange(event) : null
                }
                label={label}
                {...restProps}
            />
        );
    }
);

CustomCheckbox.displayName = 'CustomCheckbox';

export default CustomCheckbox;
