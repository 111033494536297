import Icon from '@mui/material/Icon';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component} from 'react';
import {K, pipe} from '../../core/func';
import {Maybe} from 'monet';
import type {ReactElement} from 'react';
type Props = {
    open: boolean;
    onToggle: (more: boolean) => void;
    children: ReactElement[];
};

class ShowMore extends Component<Props> {
    render() {
        const {onToggle, children, open} = this.props;
        const head = (
            <div onClick={pipe(K(true), onToggle)} className={styles.divider}>
                <span>{i18n.text('View more')}</span>
                <Icon>arrow_drop_down</Icon>
            </div>
        );
        const tail = (
            <div>
                {children}
                <div onClick={pipe(K(false), onToggle)} className={styles.divider}>
                    <span>{i18n.text('View less')}</span>
                    <Icon>arrow_drop_up</Icon>
                </div>
            </div>
        );
        return Maybe.fromNull(open).orSome(false) ? tail : head;
    }
}

export default ShowMore;
