import * as R from 'ramda';
import CollaborationMenu from '../CollaborationMenu/CollaborationMenu';
import CommentContent from '../CommentContent/CommentContent';
import CommentDialogInput from '../CommentDialogInput/CommentDialogInput';
import CustomIcon from '../CustomIcon/CustomIcon';
import DiscussionInput from './DiscussionInput/DiscussionInput';
import DiscussionReply from '../DiscussionReply/DiscussionReply';
import i18n from 'ui-i18n';
import styles from './discussion-comment.less';
import {Comment} from '../../rdm-sdk/collaboration.types';
import {PureComponent} from 'react';
import {User} from '../../rdm-sdk/app.types';
import {formatCommentTime} from '../../rdm-sdk/utils';
type Props = {
    comment: Comment;
    username: string;
    users: User[];
    canonical: string;
    onCommentDeleteClick: (comment: Comment) => Promise<any>;
    onCommentEdited: (comment: Comment) => (content: string) => Promise<any>;
    onResolveClick: (comment: Comment) => Promise<any>;
    onReopenClick: (comment: Comment) => Promise<any>;
    createReply: (comment: Comment, content: string) => Promise<any>;
};
type State = {
    isEditing: boolean;
};

class DiscussionComment extends PureComponent<Props, State> {
    inputRef: (ref?: HTMLTextAreaElement | null) => void;
    input?: HTMLTextAreaElement | null;

    constructor(props: Props) {
        super(props);

        this.inputRef = (ref) => (this.input = ref);
    }

    state = {
        isEditing: false
    };
    toggleIsEditing = () =>
        this.setState(
            R.evolve({
                isEditing: R.not
            })
        );
    exitEditing = () =>
        this.setState({
            isEditing: false
        });
    onInputEnter = (content: string) => {
        const {createReply, comment} = this.props;
        createReply(comment, content);
    };
    onReplyButtonClick = () => {
        this.input && this.input.focus();
    };

    render() {
        const {
            comment,
            username,
            users,
            canonical,
            onCommentDeleteClick,
            onCommentEdited,
            onResolveClick,
            onReopenClick
        } = this.props;
        const {isEditing} = this.state;
        const isOwner = username === comment.createdBy;
        const hasAssignee = R.has('assignee', comment);
        const isResolved = comment.status === 'resolved';
        return (
            <div className={styles['discussion-comment__container']}>
                <div className={styles['discussion-comment__avatar']}>
                    <CustomIcon name="face" />
                </div>

                <div className={styles['discussion-comment__right-side']}>
                    <div className={styles['discussion-comment']}>
                        <div className={styles['discussion-comment__info']}>
                            <p title={comment.createdBy} className={styles['discussion-comment__username']}>
                                {comment.createdBy}
                            </p>
                            <p className={styles['discussion-comment__time']}>
                                {formatCommentTime(comment.createdTime)}
                            </p>
                            <CollaborationMenu
                                iconStyle={{
                                    visibility: isOwner ? 'visible' : 'hidden'
                                }}
                                canEdit={!isResolved}
                                iconClassName={styles['discussion-comment__menu-icon']}
                                onEditClick={this.toggleIsEditing}
                                onDeleteClick={R.pipe(R.always(comment), onCommentDeleteClick)}
                            />
                        </div>

                        <p className={styles['selected-value__label']}>{i18n.text('Selected value:')}</p>
                        <p className={styles['selected-value__data']}>
                            <span>|</span>
                            {canonical}
                        </p>

                        {isEditing ? (
                            <CommentDialogInput
                                value={comment.content}
                                onSendClick={onCommentEdited(comment)}
                                exitEditing={this.exitEditing}
                                users={users}
                            />
                        ) : (
                            <CommentContent className={styles['discussion-comment__content']}>
                                {comment.content}
                            </CommentContent>
                        )}

                        {hasAssignee && (
                            <p className={styles['discussion-comment__action-info']}>
                                {i18n.text('Assigned to ${username}', {
                                    username: comment.assignee
                                })}
                            </p>
                        )}

                        <div className={styles['discussion-comment__control']}>
                            <span role="button" onClick={this.onReplyButtonClick}>
                                {i18n.text('Reply')}
                            </span>
                            {isResolved ? (
                                <span role="button" onClick={R.pipe(R.always(comment), onReopenClick)}>
                                    {i18n.text('Re-open')}
                                </span>
                            ) : (
                                <span role="button" onClick={R.pipe(R.always(comment), onResolveClick)}>
                                    {hasAssignee ? i18n.text('Mark as done') : i18n.text('Resolve')}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className={styles['discussion-comment__replies']}>
                        {comment.replies.map((reply, index) => (
                            <DiscussionReply key={index} reply={reply} />
                        ))}
                        <DiscussionInput
                            inputRef={this.inputRef}
                            value={''}
                            placeholder={
                                isResolved ? i18n.text('Adding a comment will re-open this') : i18n.text('Reply...')
                            }
                            users={users}
                            onEnter={this.onInputEnter}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default DiscussionComment;
