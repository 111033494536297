/* eslint-disable complexity */
import * as R from 'ramda';
import initialState from '../initialState';
import {SortDirection} from '../../rdm-sdk/app.types';
import {inArray, prop} from '../../core/lenses';
import {isUnmappedItemsEqual} from '../../rdm-sdk/unmapped';
import {toggleOrder} from '../../core/util';
const unmappedValuesL = prop('unmappedValues');
const unmappedSearchQueryL = prop('unmappedSearchQuery');
const selectedItemsL = prop('selectedItems');
const removedItemsL = prop('removedItems');
const unmappedSourcesTotalsL = prop('unmappedSourcesTotals');
const loadingTotalsL = prop('loadingTotals');
const loadingUnmappedL = prop('loadingUnmapped');

const unmappedValuesBySource = (source) => unmappedValuesL.compose(inArray('source', source));

const resetScrollId = (state) => unmappedValuesL.set(state, unmappedValuesL.get(state).map(R.assoc('scrollId', '')));
// eslint-disable-next-line prettier/prettier
export default function unmappedReducer(state = initialState.unmapped, event) { // NOSONAR
    switch (event.type) {
        case 'unmappedReset':
            return initialState.unmapped;

        case 'unmappedValuesReset': {
            const currentUnmappedValues = unmappedValuesL.get(state);
            return unmappedValuesL.set(
                state,
                currentUnmappedValues.map(
                    R.evolve({
                        items: R.always([]),
                        scrollId: R.always(''),
                        total: R.always(0)
                    })
                )
            );
        }

        case 'toggleUnmappedOrder': {
            const unmappedOrderL = unmappedValuesBySource(event.source).compose(prop('order'));
            return unmappedOrderL.set(state, toggleOrder(unmappedOrderL.get(state)));
        }

        case 'updateUnmappedScrollId': {
            const unmappedScrollIdL = unmappedValuesBySource(event.source).compose(prop('scrollId'));
            return unmappedScrollIdL.set(state, event.scrollId);
        }

        case 'updateUnmappedItems': {
            const unmappedItemsL = unmappedValuesBySource(event.source).compose(prop('items'));
            return unmappedItemsL.set(state, event.items);
        }

        case 'appendUnmappedItems': {
            const unmappedItemsL = unmappedValuesBySource(event.source).compose(prop('items'));
            return unmappedItemsL.set(state, [...unmappedItemsL.get(state), ...event.items]);
        }

        case 'unmappedValuesReceived': {
            const currentUnmappedValuesL = unmappedValuesBySource(event.unmappedValues.source);
            const valueBefore = currentUnmappedValuesL.get(state);
            const newUnmappedValues = Object.assign(
                {
                    order: SortDirection.ASC
                },
                valueBefore,
                event.unmappedValues
            );
            const unmappedValues = valueBefore
                ? currentUnmappedValuesL.set(state, newUnmappedValues).unmappedValues
                : [...unmappedValuesL.get(state), newUnmappedValues];
            return unmappedValuesL.set(state, R.sortBy(R.prop('source'), unmappedValues));
        }

        case 'unmappedScrollIdResetBySource': {
            const unmappedOffsetL = unmappedValuesBySource(event.source).compose(prop('scrollId'));
            return unmappedOffsetL.set(state, '');
        }

        case 'unmappedScrollIdReset':
            return resetScrollId(state);

        case 'unmappedSearchQuery':
            return unmappedSearchQueryL.set(resetScrollId(state), event.searchQuery);

        case 'unmappedSelectItemToggle': {
            const selectedItems = R.find(isUnmappedItemsEqual(event.item), state?.selectedItems)
                ? R.reject(isUnmappedItemsEqual(event.item), state?.selectedItems)
                : state?.selectedItems.concat(event.item);
            return selectedItemsL.set(state, selectedItems);
        }

        case 'unmappedSelectedItemsClear':
            return selectedItemsL.set(state, []);

        case 'unmappedRemovedItemsAppend': {
            const removedItemsByKeyL = removedItemsL.compose(prop(event.key));
            return removedItemsByKeyL.set(state, [].concat(removedItemsByKeyL.get(state) || [], event.removedItems));
        }

        case 'unmappedRemovedItemsClear':
            return removedItemsL.set(state, {});

        case 'unmappedSourcesTotals':
            return unmappedSourcesTotalsL.set(state, event.totals);

        case 'setUnmappedSourceTotal':
            return unmappedSourcesTotalsL.set(state, event.totals);

        case 'setLoadingTotal':
            return loadingTotalsL.set(state, event.loading);

        case 'setLoadingUnmapped':
            return loadingUnmappedL.set(state, event.loading);

        default:
            return state;
    }
}
