import * as R from 'ramda';
import {EDIT_MODE} from '../constants/common';
import {LookupValue, SourceMapping} from './lookups.types';
import {Unmapped, UnmappedItem} from './unmapped.types';
import {fromNotEmpty} from '../core/maybe';
import {leftJoinByProp} from '../core/util';
import {markClientOnly, markEdited} from '../core/marks';
const leftJoinByName = leftJoinByProp('name');

const mergeSources = (canonical: SourceMapping[], unmapped: SourceMapping[]): SourceMapping[] =>
    canonical.concat(unmapped.map((u) => ({...u, downStreamDefaultValue: false})));

export const mergeUnmappedWithCanonical = (unmapped: UnmappedItem[], canonical: LookupValue): LookupValue => {
    const canonicalAttributes = R.defaultTo([], canonical.attributes);
    const unmappedAttributes = R.pipe(R.pluck('attributes'), R.reject(R.isNil), R.reduce(leftJoinByName, []))(unmapped);
    const mergedAttributes = leftJoinByName(canonicalAttributes, unmappedAttributes);
    const canonicalSources = R.defaultTo({}, canonical.sources);
    const canonicalHasNoSources = R.isEmpty(canonicalSources);
    const first = R.head(unmapped);
    const unmappedSources = R.pipe(
        R.groupBy(R.prop('source')),
        R.map((values) =>
            values.map((value, i) =>
                R.pipe(
                    R.pick(['code', 'value']),
                    R.assoc('enabled', true),
                    R.assoc('downStreamDefaultValue', i === 0),
                    R.assoc('canonicalValue', value === first && canonicalHasNoSources),
                    markEdited,
                    markClientOnly
                )(value)
            )
        )
    )(unmapped);
    const unmappedCanonical = R.path(['value'], first);
    return {
        ...canonical,
        sources: R.mergeDeepWith(mergeSources, canonicalSources, unmappedSources),
        attributes: mergedAttributes.length > 0 ? mergedAttributes : undefined,
        enabled: R.defaultTo(true, canonical.enabled),
        canonical: fromNotEmpty(canonical.canonical).orSome(unmappedCanonical)
    };
};
export const isUnmappedItemsEqual = (first: UnmappedItem) => (second: UnmappedItem) =>
    second.source === first.source && second.code === first.code;
export const getUnmappedTotal = ({unmappedSourcesTotals, removedItems}: Unmapped, editMode?: EDIT_MODE) =>
    R.pipe(R.pluck('total'), R.sum)(unmappedSourcesTotals || []) -
    (editMode === EDIT_MODE.SUGGEST ? 0 : R.pipe(R.values, R.flatten, R.length)(removedItems));
