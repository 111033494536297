import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import styles from './styles.less';
type Props = {
    conflictDetails: string;
    children: React.ReactNode;
};

const ConflictWrapper = (props: Props) => {
    const {conflictDetails, children} = props;
    return (
        <Tooltip
            title={conflictDetails}
            placement="top"
            classes={{
                tooltip: styles['tooltip']
            }}
        >
            <div className={styles['conflict-wrapper']}>
                <div className={styles['conflict-wrapper__icon']}>
                    <WarningRoundedIcon />
                </div>
                {children}
            </div>
        </Tooltip>
    );
};

export default ConflictWrapper;
