import AssigneeSelector from '../AssigneeSelector/AssigneeSelector';
import DatePicker from '../../../DatePicker/DatePicker';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import makeStyles from '@mui/styles/makeStyles';
import styles from './styles.less';
import {TASK_PRIORITIES} from '../../constants';
import {WorkflowType} from '../../workflow.types';
import {getValue} from '../../../../core/util';
import {pipe} from 'ramda';
const useStyles = makeStyles((theme) => ({
    label: {
        top: 5
    },
    formControl: {
        'label + &': {
            marginBottom: 0
        }
    },
    input: {
        padding: theme.spacing(0, 0, 1)
    },
    textField: {
        background: '#F0F0F0'
    },
    dateFieldInput: {
        padding: '21px 12px 7px'
    },
    inputLabel: {}
}));
type Props = {
    workflow: WorkflowType;
    onDateChange: (arg0: number) => void;
    onPriorityChange: (arg0: string) => void;
    onAssigneeChange: (arg0: string) => void;
    readOnly: boolean;
};
export function Info(props: Props) {
    const {workflow, readOnly, onDateChange, onPriorityChange, onAssigneeChange} = props;
    const {id, dueDate, priority, assignee, taskID} = workflow;
    const classes = useStyles();
    const dateTextFieldProps = {
        InputLabelProps: {
            className: classes.label
        },
        InputProps: {
            classes: {
                formControl: classes.formControl,
                input: classes.input
            }
        }
    };
    return (
        <div className={styles['info_container']}>
            <div className={styles['info_container__row']}>
                <div className={clsx(styles['info_container__column'], styles['info_container__column-half'])}>
                    <div className={styles['info_container__date-wrapper']}>
                        <DatePicker
                            iconName="calendar_today"
                            id={`workflowDatePicker${id}`}
                            value={dueDate as Date}
                            label={i18n.text('Due Date')}
                            onSelect={pipe(Number, onDateChange)}
                            TextFieldProps={dateTextFieldProps}
                            readOnly={readOnly}
                            minDate={new Date()}
                        />
                    </div>
                </div>
                <div className={clsx(styles['info_container__column'], styles['info_container__column-half'])}>
                    <FormControl
                        variant="filled"
                        classes={{
                            root: styles['info_container__form-control-root']
                        }}
                        fullWidth
                    >
                        <TextField
                            InputProps={{
                                classes: {
                                    input: classes.dateFieldInput
                                },
                                readOnly
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel
                                }
                            }}
                            label={i18n.text('Priority')}
                            name="priority"
                            value={priority}
                            onChange={pipe(getValue, onPriorityChange)}
                            variant="filled"
                            select
                        >
                            {TASK_PRIORITIES.map((option) => (
                                <MenuItem key={option.priority} value={option.priority}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </div>
            </div>
            <div className={styles['info_container__row']}>
                <div className={styles['info_container__column']}>
                    <AssigneeSelector
                        onChange={onAssigneeChange}
                        assignee={assignee}
                        taskID={taskID}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    );
}
export default Info;
