import makeStyles from '@mui/styles/makeStyles';
export default makeStyles(() => ({
    triggerClearIcon: {
        visibility: 'hidden !important' as any,
        width: 0
    },
    valueContainer: {
        marginTop: '19px'
    }
}));
