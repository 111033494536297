import * as R from 'ramda';
import MarkLabel from '../MarkLabel/MarkLabel';
import SearchWordsHighlighter from '../SearchWordsHighlighter/SearchWordsHighlighter';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {checkRemoved} from '../../core/marks';
export default function TypeNameCellRenderer({rowData, cellData, columnData}: Record<string, any>) {
    const {typesSearchQuery} = columnData;
    const isEnabled = R.propOr(true, 'enabled', rowData);
    const isRemoved = checkRemoved(rowData);
    const {hasDCR} = rowData;
    return (
        <Tooltip title={!isEnabled ? i18n.text('Disabled') : ''} placement="bottom-start">
            <div>
                <MarkLabel entity={rowData} />
                <SearchWordsHighlighter
                    style={{
                        textDecoration: isRemoved ? 'line-through' : 'initial',
                        color: isRemoved || !isEnabled ? 'inherit' : 'rgba(0, 0, 0, 0.87)'
                    }}
                    value={cellData}
                    searchQuery={typesSearchQuery}
                    searchFields={['name', 'hasWords']}
                    enabled={true}
                />
                {hasDCR && <span className={styles['has-dcr-label']}>{i18n.text('DCR')}</span>}
            </div>
        </Tooltip>
    );
}
