import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {checkRemoved} from '../../core/marks';
export default function actionCellRenderer({rowData, columnData}: Record<string, any>) {
    const {isEditMode, rowAction, selected} = columnData;
    const isRemoved = checkRemoved(rowData);

    const onClick = (e) => {
        e.stopPropagation();
        rowAction(rowData);
    };

    return (
        <div className={clsx(styles['action-cell'], isRemoved && styles['action-cell__removed'])}>
            {isEditMode ? (
                !selected.length && (
                    <Tooltip title={i18n.text('Edit')}>
                        <IconButton className={styles['action-cell__row-button']} onClick={onClick} size="large">
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                )
            ) : (
                <Tooltip title={i18n.text('View')}>
                    <IconButton className={styles['action-cell__row-button']} onClick={onClick} size="large">
                        <Icon>info_outline</Icon>
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
}
