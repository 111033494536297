import * as R from 'ramda';
import CustomWizard from '../CustomWizard/CustomWizard';
import Dialog from '@mui/material/Dialog';
import ExportStep1 from './Step1/ExportStep1';
import ExportStep2 from './Step2/ExportStep2';
import i18n from 'ui-i18n';
import {Component} from 'react';
import {ExportDetails, ExportOptions} from '../../rdm-sdk/export.types';
import {ExportFileFormat} from '../../rdm-sdk/export';
import {connect} from 'react-redux';
import {exportSaga} from '../../redux/actions/export';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {withRouter} from 'react-router';
const STEPS = [i18n.text('Select data'), i18n.text('Job details')];

type DispatchProps = {
    exportData: (arg0: ExportOptions) => void;
    logExportBtnClick: (payload: ExportOptions) => void;
};

type Props = DispatchProps & {
    open: boolean;
    onClose: () => void;
    router: Record<string, any>;
};
type State = ExportOptions;
const initialState = {
    selectedTypeUris: [],
    exportDetails: {
        sourceUris: null,
        attributes: null,
        languageCodes: null,
        exportParents: false,
        fileFormat: ExportFileFormat.CSV,
        auditData: null
    }
} as State;
const logger = logActivityCommand('export-wizard');
export class ExportWizard extends Component<Props, State> {
    stopListening?: () => void;
    state = initialState;
    canContinue = (activeStep: number) => {
        switch (activeStep) {
            case 0:
                return this.state.selectedTypeUris.length > 0;

            default:
                return true;
        }
    };
    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };
    setSelectedTypes = (typeUris: string[]): void =>
        this.setState(
            R.evolve({
                selectedTypeUris: R.always(typeUris),
                exportDetails: R.evolve({
                    attributes: R.unless(R.isNil, R.pick(typeUris))
                })
            })
        );
    setExportDetails = (exportDetails: ExportDetails) =>
        this.setState({
            exportDetails
        });
    stepContentRenderer = (activeStep: number) => {
        const {selectedTypeUris, exportDetails} = this.state;

        switch (activeStep) {
            case 0: {
                return <ExportStep1 selectedTypeUris={selectedTypeUris} setSelectedTypes={this.setSelectedTypes} />;
            }

            case 1: {
                return (
                    <ExportStep2
                        selectedTypeUris={selectedTypeUris}
                        exportDetails={exportDetails}
                        setExportDetails={this.setExportDetails}
                    />
                );
            }

            default:
                return null;
        }
    };
    resetState = () => this.setState(initialState);

    componentDidUpdate(prevProps: Props) {
        const {router, open} = this.props;

        if (open && !prevProps.open) {
            this.stopListening = router.listenBefore(this.handleClose);
        }

        if (!open && prevProps.open && this.stopListening) {
            this.stopListening();
        }
    }

    render() {
        const {open, exportData, logExportBtnClick} = this.props;
        return (
            <Dialog open={open} fullScreen>
                <CustomWizard
                    logger={logger}
                    title={i18n.text('Export RDM Data')}
                    actionName={i18n.text('Export')}
                    steps={STEPS}
                    onClose={this.handleClose}
                    canContinue={this.canContinue}
                    onFinish={R.pipe(R.always(this.state), R.tap(logExportBtnClick), exportData, this.handleClose)}
                    stepContentRenderer={this.stepContentRenderer}
                />
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) =>
    ({
        exportData: exportSaga(dispatch),
        logExportBtnClick: R.compose(dispatch, logger('export-button-click'))
    }) as DispatchProps;

// @ts-ignore
export default withRouter(connect(null, mapDispatchToProps)(ExportWizard));
