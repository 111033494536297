import * as R from 'ramda';
import CustomWizard from '../CustomWizard/CustomWizard';
import Dialog from '@mui/material/Dialog';
import ImportStep1 from './Step1/ImportStep1';
import ImportStep2 from './Step2/ImportStep2';
import ImportStep3 from './Step3/ImportStep3';
import ImportWizardContext from './ImportWizardContext';
import i18n from 'ui-i18n';
import {Component} from 'react';
import {FileError, Mapping} from '../../rdm-sdk/import.types';
import {LookupType} from '../../rdm-sdk/types.types';
import {Subscription} from 'rxjs';
import {connect} from 'react-redux';
import {createCsvFileObservable, previewCsvFile} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {withRouter} from 'react-router';
const logger = logActivityCommand('import-wizard');
const STEPS = [i18n.text('Select'), i18n.text('Map'), i18n.text('Review & Load')];
type Props = {
    open: boolean;
    onClose: () => void;
    router: Record<string, any>;
    logLoadBtnClick: (payload: Record<string, any>) => void;
    onLoad: () => void;
};
type State = {
    file?: File | null;
    fileData: Array<string[]>;
    fileLinesCount?: number | null;
    fileErrors: FileError[];
    mappings: Mapping[];
    selectedLookupType?: LookupType | null;
    isFirstLineHeader: boolean;
};
const initialState = {
    file: null,
    fileData: [[]],
    fileLinesCount: null,
    fileErrors: [],
    mappings: [],
    selectedLookupType: null,
    isFirstLineHeader: false
};
export class ImportWizard extends Component<Props, State> {
    linesCountSubscription?: Subscription | null = null;
    stopListening?: () => void;
    state = initialState;

    unsubscribeLinesCount() {
        this.linesCountSubscription?.unsubscribe();
    }

    canContinue = (activeStep: number) => {
        const {file, fileErrors, selectedLookupType, mappings} = this.state;

        switch (activeStep) {
            case 0:
                return !R.isNil(file) && R.isEmpty(fileErrors);

            case 1:
                return !R.isNil(selectedLookupType) && !R.isEmpty(mappings);

            default:
                return true;
        }
    };
    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };
    toggleIsFirstLineHeader = () =>
        this.setState(
            R.evolve({
                isFirstLineHeader: R.not
            })
        );
    onFileUpload = (files: FileList) => {
        const file = R.head(files);

        if (file) {
            this.resetState();
            previewCsvFile(file)
                .then((fileData) =>
                    this.setState({
                        file,
                        fileData,
                        fileErrors: []
                    })
                )
                .catch((fileErrors) => {
                    console.error('Fail to read file', fileErrors);
                    this.setState({
                        file,
                        fileErrors
                    });
                });
            this.unsubscribeLinesCount();
            this.linesCountSubscription = createCsvFileObservable(file)
                .reduce(R.inc, 0)
                .subscribe(
                    (fileLinesCount) =>
                        this.setState({
                            fileLinesCount
                        }),
                    (fileErrors) => {
                        console.error('Fail to count file rows', fileErrors);
                        this.setState({
                            fileErrors
                        });
                    }
                );
        }
    };
    addMapping = (mapping: Mapping) => {
        const {mappings} = this.state;
        const {column, target} = mapping;

        if (column != null && !R.find(R.whereEq(mapping), mappings)) {
            this.setState(
                R.evolve({
                    mappings: R.pipe(
                        R.reject(
                            R.whereEq({
                                target
                            })
                        ),
                        R.prepend(mapping)
                    )
                })
            );
        }
    };
    removeMapping = ({target}: {target: string}) => {
        this.setState(
            R.evolve({
                mappings: R.reject(
                    R.whereEq({
                        target
                    })
                )
            })
        );
    };
    onTypeSelect = (selectedLookupType: LookupType) =>
        this.setState({
            selectedLookupType,
            mappings: []
        });
    stepContentRenderer = (activeStep: number) => {
        switch (activeStep) {
            case 0:
                return <ImportStep1 />;

            case 1:
                return <ImportStep2 />;

            case 2:
                return <ImportStep3 />;

            default:
                return null;
        }
    };
    resetState = () => this.setState(initialState);

    componentDidUpdate(prevProps: Props) {
        const {router, open} = this.props;

        if (open && !prevProps.open) {
            this.stopListening = router.listenBefore(this.handleClose);
        }

        if (!open && prevProps.open) {
            this.stopListening?.();
            this.unsubscribeLinesCount();
        }
    }

    getContext() {
        return {
            ...this.state,
            ...R.pick(['addMapping', 'onFileUpload', 'onTypeSelect', 'removeMapping', 'toggleIsFirstLineHeader'], this)
        };
    }

    render() {
        const {open, onLoad, logLoadBtnClick} = this.props;
        const context = this.getContext();
        return (
            <Dialog open={open} fullScreen>
                <ImportWizardContext.Provider value={context}>
                    <CustomWizard
                        logger={logger}
                        title={i18n.text('Import RDM Data')}
                        actionName={i18n.text('Load')}
                        steps={STEPS}
                        onClose={this.handleClose}
                        canContinue={this.canContinue}
                        onFinish={R.pipe(R.always(this.state), R.tap(logLoadBtnClick), onLoad, this.handleClose)}
                        stepContentRenderer={this.stepContentRenderer}
                    />
                </ImportWizardContext.Provider>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    logLoadBtnClick: R.compose(dispatch, logger('load-button-click')),
    onLoad: () => {}
});

// @ts-ignore
export default withRouter(connect(null, mapDispatchToProps)(ImportWizard));
