import * as R from 'ramda';
import BasicSearchBar from './BasicSearchBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import QueryLine from './QueryLine';
import styles from './style.less';
import {Command} from '../../redux/middlewares/command-middleware';
import {Component} from 'react';
import {ComponentType} from 'react';
import {Maybe} from 'monet';
import {MaybeMonad} from '../../core/monet';
import {StateEvent} from '../../rdm-sdk/state.types';
import {isNotEmpty} from '../../core/util';
import {parse, serialize} from './parser';
import {queryUpdater} from './store';
import {setIsSearchBarVisibleEvent} from '../../redux/actions/ui';

type ChangeEvent = {
    value: string | number | Date;
    type: string;
};

export type DetailsProps<SQ> = {
    query: SQ;
    onSearch: (query: SQ) => void;
    onReset: () => void;
    onChange: (e: ChangeEvent) => void;
};
type Props<SQ> = {
    isVisible: boolean;
    searchQuery: SQ;
    initialQuery: SQ;
    detailsComponent: ComponentType<DetailsProps<SQ>>;
    onSearch: (query: SQ) => void;
    onReset: () => void;
    keywords: string[];
    dispatch: (e: StateEvent) => Promise<any>;
    logger: (action: string) => (payload: any) => Command;
};
type State<SQ> = {
    expanded: boolean;
    query: SQ;
    searchString: string;
    propsToTrack: {
        searchQuery: SQ;
    };
};

const getQueryFromProps = ({searchQuery, initialQuery}) => searchQuery || initialQuery;

class SearchBar<SQ> extends Component<Props<SQ>, State<SQ>> {
    constructor(props: Props<SQ>) {
        super(props);
        const query = getQueryFromProps(this.props);
        this.state = {
            expanded: false,
            query,
            searchString: serialize(query),
            propsToTrack: {
                searchQuery: query
            }
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const query = getQueryFromProps(nextProps);

        if (query !== prevState.propsToTrack.searchQuery) {
            return {
                query,
                searchString: serialize(query),
                propsToTrack: {
                    searchQuery: query
                }
            };
        }

        return null;
    }

    updateExpanded = (_: any) => (state: State<SQ>) =>
        !state.expanded
            ? {
                  expanded: true,
                  query: parse(this.props.keywords, state.query, state.searchString)
              }
            : {
                  expanded: false,
                  searchString: serialize(state.query)
              };
    resetSearch = () =>
        this.setState({
            query: this.props.initialQuery,
            searchString: ''
        });
    setVisible = () => this.props.dispatch(setIsSearchBarVisibleEvent(true));
    setInvisible = () => {
        this.props.dispatch(setIsSearchBarVisibleEvent(false));
        this.setState({
            expanded: false
        });
    };

    render() {
        const {
            dispatch,
            logger,
            searchQuery,
            onSearch,
            detailsComponent: DetailsComponent,
            onReset,
            keywords,
            isVisible
        } = this.props;
        const {expanded, query, searchString} = this.state;
        const cancelLogger = logger('search-cancel-click');
        const resetLogger = logger('search-reset-click');
        const applyLogger = logger('search-apply');
        const advApplyLogger = logger('advanced-search-apply');
        const safeQuery = Maybe.fromNull(query) as MaybeMonad<SQ>;
        const setState = this.setState.bind(this);
        const hasFilterByCodes = Boolean(searchQuery && isNotEmpty(R.prop('canonicalCodes', searchQuery)));
        return (searchQuery === null || hasFilterByCodes) && !isVisible ? (
            <IconButton className="search-button" onClick={this.setVisible} disabled={hasFilterByCodes} size="large">
                <Icon>search</Icon>
            </IconButton>
        ) : (
            <div>
                <div className={`${styles.searchContainer}`}>
                    <div className={`${styles.searchBar} searchBar`}>
                        <IconButton size="large">
                            <Icon>search</Icon>
                        </IconButton>
                        {expanded ? (
                            <QueryLine safeQuery={safeQuery} onClick={R.pipe(this.updateExpanded, setState)} />
                        ) : (
                            <BasicSearchBar
                                query={query}
                                searchString={searchString}
                                onChange={(value) =>
                                    this.setState({
                                        searchString: value
                                    })
                                }
                                onSearch={R.pipe(R.tap(onSearch), applyLogger, dispatch)}
                                parse={parse(keywords)}
                            />
                        )}
                        <IconButton onClick={R.pipe(this.updateExpanded, setState)} size="large">
                            <Icon>arrow_drop_down</Icon>
                        </IconButton>
                        <span className={`${styles.divider}`} />
                        <IconButton
                            onClick={R.pipe(
                                this.resetSearch,
                                this.setInvisible,
                                onReset,
                                R.always(undefined),
                                cancelLogger,
                                dispatch
                            )}
                            size="large"
                        >
                            <Icon>close</Icon>
                        </IconButton>
                    </div>

                    {expanded && (
                        <DetailsComponent
                            query={query}
                            onChange={R.pipe(queryUpdater, setState)}
                            onSearch={R.pipe(
                                onSearch,
                                R.always(query),
                                advApplyLogger,
                                dispatch,
                                this.updateExpanded,
                                setState
                            )}
                            onReset={R.pipe(this.resetSearch, onReset, R.always(undefined), resetLogger, dispatch)}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default SearchBar;
