import TablePagination from '@mui/material/TablePagination';
import i18n from 'ui-i18n';
import styles from '../SidePanel/styles.less';
import {ActivityLogPagination} from '../activityLog.types';
import {connect} from 'react-redux';
import {getActivitiesPagination, getActivitiesTotal} from '../../../redux/reducers/activityLogReducer';
import {paginationChangeLogger} from '../logging';
import {setActivitiesPaginationEvent} from '../../../redux/actions/activityLog';
const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];
type Props = {
    pagination: ActivityLogPagination;
    onChange: (arg0: ActivityLogPagination) => void;
    setPagination: (arg0: ActivityLogPagination) => void;
    total: number;
};

function ActivitiesPagination(props: Props) {
    const {
        pagination: {rowsPerPage, page},
        setPagination,
        total
    } = props;

    function handleChangePage(event, page) {
        setPagination({
            page,
            rowsPerPage
        });
    }

    function handleChangeRowsPerPage(event) {
        setPagination({
            page: 0,
            rowsPerPage: Number(event.target.value)
        });
    }

    return (
        <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={i18n.text('Events per page:')}
            backIconButtonProps={{
                'aria-label': i18n.text('Previous Page')
            }}
            nextIconButtonProps={{
                'aria-label': i18n.text('Next Page')
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
                root: styles['side-panel__pagination'],
                selectLabel: styles['side-panel__pagination-label'],
                displayedRows: styles['side-panel__pagination-display-rows'],
                select: styles['side-panel__pagination-select']
            }}
        />
    );
}

const mapStateToProps = (state) => ({
    pagination: getActivitiesPagination(state),
    total: getActivitiesTotal(state)
});

const mapDispatchToProps = (dispatch, {onChange}) => ({
    setPagination: (pagination) => {
        dispatch(paginationChangeLogger(pagination));
        dispatch(setActivitiesPaginationEvent(pagination));
        onChange(pagination);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesPagination);
