import {refreshToken} from '../core/auth';
let refreshTokenRequest: Promise<any> | null = null;
let refreshTokenTimeStamp = 0;

const fetchWithToken = (url, options) =>
    refreshTokenRequest ? refreshTokenRequest.then(() => fetch(url, options)) : fetch(url, options);

const handleExpiredToken = (url, options, timeStamp) => (response) => {
    if (response.status === 401) {
        if (!refreshTokenRequest && timeStamp > refreshTokenTimeStamp) {
            refreshTokenTimeStamp = Date.now();
            refreshTokenRequest = refreshToken().then(() => (refreshTokenRequest = null));
        }

        return fetchWithToken(url, options);
    } else {
        return response;
    }
};

export default function fetchWithTokenRefresh(url, options) {
    return fetchWithToken(url, options).then(handleExpiredToken(url, options, Date.now()));
}
