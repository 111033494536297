import * as React from 'react';
import i18n from 'ui-i18n';
import {Component} from 'react';
type Props = {
    children: React.ReactNode | null;
};
type State = {
    hasError: boolean;
};
export default class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error: Record<string, any>, info: Record<string, any>) {
        this.setState({
            hasError: true
        });
        console.error('ErrorBoundary caught error', error);
        console.info(info);
    }

    render() {
        if (this.state.hasError) {
            return <h5>{i18n.text('Something went wrong.')}</h5>;
        }

        return this.props.children;
    }
}
