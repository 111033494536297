/**
 * Created by ndyumin on 27.10.2016.
 */
import i18n from 'ui-i18n';
import moment from 'moment';
import {fromDate, fromNotZeroNumber} from './maybe';

function _formatDate(date) {
    return i18n.date(date, 'L');
}

export function isToday(date) {
    return _formatDate(date) === _formatDate(Date.now());
}
export function formatDate(value, full = false) {
    return fromDate(value)
        .orElse(fromNotZeroNumber(value).map((v) => new Date(v)))
        .map((date, today = isToday(date), formatted = _formatDate(date)) =>
            (today ? i18n.text('Today') : formatted).concat(full && today ? ` ${formatted}` : '')
        )
        .orSome(i18n.text('Never'));
}
const DATETIME_PATTERNS = [
    'MM/DD/YYYY HH.mm',
    'MM.DD.YYYY HH.mm',
    'MM-DD-YYYY HH.mm',
    'YYYYMMDD',
    'YYYY.MM.DD',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'MM-DD-YYYY',
    'MM.DD.YYYY',
    'MM/DD/YYYY',
    'YYYY-MM-DDTHH:mm:ss.SSSSZ',
    'YYYY-MM-DDTHH:mm:ssZ',
    'YYYY-MM-DDTHH:mm:ss+HH:mm'
];
export function dateStringToMoment(str, strict = false) {
    return moment(str, DATETIME_PATTERNS, strict);
}
