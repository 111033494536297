import * as R from 'ramda';
import SearchWordsHighlighter from '../../components/SearchWordsHighlighter/SearchWordsHighlighter';
import {checkClientOnly, checkRemoved} from '../../core/marks';
export default function CanonicalCodeCellRenderer({rowData, cellData, columnData}: Record<string, any>) {
    const {query} = columnData;
    const isEnabled = R.propOr(false, 'enabled', rowData);
    const isRemoved = checkRemoved(rowData);
    const isClientOnly = checkClientOnly(rowData);
    return (
        <div>
            <SearchWordsHighlighter
                style={{
                    textDecoration: isRemoved ? 'line-through' : 'initial',
                    color: isRemoved || !isEnabled ? 'inherit' : 'rgba(0, 0, 0, 0.87)'
                }}
                value={cellData}
                searchQuery={query}
                searchFields={['canonicalCode']}
                enabled={!isClientOnly}
            />
        </div>
    );
}
