import * as R from 'ramda';
import CustomTextField from '../TextField/TextField';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Fragment} from 'react';
import {GENERATOR_TYPES} from '../../rdm-sdk/generators';
import {Generator} from '../../rdm-sdk/generators.types';
import {fromNull} from '../../core/maybe';
import {getValue} from '../../core/util';
import {validateDuplicateName, validateRangeStart} from './validation';
type Props = Generator & {
    isNew: boolean;
    generators?: Generator[] | null;
    onChange: (field: 'name' | 'type' | 'rangeStart') => (data: string | number) => void;
};

const GeneratorEditor = ({generators, name, type, rangeStart, onChange, isNew}: Props) => {
    const isDuplicateName = isNew && validateDuplicateName(name, generators).isFail();
    const isRangeStartCorrect = validateRangeStart(type, rangeStart).isSuccess();
    return (
        <Fragment>
            <CustomTextField
                onChange={R.pipe(getValue, onChange('name'))}
                value={fromNull(name).orSome('')}
                label={i18n.text('Name')}
                required={true}
                readOnly={!isNew}
                error={!!isDuplicateName}
                helperText={isDuplicateName ? i18n.text('Generator with this name already exists') : ''}
                fullWidth
            />

            <FormControl variant="standard" className={styles['add-generator-dialog__selectfield']}>
                <InputLabel htmlFor="generator-type">{i18n.text('Type')}</InputLabel>
                <Select
                    variant="standard"
                    value={type}
                    onChange={R.pipe(
                        getValue,
                        R.tap(
                            R.pipe(
                                R.ifElse(R.equals('SEQUENTIAL'), R.always(0), R.always(undefined)),
                                onChange('rangeStart')
                            )
                        ),
                        onChange('type')
                    )}
                    input={<Input id="generator-type" />}
                >
                    {Object.keys(GENERATOR_TYPES).map((type, idx) => (
                        <MenuItem dense key={idx} value={type}>
                            {GENERATOR_TYPES[type]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {type === 'SEQUENTIAL' && (
                <CustomTextField
                    onChange={R.pipe(getValue, onChange('rangeStart'))}
                    value={rangeStart}
                    label={i18n.text('Start from')}
                    error={!isRangeStartCorrect}
                    helperText={!isRangeStartCorrect ? i18n.text('Incorrect start seed') : ''}
                    fullWidth
                />
            )}
        </Fragment>
    );
};

export default GeneratorEditor;
