import CollapseButton from '../../CollapseButton/CollapseButton';
import CommentContent from '../../../CommentContent/CommentContent';
import CustomIcon from './../../../CustomIcon/CustomIcon';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {MAX_VISIBLE_COMMENTS_IN_DCR} from '../../constants';
import {StateEvent} from '../../../../rdm-sdk/state.types';
import {WorkflowComment} from '../../workflow.types';
import {connect} from 'react-redux';
import {getDateTimeLabel} from '../../../../rdm-sdk/dates';
import {isNotEmpty} from '../../../../core/util';
import {showAllCommentsWFLogger, showLessCommentsWFLogger} from '../../logging';
import {useState} from 'react';
export type CommentsProps = {
    comments: WorkflowComment[];
    dispatch: (e: StateEvent) => void;
};

type CommentProps = {
    comment: WorkflowComment;
};

const Comment = ({comment}: CommentProps) => (
    <div className={styles['comment-wrapper']}>
        <div className={styles['comment__avatar']}>
            <CustomIcon name="face" />
        </div>
        <div className={styles['comment__info']}>
            <div className={styles['comment__author']}>{comment.author}</div>
            <div className={styles['comment__time']}>{getDateTimeLabel(comment.time)}</div>
        </div>
        <div className={styles['comment__message']}>
            <CommentContent className={styles['comment__message-content']}>{comment.message}</CommentContent>
        </div>
    </div>
);

export function Comments({comments = [], dispatch}: CommentsProps) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const showCollapseButton = MAX_VISIBLE_COMMENTS_IN_DCR < comments.length;
    const visibleComments =
        showCollapseButton && isCollapsed ? comments.slice(0, MAX_VISIBLE_COMMENTS_IN_DCR) : comments;

    const toggleCollapse = () => {
        isCollapsed
            ? dispatch(showAllCommentsWFLogger(null) as StateEvent)
            : dispatch(showLessCommentsWFLogger(null) as StateEvent);
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={styles['comments-wrapper']}>
            {isNotEmpty(visibleComments) ? (
                visibleComments.map((comment) => <Comment key={comment.id} comment={comment} />)
            ) : (
                <p className={styles['comments-wrapper__no-comments']}>{i18n.text('No comments')}</p>
            )}
            {showCollapseButton && (
                <CollapseButton
                    collapsed={isCollapsed}
                    onClick={toggleCollapse}
                    showMoreLabel={i18n.text('Show all comments')}
                    showLessLabel={i18n.text('Show less comments')}
                    className={styles['comments-wrapper__collapse-button']}
                />
            )}
        </div>
    );
}
export default connect()(Comments);
