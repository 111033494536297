import * as R from 'ramda';
import i18n from 'ui-i18n';
import {LookupType} from './types.types';
import {LookupValue} from './lookups.types';
import {inRange} from '../core/util';
export const getNumberLabel = (value: number): string => {
    if (value <= 999) {
        return String(value);
    }

    if (value > 999 && value <= 9999) {
        return (value / 1000).toFixed(1) + 'K';
    }

    if (value > 9999 && value <= 99999) {
        return (value / 1000).toFixed(0) + 'K';
    }

    if (value > 99999 && value <= 9999999) {
        return (value / 1000000).toFixed(1) + 'M';
    }

    return (value / 1000000).toFixed(0) + 'M';
};
export const formatCommentTime = (unix: number) => i18n.date(unix, 'lll');
export const getStartDate = R.propOr(0, 'startDate');
export const getEndDate = R.pipe(R.propOr(0, 'endDate'), R.when(R.equals(0), R.always(Infinity)));
export const getEnabled = (type?: LookupValue | LookupType) =>
    type?.enabled && inRange(getStartDate(type), getEndDate(type), Date.now());
