import * as R from 'ramda';
import ClickOutsideHOC from '../ClickOutsideHOC/ClickOutsideHOC';
import InputTrigger from '../InputTrigger/InputTrigger';
import Suggestor from './Suggestor/Suggestor';
import styles from './input-suggestor.less';
import {Maybe} from 'monet';
import {PureComponent} from 'react';
import {User} from '../../rdm-sdk/app.types';
import {isSuggestorElement} from '../../rdm-sdk/collaboration';
import {noop} from '../../core/util';

const filterUsers = (users, text) =>
    users.filter(({username, email}) => username.includes(text) || email.includes(text));

type InputMetaData = {
    hookType: 'start' | 'cancel' | 'typing';
    cursor: {
        selectionStart: number;
        selectionEnd: number;
        top: number;
        left: number;
        height: number;
    };
    text?: string;
};
type Props = {
    value: string;
    onChange: (event: Record<string, any>) => void;
    users: User[];
    children: (...args: any[]) => any;
};
type State = {
    value: string;
    showSuggestor: boolean;
    suggestorText?: string;
    selectedSuggestion: number;
    startPosition?: number | null;
    textarea: HTMLTextAreaElement | null;
};

class InputSuggestor extends PureComponent<Props, State> {
    static defaultProps = {
        onChange: noop
    };
    suggestor: HTMLUListElement | null = null;
    suggestorRef: (ref: HTMLUListElement | null) => void;
    endTrigger: () => void = noop;

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
            showSuggestor: false,
            suggestorText: '',
            selectedSuggestion: 0,
            startPosition: null,
            textarea: null
        };

        this.suggestorRef = (ref) => (this.suggestor = ref);
    }

    textareaRef = (ref: HTMLTextAreaElement) => {
        this.setState({
            textarea: ref
        });
    };
    onInputChange = (event: Record<string, any>) => {
        this.setState({
            value: event.target.value
        });
        this.props.onChange(event);
    };
    resetValue = () =>
        this.setState({
            value: ''
        });
    onClickOutside = (event: Record<string, any>) => {
        if (isSuggestorElement(event.target)) return;
        this.onTriggerCancel();
        this.endTrigger();
    };
    onTriggerStart = (metaData: InputMetaData) => {
        this.setState({
            showSuggestor: true,
            selectedSuggestion: 0,
            startPosition: metaData.cursor.selectionStart,
            suggestorText: ''
        });
    };
    onTriggerCancel = () => {
        this.setState({
            showSuggestor: false,
            startPosition: null
        });
    };
    onTriggerType = (metaData: InputMetaData) => {
        this.setState((state) => ({
            suggestorText: metaData.text,
            selectedSuggestion: state.suggestorText !== metaData.text ? 0 : state.selectedSuggestion
        }));
    };
    endTriggerHandler = (endTrigger: () => void) => {
        this.endTrigger = endTrigger;
    };
    keyDownHandler = (event: Record<string, any>) => {
        const {key} = event;
        const {users} = this.props;
        const {selectedSuggestion, showSuggestor, suggestorText} = this.state;
        if (!showSuggestor) return;

        switch (key) {
            case 'ArrowDown': {
                event.preventDefault();
                const newSelectedSuggestion = R.mathMod(
                    selectedSuggestion + 1,
                    filterUsers(users, suggestorText).length
                );
                this.setState({
                    selectedSuggestion: newSelectedSuggestion
                });

                if (this.suggestor) {
                    this.suggestor.scrollTop =
                        newSelectedSuggestion > selectedSuggestion ? this.suggestor.scrollTop + 50 : 0;
                }

                break;
            }

            case 'ArrowUp': {
                event.preventDefault();
                const newSelectedSuggestion = R.mathMod(
                    selectedSuggestion - 1,
                    filterUsers(users, suggestorText).length
                );
                this.setState({
                    selectedSuggestion: newSelectedSuggestion
                });

                if (this.suggestor) {
                    this.suggestor.scrollTop =
                        newSelectedSuggestion < selectedSuggestion
                            ? this.suggestor.scrollTop - 50
                            : this.suggestor.scrollHeight;
                }

                break;
            }

            case 'Enter':
                event.preventDefault();
                this.insertSuggestion(filterUsers(users, suggestorText)[selectedSuggestion] || '');
        }
    };
    onSuggestionMouseEnter = (selectedSuggestion: number) =>
        this.setState({
            selectedSuggestion
        });
    insertSuggestion = (user?: User | null) => {
        const {value, startPosition} = this.state;
        let newValue = value;

        if (user && startPosition) {
            newValue =
                value.slice(0, startPosition) +
                user.email +
                ' ' +
                value.slice(startPosition + user.email.length, value.length);
        }

        this.setState({
            showSuggestor: false,
            startPosition: null,
            value: newValue
        });
        this.endTrigger();
    };

    render() {
        const {users, children: inputRenderer} = this.props;
        const {value, showSuggestor, suggestorText, selectedSuggestion, textarea} = this.state;
        const filteredUsers = filterUsers(users, suggestorText);
        // @ts-ignore
        const safeTextareaContainerWidth: Maybe<number | undefined> = Maybe.fromNull(textarea)
            // @ts-ignore
            .map((textarea) => textarea?.parentNode)
            // @ts-ignore
            .map((div) => div?.parentNode)
            .map((container) => window.getComputedStyle(container as Element))
            .map((styles) => styles.width)
            .map((width) => parseInt(width, 10))
            .map((width) => width - 14);
        const textareaContainerWidth: number | undefined = safeTextareaContainerWidth.orSome(undefined);
        return (
            <ClickOutsideHOC onClickOutside={this.onClickOutside}>
                <div className={styles['input-suggestor__container']} onKeyDown={this.keyDownHandler}>
                    <InputTrigger
                        element={textarea}
                        trigger={{
                            keyCode: 50,
                            shiftKey: true
                        }}
                        onStart={this.onTriggerStart}
                        onCancel={this.onTriggerCancel}
                        onType={this.onTriggerType}
                        endTrigger={this.endTriggerHandler}
                    >
                        {inputRenderer({
                            value,
                            textareaRef: this.textareaRef,
                            onInputChange: this.onInputChange,
                            resetValue: this.resetValue,
                            showSuggestor
                        })}
                    </InputTrigger>
                    {showSuggestor && (
                        <Suggestor
                            style={{
                                width: textareaContainerWidth
                            }}
                            suggestorRef={this.suggestorRef}
                            users={filteredUsers}
                            selected={selectedSuggestion}
                            onItemClick={this.insertSuggestion}
                            onItemMouseEnter={this.onSuggestionMouseEnter}
                        />
                    )}
                </div>
            </ClickOutsideHOC>
        );
    }
}

export default InputSuggestor;
