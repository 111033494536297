import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {HTMLProps} from 'react';
import {SortDirection, Sorting} from '../../rdm-sdk/app.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {toggleOrder} from '../../core/util';
export const getToggledSort = (field: string, sort: Sorting) => {
    const direction = sort.field === field ? toggleOrder(sort.direction) : SortDirection.ASC;
    return {
        field,
        direction
    };
};
type Props = Omit<HTMLProps<HTMLDivElement>, 'onChange'> & {
    sort: Sorting;
    label: string;
    field: string;
    onChange: (sort: Sorting) => void;
    dispatch: (event: StateEvent) => Promise<any>;
    logger: (action: string) => (payload: any) => StateEvent;
};

const SortedColumnTitle = ({dispatch, logger, label, field, sort, onChange, ...props}: Props) => {
    const isSorted = sort.field === field;
    return (
        <div
            {...props}
            className={`${styles['col-title--sortable']} ${isSorted ? 'sorted' : ''}`}
            onClick={() => {
                onChange(getToggledSort(field, sort));
                dispatch(
                    logger('sorting-change')({
                        field,
                        sort
                    })
                );
            }}
        >
            {isSorted && (
                <Tooltip title={i18n.text('Sort')}>
                    <Icon className={styles['sort-order-icon']}>
                        {`arrow_${sort.direction === SortDirection.ASC ? 'up' : 'down'}ward`}
                    </Icon>
                </Tooltip>
            )}
            <span>{label}</span>
        </div>
    );
};

export default SortedColumnTitle;
