import * as R from 'ramda';
import {AUTO_GENERATED_CODE_PREFIX, ERRORS} from '../../constants/common';
import {LookupValue} from '../../rdm-sdk/lookups.types';
import {UNMAPPED_ITEMS_TYPE} from '../../constants/dragTypes';
import {UnmappedItem} from '../../rdm-sdk/unmapped.types';
import {
    checkRemoved,
    getUnmappedKey,
    markClientOnly,
    markEdited,
    markErroneous,
    markNew,
    markWithUnmappedKey
} from '../../core/marks';
import {getCurrentGenerator} from '../../rdm-sdk/types';
import {getTypeByCode} from '../../rdm-sdk/types';
import {mergeUnmappedWithCanonical} from '../../rdm-sdk/unmapped';
import {unmappedRemovedItemsAppendEvent, unmappedSelectedItemsClearEvent} from '../../redux/actions/unmapped';
import {upsertValueEvent} from '../../redux/actions/values';
import {useDrop} from 'react-dnd';
import {validateAttributes} from '../ReferenceDialog/validation';
export const getKey = (value) =>
    value.code && value.code.startsWith(AUTO_GENERATED_CODE_PREFIX)
        ? AUTO_GENERATED_CODE_PREFIX
        : getUnmappedKey(value) || Date.now();
export const loadItemsOnDrop = (items, unmappedValues, loadMoreUnmapped) => {
    const getSelectedSources = R.pipe(R.groupBy(R.prop('source')), R.keys);

    const getUnmappedValue = (source) => R.find(R.propEq('source', source), unmappedValues);

    R.pipe(getSelectedSources, R.forEach(R.pipe(getUnmappedValue, loadMoreUnmapped)))(items);
};

type Item = {
    selectedItems: UnmappedItem[];
};

export const useCanonicalCellDrop = ({
    value,
    logger,
    dispatch,
    type,
    unmappedValues,
    loadMoreUnmapped,
    validateLookupValue
}) => {
    return useDrop(
        () => ({
            accept: UNMAPPED_ITEMS_TYPE,
            canDrop: () => {
                return !checkRemoved(value);
            },
            drop: (item: Item) => {
                const dropLogger = logger('add-reference-drop');
                const {selectedItems} = item;
                const key = getKey(value);
                dispatch(unmappedRemovedItemsAppendEvent(key, selectedItems));
                dispatch(unmappedSelectedItemsClearEvent());
                const mergedValue = R.pipe(
                    mergeUnmappedWithCanonical,
                    validateLookupValue,
                    markEdited,
                    markWithUnmappedKey(key)
                )(selectedItems, value);
                dispatch(upsertValueEvent(type, value.code, mergedValue));
                loadItemsOnDrop(selectedItems, unmappedValues, loadMoreUnmapped);
                dispatch(dropLogger(selectedItems));
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                monitorId: monitor.getHandlerId()
            })
        }),
        [value, unmappedValues]
    );
};

export const useReferenceButtonDrop = ({
    logger,
    onAddCanonicalDrop,
    dispatch,
    currentType,
    configuration,
    unmappedValues,
    loadMoreUnmapped,
    checkCodeUnique
}) => {
    return useDrop(
        () => ({
            accept: UNMAPPED_ITEMS_TYPE,
            drop: (item: Item) => {
                const {selectedItems} = item;
                const dropLogger = logger('create-reference-drop');
                const currentTypeConf = getTypeByCode(configuration, currentType);
                const isGeneratorEnabled = Boolean(getCurrentGenerator(configuration, currentType));
                const code = isGeneratorEnabled
                    ? AUTO_GENERATED_CODE_PREFIX + Date.now()
                    : R.prop('code', R.head(selectedItems));
                const key = getKey({
                    code
                });
                const lookupValue = R.pipe(
                    R.assoc('code', code),
                    markClientOnly,
                    markWithUnmappedKey(key)
                )(mergeUnmappedWithCanonical(selectedItems, {} as LookupValue));
                const attributesExtended = R.pipe(
                    R.propOr([], 'attributes'),
                    R.map((attr) =>
                        Object.assign(
                            {},
                            attr,
                            R.find(R.propEq('name', attr.name), R.defaultTo([], lookupValue.attributes))
                        )
                    )
                )(currentTypeConf);
                const isAttributesValid = validateAttributes(attributesExtended).isSuccess();
                loadItemsOnDrop(selectedItems, unmappedValues, loadMoreUnmapped);

                const updateUnmapped = () => {
                    dispatch(unmappedRemovedItemsAppendEvent(key, selectedItems));
                    dispatch(unmappedSelectedItemsClearEvent());
                };

                const updateValues = () => {
                    dispatch(upsertValueEvent(currentType, null, markNew(lookupValue)));
                    updateUnmapped();
                };

                const openDialog = (isCodeUnique) => {
                    const value = !isCodeUnique ? markErroneous(ERRORS.CODE_EXISTS, lookupValue) : lookupValue;
                    onAddCanonicalDrop(value, updateUnmapped);
                };

                const onDrop = (isCodeUnique) => {
                    if (isCodeUnique && isAttributesValid) {
                        updateValues();
                    } else {
                        openDialog(isCodeUnique);
                    }
                };

                if (isGeneratorEnabled) {
                    onDrop(true);
                } else {
                    checkCodeUnique(code)
                        .then(onDrop)
                        .catch((error) => console.error('Check for uniqueness of the code is failed', error));
                }

                dispatch(dropLogger(selectedItems));
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                monitorId: monitor.getHandlerId()
            })
        }),
        [configuration, unmappedValues]
    );
};
