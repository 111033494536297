import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListFilter, {ListFilterProps} from '../ListFilter/ListFilter';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {CustomTableHeaderProps} from './CustomTable';
import {noop} from '../../core/util';

type TableHeaderRendererProps = {
    caption: string;
    summary: string | null;
    summaryWarning?: string | null | false;
    summaryWarningTooltip?: string;
    filter?: Omit<ListFilterProps, 'logFilterChange'>;
    logger: (message: string) => void;
};
const tableHeaderRenderer = ({
    caption,
    summary,
    summaryWarning,
    summaryWarningTooltip = '',
    filter,
    logger
}: TableHeaderRendererProps) =>
    function tableHeaderRenderer({selected = [], unselectClb = noop, removeClb = noop} = {} as CustomTableHeaderProps) {
        return (
            <div
                className={clsx(
                    styles['custom-table__caption'],
                    selected.length && styles['custom-table__caption--active']
                )}
                role="row"
            >
                <span className={styles['custom-table__title']}>{caption}</span>
                {selected.length > 0 && (
                    <span className={styles['custom-table__title--unselect']} onClick={unselectClb}>
                        {selected.length > 1
                            ? i18n.text('Unselect ${count} items', {
                                  count: selected.length
                              })
                            : i18n.text('Unselect ${count} item', {
                                  count: selected.length
                              })}
                    </span>
                )}
                {selected.length === 0 && (summary || summaryWarning) && (
                    <span className={styles['custom-table__summary']}>
                        {summary}
                        {summaryWarning && (
                            <Tooltip title={summaryWarningTooltip}>
                                <span className={styles['custom-table__summary-warning']}>
                                    <Icon className={styles['custom-table__summary-warning-icon']}>warning</Icon>
                                    {summaryWarning}
                                </span>
                            </Tooltip>
                        )}
                    </span>
                )}
                <div className="spacer" />
                {selected.length ? (
                    <Tooltip title={i18n.text('Delete')}>
                        <IconButton onClick={removeClb} size="large">
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                ) : (
                    filter && <ListFilter logger={logger} {...filter} />
                )}
            </div>
        );
    };

export default tableHeaderRenderer;
