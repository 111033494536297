import CustomIcon from '../CustomIcon/CustomIcon';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './add-comment-button.less';
import {PureComponent} from 'react';
type Props = {
    setParentHovered: (arg0: boolean) => () => void;
    onClick: () => void;
};

class AddCommentButton extends PureComponent<Props> {
    render() {
        const {setParentHovered, onClick} = this.props;
        return (
            <div onMouseEnter={setParentHovered(true)} onMouseLeave={setParentHovered(false)}>
                <Tooltip id="tooltip-comment-button" title={i18n.text('Add a comment')}>
                    <div className={styles['add-comment']} onClick={onClick}>
                        <CustomIcon name="comment" />
                    </div>
                </Tooltip>
            </div>
        );
    }
}

export default AddCommentButton;
