import {LookupValue} from '../../rdm-sdk/lookups.types';
import {ValuesSearchQuery} from '../../rdm-sdk/filters.types';
import {contains, splitToWords} from '../../core/util';
import {fromNull} from '../../core/maybe';
export type SearchHighlightMapping = {
    matchedDate: boolean;
    sources: Record<
        string,
        {
            matchedCode: Record<string, boolean>;
            matchedSource: boolean;
            matchedValue: Record<string, boolean>;
        }
    >;
};

const isEmpty = (value: any) => value === null || typeof value === 'undefined' || value.length === 0;

const notEmpty = (value) => !isEmpty(value);

export const matchSearchSource = (sourcesQuery: string[], source: string) =>
    isEmpty(sourcesQuery) || notEmpty(sourcesQuery.filter((s) => s.includes(source)));
export const searchQueryIsNotEmpty = (searchQuery: ValuesSearchQuery) =>
    [
        searchQuery.value.value,
        searchQuery.code.value,
        searchQuery.startDate,
        searchQuery.endDate,
        searchQuery.hasWords,
        searchQuery.canonicalValue.value
    ].some(notEmpty);

const toggleNegate = (queryNegate) => (v) => (queryNegate ? !v : v);

const findWords = (str, value, all = false) => splitToWords(str)[all ? 'every' : 'some'](contains(value));

const matchSearchDate = (searchQuery, startDate, endDate) => {
    const foundStartDate = !searchQuery.startDate || new Date(startDate) >= searchQuery.startDate;
    const foundEndDate = !searchQuery.endDate || new Date(endDate) <= searchQuery.endDate;
    return foundStartDate && foundEndDate;
};

export const matchSearchQuery = (
    searchQuery: ValuesSearchQuery,
    searchMapping: SearchHighlightMapping,
    source: string,
    value: string,
    code: string
) => {
    const sourceSearchMapping = searchMapping.sources[source];
    const matchedAnyValue =
        sourceSearchMapping.matchedCode[code] ||
        sourceSearchMapping.matchedValue[value] ||
        (isEmpty(searchQuery.code.value) && isEmpty(searchQuery.value.value) && isEmpty(searchQuery.hasWords));
    return searchMapping.matchedDate && sourceSearchMapping.matchedSource && matchedAnyValue;
};
export const buildSearchMapping = (
    searchQuery: ValuesSearchQuery,
    lookupValues: LookupValue[]
): SearchHighlightMapping[] =>
    lookupValues.map((value) => {
        const searchMapping = {} as SearchHighlightMapping;
        searchMapping.matchedDate = matchSearchDate(searchQuery, value.startDate, value.endDate);
        searchMapping.sources = Object.keys(value.sources).reduce((acc, s) => {
            const matchedSource = matchSearchSource(searchQuery.sources, s);
            const matchedCode = {};
            const matchedValue = {};
            const codesString = value.sources[s].map((sv) => sv.code).join(' ');
            const toggleCode = toggleNegate(searchQuery.code.negate);
            const safeCode = fromNull(searchQuery.code.value).orSome('');
            const matchedAllCode = matchedSource && findWords(safeCode, codesString, true);
            const isEmptyCode = isEmpty(searchQuery.code.value);
            const valuesString = value.sources[s].map((sv) => sv.value).join(' ');
            const toggleValue = toggleNegate(searchQuery.value.negate);
            const safeValue = fromNull(searchQuery.value.value).orSome('');
            const matchedAllValue = matchedSource && findWords(safeValue, valuesString, true);
            const isEmptyValue = isEmpty(searchQuery.value.value);
            const safeHasWords = fromNull(searchQuery.hasWords).orSome('');
            const isEmptyHasWords = isEmpty(searchQuery.hasWords);
            const codeAndValues = codesString + ' ' + valuesString;
            const matchedAllHasWords = matchedSource && findWords(safeHasWords, codeAndValues);
            value.sources[s].forEach((sv) => {
                matchedCode[sv.code] =
                    (!isEmptyCode && toggleCode(matchedAllCode && findWords(safeCode, sv.code))) ||
                    (!isEmptyHasWords && findWords(safeHasWords, sv.code));
                matchedValue[sv.value] =
                    (!isEmptyValue && toggleValue(matchedAllValue && findWords(safeValue, sv.value))) ||
                    (!isEmptyHasWords && findWords(safeHasWords, sv.value));
            });
            const sourceMapping = {
                matchedSource:
                    (isEmptyCode || toggleCode(matchedAllCode)) &&
                    (isEmptyValue || toggleValue(matchedAllValue)) &&
                    (isEmptyHasWords || matchedAllHasWords),
                matchedCode,
                matchedValue
            };
            return {...acc, [s]: sourceMapping};
        }, {});
        return searchMapping;
    });
