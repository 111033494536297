import {User} from '../rdm-sdk/app.types';
import {startsWith} from 'ramda';

type PxUser = {
    id: string;
    email: string;
    isFreemium?: boolean;
};
declare global {
    interface Window {
        aptrinsic?: (name: string, key: PxUser | string, value: Record<string, unknown>) => void;
        pxKey?: string;
    }
}

const isFreemium = (user: User) => {
    return (user.roles || []).some(startsWith('ROLE_FREEMIUM'));
};

const initPx = (user: User) => {
    if (!window.pxKey) return;

    if (!window.aptrinsic) {
        setTimeout(initPx, 500, user);
    } else {
        const userConfig: PxUser = {
            id: user.username,
            email: user.email
        };
        if (isFreemium(user)) {
            userConfig.isFreemium = true;
        }
        window.aptrinsic('identify', userConfig, {
            id: user.customer || 'Undefined'
        });
        window.aptrinsic('set', 'globalContext', {app: 'rdm-ui'});
    }
};

export const initAnalytics = (user: User) => {
    if (window.pxKey) {
        initPx(user);
    }
};
