import UnmappedDragPreview from '../UnmappedTable/UnmappedDragPreview';
import styles from './styles.less';
import {GRID_COLUMN_REORDER_TYPE, UNMAPPED_ITEMS_TYPE} from '../../constants/dragTypes';
import {noop} from '../../core/util';
import {useDragLayer} from 'react-dnd';
const STYLE_FUNCTIONS = {
    [UNMAPPED_ITEMS_TYPE](props) {
        const {initialOffset, currentOffset} = props;

        if (!initialOffset || !currentOffset) {
            return {
                display: 'none'
            };
        }

        const transform = `translate(${currentOffset.x}px,${currentOffset.y}px)`;
        return {
            transform,
            WebkitTransform: transform
        };
    },

    [GRID_COLUMN_REORDER_TYPE](props) {
        const {initialOffset, currentOffset, item} = props;

        if (!initialOffset || !currentOffset) {
            return {
                display: 'none'
            };
        }

        const transform = `translate(${currentOffset.x}px,${160}px)`;
        return {
            transform,
            WebkitTransform: transform,
            width: item.columnWidth,
            height: item.columnHeight,
            backgroundColor: 'rgba(0,0,0,0.12)',
            boxShadow: '0 1px 0 0 rgba(0,0,0,0.1), 0 2px 5px 0 rgba(0,0,0,0.26)'
        };
    }
};

export const CustomDragLayer = () => {
    const {item, itemType, currentOffset, isDragging, initialOffset} = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
        initialOffset: monitor.getInitialSourceClientOffset()
    }));

    const renderItem = () => {
        switch (itemType) {
            case UNMAPPED_ITEMS_TYPE:
                return <UnmappedDragPreview selectedItems={item.selectedItems} />;

            case GRID_COLUMN_REORDER_TYPE:
                return <div />;

            default:
                return null;
        }
    };

    const getStyle = STYLE_FUNCTIONS[itemType as string] || noop;
    return isDragging ? (
        <div className={styles['dragLayer']}>
            <div style={getStyle({initialOffset, currentOffset, item})}>{renderItem()}</div>
        </div>
    ) : null;
};

export default CustomDragLayer;
