/**
 * Created by ndyumin on 31.03.2017.
 */
import {Validation} from 'monet';
export const validateValue = (mapping) =>
    typeof mapping.value === 'string' && mapping.value.length > 0
        ? Validation.success(mapping.value)
        : Validation.fail(['value is empty']);
export const validateCode = (mapping) =>
    typeof mapping.code === 'string' && mapping.code.length > 0
        ? Validation.success(mapping.code)
        : Validation.fail(['code is empty']);
export const validateMapping = (mapping) => validateValue(mapping).ap(validateCode(mapping).acc());
