import ActivityContent from '../../parts/ActivityContent/ActivityContent';
import ActivityTitle from '../../parts/ActivityTitle/ActivityTitle';
import ActivityUserTime from '../../parts/ActivityUserTime/ActivityUserTime';
import SourceFields from '../SourceFields/SourceFields';
import i18n from 'ui-i18n';
import {Fragment} from 'react';
import {RowItem} from '../../activityLog.types';
type Props = {
    item: RowItem;
};

function SourcesRowContent(props: Props) {
    const {
        item: {label, user, timestamp, type, fields = [], isHead = false}
    } = props;
    const hasFields = fields && fields.length > 0;
    return (
        <Fragment>
            {isHead && <ActivityUserTime user={user} timestamp={timestamp} />}
            <ActivityTitle disabledLink={true} type={type} title={i18n.text('Source:')} label={label} />
            {hasFields && <ActivityContent>{<SourceFields items={fields} />}</ActivityContent>}
        </Fragment>
    );
}

export default SourcesRowContent;
