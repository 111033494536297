import CustomIcon from '../../CustomIcon/CustomIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import {noop} from '../../../core/util';
type Props = {
    onClick?: () => void;
};

function ActivityLogIconButton({onClick = noop}: Props) {
    return (
        <Tooltip title={i18n.text('Activity log')}>
            <IconButton onClick={onClick} size="large">
                <CustomIcon name="activity_log" />
            </IconButton>
        </Tooltip>
    );
}

export default ActivityLogIconButton;
