import * as R from 'ramda';
import FileUploadForm from '../../FileUploadForm/FileUploadForm';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {FileError} from '../../../rdm-sdk/import.types';
import {PureComponent} from 'react';
import {withImportWizardContext} from '../ImportWizardContext';
type Props = {
    onFileUpload: (files: FileList) => void;
    file?: File | null;
    fileErrors: FileError[];
};

class ImportStep1 extends PureComponent<Props> {
    render() {
        const {file, onFileUpload, fileErrors} = this.props;
        const errorMessage = fileErrors.length ? i18n.text('Please import a valid csv file') : null;
        return (
            <div className={styles['step-container']}>
                <Paper elevation={2}>
                    <div className={styles['title-container']}>
                        <Typography variant={'h6'}>{i18n.text('Select file')}</Typography>
                    </div>
                    <div className={styles['content-container']}>
                        <FileUploadForm
                            files={file ? [file] : []}
                            onUpload={onFileUpload}
                            errorMessage={errorMessage}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

const mapContextToProps = R.pick(['file', 'fileErrors', 'onFileUpload']);
export default withImportWizardContext(mapContextToProps, ImportStep1);
