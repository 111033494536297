import * as R from 'ramda';
import * as React from 'react';
import ActivityContent from '../../parts/ActivityContent/ActivityContent';
import ActivityTitle, {ActivityTitleProps} from '../../parts/ActivityTitle/ActivityTitle';
import ActivityUserTime from '../../parts/ActivityUserTime/ActivityUserTime';
import ChangeGroup from '../../ChangeGroup/ChangeGroup';
import HierarchyChange from '../HierarchyChange/HierarchyChange';
import SourceChange from '../SourceChange/SourceChange';
import ValueFields from '../ValueFields/ValueFields';
import i18n from 'ui-i18n';
import {ACTIVITY_TYPE} from '../../constants';
import {ChangeType, LookupActivityItem, NestedChangeType} from '../../activityLog.types';
import {DraftMode} from '../../../../rdm-sdk/app.types';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {dateStringToMoment, formatDate} from '../../../../core/date';
import {formatBool} from '../../../../rdm-sdk/activities';
import {getDispatchOnResolve} from '../../../../redux/middlewares/confirm-middleware/confirmMiddleware';
import {getLookupsByCanonicalValueQuery, getLookupsByMappingInSourcesQuery} from '../../../Search/store';
import {noop} from '../../../../core/util';
import {requestValuesSaga} from '../../../../redux/actions/values';
import {searchQueryEvent} from '../../../../redux/actions/search';
import {showConfirmationDialogEvent} from '../../../../redux/actions/app';

const getItemKey = (item, source = '') => `${item.id || ''}_${item.timestamp}_${item.user}_${item.label}_${source}`;

const getDate = (value) => (typeof value === 'string' ? dateStringToMoment(value).unix() * 1000 : value);

const toDate = R.pipe(getDate, formatDate);

const formatAttribute = (item) => {
    switch (item.dataType) {
        case 'Boolean':
            return R.evolve(
                {
                    newValue: formatBool,
                    oldValue: formatBool
                },
                item
            );

        case 'Date':
        case 'Time':
            return R.evolve(
                {
                    newValue: toDate,
                    oldValue: toDate
                },
                item
            );

        case 'Float':
        case 'Integer':
        case 'Number':
            return R.evolve(
                {
                    newValue: i18n.number,
                    oldValue: i18n.number
                },
                item
            );

        case 'String':
        default:
            return item;
    }
};

const getHasChanges = (changes: boolean[]) => {
    return changes.some(R.identity);
};

type StateProps = {
    draftMode?: DraftMode;
};

type DispatchProps = {
    filterValues: <SQ>(arg0: {query: SQ; draftMode?: DraftMode}) => void;
};

type Props = StateProps &
    DispatchProps & {
        item: LookupActivityItem;
        ChangeComponent: React.ComponentType<ChangeType>;
        NestedChangeComponent?: React.ComponentType<NestedChangeType> | null;
        measureRowHeight?: () => void;
        TitleComponent?: React.ComponentType<ActivityTitleProps & Record<string, unknown>>;
        linkClickLogger: (arg0: string) => void;
        onLinkClick?: () => void;
    };

function ValuesRowContent(props: Props) {
    const {
        item,
        item: {
            user,
            timestamp,
            label,
            type,
            fields = [],
            attributes = [],
            parents = [],
            children = [],
            localizations = [],
            sources = [],
            isHead = false,
            sourceChangeMessage = '',
            id = null
        },
        measureRowHeight = noop,
        filterValues,
        draftMode,
        ChangeComponent,
        NestedChangeComponent,
        TitleComponent
    } = props;
    const hasFields = fields.length > 0;
    const hasAttributes = attributes.length > 0;
    const hasParents = parents.length > 0;
    const hasChildren = children.length > 0;
    const hasLocalizations = localizations.length > 0;
    const hasSources = sources.length > 0;
    const hasUserTime = isHead && Boolean(user && timestamp);
    const hasChanges = getHasChanges([hasAttributes, hasFields, hasParents, hasChildren, hasLocalizations, hasSources]);

    function handleCanonicalLinkClick() {
        filterValues({
            query: getLookupsByCanonicalValueQuery(label),
            draftMode
        });
    }

    const handleMappingLinkClick = R.curry((source: string, mapping) => {
        if (mapping) {
            filterValues({
                query: getLookupsByMappingInSourcesQuery(mapping, [{source}]),
                draftMode
            });
        }
    });
    const Title = TitleComponent || ActivityTitle;
    return (
        <Fragment>
            {hasUserTime && <ActivityUserTime user={user} timestamp={timestamp} />}
            <Title
                id={id}
                disabledLink={type === ACTIVITY_TYPE.DELETE}
                onLinkClick={handleCanonicalLinkClick}
                type={type}
                title={i18n.text('Canonical value:')}
                label={label}
            />

            {hasChanges && (
                <ActivityContent>
                    {hasFields && <ValueFields items={fields} ChangeComponent={ChangeComponent} />}
                    {hasAttributes && (
                        <ChangeGroup
                            title={i18n.text('Custom attributes')}
                            items={attributes.map(formatAttribute)}
                            ChangeComponent={ChangeComponent}
                        />
                    )}
                    {hasParents && (
                        <HierarchyChange
                            id={getItemKey(item)}
                            title={i18n.text('Parents')}
                            items={parents}
                            measureRowHeight={measureRowHeight}
                            ChangeComponent={ChangeComponent}
                        />
                    )}
                    {hasChildren && (
                        <HierarchyChange
                            id={getItemKey(item)}
                            title={i18n.text('Children')}
                            items={children}
                            measureRowHeight={measureRowHeight}
                            ChangeComponent={ChangeComponent}
                        />
                    )}
                    {hasLocalizations && (
                        <ChangeGroup
                            title={i18n.text('Localizations')}
                            items={localizations}
                            ChangeComponent={ChangeComponent}
                        />
                    )}
                    {hasSources &&
                        sources.map(({source, mappings}) => (
                            <SourceChange
                                key={getItemKey(item, source)}
                                sourceChangeMessage={sourceChangeMessage}
                                sourceKey={getItemKey(item, source)}
                                mappings={mappings}
                                source={source}
                                onLinkClick={handleMappingLinkClick(source)}
                                measureRowHeight={measureRowHeight}
                                ChangeComponent={ChangeComponent}
                                NestedChangeComponent={NestedChangeComponent}
                            />
                        ))}
                </ActivityContent>
            )}
        </Fragment>
    );
}

const mapStateToProps: (state) => StateProps = R.pick(['draftMode']);

const mapDispatchToProps = (dispatch, {linkClickLogger, onLinkClick = noop}) => {
    const dispatchOnResolve = getDispatchOnResolve(dispatch);
    return {
        filterValues: ({draftMode, query}) => {
            if (draftMode?.dirtyValues) {
                dispatch(showConfirmationDialogEvent());
            }

            linkClickLogger && dispatch(linkClickLogger(query));
            onLinkClick();
            dispatchOnResolve(searchQueryEvent(query));
            requestValuesSaga(dispatchOnResolve)();
        }
    } as DispatchProps;
};

export default connect(mapStateToProps, mapDispatchToProps)(ValuesRowContent);
