import Tag from '../Tag/Tag';
import clsx from 'clsx';
import styles from '../Tag/styles.less';
import {LocalizationItem, LookupValue, SourceMapping} from '../../rdm-sdk/lookups.types';
import {LookupType} from '../../rdm-sdk/types.types';
import {checkEdited, checkNewTruly} from '../../core/marks';
type Props = {
    entity?: LookupValue | SourceMapping | LookupType | LocalizationItem;
    className?: string;
    position?: 'left' | 'right';
};

const MarkLabel = (props: Props) => {
    const {entity, position = 'left', className, ...otherProps} = props;
    const isEdited = checkEdited(entity);
    const isNew = checkNewTruly(entity);
    let type = null as 'ADD' | 'CHANGE' | null;

    if (isNew) {
        type = 'ADD';
    } else if (isEdited) {
        type = 'CHANGE';
    }

    return (
        <Tag type={type} className={clsx(position === 'right' && styles['icon-right'], className)} {...otherProps} />
    );
};

export default MarkLabel;
