import * as R from 'ramda';
import UnmappedTile from './UnmappedTile';
import clsx from 'clsx';
import styles from './styles.less';
import {CONTENT_MARGIN_TOP} from './constants';
import {UNMAPPED_ITEMS_TYPE} from '../../constants/dragTypes';
import {UnmappedItem} from '../../rdm-sdk/unmapped.types';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {isUnmappedItemsEqual} from '../../rdm-sdk/unmapped';
import {useDrag} from 'react-dnd';
import {useEffect} from 'react';

type Props = {
    items: UnmappedItem[];
    selectedItems: UnmappedItem[];
    searchWords: string[];
    onItemClick: (item: UnmappedItem) => void;
    index: number;
    isUnmappedDragging: boolean;
    setIsDragging: (isDragging: boolean) => void;
};
export const UnmappedTileDraggable = (props: Props) => {
    const {items, selectedItems, searchWords, onItemClick, index, isUnmappedDragging, setIsDragging} = props;

    const [{isDragging, monitorId}, drag, preview] = useDrag(
        () => ({
            type: UNMAPPED_ITEMS_TYPE,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
                monitorId: monitor.getHandlerId()
            }),
            item: () => {
                const item = items[index];
                let selected = selectedItems;

                if (!R.find(isUnmappedItemsEqual(item), selectedItems)) {
                    onItemClick(item);
                    selected = selected.concat(item);
                }
                return {
                    selectedItems: selected
                };
            },
            end: () => {
                setIsDragging(false);
            }
        }),
        [selectedItems]
    );

    useEffect(() => {
        setIsDragging(isDragging);
    }, [isDragging]);

    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true});
    }, []);

    const item = items[index];
    const selectedItemIndex = R.findIndex(isUnmappedItemsEqual(item), R.defaultTo([], selectedItems));
    const isSelected = selectedItemIndex !== -1;
    return (
        <div
            ref={drag}
            data-monitorid={monitorId}
            className={clsx(
                styles['unmapped-table__tile'],
                isSelected && styles['unmapped-draggable-table__tile--selected']
            )}
            style={{
                marginTop: index === 0 ? CONTENT_MARGIN_TOP : 0,
                opacity: isUnmappedDragging && isSelected ? 0 : 1
            }}
        >
            <UnmappedTile
                className={styles['unmapped-draggable-table__tile']}
                items={items}
                searchWords={searchWords}
                onItemClick={onItemClick}
                index={index}
            />
            {isSelected && (
                <div className={styles['unmapped-draggable-table__tile-counter']}>{selectedItemIndex + 1}</div>
            )}
        </div>
    );
};

export default UnmappedTileDraggable;
