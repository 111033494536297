import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import deviceSvg from '../../../assets/inline/device.svg';
import i18n from 'ui-i18n';
import styles from './styles.less';
type Props = {
    path: string;
};

const UploadInfoCard = ({path}: Props) => (
    <Card>
        <h3 className={styles['title']}>{i18n.text('File upload')}</h3>
        <CardContent className={styles['content']}>
            <div
                className={styles['icon']}
                dangerouslySetInnerHTML={{
                    __html: deviceSvg
                }}
            />
            <div className={styles['info-box']}>
                <h4 className={styles['subtitle']}>{i18n.text('Uploaded')}</h4>
                <p className={styles['description']}>{i18n.text('Path of the uploaded file')}</p>
                <p className={styles['path']}>{path}</p>
            </div>
        </CardContent>
    </Card>
);

export default UploadInfoCard;
