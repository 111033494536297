import * as React from 'react';
import ActivityChanges from '../parts/ActivityChanges/ActivityChanges';
import ChangesTitle from '../parts/ChangesTitle/ChangesTitle';
import DefaultChange from '../Change/Change';
import {ChangeType} from '../activityLog.types';
type Props = {
    items: Array<Record<string, any>>;
    title?: string;
    ChangeComponent: React.ComponentType<ChangeType>;
};

function ChangeGroup(props: Props) {
    const {items, title, ChangeComponent} = props;
    const Change = ChangeComponent || DefaultChange;
    return (
        <ActivityChanges>
            {title && <ChangesTitle>{title}</ChangesTitle>}
            {items.map((item, index) => (
                <Change
                    id={item.id}
                    key={item.name + index}
                    displayName={item.name}
                    type={item.type}
                    newValue={item.newValue}
                    oldValue={item.oldValue}
                />
            ))}
        </ActivityChanges>
    );
}

export default ChangeGroup;
