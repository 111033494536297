import * as R from 'ramda';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import ReactDOM from 'react-dom';
import Select, {SelectProps} from '@mui/material/Select';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Component, ReactInstance, ReactNode} from 'react';
import {GroupedOptions, Options} from '../../rdm-sdk/app.types';
import {fromNotEmpty} from '../../core/maybe';
import {getValue} from '../../core/util';
type Props = Omit<SelectProps, 'onSelect'> & {
    onSelect: (value: string | number) => void;
    className: string;
    placeholder: string;
    options?: Options;
    groupedOptions?: GroupedOptions;
    getChipLabel: (value: string) => string;
};

class CustomMultiSelect extends Component<Props> {
    multiSelectRef?: ReactInstance;
    focusOnMultiSelect = () => {
        // workaround for case when Select stays permanently focused (Chips onDelete method calls event.stopPropagation internally)
        // eslint-disable-next-line react/no-find-dom-node
        const wrapperDiv = ReactDOM.findDOMNode(this.multiSelectRef);
        const selectDiv = R.path(['firstChild', 'firstChild'], wrapperDiv);
        selectDiv && selectDiv.focus();
    };

    render() {
        const {onSelect, className, placeholder, options, getChipLabel, groupedOptions, ...otherProps} = this.props;
        return (
            <Select
                multiple
                displayEmpty
                ref={(ref) => (this.multiSelectRef = ref as ReactInstance)}
                className={clsx(styles['multi-select'], className)}
                onChange={R.pipe(getValue, onSelect)}
                renderValue={(items) =>
                    (items as string[]).length > 0 ? (
                        <div className={styles['select__chips']}>
                            {(items as string[]).map((item) => (
                                <Chip
                                    key={item}
                                    label={getChipLabel(item)}
                                    classes={{
                                        root: styles['select__chip'],
                                        label: styles['select__chip-label']
                                    }}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onDelete={R.pipe(
                                        R.always(items),
                                        R.reject(R.equals(item)),
                                        onSelect,
                                        this.focusOnMultiSelect
                                    )}
                                />
                            ))}
                        </div>
                    ) : (
                        placeholder
                    )
                }
                {...otherProps}
            >
                {
                    fromNotEmpty(groupedOptions)
                        .map(
                            R.chain(({groupValue, groupLabel, options}) => [
                                <div
                                    key={groupValue}
                                    className={clsx(styles['select__item'], styles['select__group-label'])}
                                >
                                    {groupLabel}
                                </div>,
                                ...options.map(({value, label}) => (
                                    <MenuItem
                                        className={styles['select__group-option']}
                                        dense
                                        key={value}
                                        value={value}
                                    >
                                        {label}
                                    </MenuItem>
                                ))
                            ])
                        )
                        .orElse(
                            fromNotEmpty(options).map(
                                R.map(({value, label}) => (
                                    <MenuItem dense key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))
                            )
                        )
                        .orSome(<div className={styles['select__item']}>{i18n.text('No items')}</div>) as ReactNode
                }
            </Select>
        );
    }
}

export default CustomMultiSelect;
