import * as R from 'ramda';
import DatePicker from '../DatePicker/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '../TextField/TextField';
import i18n from 'ui-i18n';
import moment from 'moment';
import styles from './styles.less';
import {dateStringToMoment} from '../../core/date';
import {fieldUpdateHandler, getChecked, getValue, later} from '../../core/util';
import {fromBoolean, fromString, isDefined} from '../../core/maybe';
import {validateAttribute} from './validation';

const formatDate = (timestamp) => timestamp && moment(timestamp).format();

const getDate = (value) => {
    if (typeof value === 'string') {
        return new Date(dateStringToMoment(value).unix() * 1000);
    } else if (typeof value === 'number' && value > 0) {
        return new Date(value);
    } else {
        return null;
    }
};

type Props = {
    attribute: Record<string, any>;
    onChange: (attr: Record<string, any>) => void;
    isEditMode: boolean;
};

const Attribute = ({attribute, onChange, isEditMode}: Props) => {
    const value = attribute.value;
    const name = fromString(attribute.name).orSome('');
    const type = fromString(attribute.type).orSome('');
    const required = fromBoolean(attribute.required).orSome(false);
    const next = fieldUpdateHandler(onChange, attribute)('value');
    if (required && !isDefined(value) && type === 'Boolean') later(next)('false');

    switch (type) {
        case 'Date':
            return (
                <DatePicker
                    readOnly={!isEditMode}
                    id={name}
                    label={`${name}${required && isEditMode ? ' *' : ''}`}
                    value={getDate(value)}
                    onSelect={R.pipe(formatDate, next)}
                />
            );

        case 'Boolean':
            return (
                <FormControlLabel
                    className={styles['reference-dialog__attribute-switch']}
                    control={<Switch color="primary" className={styles['reference-dialog__attribute-switch-thumb']} />}
                    disabled={!isEditMode}
                    label={`${name}${required && isEditMode ? ' *' : ''}`}
                    checked={value === 'true'}
                    onChange={R.pipe(getChecked, Boolean, String, next)}
                />
            );

        default: {
            const attrValidation = validateAttribute(attribute);
            const validationFailed = attrValidation.isFail();
            const getValidationErrorMessage = () =>
                (attrValidation.fail() as string[])[0] === 'attribute type check failed'
                    ? i18n.text('Type of input is not ${type}!', {
                          type: i18n.text(type)
                      })
                    : i18n.text('This field is required');
            const errorMessage = validationFailed && isEditMode ? getValidationErrorMessage() : '';
            return (
                <TextField
                    readOnly={!isEditMode}
                    fullWidth
                    label={name}
                    value={value || ''}
                    required={required && isEditMode}
                    onChange={R.pipe(getValue, next)}
                    error={validationFailed}
                    helperText={errorMessage}
                />
            );
        }
    }
};

export default Attribute;
