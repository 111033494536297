import * as React from 'react';
import styles from './portal-dialog.less';
import {Component} from 'react';
import {createPortal} from 'react-dom';
import {createRef} from 'react';
import {getClosestScrollContainer, getElementRect, getTranslate} from '../../core/util';
const RIGHT_DIALOG_PADDING = 20;
const TOP_RIGHT_POSITION_STYLE = {
    left: '100%',
    top: '0'
};
type Props = {
    style?: Record<string, any>;
    target?: HTMLElement;
    children: React.ReactNode;
};

class PortalDialog extends Component<Props> {
    dialogRef: React.RefObject<HTMLDivElement> = createRef();

    componentDidMount() {
        this.setDialogPosition();
        const scrollContainer = getClosestScrollContainer(this.props.target || (this.dialogRef?.current as Element));
        scrollContainer && scrollContainer.addEventListener('scroll', this.setDialogPosition);
        window.addEventListener('resize', this.setDialogPosition);
    }

    componentDidUpdate() {
        this.setDialogPosition();
    }

    componentWillUnmount() {
        const scrollContainer = getClosestScrollContainer(this.props.target || (this.dialogRef.current as Element));
        scrollContainer && scrollContainer.removeEventListener('scroll', this.setDialogPosition);
        window.removeEventListener('resize', this.setDialogPosition);
    }

    setDialogPosition = () => {
        const {target} = this.props;
        const dialog = this.dialogRef?.current;

        if (target && dialog) {
            const targetRect = getElementRect(target);
            dialog.style.left = `${targetRect.x + targetRect.width}px`;
            dialog.style.top = `${targetRect.y}px`;
        }

        const scrollContainer = getClosestScrollContainer(target || (dialog as Element));

        if (scrollContainer && dialog) {
            dialog.style.transform = '';
            const translateDialog = getTranslate(scrollContainer, dialog);
            const translateX = translateDialog.right - (translateDialog.right < 0 ? RIGHT_DIALOG_PADDING : 0);
            const translateY = translateDialog.bottom || translateDialog.top || 0;
            dialog.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
        }
    };

    renderDialog(style: Record<string, any> = {}) {
        const {children} = this.props;
        return (
            <div ref={this.dialogRef} className={styles['portal-dialog']} style={style}>
                {children}
            </div>
        );
    }

    render() {
        const {target} = this.props;

        if (target) {
            const rect = getElementRect(target);
            const style = {
                left: rect.x + rect.width,
                top: rect.y
            };
            return createPortal(this.renderDialog(style), global.document.body);
        } else return this.renderDialog(TOP_RIGHT_POSITION_STYLE);
    }
}

export default PortalDialog;
