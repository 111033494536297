import * as R from 'ramda';
import {activityLogInitialState} from '../initialState';
import {checkRange, isEmpty} from '../../core/util';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
export const getActivitiesTotal = R.pathOr(0, ['activityLog', 'total']);
// eslint-disable-next-line prettier/prettier
export function totalReducer(state = activityLogInitialState.total, event) { // NOSONAR
    switch (event.type) {
        case 'setActivitiesTotal':
            return event.total;

        case 'resetActivitiesTotal':
            return activityLogInitialState.total;

        default:
            return state;
    }
}
export const getActivities = R.pathOr(null, ['activityLog', 'activities']);
// eslint-disable-next-line prettier/prettier
export function activitiesReducer(state = activityLogInitialState.activities, event) { // NOSONAR
    switch (event.type) {
        case 'activitiesReceived':
            return event.activities;

        case 'resetActivities':
            return activityLogInitialState.activities;

        default:
            return state;
    }
}
export const getActivitiesPagination = R.path(['activityLog', 'pagination']);
// eslint-disable-next-line prettier/prettier
export function paginationReducer(state = activityLogInitialState.pagination, event) { // NOSONAR
    switch (event.type) {
        case 'setActivitiesPagination':
            return event.pagination;

        case 'setActivitiesFirstPage':
            return {...state, page: 0};

        case 'resetActivitiesPagination':
            return activityLogInitialState.pagination;

        default:
            return state;
    }
}
export const getActivityLogFilterActivityTypes = R.pathOr([], ['activityLog', 'filters', 'activityTypes']);
export const getActivityLogFilterUsers = R.pathOr([], ['activityLog', 'filters', 'users']);
export const getActivityLogFilterStartDate = R.pathOr(null, ['activityLog', 'filters', 'startDate']);
export const getActivityLogFilterEndDate = R.pathOr(null, ['activityLog', 'filters', 'endDate']);
export const getActivityLogFilterSources = R.pathOr([], ['activityLog', 'filters', 'sources']);
export const getActivityLogFilterLookupValues = R.pathOr([], ['activityLog', 'filters', 'lookupValues']);
export const getActivityLogFilterLookupTypes = R.pathOr([], ['activityLog', 'filters', 'lookupTypes']);
export const getActivityLogFilters = R.pathOr(activityLogInitialState.filters, ['activityLog', 'filters']);
export const getIsActivityLogFilterEmpty = R.pipe(getActivityLogFilters, R.values, R.map(isEmpty), R.all(R.identity));
export const getRDMTenantName = R.path(['tenant']);
// eslint-disable-next-line prettier/prettier
export function filtersReducer(state = activityLogInitialState.filters, event) { //NOSONAR
    switch (event.type) {
        case 'setActivityLogFilterActivityTypes':
            return R.assoc('activityTypes', event.activityTypes, state);

        case 'setActivityLogFilterUsers':
            return R.assoc('users', event.users, state);

        case 'setActivityLogFilterStartDate':
            return checkRange(event.startDate, state.endDate) ? R.assoc('startDate', event.startDate, state) : state;

        case 'setActivityLogFilterEndDate':
            return checkRange(state.startDate, event.endDate) ? R.assoc('endDate', event.endDate, state) : state;

        case 'setActivityLogFilterSources':
            return R.assoc('sources', event.sources, state);

        case 'setActivityLogFilterLookupValues':
            return R.assoc('lookupValues', event.lookupValues, state);

        case 'setActivityLogFilterLookupTypes':
            return R.assoc('lookupTypes', event.lookupTypes, state);

        case 'resetActivityLogFilters':
            return activityLogInitialState.filters;

        default:
            return state;
    }
}
export const getIsActivityLogFilterApplied = R.pathOr(false, ['activityLog', 'isFilterApplied']);
// eslint-disable-next-line prettier/prettier
export function isFilterAppliedReducer(state = activityLogInitialState.isFilterApplied, event) { // NOSONAR
    switch (event.type) {
        case 'setActivityLogFilterApplied':
            return true;

        case 'resetActivityLogFilters':
            return false;

        default:
            return state;
    }
}
export const getActivityLogTenant = R.pathOr(null, ['activityLog', 'tenant']);
// eslint-disable-next-line prettier/prettier
export function tenantReducer(state = activityLogInitialState.tenant, event) { // NOSONAR
    switch (event.type) {
        case 'setActivityLogTenant':
            return event.tenant || null;

        case 'resetActivityLogTenant':
            return null;

        default:
            return state;
    }
}
export const getIsActivityLogEnabled = createSelector(
    getActivityLogTenant,
    R.either(R.path(['policies', 'activityLogRead']), R.path(['policies', 'activityLogAllRead'])),
    (_state, props) => R.pathOr(false, ['features', 'activityLog'], props),
    (activityLogTenant, userHasRights, activityLogFeatureEnabled) =>
        activityLogTenant && userHasRights && activityLogFeatureEnabled
);
export default combineReducers({
    activities: activitiesReducer,
    total: totalReducer,
    pagination: paginationReducer,
    filters: filtersReducer,
    isFilterApplied: isFilterAppliedReducer,
    tenant: tenantReducer
});
