import * as R from 'ramda';
import FileDetails from '../FileDetails/FileDetails';
import MappingSummary from '../MappingSummary/MappingSummary';
import styles from './styles.less';
import {PureComponent} from 'react';
import {withImportWizardContext} from '../ImportWizardContext';
type Props = {
    file?: File | null;
    typeLabel: string;
    fileLinesCount?: number | null;
    totalSources: number;
    mappedSources: number;
};

class ImportStep3 extends PureComponent<Props> {
    render() {
        const {file, typeLabel, fileLinesCount, totalSources, mappedSources} = this.props;
        return (
            <div className={styles['step-container']}>
                {file && <FileDetails fileName={file.name} typeLabel={typeLabel} fileLinesCount={fileLinesCount} />}
                <MappingSummary totalSources={totalSources} mappedSources={mappedSources} />
            </div>
        );
    }
}

const mapContextToProps = (context) => ({
    file: context.file,
    typeLabel: R.propOr('-', 'label', context.selectedLookupType),
    fileLinesCount: context.fileLinesCount,
    totalSources: R.pathOr(0, ['0', 'length'], context.fileData),
    mappedSources: R.uniqBy(R.prop('column'), context.mappings).length
});

export default withImportWizardContext(mapContextToProps, ImportStep3);
