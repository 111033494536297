import Change from '../../../ActivityLog/Change/Change';
import ChangeItemDecorator from '../ChangeItemDecorator/ChangeItemDecorator';
import {ChangeType} from '../../../ActivityLog/activityLog.types';

function DCRChange(props: ChangeType) {
    const {id} = props;
    return (
        <ChangeItemDecorator id={id} grouped={false}>
            <Change {...props} />
        </ChangeItemDecorator>
    );
}

export default DCRChange;
