import * as React from 'react';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Component} from 'react';
import {ElementRef} from 'react';
import {Grid, GridProps, MultiGridProps} from 'react-virtualized';
import {ScrollData} from '../../rdm-sdk/app.types';
const MAX_HIDDEN_HEIGHT = 10;
type Props = {
    startIndexStream$: BehaviorSubject<number>;
    maxIndex: number;
    children: (arg0: {
        onScroll: (arg0: ScrollData) => void;
        gridRef: (arg0: ElementRef<typeof Grid>) => void;
        startIndex: number;
    }) => React.ReactNode;
    canLoad: boolean;
    loadMore: (onLoaded: () => void) => void;
};
type State = {
    startIndex: number;
    isLoading: boolean;
};

type GridRef = GridProps & {
    _renderedRowStartIndex: number;
};

class ViewportSync extends Component<Props, State> {
    _grid?: MultiGridProps & GridRef;
    startIndexSubscription?: Subscription;
    scrollTop?: number;
    scrolledToEnd?: boolean;
    renderedLastIndex?: boolean;
    state = {
        startIndex: -1,
        isLoading: false
    };

    componentDidMount() {
        this.startIndexSubscription = this.props.startIndexStream$.subscribe((startIndex) => {
            this.setState({
                startIndex
            });
        });
    }

    componentDidUpdate() {
        const {canLoad} = this.props;

        if (
            this._grid &&
            canLoad &&
            !this.state.isLoading &&
            this._grid?.props.height > this._grid.getTotalRowsHeight()
        ) {
            this.setState({
                isLoading: true
            });
            this.props.loadMore(() => {
                this.setState({
                    isLoading: false
                });
            });
        }
    }

    componentWillUnmount() {
        this.startIndexSubscription?.unsubscribe();

        if (this._grid) {
            this.updateStartIndex();
        }
    }

    updateStartIndex = () => {
        const {_renderedRowStartIndex} = this._grid || {};

        const firstRowOffset = this?._grid?.getOffsetForCell({
            rowIndex: _renderedRowStartIndex,
            alignment: 'start'
        });

        const firstRowHiddenHeight = Number(this.scrollTop) - firstRowOffset.scrollTop;
        const newStartIndex = Number(_renderedRowStartIndex) + (firstRowHiddenHeight > MAX_HIDDEN_HEIGHT ? 1 : 0);
        const oldStartIndex = this.props.startIndexStream$.getValue();

        if (oldStartIndex !== newStartIndex && (oldStartIndex <= newStartIndex || this.canScrollDown())) {
            this.props.startIndexStream$.next(newStartIndex);
        }
    };
    canScrollDown = () => !this.renderedLastIndex || !this.scrolledToEnd;
    onScroll = (data: ScrollData) => {
        const {scrollTop, scrollHeight, clientHeight} = data;
        this.scrollTop = scrollTop;

        if (scrollHeight) {
            this.scrolledToEnd = scrollTop >= scrollHeight - clientHeight;
        }

        if (this._grid) {
            this.renderedLastIndex = this._grid._renderedRowStopIndex >= this.props.maxIndex;

            if (this.state.startIndex !== -1) {
                const offset = this._grid.getOffsetForCell({
                    rowIndex: this.state.startIndex,
                    alignment: 'start'
                });

                if (scrollTop === offset.scrollTop || !this.canScrollDown()) {
                    this.setState({
                        startIndex: -1
                    });
                }
            }
        }
    };
    gridRef = (grid: ElementRef<typeof Grid>) => {
        if (grid) {
            // @ts-ignore
            this._grid = grid;
        }
    };

    render() {
        return this.props.children({
            onScroll: this.onScroll,
            gridRef: this.gridRef,
            startIndex: this.state.startIndex
        });
    }
}

export default ViewportSync;
