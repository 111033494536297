import {Source} from './sources.types';
export type Coordinates = {
    row: number;
    col: number;
};
export type LocalizationColumnItem = {
    code: string;
    label: string;
    columnWidth: number;
};
export type MappingColumnItem = Source & {
    columnWidth: number;
};
export type ColumnItem = LocalizationColumnItem | MappingColumnItem;
export type HiddenColumnsRecord = {
    index: number;
    items: ColumnItem[];
};
export type MappingIdentifier = {
    code: string;
    source: string;
    mappingIndex: number;
};

export enum SelectedMappingMode {
    EDIT = 'edit',
    FOCUS = 'focus'
}

export type SelectedMapping = {
    identifier: MappingIdentifier;
    mode: SelectedMappingMode;
};
