import * as R from 'ramda';
import Divider from '@mui/material/Divider';
import ExportButton from './ExportButton';
import FeatureFilter from '../Feature/FeatureFilter';
import ImportButton from './ImportButton';
import generatorsIcon from '../../assets/inline/generator.svg';
import i18n from 'ui-i18n';
import sourcesIcon from '../../assets/inline/sources.svg';
import styles from './drawer.less';
import typesIcon from '../../assets/inline/lookupTypes.svg';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Component, FC, ReactElement, SVGAttributes} from 'react';
import {FEATURE} from '../../rdm-sdk/features';
import {Link} from 'react-router';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {fromNull} from '../../core/maybe';
import {logActivityCommand} from '../../redux/actions/activityLogging';

const isCurrent = (location) => (path) => location.pathname === path;

const wrap = (value) => <span>{value}</span>;

type SideMenuItemProps = {
    icon: FC<SVGAttributes<SVGElement>>;
    children: ReactElement | string | ReactElement[];
    path: string;
    isCurrent: (path: string) => boolean;
    onClick: () => void;
};

const SideMenuItem = ({icon, children, path, isCurrent, onClick}: SideMenuItemProps) => (
    <Link
        to={path}
        className={isCurrent(path) ? `${styles.menu_item} ${styles.menu_item__selected}` : styles.menu_item}
        onClick={onClick}
    >
        <span
            className={styles.menu_item__icon}
            dangerouslySetInnerHTML={{
                __html: icon
            }}
        />
        {children instanceof Array ? children.map(wrap) : wrap(children)}
    </Link>
);

type Props = {
    open: boolean;
    location: Record<string, any>;
    policies: CheckedPolicies;
    dispatch: (e: StateEvent) => void;
    tenant?: string | null;
};
const logger = logActivityCommand('drawer');
const menuItemClickLogger = logger('nav-item-click');
const exportClickLogger = logger('export-button-click');
const importClickLogger = logger('import-button-click');
export class Drawer extends Component<Props> {
    render() {
        const {
            dispatch,
            open,
            tenant: tenantId,
            location,
            policies: {generatorsEdit}
        } = this.props;
        const isCurrentPath = isCurrent(location);
        const tenant = fromNull(tenantId).orSome('');
        const logMenuItemClick = R.pipe(R.always(undefined), menuItemClickLogger, dispatch);
        return (
            <div className={`${styles.primary_text} ${styles.custom_drawer} ${open ? styles.isVisible : ''}`}>
                <nav className={styles.navigation}>
                    <SideMenuItem
                        isCurrent={isCurrentPath}
                        path={`/${tenant}/types`}
                        icon={typesIcon}
                        onClick={logMenuItemClick}
                    >
                        {i18n.text('Lookup Types')}
                    </SideMenuItem>
                    <SideMenuItem
                        isCurrent={isCurrentPath}
                        path={`/${tenant}/sources`}
                        icon={sourcesIcon}
                        onClick={logMenuItemClick}
                    >
                        {i18n.text('Sources')}
                    </SideMenuItem>
                    {generatorsEdit && (
                        <SideMenuItem
                            isCurrent={isCurrentPath}
                            path={`/${tenant}/generators`}
                            icon={generatorsIcon}
                            onClick={logMenuItemClick}
                        >
                            {i18n.text('Generators')}
                        </SideMenuItem>
                    )}
                </nav>
                <Divider className={styles.divider} />
                <FeatureFilter required={[FEATURE.EXPORT]}>
                    <ExportButton onClick={R.pipe(R.always(undefined), exportClickLogger, dispatch)} />
                </FeatureFilter>
                <FeatureFilter required={[FEATURE.IMPORT]}>
                    <ImportButton onClick={R.pipe(R.always(undefined), importClickLogger, dispatch)} />
                </FeatureFilter>
            </div>
        );
    }
}
const mapStateToProps = R.pick(['policies']) as (state) => {
    policies: CheckedPolicies;
};
export default connect(mapStateToProps)(Drawer);
