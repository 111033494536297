import activityLogEmptyIcon from '../../../assets/inline/activityLogEmpty.svg';
import i18n from 'ui-i18n';

const EmptyState = () => (
    <div className={'empty-state'}>
        <div
            className={'empty-state__icon'}
            dangerouslySetInnerHTML={{
                __html: activityLogEmptyIcon
            }}
        />
        <h3 className={'empty-state__header'}>{i18n.text("We couldn't find any activities")}</h3>
        <p className={'empty-state__text'}>{i18n.text('Check your filters parameters or try later')}</p>
    </div>
);

export default EmptyState;
