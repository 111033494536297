import * as R from 'ramda';
import {RequestBuilder} from '../../rdm-sdk/network';
import {catchIfNotAbortError} from '../../errors/utils';
import {checkIfTypeIsClientOnly} from '../../rdm-sdk/types';
import {commandCreator} from '../middlewares/command-middleware';
import {getTasksCreatorFilter} from '../selectors/mainReducerSelectors';
import {json, requestWithBlockingSpinner, requestWithNonBlockingSpinner} from '../../network';
import {then} from '../../core/func';
import {valuesFilterFromState} from '../../rdm-sdk/filters';
const MAX_COUNT_TOTAL_TASKS = 25;
const MAX_TOTALS_SOURCES = 10000;
const MAX_TOTALS_LOOKUP_TYPES = 1000;
const getCode = R.pipe(R.prop('uri'), R.split('/'), R.last);
export const statsEvent = (stats) => ({
    type: 'statsUpdate',
    stats
});
export const resetAllStatsEvent = () => ({
    type: 'statsUpdate',
    stats: {
        valuesByTypes: {},
        valuesBySources: {},
        unmappedByTypes: {},
        unmappedBySources: {},
        typesHasDCR: {}
    }
});
export const resetValuesBySourcesStatsEvent = () =>
    statsEvent({
        valuesBySources: {}
    });

const getTotalStats = (statKey, getTotalKey = R.identity) =>
    R.pipe(
        R.defaultTo({}),
        R.toPairs,
        R.reduce((stats, [key, total]) => R.assoc(getTotalKey(key), total, stats), {}),
        R.assoc(statKey, R.__, {})
    );

export const requestLookupTotalsByTypes = commandCreator(({state}, withBlockingSpinner = false) => {
    const {tenant, policies} = state;

    const getRequest = () => {
        const request = new RequestBuilder(`/rdm/lookups/${tenant}/_facets`)
            .addParam('facet', 'type')
            .addParam('max', MAX_TOTALS_LOOKUP_TYPES)
            .build();
        return (
            withBlockingSpinner ? requestWithBlockingSpinner(request) : requestWithNonBlockingSpinner(request)
        ).then(json);
    };

    const totalsP = policies.lookupValuesRead ? getRequest() : Promise.resolve({});
    return totalsP
        .then(R.prop('type'))
        .then(
            getTotalStats('valuesByTypes', (uri) =>
                getCode({
                    uri
                })
            )
        )
        .catch((error) => {
            console.error('Get lookups totals (by types) error', error);
            return Promise.reject(error);
        });
});
export const requestHasLookupsDCRByTypes = commandCreator(({state}) => {
    const {
        tenant,
        configuration: {lookupTypes},
        policies
    } = state;

    const makeRequest = ({maxCount, objectURIs = null}: {maxCount: number; objectURIs?: string[] | null}) => {
        type BodyObject = {
            offset: number;
            max: number;
            createdBy: string;
            objectURIs?: string[];
        };
        const bodyObject: BodyObject = {
            offset: 0,
            max: maxCount,
            createdBy: getTasksCreatorFilter(state)
        };
        if (objectURIs) bodyObject.objectURIs = objectURIs;
        const body = JSON.stringify(bodyObject);
        return requestWithNonBlockingSpinner(`/workflow/${tenant}/tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body
        }).then(json);
    };

    const makeRequests = () =>
        lookupTypes.map((type) => {
            return makeRequest({
                maxCount: 1,
                objectURIs: [R.prop('uri', type)]
            });
        });

    if (!policies.lookupValuesRead)
        return Promise.resolve({
            typesHasDCR: []
        });
    return makeRequest({
        maxCount: MAX_COUNT_TOTAL_TASKS
    })
        .then(({data, total}) => {
            if (data.length === total) {
                const lookupTypeUris = R.pluck('uri', lookupTypes);
                const stats = data.reduce((acc, {objectURIs}) => {
                    const uri = lookupTypeUris.find((uri) => objectURIs.includes(uri));
                    return uri
                        ? R.assoc(
                              getCode({
                                  uri
                              }),
                              true,
                              acc
                          )
                        : acc;
                }, {});
                return {
                    typesHasDCR: lookupTypes.reduce((stats, lookupType) => {
                        const code = getCode(lookupType);
                        stats[code] = stats[code] || false;
                        return stats;
                    }, stats)
                };
            } else {
                return Promise.all(makeRequests()).then((typesHasDCR) => {
                    return {
                        typesHasDCR: typesHasDCR.reduce(
                            (stats, task, idx) => ({
                                ...stats,
                                [getCode(lookupTypes[idx])]: R.pipe(R.prop('data'), R.isEmpty, R.not)(task)
                            }),
                            {}
                        )
                    };
                });
            }
        })
        .catch((error) => {
            console.error('Get DCR presence in lookup type error', error);
            return Promise.reject(error);
        });
});
export const requestLookupTotalsBySources = commandCreator(({state}, signal = null) => {
    const {tenant, configuration, currentType} = state;
    const filter = valuesFilterFromState(state);

    const getRequest = () => {
        const currentTypeIsClientOnly = checkIfTypeIsClientOnly(configuration, currentType);
        const requestOptions = signal
            ? {
                  signal
              }
            : {};
        const request = new RequestBuilder(`/rdm/lookups/${tenant}/_facets`)
            .addParam('facet', 'source')
            .addParam('max', MAX_TOTALS_SOURCES)
            .addParam('filter', filter)
            .build();
        return currentTypeIsClientOnly
            ? Promise.resolve({})
            : requestWithNonBlockingSpinner(request, requestOptions).then(json);
    };

    return getRequest()
        .then(R.prop('source'))
        .then(getTotalStats('valuesBySources'))
        .catch(
            catchIfNotAbortError((error) => {
                console.error('Get lookups totals (by source) error', error);
                return Promise.reject(error);
            })
        );
});
export const requestUnmappedTotalsByTypes = commandCreator(({state}) => {
    const {tenant, policies} = state;

    const getRequest = () => {
        const request = new RequestBuilder(`/rdm/unmapped/${tenant}/_facets`)
            .addParam('facet', 'type')
            .addParam('max', MAX_TOTALS_LOOKUP_TYPES)
            .build();
        return requestWithNonBlockingSpinner(request).then(json);
    };

    const totalsP = policies.lookupValuesRead ? getRequest() : Promise.resolve({});
    return totalsP
        .then(R.prop('type'))
        .then(
            getTotalStats('unmappedByTypes', (uri) =>
                getCode({
                    uri
                })
            )
        )
        .catch((error) => {
            console.error('Get unmapped totals (by types) error', error);
            return Promise.reject(error);
        });
});
export const requestUnmappedTotalsBySources = commandCreator(({state}) => {
    const {
        tenant,
        configuration: {sources},
        policies
    } = state;
    const request = new RequestBuilder(`/rdm/unmapped/${tenant}/_facets`).addParam('facet', 'source').build();
    const initialTotals = sources.reduce((totals, source) => R.assoc(getCode(source), 0, totals), {});
    return policies.lookupValuesRead && !R.isEmpty(sources)
        ? requestWithNonBlockingSpinner(request)
              .then(json)
              .then(R.prop('source'))
              .then((totals) => ({
                  unmappedBySources: {...initialTotals, ...totals}
              }))
              .catch((error) => {
                  console.error('Get unmapped totals (by sources) error', error);
                  return Promise.reject(error);
              })
        : Promise.resolve({
              unmappedBySources: initialTotals
          });
});
export const requestLookupTotalsByTypesSaga = (dispatch) =>
    R.pipe(requestLookupTotalsByTypes, dispatch, then(R.compose(dispatch, statsEvent)));
export const requestHasLookupsDCRByTypesSaga = (dispatch) =>
    R.pipe(requestHasLookupsDCRByTypes, dispatch, then(R.compose(dispatch, statsEvent)));
export const requestLookupTotalsBySourcesSaga = (dispatch) =>
    R.pipe(requestLookupTotalsBySources, dispatch, then(R.compose(dispatch, statsEvent)));
export const requestUnmappedTotalsByTypesSaga = (dispatch) =>
    R.pipe(requestUnmappedTotalsByTypes, dispatch, then(R.compose(dispatch, statsEvent)));
export const requestUnmappedTotalsBySourcesSaga = (dispatch) =>
    R.pipe(requestUnmappedTotalsBySources, dispatch, then(R.compose(dispatch, statsEvent)));
