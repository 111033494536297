import * as R from 'ramda';
import * as React from 'react';
import AddCommentButton from '../../AddCommentButton/AddCommentButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CommentDialog from '../../CommentDialog/CommentDialog';
import CommentTriangle from '../../Triangles/CommentTriangle';
import PortalDialog from '../../PortalDialog/PortalDialog';
import TetherComponent from 'react-tether';
import clsx from 'clsx';
import styles from './custom-table-row.less';
import {CheckedPolicies} from '../../../permissions/permissions.types';
import {CollaborationOptions, GeneralCollaboration} from '../../../rdm-sdk/collaboration.types';
import {Column} from 'react-virtualized';
import {LookupType} from '../../../rdm-sdk/types.types';
import {LookupValue} from '../../../rdm-sdk/lookups.types';
import {OpenedComment} from '../../../rdm-sdk/ui.types';
import {PureComponent} from 'react';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {TableValue} from '../../../rdm-sdk/app.types';
import {checkClientOnly, checkRemoved} from '../../../core/marks';
import {fromBoolean} from '../../../core/maybe';
import {getLookupValueObjectId, isSuggestorElement} from '../../../rdm-sdk/collaboration';
import {later, noop} from '../../../core/util';
import {setOpenedCommentEvent} from '../../../redux/actions/ui';
type Props = {
    dispatch: (event: StateEvent) => Promise<any>;
    currentType: string;
    className?: string | null;
    style: Record<string, any>;
    onRowClick: (arg0: {event: React.SyntheticEvent<HTMLDivElement>; rowData: TableValue}) => void;
    rowData: TableValue;
    children: Array<React.ReactElement<typeof Column>>;
    collaborationOptions: CollaborationOptions;
    collaboration?: GeneralCollaboration | null;
    openedComment?: OpenedComment | null;
    collaborationEnabled: boolean;
    logger: (action: string) => (payload: any) => StateEvent;
    policies: CheckedPolicies;
};
type State = {
    isHovered: boolean;
};

const isLookupValueType = (value: TableValue): value is LookupValue => {
    return Object.prototype.hasOwnProperty.call(value, 'code');
};

const isTableValueHasEnabled = (value: TableValue): value is LookupValue | LookupType => {
    return Object.prototype.hasOwnProperty.call(value, 'enabled');
};

class TableRow extends PureComponent<Props, State> {
    state = {
        isHovered: false
    };
    setHovered = (isHovered: boolean) => () =>
        this.setState({
            isHovered
        });
    onMouseEnter = this.setHovered(true);
    onMouseLeave = this.setHovered(false);
    openCommentDialog = () => {
        const {rowData, dispatch} = this.props;

        if (isLookupValueType(rowData)) {
            dispatch(
                setOpenedCommentEvent({
                    code: rowData.code
                })
            );
        }

        this.onMouseLeave();
    };
    closeCommentDialog = () => this.props.dispatch(setOpenedCommentEvent(null));
    onClickOutsideComment = R.unless(R.pipe(R.prop('target'), isSuggestorElement), later(this.closeCommentDialog));

    isCollaborationEnabled() {
        return this.props.collaborationEnabled;
    }

    getShouldRenderCommentButton(hasComment: boolean) {
        const {rowData, collaborationOptions, policies} = this.props;
        const {isHovered} = this.state;
        return (
            this.isCollaborationEnabled() &&
            policies.collaborationEdit &&
            collaborationOptions &&
            isHovered &&
            !hasComment &&
            !checkClientOnly(rowData)
        );
    }

    getShouldRenderComponentDialog(hasComment: boolean) {
        const {openedComment, policies, rowData} = this.props;
        const {isHovered} = this.state;
        const code = isLookupValueType(rowData) ? rowData.code : undefined;
        const isCommentDialogOpen = openedComment ? openedComment.code === code : false;
        return (
            (this.isCollaborationEnabled() &&
                policies.collaborationEdit &&
                hasComment &&
                isHovered &&
                !openedComment) ||
            isCommentDialogOpen
        );
    }

    render() {
        const {
            rowData,
            className,
            onRowClick,
            style,
            children: columns,
            collaborationOptions,
            collaboration,
            openedComment,
            currentType,
            dispatch,
            logger,
            policies
        } = this.props;
        const isRemoved = fromBoolean(checkRemoved(rowData)).orSome(false);
        const isEnabled = isTableValueHasEnabled(rowData) ? rowData.enabled : true;
        const code = isLookupValueType(rowData) ? rowData.code : undefined;
        const isCommentDialogOpen = openedComment ? openedComment.code === code : false;
        const removedStyle: React.CSSProperties = isRemoved
            ? {
                  pointerEvents: 'none',
                  textDecoration: 'line-through'
              }
            : {};
        const enabledStyle = isEnabled
            ? {
                  color: 'rgba(0,0,0,0.54)'
              }
            : {
                  color: 'rgba(0,0,0,0.42)'
              };
        const objectId = getLookupValueObjectId(currentType, code);
        const commentId = R.pipe(
            R.prop('commentsCount'),
            R.propOr([], objectId),
            R.filter(R.propEq('status', 'open')),
            R.nth(0),
            R.propOr(null, 'commentId')
        )(collaboration);
        const hasComment = commentId !== null;
        const shouldRenderComponentDialog = this.getShouldRenderComponentDialog(hasComment);
        const shouldRenderCommentButton = this.getShouldRenderCommentButton(hasComment);
        const shouldRenderCommentTriangle = this.isCollaborationEnabled() && policies.collaborationEdit && hasComment;
        const onClickOutside = isCommentDialogOpen ? this.onClickOutsideComment : noop;
        return (
            <TetherComponent
                attachment="middle left"
                targetAttachment="middle right"
                constraints={[
                    {
                        to: 'window',
                        pin: true
                    }
                ]}
                targetOffset="0 -21px"
                style={{
                    zIndex: '1499'
                }}
                renderTarget={(ref) => {
                    return (
                        <div
                            ref={ref as React.Ref<HTMLDivElement>}
                            className={clsx(className, shouldRenderComponentDialog && styles['table-row--dialog-open'])}
                            role="row"
                            onClick={(event) => {
                                if (
                                    !['A', 'INPUT'].includes(
                                        // @ts-ignore
                                        event.target.nodeName
                                    )
                                ) {
                                    onRowClick({
                                        rowData,
                                        event
                                    });
                                }
                            }}
                            style={{...style, ...removedStyle, ...enabledStyle}}
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        >
                            {shouldRenderCommentTriangle && (
                                <CommentTriangle className={styles['table-row__comment-triangle']} />
                            )}
                            {columns}
                            {shouldRenderComponentDialog && (
                                <PortalDialog target={ref.current}>
                                    <ClickAwayListener onClickAway={onClickOutside}>
                                        <CommentDialog
                                            isOpen={isCommentDialogOpen}
                                            collaborationOptions={collaborationOptions}
                                            data={{
                                                code
                                            }}
                                            closeCommentDialog={this.closeCommentDialog}
                                            commentId={commentId}
                                            setParentHovered={this.setHovered}
                                            openCommentDialog={this.openCommentDialog}
                                        />
                                    </ClickAwayListener>
                                </PortalDialog>
                            )}
                        </div>
                    );
                }}
                renderElement={(ref) =>
                    shouldRenderCommentButton &&
                    !shouldRenderComponentDialog && (
                        <AddCommentButton
                            ref={ref}
                            onClick={R.pipe(
                                this.openCommentDialog,
                                R.always(rowData),
                                logger('add-comment-click'),
                                dispatch
                            )}
                            setParentHovered={this.setHovered}
                        />
                    )
                }
            />
        );
    }
}

export default TableRow;
