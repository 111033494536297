import BehaviourSubjectSubscription from './BehaviourSubjectSubscription';
import {Observable} from 'rxjs';
import {ReactNode} from 'react';
import {convertObservableToBehaviorSubject} from '../../core/util';

type Props<T> = {
    children: (...args: T[]) => ReactNode;
    source: Observable<unknown>;
    initialValue?: unknown;
};

function ObservableSubscription<T>({children, source, initialValue}: Props<T>) {
    return (
        <BehaviourSubjectSubscription source={convertObservableToBehaviorSubject(source, initialValue)}>
            {children}
        </BehaviourSubjectSubscription>
    );
}

ObservableSubscription.defaultProps = {
    initialValue: null
};
export default ObservableSubscription;
