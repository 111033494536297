import i18n from 'ui-i18n';
import {always, assoc, path, pipe, prop, uniq} from 'ramda';
import {collectDCRs, parseWorkflowTasks} from '../../rdm-sdk/workflow';
import {commandCreator} from '../middlewares/command-middleware';
import {
    getConflictChangesByTaskId,
    getRejectedChangesByTaskId,
    getTasksPagination,
    getTasksURIs
} from '../selectors/workflowSelectors';
import {getTasksCreatorFilter} from '../selectors/mainReducerSelectors';
import {json, requestWithBlockingSpinner, requestWithNonBlockingSpinner} from '../../network';
import {resetDependenciesEvent} from './dependencies';
import {sequentialPromiseAll} from '../../core/util';
import {setOpenedSnackbarEvent, setOpenedTaskEvent} from './ui';
import {then} from '../../core/func';
import {typeCodeToUri} from '../../rdm-sdk/types';
export const resetWorkflowsEvent = () => ({
    type: 'resetWorkflows'
});
export const resetWorkflowDCRsEvent = () => ({
    type: 'resetWorkflowDCRs'
});
export const workflowTasksReceivedEvent = (tasks) => ({
    type: 'workflowTasksReceived',
    tasks
});
export const workflowTaskUpdateEvent = (task) => ({
    type: 'workflowTaskUpdate',
    task
});
export const workflowTaskResetEvent = (taskID) => ({
    type: 'workflowTaskReset',
    taskID
});
export const workflowDCRsReceivedEvent = (DCRs) => ({
    type: 'workflowDCRsReceived',
    DCRs
});
export const taskCommentSentEvent = () => ({
    type: 'taskCommentSent'
});
export const workflowAddRejectedEvent = (id) => ({
    type: 'workflowAddRejected',
    id
});
export const workflowDeleteRejectedEvent = (id) => ({
    type: 'workflowDeleteRejected',
    id
});
export const taskAssigneesReceivedEvent = (assignees) => {
    const {taskId, data} = assignees;
    return {
        type: 'taskAssigneesReceived',
        taskId,
        assignees: data
    };
};
export function setTasksPaginationEvent(pagination) {
    return {
        type: 'setTasksPagination',
        pagination
    };
}
export function resetTasksPaginationEvent() {
    return {
        type: 'resetTasksPagination'
    };
}
export function setIsTenantRegisteredEvent(isRegistered) {
    return {
        type: 'tenantRegistered',
        isRegistered
    };
}
export function setDisplayedTaskEvent(displayedTask) {
    return {
        type: 'setDisplayedTask',
        displayedTask
    };
}
export const requestWorkflowTasksCommand = commandCreator(({state}) => {
    const {tenant, currentType} = state;
    const {rowsPerPage, page} = getTasksPagination(state);
    const body = JSON.stringify({
        objectURIs: [typeCodeToUri(encodeURIComponent(currentType))],
        offset: page * rowsPerPage,
        max: rowsPerPage,
        createdBy: getTasksCreatorFilter(state)
    });
    return requestWithBlockingSpinner(
        `/workflow/${tenant}/tasks`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body
        },
        i18n.text('Tasks request error')
    )
        .then(json)
        .then(parseWorkflowTasks);
});
export const requestWorkflowTaskCommand = commandCreator(({state}, taskId) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/workflow/${tenant}/tasks/${taskId}`, {}, i18n.text('Task request error'))
        .then(json)
        .then((task) =>
            parseWorkflowTasks({
                data: [task]
            })
        );
});
export const requestWorkflowDCRsCommand = commandCreator(({state}) => {
    const {tenant} = state;
    const uris = getTasksURIs(state);
    return requestWithBlockingSpinner(
        `/rdm/changeRequests/${tenant}/_byUris?showObjectsInfo=true&showConflicts=true`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uris
            })
        },
        i18n.text('Data change request error')
    )
        .then(json)
        .then(collectDCRs);
});
export const requestTaskChange = (tenant, taskId, data) => {
    return requestWithBlockingSpinner(`/workflow/${tenant}/tasks/${taskId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(json);
};
export const changeTaskCommand = commandCreator(({state}, taskId, task) => {
    const {tenant} = state;
    return requestTaskChange(tenant, taskId, task);
});
export const createDCRCommand = commandCreator(({state}, DCR = {}) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(
        `/rdm/changeRequests/${tenant}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...DCR, tenantId: tenant})
        },
        i18n.text('Create DCR error')
    ).then(json);
});
export const startProcessCommand = commandCreator(({state}, DCRs) => {
    const {tenant, currentType} = state;
    return requestWithBlockingSpinner(
        `/workflow/${tenant}/processInstances`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                processType: 'rdmDataChangeRequestReview',
                objectURIs: [typeCodeToUri(currentType), path([0, 'id'], DCRs)]
            })
        },
        i18n.text('Start process error')
    ).then(json);
});
export const requestTaskAssigneesCommand = commandCreator(({state}, taskId) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(
        `/workflow/${tenant}/assignee`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tasks: [taskId]
            })
        },
        i18n.text('Assignees request error')
    )
        .then(json)
        .then(assoc('taskId', taskId));
});
export const deleteUnnecessaryDCRCommand = commandCreator(({state}, taskID) => {
    const {tenant} = state;
    const changesToDelete = uniq(
        getRejectedChangesByTaskId(state, taskID).concat(getConflictChangesByTaskId(state, taskID))
    );
    return sequentialPromiseAll(
        changesToDelete.map(
            ({changeId, DCRId}) =>
                () =>
                    requestWithBlockingSpinner(
                        `/rdm/changeRequests/${tenant}/${DCRId}/changes/${changeId}`,
                        {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        },
                        i18n.text('Delete DCR change error')
                    ).then(json)
        )
    );
});
export const executeActionCommand = commandCreator(({state}, action, taskId) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(
        `/workflow/${tenant}/tasks/${taskId}/_action`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                action
            })
        },
        i18n.text('Execute action error')
    ).then(json);
});
const resetOpenedTaskCommand = commandCreator(({state, dispatch}) => {
    const {ui} = state;
    return ui.openedTask
        ? Promise.resolve(null)
              .then(pipe(setOpenedTaskEvent, dispatch))
              .then(pipe(resetWorkflowsEvent, dispatch))
              .then(requestWorkflowTasksSaga(dispatch))
        : Promise.resolve();
});
export const getIsTenantRegisteredCommand = commandCreator(({state}) => {
    const {tenant} = state;
    return requestWithNonBlockingSpinner(`/workflow/tenant_check/${tenant}`).then(json).then(prop('registered'));
});

const handleReceivedTasks = (dispatch) => (tasks) => {
    dispatch(workflowTasksReceivedEvent(tasks));
    dispatch(resetWorkflowDCRsEvent());
};

// SAGAS
export const createDCRSaga = (dispatch) => pipe(createDCRCommand, dispatch);
export const startProcessSaga = (dispatch) => pipe(startProcessCommand, dispatch);
export const requestOpenedWorkflowTaskSaga = (dispatch) => (taskId) =>
    pipe(
        requestWorkflowTaskCommand,
        dispatch,
        then(handleReceivedTasks(dispatch))
    )(taskId).catch(pipe(resetOpenedTaskCommand, dispatch));
export const requestWorkflowTasksSaga = (dispatch) =>
    pipe(requestWorkflowTasksCommand, dispatch, then(handleReceivedTasks(dispatch)));
export const getIsTenantRegisteredSaga = (dispatch) =>
    pipe(getIsTenantRegisteredCommand, dispatch, then(pipe(setIsTenantRegisteredEvent, dispatch)));
export const requestWorkflowTaskSaga = (dispatch) =>
    pipe(requestWorkflowTaskCommand, dispatch, then(pipe(workflowTaskUpdateEvent, dispatch)));
export const requestWorkflowTasksAndDCRsSaga = (dispatch) =>
    pipe(requestWorkflowTasksSaga(dispatch), then(requestWorkflowDCRsSaga(dispatch)));
export const requestWorkflowDCRsSaga = (dispatch) =>
    pipe(requestWorkflowDCRsCommand, dispatch, then(pipe(workflowDCRsReceivedEvent, dispatch)));
export const getTaskFieldModifier = (fieldName) => (field) => ({
    [fieldName]: field
});
export const changeTaskSagaBuilder = (successMessage, taskFieldModifier) => (dispatch) => (taskID, field) =>
    pipe(
        changeTaskCommand,
        dispatch,
        then(() => requestWorkflowTaskSaga(dispatch)(taskID)),
        then(
            pipe(
                always({
                    message: successMessage
                }),
                setOpenedSnackbarEvent,
                dispatch
            )
        )
    )(taskID, taskFieldModifier(field));
export const setTaskPrioritySaga = changeTaskSagaBuilder(
    i18n.text('Priority has been changed'),
    getTaskFieldModifier('priority')
);
export const setTaskDueDateSaga = changeTaskSagaBuilder(
    i18n.text('Due date has been changed'),
    getTaskFieldModifier('dueDate')
);
export const setTaskAssigneeSaga = changeTaskSagaBuilder(
    i18n.text('Assignee has been changed'),
    getTaskFieldModifier('assignee')
);
export const createTaskCommentSaga = changeTaskSagaBuilder(
    i18n.text('Comment was sent successfully'),
    getTaskFieldModifier('processInstanceComment')
);
export const requestTaskAssigneesSaga = (dispatch) =>
    pipe(requestTaskAssigneesCommand, dispatch, then(pipe(taskAssigneesReceivedEvent, dispatch)));
export const resetOpenedTaskSaga = (dispatch) => pipe(resetOpenedTaskCommand, dispatch);
export const executeActionSaga = (dispatch) => (action, taskID) =>
    pipe(
        deleteUnnecessaryDCRCommand,
        dispatch
    )(taskID).then(() =>
        pipe(
            executeActionCommand,
            dispatch,
            then(pipe(resetOpenedTaskCommand, dispatch)),
            then(() => pipe(workflowTaskResetEvent, dispatch)(taskID)),
            then(pipe(resetDependenciesEvent, dispatch)),
            then(
                pipe(
                    always({
                        message: i18n.text('Action has been started')
                    }),
                    setOpenedSnackbarEvent,
                    dispatch
                )
            )
        )(action, taskID)
    );
