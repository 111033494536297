/**
 * Created by ndyumin on 10.04.2017.
 */
import {TypesSearchQuery, ValuesSearchQuery} from '../../rdm-sdk/filters.types';
export const initialQuery: ValuesSearchQuery = {
    hasWords: null,
    hasMissingValues: false,
    value: {
        type: 'contains',
        negate: false,
        value: null
    },
    code: {
        type: 'equals',
        negate: false,
        value: null
    },
    sources: [],
    startDate: null,
    endDate: null,
    canonicalCode: {
        type: 'equals',
        negate: false,
        value: null
    },
    canonicalValue: {
        type: 'contains',
        negate: false,
        value: null
    },
    canonicalCodes: []
};
export const initialTypeQuery: TypesSearchQuery = {
    hasWords: null,
    name: {
        type: 'contains',
        negate: false,
        value: null
    },
    code: {
        type: 'contains',
        negate: false,
        value: null
    },
    generator: null,
    startDate: null,
    endDate: null
};
