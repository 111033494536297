import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
type Props = {
    active: boolean;
    rejected: boolean;
    onClick: () => void;
};

function RejectButton({active, rejected, onClick}: Props) {
    return (
        <div className={clsx(styles['reject-button'], active && styles['reject-button__active'])} onClick={onClick}>
            {rejected ? i18n.text('Unreject') : i18n.text('Reject')}
        </div>
    );
}

export default RejectButton;
