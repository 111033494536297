import TextField from '@mui/material/TextField';
import {InputBaseComponentProps} from '@mui/material/InputBase';
import {InputProps} from '@mui/material/Input';
import {ReactElement, Ref, forwardRef} from 'react';

const InputComponent = forwardRef((props: Omit<InputBaseComponentProps, 'align'>, ref: Ref<HTMLDivElement>) => {
    return <div ref={ref} {...props} />;
});

InputComponent.displayName = 'InputComponent';

type Props = {
    selectProps: {
        classes: {
            control: string;
        };
        TextFieldProps: {
            InputProps: InputProps;
            inputProps: InputBaseComponentProps;
        };
    };
    innerProps: InputBaseComponentProps;
    innerRef: Ref<HTMLElement>;
    children: ReactElement;
};

export default function Control(props: Props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps = {} as Props['selectProps']['TextFieldProps']}
    } = props;
    return (
        <TextField
            variant="standard"
            fullWidth
            {...TextFieldProps}
            InputProps={{
                inputComponent: InputComponent,
                ...TextFieldProps.InputProps,
                inputProps: {
                    className: classes.control,
                    ref: innerRef,
                    children,
                    ...innerProps,
                    ...TextFieldProps.inputProps
                }
            }}
        />
    );
}
