import CloseDialogButton from '../CloseDialogButton/CloseDialogButton';
import Dialog from '../Dialog/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import SearchWordsHighlighter from '../SearchWordsHighlighter/SearchWordsHighlighter';
import TextField from '../TextField/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import i18n from 'ui-i18n';
import styles from './mapping-dialog.less';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {DetailsOpen} from '../Mapping/mappingGrid.types';
import {Maybe} from 'monet';
import {ValuesSearchQuery} from '../../rdm-sdk/filters.types';
import {getSourceLabel} from '../../rdm-sdk/sources';
import {prop, safePath, safeProps} from '../../core/lenses';
const lenses = safeProps(
    'enabled',
    'entry',
    'canonical',
    'canonicalValue',
    'source',
    'code',
    'value',
    'description',
    'downStreamDefaultValue',
    'searchMapping'
);
export const getCheckbox = (safeValue, label, tooltip) =>
    safeValue
        .map((value, index) =>
            value ? (
                <div key={index} className={styles['checkbox-wrapper']}>
                    <Tooltip
                        title={tooltip}
                        placement="bottom-start"
                        PopperProps={{
                            modifiers: [
                                {
                                    name: 'preventOverflow',
                                    enabled: true,
                                    options: {
                                        altAxis: true,
                                        altBoundary: true,
                                        tether: true,
                                        rootBoundary: 'document',
                                        padding: 8
                                    }
                                }
                            ]
                        }}
                    >
                        <Typography variant="body2">
                            <Icon>done</Icon>
                            <span>{label}</span>
                        </Typography>
                    </Tooltip>
                </div>
            ) : (
                ''
            )
        )
        .orSome('');

const mappingLens = (source) => prop('entry').compose(prop('sources')).compose(prop(source));

const notEmptyString = (string) => string !== '';

type Props = {
    configuration: Configuration;
    details?: DetailsOpen;
    onClose: () => void;
    open: boolean;
    searchQuery: ValuesSearchQuery;
};

export class MappingDetailsModal extends Component<Props> {
    render() {
        const {configuration, details, onClose, open, searchQuery} = this.props;
        const safeDetails = Maybe.fromNull(details);
        const state = safeDetails.flatMap((details) => {
            return Maybe.fromNull(mappingLens(details?.source).get(details)).flatMap((mappings) =>
                Maybe.fromNull(mappings[details?.index ? details?.index : 0])
            );
        });
        const entry = lenses.entry.get(safeDetails);
        const abbr = lenses.source.get(safeDetails).orSome(null);
        const source = getSourceLabel(configuration, abbr);
        const canonical = lenses.canonical.get(entry).orSome('');
        const code = lenses.code.get(state).orSome('');
        const value = lenses.value.get(state).orSome('');
        const safeDescription = lenses.description.get(state);
        const safeIsCanonical = lenses.canonicalValue.get(state);
        const safeIsDownstream = lenses.downStreamDefaultValue.get(state);
        const searchMapping = lenses.searchMapping.get(safeDetails);
        const highlightText = safePath('sources', abbr, 'matchedSource').get(searchMapping).orSome(false);
        const description = safeDescription
            .filter(notEmptyString)
            .map((value, index) => (
                <TextField key={index} readOnly value={value} label={i18n.text('Source description')} fullWidth />
            ))
            .orSome('');
        const isCanonical = getCheckbox(
            safeIsCanonical,
            i18n.text('Assigned canonical value'),
            i18n.text('Canonical value for this row')
        );
        const isDownstream = getCheckbox(
            safeIsDownstream,
            i18n.text('Default source system value'),
            i18n.text('Default value used during reverse transcoding back to source system')
        );
        return (
            <Dialog className={styles['mapping-details']} open={open} onCancel={onClose}>
                <DialogTitle>
                    {source}: {i18n.text('Source value details')}
                </DialogTitle>

                <CloseDialogButton onClick={onClose} />

                <DialogContent className={styles['content']}>
                    <TextField readOnly value={canonical} label={i18n.text('Canonical value')} />

                    <div className={styles['mapping-details__code-value']}>
                        <div className={`${styles['mapping-details__source-value']} ${styles.detailsValue}`}>
                            <label className={styles.detailsValueLabel}>{i18n.text('Source value')}</label>
                            <SearchWordsHighlighter
                                value={value}
                                searchQuery={searchQuery}
                                searchFields={['value', 'hasWords']}
                                enabled={highlightText}
                            />
                        </div>

                        <div className={`${styles['mapping-details__source-code']} ${styles.detailsValue}`}>
                            <label className={styles.detailsValueLabel}>{i18n.text('Source code')}</label>
                            <SearchWordsHighlighter
                                value={code}
                                searchQuery={searchQuery}
                                searchFields={['code', 'hasWords']}
                                enabled={highlightText}
                            />
                        </div>
                    </div>

                    {description}
                    {isCanonical}
                    {isDownstream}
                </DialogContent>
            </Dialog>
        );
    }
}
