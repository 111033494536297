import * as R from 'ramda';
import SelectLookupTypeDialog from '../SelectLookupTypeDialog/SelectLookupTypeDialog';
import TargetSelector from 'react-components/dist/DataMapping/TargetSelector';
import i18n from 'ui-i18n';
import {Fragment, PureComponent} from 'react';
import {LookupType} from '../../../rdm-sdk/types.types';
import {withImportWizardContext} from '../ImportWizardContext';
type Props = {
    onTypeSelect: (arg0: LookupType) => void;
    selectedLookupType?: LookupType | null;
};
type State = {
    open: boolean;
};
export class SelectLookupTypeButton extends PureComponent<Props, State> {
    state = {
        open: !this.props.selectedLookupType
    };
    handleOpen = () =>
        this.setState({
            open: true
        });
    handleClose = () =>
        this.setState({
            open: false
        });

    render() {
        const {selectedLookupType} = this.props;
        return (
            <Fragment>
                <TargetSelector
                    onClick={this.handleOpen}
                    label={selectedLookupType ? selectedLookupType.label : i18n.text('Select type')}
                    tooltip={i18n.text('Select type')}
                />
                <SelectLookupTypeDialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    onTypeSelect={this.props.onTypeSelect}
                />
            </Fragment>
        );
    }
}
const mapContextToProps = R.pick(['onTypeSelect', 'selectedLookupType']);
export default withImportWizardContext(mapContextToProps, SelectLookupTypeButton);
