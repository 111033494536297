import ValuesPage from './ValuesPage';
import ValuesPageNewTypeMock from './ValuesPageNewTypeMock';
type Props = {
    location: Location;
    params: {
        tab: string;
        type: string;
        tenantId: string;
    };
};

const ValuesPageContainer = (props: Props) => {
    const {
        location: {pathname, search},
        params: {tenantId}
    } = props;
    return pathname === `/${tenantId}/types/NEW_LOOKUP_TYPE/mapping` && search === '?mode=new_type' ? (
        <ValuesPageNewTypeMock {...props} />
    ) : (
        <ValuesPage {...props} />
    );
};

export default ValuesPageContainer;
