/**
 * Created by ndyumin on 15.02.2017.
 */
import * as R from 'ramda';
import Papa from 'papaparse';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Maybe} from 'monet';
import {SortDirection} from '../rdm-sdk/app.types';
import {fromDate, fromNotZeroNumber, fromNull} from './maybe';
import {inArray} from './lenses';
export const filterFields =
    (...fields) =>
    (obj) =>
        fields.reduce(
            (o, field) =>
                Object.assign(o, {
                    [field]: obj[field]
                }),
            {}
        );
export const deepEquals = (o1, o2) => JSON.stringify(o1) === JSON.stringify(o2);
export const later =
    (fn) =>
    (...args) =>
        setTimeout(fn, 1, ...args);
export const checkRange = (startDate, endDate) => !(startDate && endDate) || startDate < endDate;
export const clone = R.compose(JSON.parse, JSON.stringify);
export const trim = (value) => value.replace(/\s+/g, ' ').trim();
export const escapeDoubleQuotes = (value) => value.replace(/"/g, '\\"');
export const leftJoin = R.curry((xs, ys) => xs.concat(ys.filter((y) => !~xs.indexOf(y))));
export const leftJoinByProp = (prop) =>
    R.curry((xs, ys) => xs.concat(ys.filter((y) => !inArray(prop, y[prop]).get(xs))));
export const isUriUnique = (uri, objects) => !objects.find((obj) => obj.uri === uri);
export const splitToWords = (str) => fromNull(str.match(/(\S)+/g)).orSome([]);
export const sortBy = R.curry(function sortBy(field, order, data) {
    data = data.slice();
    if (field == null || order == null) return data;
    data.sort((a, b) => {
        if (a[field] === undefined) return 1;
        if (b[field] === undefined) return -1;
        if (a[field] > b[field]) return 1;
        else return -1;
    });
    return order === SortDirection.DESC ? data.reverse() : data;
});
export const toggleOrder = (order) =>
    fromNull(order)
        .map((order) => (order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC))
        .orSome(SortDirection.ASC);
export const getChecked = (e) => e.target.checked;
export const getValue = (e) => e.target.value;
export const noop = () => {};
export const fieldUpdateHandler = (handler, obj) => (field) => (update) =>
    handler(
        Object.assign({}, obj, {
            [field]: update
        })
    );
export const isExceptionRoute = (path, custom = []) => {
    if (typeof path === 'object') {
        path = fromNull(path.pathname).orSome('');
    }

    const common = ['/login/'];
    return [...common, ...custom].some((str) => path.includes(str));
};
export const changeOrder = R.curry((fromIdx, toIdx, array) => {
    if (!array.length) return array;
    const elem = array[fromIdx];
    return R.pipe(R.remove(fromIdx, 1), R.insert(toIdx, elem))(array);
});
export const escapeRegExpSymbols = (word) => word.replace(/[.$^{[(|)*+?\\]/g, '\\$&');

const getRegExp = (word) => new RegExp(`^${escapeRegExpSymbols(word)}$`, 'i');

export const contains = R.curry((text, word) => splitToWords(text).some(R.test(getRegExp(word))));
export const startsWith = R.curry((text, str) => new RegExp(`^${str}`, 'i').test(text));
export const pluralize = (label, count) => `${count} ${label}${count === 1 ? '' : 's'}`;
export const capitalize = R.unless(R.isEmpty, R.pipe(R.toLower, R.adjust(0, R.toUpper), R.join('')));
export const isIE = () => !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);

const getParent = (style: CSSStyleDeclaration, parent: Node, position: string) => {
    const _style = style;
    const overflow = _style.overflow;
    const overflowX = _style.overflowX;
    const overflowY = _style.overflowY;
    if (/(auto|scroll|overlay)/.test(overflow + overflowY + overflowX)) {
        if (position !== 'absolute' || ['relative', 'absolute', 'fixed'].indexOf(style.position) >= 0) {
            return parent;
        }
    }
    return null;
};

export function getScrollParents(el: Element) {
    // In firefox if the el is inside an iframe with display: none; window.getComputedStyle() will return null;
    // https://bugzilla.mozilla.org/show_bug.cgi?id=548397
    const computedStyle = getComputedStyle(el) || {};
    const position = computedStyle.position;
    const parents: Node[] = [];

    if (position === 'fixed') {
        return [el];
    }

    let parent: ParentNode | null = el.parentNode;

    while (parent && parent.nodeType === 1) {
        let style = {} as CSSStyleDeclaration;

        try {
            style = getComputedStyle(parent as Element);
        } catch (err) {
            // do nothing;
        }

        if (isEmpty(style) || style === null) {
            parents.push(parent);
            return parents;
        }

        const newParent = getParent(style, parent, position);
        if (newParent) {
            parents.push(newParent);
        }

        parent = parent.parentNode;
    }

    parents.push(el.ownerDocument.body);

    // If the node is within a frame, account for the parent window scroll
    if (el.ownerDocument !== document) {
        // @ts-ignore
        parents.push(el.ownerDocument.defaultView);
    }

    return parents;
}

const toDate = (unix) => new Date(unix);

export const unixToDate = (unix: Date | number | null) => {
    // @ts-ignore
    const safeDate: Maybe<Date | null> = fromNotZeroNumber(unix).map(toDate);
    return safeDate.orSome(null);
};
export const dateToUnix = (date) => fromDate(date).map(Number).orSome(0);
export const toObservable = (store) => {
    const initial = store.getState();
    const subj = new BehaviorSubject(initial);
    store.subscribe(R.compose(subj.next.bind(subj), store.getState));
    return subj;
};
export const createRxStore = (reducer, initial) => {
    const updater$ = new Subject();
    const store$ = new BehaviorSubject(initial);
    const storeSub = updater$.scan(reducer, store$.getValue()).subscribe(store$.next.bind(store$));
    return {
        store$: store$.asObservable(),
        updater$,
        getState: store$.getValue.bind(store$),
        dispose: storeSub.unsubscribe.bind(storeSub)
    };
};
export const inRange = (start, stop, num) => start <= num && stop >= num;
export const logError = (label) => console.error.bind(console, label);
export const mapObjKeys = R.curry((fn, obj) => R.pipe(R.toPairs, R.map(R.adjust(0, fn)), R.fromPairs)(obj));
export const createCsvFileObservable = (file) =>
    Observable.create((observer) => {
        let unsubscribed = false;
        Papa.parse(file, {
            skipEmptyLines: true,

            step({errors, data}, parser) {
                if (unsubscribed) return parser.abort();
                if (errors.length) return observer.error(errors);
                observer.next([data]);
            },

            complete() {
                observer.complete();
            }
        });
        return () => (unsubscribed = true);
    });
export const previewCsvFile = (file, lines = 20) =>
    createCsvFileObservable(file).scan(R.concat, []).take(lines).toPromise();
export const getUrlTenant = () => window.location.hash.split('/')[1];
export const isEmpty = R.either(R.isNil, R.isEmpty);
export const isNotEmpty = R.complement(isEmpty);
export function convertObservableToBehaviorSubject(observable, initValue) {
    const subject = new BehaviorSubject(initValue);
    observable.subscribe(
        (x) => subject.next(x),
        (err) => subject.error(err),
        () => subject.complete()
    );
    return subject;
}
export const toNegativeOrZero = R.clamp(-Infinity, 0);
export const toPositiveOrZero = R.clamp(0, Infinity);
export const getTranslate = (container, element) => {
    if (!container || !element)
        return {
            x: 0,
            y: 0
        };
    const dialogRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    return {
        left: toPositiveOrZero(containerRect.x - dialogRect.x),
        top: toPositiveOrZero(containerRect.y - dialogRect.y),
        right: toNegativeOrZero(containerRect.width + containerRect.x - (dialogRect.x + dialogRect.width)),
        bottom: toNegativeOrZero(containerRect.height + containerRect.y - (dialogRect.y + dialogRect.height))
    };
};
export const getClosestScrollContainer = (element: Element) =>
    // @ts-ignore
    Maybe.fromNull(element).map(getScrollParents).map(R.head).orSome(null) as Element;
export const getElementRect = (element) => element.getBoundingClientRect();
export async function sequentialPromiseAll<T>(promisesF: (() => Promise<T>)[]) {
    const results = [] as T[];

    for (const promiseF of promisesF) {
        const result: T = await promiseF();
        results.push(result);
    }

    return Promise.resolve(results);
}
