import Checkbox from '@mui/material/Checkbox';
import MenuItem, {MenuItemProps} from '@mui/material/MenuItem';
import clsx from 'clsx';
import {ReactElement} from 'react';

type Props = {
    selectProps: {
        classes: {
            option: string;
        };
    };
    innerProps: MenuItemProps;
    innerRef: MenuItemProps['ref'];
    isFocused: boolean;
    isSelected: boolean;
    children: ReactElement;
};

export default function Option(props: Props) {
    const {
        innerProps,
        innerRef,
        children,
        isFocused,
        isSelected,
        selectProps: {classes}
    } = props;
    return (
        <MenuItem
            className={clsx(classes.option, isSelected && classes['option--selected'])}
            ref={innerRef}
            selected={isFocused}
            // @ts-ignore
            component="div"
            {...innerProps}
        >
            <Checkbox color="primary" checked={isSelected} />
            {children}
        </MenuItem>
    );
}
