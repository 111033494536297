import styles from './styles.less';
import {PureComponent} from 'react';
import {UnmappedItem} from '../../rdm-sdk/unmapped.types';
type Props = {
    selectedItems: UnmappedItem[];
};

class UnmappedDragPreview extends PureComponent<Props> {
    render() {
        const {selectedItems} = this.props;
        const firstSelected = selectedItems[0] || {};
        return (
            <div className={styles['unmapped-table__drag-preview']}>
                <div className={`${styles['unmapped-table__tile']} ${styles['unmapped-table__tile--selected']}`}>
                    <div className={styles['unmapped-table__code-value-container']}>
                        <div className={styles['unmapped-table__tile-value']}>{firstSelected.value}</div>
                        <div className={styles['unmapped-table__tile-code']}>{firstSelected.code}</div>
                    </div>
                </div>
                {selectedItems.length > 1 && (
                    <div>
                        <div className={styles['unmapped-table__stacked-container']} />
                        <div className={styles['unmapped-table__stacked-container']} />
                        <div className={styles['unmapped-table__select-count']}>+{selectedItems.length - 1}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default UnmappedDragPreview;
