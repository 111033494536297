import * as R from 'ramda';
import ExtraItemsList from './ExtraItemsList';
import Icon from '@mui/material/Icon';
import TetherComponent from 'react-tether';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './add-grid-column-menu.less';
import {ColumnItem} from '../../rdm-sdk/grid.types';
import {Component, RefObject} from 'react';
type Props = {
    style: Record<string, any>;
    items: ColumnItem[];
    onClick: (item: ColumnItem) => void;
};
type State = {
    isOpen: boolean;
};
export default class AddGridColumnMenu extends Component<Props, State> {
    state = {
        isOpen: false
    };
    toggleOpen = () =>
        this.setState(({isOpen}) => ({
            isOpen: !isOpen
        }));

    render() {
        const {style, items, onClick} = this.props;
        const {isOpen} = this.state;
        return (
            <TetherComponent
                attachment="top left"
                targetAttachment="top left"
                constraints={[
                    {
                        to: 'scrollParent',
                        pin: true
                    }
                ]}
                renderTarget={(ref) => (
                    <Tooltip title={i18n.text('Add column')}>
                        <button
                            ref={ref as RefObject<HTMLButtonElement>}
                            className={styles['extra-items-list__button']}
                            style={style}
                            onClick={this.toggleOpen}
                        >
                            <Icon>add</Icon>
                        </button>
                    </Tooltip>
                )}
                renderElement={(ref) =>
                    isOpen && (
                        <ExtraItemsList
                            ref={ref as any}
                            items={items}
                            onClickOutside={this.toggleOpen}
                            onClick={R.pipe(onClick, this.toggleOpen)}
                        />
                    )
                }
            />
        );
    }
}
