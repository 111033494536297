import i18n from 'ui-i18n';
import moment from 'moment';
const DAY_MS = 86400000;
export const MAX_MINUTES_LABEL = 30;
export const minutesDiff = (ts1, ts2) => Math.floor((ts1 - ts2) / (1000 * 60));
export const getDateLabel = (timestamp) => {
    const momentDate = moment.unix(timestamp / 1000);
    const isToday = new Date(timestamp).toLocaleDateString() === new Date().toLocaleDateString();
    const isYesterday = new Date(timestamp + DAY_MS).toLocaleDateString() === new Date().toLocaleDateString();

    if (isToday) {
        return i18n.text('Today');
    } else if (isYesterday) {
        return i18n.text('Yesterday');
    } else {
        return momentDate.format('MMM DD');
    }
};

const getTimeLabelSimple = (timestamp) => moment.unix(timestamp / 1000).format('LT');

export const getTimeLabel = (timestamp) => {
    const minAgo = minutesDiff(Date.now(), timestamp);

    if (minAgo === 0) {
        return i18n.text('now');
    } else if (minAgo < MAX_MINUTES_LABEL) {
        return `${minAgo} ${i18n.text('min ago')}`;
    } else {
        return getTimeLabelSimple(timestamp);
    }
};
export const getDateTimeLabel = (timestamp) => `${getDateLabel(timestamp)} at ${getTimeLabelSimple(timestamp)}`;
export const isOverDueDate = (date) => moment().startOf('d').isAfter(moment(date));
