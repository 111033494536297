import * as R from 'ramda';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Resizable from 're-resizable';
import UnmappedSearchBar from '../UnmappedSearch/UnmappedSearchBar';
import UnmappedTable from '../UnmappedTable/UnmappedTable';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import initialState from '../../redux/initialState';
import noUnmappedValuesIcon from '../../assets/inline/no-unmapped-values.svg';
import styles from './styles.less';
import {Component} from 'react';
import {EDIT_MODE} from '../../constants/common';
import {Policies} from '../../permissions/permissions.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {Unmapped, UnmappedValue} from '../../rdm-sdk/unmapped.types';
import {connect} from 'react-redux';
import {getUnmappedTotal} from '../../rdm-sdk/unmapped';
import {loadItemsOnDrop} from '../Mapping/dropHelpers';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {unmappedSelectedItemsClearEvent} from '../../redux/actions/unmapped';
const NO_UNMAPPED_VALUES_TEXT = i18n.text("Hooray! You don't have any unmapped values");
const UNMAPPED_INFO = i18n.text(
    'Drag and drop unmapped values to canonical value rows to map to existing values or to create a new one.'
);
export const NoUnmappedValues = () => (
    <div className={styles['no-unmapped-values']}>
        <div
            className={styles['no-unmapped-values__icon']}
            dangerouslySetInnerHTML={{
                __html: noUnmappedValuesIcon
            }}
        />
        <div className={styles['no-unmapped-values__text']}>{NO_UNMAPPED_VALUES_TEXT}</div>
    </div>
);

type StateProps = {
    unmapped: Unmapped;
    policies: Policies;
    editMode: EDIT_MODE;
};

type Props = StateProps & {
    dispatch: (e: StateEvent) => void;
    setIsDragging: (isDragging: boolean) => void;
    loadUnmapped: () => void;
    loadMoreUnmapped: (unmappedValue: UnmappedValue, onLoaded?: () => void) => void;
    isUnmappedDragging: boolean;
    onClick: () => void;
    onClose: () => void;
};
type State = {
    width?: number | null;
    searchQuery?: string | null;
};
const logger = logActivityCommand('unmapped-panel');
const searchLogger = logger('search');
export class UnmappedPanel extends Component<Props, State> {
    static defaultProps = {
        unmapped: initialState.unmapped as Unmapped
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            width: null,
            searchQuery: props.unmapped.unmappedSearchQuery
        };
    }

    onSearch = () => {
        const {
            dispatch,
            unmapped: {unmappedSearchQuery, removedItems, unmappedValues},
            loadMoreUnmapped
        } = this.props;
        this.setState({
            searchQuery: unmappedSearchQuery
        });
        const getFlatItems = R.pipe(R.values, R.flatten);
        loadItemsOnDrop(getFlatItems(removedItems), unmappedValues, loadMoreUnmapped);
        dispatch(unmappedSelectedItemsClearEvent());
        dispatch(searchLogger(unmappedSearchQuery) as StateEvent);
    };

    render() {
        const {
            dispatch,
            unmapped,
            unmapped: {unmappedSearchQuery, selectedItems, loadingTotals, loadingUnmapped},
            setIsDragging,
            isUnmappedDragging,
            loadUnmapped,
            loadMoreUnmapped,
            onClick,
            onClose,
            policies,
            editMode
        } = this.props;
        const {searchQuery, width} = this.state;
        const total = getUnmappedTotal(unmapped, editMode);
        const hasUnmappedItems = total > 0;
        const isUnmappedLoaded = !loadingTotals && !loadingUnmapped;
        const selectMode = selectedItems.length > 0;

        const getTotalCounter = (total) =>
            total > 1
                ? i18n.text('${count} items', {
                      count: total
                  })
                : i18n.text('${count} item', {
                      count: total
                  });
        return (
            <Resizable
                className={styles['unmapped']}
                size={{
                    width: width || '100%'
                }}
                onResizeStop={(e, direction, ref) => {
                    this.setState({
                        width: ref.offsetWidth
                    });
                }}
                enable={{
                    top: false,
                    right: false,
                    bottom: false,
                    left: true,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false
                }}
                onClick={onClick}
            >
                <div className={clsx(styles['unmapped__header'], selectMode && styles['unmapped__header_select-mode'])}>
                    <div className={styles['unmapped__header-line']}>
                        <IconButton className={styles['unmapped__close']} onClick={onClose} size="large">
                            <Icon>close</Icon>
                        </IconButton>
                        <div className={styles['unmapped__title']}>{i18n.text('Unmapped values')}</div>

                        {selectMode ? (
                            <div
                                className={styles['unmapped__unselect']}
                                onClick={() => dispatch(unmappedSelectedItemsClearEvent())}
                            >
                                {selectedItems.length > 1
                                    ? i18n.text('Unselect ${count} values', {
                                          count: selectedItems.length
                                      })
                                    : i18n.text('Unselect ${count} value', {
                                          count: selectedItems.length
                                      })}
                            </div>
                        ) : (
                            <div className={styles['unmapped__total']}>
                                {total ? getTotalCounter(total) : i18n.text('no items')}
                            </div>
                        )}
                    </div>

                    {hasUnmappedItems && (
                        <UnmappedSearchBar
                            dispatch={dispatch}
                            unmappedSearchQuery={unmappedSearchQuery}
                            disabled={!isUnmappedLoaded}
                            onSearch={this.onSearch}
                        />
                    )}

                    {hasUnmappedItems && policies.lookupValuesEdit && (
                        <p className={styles['unmapped__info']}>{UNMAPPED_INFO}</p>
                    )}

                    {hasUnmappedItems && <div className={styles['unmapped__table-header-overlay']} />}
                </div>

                {isUnmappedLoaded ? !hasUnmappedItems && <NoUnmappedValues /> : <LinearProgress />}

                {hasUnmappedItems && (
                    <UnmappedTable
                        logger={logger}
                        className={styles['unmapped__content']}
                        searchQuery={searchQuery}
                        setIsDragging={setIsDragging}
                        isUnmappedDragging={isUnmappedDragging}
                        loadUnmapped={loadUnmapped}
                        loadMoreUnmapped={loadMoreUnmapped}
                    />
                )}
            </Resizable>
        );
    }
}

const mapStateToProps: (state) => StateProps = R.pick(['editMode', 'unmapped', 'policies']);
export default connect(mapStateToProps)(UnmappedPanel);
