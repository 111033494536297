import * as R from 'ramda';
import {ColumnItem, HiddenColumnsRecord, MappingColumnItem, MappingIdentifier} from './grid.types';
import {LookupValue, SourceMapping} from './lookups.types';
import {REGULAR_COLUMN_WIDTH} from '../components/Localization/Localization';
import {fromNull} from '../core/maybe';
import {orSome} from '../core/monet';
export const createHiddenColumnRecord = (item: ColumnItem, index: number): HiddenColumnsRecord => ({
    index,
    items: [item]
});
export const mergeHiddenByIndex = R.pipe(
    R.reduceBy(
        (acc, item) =>
            R.evolve(
                {
                    items: R.concat(R.__, item.items),
                    index: R.always(item.index)
                },
                acc
            ),
        {
            items: [],
            index: null
        },
        R.prop('index')
    ),
    R.values
);
export const updateHiddenColumns = R.curry(
    (incrementCount: number, actionIndex: number, hiddenColumns: HiddenColumnsRecord[]): HiddenColumnsRecord[] =>
        R.map(
            R.evolve({
                index: R.when(R.lt(actionIndex), R.add(incrementCount))
            }),
            hiddenColumns
        )
);
export const appendHiddenColumn = R.curry(
    (record: HiddenColumnsRecord, hiddenColumns: HiddenColumnsRecord[]): HiddenColumnsRecord[] => {
        const recordIndex = R.findIndex(R.propEq('index', record.index), hiddenColumns);
        return recordIndex > -1
            ? R.adjust(
                  recordIndex,
                  R.evolve({
                      items: R.concat(R.__, record.items)
                  }),
                  hiddenColumns
              )
            : R.concat(hiddenColumns, [record]);
    }
);
export const mergeHiddenColumns = R.reduce(R.flip(appendHiddenColumn), []);
export const addHiddenColumn = (
    item: ColumnItem,
    index: number,
    hiddenColumns: HiddenColumnsRecord[]
): HiddenColumnsRecord[] => {
    return R.pipe(
        appendHiddenColumn(createHiddenColumnRecord(item, index)),
        updateHiddenColumns(-1, index),
        mergeHiddenColumns
    )(hiddenColumns);
};
export const updateHiddenColumnsOnDnD = R.curry(
    (dragFrom: number, dragTo: number, hiddenColumns: HiddenColumnsRecord[]): HiddenColumnsRecord[] => {
        return R.pipe(
            R.map(
                R.evolve({
                    index: (hiddenIndex) => {
                        if (dragFrom < hiddenIndex && hiddenIndex <= dragTo) {
                            return hiddenIndex - 1;
                        } else if (dragFrom >= hiddenIndex && hiddenIndex > dragTo) {
                            return hiddenIndex + 1;
                        } else {
                            return hiddenIndex;
                        }
                    }
                })
            ),
            mergeHiddenColumns
        )(hiddenColumns);
    }
);
export const removeHiddenColumn = R.curry((index: number, hiddenColumns: HiddenColumnsRecord[]) => {
    const removedItems = R.pipe(
        R.find(R.propEq('index', index)),
        fromNull,
        R.pluck('items'),
        orSome([])
    )(hiddenColumns);
    return R.pipe(R.reject(R.propEq('index', index)), updateHiddenColumns(removedItems.length, index))(hiddenColumns);
});
export const moveScrollOnColumnAdd = (gridRef: Record<string, any> | undefined, index: number) => {
    const scrollContainer = gridRef?._bottomRightGrid._scrollingContainer;

    if (index === -1) {
        // scroll until add button is visible
        scrollContainer.scrollLeft = scrollContainer.scrollWidth;
    } else {
        // scroll to the left or to the right on column insert
        const width = parseInt(scrollContainer.style.width, 10);
        const columnStart = index * REGULAR_COLUMN_WIDTH;
        const columnEnd = columnStart + REGULAR_COLUMN_WIDTH;
        const viewStart = scrollContainer.scrollLeft;
        const viewEnd = scrollContainer.scrollLeft + width;

        if (columnStart < viewStart) {
            scrollContainer.scrollLeft -= viewStart - columnStart;
        } else if (columnEnd > viewEnd) {
            scrollContainer.scrollLeft += columnEnd - viewEnd;
        }
    }
};
export const getMappingIdentifier = (code: string, source: string, mappingIndex: number): MappingIdentifier => ({
    code,
    source,
    mappingIndex
});
export const getMappingByIdentifier = (
    identifier: MappingIdentifier,
    lookupValues: LookupValue[]
): SourceMapping | null =>
    R.pipe(
        R.find(R.propEq('code', identifier.code)),
        R.pathOr(null, ['sources', identifier.source, identifier.mappingIndex])
    )(lookupValues);
export const getNextSourceAbbreviation = (abbreviation: string, sources: MappingColumnItem[]): string => {
    const index = R.findIndex(R.propEq('abbreviation', abbreviation), sources);
    return sources[index + 1] && index > -1 ? sources[index + 1].abbreviation : abbreviation;
};
export const getPrevSourceAbbreviation = (abbreviation: string, sources: MappingColumnItem[]): string => {
    const index = R.findIndex(R.propEq('abbreviation', abbreviation), sources);
    return sources[index - 1] && index > -1 ? sources[index - 1].abbreviation : abbreviation;
};
