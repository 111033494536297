import * as R from 'ramda';
import {createRxStore} from '../core/util';
export const REQUEST_THRESHOLD = 6;
export enum PRIORITY {
    HIGH = 10,
    MEDIUM = 5,
    LOW = 0
}
const byPriority = R.descend(R.prop('priority'));
const {updater$, store$} = createRxStore(R.flip(R.evolve), {
    queue: [],
    count: 0
});
store$
    .filter(({count, queue}) => REQUEST_THRESHOLD > count && queue.length)
    .subscribe(({queue}) => {
        R.head(queue)
            .makeRequest()
            .finally(() =>
                updater$.next({
                    count: R.dec
                })
            );
        updater$.next({
            queue: R.tail,
            count: R.inc
        });
    });
export const enqueue = (request, priority) =>
    new Promise((resolve, reject) => {
        const task = {
            makeRequest: () => request().then(resolve, reject),
            priority
        };
        updater$.next({
            queue: R.pipe(R.append(task), R.sort(byPriority))
        });
    });
