import * as R from 'ramda';
import ActivityChanges from '../../parts/ActivityChanges/ActivityChanges';
import Change from '../../Change/Change';
import ChangesTitle from '../../parts/ChangesTitle/ChangesTitle';
import NestedChange from '../../NestedChange/NestedChange';
import i18n from 'ui-i18n';
import {ACTIVITY_TYPE} from '../../constants';
import {ActivityTypeAttributeItem} from '../../activityLog.types';
import {formatBool, getDelta} from '../../../../rdm-sdk/activities';

const getChanges = (newValue, oldValue) =>
    getDelta(newValue, oldValue).map((delta) => {
        const change = {
            ...delta,
            displayName: delta.name,
            type: delta.type === ACTIVITY_TYPE.ADD ? null : delta.type
        };

        switch (change.name) {
            case 'name':
                return {...change, displayName: i18n.text('Attribute name')};

            case 'type':
                return {...change, displayName: i18n.text('Data type')};

            case 'required':
                return {
                    ...change,
                    displayName: i18n.text('Required'),
                    newValue: R.is(Boolean, change.newValue) ? formatBool(change.newValue) : undefined,
                    oldValue: R.is(Boolean, change.oldValue) ? formatBool(change.oldValue) : undefined
                };

            case 'visible':
                return {
                    ...change,
                    displayName: i18n.text('Hide'),
                    newValue: R.is(Boolean, change.newValue) ? formatBool(!change.newValue) : undefined,
                    oldValue: R.is(Boolean, change.oldValue) ? formatBool(!change.oldValue) : undefined
                };
        }

        return change;
    });

type Props = {
    items: ActivityTypeAttributeItem[];
};

function TypeAttributes(props: Props) {
    const {items} = props;
    return (
        <ActivityChanges>
            <ChangesTitle>{i18n.text('Custom attributes')}</ChangesTitle>
            {items.map((item) =>
                item.type === ACTIVITY_TYPE.DELETE ? (
                    <Change key={item.name} displayName={item.name} type={item.type} />
                ) : (
                    <NestedChange
                        key={item.name}
                        header={
                            <Change
                                displayName={item.name}
                                type={item.type === ACTIVITY_TYPE.ADD ? ACTIVITY_TYPE.ADD : null}
                            />
                        }
                        changes={getChanges(item.newValue, item.oldValue)}
                    />
                )
            )}
        </ActivityChanges>
    );
}

export default TypeAttributes;
