import clsx from 'clsx';
import styles from './comment-triangle.less';
type Props = {
    className?: string;
};

const CommentTriangle = ({className}: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className={clsx(styles['comment-triangle'], className)}
        preserveAspectRatio="xMinYMid meet"
        width={10}
        height={10}
    >
        <polygon points="0,0 10,0 10,10" />
    </svg>
);

export default CommentTriangle;
