/**
 * Created by ndyumin on 13.10.2016.
 */
import * as R from 'ramda';
import {Maybe} from 'monet';
export const isDefined = (value) => typeof value !== 'undefined';
export const isNotNull = (value) => value != null;
export const isString = (value) => typeof value === 'string';
export const isNumber = (value) => typeof value === 'number' && !isNaN(value);
export const isBoolean = (value) => typeof value === 'boolean';
export const isObject = (value) => typeof value === 'object' && value !== null;
export const isDate = (value) => value instanceof Date;
export const isArray = (value) => Array.isArray(value);
export const notEmpty = (value) => isNotNull(value) && !R.isEmpty(value);
export const notZeroNumber = (num) => isNumber(num) && num !== 0;
export const custom = (predicate) => (value) => (predicate(value) ? Maybe.Some(value) : Maybe.None());
export const fromDefined = custom(isDefined);
export const fromNull = custom(isNotNull);
export const fromString = custom(isString);
export const fromBoolean = custom(isBoolean);
export const fromNumber = custom(isNumber);
export const fromObject = custom(isObject);
export const fromArray = custom(isArray);
export const fromDate = custom(isDate);
export const fromNotEmpty = custom(notEmpty);
export const fromNotZeroNumber = custom(notZeroNumber);
export function get(field) {
    return (obj) => obj[field];
}
export function doIfSome(maybe, fn) {
    if (maybe.isSome()) {
        fn(maybe.some());
    }
}
