import {logActivityCommand} from '../../redux/actions/activityLogging';
export const logger = logActivityCommand('activity-log');
export const openClickLogger = logger('open-click');
export const closeClickLogger = logger('close-click');
export const paginationChangeLogger = logger('pagination-change');
export const searchApplyLogger = logger('search-apply');
export const filterClearLogger = logger('filter-clear');
export const filterCancelLogger = logger('filter-cancel');
export const filterClickLogger = logger('filter-click');
export const linkClickLogger = logger('link-click');
