import * as R from 'ramda';
import MultiSelect from '../../../ReactSelect/MultiSelect';
import i18n from 'ui-i18n';
import {LookupValue} from '../../../../rdm-sdk/lookups.types';
import {Observable, Subject} from 'rxjs';
import {SelectOption} from '../../activityLog.types';
import {connect} from 'react-redux';
import {getActivityLogFilterLookupValues} from '../../../../redux/reducers/activityLogReducer';
import {parseLookupValue} from '../../../../rdm-sdk/lookups';
import {requestAllValuesCommand} from '../../../../redux/actions/values';
import {setActivityLogFilterLookupValuesEvent} from '../../../../redux/actions/activityLog';
import {useEffect, useRef, useState} from 'react';

export const LOOKUP_VALUES_LIMIT = 30;
export const DEBOUNCE_INTERVAL = 400;

const toSelectOption = (lookup) =>
    ({
        label: lookup.canonical,
        value: lookup.code
    }) as SelectOption;

const toLookupValuesDefaultOptions: (lookupValues: Record<string, any>[]) => SelectOption[] = R.map(toSelectOption);
const toLookupValuesOptions: (lookupValues: Record<string, any>[]) => SelectOption[] = R.map(
    R.pipe(parseLookupValue, toSelectOption)
);

type StateProps = {
    defaultOptions: SelectOption[];
    values: SelectOption[];
    currentType: string;
};

type DispatchProps = {
    onChange: (values: SelectOption[]) => void;
    requestAllValues: (
        type: string | null | undefined,
        value: string | null | undefined,
        options: Record<string, any>
    ) => Promise<LookupValue[]>;
};

type Props = StateProps & DispatchProps;
export function LookupValuesSelect(props: Props) {
    const {defaultOptions, values, onChange, currentType, requestAllValues} = props;
    const [options, setOptions] = useState(defaultOptions);
    const [isLoading, setIsLoading] = useState(false);

    const inputStream$ = useRef(new Subject());
    const inputValueCache = useRef(new Map());
    useEffect(() => {
        let currInputValue: string | null = null;
        const subscription = inputStream$.current
            .debounceTime(DEBOUNCE_INTERVAL)
            .do((inputValue) => {
                currInputValue = inputValue as string;
                setIsLoading(true);
            })
            .switchMap((inputValue) =>
                Observable.fromPromise(
                    requestAllValues(currentType, inputValue as string, {
                        limit: LOOKUP_VALUES_LIMIT
                    })
                )
            )
            .map(toLookupValuesOptions)
            .subscribe((options) => {
                setOptions(options);
                inputValueCache.current.set(currInputValue, options);
                setIsLoading(false);
            });
        return () => subscription.unsubscribe();
    }, []);

    function handleInputChange(inputValue: string, {action}: {action: string}) {
        if (action === 'set-value') return;

        if (!inputValue) {
            setOptions(defaultOptions);
        } else if (inputValueCache.current.has(inputValue)) {
            setOptions(inputValueCache.current.get(inputValue));
        } else {
            inputStream$.current.next(inputValue);
        }
    }

    return (
        <MultiSelect
            creatable
            label={i18n.text('Canonical value')}
            options={options}
            value={values}
            onChange={onChange}
            onInputChange={handleInputChange}
            isLoading={isLoading}
            filterOptions={R.T}
            isValidNewOption={R.T}
        />
    );
}

const mapStateToProps = (state) =>
    ({
        defaultOptions: toLookupValuesDefaultOptions(state.lookupValues),
        currentType: state.currentType,
        values: getActivityLogFilterLookupValues(state)
    }) as StateProps;

const mapDispatchToProps = (dispatch) =>
    ({
        requestAllValues: (type, value, options) => dispatch(requestAllValuesCommand(type, value, options)),
        onChange: (props) => dispatch(setActivityLogFilterLookupValuesEvent(props))
    }) as DispatchProps;
export default connect(mapStateToProps, mapDispatchToProps)(LookupValuesSelect);
