/**
 * Created by ndyumin on 02.11.2016.
 */
export const compose =
    <A, B, C>(f: (b: B) => C, d: (a: A) => B): ((a: A) => C) =>
    (...args) =>
        f(d(...args));
export const pipe = (fn: (...args: any[]) => any, ...fns: Array<(...args: any[]) => any>): ((...args: any[]) => any) =>
    // @ts-ignore
    fns.length ? compose(pipe(...fns), fn) : fn;
export const curry = (fn: (...args: any[]) => any): ((...args: any[]) => any) =>
    fn.length <= 1 ? fn : (...args) => (args.length === fn.length ? fn(...args) : curry(fn.bind(null, ...args)));
export const I = <T>(x: T): T => x;
export const K = function <T>(x: T): () => T {
    return () => x;
};
interface Functor<T> {
    map<U>(fn: (t: T) => U): Functor<U>;
}
export const map =
    <T, U>(fn: (t: T) => U) =>
    (functor: Functor<T>): Functor<U> =>
        functor.map(fn);
export const filter =
    <T>(pred: (t: T) => boolean) =>
    (arr: T[]) =>
        arr.filter(pred);
export const forEach =
    <T>(fn: (t: T) => unknown) =>
    (arr: T[]) =>
        arr.forEach(fn);
export const then =
    <T, U>(fn: (t: T) => U) =>
    (promise: Promise<T>): Promise<U> =>
        promise.then(fn);
