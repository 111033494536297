import * as React from 'react';
import ChangesTitle from './ChangesTitle/ChangesTitle';
import CollapseButton from '../CollapseButton/CollapseButton';
import DCRChange from './DCRChange/DCRChange';
import DCRNestedChange from './DCRNestedChange/DCRNestedChange';
import EmptyChanges from './EmptyChanges/EmptyChanges';
import RejectWrapper from './RejectWrapper/RejectWrapper';
import ValuesRowContent from '../../ActivityLog/LookupValues/ValuesRowContent/ValuesRowContent';
import clsx from 'clsx';
import styles from './styles.less';
import {MAX_VISIBLE_CHANGES_IN_DCR, WorkflowChangeTypes} from '../constants';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {WorkflowDCRType} from '../workflow.types';
import {connect} from 'react-redux';
import {getCanUserRejectGroupedChangeById} from '../../../redux/selectors/workflowSelectors';
import {isEmpty} from 'ramda';
import {linkClickWFLogger, showLessWFLogger, showMoreWFLogger} from '../logging';
import {memo, useState} from 'react';
import {resetDependenciesEvent} from '../../../redux/actions/dependencies';
import {setDisplayedTaskEvent} from '../../../redux/actions/workflow';
type Props = {
    DCRs: WorkflowDCRType[];
    dispatch: (e: StateEvent) => void;
};

const mapWrapperStateToProps = (state, {item: {id, workflowChangeType}}) => ({
    canReject:
        workflowChangeType === WorkflowChangeTypes.ADDED_LOOKUP_TYPE && getCanUserRejectGroupedChangeById(state, id)
});

type ValuesRowContentWrapperProps = {
    canReject: boolean;
    item: WorkflowDCRType;
    children: React.ReactElement;
};
const ValuesRowContentWrapper = connect(mapWrapperStateToProps)(({
    canReject,
    item,
    children
}: ValuesRowContentWrapperProps) => {
    const {id} = item;
    return canReject ? <RejectWrapper id={id}>{children}</RejectWrapper> : children;
});
export function Changes(props: Props) {
    const {DCRs, dispatch} = props;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const showCollapseButton = MAX_VISIBLE_CHANGES_IN_DCR < DCRs.length;
    const visibleDCRs = showCollapseButton && isCollapsed ? DCRs.slice(0, MAX_VISIBLE_CHANGES_IN_DCR) : DCRs;
    const lastIndex = visibleDCRs.length - 1;

    const toggleCollapse = () => {
        isCollapsed ? dispatch(showMoreWFLogger(null) as StateEvent) : dispatch(showLessWFLogger(null) as StateEvent);
        setIsCollapsed(!isCollapsed);
    };

    const hasDCRs = !isEmpty(visibleDCRs);

    const onLinkClick = () => {
        dispatch(resetDependenciesEvent());
        dispatch(setDisplayedTaskEvent(null));
    };

    return hasDCRs ? (
        <div className={styles['changes-container']}>
            {visibleDCRs.map((DCR, index) => (
                <div
                    key={`${DCR.id}_${index}`}
                    className={clsx(
                        styles['change-container'],
                        index === lastIndex && styles['change-container__last']
                    )}
                >
                    <div className={styles['change-container__dot']}></div>
                    <ValuesRowContentWrapper item={DCR}>
                        <ValuesRowContent
                            item={DCR}
                            ChangeComponent={DCRChange}
                            NestedChangeComponent={DCRNestedChange}
                            TitleComponent={ChangesTitle}
                            linkClickLogger={linkClickWFLogger}
                            onLinkClick={onLinkClick}
                        />
                    </ValuesRowContentWrapper>
                </div>
            ))}
            {showCollapseButton && <CollapseButton collapsed={isCollapsed} onClick={toggleCollapse} />}
        </div>
    ) : (
        <EmptyChanges />
    );
}
export default connect()(memo(Changes));
