import * as R from 'ramda';
import * as React from 'react';
import ActivityChanges from '../../parts/ActivityChanges/ActivityChanges';
import ChangesTitle from '../../parts/ChangesTitle/ChangesTitle';
import CollapseButton from '../../../CollapseButton/CollapseButton';
import Link from '../../parts/Link/Link';
import TruncateText from '../../../TruncateText/TruncateText';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {ACTIVITY_TYPE} from '../../constants';
import {ActivityMappingItem, ChangeType, NestedChangeType} from '../../activityLog.types';
import {SourceMapping} from '../../../../rdm-sdk/lookups.types';
import {StateEvent} from '../../../../rdm-sdk/state.types';
import {connectValuesSidePanelStore, setOpenedSourceChangesEvent} from '../ValuesSidePanel/valuesSidePanelStore';
import {formatBool, getDelta} from '../../../../rdm-sdk/activities';
type SourceChangeHeaderProps = {
    mapping: ActivityMappingItem;
    onClick: (mapping?: SourceMapping | null) => void;
    ChangeComponent: React.ComponentType<ChangeType>;
};

function SourceChangeHeader({mapping, onClick, ChangeComponent}: SourceChangeHeaderProps) {
    const value = mapping.newValue || mapping.oldValue;
    return (
        <ChangeComponent
            id={mapping.id}
            displayName={i18n.text('Source value')}
            type={mapping.type}
            className={styles['source-change__header']}
        >
            <Link className={styles['source-change__link']} onClick={() => onClick(value)}>
                {Boolean(value) && (
                    <React.Fragment>
                        <TruncateText>{R.prop('value', value)}</TruncateText>
                        <TruncateText prefix={' ('} postfix={')'}>
                            {R.prop('code', value)}
                        </TruncateText>
                    </React.Fragment>
                )}
            </Link>
        </ChangeComponent>
    );
}

const getChanges = (newValue, oldValue) =>
    getDelta(newValue, oldValue).map((delta) => {
        const change = {
            ...delta,
            displayName: delta.name,
            type: delta.type === ACTIVITY_TYPE.ADD ? null : delta.type
        };

        switch (change.name) {
            case 'code':
                return {...change, displayName: i18n.text('Code')};

            case 'value':
                return {...change, displayName: i18n.text('Value')};

            case 'enabled':
                return {
                    ...change,
                    displayName: i18n.text('Status'),
                    newValue: R.is(Boolean, change.newValue) ? formatBool(change.newValue) : undefined,
                    oldValue: R.is(Boolean, change.oldValue) ? formatBool(change.oldValue) : undefined
                };

            case 'canonicalValue':
                return {
                    ...change,
                    displayName: i18n.text('Is canonical'),
                    newValue: R.is(Boolean, change.newValue) ? formatBool(change.newValue) : undefined,
                    oldValue: R.is(Boolean, change.oldValue) ? formatBool(change.oldValue) : undefined
                };

            case 'downStreamDefaultValue':
                return {
                    ...change,
                    displayName: i18n.text('Is default'),
                    newValue: R.is(Boolean, change.newValue) ? formatBool(change.newValue) : undefined,
                    oldValue: R.is(Boolean, change.oldValue) ? formatBool(change.oldValue) : undefined
                };

            case 'description':
                return {...change, displayName: i18n.text('Description')};
        }

        return change;
    });

type Props = {
    mappings: ActivityMappingItem[];
    source: string;
    sourceKey: string;
    onLinkClick: (mapping?: SourceMapping | null) => void;
    measureRowHeight: () => void;
    open: boolean;
    sourceChangeMessage: string;
    dispatch: (arg0: StateEvent) => void;
    ChangeComponent: React.ComponentType<ChangeType>;
    NestedChangeComponent: React.ComponentType<NestedChangeType>;
};

function SourceChange(props: Props) {
    const {
        source,
        sourceKey,
        mappings,
        onLinkClick,
        measureRowHeight,
        open,
        sourceChangeMessage = '',
        dispatch,
        ChangeComponent,
        NestedChangeComponent
    } = props;

    const setOpen = (open) => dispatch(setOpenedSourceChangesEvent(sourceKey, open));

    function handleOpen() {
        setOpen(true);
        setTimeout(measureRowHeight, 0);
    }

    function handleClose() {
        setOpen(false);
        setTimeout(measureRowHeight, 0);
    }

    return (
        <ActivityChanges>
            <ChangesTitle className={styles['source-change__title']}>
                {i18n.text('Source:')}
                <span className={styles['source-change__subtitle']}>{source}</span>
                {!sourceChangeMessage && (
                    <CollapseButton
                        open={open}
                        className={styles['source-change__collapse-button']}
                        onOpen={handleOpen}
                        onClose={handleClose}
                    />
                )}
                {sourceChangeMessage && <div className={styles['source-change__message']}>{sourceChangeMessage}</div>}
            </ChangesTitle>
            {open &&
                mappings.map((mapping, index) =>
                    mapping.type === ACTIVITY_TYPE.DELETE ? (
                        /*|| mapping.type === ACTIVITY_TYPE.ADD*/
                        <SourceChangeHeader
                            key={mapping.name + index}
                            onClick={onLinkClick}
                            mapping={mapping}
                            ChangeComponent={ChangeComponent}
                        />
                    ) : (
                        <NestedChangeComponent
                            id={mapping.id}
                            key={mapping.name + index}
                            header={
                                <SourceChangeHeader
                                    onClick={onLinkClick}
                                    mapping={mapping}
                                    ChangeComponent={ChangeComponent}
                                />
                            }
                            changes={getChanges(mapping.newValue, mapping.oldValue)}
                            ChangeComponent={ChangeComponent}
                        />
                    )
                )}
        </ActivityChanges>
    );
}

const mapStateToProps = (state, {sourceKey}: Record<string, any>) => ({
    open: R.pathOr(true, ['openedSourceChanges', sourceKey], state)
});

export default connectValuesSidePanelStore(mapStateToProps)(SourceChange);
