import PieChartCard from '../PieChartCard/PieChartCard';
import i18n from 'ui-i18n';
import styles from './styles.less';
type Props = {
    mappedSources: number;
    totalSources: number;
};

const MappingSummary = ({mappedSources, totalSources}: Props) => (
    <div className={styles['card-group']}>
        <h3 className={styles['title']}>{i18n.text('Mapping Summary')}</h3>
        <div className={styles['card-container']}>
            <PieChartCard mappedSources={mappedSources} totalSources={totalSources} />
        </div>
    </div>
);

export default MappingSummary;
