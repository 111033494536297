export const MAX_VISIBLE_ITEMS_IN_ACTIVITY = 3;
export const MAX_VISIBLE_ITEMS_IN_HIERARCHY_CHANGE = 3;
export const COLLAPSE_SHOW_MORE = 'SHOW_MORE';
export const COLLAPSE_SHOW_LESS = 'SHOW_LESS';
export enum ACTIVITY_TYPE {
    ADD = 'ADD',
    CHANGE = 'CHANGE',
    DELETE = 'DELETE'
}
export enum OBJECT_TYPE {
    LOOKUP_TYPE = 'lookupType',
    LOOKUP_VALUE = 'lookupValue',
    SOURCE = 'source'
}

export enum ActivityLogLabel {
    DATE_LABEL = 'DATE_LABEL',
    COLLAPSE_LABEL = 'COLLAPSE_LABEL',
    SHOW_MORE_LABEL = 'SHOW_MORE_LABEL'
}
