import './styles.less';
import * as R from 'ramda';
import Highlighter from 'react-highlight-words';
import {CSSProperties} from 'react';
import {escapeRegExpSymbols, splitToWords} from '../../core/util';
import {fromNull} from '../../core/maybe';
import {safeProp} from '../../core/lenses';
const safeValueL = safeProp('value');
const safeCodeL = safeProp('code');
const safeNameL = safeProp('name');
const safeCanonicalCodeL = safeProp('canonicalCode');
const safeCanonicalValueL = safeProp('canonicalValue');
const safeHasWordsL = safeProp('hasWords');
const safeValueValueL = safeValueL.compose(safeValueL);
const safeCodeValueL = safeCodeL.compose(safeValueL);
const safeNameValueL = safeNameL.compose(safeValueL);
const safeCanonicalCodeValueL = safeCanonicalCodeL.compose(safeValueL);
const safeCanonicalValueValueL = safeCanonicalValueL.compose(safeValueL);

const getBoundarySymbol = (symbol) => (symbol === '&' ? '' : '\\b');

const wordToRegExp = (word) =>
    getBoundarySymbol(R.head(word)) + escapeRegExpSymbols(word) + getBoundarySymbol(R.last(word));

type Props<SQ> = {
    value?: string;
    searchQuery: SQ;
    searchFields: string[];
    enabled: boolean;
    style?: CSSProperties;
    className?: string;
};

function SearchWordsHighlighter<SQ>({
    value,
    searchQuery,
    searchFields,
    enabled,
    style,
    className = 'highlightText'
}: Props<SQ>) {
    const safeQuery = fromNull(searchQuery);
    const searchValues = {
        value: safeValueValueL.get(safeQuery).orSome(''),
        code: safeCodeValueL.get(safeQuery).orSome(''),
        hasWords: safeHasWordsL.get(safeQuery).orSome(''),
        name: safeNameValueL.get(safeQuery).orSome(''),
        canonicalCode: safeCanonicalCodeValueL.get(safeQuery).orSome(''),
        canonicalValue: safeCanonicalValueValueL.get(safeQuery).orSome('')
    };
    const searchString = searchFields.map((field) => searchValues[field]).join(' ');
    const searchWords = enabled ? splitToWords(searchString).map(wordToRegExp) : [];
    return (
        <div className="search-words-highlighter">
            <Highlighter
                highlightStyle={style}
                unhighlightStyle={style}
                searchWords={searchWords}
                textToHighlight={value}
                highlightClassName={className}
            />
        </div>
    );
}

export default SearchWordsHighlighter;
