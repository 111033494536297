import TextField, {TextFieldProps} from '@mui/material/TextField';
import clsx from 'clsx';
import styles from './textfield.less';
import {forwardRef} from 'react';

type Props = {
    readOnly?: boolean;
} & Omit<TextFieldProps, 'variant'>;

const CustomTextField = forwardRef(
    (
        {readOnly = false, className: originalClassName, inputProps = {}, ...otherProps}: Props,
        ref: React.Ref<HTMLDivElement>
    ) => (
        <TextField
            ref={ref}
            variant="standard"
            className={clsx(readOnly && styles.readOnly, originalClassName)}
            inputProps={{...inputProps, readOnly}}
            FormHelperTextProps={{
                classes: {
                    root: styles.helperText
                }
            }}
            {...otherProps}
        />
    )
);

CustomTextField.displayName = 'CustomTextField';

export default CustomTextField;
