import Button from '@mui/material/Button';
import i18n from 'ui-i18n';
import pageNotFoundIcon from '../../assets/inline/pageNotFoundIcon.svg';
import styles from './styles.less';
import {Component} from 'react';
import {StateEvent} from '../../rdm-sdk/state.types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {setLoadingEvent} from '../../redux/actions/app';

type DispatchProps = {
    dispatch: (e: StateEvent) => Promise<any>;
};

type Props = DispatchProps & {
    params: {
        tenantId: string;
    };
};

export class PageNotFound extends Component<Props> {
    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(setLoadingEvent(false));
    }

    render() {
        const {
            dispatch,
            params: {tenantId}
        } = this.props;
        return (
            <div
                style={{
                    display: 'flex',
                    height: '100vh',
                    backgroundColor: '#F9FAFA'
                }}
                className={'empty-state'}
            >
                <div
                    className={'empty-state__icon'}
                    dangerouslySetInnerHTML={{
                        __html: pageNotFoundIcon
                    }}
                />
                <div className={styles['page-not-found__message-block']}>
                    <h1 className={styles['page-not-found__header']}>{i18n.text('Something wrong here...')}</h1>
                    <p className={styles['page-not-found__text']}>
                        {i18n.text("Sorry, the page you were looking for doesn't exist.")}
                    </p>
                    <p className={styles['page-not-found__text']}>
                        {i18n.text('Go back to rdm.reltio.com or contact us about a problem.')}
                    </p>
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    className={styles['page-not-found__button']}
                    onClick={() => dispatch(push(`/${tenantId}`))}
                >
                    {i18n.text('Back to homepage')}
                </Button>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) =>
    ({
        dispatch
    }) as DispatchProps;

export default connect(null, mapDispatchToProps)(PageNotFound);
