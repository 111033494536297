import CustomIcon from '../../CustomIcon/CustomIcon';
import styles from './comment-header.less';
import {PureComponent} from 'react';
type Props = {
    username: string;
};

class CommentDialogHeader extends PureComponent<Props> {
    render() {
        const {username} = this.props;
        return (
            <div className={styles['comment-header']}>
                <div className={styles['comment-header__avatar']}>
                    <CustomIcon name="face" />
                </div>
                <div className={styles['comment-header__username']}>{username}</div>
            </div>
        );
    }
}

export default CommentDialogHeader;
