import * as R from 'ramda';
import ImportWizard from '../ImportWizard/ImportWizard';
import i18n from 'ui-i18n';
import importIcon from '../../assets/inline/import.svg';
import styles from './drawer.less';
import {Component, Fragment} from 'react';
type Props = {
    onClick: () => void;
};
type State = {
    wizardOpen: boolean;
};

class ImportButton extends Component<Props, State> {
    state = {
        wizardOpen: false
    };
    toggleWizardOpen = () =>
        this.setState(
            R.evolve({
                wizardOpen: R.not
            })
        );
    handleClick = R.pipe(this.props.onClick, this.toggleWizardOpen);

    render() {
        return (
            <Fragment>
                <div className={styles.menu_item} onClick={this.handleClick}>
                    <span
                        className={styles.menu_item__icon}
                        dangerouslySetInnerHTML={{
                            __html: importIcon
                        }}
                    />
                    {i18n.text('Import')}
                </div>
                <ImportWizard open={this.state.wizardOpen} onClose={this.toggleWizardOpen} />
            </Fragment>
        );
    }
}

export default ImportButton;
