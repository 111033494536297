import * as R from 'ramda';
import i18n from 'ui-i18n';
import numeral from 'numeral';
import {isIE} from './util';

declare global {
    interface Navigator {
        browserLanguage: string;
    }
}

export const getLocale = () => (isIE() ? window.navigator.browserLanguage : window.navigator.language);

const ensureNumeralLanguage = (language) => {
    try {
        numeral.language(language);
    } catch (_error) {
        const defaultLanguageData = numeral.languageData('en');
        numeral.language(language, defaultLanguageData);
    }
};

export const loadLocale = R.curry((locale, bundle) => {
    try {
        ensureNumeralLanguage(locale);
        return i18n.loadLocale(locale, bundle);
    } catch (error) {
        console.error(`Translations bundle for "${locale}" language is not loaded\n`, error);
        return Promise.resolve();
    }
});
