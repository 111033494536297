import Badge from '@mui/material/Badge';
import CustomIcon from '../../CustomIcon/CustomIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {noop} from '../../../core/util';
type Props = {
    onClick: () => void;
    sizeLabel: string;
};

function WorkflowIconButton({onClick = noop, sizeLabel}: Props) {
    return (
        <Tooltip title={i18n.text('Workflow')}>
            <IconButton onClick={onClick} size="large">
                <Badge
                    badgeContent={sizeLabel}
                    color="error"
                    classes={{
                        root: styles['workflow-counter__badge-root'],
                        badge: styles['workflow-counter__badge']
                    }}
                    overlap="rectangular"
                >
                    <CustomIcon name="workflow" />
                </Badge>
            </IconButton>
        </Tooltip>
    );
}

export default WorkflowIconButton;
