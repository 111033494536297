import {INTEGER_REG_EX} from '../../constants/common';
import {Validation} from 'monet';
import {dateStringToMoment} from '../../core/date';
import {isDefined, isNotNull, isString} from '../../core/maybe';
import {trim} from '../../core/util';
export const validateCode = (code) =>
    isString(code) && code.length > 0 ? Validation.success(code) : Validation.fail(['code is empty']);
export const validateRequiredAttribute = (attribute) =>
    !attribute.required || (isString(attribute.value) && trim(attribute.value).length > 0);
const BOOLEAN_REG_EX = /^(true|false)$/;
const NUMBER_REG_EX = /^-?[0-9]+(\.[0-9]+)?$/;
const FLOAT_REG_EX = /^-?[0-9]+(\.[0-9]+)+$/;
export const validateAttributeType = (attribute) => {
    if (!isDefined(attribute.value) || !isNotNull(attribute.value)) return true;
    if (!isString(attribute.value)) return false;
    const value = attribute.value;
    if (!value.length) return true;

    switch (attribute.type) {
        case 'Number':
            return NUMBER_REG_EX.test(value);

        case 'Boolean':
            return BOOLEAN_REG_EX.test(value);

        case 'Time':
            return dateStringToMoment(value, true).isValid();

        case 'Float':
            return FLOAT_REG_EX.test(value);

        case 'Integer':
            return INTEGER_REG_EX.test(value);

        default:
            return true;
    }
};

const isRequiredAttribute = (attribute) =>
    validateRequiredAttribute(attribute)
        ? Validation.success(attribute.value)
        : Validation.fail(['required attribute is empty']);
export const validateAttribute = (attribute) =>
    validateAttributeType(attribute)
        ? isRequiredAttribute(attribute)
        : Validation.fail(['attribute type check failed']);
export const validateAttributes = (attributes) =>
    attributes
        ? attributes
              .map(validateAttribute)
              // @ts-ignore
              .reduce((v, all) => all.ap(v), Validation.success().acc())
        : Validation.success([]);
export const validateDetails = (reference) =>
    validateAttributes(reference.attributes).ap(validateCode(reference.code).acc());
