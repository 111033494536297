import * as R from 'ramda';
import {LookupValue} from '../../rdm-sdk/lookups.types';
import {SourceStats} from '../../rdm-sdk/stats.types';
import {buildSearchMapping, searchQueryIsNotEmpty} from '../../components/Mapping/searchHelpers';
import {countAddedLookupValues} from '../../rdm-sdk/lookups';
import {createSelector} from 'reselect';
export const getValuesBySources = R.pathOr({}, ['stats', 'valuesBySources']);
export const getLookupValues = R.propOr({}, 'lookupValues');
export const getValuesBySourcesStats = createSelector(
    getValuesBySources,
    getLookupValues,
    (valuesBySources: SourceStats, lookupValues: LookupValue[]) =>
        !R.isEmpty(valuesBySources) ? R.mergeWith(R.add, valuesBySources, countAddedLookupValues(lookupValues)) : {}
);
export const getSearchMappings = createSelector(
    getLookupValues,
    (state, props) => props.query,
    (lookupValues: LookupValue[], query) =>
        searchQueryIsNotEmpty(query) ? buildSearchMapping(query, lookupValues) : null
);
export const getEditMode = R.propOr(null, 'editMode');
const getIsOnlyPersonalWFPermission = createSelector(
    R.path(['policies', 'workflowRead']),
    R.path(['policies', 'workflowAllRead']),
    (workflowRead, workflowAllRead) => workflowRead && !workflowAllRead
);
const getSessionUsername = R.pathOr('', ['session', 'username']);
export const getTasksCreatorFilter = createSelector(
    getIsOnlyPersonalWFPermission,
    getSessionUsername,
    (onlyPersonalTasks, username) => (onlyPersonalTasks ? username : undefined)
) as (state: any) => string;
