import Button from '@mui/material/Button';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {connect} from 'react-redux';
import {getIsActivityLogFilterEmpty} from '../../../../redux/reducers/activityLogReducer';
type Props = {
    onClear: () => void;
    onCancel: () => void;
    onSearch: () => void;
    isFilterEmpty: boolean;
};

function FilterControl(props: Props) {
    const {onCancel, onClear, onSearch, isFilterEmpty} = props;
    return (
        <div className={styles['filter-control']}>
            <Button variant="grey" disabled={isFilterEmpty} onClick={onClear}>
                {i18n.text('CLEAR ALL')}
            </Button>
            <div className="spacer" />
            <Button variant="grey" className={styles['filter-control__cancel']} onClick={onCancel}>
                {i18n.text('CANCEL')}
            </Button>
            <Button disabled={isFilterEmpty} color="primary" onClick={onSearch}>
                {i18n.text('APPLY')}
            </Button>
        </div>
    );
}

const mapDispatchToProps = (state) => ({
    isFilterEmpty: getIsActivityLogFilterEmpty(state)
});

export default connect(mapDispatchToProps)(FilterControl);
