import * as R from 'ramda';
import * as React from 'react';
import Icon from '@mui/material/Icon';
import ListWithScroll from '../ListWithScroll/ListWithScroll';
import UnmappedTile from './UnmappedTile';
import UnmappedTileDraggable from './UnmappedTileDraggable';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {AutoSizer} from 'react-virtualized';
import {CONTENT_MARGIN_TOP, HEADER_ROW_HEIGHT, ROW_HEIGHT} from './constants';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {EDIT_MODE, SCROLL_OFFSET} from '../../constants/common';
import {PureComponent} from 'react';
import {SortDirection} from '../../rdm-sdk/app.types';
import {UnmappedItem, UnmappedRemovedItems, UnmappedValue} from '../../rdm-sdk/unmapped.types';
import {connect} from 'react-redux';
import {fromNull} from '../../core/maybe';
import {getEditMode} from '../../redux/selectors/mainReducerSelectors';
import {getIsDCRDisplayed, getIsWorkflowEnabled} from '../../redux/selectors/workflowSelectors';
import {getSourceLabel} from '../../rdm-sdk/sources';
import {isUnmappedItemsEqual} from '../../rdm-sdk/unmapped';
import {isWorkflowHasEditMode} from '../../rdm-sdk/editModeTypes';
import {splitToWords} from '../../core/util';

type RendererProps = {
    index: number;
    key: string;
    style?: React.CSSProperties;
};

// eslint-disable-next-line react/display-name
const listRowRenderer = (context) => (props: RendererProps) => {
    const {isDraggable, onItemClick, ...otherContext} = context;
    const {index} = props;
    const isRemoved = R.path(['items', index, 'removed'], context);
    return (
        <div key={props.key} style={props.style}>
            {isDraggable && !isRemoved ? (
                <UnmappedTileDraggable {...props} {...otherContext} onItemClick={onItemClick} />
            ) : (
                <UnmappedTile {...props} {...otherContext} />
            )}
        </div>
    );
};

const flattenRemovedItems = R.pipe(R.values, R.flatten);
export const getUnmappedValueTotal = (unmappedValue: UnmappedValue, removedItems: UnmappedItem[]) => {
    const removed = R.filter(
        R.both(R.propEq('source', unmappedValue.source), (item) =>
            R.find(isUnmappedItemsEqual(item), unmappedValue.items)
        )
    )(removedItems);
    return unmappedValue.total - removed.length;
};

function filterRemoved(items: UnmappedItem[], removed, source) {
    if (!removed.length || !items.length) return items;
    const removedBySource = removed.filter((removed) => removed.source === source);
    if (!removedBySource.length) return items;
    const filtered: UnmappedItem[] = [];

    for (const item of items) {
        const currRemovedIndex = R.findIndex(isUnmappedItemsEqual(item), removedBySource);

        if (currRemovedIndex === -1) {
            filtered.push(item);
        } else {
            removedBySource.splice(currRemovedIndex, 1);
        }
    }

    return filtered;
}

const markRemoved = (items, removed, source) => {
    if (!removed.length || !items.length) {
        return items;
    }

    const removedBySource = removed.filter((removed) => removed.source === source);

    if (!removedBySource.length) {
        return items;
    }

    return items.map((item) => {
        if (R.find(isUnmappedItemsEqual(item), removedBySource)) {
            return {...item, removed: true};
        }

        return item;
    });
};

type Props = {
    configuration: Configuration;
    unmappedValue: UnmappedValue;
    selectedItems: UnmappedItem[];
    onSort: (unmappedValue: UnmappedValue) => Promise<any>;
    onItemClick: (item: UnmappedItem) => void;
    prevCollapsedId: number | boolean;
    nextCollapsedId: number | boolean;
    onExpandClick: (colId: number) => void;
    loadMore: (unmappedValue: UnmappedValue, onLoaded?: () => void) => void;
    searchQuery: string;
    setIsDragging: (isDragging: boolean) => void;
    isUnmappedDragging: boolean;
    removedItems: UnmappedRemovedItems;
    policies: CheckedPolicies;
    isWorkflowEnabled: boolean;
    isDCRDisplayed: boolean;
    editMode: EDIT_MODE;
};
export class UnmappedList extends PureComponent<Props> {
    render() {
        const {
            unmappedValue,
            selectedItems,
            onSort,
            onItemClick,
            onExpandClick,
            prevCollapsedId,
            nextCollapsedId,
            loadMore,
            searchQuery,
            setIsDragging,
            isUnmappedDragging,
            removedItems,
            configuration,
            policies,
            isWorkflowEnabled,
            isDCRDisplayed,
            editMode
        } = this.props;
        let listRef;
        const removedItemsFlat = flattenRemovedItems(removedItems);
        const items =
            editMode === EDIT_MODE.SUGGEST
                ? markRemoved(unmappedValue.items, removedItemsFlat, unmappedValue.source)
                : filterRemoved(unmappedValue.items, removedItemsFlat, unmappedValue.source);
        const itemsTotal = getUnmappedValueTotal(unmappedValue, removedItemsFlat);
        const searchWords = fromNull(searchQuery).map(splitToWords).orSome([]);

        const onSortHandler = () => {
            listRef && listRef.getScrollbarsRef().scrollTop();
            onSort(unmappedValue).then(() => loadMore(unmappedValue));
        };

        const isWorkflowEditMode = isWorkflowHasEditMode(policies) && isWorkflowEnabled;
        const isDraggable = (policies.lookupValuesEdit || isWorkflowEditMode) && !isDCRDisplayed;
        return (
            <div className={styles['unmapped-table__list']}>
                <div
                    style={{
                        height: HEADER_ROW_HEIGHT
                    }}
                    className={styles['unmapped-table__title']}
                >
                    <div className={styles['unmapped-table__title-tile']}>
                        {prevCollapsedId !== false && (
                            <Icon
                                className={styles['unmapped-table__expand-left-icon']}
                                onClick={() => {
                                    onExpandClick(Number(prevCollapsedId));
                                }}
                            >
                                arrow_drop_up
                            </Icon>
                        )}

                        <Icon
                            className={clsx(
                                styles['unmapped-table__title-icon'],
                                unmappedValue.order === SortDirection.DESC &&
                                    styles['unmapped-table__title-icon--rotated']
                            )}
                            onClick={onSortHandler}
                        >
                            arrow_upward
                        </Icon>
                        <span className={styles['unmapped-table__title-name']} onClick={onSortHandler}>
                            {getSourceLabel(configuration, unmappedValue.source)}
                        </span>
                        <span className={styles['unmapped-table__title-total']}>
                            {itemsTotal > 1
                                ? i18n.text('${count} values', {
                                      count: itemsTotal
                                  })
                                : i18n.text('${count} value', {
                                      count: itemsTotal
                                  })}
                        </span>

                        {nextCollapsedId && (
                            <Icon
                                className={styles['unmapped-table__expand-right-icon']}
                                onClick={() => {
                                    onExpandClick(Number(nextCollapsedId));
                                }}
                            >
                                arrow_drop_down
                            </Icon>
                        )}
                    </div>
                </div>

                <AutoSizer>
                    {({width, height}) => (
                        <ListWithScroll
                            ref={(list) => (listRef = list)}
                            width={width}
                            height={height - HEADER_ROW_HEIGHT}
                            rowCount={items.length}
                            rowHeight={({index}) => (index === 0 ? ROW_HEIGHT + CONTENT_MARGIN_TOP : ROW_HEIGHT)}
                            rowRenderer={listRowRenderer({
                                items,
                                onItemClick,
                                searchWords,
                                selectedItems,
                                setIsDragging,
                                isUnmappedDragging,
                                isDraggable
                            })}
                            loadMore={(onLoaded) => {
                                if (unmappedValue.scrollId) loadMore(unmappedValue, onLoaded);
                            }}
                            onScroll={({scrollHeight, clientHeight, scrollTop}) => {
                                if (scrollHeight - clientHeight - scrollTop <= SCROLL_OFFSET) {
                                    loadMore(unmappedValue);
                                }
                            }}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    editMode: getEditMode(state),
    isWorkflowEnabled: getIsWorkflowEnabled(state, props),
    isDCRDisplayed: getIsDCRDisplayed(state),
    ...R.pick(['configuration', 'policies'], state)
});

export default connect(mapStateToProps)(UnmappedList);
