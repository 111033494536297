import clsx from 'clsx';
import styles from './styles.less';
import {RowHeaderItem} from '../../activityLog.types';
type Props = {
    sticky?: boolean;
    item: RowHeaderItem;
};

function ListRowHeader(props: Props) {
    const {sticky, item} = props;
    const isFirst = item.headerIndex === 0;
    return (
        <div
            className={clsx(
                styles['list-row-header'],
                sticky && styles['list-row-header--sticky'],
                isFirst && styles['list-row-header--is-first']
            )}
        >
            <div className={styles['list-row-header__circle']} />
            <div className={clsx(styles['list-row__vert-line'], isFirst && styles['list-row__vert-line--is-first'])} />
            <h2 className={styles['list-row-header__label']}>{item.value}</h2>
        </div>
    );
}

export default ListRowHeader;
