import * as React from 'react';
import clsx from 'clsx';
import styles from './styles.less';
type Props = {
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
};

function Bookmark(props: Props) {
    const {onClick, children, className} = props;
    return (
        <div className={clsx(styles['bookmark'], className)} onClick={onClick}>
            {children}
        </div>
    );
}

export default Bookmark;
