import * as R from 'ramda';
import Button from '@mui/material/Button';
import CloseDialogButton from '../CloseDialogButton/CloseDialogButton';
import Dialog from '../Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '../TextField/TextField';
import i18n from 'ui-i18n';
import styles from './add-source-dialog.less';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {Source} from '../../rdm-sdk/sources.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {addSourceEvent, deleteSourcesEvent, updateSourceEvent} from '../../redux/actions/configuration';
import {connect} from 'react-redux';
import {fromNotEmpty, fromNull} from '../../core/maybe';
import {getValue, isUriUnique} from '../../core/util';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {sanitizeSource} from '../../core/sanitizers';
import {sourceAbbrToUri} from '../../rdm-sdk/sources';
import {validateSource} from './validation';
const DEFAULT_STATE = {
    isNew: true,
    isEdited: false,
    abbreviation: '',
    uri: '',
    label: '',
    icon: '',
    propsToTrack: {
        source: null
    }
};

const initState = (source: Source | null): State =>
    fromNull(source)
        .map(
            R.pipe(R.assoc('isNew', false), R.assoc('isEdited', false), R.assocPath(['propsToTrack', 'source'], source))
        )
        .orSome(DEFAULT_STATE) as State;

type Props = {
    source: Source | null;
    dispatch: (e: StateEvent) => void;
    onCreated: () => void;
    open: boolean;
    onCancelled: () => void;
    configuration: Configuration;
    isEditMode: boolean;
};
type State = Source & {
    isNew: boolean;
    isEdited: boolean;
    propsToTrack: {
        source?: Source | null;
    };
};
const logger = logActivityCommand('source-modal');
const saveLogger = logger('save-click');
const addLogger = logger('add-save-click');
const deleteLogger = logger('delete-click');
const cancelLogger = logger('cancel-click');
export class AddSourceDialog extends Component<Props, State> {
    state = initState(this.props.source) as State;
    static defaultProps: Partial<Props> = {
        source: null
    } as Props;

    static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {
        const {source: nextSource} = nextProps;
        const {
            propsToTrack: {source: prevSource}
        } = prevState;

        if (nextSource !== prevSource) {
            return initState(nextSource);
        } else {
            return null;
        }
    }

    onAbbreviationChange = (abbreviation: string) => {
        this.setState({
            abbreviation,
            uri: sourceAbbrToUri(abbreviation),
            isEdited: true
        });
    };
    next = (key: string) => (value: any) =>
        this.setState({
            [key]: value,
            isEdited: true
        } as State);
    onCreateClick = () => {
        const {dispatch, onCreated} = this.props;
        R.pipe(sanitizeSource, this.state.isNew ? addSourceEvent : updateSourceEvent, dispatch, onCreated)(this.state);
    };
    onDeleteClick = () => {
        const {dispatch, onCreated} = this.props;
        R.pipe(deleteSourcesEvent, dispatch, onCreated)([this.state]);
    };
    onDialogExited = () => {
        this.setState(initState(this.props.source) as State);
    };

    render() {
        const {
            dispatch,
            open,
            onCancelled,
            configuration: {sources},
            isEditMode
        } = this.props;
        const {isNew, isEdited} = this.state;
        const label = fromNull(this.state.label).orSome('');

        const getEditSourceLabel = () => (isEditMode ? i18n.text('Edit Source System') : label);
        const title = isNew ? i18n.text('Add Source System') : getEditSourceLabel();
        const isDuplicateUri = isNew && !isUriUnique(this.state.uri, sources);
        const isValid = validateSource(this.state).isSuccess() && !isDuplicateUri;
        const abbreviation = fromNull(this.state.abbreviation).orSome('');
        const icon = fromNotEmpty(this.state.icon).orSome(`${isEditMode ? '' : i18n.text('No icon')}`);
        const uri = fromNull(this.state.uri).orSome('');
        const loggableInfo = {
            label,
            abbreviation,
            icon,
            uri
        };
        return (
            <Dialog
                open={open}
                onCancel={onCancelled}
                className={styles['add-source-dialog']}
                TransitionProps={{
                    onExited: this.onDialogExited
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <CloseDialogButton visible={!isEditMode} onClick={onCancelled} />
                <DialogContent>
                    {isEditMode && (
                        <TextField
                            name="name"
                            onChange={R.pipe(getValue, this.next('label'))}
                            value={label}
                            label={i18n.text('Name')}
                            required={true}
                            fullWidth
                        />
                    )}
                    <TextField
                        name="abbreviation"
                        readOnly={!isNew}
                        onChange={R.pipe(getValue, this.onAbbreviationChange)}
                        value={abbreviation}
                        label={i18n.text('Abbreviation')}
                        required={isEditMode}
                        helperText={isDuplicateUri ? i18n.text('Source already exists') : ''}
                        error={isDuplicateUri}
                        fullWidth
                    />
                    <TextField
                        name="icon"
                        onChange={R.pipe(getValue, this.next('icon'))}
                        value={icon}
                        label={i18n.text('Icon')}
                        readOnly={!isEditMode}
                        fullWidth
                    />
                    <TextField name="uri" readOnly value={uri} label={i18n.text('URI')} fullWidth />
                </DialogContent>
                {isEditMode && (
                    <DialogActions>
                        {!isNew && (
                            <Button
                                variant="grey"
                                className="add-source-dialog__delete-button"
                                onClick={R.pipe(this.onDeleteClick, R.always(loggableInfo), deleteLogger, dispatch)}
                            >
                                {i18n.text('Delete')}
                            </Button>
                        )}
                        <div className="spacer" />
                        <Button
                            variant="grey"
                            className="add-source-dialog__cancel-button"
                            onClick={R.pipe(onCancelled, R.always(loggableInfo), cancelLogger, dispatch)}
                        >
                            {i18n.text('Cancel')}
                        </Button>
                        <Button
                            color="primary"
                            disabled={!isValid || !isEdited}
                            onClick={R.pipe(
                                this.onCreateClick,
                                R.always(loggableInfo),
                                isNew ? addLogger : saveLogger,
                                dispatch
                            )}
                        >
                            {i18n.text('Done')}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}
const mapStateToProps = R.pick(['configuration']);
export default connect(mapStateToProps)(AddSourceDialog);
