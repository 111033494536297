/**
 * Created by ndyumin on 12.04.2018.
 */
import * as R from 'ramda';
import {FEATURE} from '../../rdm-sdk/features';
import {commandCreator} from '../middlewares/command-middleware';
import {featureStream$} from '../../configs';
import {fromNull} from '../../core/maybe';
import {rawRequest} from '../../network';

declare global {
    interface Window {
        gbqLogConfigured?: boolean;
    }
}

export const logActivityCommand = (feature) => (action: string) =>
    commandCreator(({state}, payload) => {
        const {pathname = ''} = state?.routing?.locationBeforeTransitions || {};
        const isLoggingEnabled = featureStream$.getValue()[FEATURE.UI_AUDIT_LOG] !== false && window.gbqLogConfigured;
        const [tenantId, page, , tab] = pathname.split('/').filter(R.length);
        const tenant = state.tenant || tenantId;
        const hasSession = !!state.session;
        const location = fromNull(tab)
            .map((tab) => `${tab}-tab`)
            .orElse(fromNull(page).map((page) => `${page}-page`))
            .orSome('');
        isLoggingEnabled &&
            hasSession &&
            rawRequest('/log', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    tenant,
                    location,
                    feature,
                    action,
                    payload: JSON.stringify(payload)
                })
            });
    });
