/**
 * Created by ndyumin on 03.10.2016.
 */
import {json, requestWithBlockingSpinner} from '../../network';
export function tenantListEvent(list) {
    return {
        type: 'tenantList',
        list
    };
}
export function tenantChangedEvent(tenant) {
    return {
        type: 'tenantChanged',
        tenant
    };
}
export function updateTenantsListCommand() {
    return requestWithBlockingSpinner('/rdm/configuration').then(json);
}
