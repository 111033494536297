const UnmappedPanelIcon = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons-black/Show-panel" fill="#000000">
                <path
                    d="M5,9 L3,11 L3,1.99874609 C3,1.44715386 3.45530558,1 3.99180311,1 L22.0081969,1 C22.5559546,1 23,1.43861485 23,1.99874609 L23,21.0012539 C23,21.5528461 22.5446944,22 22.0081969,22 L3.99180311,22 C3.44404538,22 3,21.5613852 3,21.0012539 L3,15 L5,17 L5,20 L10,20 L10,6 L12,6 L12,20 L21,20 L21,6 L5,6 L5,9 Z M8,9 L8,17 L4,13 L8,9 Z"
                    id="Combined-Shape"
                ></path>
            </g>
        </g>
    </svg>
);

export default UnmappedPanelIcon;
