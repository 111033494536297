import * as React from 'react';
import Changes from '../Changes/Changes';
import CommentSection from './CommentSection/CommentSection';
import Header from './Header/Header';
import Info from './Info/Info';
import styles from './styles.less';
import {CONFIRMATION_DIALOG_TYPES} from '../../../constants/common';
import {CheckedPolicies} from '../../../permissions/permissions.types';
import {
    DCRCloseClickWFLogger,
    DCROpenClickWFLogger,
    addCommentWFLogger,
    assigneeChangeWFLogger,
    dateChangeWFLogger,
    executeActionWFLogger,
    priorityChangeWFLogger,
    scrollToCommentsWFLogger
} from '../logging';
import {DraftMode} from '../../../rdm-sdk/app.types';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {WorkflowType} from '../workflow.types';
import {
    applyDCRsToLookupsSaga,
    cancelApplyingDCRsSaga,
    requestDCRLookupsSaga,
    resetApplyingDCRsSaga
} from '../../../redux/actions/applyingDCR';
import {connect} from 'react-redux';
import {
    createTaskCommentSaga,
    executeActionSaga,
    setDisplayedTaskEvent,
    setTaskAssigneeSaga,
    setTaskDueDateSaga,
    setTaskPrioritySaga
} from '../../../redux/actions/workflow';
import {getDispatchOnResolve} from '../../../redux/middlewares/confirm-middleware/confirmMiddleware';
import {getDisplayedTask} from '../../../redux/selectors/workflowSelectors';
import {getIsConfigurationDirty, getIsValuesDirty} from '../../../redux/reducers/draftModeReducer';
import {head, pick, pipe, prop, propOr} from 'ramda';
import {requestUnmappedAllSaga} from '../../../redux/actions/unmapped';
import {requestValuesSaga} from '../../../redux/actions/values';
import {showConfirmationDialogEvent} from '../../../redux/actions/app';
import {useRef, useState} from 'react';

type StateProps = {
    draftMode: DraftMode;
    isTaskDisplayed: boolean;
    policies: CheckedPolicies;
    session: Record<string, any>;
};

type DispatchProps = {
    dispatch: (event: StateEvent) => void;
    createComment: (arg0: string, arg1: string) => null;
    setPriority: (arg0: string, arg1: string) => null;
    setDueDate: (arg0: string, arg1: number) => null;
    setAssignee: (arg0: string, arg1: string) => null;
    dispatchOnResolve: (e: StateEvent) => Promise<any>;
};

type Props = StateProps &
    DispatchProps & {
        workflow: WorkflowType;
    };
export function WorkflowItem(props: Props) {
    const {
        workflow,
        createComment,
        setPriority,
        setDueDate,
        setAssignee,
        policies,
        session,
        dispatch,
        draftMode,
        dispatchOnResolve,
        isTaskDisplayed
    } = props;
    const {comments, taskID, assignee} = workflow;
    const DCRs = propOr([], 'DCRs', workflow);
    const [open, setOpen] = useState(true);
    const commentsRef = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);

    const handleOpen = () => {
        dispatch(DCROpenClickWFLogger(null) as StateEvent);
        setOpen(true);
    };

    const handleClose = () => {
        dispatch(DCRCloseClickWFLogger(null) as StateEvent);
        setOpen(false);
    };

    const onCommentSend = (comment: string): void => {
        createComment(taskID, comment);
    };

    const onDateChange = (date: number): void => {
        setDueDate(taskID, date);
    };

    const onPriorityChange = (priority: string): void => {
        setPriority(taskID, priority);
    };

    const onAssigneeChange = (assignee: string): void => {
        setAssignee(taskID, assignee);
    };

    const onChooseAction = (action: string): void => {
        const dirtyValues = getIsValuesDirty(draftMode);
        const dirtyConfiguration = getIsConfigurationDirty(draftMode);

        if (dirtyValues || dirtyConfiguration) {
            dispatch(showConfirmationDialogEvent(CONFIRMATION_DIALOG_TYPES.EXECUTE_ACTION));
        }

        dispatchOnResolve(
            executeActionWFLogger({
                action,
                taskID
            }) as StateEvent
        );
        executeActionSaga(dispatchOnResolve)(action, taskID).then(() => {
            resetApplyingDCRsSaga(dispatch)();
            requestValuesSaga(dispatchOnResolve)();
            requestUnmappedAllSaga(dispatchOnResolve)();
        });
    };

    const scrollToComments = () => {
        dispatch(scrollToCommentsWFLogger(null) as StateEvent);

        if (itemRef.current && commentsRef.current) {
            const stickyHeaderHeight = 110;
            const commentsBlockOffset = commentsRef.current.offsetTop + itemRef.current.offsetTop;
            const scrollableBlock = itemRef.current.parentNode as HTMLDivElement;
            scrollableBlock?.scrollTo({
                left: 0,
                top: commentsBlockOffset - stickyHeaderHeight,
                behavior: 'smooth'
            });
        }
    };

    const canUpdate = policies.workflowTaskUpdate;
    const canReview = policies.workflowReview;
    const canPerformAction = canReview && canUpdate && session.username === assignee;

    const onShowChangesClick = () => {
        const getFirstDCRId = pipe(head, prop('DCRId'));
        const DCRId = getFirstDCRId(DCRs);
        const dirtyValues = getIsValuesDirty(draftMode);
        const dirtyConfiguration = getIsConfigurationDirty(draftMode);

        if (dirtyValues || dirtyConfiguration) {
            dispatch(showConfirmationDialogEvent(CONFIRMATION_DIALOG_TYPES.SHOW_DCR));
        }

        requestDCRLookupsSaga(dispatchOnResolve)(DCRId).then(() => {
            applyDCRsToLookupsSaga(dispatch)(DCRs);
            dispatch(setDisplayedTaskEvent(taskID));
        });
    };

    const onHideChangesClick = () => {
        cancelApplyingDCRsSaga(dispatch)();
    };

    return (
        <div className={styles['workflow-item']} ref={itemRef}>
            <Header
                workflow={workflow}
                open={open}
                canPerformAction={canPerformAction}
                onCommentIconClick={scrollToComments}
                onOpen={handleOpen}
                onClose={handleClose}
                onChooseAction={onChooseAction}
                onShowChangesClick={onShowChangesClick}
                onHideChangesClick={onHideChangesClick}
                isTaskDisplayed={isTaskDisplayed}
            />
            {open && (
                <React.Fragment>
                    <Info
                        workflow={workflow}
                        readOnly={!canUpdate}
                        onDateChange={onDateChange}
                        onPriorityChange={onPriorityChange}
                        onAssigneeChange={onAssigneeChange}
                    />
                    <Changes DCRs={DCRs} />
                    <CommentSection
                        onSend={onCommentSend}
                        comments={comments}
                        ref={commentsRef}
                        readOnly={!canUpdate}
                    />
                </React.Fragment>
            )}
        </div>
    );
}

const mapStateToProps = (state, {workflow}) =>
    ({
        ...pick(['policies', 'session', 'draftMode'])(state),
        isTaskDisplayed: getDisplayedTask(state) === workflow.taskID
    }) as StateProps;

const mapDispatchToProps = (dispatch) =>
    ({
        dispatch,
        createComment: (taskID, comment) => {
            dispatch(
                addCommentWFLogger({
                    taskID,
                    comment
                }) as StateEvent
            );
            createTaskCommentSaga(dispatch)(taskID, comment);
        },
        setPriority: (taskID, priority) => {
            dispatch(
                priorityChangeWFLogger({
                    taskID,
                    priority
                }) as StateEvent
            );
            setTaskPrioritySaga(dispatch)(taskID, priority);
        },
        setDueDate: (taskID, date) => {
            dispatch(
                dateChangeWFLogger({
                    taskID,
                    date
                }) as StateEvent
            );
            setTaskDueDateSaga(dispatch)(taskID, date);
        },
        setAssignee: (taskID, assignee) => {
            dispatch(
                assigneeChangeWFLogger({
                    taskID,
                    assignee
                }) as StateEvent
            );
            setTaskAssigneeSaga(dispatch)(taskID, assignee);
        },
        dispatchOnResolve: getDispatchOnResolve(dispatch)
    }) as DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowItem);
