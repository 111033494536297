import DatePicker from '../../../DatePicker/DatePicker';
import i18n from 'ui-i18n';
import makeStyles from '@mui/styles/makeStyles';
import styles from './styles.less';
import {connect} from 'react-redux';
import {
    getActivityLogFilterEndDate,
    getActivityLogFilterStartDate
} from '../../../../redux/reducers/activityLogReducer';
import {
    setActivityLogFilterEndDateEvent,
    setActivityLogFilterStartDateEvent
} from '../../../../redux/actions/activityLog';
const useStyles = makeStyles((theme) => ({
    label: {
        top: 5
    },
    formControl: {
        'label + &': {
            marginBottom: 0
        }
    },
    input: {
        padding: theme.spacing(0, 0, 1)
    }
}));
type Props = {
    startDate?: Date | null;
    endDate?: Date | null;
    onStartDateChange: (date?: Date | null) => void;
    onEndDateChange: (date?: Date | null) => void;
};

function RangeSelect(props: Props) {
    const {startDate, endDate, onStartDateChange, onEndDateChange} = props;
    const classes = useStyles();
    const textFieldProps = {
        InputLabelProps: {
            className: classes.label
        },
        InputProps: {
            classes: {
                formControl: classes.formControl,
                input: classes.input
            }
        }
    };
    return (
        <div className={styles['range-select']}>
            <div className={styles['range-select__picker-wrapper']}>
                <DatePicker
                    iconName="calendar_today"
                    id="activityLogStartDatePicker"
                    label={i18n.text('From')}
                    value={startDate}
                    onSelect={onStartDateChange}
                    TextFieldProps={textFieldProps}
                />
            </div>
            <div className={styles['range-select__picker-wrapper']}>
                <DatePicker
                    iconName="calendar_today"
                    id="activityLogEndDatePicker"
                    label={i18n.text('To')}
                    value={endDate}
                    onSelect={onEndDateChange}
                    TextFieldProps={textFieldProps}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    startDate: getActivityLogFilterStartDate(state),
    endDate: getActivityLogFilterEndDate(state)
});

const mapDispatchToProps = {
    onStartDateChange: setActivityLogFilterStartDateEvent,
    onEndDateChange: setActivityLogFilterEndDateEvent
};
export default connect(mapStateToProps, mapDispatchToProps)(RangeSelect);
