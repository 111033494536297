import ActionsMenu from '../ActionsMenu/ActionsMenu';
import CollapseButton from '../../../CollapseButton/CollapseButton';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import clsx from 'clsx';
import flagIcon from '../../../../assets/inline/flag.svg';
import i18n from 'ui-i18n';
import rdmDCRIcon from '../../../../assets/inline/rdmDCR.svg';
import styles from './styles.less';
import {TASK_PRIORITIES} from '../../constants';
import {WorkflowType} from '../../workflow.types';
import {find, propEq, propOr} from 'ramda';
import {getDateLabel, isOverDueDate} from '../../../../rdm-sdk/dates';
export type HeaderProps = {
    workflow: WorkflowType;
    open: boolean;
    canPerformAction: boolean;
    onOpen: () => void;
    onClose: () => void;
    onCommentIconClick: () => void;
    onChooseAction: (arg0: string) => void;
    onShowChangesClick: () => void;
    onHideChangesClick: () => void;
    isTaskDisplayed: boolean;
};

const getTaskStyleByPriority = (priority) => ({
    borderTopColor: propOr('transparent', 'color', find(propEq('priority', priority), TASK_PRIORITIES))
});

export function Header(props: HeaderProps) {
    const {
        workflow,
        open,
        canPerformAction,
        onCommentIconClick,
        onChooseAction,
        onOpen,
        onClose,
        onShowChangesClick,
        onHideChangesClick,
        isTaskDisplayed
    } = props;
    const {id, step, createTime: time, priority, name, createdBy, comments, possibleActions, dueDate} = workflow;
    const commentsCount = (comments || []).length;
    return (
        <div
            className={clsx(styles['header'], open && styles['header__line'])}
            style={getTaskStyleByPriority(priority)}
        >
            <CollapseButton
                open={open}
                className={styles['header__collapse-button']}
                onOpen={onOpen}
                onClose={onClose}
            />
            {canPerformAction && (
                <ActionsMenu
                    className={styles['header__actions']}
                    actions={possibleActions}
                    onChooseAction={onChooseAction}
                />
            )}

            <div
                className={styles['header__dcr-icon']}
                dangerouslySetInnerHTML={{
                    __html: rdmDCRIcon
                }}
            />
            <div className={styles['header__row']}>
                <div className={styles['header__name']}>{name}</div>
                {isOverDueDate(dueDate) && (
                    <div
                        className={styles['header__date-flag']}
                        dangerouslySetInnerHTML={{
                            __html: flagIcon
                        }}
                    />
                )}
                <div className={styles['header__date']}>{getDateLabel(time)}</div>
            </div>
            <div className={styles['header__row']}>
                <div className={styles['header__info']}>
                    {step} - {id}
                </div>
            </div>
            <div className={styles['header__row']}>
                <div className={styles['header__info']}>
                    {i18n.text('Created by')} {createdBy}
                </div>
            </div>
            {open && (
                <div className={styles['header__comment-wrapper']}>
                    <Tooltip title={i18n.text('Comments')}>
                        <IconButton onClick={onCommentIconClick} size="large">
                            <CommentOutlinedIcon
                                classes={{
                                    root: styles['header__comment-icon']
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <div className={styles['header__comments-count']}>{commentsCount}</div>

                    <div className={styles['header__toggle-changes']}>
                        {isTaskDisplayed ? (
                            <Tooltip title={i18n.text('Hide changes')}>
                                <IconButton onClick={onHideChangesClick} size="large">
                                    <VisibilityOffIcon color={'primary'} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={i18n.text('Show changes')}>
                                <IconButton onClick={onShowChangesClick} size="large">
                                    <VisibilityIcon color={'primary'} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
export default Header;
