import Button from '@mui/material/Button';
import EmptyState from '../EmptyState/EmptyState';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import WorkflowsList from '../WorkflowsList/WorkflowsList';
import WorkflowsPagination from '../WorkflowsPagination/WorkflowsPagination';
import i18n from 'ui-i18n';
import styles from './styles.less';
import {Fragment} from 'react';
import {WorkflowType} from '../workflow.types';
import {isNotEmpty} from '../../../core/util';
type Props = {
    workflows: WorkflowType[] | null;
    onClose: () => void;
    onPaginationChange: () => void;
    onRefresh: () => void;
};

const WorkflowTasks = (props: Props) => {
    const {workflows, onClose, onPaginationChange, onRefresh} = props;
    return (
        <Fragment>
            <div className={styles['workflow-tasks__header']}>
                <IconButton className={styles['workflow-tasks__close']} onClick={onClose} size="large">
                    <Icon>close</Icon>
                </IconButton>
                <Typography variant={'h6'} className={styles['workflow-tasks__title']}>
                    {i18n.text('Workflows')}
                </Typography>
                <div className="spacer" />
                <Button color="primary" onClick={onPaginationChange} startIcon={<RefreshIcon />}>
                    {i18n.text('Refresh')}
                </Button>
            </div>
            <div className={styles['workflow-tasks__container']}>
                {!!workflows && (isNotEmpty(workflows) ? <WorkflowsList workflows={workflows} /> : <EmptyState />)}
            </div>
            <div className={styles['workflow-tasks__pagination']}>
                <WorkflowsPagination onChange={onRefresh} />
            </div>
        </Fragment>
    );
};

export default WorkflowTasks;
