import * as R from 'ramda';
import i18n from 'ui-i18n';
import {ExportOptions} from '../../rdm-sdk/export.types';
import {commandCreator} from '../middlewares/command-middleware';
import {fromNotEmpty} from '../../core/maybe';
import {mapObjKeys} from '../../core/util';
import {requestWithNonBlockingSpinner} from '../../network';
import {setOpenedSnackbarEvent} from './ui';
import {sourceUriToAbbr} from '../../rdm-sdk/sources';
import {then} from '../../core/func';
import {typeUriToCode} from '../../rdm-sdk/types';
export const getExportParameters = (options: ExportOptions) => {
    const {
        selectedTypeUris,
        exportDetails: {fileFormat, exportParents, sourceUris, languageCodes, attributes, auditData}
    } = options;
    return {
        fileFormat,
        exportParents,
        exportLookupTypes: selectedTypeUris.map(typeUriToCode),
        exportSources: {
            enabled: !!sourceUris,
            include: fromNotEmpty(sourceUris)
                .map(R.map(sourceUriToAbbr))
                // @ts-ignore
                .orSome(null)
        },
        exportLocalizations: {
            enabled: !!languageCodes,
            include: fromNotEmpty(languageCodes).orSome(null)
        },
        exportAttributes: {
            enabled: !!attributes,
            include: fromNotEmpty(attributes)
                .map(mapObjKeys(typeUriToCode))
                // @ts-ignore
                .orSome(null)
        },
        exportUpdateInfo: R.propOr(false, 'exportUpdateInfo', auditData),
        exportVersion: R.propOr(false, 'exportVersion', auditData)
    };
};
const exportCommand = commandCreator(({state}, options: ExportOptions) => {
    const {tenant} = state;
    const exportParameters = getExportParameters(options);
    return requestWithNonBlockingSpinner(
        `/rdm/export/${tenant}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(exportParameters)
        },
        i18n.text('Export error')
    );
});
export const exportSaga = (dispatch: (...args: any[]) => any) =>
    R.pipe(
        exportCommand,
        dispatch,
        then(() => {
            dispatch(
                setOpenedSnackbarEvent({
                    title: i18n.text('Export job started'),
                    message: i18n.text('We will send you an email when your export job has successfully completed')
                })
            );
        })
    );
