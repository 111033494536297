/**
 * Created by parsentieva on 18.07.2017.
 */
import * as R from 'ramda';
import Button from '@mui/material/Button';
import Dialog from '../Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18n from 'ui-i18n';
import styles from './confirmation-dialog.less';
import {CONFIRMATION_DIALOG_TYPES, EDIT_MODE} from '../../constants/common';
import {Component} from 'react';
import {DraftMode} from '../../rdm-sdk/app.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {canSaveLookups} from '../../rdm-sdk/lookups';
import {connect} from 'react-redux';
import {discardDraftSaga, saveDraftSaga, suggestDraftSaga} from '../../redux/actions/draftMode';
import {hideConfirmationDialogEvent, resolveEvent} from '../../redux/actions/app';
type Props = {
    confirmationDialogType: string;
    canApplyResolver: boolean;
    resolve: (result: string) => void;
    hideDialog: () => void;
    draftMode: DraftMode;
    editMode?: string;
    dispatch: (e: StateEvent) => Promise<any>;
};

const getDescriptionByDialogType = (type) => {
    const descriptions = {
        [CONFIRMATION_DIALOG_TYPES.LEAVE_PAGE]: i18n.text(
            'You have made changes on this page. If you navigate away from this page without first saving your data, the changes will be lost.'
        ),
        [CONFIRMATION_DIALOG_TYPES.EXECUTE_ACTION]: i18n.text(
            'You have made changes on this page. If you execute the action without first saving your data, the changes will be lost.'
        ),
        [CONFIRMATION_DIALOG_TYPES.SHOW_DCR]: i18n.text(
            'You have made changes on this page. If you display the DCR without first saving your data, the changes will be lost.'
        )
    };
    return descriptions[type] || '';
};

const getTitleByEditMode = (editMode) =>
    editMode === EDIT_MODE.SUGGEST
        ? i18n.text('Do you want to suggest your changes?')
        : i18n.text('Do you want to save your changes?');

export class ConfirmationDialog extends Component<Props> {
    render() {
        const {confirmationDialogType, canApplyResolver, resolve, draftMode, editMode, dispatch, hideDialog} =
            this.props;
        return (
            <Dialog open={Boolean(confirmationDialogType)} className={styles.dialog}>
                <DialogTitle>{getTitleByEditMode(editMode)}</DialogTitle>
                <DialogContent>
                    <div className={styles.contentText}>{getDescriptionByDialogType(confirmationDialogType)}</div>
                </DialogContent>
                <DialogActions>
                    <Button variant="grey" onClick={R.pipe(hideDialog, R.always('cancel'), resolve)}>
                        {i18n.text('Cancel')}
                    </Button>
                    <div className="spacer" />
                    <Button
                        onClick={() => {
                            hideDialog();
                            discardDraftSaga(draftMode, dispatch).then(() => resolve('discard'));
                        }}
                    >
                        {i18n.text('Discard')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            hideDialog();
                            editMode === EDIT_MODE.SUGGEST
                                ? suggestDraftSaga(draftMode, dispatch).then(() => resolve('save'))
                                : saveDraftSaga(draftMode, dispatch).then(() => resolve('save'));
                        }}
                        disabled={!canApplyResolver}
                    >
                        {editMode === EDIT_MODE.SUGGEST ? i18n.text('Suggest') : i18n.text('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    canApplyResolver: canSaveLookups(state.lookupValues),
    confirmationDialogType: state.showConfirmationDialog,
    draftMode: state.draftMode,
    editMode: state.currentType ? state.editMode : ''
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    resolve: R.compose(dispatch, resolveEvent),
    hideDialog: R.compose(dispatch, hideConfirmationDialogEvent)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);
