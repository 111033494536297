import * as R from 'ramda';
import CustomIcon from '../CustomIcon/CustomIcon';
import CustomTable from '../CustomTable/CustomTable';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import actionCellRenderer from './actionCellRenderer';
import dateCellRenderer from '../CustomTable/dateCellRenderer';
import i18n from 'ui-i18n';
import sourceValuesCellRenderer from './sourceValuesCellRenderer';
import styles from './styles.less';
import typeNameCellRenderer from './typeNameCellRenderer';
import typesFilter from '../../pages/TypesPage/typesFilter';
import unmappedCountCellRenderer from '../CustomTable/unmappedCountCellRenderer';
import withFeatures from '../Feature/withFeatures';
import {CheckedPolicies} from '../../permissions/permissions.types';
import {Component} from 'react';
import {Configuration} from '../../rdm-sdk/configuration.types';
import {CustomTableHeaderProps} from '../CustomTable/CustomTable';
import {FEATURE} from '../../rdm-sdk/features';
import {FILTER_OPTIONS} from '../../constants/common';
import {Features} from '../../rdm-sdk/features.types';
import {LookupType} from '../../rdm-sdk/types.types';
import {SortDirection, Sorting} from '../../rdm-sdk/app.types';
import {StateEvent} from '../../rdm-sdk/state.types';
import {Stats} from '../../rdm-sdk/stats.types';
import {TypesSearchQuery} from '../../rdm-sdk/filters.types';
import {connect} from 'react-redux';
import {getTypeStats} from '../../rdm-sdk/stats';
import {logActivityCommand} from '../../redux/actions/activityLogging';
import {lookupTypesRemoveEvent} from '../../redux/actions/types';
import {noop, sortBy} from '../../core/util';
import {typeUriToCode} from '../../rdm-sdk/types';
import {typesHeaderRenderer} from '../../pages/TypesPage/typesHeaderRenderer';
const initialFilterOptions = [FILTER_OPTIONS.ALL, FILTER_OPTIONS.ENABLED];
const COLUMNS = [
    {
        label: i18n.text('Name'),
        flexGrow: 0.5,
        dataKey: 'label',
        width: 400,
        cellRenderer: typeNameCellRenderer
    },
    {
        label: i18n.text('Start date'),
        flexGrow: 0.15,
        dataKey: 'startDate',
        width: 140,
        cellRenderer: dateCellRenderer
    },
    {
        label: i18n.text('End date'),
        flexGrow: 0.15,
        dataKey: 'endDate',
        width: 140,
        cellRenderer: dateCellRenderer
    }
];
const VALUES_COUNT_COLUMN = {
    label: i18n.text('Source values'),
    flexGrow: 0.15,
    dataKey: 'valuesCount',
    width: 145,
    columnData: {
        textAlign: 'right'
    },
    cellRenderer: sourceValuesCellRenderer
};
const UNMAPPED_COLUMN = {
    label: i18n.text('Unmapped values'),
    flexGrow: 0.15,
    dataKey: 'unmappedCount',
    width: 180,
    columnData: {
        textAlign: 'right'
    },
    cellRenderer: unmappedCountCellRenderer
};
const ACTION_COLUMN = {
    label: '',
    flexGrow: 0,
    flexShrink: 0,
    width: 50,
    dataKey: 'action',
    cellRenderer: actionCellRenderer
};
const getCode = R.pipe(R.prop('uri'), typeUriToCode);
type Props = {
    dispatch: (e: StateEvent) => Promise<any>;
    policies: CheckedPolicies;
    configuration: Configuration;
    stats: Stats;
    typesSearchQuery: TypesSearchQuery;
    features: Features;
    toggleHierarchyMode: () => void;
    openTypeDialog: (arg0: LookupType) => void;
    openValues: (arg0: LookupType) => void;
};
type State = {
    sorting: Sorting;
    filter: string;
};
const logger = logActivityCommand('types-table');
export class TypesTable extends Component<Props, State> {
    state = {
        sorting: {
            field: 'label',
            direction: SortDirection.ASC
        },
        filter: FILTER_OPTIONS.ALL
    } as State;
    onTypeDelete = R.pipe(lookupTypesRemoveEvent, this.props.dispatch);

    render() {
        const {
            features,
            policies,
            stats: {valuesByTypes, unmappedByTypes, typesHasDCR},
            configuration: {lookupTypes},
            typesSearchQuery,
            openTypeDialog,
            openValues,
            toggleHierarchyMode,
            dispatch
        } = this.props;
        const {sorting, filter} = this.state;
        const typesData = R.pipe(
            R.map((lookupType) => ({
                ...lookupType,
                valuesCount: getTypeStats(lookupType, valuesByTypes),
                unmappedCount: getTypeStats(lookupType, unmappedByTypes),
                hasDCR: getTypeStats(lookupType, typesHasDCR)
            })),
            R.filter(typesFilter(typesSearchQuery, filter)),
            sortBy(sorting.field, sorting.direction)
        )(lookupTypes);
        const unmappedTypesTotal = R.pipe(
            R.filter(R.lt(0)),
            R.keys,
            R.intersection(R.map(getCode, typesData)),
            R.length
        )(unmappedByTypes);
        const columns = [
            ...COLUMNS,
            policies.lookupValuesRead ? VALUES_COUNT_COLUMN : false,
            unmappedTypesTotal ? UNMAPPED_COLUMN : false,
            ACTION_COLUMN
        ].filter(R.identity);
        const filterOptions = [...initialFilterOptions];
        !R.isEmpty(typesHasDCR) && filterOptions.push(FILTER_OPTIONS.HAS_DCR);
        unmappedTypesTotal && filterOptions.push(FILTER_OPTIONS.HAS_UNMAPPED);
        const typesHeader = typesHeaderRenderer({
            typesTotal: typesData.length,
            unmappedTypesTotal,
            logger,
            filter: {
                selected: filter,
                onChange: (selected) =>
                    this.setState({
                        filter: selected
                    }),
                options: filterOptions
            }
        });
        const headerRenderer = features[FEATURE.HIERARCHY]
            ? (params: CustomTableHeaderProps) => (
                  <div className={styles['types-table__header']}>
                      {typesHeader(params)}
                      {params.selected.length === 0 && (
                          <div className={styles['types-table__hierarchy-button']}>
                              <Tooltip title={i18n.text('Hierarchy view')}>
                                  <IconButton
                                      onClick={R.pipe(toggleHierarchyMode, logger('view-toggle-click'), dispatch)}
                                      size="large"
                                  >
                                      <CustomIcon name="hierarchy" />
                                  </IconButton>
                              </Tooltip>
                          </div>
                      )}
                  </div>
              )
            : typesHeader;
        return (
            <CustomTable
                isEditMode={policies.configEdit}
                logger={logger}
                primaryHeaderRenderer={headerRenderer}
                data={typesData}
                selectKey={'uri'}
                columns={columns}
                columnsData={{
                    isEditMode: policies.configEdit,
                    typesSearchQuery,
                    rowAction: openTypeDialog
                }}
                onDelete={this.onTypeDelete}
                sorting={{
                    sort: sorting,
                    onChange: (sorting) =>
                        this.setState({
                            sorting
                        })
                }}
                onRowClick={policies.lookupValuesRead ? R.pipe(R.prop('rowData'), openValues) : noop}
            />
        );
    }
}
const mapStateToProps = R.pick(['configuration', 'stats', 'typesSearchQuery', 'policies']);
export default R.pipe(withFeatures, connect(mapStateToProps))(TypesTable);
