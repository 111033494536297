import * as R from 'ramda';
import MultiSelect from '../../../ReactSelect/MultiSelect';
import i18n from 'ui-i18n';
import {SelectLookupTypeOption} from '../../activityLog.types';
import {connect} from 'react-redux';
import {getActivityLogFilterLookupTypes} from '../../../../redux/reducers/activityLogReducer';
import {setActivityLogFilterLookupTypesEvent} from '../../../../redux/actions/activityLog';
import {typeUriToCode} from '../../../../rdm-sdk/types';
const toLookupTypeOptions = R.map((lookupType) => ({
    label: lookupType.label,
    value: typeUriToCode(lookupType.uri),
    uri: lookupType.uri
}));
type Props = {
    options: SelectLookupTypeOption[];
    values: SelectLookupTypeOption[];
    onChange: (values: SelectLookupTypeOption[]) => void;
};
export function LookupTypeSelect(props: Props) {
    const {options, values, onChange} = props;
    return (
        <MultiSelect creatable label={i18n.text('Lookup type')} options={options} value={values} onChange={onChange} />
    );
}

const mapStateToProps = (state) => ({
    options: toLookupTypeOptions(state.configuration.lookupTypes),
    values: getActivityLogFilterLookupTypes(state)
});

const mapDispatchToProps = {
    onChange: setActivityLogFilterLookupTypesEvent
};
export default connect(mapStateToProps, mapDispatchToProps)(LookupTypeSelect);
