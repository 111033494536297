import * as R from 'ramda';
import {ColumnItem} from '../../rdm-sdk/grid.types';
import {FIXED_COLUMN_COUNT, LANGUAGES} from './Localization';
import {Props, State} from './Localization';
import {addHiddenColumn, removeHiddenColumn, updateHiddenColumns, updateHiddenColumnsOnDnD} from '../../rdm-sdk/grid';
import {changeOrder} from '../../core/util';
import {getLocalizationCodes} from '../../rdm-sdk/lookups';
export const localizationLanguagesUpdater = (state: State, props: Props) => {
    const hiddenLanguagesCodes = R.pipe(R.pluck('items'), R.flatten, R.pluck('code'))(state.hiddenColumns);
    const languageCodesBefore = R.pluck('code', state.languages);
    const languageCodes = getLocalizationCodes(props.lookupValues);
    const languageCodesDiff = R.reject(
        R.includes(R.__, [...languageCodesBefore, ...hiddenLanguagesCodes]),
        languageCodes
    );
    const languagesDiff = R.filter((lang) => languageCodesDiff.includes(lang.code), LANGUAGES);
    const newLanguageCodes = languageCodesBefore.concat(languageCodesDiff);
    const extraLanguages = R.reject(
        ({code}) => newLanguageCodes.includes(code) || hiddenLanguagesCodes.includes(code),
        LANGUAGES
    );
    return {
        languages: [...state.languages, ...languagesDiff],
        extraLanguages
    };
};
export const localizationColumnOrderUpdater = (sourceIndex: number, targetIndex: number) =>
    R.evolve({
        languages: changeOrder(sourceIndex - 1, targetIndex - 1),
        hiddenColumns: updateHiddenColumnsOnDnD(sourceIndex, targetIndex)
    });
export const localizationLanguageAddUpdater = (language: ColumnItem, index: number) =>
    R.evolve({
        languages: R.insert(index, language),
        extraLanguages: R.reject(R.eqProps('code', language)),
        hiddenColumns: R.unless(() => index === -1, updateHiddenColumns(1, index))
    });
export const localizationColumnHideUpdater = (label: string, columnIndex: number) => (state: State) => {
    const language = R.find(R.propEq('label', label), state.languages);
    return {
        hiddenColumns: addHiddenColumn(language, columnIndex, state.hiddenColumns),
        languages: R.reject(R.propEq('label', label), state.languages)
    };
};
export const localizationColumnShowUpdater = (columnIndex: number) => (state: State) => {
    const hiddenLanguages = R.pipe(R.find(R.propEq('index', columnIndex)), R.propOr([], 'items'))(state.hiddenColumns);
    const languages = [
        ...state.languages.slice(0, columnIndex - FIXED_COLUMN_COUNT),
        ...hiddenLanguages,
        ...state.languages.slice(columnIndex - FIXED_COLUMN_COUNT)
    ];
    return {
        languages: languages,
        hiddenColumns: removeHiddenColumn(columnIndex, state.hiddenColumns)
    };
};
export const localizationColumnResizeUpdater = (columnIndex: number, deltaX: number) =>
    R.evolve({
        languages: R.adjust(
            columnIndex - FIXED_COLUMN_COUNT,
            R.evolve({
                columnWidth: (width) => Math.max(width + deltaX, 64)
            })
        )
    });
