/**
 * Created by ndyumin on 10.10.2016.
 */
import * as R from 'ramda';
import i18n from 'ui-i18n';
import {commandCreator} from '../middlewares/command-middleware';
import {getTypeByCode} from '../../rdm-sdk/types';
import {json, requestWithBlockingSpinner} from '../../network';
import {markEdited, markNew, markRemoved, metadataReplacer} from '../../core/marks';
import {needMdmSyncResetEvent, showDisclaimerSnackbarCommand} from './ui';
import {push} from 'react-router-redux';
import {rejectRemovedSources, rejectRemovedTypes} from '../../rdm-sdk/configuration';
import {then} from '../../core/func';
export function tenantConfigurationEvent(configuration) {
    return {
        type: 'tenantConfiguration',
        configuration
    };
}
export function requestTenantConfigurationCommand(tenant) {
    return requestWithBlockingSpinner(`/rdm/configuration/${tenant}`, {}, i18n.text('Request configuration error'))
        .then(json)
        .then(
            R.evolve({
                sources: R.uniqBy(R.prop('uri'))
            })
        );
}
const redirectIfMissingCurrentTypeCommand = commandCreator(({state, dispatch}) => {
    const {currentType, tenant, configuration} = state;
    const missingCurrentType = currentType && !getTypeByCode(configuration, currentType);

    if (missingCurrentType) {
        dispatch(push(`/${tenant}/types`));
    }
});
export const resetTenantConfigurationCommand = commandCreator(({state}) => {
    const {tenant} = state;
    return requestTenantConfigurationCommand(tenant);
});
export const resetTenantConfigurationSaga = (dispatch) =>
    R.pipe(
        resetTenantConfigurationCommand,
        dispatch,
        then(
            R.pipe(
                tenantConfigurationEvent,
                dispatch,
                redirectIfMissingCurrentTypeCommand,
                dispatch,
                needMdmSyncResetEvent,
                dispatch
            )
        )
    );
export function addSourceEvent(source) {
    return {
        type: 'addSource',
        source: markNew(source)
    };
}
export function updateSourceEvent(source) {
    return {
        type: 'updateSource',
        source: markEdited(source)
    };
}
export function deleteSourcesEvent(sources) {
    return {
        type: 'deleteSources',
        sources: sources.map(markRemoved)
    };
}
export const updateTenantConfigurationCommand = commandCreator(({state}) => {
    const {configuration, tenant} = state;
    const configurationToSave = R.pipe(rejectRemovedTypes, rejectRemovedSources)(configuration);
    return requestWithBlockingSpinner(
        `/rdm/configuration/${tenant}`,
        {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(configurationToSave, metadataReplacer)
        },
        i18n.text('Configuration update error')
    ).then(json);
});
export const updateTenantConfigurationSaga = (dispatch) =>
    R.pipe(
        updateTenantConfigurationCommand,
        dispatch,
        then(R.pipe(tenantConfigurationEvent, dispatch, showDisclaimerSnackbarCommand, dispatch))
    );
