import {ReactElement} from 'react';

type Props = {
    selectProps: {
        classes: {
            valueContainer: string;
        };
    };
    children: ReactElement;
};

export default function ValueContainer(props: Props) {
    const {
        selectProps: {classes},
        children
    } = props;
    return <div className={classes.valueContainer}>{children}</div>;
}
