import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import skinComponents from './components';
import useStyles, {StyleProps} from './styles';
import {useState} from 'react';
let id = 0;

const getInputId = () => `custom-select-${id++}`;

type Action = {
    action: string;
};
type Props<V> = {
    label: string;
    creatable: boolean;
    onChange: (value: V[], action?: Action) => void;
    onInputChange: (value: string, action: Action) => void;
    components: any[];
    options: V[];
    placeholder?: string;
    value?: V[];
    isLoading?: boolean;
    filterOptions?: (option: V) => boolean;
    isValidNewOption?: (option: V) => boolean;
};

function MultiSelect<V>(props: Props<V>) {
    const {
        label,
        creatable,
        onChange: onChangeProp,
        onInputChange: onInputChangeProp,
        components: componentsProp,
        ...reactSelectProps
    } = props;
    const SelectComponent = creatable ? CreatableSelect : Select;
    const [inputValue, setInputValue] = useState('');
    const classes = useStyles(props as StyleProps);
    const inputId = getInputId();
    const styles = {
        indicatorsContainer: (base) => ({...base, position: 'relative', top: -7}),
        indicatorSeparator: (base) => ({...base, marginBottom: 4, marginTop: 4}),
        loadingIndicator: (base) => ({...base, display: 'none'})
    };
    const components = {...skinComponents, ...componentsProp};

    function handleInputChange(value, {action}) {
        onInputChangeProp(value, {
            action
        });

        if (action !== 'set-value') {
            setInputValue(value);
        }
    }

    function handleChange(value, {action}) {
        onChangeProp(value || [], {
            action
        });

        if (action === 'create-option') {
            setInputValue('');
            onInputChangeProp('', {
                action: 'input-change'
            });
        }
    }

    return (
        <div className={classes.container}>
            <SelectComponent
                isMulti
                inputId={inputId}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={handleChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={components}
                classes={classes}
                styles={styles}
                TextFieldProps={{
                    label,
                    InputLabelProps: {
                        htmlFor: inputId,
                        shrink: reactSelectProps?.value?.length ? true : undefined,
                        className: classes.label
                    },
                    InputProps: {
                        classes: {
                            formControl: classes.formControl
                        }
                    }
                }}
                menuPlacement="auto"
                {...reactSelectProps}
            />
        </div>
    );
}

MultiSelect.defaultProps = {
    creatable: false,
    components: {},
    onInputChange: () => {},
    onChange: () => {}
};
export default MultiSelect;
