import Tag from '../../Tag/Tag';
import clsx from 'clsx';
import styles from './styles.less';
import {CustomChangeType} from '../activityLog.types';

function CustomChange(props: CustomChangeType) {
    const {displayName, children, type, className, postfix = ':'} = props;
    return (
        <div className={clsx(styles['change'], className)}>
            <span className={styles['change__display-name']}>
                {displayName}
                {postfix}
            </span>
            {children}
            <Tag type={type} />
        </div>
    );
}

export default CustomChange;
