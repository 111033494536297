import clsx from 'clsx';
import listStyles from '../AddGridColumnMenu/add-grid-column-menu.less';
export type MenuItemProps = {
    isVisible?: boolean;
    onClick: (...args: any[]) => any;
    children: string;
    className?: string;
};

function MenuItem({isVisible = true, onClick, children, className}: MenuItemProps) {
    return isVisible ? (
        <li className={clsx(listStyles['extra-items-list__item'], className)} onClick={onClick}>
            {children}
        </li>
    ) : null;
}

export default MenuItem;
