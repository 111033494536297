import * as R from 'ramda';
import {
    CollaborationObjectType,
    CollaborationUpdate,
    Comment,
    CommentData,
    NewReply,
    ReplyAction
} from './collaboration.types';
import {LookupValue} from './lookups.types';
import {checkClientOnly} from '../core/marks';
import {curry} from '../core/func';
import {typeCodeToUri} from './types';
export const RESOLVE_REPLY: NewReply = {
    content: '',
    namedUsers: [],
    action: ReplyAction.RESOLVE
};
export const getLookupValueObjectId = curry((type, code) => `${CollaborationObjectType.LOOKUP_VALUE}/${type}/${code}`);
export const getMappingGroupObjectId = curry(
    (type, code, source) => `${CollaborationObjectType.MAPPING_GROUP}/${type}/${code}/${source}`
);
export const getLookupValueRelatedObjectUri = (type: string) => `${typeCodeToUri(type)}/value`;
export const getMappingRelatedObjectUri = (type: string) => `${typeCodeToUri(type)}/mappingGroup`;
export const getCodeFromObjectId = (objectId: string): string => objectId.split('/')[3];
export const getTypeFromLookupValueObjectId = (objectId: string): string => objectId.split('/')[2];
export const getObjectId = R.curry((objectType: string, currentType: string, data: CommentData) => {
    switch (objectType) {
        case CollaborationObjectType.LOOKUP_VALUE:
            return getLookupValueObjectId(currentType, data.code);

        case CollaborationObjectType.MAPPING_GROUP:
            return getMappingGroupObjectId(currentType, data.code, data.source);

        default:
            return null;
    }
});
export const getCommentPermlink = R.curry((objectType: string, data: CommentData) => {
    switch (objectType) {
        case CollaborationObjectType.LOOKUP_VALUE:
            return getValueCommentPermlink(data?.code);

        case CollaborationObjectType.MAPPING_GROUP:
            return getMappingGroupCommentPermlink(data?.code, data.source);

        default:
            return null;
    }
});
export const getRelatedObjectUri = R.curry((objectType: string, type: string) => {
    switch (objectType) {
        case CollaborationObjectType.LOOKUP_VALUE:
            return getLookupValueRelatedObjectUri(type);

        case CollaborationObjectType.MAPPING_GROUP:
            return getMappingRelatedObjectUri(type);

        default:
            return null;
    }
});
export const getLookupValueObjectIds = (currentType: string, lookupValues: LookupValue[]) =>
    R.pipe(R.reject(checkClientOnly), R.map(R.pipe(R.prop('code'), getLookupValueObjectId(currentType))))(lookupValues);
export const getMappingGroupObjectIds = (currentType: string, lookupValues: LookupValue[]) =>
    R.pipe(
        R.reject(checkClientOnly),
        R.chain(
            R.pipe(
                R.pick(['code', 'sources']),
                R.evolve({
                    sources: R.keys
                }),
                ({code, sources = []}) => R.map(getMappingGroupObjectId(currentType, code), sources)
            )
        )
    )(lookupValues);
export const getObjectIds = (objectType: string, currentType: string, lookupValues: LookupValue[]) => {
    switch (objectType) {
        case CollaborationObjectType.LOOKUP_VALUE:
            return getLookupValueObjectIds(currentType, lookupValues);

        case CollaborationObjectType.MAPPING_GROUP:
            return getMappingGroupObjectIds(currentType, lookupValues);

        default:
            return null;
    }
};
export const toCommentsCountDict = R.reduce(
    (commentsCountDict, commentCount) =>
        Object.assign({}, commentsCountDict, {
            [commentCount.objectId]: commentCount.comments
        }),
    {}
);
export const getPrefixedEmailRegEx = (prefix: string) => RegExp(`${'\\' + prefix}\\S+@\\S+\\.[A-Za-z]+`, 'g');

type ReplaceEmailPrefix = (oldPrefix: string, newPrefix: string, content: string) => string;
export const replaceEmailPrefix: ReplaceEmailPrefix = R.curry((oldPrefix, newPrefix, content) => {
    const regEx = getPrefixedEmailRegEx(oldPrefix);

    const replacer = (match) => newPrefix + match.slice(oldPrefix.length);

    return content.replace(regEx, replacer);
});
export const getNamedUsers = curry((prefix, content) => {
    const regEx = getPrefixedEmailRegEx(prefix);
    return R.pipe(R.match(regEx), R.map(R.slice(prefix.length, Infinity)))(content);
});
export const getMentions = getNamedUsers('@');
export const getCommentUpdate = (comment: Comment, content: string): CollaborationUpdate => ({
    content: content,
    namedUsers: getMentions(content),
    visibility: comment.visibility
});
export const getReopenReply = (content = '') => ({
    content: content,
    namedUsers: getMentions(content),
    action: 'reopen'
});
export const getValueCommentPermlink = (code?: string) => {
    return `${window.location.href}/${encodeURIComponent(code || '')}`;
};
export const getMappingGroupCommentPermlink = (code?: string, source?: string) => {
    return `${window.location.href}/${encodeURIComponent(code || '')}/${encodeURIComponent(source || '')}`;
};
export const isSuggestorElement = (target: Record<string, any>) => {
    return target && typeof target.className === 'string' && target.className.includes('suggestor');
};
