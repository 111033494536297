import i18n from 'ui-i18n';
import languages from '../components/Localization/languagesMock';
import {ACTIVITY_TYPE} from '../components/ActivityLog/constants';
import {WorkflowChangeTypes} from '../components/Workflow/constants';
import {WorkflowDCRType} from '../components/Workflow/workflow.types';
import {
    __,
    assoc,
    both,
    chain,
    curry,
    eqBy,
    eqProps,
    equals,
    evolve,
    filter,
    find,
    flatten,
    has,
    head,
    identity,
    isEmpty,
    last,
    lensProp,
    map,
    mergeDeepLeft,
    over,
    path,
    pathOr,
    pick,
    pipe,
    pluck,
    prop,
    propEq,
    propOr,
    reduce,
    reject,
    remove,
    split,
    unless,
    update,
    whereEq
} from 'ramda';
import {getType, parseLookupDelta} from './activities';
import {isNotEmpty} from '../core/util';
import {markEdited, markNew, markRemoved} from '../core/marks';
import {markLookupValueRemoved} from './lookups';
import {typeCodeToUri} from './types';

const filterLookupType = (item) => !/rdm\/lookupTypes\//.test(item);

export const parseWorkflowTasks = over(
    lensProp('data'),
    map((task) => {
        const {
            assignee,
            objectURIs,
            dueDate,
            taskId,
            displayName: step,
            processDefinitionDisplayName: name,
            processInstanceComments: comments,
            possibleActions,
            priority,
            repeatingTask,
            valid,
            createTime,
            createdBy
        } = task;
        return {
            id: taskId,
            taskID: taskId,
            step,
            name,
            assignee,
            comments: (comments || []).reverse(),
            dueDate,
            objectURIs: objectURIs.filter(filterLookupType),
            possibleActions,
            priority,
            repeatingTask,
            valid,
            createTime,
            createdBy
        };
    })
);

const getCanonicalValueByUri = (key, info) =>
    pathOr('', [key, 'canonicalValue'], info) || pathOr('', [key, 'value'], info);

const transformTypeToActivityType = (rawType) => {
    const typeRelations = {
        CREATE: 'ADDED',
        INSERT: 'ADDED',
        UPDATE: 'CHANGED',
        DELETE: 'DELETED'
    };
    const relationKey = Object.keys(typeRelations).find((key) => rawType.includes(key));
    return relationKey ? rawType.replace(relationKey, typeRelations[relationKey]) : rawType;
};

const getItemType = pipe(prop('type'), getType);

const getCommonType = (items) => {
    const hasEqualTypes = items.every((item, i, items) => i === 0 || eqBy(getItemType, item, items[i - 1]));
    return hasEqualTypes ? getItemType(items[0]) : ACTIVITY_TYPE.CHANGE;
};

const getCommonWFType = (items) => {
    const hasEqualTypes = items.every((item, i, items) => i === 0 || eqProps('type', item, items[i - 1]));
    return hasEqualTypes ? prop('type', items[0]) : '';
};

export const transformType = evolve({
    changes: map(map(over(lensProp('type'), transformTypeToActivityType)))
});
const getChangesFirstId = path([0, 'id']);

const isNotEmptyChanges = (changes) => (lookupId) => !isEmpty(changes[lookupId]);

const transformChangesToInnerFormat = map((item) => ({
    ...item,
    attributeType: prop('path', item),
    sourceMappings: prop('source', item)
}));
const getMappingCanonicalValue = pipe(prop('values'), find(propEq('canonicalValue', true)), prop('value'));
const getNewLookupCanonicalValue = pipe(
    prop('sourceMappings'),
    map(getMappingCanonicalValue),
    filter(identity),
    propOr('', 0)
);

const getSourceForUnmappedValue = (id) => {
    const [, , source] = id.split('/');
    return {
        value: {
            sourceMappings: [
                {
                    source,
                    values: []
                }
            ]
        }
    };
};

const isMappingWorkflowType = (type) => type.includes('MAPPING');

const isDeleteType = (type) => type.includes('DELETE');

const getGroupedChangesIds = (items, isNewLookupValue) => {
    const newLookupGroupsIds = isNewLookupValue ? [getChangesFirstId(items)] : [];
    const mappingsGroupsIds = items.reduce(
        (ids, {type, id}) => (isMappingWorkflowType(type) && !isDeleteType(type) ? ids.concat([id]) : ids),
        []
    );
    return newLookupGroupsIds.concat(mappingsGroupsIds);
};

const filterItemsHasConflict = filter(has('conflict'));
const getChangesConflictDetails = pipe(filterItemsHasConflict, map(pick(['id', 'conflictDetails'])));
const getCodeFromUri = pipe(split('/'), last);
const getMappingsWithChangedCanonicalValue = pipe(
    filter(pipe(prop('type'), isMappingWorkflowType)),
    filter((item) => {
        const isCanonicalBefore = pathOr(false, ['oldValue', 'canonicalValue'], item);
        const isCanonicalAfter = pathOr(false, ['newValue', 'canonicalValue'], item);
        return isCanonicalBefore !== isCanonicalAfter;
    })
);
const getMappingsWithConflict = pipe(filter(pipe(prop('type'), isMappingWorkflowType)), filterItemsHasConflict);

const expandMappingConflicts = (items) => {
    const mappingsChangedCanonicalValue = getMappingsWithChangedCanonicalValue(items);
    const mappingsHaveConflict = getMappingsWithConflict(items);
    return !isEmpty(mappingsChangedCanonicalValue) && !isEmpty(mappingsHaveConflict)
        ? items.map(
              unless(
                  has('conflict'),
                  mergeDeepLeft(pipe(head, pick(['conflictDetails', 'conflict']))(mappingsHaveConflict))
              )
          )
        : items;
};

export const parseDCR = (DCR) => {
    const {changes, objectsInfo, id} = DCR;
    return flatten(
        Object.keys(changes)
            .filter(isNotEmptyChanges(changes))
            .map((lookupId) => {
                const items = pipe(transformChangesToInnerFormat, expandMappingConflicts)(changes[lookupId]);
                const changesIds = pluck('id', items);
                const changesConflicts = getChangesConflictDetails(items);
                const isNewLookupValue = pipe(head, prop('type'), equals(WorkflowChangeTypes.ADDED_LOOKUP_TYPE))(items);
                const getNewLookupValue = pathOr({}, [0, 'newValue']);
                const addedLookupValue = isNewLookupValue ? getNewLookupValue(items) : null;
                const sourceMappingsObject = isNewLookupValue
                    ? {
                          value: addedLookupValue
                      }
                    : {
                          delta: items
                      };
                const workflowChangeType = getCommonWFType(items);
                const isDeleteUnmappedValue = workflowChangeType === WorkflowChangeTypes.DELETED_UNMAPPED_VALUE;
                const unmappedSourceMappings = isDeleteUnmappedValue ? getSourceForUnmappedValue(lookupId) : null;
                const lookupCode = getCodeFromUri(lookupId);
                const label = isNewLookupValue
                    ? getNewLookupCanonicalValue(addedLookupValue)
                    : getCanonicalValueByUri(lookupId, objectsInfo) || lookupCode;
                const sourceChangeMessage = isDeleteUnmappedValue ? i18n.text('Deleted from unmapped values') : '';
                return {
                    id: getChangesFirstId(items),
                    sourceChangeMessage,
                    activityId: id,
                    DCRId: id,
                    type: getCommonType(items),
                    workflowChangeType,
                    label,
                    changesIds,
                    changesConflicts,
                    lookupCode,
                    groupedChangesIds: getGroupedChangesIds(items, isNewLookupValue),
                    ...parseLookupDelta(items, unmappedSourceMappings || sourceMappingsObject, addedLookupValue)
                };
            })
    );
};
export const collectDCRs = chain(pipe(transformType, parseDCR));

const isLookupChangedInDCR = (DCR) => {
    const {attributes, localizations, fields, parents, children} = DCR;
    return attributes.length || fields.length || localizations.length || parents.length || children.length;
};

export const getMarkingFunctionByDCR = (DCR: WorkflowDCRType = {} as WorkflowDCRType) => {
    const {workflowChangeType} = DCR;

    switch (workflowChangeType) {
        case WorkflowChangeTypes.ADDED_LOOKUP_TYPE:
            return markNew;

        case WorkflowChangeTypes.DELETED_LOOKUP_TYPE:
            return markLookupValueRemoved;

        default:
            return isLookupChangedInDCR(DCR) ? markEdited : identity;
    }
};

const rejectChangesWithConflicts = (conflicts, changes = []) => {
    const findConflictChangeById = (id) => conflicts.find(propEq('id', id));

    return reject(pipe(propOr('', 'id'), findConflictChangeById), changes);
};

const applyDCRAttributesToLookup = curry((DCR, lookupValue) => {
    const DCRAttributes = DCR.attributes || [];
    const conflicts = DCR.changesConflicts || [];
    const DCRAttributesWithoutConflicts = rejectChangesWithConflicts(conflicts, DCRAttributes);
    const attributes = lookupValue.attributes || [];

    const findAttributeIndex = ({name, value}, attributes) =>
        attributes.findIndex(both(propEq('name', name), propEq('value', value)));

    return assoc(
        'attributes',
        DCRAttributesWithoutConflicts.reduce((attributes, attribute) => {
            const {type, newValue: value, oldValue, name} = attribute;

            switch (type) {
                case ACTIVITY_TYPE.ADD: {
                    return attributes.concat({
                        value,
                        name
                    });
                }

                case ACTIVITY_TYPE.DELETE: {
                    const index = findAttributeIndex(
                        {
                            value: oldValue,
                            name
                        },
                        attributes
                    );
                    return index > -1 ? remove(index, 1, attributes) : attributes;
                }

                case ACTIVITY_TYPE.CHANGE: {
                    const index = findAttributeIndex(
                        {
                            value: oldValue,
                            name
                        },
                        attributes
                    );
                    return index > -1
                        ? update(
                              index,
                              {
                                  value,
                                  name
                              },
                              attributes
                          )
                        : attributes;
                }

                default:
                    return attributes;
            }
        }, attributes),
        lookupValue
    );
});
const applyDCRLocalizationsToLookup = curry((DCR, lookupValue) => {
    const DCRLocalizations = DCR.localizations || [];
    const conflicts = DCR.changesConflicts || [];
    const DCRLocalizationsWithoutConflicts = rejectChangesWithConflicts(conflicts, DCRLocalizations);
    const localizations = lookupValue.localizations || [];
    const getLanguageCodeByName = pipe(propEq('label'), find(__, languages), prop('code'));

    const findLocalizationIndex = ({languageCode, value}, localizations) =>
        localizations.findIndex(
            whereEq({
                languageCode,
                value
            })
        );

    return assoc(
        'localizations',
        DCRLocalizationsWithoutConflicts.reduce((localizations, localization) => {
            const {type, newValue: value, oldValue, name} = localization;
            const languageCode = getLanguageCodeByName(name) || name;

            switch (type) {
                case ACTIVITY_TYPE.ADD: {
                    return localizations.concat(
                        markNew({
                            value,
                            languageCode
                        })
                    );
                }

                case ACTIVITY_TYPE.DELETE: {
                    const index = findLocalizationIndex(
                        {
                            value: oldValue,
                            languageCode
                        },
                        localizations
                    );
                    return index > -1 ? update(index, markRemoved(localizations[index]), localizations) : localizations;
                }

                case ACTIVITY_TYPE.CHANGE: {
                    const index = findLocalizationIndex(
                        {
                            value: oldValue,
                            languageCode
                        },
                        localizations
                    );
                    return index > -1
                        ? update(
                              index,
                              markEdited({
                                  value,
                                  languageCode
                              }),
                              localizations
                          )
                        : localizations;
                }

                default:
                    return localizations;
            }
        }, localizations),
        lookupValue
    );
});
const applyDCRFieldsToLookup = curry((DCR, lookupValue) => {
    const DCRFields = DCR.fields || [];
    const conflicts = DCR.changesConflicts || [];
    const DCRFieldsWithoutConflicts = rejectChangesWithConflicts(conflicts, DCRFields);
    return DCRFieldsWithoutConflicts.reduce((lookupValue, field) => {
        const {name, newValue: value} = field;
        return name !== 'canonical' ? assoc(name, value, lookupValue) : lookupValue;
    }, lookupValue);
});

const applyDCRMappings = (DCRMappings = [], mappings = []) => {
    const findMappingIndex = ({canonicalValue, code, description, downStreamDefaultValue, enabled, value}, mappings) =>
        mappings.findIndex(
            whereEq({
                canonicalValue,
                code,
                description,
                downStreamDefaultValue,
                enabled,
                value
            })
        );

    const addedMappings = DCRMappings.reduce((mappings, mapping) => {
        const {type, newValue: value} = mapping;

        switch (type) {
            case ACTIVITY_TYPE.ADD: {
                return mappings.concat(markNew(value));
            }

            default:
                return mappings;
        }
    }, []);
    const changedMappings = DCRMappings.reduce((mappings, mapping) => {
        const {type, newValue: value, oldValue} = mapping;

        switch (type) {
            case ACTIVITY_TYPE.DELETE: {
                const index = findMappingIndex(oldValue, mappings);
                return index > -1 ? update(index, markRemoved(mappings[index]), mappings) : mappings;
            }

            case ACTIVITY_TYPE.CHANGE: {
                const index = findMappingIndex(oldValue, mappings);
                return index > -1 ? update(index, markEdited(value), mappings) : mappings;
            }

            default:
                return mappings;
        }
    }, mappings);
    return changedMappings.concat(addedMappings);
};

const applyDCRSourcesToLookup = curry((DCR, lookupValue) => {
    const conflicts = DCR.changesConflicts || [];
    const sources = lookupValue.sources || [];
    const DCRSources = DCR.sources || [];
    return assoc(
        'sources',
        DCRSources.reduce((sources, DCRsource) => {
            const getMappingsBySource = ({sourceAbbreviation, sources}) => propOr([], sourceAbbreviation, sources);

            const DCRMappings = DCRsource.mappings;
            const DCRMappingsWithoutConflicts = rejectChangesWithConflicts(conflicts, DCRMappings);
            const sourceAbbreviation = DCRsource.source;
            const mappings = getMappingsBySource({
                sourceAbbreviation,
                sources
            });
            const newMappings = applyDCRMappings(DCRMappingsWithoutConflicts, mappings);
            return assoc(sourceAbbreviation, newMappings, sources);
        }, sources),
        lookupValue
    );
});
const applyDCRCanonicalToLookup = curry((DCR, lookupValue) => {
    const conflicts = DCR.changesConflicts || [];
    const hasConflicts = isNotEmpty(conflicts);
    const DCRSources = DCR.sources || [];
    const canonicalValue = DCRSources.reduce((canonicalValue, DCRsource) => {
        const DCRMappings = DCRsource.mappings;
        return pipe(
            rejectChangesWithConflicts,
            reduce((canonicalValue, {newValue}) => {
                return newValue && newValue.canonicalValue ? newValue.value : canonicalValue;
            }, canonicalValue)
        )(conflicts, DCRMappings);
    }, '');
    return canonicalValue && !hasConflicts ? assoc('canonical', canonicalValue, lookupValue) : lookupValue;
});
const applyDCRCodeToLookup = curry((DCR, lookupValue) => {
    const workflowChangeType = DCR.workflowChangeType;
    const lookupCode = prop('lookupCode', DCR);
    return workflowChangeType === WorkflowChangeTypes.ADDED_LOOKUP_TYPE
        ? assoc('code', lookupCode, lookupValue)
        : lookupValue;
});
export const applyDCRToLookup = (DCR, lookupValue) => {
    const workflowChangeType = DCR.workflowChangeType;
    if (isEmpty(lookupValue) && workflowChangeType !== WorkflowChangeTypes.ADDED_LOOKUP_TYPE) return null;
    const newLookupValue = pipe(
        applyDCRAttributesToLookup(DCR),
        applyDCRLocalizationsToLookup(DCR),
        applyDCRFieldsToLookup(DCR),
        applyDCRSourcesToLookup(DCR),
        applyDCRCanonicalToLookup(DCR),
        applyDCRCodeToLookup(DCR)
    )(lookupValue);
    return newLookupValue;
};

const applyDCRToParents = (DCR, dependencies) => {
    const conflicts = DCR.changesConflicts || [];
    const parents = DCR.parents || [];
    const parentsWithoutConflicts = rejectChangesWithConflicts(conflicts, parents);
    return applyDCRDepsToDependencies(parentsWithoutConflicts, dependencies);
};

const applyDCRToChildren = (DCR, dependencies) => {
    const conflicts = DCR.changesConflicts || [];
    const children = DCR.children || [];
    const childrenWithoutConflicts = rejectChangesWithConflicts(conflicts, children);
    return applyDCRDepsToDependencies(childrenWithoutConflicts, dependencies);
};

const applyDCRDepsToDependencies = (DCRDependencies = [], dependencies) => {
    const findParentIndex = ({uri}, dependencies) => dependencies.findIndex(propEq('uri', uri));

    if (!DCRDependencies.length) return [];
    return DCRDependencies.reduce((dependencies, dependency) => {
        const {type, newValue: lookupCode, oldValue: oldLookupCode, name: lookupType} = dependency;

        switch (type) {
            case ACTIVITY_TYPE.ADD: {
                return dependencies.concat({
                    uri: `${typeCodeToUri(lookupType)}/${lookupCode}`
                });
            }

            case ACTIVITY_TYPE.DELETE: {
                const index = findParentIndex(
                    {
                        uri: `${typeCodeToUri(lookupType)}/${oldLookupCode}`
                    },
                    dependencies
                );
                return remove(index, 1, dependencies);
            }

            default:
                return dependencies;
        }
    }, dependencies);
};

export const applyDCRToDependencies = (DCR, {parents, dependent}) => {
    const newParents = applyDCRToParents(DCR, parents);
    const newDependent = applyDCRToChildren(DCR, dependent);
    const depsParents = {
        data: newParents || parents
    };
    const depsDependents = {
        data: newDependent || dependent
    };
    return markEdited({
        parents: markEdited(depsParents),
        dependent: markEdited(depsDependents)
    });
};
