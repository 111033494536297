import Typography from '@mui/material/Typography';
import {ReactNode} from 'react';

type Props = {
    children: ReactNode;
    selectProps: {
        classes: {
            noOptionsMessage: string;
        };
    };
};
export default function NoOptionsMessage(props: Props) {
    const {
        selectProps: {classes},
        children
    } = props;
    return (
        <Typography color="textSecondary" className={classes.noOptionsMessage}>
            {children}
        </Typography>
    );
}
