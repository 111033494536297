import * as R from 'ramda';
import CacheContext from './CacheContext';
import {Component, ReactElement} from 'react';

type Props = {
    children: ReactElement | null;
};
export default class CacheProvider extends Component<Props> {
    cache = {};
    cacheContext = {
        getCacheData: (key) => this.cache[key],
        setCacheData: R.curry((key, value) => (this.cache[key] = value))
    };

    render() {
        return <CacheContext.Provider value={this.cacheContext}>{this.props.children}</CacheContext.Provider>;
    }
}
