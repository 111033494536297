import * as R from 'ramda';
import {RequestBuilder} from '../../rdm-sdk/network';
import {SortDirection} from '../../rdm-sdk/app.types';
import {buildActivityLogFilter} from '../../rdm-sdk/filters';
import {collectActivities} from '../../rdm-sdk/activities';
import {commandCreator} from '../middlewares/command-middleware';
import {
    getActivitiesPagination,
    getActivityLogFilters,
    getActivityLogTenant,
    getRDMTenantName
} from '../reducers/activityLogReducer';
import {json, requestWithBlockingSpinner} from '../../network';
import {then} from '../../core/func';
export function activitiesReceivedEvent(activities) {
    return {
        type: 'activitiesReceived',
        activities
    };
}
export function resetActivitiesEvent() {
    return {
        type: 'resetActivities'
    };
}
export function setActivitiesTotalEvent(total) {
    return {
        type: 'setActivitiesTotal',
        total
    };
}
export function resetActivitiesTotalEvent() {
    return {
        type: 'resetActivitiesTotal'
    };
}
export function setActivitiesPaginationEvent(pagination) {
    return {
        type: 'setActivitiesPagination',
        pagination
    };
}
export function setActivitiesFirstPageEvent() {
    return {
        type: 'setActivitiesFirstPage'
    };
}
export function resetActivitiesPaginationEvent() {
    return {
        type: 'resetActivitiesPagination'
    };
}
export function setActivityLogFilterActivityTypesEvent(activityTypes) {
    return {
        type: 'setActivityLogFilterActivityTypes',
        activityTypes
    };
}
export function setActivityLogFilterUsersEvent(users) {
    return {
        type: 'setActivityLogFilterUsers',
        users
    };
}
export function setActivityLogFilterStartDateEvent(startDate) {
    return {
        type: 'setActivityLogFilterStartDate',
        startDate
    };
}
export function setActivityLogFilterEndDateEvent(endDate) {
    return {
        type: 'setActivityLogFilterEndDate',
        endDate
    };
}
export function setActivityLogFilterSourcesEvent(sources) {
    return {
        type: 'setActivityLogFilterSources',
        sources
    };
}
export function setActivityLogFilterLookupValuesEvent(lookupValues) {
    return {
        type: 'setActivityLogFilterLookupValues',
        lookupValues
    };
}
export function setActivityLogFilterLookupTypesEvent(lookupTypes) {
    return {
        type: 'setActivityLogFilterLookupTypes',
        lookupTypes
    };
}
export function resetActivityLogFiltersEvent() {
    return {
        type: 'resetActivityLogFilters'
    };
}
export function setActivityLogFilterAppliedEvent() {
    return {
        type: 'setActivityLogFilterApplied'
    };
}
export function setActivityLogTenantEvent(tenant) {
    return {
        type: 'setActivityLogTenant',
        tenant
    };
}
// Commands
export const requestActivitiesCommand = commandCreator(({state}, objectType) => {
    const {currentType} = state;
    const activityLogTenant = getActivityLogTenant(state);
    const {rowsPerPage, page} = getActivitiesPagination(state);
    const tenantName = getRDMTenantName(state);
    const filter = buildActivityLogFilter(objectType, currentType, getActivityLogFilters(state), tenantName);
    const req = new RequestBuilder(`/activityLog/tenants/${activityLogTenant}/activities`)
        .addParam('offset', page * rowsPerPage)
        .addParam('max', rowsPerPage)
        .addParam('order', SortDirection.DESC)
        .addParam('filter', filter)
        .build();
    return requestWithBlockingSpinner(req)
        .then(json)
        .then(collectActivities(objectType, currentType))
        .catch((error) => {
            console.error('Request activities error:', error);
            return [];
        });
});
export const requestActivitiesTotalCommand = commandCreator(({state}, objectType) => {
    const {currentType} = state;
    const activityLogTenant = getActivityLogTenant(state);
    const tenantName = getRDMTenantName(state);
    const filter = buildActivityLogFilter(objectType, currentType, getActivityLogFilters(state), tenantName);
    const req = new RequestBuilder(`/activityLog/tenants/${activityLogTenant}/activities/_total`)
        .addParam('filter', filter)
        .build();
    return requestWithBlockingSpinner(req)
        .then(json)
        .catch((error) => {
            console.error('Request activities total error:', error);
            return {
                total: 0
            };
        });
});
export const requestTenantPropertiesCommand = commandCreator(({state}) => {
    const {tenant} = state;
    return requestWithBlockingSpinner(`/rdm/configuration/${tenant}/_properties`, {})
        .then(json)
        .catch((error) => {
            console.error('Request tenant properties error', error);
            return null;
        });
});
// Sagas
export const requestActivitiesSaga = (dispatch) =>
    R.pipe(requestActivitiesCommand, dispatch, then(R.pipe(activitiesReceivedEvent, dispatch)));
export const requestActivitiesTotalSaga = (dispatch) =>
    R.pipe(requestActivitiesTotalCommand, dispatch, then(R.pipe(R.prop('total'), setActivitiesTotalEvent, dispatch)));
export const requestActivityLogTenantSaga = (dispatch) =>
    R.pipe(
        requestTenantPropertiesCommand,
        dispatch,
        then(R.pipe(R.prop('activityLogTenant'), setActivityLogTenantEvent, dispatch))
    );
