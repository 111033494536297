import * as R from 'ramda';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import i18n from 'ui-i18n';
import styles from './styles.less';
import uploadFileSvg from '../../assets/inline/uploadFile.svg';
import {Component} from 'react';
type Props = {
    onUpload: (files: FileList) => void;
    files: File[];
    accept?: string;
    multiple?: boolean;
    errorMessage?: string | null;
};

class FileUploadForm extends Component<Props> {
    static defaultProps = {
        accept: '.csv',
        multiple: false
    };

    render() {
        const {files, accept, multiple, errorMessage} = this.props;
        const fileNames = R.pipe(R.pluck('name'), R.join(','))(files);
        return (
            <div className={styles['file-upload-form']} onDrop={this.handleFileUpload} onDragOver={this.handleDragOver}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: uploadFileSvg
                    }}
                />

                <Typography variant="h6">{i18n.text('Drop file here')}</Typography>

                <div className={styles['divider-container']}>
                    <Divider />
                    <span className={styles['divider-text']}>{i18n.text('or')}</span>
                </div>

                <div className={styles['file-uploader']}>
                    <Button variant="contained" color="primary" className={styles['upload-button']}>
                        {i18n.text('BROWSE FILE')}
                    </Button>

                    <div className={clsx(styles['file-name'], Boolean(errorMessage) && styles['file-name--error'])}>
                        {fileNames || i18n.text('No file chosen')}
                    </div>

                    <div className={styles['file-input-wrapper']}>
                        <input
                            type="file"
                            className={styles['file-input']}
                            onChange={this.handleFileUpload}
                            name="files"
                            accept={accept}
                            multiple={multiple}
                        />
                    </div>
                </div>
                <div className={styles['error-message']}>{errorMessage}</div>
            </div>
        );
    }

    handleFileUpload = (event: Record<string, any>) => {
        event.stopPropagation();
        event.preventDefault();
        const files = event.target.files || event.dataTransfer.files;
        this.props.onUpload(files);
    };
    handleDragOver = (event: Record<string, any>) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    };
}

export default FileUploadForm;
