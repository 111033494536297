import * as R from 'ramda';
import ClickOutsideHOC from '../../ClickOutsideHOC/ClickOutsideHOC';
import CommentDialog from '../../CommentDialog/CommentDialog';
import CommentTriangle from '../../Triangles/CommentTriangle';
import DragHeaderHintLine from '../../GridHeader/DragHeaderHintLine';
import Icon from '@mui/material/Icon';
import InvisibleCell from '../InvisibleCell/InvisibleCell';
import MappingCell from '../MappingCell/MappingCell';
import PortalDialog from '../../PortalDialog/PortalDialog';
import Tooltip from '@mui/material/Tooltip';
import i18n from 'ui-i18n';
import styles from '../grid.less';
import withFeatures from '../../Feature/withFeatures';
import {CheckedPolicies} from '../../../permissions/permissions.types';
import {CollaborationOptions, GeneralCollaboration} from '../../../rdm-sdk/collaboration.types';
import {FEATURE} from '../../../rdm-sdk/features';
import {Features} from '../../../rdm-sdk/features.types';
import {GRID_COLUMN_REORDER_TYPE} from '../../../constants/dragTypes';
import {InsertSourceMapping, OnDetailsOpen, RemoveSourceMapping, UpdateSourceMapping} from '../mappingGrid.types';
import {LookupValue} from '../../../rdm-sdk/lookups.types';
import {MappingIdentifier, SelectedMappingMode} from '../../../rdm-sdk/grid.types';
import {OpenedComment} from '../../../rdm-sdk/ui.types';
import {SearchHighlightMapping} from '../searchHelpers';
import {Source} from '../../../rdm-sdk/sources.types';
import {StateEvent} from '../../../rdm-sdk/state.types';
import {ValuesSearchQuery} from '../../../rdm-sdk/filters.types';
import {checkRemoved} from '../../../core/marks';
import {connect} from 'react-redux';
import {fromArray} from '../../../core/maybe';
import {getIsDCRDisplayed} from '../../../redux/selectors/workflowSelectors';
import {getMappingGroupObjectId, isSuggestorElement} from '../../../rdm-sdk/collaboration';
import {getMappingIdentifier} from '../../../rdm-sdk/grid';
import {getOpenedComment} from '../../../redux/reducers/uiReducer';
import {noop} from '../../../core/util';
import {setOpenedCommentEvent} from '../../../redux/actions/ui';
import {useCallback, useState} from 'react';
import {useDrop} from 'react-dnd';
export const DEFAULT_MAPPING = {
    enabled: true,
    downStreamDefaultValue: false,
    canonicalValue: false,
    description: '',
    code: '',
    value: ''
};
type Props = {
    dispatch: (event: StateEvent) => void;
    currentType: string;
    lookupValue: LookupValue;
    source: Source;
    onDetailsOpen: OnDetailsOpen;
    updateSourceMapping: UpdateSourceMapping;
    insertSourceMapping: InsertSourceMapping;
    removeSourceMapping: RemoveSourceMapping;
    searchQuery: ValuesSearchQuery;
    searchMapping: SearchHighlightMapping;
    columnWidth: number;
    policies: CheckedPolicies;
    isOver: boolean;
    canDrop: boolean;
    relativeToSelectedColumn: 'left' | 'right' | 'center';
    columnIndex: number;
    isColumnSelected: boolean;
    openedComment?: OpenedComment | null;
    collaborationOptions: CollaborationOptions;
    collaboration: GeneralCollaboration;
    features: Features;
    setFocusCell: (arg0: MappingIdentifier) => void;
    logColumnReorder: (payload: {sourceIndex: number; targetIndex: number}) => Promise<any>;
    setEditorCell: (arg0: MappingIdentifier) => void;
    resetSelectedMapping: () => void;
    changeSelectedMappingMode: (arg0: SelectedMappingMode) => void;
    focusCellIndex?: number | null;
    editorCellIndex?: number | null;
    isEditMode: boolean;
    isDCRDisplayed: boolean;
    changeColumnOrder: (sourceIndex: number, targetIndex: number) => void;
};

type DragItem = {
    columnIndex: number;
};

export const MappingCellGroup = (props: Props) => {
    const {
        changeColumnOrder,
        changeSelectedMappingMode,
        collaboration,
        collaborationOptions,
        columnIndex,
        columnWidth,
        currentType,
        dispatch,
        editorCellIndex,
        features,
        focusCellIndex,
        insertSourceMapping,
        isColumnSelected,
        isDCRDisplayed,
        isEditMode,
        logColumnReorder,
        lookupValue,
        onDetailsOpen,
        openedComment,
        policies,
        relativeToSelectedColumn,
        removeSourceMapping,
        resetSelectedMapping,
        searchMapping,
        searchQuery,
        setEditorCell,
        setFocusCell,
        source,
        updateSourceMapping
    } = props;

    const [{isOver, canDrop, monitorId}, drop] = useDrop(
        () => ({
            accept: GRID_COLUMN_REORDER_TYPE,
            canDrop: (item: DragItem) => {
                const {columnIndex: currentColumnIndex} = item;
                return columnIndex > 0 && columnIndex !== currentColumnIndex;
            },
            drop: (item: DragItem) => {
                const {columnIndex: currentColumnIndex} = item;
                changeColumnOrder(currentColumnIndex, columnIndex);
                logColumnReorder({
                    sourceIndex: currentColumnIndex,
                    targetIndex: columnIndex
                });
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                monitorId: monitor.getHandlerId()
            })
        }),
        []
    );

    const [isHovered, setIsHovered] = useState(false);

    const isCollaborationEnabled = useCallback(() => {
        return features[FEATURE.COLLABORATION];
    }, [features]);

    const setHovered = useCallback(
        (isHovered: boolean) => () => {
            setIsHovered(isHovered);
        },
        []
    );
    const onMouseEnter = useCallback(setHovered(true), []);
    const onMouseLeave = useCallback(setHovered(false), []);

    const insertMapping = (event: Record<string, any>) => {
        if (event.ctrlKey) {
            setFocusCell(getMappingIdentifier(lookupValue.code, source.abbreviation, 0));
        } else {
            insertSourceMapping(source.abbreviation, lookupValue, DEFAULT_MAPPING);
        }

        dispatch(setOpenedCommentEvent(null));
        onMouseLeave();
    };

    const closeCommentDialog = (event?: Record<string, any>) => {
        if (isSuggestorElement(event?.target)) return;
        dispatch(setOpenedCommentEvent(null));
    };

    const openCommentDialog = () => {
        dispatch(
            setOpenedCommentEvent({
                code: lookupValue.code,
                source: source.abbreviation
            })
        );
        onMouseLeave();
    };
    const onInvisibleCellClick = (event: Record<string, any>) => {
        insertMapping(event);
        setFocusCell(getMappingIdentifier(lookupValue.code, source.abbreviation, 0));
    };

    const getCellGroup = () => {
        const mappings = fromArray(lookupValue.sources[source.abbreviation]).orSome([]);
        const shouldShowAddIcon = !checkRemoved(lookupValue) && mappings.length > 0;
        const hasComment = commentId !== null;
        const shouldRenderCommentTriangle = isCollaborationEnabled() && policies.collaborationEdit && hasComment;
        const addIcon = (
            <Tooltip title={i18n.text('Add value')}>
                <div onClick={insertMapping} className={styles['mapping-cell-group__item-add']}>
                    <Icon className={styles['mapping-cell-group__add-icon']}>add</Icon>
                </div>
            </Tooltip>
        );
        return (
            <div
                className={styles['mapping-cell-group__container']}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={!shouldShowAddIcon && isEditMode ? insertMapping : noop}
            >
                {shouldRenderCommentTriangle && <CommentTriangle />}
                {mappings.map((mapping, index) => (
                    <MappingCell
                        key={index}
                        mappingIndex={index}
                        lookupValue={lookupValue}
                        mapping={mapping}
                        source={source}
                        onDetailsOpen={onDetailsOpen}
                        updateSourceMapping={updateSourceMapping}
                        removeSourceMapping={removeSourceMapping}
                        searchQuery={searchQuery}
                        searchMapping={searchMapping}
                        columnWidth={columnWidth}
                        isColumnSelected={isColumnSelected}
                        openCommentDialog={openCommentDialog}
                        hasComment={hasComment}
                        isCommentDialogOpen={Boolean(shouldRenderCommentDialog)}
                        changeSelectedMappingMode={changeSelectedMappingMode}
                        setFocusCell={setFocusCell}
                        setEditorCell={setEditorCell}
                        resetSelectedMapping={resetSelectedMapping}
                        policies={policies}
                        isEditorCell={editorCellIndex === index}
                        isFocusCell={focusCellIndex === index}
                        isEditMode={isEditMode}
                        isDCRDisplayed={isDCRDisplayed}
                    />
                ))}
                {!shouldShowAddIcon && isEditMode && (
                    <InvisibleCell onClick={onInvisibleCellClick} isFocusCell={focusCellIndex === 0} />
                )}
                {shouldShowAddIcon && isEditMode && addIcon}

                {isOver && canDrop && <DragHeaderHintLine relativeToSelectedColumn={relativeToSelectedColumn} />}
            </div>
        );
    };

    const objectId = getMappingGroupObjectId(currentType, lookupValue.code, source.abbreviation);
    const commentId = R.pipe(
        R.propOr([], objectId),
        R.filter(R.propEq('status', 'open')),
        R.nth(0),
        R.propOr(null, 'commentId')
    )(collaboration.commentsCount);
    const isCommentDialogOpen = Boolean(
        openedComment && openedComment.code === lookupValue.code && openedComment.source === source.abbreviation
    );
    const hasComment = commentId !== null;
    const hasEditorCell = editorCellIndex !== null;
    const shouldRenderCommentDialog =
        isCollaborationEnabled() &&
        policies.collaborationEdit &&
        ((hasComment && isHovered && !openedComment) || isCommentDialogOpen) &&
        !hasEditorCell;
    const cellGroup = getCellGroup();
    const cellGroupWithComment = (
        <div>
            {cellGroup}
            {shouldRenderCommentDialog && (
                <PortalDialog>
                    <ClickOutsideHOC onClickOutside={closeCommentDialog}>
                        <CommentDialog
                            isOpen={isCommentDialogOpen}
                            collaborationOptions={collaborationOptions}
                            data={{
                                code: lookupValue.code,
                                source: source.abbreviation
                            }}
                            closeCommentDialog={closeCommentDialog}
                            commentId={commentId}
                            setParentHovered={setHovered}
                            openCommentDialog={openCommentDialog}
                        />
                    </ClickOutsideHOC>
                </PortalDialog>
            )}
        </div>
    );
    return (
        <div ref={drop} data-monitorid={monitorId} className={styles['mapping-cell-group__drop-target']}>
            {cellGroupWithComment}
        </div>
    );
};

const mapStateToProps = (state) => ({
    openedComment: getOpenedComment(state),
    policies: state.policies,
    isDCRDisplayed: getIsDCRDisplayed(state)
});

export default R.pipe(withFeatures, connect(mapStateToProps))(MappingCellGroup);
