import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';

type Props = {
    selectProps: {
        classes: {
            clearIndicator: string;
        };
    };
    innerProps: IconButtonProps;
};

export default function ClearIndicator({selectProps: {classes}, innerProps}: Props) {
    return (
        <IconButton {...innerProps} className={classes.clearIndicator} size="large">
            <HighlightOffIcon />
        </IconButton>
    );
}
