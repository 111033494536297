import * as R from 'ramda';
import AddTypeDialog from '../../AddTypeDialog/AddTypeDialog';
import Button from '@mui/material/Button';
import i18n from 'ui-i18n';
import {Component, Fragment} from 'react';
type Props = unknown;
type State = {
    open: boolean;
};

class AddLookupTypeButton extends Component<Props, State> {
    state = {
        open: false
    };
    toggleOpen = () =>
        this.setState(
            R.evolve({
                open: R.not
            })
        );

    render() {
        return (
            <Fragment>
                <Button color="primary" onClick={this.toggleOpen}>
                    {i18n.text('+ CREATE NEW LOOKUP TYPE')}
                </Button>
                <AddTypeDialog
                    open={this.state.open}
                    onCreateClick={() => {}}
                    onCancelClick={() => {}}
                    onClose={this.toggleOpen}
                />
            </Fragment>
        );
    }
}

export default AddLookupTypeButton;
